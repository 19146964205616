import gql from 'graphql-tag';

export const MUTATION_UPDATE_SUBSIDIARY_MONITORING_ENTITY = gql`
  mutation updateSubsidiaryEntityMonitoring(
    $input: SubsidiaryEntityMonitoringUpdateInput!
  ) {
    updateSubsidiaryEntityMonitoring(input: $input) {
      id
      status
    }
  }
`;

export const MUTATION_DELETE_SUBSIDIARY_MONITORING_ENTITY = gql`
  mutation deleteSubsidiaryEntityMonitoring($id: ID!) {
    deleteSubsidiaryEntityMonitoring(id: $id)
  }
`;

export const MUTATION_APPROVE_SUBSIDIARY_MONITORING_ENTITY = gql`
  mutation approveSubsidiaryEntityMonitoring($id: ID!) {
    approveSubsidiaryEntityMonitoring(id: $id) {
      id
      status
    }
  }
`;

export const MUTATION_SEND_SUBSIDIARY_MONITORING_ENTITY_FOR_APPROVAL = gql`
  mutation sendSubsidiaryEntityMonitoringForApproval($id: ID!) {
    sendSubsidiaryEntityMonitoringForApproval(id: $id) {
      id
      status
    }
  }
`;

export const MUTATION_REQUEST_EDIT_SUBSIDIARY_MONITORING_ENTITY = gql`
  mutation requestEditForSubsidiaryEntityMonitoring($id: ID!) {
    requestEditForSubsidiaryEntityMonitoring(id: $id) {
      id
      status
    }
  }
`;

export const MUTATION_REJECT_EDIT_SUBSIDIARY_MONITORING_ENTITY = gql`
  mutation rejectEditForSubsidiaryEntityMonitoring($id: ID!) {
    rejectEditForSubsidiaryEntityMonitoring(id: $id) {
      id
      status
    }
  }
`;

export const MUTATION_RETURN_SUBSIDIARY_MONITORING_ENTITY = gql`
  mutation returnSubsidiaryEntityMonitoring($id: ID!) {
    returnSubsidiaryEntityMonitoring(id: $id) {
      id
      status
    }
  }
`;

export const MUTATION_CREATE_SUBSIDIARY_MONITORING_REPORT_FILE_TOKEN = gql`
  mutation createSubsidiaryEntityMonitoringReportOneTimeToken($id: ID!) {
    createSubsidiaryEntityMonitoringReportOneTimeToken(
      subsidiary_entity_monitoring_id: $id
    )
  }
`;
