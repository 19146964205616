import React from 'react';
import immer from 'immer';
import classNames from 'classnames';
import MaterialTextField from '@material-ui/core/TextField';
import s from '../Inputs/Inputs.module.scss';

export const NewTextField = React.memo(function NewTextField({
  errorMessage,
  fullWidth,
  InputProps,
  ...props
}) {
  const inputProps = immer(InputProps || {}, (props) => {
    if (!props.classes) {
      props.classes = {};
    }
    props.classes.root = classNames(
      { [s.TextInput]: !fullWidth, [s.TextInput_fullWidth]: fullWidth },
      props.classes.root
    );
  });

  return (
    <MaterialTextField
      variant={'outlined'}
      {...props}
      value={props.value ?? ''}
      margin="normal"
      error={!!errorMessage}
      helperText={errorMessage}
      InputProps={inputProps}
      classes={{
        root: classNames(s.Field, {
          [s.Field_fullWidth]: fullWidth
        })
      }}
    />
  );
});
