import React, { useState } from 'react';
import classNames from 'classnames';
import { isFunction } from 'lodash-es';
import { DatePicker } from '@material-ui/pickers';
import { Input } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {
  Clear as ClearIcon,
  InsertInvitation as CalendarIcon
} from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import { DATE_FORMAT_RUSSIAN, tryParseIso } from '../../utils/date';
import s from './DatePickerRanger.module.scss';

const getInitialDate = (date) => {
  return date ? tryParseIso(date) : null;
};

export const DatePickerRanger = ({
  startDate: propsStartDate,
  endDate: propsEndDate,
  onStartChange,
  onEndChange,
  className,
  sinceTitle,
  tillTitle,
  label,
  variant = 'standard'
}) => {
  const [startDate, setStartDate] = useState(getInitialDate(propsStartDate));
  const [endDate, setEndDate] = useState(getInitialDate(propsEndDate));

  const handleStartChange = (newStartDate) => {
    // if (isAfter(newStartDate, endDate)) {
    //   return;
    // }
    setStartDate(newStartDate);
    isFunction(onStartChange) && onStartChange(newStartDate);
  };

  const handleEndChange = (newEndDate) => {
    // if (isBefore(newEndDate, startDate)) {
    //   return;
    // }
    setEndDate(newEndDate);
    isFunction(onEndChange) && onEndChange(newEndDate);
  };

  return (
    <div
      className={classNames(s.DatePickerRanger, s[`_${variant}`], className)}
    >
      <label
        className={classNames(s.DatePickerRanger__label, s[`_${variant}`])}
      >
        {label}
      </label>
      <div className={s.DatePickerRanger__inputs}>
        <div className={s.DatePickerRanger__inputContainer}>
          {sinceTitle && <label>{sinceTitle}</label>}
          <DatePicker
            value={startDate}
            onChange={handleStartChange}
            className={s.DatePickerRanger__input}
            maxDate={endDate}
            disableUnderline={variant === 'outlined'}
            format={DATE_FORMAT_RUSSIAN}
            clearable
            TextFieldComponent={(props) => {
              return (
                <Input
                  {...props}
                  disableUnderline={variant === 'outlined'}
                  className={s.DatePickerRanger__input}
                  endAdornment={
                    startDate ? (
                      <IconButton
                        className={s.DatePickerRanger__clearButton}
                        onClick={(event) => {
                          handleStartChange(null);
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    ) : (
                      <IconButton className={s.DatePickerRanger__clearButton}>
                        <CalendarIcon />
                      </IconButton>
                    )
                  }
                />
              );
            }}
          />
        </div>
        <div className={s.DatePickerRanger__inputContainer}>
          {tillTitle && <label>{tillTitle}</label>}
          <DatePicker
            value={endDate}
            onChange={handleEndChange}
            minDate={startDate}
            format={DATE_FORMAT_RUSSIAN}
            clearable
            TextFieldComponent={(props) => {
              return (
                <Input
                  {...props}
                  disableUnderline={variant === 'outlined'}
                  className={s.DatePickerRanger__input}
                  endAdornment={
                    endDate ? (
                      <IconButton
                        className={s.DatePickerRanger__clearButton}
                        onClick={(event) => {
                          handleEndChange(null);
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    ) : (
                      <IconButton className={s.DatePickerRanger__clearButton}>
                        <CalendarIcon />
                      </IconButton>
                    )
                  }
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};
