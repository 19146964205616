import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SubTable from '../../../common/SubTable/SubTable';
import { EMDASH } from '../../../utils/constants';
import { ContestDocsRoadmapPreview } from './ContestDocsRoadmap/ContestDocsRoadmapPreview';

export const ContestDocsMonitoringFormPreview = ({ entity }) => {
  return (
    <>
      {entity.roadmaps?.map((rm, iRm) => (
        <ContestDocsRoadmapPreview key={iRm} index={iRm} roadmap={rm} />
      ))}

      <SubTable>
        <TableRow>
          <TableCell>Аналитическая информация</TableCell>
          <TableCell>{entity.analytical_information || EMDASH}</TableCell>
        </TableRow>
      </SubTable>
    </>
  );
};
