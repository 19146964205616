import React, { useCallback, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useContextStore } from '@proscom/prostore-react';
import { stringifyUrl } from 'query-string';
import { useCurrentUser } from '../../store/useCurrentUser';
import { useOtherEditor } from '../../common/Form/useOtherEditor';
import { useUrlQuery } from '../../common/useUrlQuery';
import {
  STORE_FILE_TOKEN,
  SUBSIDIARY_MONITORING_STORE
} from '../../store/names';
import { SubsidiaryMonitoringEntity } from '../../store/subsidiaryMonitoring/SubsidiaryMonitoringEntity';
import { useDeleteRow } from '../../common/Table/useDeleteRow';
import { useGeneralConfirmationRow } from '../../common/Table/useGeneralConfirmationRow';
import { getCurrentMonth, getCurrentYear } from '../../utils/data/date';
import { useSubsidiaryMonitoringEntity } from '../../store/subsidiaryMonitoring/useSubsidiaryMonitoringEntity';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../graphql/graphqlErrors';
import { useUserRole } from '../../store/role/useUserRole';
import {
  lazyValidation,
  SubsidiaryMonitoringEntityApprovalValidationSchema,
  SubsidiaryMonitoringEntityDraftValidationSchema
} from '../../utils/data/validation';
import { downloadRestFile } from '../../utils/AxiosHelpers';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';
import PageTitle from '../../common/PageTitle/PageTitle';
import { ExportHistoryPopup } from '../../common/ExportHistoryPopup/ExportHistoryPopup';
import { QUERY_SUBSIDIARY_MONITORING_EXPORT_HISTORY } from '../../graphql/queries/subsidiaryMonitoring';
import { SubsidiaryMonitoringForm } from './SubsidiaryMonitoringForm/SubsidiaryMonitoringForm';
import { useSubsidiaryMonitoringExportDialog } from './SubsidiaryMonitoringForm/SubsidiaryMonitoringExport/useSubsidiaryMonitoringExportDialog';
import { subsidiaryMonitoringPageTitle } from './SubsidiaryMonitoringPage';

export default function SubsidiaryMonitoringFormPage({
  match,
  history,
  location
}) {
  const { entityId } = match.params;
  const [query, changeQuery] = useUrlQuery(location, history);
  // const backLink = removeLastPathItem(match.url);
  const user = useCurrentUser();
  const { isRegionAdmin } = useUserRole(user);
  const [exportHistoryOpen, setExportHistoryOpen] = useState(false);

  const entityStore = useContextStore(SUBSIDIARY_MONITORING_STORE);
  const tokenStore = useContextStore(STORE_FILE_TOKEN);

  const [isDeleting, deleteDialog, openDeleteDialog] = useDeleteRow(
    entityStore.delete,
    'форму',
    () =>
      changeQuery({
        roadmap_id: undefined,
        subsidiary_entity_id: undefined
      })
  );
  const [
    isRequestEditLoading,
    requestEditDialog,
    openRequestEditDialog
  ] = useGeneralConfirmationRow(
    entityStore.requestEdit,
    'Вы уверены, что хотите запросить редактирование формы?',
    'Возникла ошибка при запросе редактирования формы. Попробуйте еще раз'
  );
  const [
    isAllowEditLoading,
    allowEditDialog,
    openAllowEditDialog
  ] = useGeneralConfirmationRow(
    entityStore.return,
    'Вы уверены, что хотите разрешить редактирование формы?',
    'Возникла ошибка при разрешении редактирования формы. Попробуйте еще раз'
  );
  const [
    isRejectEditLoading,
    rejectEditDialog,
    openRejectEditDialog
  ] = useGeneralConfirmationRow(
    entityStore.rejectEdit,
    'Вы уверены, что хотите запретить редактирование формы?',
    'Возникла ошибка при запрете редактирования формы. Попробуйте еще раз'
  );
  const [
    isReturnLoading,
    returnDialog,
    openReturnDialog
  ] = useGeneralConfirmationRow(
    entityStore.return,
    'Вы уверены, что хотите вернуть форму на доработку?',
    'Возникла ошибка при возвращении формы на доработку. Попробуйте еще раз'
  );

  const [
    exportLoading,
    exportDialog,
    openExportDialog
  ] = useSubsidiaryMonitoringExportDialog(
    async (id, data) => {
      const accessToken = await tokenStore.createSubsidiaryMonitoringReportOneTimeToken(
        id
      );
      const url = stringifyUrl({
        url: `/monthly_monitoring/subsidiary_entity/${id}`,
        query: data
      });
      await downloadRestFile({
        method: 'get',
        responseUrl: url,
        accessToken
      });
    },
    'Для экспорта формы укажите должность и ФИО лица, подписывающего документ',
    'Возникла ошибка при экспорте формы. Попробуйте еще раз'
  );

  const year = query.year || getCurrentYear().toString();
  const month =
    query.month || SubsidiaryMonitoringEntity.monthArray[getCurrentMonth() - 1];
  const region_id = isRegionAdmin ? user?.region?.id : query.region_id;
  const roadmap_id = query.roadmap_id;
  const subsidiary_entity_id = query.subsidiary_entity_id;

  const filter = {
    year,
    month,
    region_id,
    roadmap_id,
    subsidiary_entity_id
  };

  const input = useMemo(() => {
    return !isDeleting && year && month && subsidiary_entity_id
      ? {
          year: +year,
          month,
          subsidiary_entity_id
        }
      : null;
  }, [isDeleting, subsidiary_entity_id, year, month]);
  const entityQuery = useSubsidiaryMonitoringEntity(input);
  const entityQueryLoading = entityQuery.check.spinner;
  const entityQueryData = entityQuery.state.data;
  const entityQueryDataId = entityQueryData?.id;

  const handleChangeFilter = useCallback(
    (name, value) => {
      const obj = {
        [name]: value ?? undefined
      };

      if (['year', 'month', 'region_id'].includes(name)) {
        obj.roadmap_id = undefined;
      }
      if (['year', 'month', 'region_id', 'roadmap_id'].includes(name)) {
        obj.subsidiary_entity_id = undefined;
      }

      changeQuery(obj);
    },
    [changeQuery]
  );

  const handleSubmit = useCallback(
    (data, actions) => {
      const input = SubsidiaryMonitoringEntity.fromForm(data);

      const isSubmit =
        data['submitStatus'] ===
        SubsidiaryMonitoringEntity.formSubmitStatus.Submit;
      const isApprove =
        data['submitStatus'] ===
        SubsidiaryMonitoringEntity.formSubmitStatus.Approve;
      const isSendForApproval =
        data['submitStatus'] ===
        SubsidiaryMonitoringEntity.formSubmitStatus.SendForApproval;

      if (isSubmit) {
        entityStore
          .update(input)
          .then((result) => {
            // actions.setStatus(FORM_STATUS_SUBMITTED);
            // setTimeout(() => {
            //   history.replace('/contestDocsMonitoring');
            // }, 0);
            toast.success('Форма успешно сохранена');
          })
          .catch((error) => {
            showGraphqlErrorToast(error, mutationErrors.updateObject);
          })
          .then(() => {
            actions.setSubmitting(false);
            // actions.resetForm();
          });
      }
      if (isApprove) {
        entityStore
          .approve(entityQueryDataId)
          .catch((error) => {
            showGraphqlErrorToast(error, mutationErrors.approveObject);
          })
          .then(() => {
            actions.setSubmitting(false);
            // actions.resetForm();
          });
      }
      if (isSendForApproval) {
        entityStore
          .sendForApproval(entityQueryDataId)
          .catch((error) => {
            showGraphqlErrorToast(error, mutationErrors.sendForApprovalObject);
          })
          .then(() => {
            actions.setSubmitting(false);
            // actions.resetForm();
          });
      }
    },
    [entityStore, entityQueryDataId]
  );

  const handleGoBack = useCallback(() => {
    history.push('/subsidiaryMonitoring');
  }, [history]);

  const initialValues = useMemo(() => {
    return SubsidiaryMonitoringEntity.toForm(entityQueryData);
  }, [entityQueryData]);

  const editor = useOtherEditor(
    'subsidiaryMonitoringEntity',
    entityId || entityQueryDataId
  );

  const loading =
    isDeleting ||
    isRequestEditLoading ||
    isAllowEditLoading ||
    isRejectEditLoading ||
    isReturnLoading;

  return (
    <>
      <HeadTitle title={subsidiaryMonitoringPageTitle} />
      <PageTitle withBackBtn={false} title={subsidiaryMonitoringPageTitle} />
      <Formik
        validationSchema={lazyValidation((values) => {
          const isApproval =
            values['submitStatus'] ===
              SubsidiaryMonitoringEntity.formSubmitStatus.Approve ||
            values['submitStatus'] ===
              SubsidiaryMonitoringEntity.formSubmitStatus.SendForApproval;
          return isApproval
            ? SubsidiaryMonitoringEntityApprovalValidationSchema
            : SubsidiaryMonitoringEntityDraftValidationSchema;
        })}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        render={(formikProps) => (
          <SubsidiaryMonitoringForm
            {...formikProps}
            entity={entityQueryData}
            entityLoading={entityQueryLoading || loading}
            entityDeleting={isDeleting}
            exportLoading={exportLoading}
            editor={editor}
            filter={filter}
            onFilterChange={handleChangeFilter}
            onBackClick={handleGoBack}
            onDelete={openDeleteDialog}
            onRequestEdit={openRequestEditDialog}
            onAllowEdit={openAllowEditDialog}
            onRejectEdit={openRejectEditDialog}
            onReturn={openReturnDialog}
            onExport={openExportDialog}
            onExportHistoryOpen={() => setExportHistoryOpen(true)}
          />
        )}
      />
      <ExportHistoryPopup
        id={entityId || entityQueryDataId}
        query={QUERY_SUBSIDIARY_MONITORING_EXPORT_HISTORY}
        open={exportHistoryOpen}
        onClose={() => setExportHistoryOpen(false)}
      />
      {deleteDialog}
      {requestEditDialog}
      {allowEditDialog}
      {rejectEditDialog}
      {returnDialog}
      {exportDialog}
    </>
  );
}
