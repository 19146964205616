import { skipIfNull } from '@proscom/prostore';
import { QUERY_GET_INTERNET_SPEED_IN_NODES } from '../../graphql/queries/internetSpeed';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';

const internetSpeedQueryOptions = {
  query: QUERY_GET_INTERNET_SPEED_IN_NODES,
  mapData: (result) => result.internetSpeedInNodes,
  skipQuery: skipIfNull(null)
};

export function useInternetSpeedInNodes(filter) {
  return useRetriableGraphqlVars({
    queryOptions: internetSpeedQueryOptions,
    variableCreator: [
      () =>
        filter
          ? {
              filter
            }
          : null,
      [filter]
    ]
  });
}
