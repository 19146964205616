import gql from 'graphql-tag';
import { FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT } from './subsidiaryEntity';

export const FRAGMENT_OWNER_ROADMAP_INDICATOR = gql`
  fragment ownerRoadmapIndicator on RoadmapIndicatorType {
    id
    name_full
    unit {
      id
      name
    }
    plan_roadmap_indicator {
      explanation
      min_numeric_value_per_year
      min_numeric_value_per_year_formula
    }
  }
`;

export const FRAGMENT_SUBSIDIARY_ENTITY_ROADMAP_INDICATOR = gql`
  fragment subsidiaryEntityRoadmapIndicator on SeRoadmapIndicatorType {
    id
    name_full
    year
    month
    status
    comment_fpo
    owner_roadmap_indicator_id
    region_id
    region {
      id
      name
    }
    subsidiary_entity_id
    value_from_tender_documentation
    value_fact
    author_user_id
    author_user {
      id
      name
      email
      login
    }
    created_at
    updated_at
    version
  }
`;

export const FRAGMENT_SUBSIDIARY_ENTITY_ROADMAP_INDICATORS = gql`
  fragment subsidiaryEntityRoadmapIndicators on SeRoadmapIndicatorType {
    id
    code
    name_full
    value_from_tender_documentation
    value_fact
    status
    subsidiary_entity {
      ...subsidiaryEntityForSelect
    }
    owner_roadmap_indicator {
      id
      plan_roadmap_indicator {
        min_numeric_value_per_year
        min_numeric_value_per_year_formula
      }
    }
  }

  ${FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT}
`;

export const QUERY_SUBSIDIARY_ENTITY_ROADMAP_INDICATORS = gql`
  query subsidiaryEntityRoadmapIndicators(
    $filter: SubsidiaryEntityRoadmapIndicatorsFilter
    $sorting: [SubsidiaryEntityRoadmapIndicatorsSorting!]
  ) {
    subsidiaryEntityRoadmapIndicators(filter: $filter, sorting: $sorting) {
      ...subsidiaryEntityRoadmapIndicators
    }
  }
  ${FRAGMENT_SUBSIDIARY_ENTITY_ROADMAP_INDICATORS}
`;

export const QUERY_ROADMAP_INDICATORS = gql`
  query roadmapIndicators($filter: RoadmapIndicatorsFilter) {
    roadmapIndicators(filter: $filter) {
      id
      code
      name_full
    }
  }
`;

export const QUERY_SUBSIDIARY_ENTITY_ROADMAP_INDICATOR = gql`
  query subsidiaryEntityRoadmapIndicator($id: ID!) {
    subsidiaryEntityRoadmapIndicator(id: $id) {
      ...subsidiaryEntityRoadmapIndicator
      owner_roadmap_indicator {
        ...ownerRoadmapIndicator
      }
      subsidiary_entity {
        ...subsidiaryEntityForSelect
        base_organization {
          bs_address
        }
      }
    }
  }

  ${FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT}
  ${FRAGMENT_SUBSIDIARY_ENTITY_ROADMAP_INDICATOR}
  ${FRAGMENT_OWNER_ROADMAP_INDICATOR}
`;

export const ROADMAP_INDICATOR = gql`
  query roadmapIndicator($id: ID!) {
    roadmapIndicator(id: $id) {
      ...ownerRoadmapIndicator
    }
  }

  ${FRAGMENT_OWNER_ROADMAP_INDICATOR}
`;
