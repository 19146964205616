import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SubTable from '../../../../../common/SubTable/SubTable';
import { getName } from '../../../../../utils/data/label';
import { Individual } from '../../../../../store/Individual';

export const CommonInfo = React.memo(function CommonInfo({
  event,
  region,
  project,
  roadmap
}) {
  const federalResponsiblePerson =
    event.responsibility_level_vo && event.responsible_person;

  return (
    <>
      <SubTable title="Общие сведения" editVariant>
        <TableRow>
          <TableCell>Регион</TableCell>
          <TableCell>{getName(region)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Региональный проект</TableCell>
          <TableCell>{getName(project)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Результат</TableCell>
          <TableCell>{getName(roadmap.result)}</TableCell>
        </TableRow>
      </SubTable>
      {event.responsibility_level_vo && (
        <SubTable
          title={`Ответственный за реализацию. Ведомственный проектный офис проекта "Образование"`}
          editVariant
        >
          <TableRow>
            <TableCell>ФИО</TableCell>
            <TableCell>
              {Individual.combineName(federalResponsiblePerson)}
            </TableCell>
          </TableRow>
        </SubTable>
      )}
    </>
  );
});
