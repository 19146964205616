import React, { useMemo } from 'react';
import { CustomFastField } from '../../../../../common/Inputs/CustomFastField';
import TableInput from '../../../../../common/Table/TableInput';
import InputGroup from '../../../../../common/Inputs/InputGroup';
import { TableSelectWrapper } from '../../../../../common/Inputs/TableSelectWrapper';
import { RpImplementationForecast } from '../../../../../store/rpImplementationForecast/RpImplementationForecast';
import { Form } from '../../../../../store/form/Form';
import {
  TableCurrencyInput,
  TableTextInput
} from '../../../../../common/Inputs/TextInput';
import { FormSelectInput } from '../../../../fillForms/form/FormSelectInput';
import { getName } from '../../../../../utils/data/label';
import s from '../../RpImplementationForecastPage.module.scss';

const getResultForecastsColumns = (federalProjectsOptions, resultFederals) => [
  {
    editComponent: TableSelectWrapper(federalProjectsOptions, {
      width: '200px'
    }),
    field: 'federal_project_id',
    title: 'Региональный проект',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellMedium,
    render: ({ federal_project_id }) => {
      const federalProject = federalProjectsOptions.find(
        (item) => item.value === federal_project_id
      );
      return federalProject?.label || '—';
    }
  },
  {
    editComponent: function ResultEdit({
      value,
      onChange,
      rowData,
      columnDef
    }) {
      const variables = useMemo(
        () => ({
          federal_project_ids: rowData.federal_project_id
        }),
        [rowData.federal_project_id]
      );
      return (
        <FormSelectInput
          entity={Form.EntityClass.ResultFp}
          value={value}
          onChange={onChange}
          hasCopyBtn={false}
          isField
          TextFieldProps={{
            classes: {
              root: s.SelectInput
            }
          }}
          fullWidth
          variant="standard"
          variables={variables}
        />
      );
    },
    field: 'result_federal_id',
    title: 'Название результата',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellLarge,
    render: ({ result_federal_id }) => {
      const resultFederal = resultFederals?.find(
        (item) => item.id === result_federal_id
      );
      return getName(resultFederal) || '—';
    }
  },
  {
    editComponent: TableCurrencyInput,
    title: 'Плановое значение по результату на 2020 год',
    field: 'plan_value',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellSmall
  },
  {
    editComponent: TableCurrencyInput,
    title: 'Прогнозное значение по результату на 2020 год',
    field: 'forecast_value',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellSmall
  },
  {
    editComponent: (props) => <TableTextInput {...props} multiline />,
    title: 'Обоснование изменений',
    field: 'changes_justification',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellLarge
  }
];

export const ResultForecastsFields = ({
  federalProjectsOptions,
  resultFederals
}) => {
  return (
    <InputGroup>
      <CustomFastField
        name="result_forecasts"
        columns={getResultForecastsColumns(
          federalProjectsOptions,
          resultFederals
        )}
        component={TableInput}
        tableName="Прогноз по результатам"
      />
    </InputGroup>
  );
};
