import { skipIfNull } from '@proscom/prostore';

import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import {
  QUERY_GET_ROADMAP_EVENT,
  QUERY_GET_ROADMAP_EVENT_WITH_REGION_EVENTS_LIST
} from '../../graphql/queries/roadmapEvents';

const roadmapEvent = {
  query: QUERY_GET_ROADMAP_EVENT,
  mapData: (data) => {
    const event = data.roadmapEvent;
    const roadmap = event.roadmap;

    const federalResult = event.roadmap.roadmap_years
      .filter((x) => x.year === event.year)
      .shift()?.result;

    return {
      ...event,
      roadmap: {
        ...roadmap,
        result: federalResult
      }
    };
  },
  skipQuery: skipIfNull(null)
};

const roadapEventWithRegionEventsList = {
  query: QUERY_GET_ROADMAP_EVENT_WITH_REGION_EVENTS_LIST,
  mapData: (data) => {
    const roadmapEvent = data.roadmapEvent;
    const roadmap = roadmapEvent.roadmap;

    const federalResult = roadmapEvent.roadmap.roadmap_years
      .filter((x) => x.year === roadmapEvent.year)
      .shift()?.result;

    return {
      ...roadmapEvent,
      roadmap: {
        ...roadmap,
        result: federalResult
      }
    };
  },
  skipQuery: skipIfNull(null)
};

export function useRoadmapEvent(id, regionId, networkOnly = false) {
  return useRetriableGraphqlVars({
    queryOptions: roadmapEvent,
    variableCreator: [
      () =>
        id && regionId
          ? {
              id: id,
              filter: { region_ids: [regionId] }
            }
          : null,
      [id, regionId]
    ],
    options: {
      apolloOptions: {
        fetchPolicy: networkOnly ? 'network-only' : 'cache-first'
      }
    }
  });
}

export function useRoadmapEventWithoutRegion(id, networkOnly = false) {
  return useRetriableGraphqlVars({
    queryOptions: roadapEventWithRegionEventsList,
    variableCreator: [
      () =>
        id
          ? {
              id,
              filter: {}
            }
          : null,
      [id]
    ],
    options: {
      apolloOptions: {
        fetchPolicy: networkOnly ? 'network-only' : 'cache-first'
      }
    }
  });
}
