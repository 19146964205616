import React from 'react';
import { SubsidiaryEntityNameCell } from '../../../subsidiaryEntities/SubsidiaryEntityNameCell';
import { IndicatorCheckboxItem } from './IndicatorCheckboxItem';
import { IndicatorCheckboxHead } from './IndicatorCheckboxHead';

export const useIndicatorsColumns = (
  indicators,
  selected,
  setSelected,
  sortableColumns
) => {
  const allChecked = selected.length === indicators?.length;
  const anyChecked = selected.length !== 0;

  return [
    {
      label: (
        <IndicatorCheckboxHead
          allChecked={allChecked}
          indicators={indicators}
          setSelected={setSelected}
          indeterminate={anyChecked && !allChecked}
        />
      ),
      key: 'checked',
      renderCell: (value, row) => (
        <IndicatorCheckboxItem
          currentId={row.id}
          currentStatus={row.status}
          selected={selected}
          setSelected={setSelected}
          value={!!selected.find((indicator) => indicator.id === row.id)}
        />
      ),
      isThin: true
    },
    { label: '№', key: 'order', isThin: true },
    { label: 'Код', key: 'code', isThin: true },
    {
      label: 'Название индикатора',
      key: 'name_full',
      isBold: true,
      isWide: true
    },
    {
      label: 'Название субсидиарной сущности',
      key: 'ssNameObject',
      renderCell: (value) => <SubsidiaryEntityNameCell entity={value} />,
      isWide: true
    },
    {
      label: 'Минимальное значение в год',
      key: 'minValue',
      isThin: true
    },
    {
      label: 'Значение из конкурсной документации',
      key: 'value_from_tender_documentation',
      isThin: true
    },
    { label: 'Значение факт', key: 'value_fact', isThin: true },
    { label: 'Статус', key: 'statusName', isThin: true },
    ...sortableColumns
  ];
};
