import Button from '@material-ui/core/Button';
import React, { useMemo, useState } from 'react';
import {
  useParams,
  useHistory,
  generatePath,
  useRouteMatch
} from 'react-router';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { showGraphqlErrorToast } from '../../../graphql/graphqlErrors';
import { useExportInfralist } from '../../../routes/infralist/useExportInfralist';
import { useExportInfralistComments } from '../../../routes/infralist/useExportInfralistComments';
import { infralistRoutes } from '../../../store/infralist/routes/infralistRoutes';
import { useBindSubsidiaryEntity } from '../../../store/infralist/useBindSubsidiaryEntity';
import { useInfralist } from '../../../store/infralist/useInfralist';
import {
  SUBSIDIARY_ENTITIES,
  useSubsidiaryEntityList
} from '../../../store/infralist/useSubsidiaryEntityList';
import { useVersionRouteRelates } from '../../../store/infralist/versions/useVersionRouteRelates';
import { useDialog } from '../../../utils/hooks/useDialog';
import { ExportModal } from '../../ExportModal/ExportModal';
import { Autocomplete } from '../FormFields/Autocomplete/Autocomplete';
import { LabelField } from '../FormFields/LabelField/LabelField';
import { TableContextMenu } from '../InfralistTable/TableContextMenu/TableContextMenu';
import { HeaderMain } from './HeaderMain/HeaderMain';
import { HeaderNotifications } from './HeaderNotifications/HeaderNotifications';
import s from './InfralistHeader.module.scss';

export const InfralistHeader = ({
  versionsPanelIsVisible,
  setVersionsPanelVisible
}) => {
  const history = useHistory();
  const [exportVisible, setExportVisible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const isCancelJournalButtonVisible = useRouteMatch(
    infralistRoutes.CHANGE_LOGS
  );
  const { infralistId } = useParams();
  const infralistQuery = useInfralist(infralistId);

  const infralist = infralistQuery?.state?.data;

  const subsidiaryEntitiesFilter = useMemo(
    () =>
      infralist
        ? {
            region_ids: infralist?.regional_roadmap_event?.region_id,
            owner_roadmap_ids: infralist?.roadmap_event?.roadmap_id,
            year: infralist?.roadmap_event?.year + ''
          }
        : null,
    [infralist]
  );

  const subsidiaryEntitiesQuery = useSubsidiaryEntityList(
    subsidiaryEntitiesFilter
  );
  const subsidiaryEntities = subsidiaryEntitiesQuery.state.data ?? [];

  const { bindSubsidiaryEntity, isLoadingBinding } = useBindSubsidiaryEntity(
    infralistId
  );

  const isLoading = infralistQuery.check.spinner || isLoadingBinding;
  const currentOption = subsidiaryEntities.find(
    (item) => item.value === infralist?.subsidiary_entity?.id
  );
  const { exportInfralist } = useExportInfralist({ infralistId });
  const exportComments = useExportInfralistComments('infralist', {
    infralistId
  });

  const handleBindSubsidiaryEntity = (subsidiaryId) => {
    bindSubsidiaryEntity(subsidiaryId).finally(() => setTooltipVisible(false));
  };

  const {
    baseRoute: currentBaseRoute,
    params: routeParams,
    isVersionRouteMatches
  } = useVersionRouteRelates();

  const {
    isOpen: isExportCommentsOpen,
    open: openExportComments,
    close: closeExportComments
  } = useDialog();

  const onExport = (type, ext) => {
    return exportInfralist(type, ext)
      .then(() => setExportVisible(false))
      .catch(() => {
        toast.error(
          'Экспорт возможен только после полного завершения формирования'
        );
      });
  };

  const onExportComments = (type) => {
    /**
     * для скачивания всего инфралиста не нужно указывать тип коммента,
     * т.к. нужны комментарии (rvpo + general) || (fpo + general)
     */
    return exportComments(undefined, type)
      .finally(closeExportComments)
      .catch(showGraphqlErrorToast);
  };

  return (
    // класс "mui-fixed" исправляет смещение абсолютных элементов при открытии модальных окон в material-ui
    // https://github.com/mui-org/material-ui/issues/10000#issuecomment-359717889
    <header className={classNames(s.header, 'mui-fixed')}>
      <HeaderMain />

      <div className={s.right}>
        {!versionsPanelIsVisible && !isCancelJournalButtonVisible && (
          <>
            {infralist?.type === SUBSIDIARY_ENTITIES.INFRALIST_SS && (
              <Tooltip
                title={currentOption?.label || ''}
                open={tooltipVisible}
                placement={'left'}
              >
                <div
                  className={s.selectWrapper}
                  onMouseOver={() => setTooltipVisible(true)}
                  onMouseLeave={() => setTooltipVisible(false)}
                >
                  <LabelField
                    label={'Субсидиарная сущность'}
                    className={s.selectLabel}
                  >
                    <Autocomplete
                      name={'INFRALIST_SS'}
                      options={subsidiaryEntities}
                      onChange={handleBindSubsidiaryEntity}
                      className={s.select}
                      value={infralist?.subsidiary_entity?.id}
                      disabled={!infralist?.editable || isLoading}
                      isLoading={isLoading}
                    />
                  </LabelField>
                </div>
              </Tooltip>
            )}
            <HeaderNotifications infralistId={infralistId} />
            <TableContextMenu
              options={[
                {
                  title: 'Экспорт',
                  onClick: () => setExportVisible(true)
                },
                {
                  title: 'Журнал внесения изменений',
                  onClick: () => {
                    history.push(`/infralist-change-logs/${infralistId}`);
                  }
                },
                {
                  title: 'Версии инфралиста',
                  onClick: () => setVersionsPanelVisible(true)
                },
                {
                  title: 'История комментариев',
                  onClick: openExportComments
                }
              ]}
            />
            <ExportModal
              onClose={() => setExportVisible(false)}
              open={exportVisible}
              onExport={onExport}
              isDoubleDocx
            />
            <ExportModal
              onClose={closeExportComments}
              open={isExportCommentsOpen}
              onExport={onExportComments}
            />
          </>
        )}
        {versionsPanelIsVisible && (
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              setVersionsPanelVisible(false);
              if (isVersionRouteMatches) {
                history.push(generatePath(currentBaseRoute, routeParams));
              }
            }}
          >
            Завершить
          </Button>
        )}
        {isCancelJournalButtonVisible && (
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              history.goBack();
            }}
          >
            Завершить
          </Button>
        )}
      </div>
    </header>
  );
};
