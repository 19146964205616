import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import SubTable from '../../../common/SubTable/SubTable';
import { useArchiveDownloadingFile } from '../downloadArchiveFile';
import { enableArchiveDownload } from '../../../config';
import { EventSlot } from './EventSlot';
import { EventOtherDocument } from './EventOtherDocument';
import { DownloadArchiveFilePopup } from './DownloadArchiveFilePopup/DownloadArchiveFilePopup';
import s from './EventPage.module.scss';

export const DocumentsWithSlots = ({
  slots = [],
  documents = [],
  editable = false,
  uploadMultiple = false,
  staffError,
  haveReason,
  ...props
}) => {
  if (!slots) return null;
  return slots.map((slot) => {
    const slotDocuments = (documents || []).filter(
      (document) => document.document_slot_id === slot.id
    );

    if (slot.code_name === 'staff_schedule_guideline_deviation') {
      if (slotDocuments.length === 0 && !staffError) {
        return null;
      }
    }

    return (
      <EventSlot
        key={slot.id}
        slot={slot}
        documents={slotDocuments}
        editable={editable}
        uploadMultiple={uploadMultiple}
        staffError={staffError}
        haveReason={haveReason}
        {...props}
      />
    );
  });
};

export const DocumentsWithoutSlots = ({
  roadmapEventDocuments,
  regionalEventId,
  eventName,
  isArchiveDownload,
  showSlots,
  ...props
}) => {
  const { handleArchiveDownload, isDownloading } = useArchiveDownloadingFile(
    regionalEventId,
    eventName,
    {
      slotIsNull: true
    }
  );

  const documentsWithoutSlot = roadmapEventDocuments?.filter(
    (document) => document.document_slot_id === null
  );

  return (
    <>
      {documentsWithoutSlot &&
        documentsWithoutSlot.map((document, index) => {
          return (
            <EventOtherDocument
              key={index}
              document={document}
              index={index}
              {...props}
            />
          );
        })}
      {enableArchiveDownload &&
        isArchiveDownload &&
        showSlots &&
        documentsWithoutSlot?.length > 0 && (
          <TableRow>
            <TableCell className={s.cellStyle}>
              Скачивание дополнительных документов мероприятия
            </TableCell>
            <TableCell>
              <div>
                <Button
                  variant="outlined"
                  component="span"
                  onClick={handleArchiveDownload}
                  disabled={isDownloading}
                >
                  {isDownloading ? 'Скачивание' : 'Скачать архивом'}
                </Button>
              </div>
            </TableCell>
          </TableRow>
        )}
    </>
  );
};

export const EventDocuments = ({
  slots,
  documents,
  showSlots,
  title,
  editVariant,
  isArchiveDownload,
  eventName,
  regionalEventId,
  showDocumentStatus
}) => {
  return (
    (slots || documents) && (
      <SubTable title={title} editVariant={editVariant}>
        {/* todo в базе не заполнены флаги */}
        {(showSlots || true) && (
          <DocumentsWithSlots
            slots={slots}
            documents={documents}
            showDocumentStatus={showDocumentStatus}
            regionalEventId={regionalEventId}
            isArchiveDownload={isArchiveDownload}
          />
        )}
        <DocumentsWithoutSlots
          roadmapEventDocuments={documents}
          showDocumentStatus={showDocumentStatus}
          regionalEventId={regionalEventId}
          eventName={eventName}
          showSlots={showSlots}
          isArchiveDownload={isArchiveDownload}
        />
        {enableArchiveDownload &&
          isArchiveDownload &&
          documents?.length > 0 && (
            <DownloadArchiveFilePopup
              documents={documents}
              regionalEventId={regionalEventId}
            />
          )}
      </SubTable>
    )
  );
};
