import { clearApolloQueryCache } from '../../utils/apollo';
import {
  MUTATION_CREATE_MENTION_COVERAGE,
  MUTATION_UPDATE_MENTION_COVERAGE
} from '../../graphql/mutations/mentoringCoverage';
import { MentoringCoverage } from './MentoringCoverage';

export class MentoringCoverageStore {
  constructor({ client }) {
    this.client = client;
  }

  queriesToClear = [
    'mentoringCoverage',
    'mentoringCoverageList',
    'mentoringCoverageByRegionId'
  ];

  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  async createMentoringCoverage(input) {
    const query = await this.client.mutate({
      mutation: MUTATION_CREATE_MENTION_COVERAGE,
      variables: {
        input: MentoringCoverage.mentoringCoverageCreateFields(input)
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
    return query.data.result.id;
  }

  async approve(id) {
    const query = await this.client.mutate({
      mutation: MUTATION_UPDATE_MENTION_COVERAGE,
      variables: {
        input: {
          id: id,
          status: 'APPROVED'
        }
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
    return query.data.result.id;
  }

  async updateMentoringCoverage(input) {
    const query = await this.client.mutate({
      mutation: MUTATION_UPDATE_MENTION_COVERAGE,
      variables: {
        input: MentoringCoverage.mentoringCoverageUpdateFields(input)
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
    return query.data.result.id;
  }
}
