import React, { useCallback, useEffect, useMemo } from 'react';
import { useRetriableRegionalProjects } from '../../store/regionalProject/useRegionalProjects';
import {
  useMemoSuggestions,
  useMemoSuggestionsFull
} from '../../utils/suggestions';
import { useRetriableExpensesDirectionsFilter } from '../../store/expensesDirection/useExpensesDirections';
import { useRetriableGrbses } from '../../store/purchase/useGrbses';
import AutocompleteBaseInput from '../../common/Inputs/Selects/AutocompleteBaseInput';
import { SearchForm } from '../../common/SearchForm/SearchForm';
import { getCurrentYear, yearsSuggestions } from '../../utils/data/date';
import { Purchase } from '../../store/purchase/Purchase';

export const usePurchasesFilter = (query, changeQuery, regionId) => {
  const year = query.year || getCurrentYear().toString();

  const filter = {
    expenses_direction_ids: query.expenses_direction_id,
    grbs_ids: query.grbs_id,
    regional_project_ids: query.regional_project_id,
    region_ids: regionId,
    customer_search: query.search,
    implementation_years: [+year],
    status: query.status
  };

  const setFilterValue = useCallback(
    (value, name) => {
      const queryObj = {
        [name]: value ?? undefined,
        page: 0
      };
      if (name === 'year') {
        queryObj.expenses_direction_id = undefined;
        queryObj.regional_project_id = undefined;
      }
      return changeQuery(queryObj);
    },
    [changeQuery]
  );

  useEffect(() => {
    setFilterValue(year, 'year');
  }, [year, setFilterValue]);

  const regionalProjectsFilter = useMemo(() => {
    return {
      region_ids: regionId,
      has_pof: true,
      implementation_years: filter.implementation_years
    };
  }, [regionId, filter.implementation_years]);
  const regionalProjectsQuery = useRetriableRegionalProjects(
    regionalProjectsFilter
  );
  const regionalProjects = regionalProjectsQuery.state.data?.data;
  const regionalProjectsSuggestions = useMemoSuggestions(regionalProjects);

  const regionalProject = regionalProjects?.find(
    ({ id }) => id === filter.regional_project_ids
  );

  const expensesDirectionsFilter = useMemo(() => {
    return {
      regional_project_ids: regionalProject?.id,
      implementation_years: filter.implementation_years,
      region_ids: regionId ?? undefined,
      exists_in_mbt_by_subjects: !regionId || undefined,
      exists_in_pof_links: !regionId || undefined
    };
  }, [regionalProject, regionId, filter.implementation_years]);

  const expensesDirectionsQuery = useRetriableExpensesDirectionsFilter(
    expensesDirectionsFilter
  );
  const expensesDirections = useMemoSuggestionsFull(
    expensesDirectionsQuery.state.data
  );

  const grbsesQuery = useRetriableGrbses({
    region_ids: regionalProject?.region_id || regionId
  });
  const grbses = useMemoSuggestionsFull(grbsesQuery.state.data);

  const filterComponent = (
    <div>
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Год"
        name="year"
        suggestions={yearsSuggestions}
        value={year}
        variant="standard"
        isClearable={false}
      />
      <div
        style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end' }}
      >
        <AutocompleteBaseInput
          onChange={setFilterValue}
          hasCopyBtn={false}
          label="Региональный проект"
          name="regional_project_id"
          suggestions={regionalProjectsSuggestions}
          suggestionsLoading={regionalProjectsQuery.check.spinner}
          value={filter.regional_project_ids}
          variant="standard"
        />
        <AutocompleteBaseInput
          onChange={setFilterValue}
          hasCopyBtn={false}
          label="Направление расходов"
          name="expenses_direction_id"
          suggestions={expensesDirections}
          suggestionsLoading={expensesDirectionsQuery.check.spinner}
          value={filter.expenses_direction_ids}
          resetIfNotExists={true}
          variant="standard"
        />
        <AutocompleteBaseInput
          onChange={setFilterValue}
          hasCopyBtn={false}
          label="ГРБС"
          name="grbs_id"
          suggestions={grbses}
          suggestionsLoading={grbsesQuery.check.spinner}
          value={filter.grbs_ids}
          resetIfNotExists={true}
          variant="standard"
        />
        <AutocompleteBaseInput
          onChange={setFilterValue}
          hasCopyBtn={false}
          label="Статус"
          name="status"
          suggestions={Purchase.status.getOptions()}
          value={filter.status}
          variant="standard"
        />
        <SearchForm
          style={{ margin: '0 28px 1.5rem 0' }}
          changeQuery={changeQuery}
          initialInput={query.search}
          label={'Поиск по заказчику'}
        />
      </div>
    </div>
  );
  return [filterComponent, filter];
};
