import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { GET_QUERY_RP_IMPLEMENTATION_FORECAST } from '../../graphql/queries/rpImplementationForecasts';

const rpImplementationForecastQueryOptions = {
  query: GET_QUERY_RP_IMPLEMENTATION_FORECAST,
  mapData: (result) => result.rpImplementationForecast,
  skipQuery: skipIfNull(null)
};

export function useRpImplementationForecast(filter, networkOnly = false) {
  return useRetriableGraphql({
    queryOptions: rpImplementationForecastQueryOptions,
    variables: { ...filter },
    options: {
      apolloOptions: {
        fetchPolicy: networkOnly ? 'network-only' : 'cache-first'
      }
    }
  });
}
