import React from 'react';
import { Prompt } from 'react-router-dom';
import { FastField, Form } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import className from 'classnames';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { FormHeader } from '../../../../common/Form/Form';
import { getName } from '../../../../utils/data/label';
import { ParentalAssistanceGuideline } from '../../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import SubTable from '../../../../common/SubTable/SubTable';
import { ResultPart } from '../../parts/ResultPart';
import { ProcedureOrganizationalPart } from '../../parts/ProcedureOrganizationalPart';
import { UniversalDocumentCell } from '../../common/FileUpload/DownloadFileInput';
import { NewAutocompleteInput } from '../../../../common/InputsNew/NewAutocompleteInput';
import { TextInput } from '../../../../common/Inputs/TextInput';
import s from '../../ParentalAssistanceGuidelines.module.scss';

export default function ParentalAssistanceGuidelinesOrganizationalEditForm({
  onBackClick,
  isSubmitting,
  status,
  values,
  setValue,
  nonCommercialOrganization,
  touched,
  errors
}) {
  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <Form noValidate>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting}
          isSubmitting={isSubmitting}
          classWrapper={s.FormHeader}
        />
        <SubTable>
          <TableRow>
            <TableCell
              className={className(s.description, s.attention)}
              colSpan={2}
            >
              <p>
                Внимание! Работа в форме (заполнение, сохранение, утверждение
                данных) должна производиться в одной открытой вкладке браузера
                одним пользователем. Одновременная работа нескольких сессий
                может привести к перезаписи данных, которые были заполнены в
                другой вкладке или сохранены другим пользователем
              </p>
            </TableCell>
          </TableRow>
        </SubTable>
        <SubTable title="Общие сведения" editVariant>
          <TableRow>
            <TableCell>Организация</TableCell>
            <TableCell>{getName(nonCommercialOrganization)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Статус</TableCell>
            <TableCell>
              <NewAutocompleteInput
                name="status"
                suggestions={ParentalAssistanceGuideline.status.getOptions()}
                label={'Статус'}
                hasCopyBtn={false}
                fullWidth
              />
            </TableCell>
          </TableRow>
        </SubTable>
        <SubTable
          title="I. Нормативно - правовое регулирование порядка оказания услуг"
          editVariant
        >
          <UniversalDocumentCell
            name={'list_of_local_service_regulations'}
            title={
              '1. Перечень локальных нормативных актов, регламентирующих порядок предоставления услуги. Представьте копии указанных нормативных актов.'
            }
          >
            <TableRow>
              <TableCell className={s.question}>Комментарий</TableCell>
              <TableCell>
                <FastField
                  name="list_of_local_service_regulations_description"
                  component={TextInput}
                  label="Комментарий (при наличии)"
                  multiline
                  fullWidth
                />
              </TableCell>
            </TableRow>
          </UniversalDocumentCell>
          <UniversalDocumentCell
            name={'list_of_regional_service_regulations'}
            title={
              '2. Перечень региональных и (или) муниципальных нормативных актов, регламентирующих порядок предоставления услуги на территории осуществления деятельности организации/Службы (при наличии). Представьте копии указанных нормативных актов.\n'
            }
          >
            <TableRow>
              <TableCell className={s.question}>Комментарий</TableCell>
              <TableCell>
                <FastField
                  name="list_of_regional_service_regulations_description"
                  component={TextInput}
                  label="Комментарий (при наличии)"
                  multiline
                  fullWidth
                />
              </TableCell>
            </TableRow>
          </UniversalDocumentCell>
        </SubTable>
        <ProcedureOrganizationalPart
          values={values}
          setValue={setValue}
          touched={touched}
          errors={errors}
        />
        <ResultPart values={values} touched={touched} errors={errors} />
      </Form>
    </>
  );
}
