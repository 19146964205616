import { useStore } from '@proscom/prostore-react';
import { useUserRoleByPermissions } from '../../../../utils/hooks/useUserRoleByPermissions';
import { STORE_AUTH } from '../../../names';
import { COMMENT_TYPES } from '../../commentEntities';
import { useModifyDirectionComments } from '../../useModifyDirectionComments';

export const useDirectionComments = ({ infralistId, directionId, type }) => {
  const [auth] = useStore(STORE_AUTH);
  const user = auth.authData.user;
  const { getRole } = useUserRoleByPermissions();
  const role = getRole(user);
  const commentType =
    type && [COMMENT_TYPES.GENERAL, role?.type].includes(type)
      ? type
      : role?.type;

  const {
    createCommentary,
    updateCommentary,
    deleteCommentary
  } = useModifyDirectionComments(commentType);

  const onCreate = (data) => {
    return createCommentary({
      content: data.content,
      infralist_id: infralistId,
      direction_id: directionId,
      type: commentType,
      user_id: user?.id
    });
  };

  const onUpdate = (data) => {
    return updateCommentary({
      id: data.id,
      content: data.content,
      infralist_id: infralistId,
      direction_id: directionId,
      type: commentType
    });
  };

  return {
    deleteCommentary,
    createCommentary: onCreate,
    updateCommentary: onUpdate,
    commentType
  };
};
