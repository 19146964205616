import React, { useMemo } from 'react';
import PageTitle from '../../common/PageTitle/PageTitle';
import Table from '../../common/Table/Table';
import {
  QueryPagination,
  useQueryPagination,
  useQueryPaginationCount
} from '../../common/usePagination';
import { useUrlQuery } from '../../common/useUrlQuery';
import { useCurrentUser } from '../../store/useCurrentUser';
import { useForms } from '../../store/form/formQueries';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';

const columns = [
  {
    label: 'Имя',
    key: 'name',
    isWide: true,
    isBold: true
  }
];

const title = 'Формы';

export default function FillFormsPage({ match, location, history }) {
  const user = useCurrentUser();
  const [query, changeQuery] = useUrlQuery(location, history);
  const pagination = useQueryPagination(query, changeQuery, 50);

  const itemsQuery = useForms({ canFill: true }, pagination.params);

  useQueryPaginationCount(pagination, itemsQuery);
  const itemsData = itemsQuery.state.data;
  const data = useMemo(() => {
    if (!itemsData) {
      return null;
    }
    const { list, header } = itemsData;
    return list.map((user, iEntity) => ({
      ...user,
      order: 1 + iEntity + header.page * header.onePage
    }));
  }, [itemsData]);

  return (
    <>
      <HeadTitle title={title} />
      <PageTitle withBackBtn={false} title={title} />
      <Table
        columns={columns}
        data={data}
        isLoading={itemsQuery.check.spinner}
        renderNoData={() => <h3>Нет доступных форм</h3>}
        rowRedirectRoute={`${match.url}/`}
        pagination={<QueryPagination pagination={pagination} />}
      />
    </>
  );
}
