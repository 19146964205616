import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';

export const CancelButton = React.memo(
  ({ onClick, size = 'small', ...props }) => (
    <IconButton aria-label="cancel" onClick={onClick} {...props}>
      <CancelIcon fontSize={size} />
    </IconButton>
  )
);
