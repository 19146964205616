import React, { useCallback } from 'react';
import { SearchForm } from '../../common/SearchForm/SearchForm';
import { User } from '../../store/role/User';
import { useCurrentUser } from '../../store/useCurrentUser';

export const useUsersPageFilter = (query, changeQuery) => {
  const user = useCurrentUser();

  const filter = {
    search: query.search,
    creator_user_id: user?.role !== User.Role.ADMIN ? user?.id : undefined
  };

  const filterComponent = (
    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end' }}>
      <SearchForm
        changeQuery={changeQuery}
        initialInput={filter.search}
        label="Поиск по пользователям"
      />
    </div>
  );
  return [filterComponent, filter];
};
