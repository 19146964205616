import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import s from './EditButton.module.scss';

export const EditButton = ({ editLink }) => {
  return (
    <Tooltip title="Редактировать">
      <Link to={editLink}>
        <IconButton color="primary" classes={{ root: s.IconButton }}>
          <EditIcon />
        </IconButton>
      </Link>
    </Tooltip>
  );
};
