import gql from 'graphql-tag';

export const FRAGMENT_SUBSIDIARY_MONITORING_ENTITY_SS = gql`
  fragment subsidiaryMonitoringEntitySS on SubsidiaryEntityType {
    id
    name
    name_full
    inn
    kpp
    base_organization_name
    base_organization {
      id
      inn
      kpp
      name_short
      name_full
    }
    owner_roadmap {
      id
      name
      name_full
      participating_in_staff_training
    }
    region {
      id
      name
    }
  }
`;

export const FRAGMENT_SUBSIDIARY_MONITORING_ENTITY_BASE = gql`
  fragment subsidiaryMonitoringEntityBase on SubsidiaryEntityMonitoringType {
    id
    status
    subsidiary_entity {
      ...subsidiaryMonitoringEntitySS
    }
  }

  ${FRAGMENT_SUBSIDIARY_MONITORING_ENTITY_SS}
`;

export const FRAGMENT_SUBSIDIARY_MONITORING_ENTITY_FULL = gql`
  fragment subsidiaryMonitoringEntityFull on SubsidiaryEntityMonitoringType {
    analytical_information
    id
    status
    year
    month
    subsidiary_entity {
      ...subsidiaryMonitoringEntitySS
      address
      index
      region_string
      locality
      street
      house
      building
      latitude
      longitude
      supervisor {
        first_name
        surname
        patronymic
        emails
        phones
      }
    }
    indicators {
      note
      plan_value
      value
      components {
        component {
          id
          name
          name_full
          code
          precision
          fill_type
          unit {
            name_local
          }
        }
        value
      }
      roadmap_indicator {
        id
        name
        name_full
        formula
        precision
        unit {
          name_local
        }
        fill_type
      }
    }
    trainings {
      amount
      date
      hours
      name
      note
      organization_name
    }
    additional_fields {
      additional_field {
        id
        data_type
        name
        name_full
        is_link
        suggestion
      }
      note
      value_date
      value_number
      value_string
    }
    location_note
    organization_head_email
    organization_head_name
    organization_head_note
    organization_head_patronymic
    organization_head_phone
    organization_head_surname
    subsidiary_entity_head_note
    comment_subsidiary_entity_address
    comment_subsidiary_entity_name
    comment_subsidiary_entity_head_surname
    comment_subsidiary_entity_head_name
    comment_subsidiary_entity_head_patronymic
    comment_subsidiary_entity_head_phone
    comment_subsidiary_entity_head_email
    comment_organization_head_surname
    comment_organization_head_name
    comment_organization_head_patronymic
    comment_organization_head_phone
    comment_organization_head_email
  }

  ${FRAGMENT_SUBSIDIARY_MONITORING_ENTITY_SS}
`;

export const QUERY_SUBSIDIARY_MONITORING_PAGE = gql`
  query subsidiaryEntityMonitoringPage(
    $input: SubsidiaryEntityMonitoringPageInput
  ) {
    subsidiaryEntityMonitoringPage(input: $input) {
      header {
        hasNext
        onePage
        page
        totalCount
      }
      list {
        ...subsidiaryMonitoringEntityBase
      }
    }
  }

  ${FRAGMENT_SUBSIDIARY_MONITORING_ENTITY_BASE}
`;

export const QUERY_SUBSIDIARY_MONITORING_ENTITY = gql`
  query subsidiaryEntityMonitoring($input: SubsidiaryEntityMonitoringSearch!) {
    subsidiaryEntityMonitoring(args: $input) {
      ...subsidiaryMonitoringEntityFull
    }
  }

  ${FRAGMENT_SUBSIDIARY_MONITORING_ENTITY_FULL}
`;

export const QUERY_SUBSIDIARY_MONITORING_EXPORT_HISTORY = gql`
  query subsidiaryEntityMonitoringExportHistory($filter: ExportHistoryFilter!) {
    list: subsidiaryEntityMonitoringExportHistory(filter: $filter) {
      export_date
      hash_string
      c_user {
        user {
          id
          name
          login
        }
      }
    }
  }
`;
