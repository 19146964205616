import {
  MUTATION_CREATE_EQUIPMENT_COMMENTARY,
  MUTATION_DELETE_EQUIPMENT_COMMENTARY,
  MUTATION_UPDATE_EQUIPMENT_COMMENTARY
} from '../../graphql/mutations/comment';
import { clearApolloQueryCache } from '../../utils/apollo';
import { INFRALIST_QUERIES } from './infralistQueries';

export const useModifyEquipmentComments = (client, equipmentId, type) => {
  const refetchQueries =
    type === 'rvpo'
      ? [INFRALIST_QUERIES.RVPO_EQUIPMENT_RVPO_COMMENTARIES]
      : type === 'fpo'
      ? [INFRALIST_QUERIES.RVPO_EQUIPMENT_FPO_COMMENTARIES]
      : type === 'general'
      ? [INFRALIST_QUERIES.RVPO_EQUIPMENT_GENERAL_COMMENTARIES]
      : [INFRALIST_QUERIES.RVPO_EQUIPMENT_RVPO_COMMENTARIES];

  const createCommentary = (data) => {
    return client.mutate({
      mutation: MUTATION_CREATE_EQUIPMENT_COMMENTARY,
      variables: {
        input: data
      },
      refetchQueries
    });
  };

  const updateCommentary = (data) => {
    return client.mutate({
      mutation: MUTATION_UPDATE_EQUIPMENT_COMMENTARY,
      variables: {
        input: data
      },
      refetchQueries,
      update: (store) => clearApolloQueryCache(store, refetchQueries)
    });
  };

  const deleteCommentary = (id) => {
    return client.mutate({
      mutation: MUTATION_DELETE_EQUIPMENT_COMMENTARY,
      variables: {
        id
      },
      refetchQueries,
      update: (store) => clearApolloQueryCache(store, refetchQueries)
    });
  };

  return {
    createCommentary,
    deleteCommentary,
    updateCommentary
  };
};
