import { useState } from 'react';
import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { toast } from 'react-toastify';
import { MUTATION_INFRALIST_COMPLETE_CHECKING } from '../../../graphql/mutations/infralist';
import { INFRALIST_QUERIES } from '../infralistQueries';
import {
  getGraphQLErrorInfo,
  showGraphqlErrorToast
} from '../../../graphql/graphqlErrors';
import { useDialog } from '../../../utils/hooks/useDialog';
import { clearApolloQueryCache } from '../../../utils/apollo';

export const useCompleteCheckInfralist = (infralistId) => {
  const [loading, setLoading] = useState(false);
  const [loadingWithoutEquipment, setLoadingWithoutEquipment] = useState(false);
  const client = useContextApolloClient();
  const {
    isOpen: isCompleteCheckPopupVisible,
    open: openCompleteCheckPopup,
    close: closeCompleteCheckPopup
  } = useDialog();

  const {
    isOpen: isCompleteCheckPopupVisibleWithoutEquipment,
    open: openCompleteCheckPopupWithoutEquipment,
    close: closeCompleteCheckPopupWithoutEquipment
  } = useDialog();

  const catchCompleteSignInfralistErrors = (graphqlError) => {
    const errorInfo = getGraphQLErrorInfo(graphqlError);
    const completeError =
      'Для завершения проверки необходимо проставить статусы всем позициям оборудования';
    // Привязываемся к тексту ошибки
    const catchErrors = ['Not all rvpo equipment is checked in section'];
    if (catchErrors.includes(errorInfo?.message)) {
      toast.error(completeError);
    } else {
      showGraphqlErrorToast(graphqlError);
    }
  };

  const completeCheckInfralist = (isEmptyCheck) => {
    if (isEmptyCheck || typeof isEmptyCheck === 'undefined') {
      setLoading(true);
    } else {
      setLoadingWithoutEquipment(true);
    }
    clearApolloQueryCache(client.store.getCache(), [
      INFRALIST_QUERIES.INFRALIST,
      INFRALIST_QUERIES.DIRECTIONS_LIST,
      INFRALIST_QUERIES.DIRECTION,
      INFRALIST_QUERIES.DIRECTION_SECTIONS,
      INFRALIST_QUERIES.SECTION,
      INFRALIST_QUERIES.EQUIPMENT_LIST
    ]);
    return client
      .mutate({
        mutation: MUTATION_INFRALIST_COMPLETE_CHECKING,
        variables: {
          infralist_id: infralistId,
          is_empty_check: isEmptyCheck
        },
        refetchQueries: [
          INFRALIST_QUERIES.INFRALIST,
          INFRALIST_QUERIES.DIRECTIONS_LIST,
          INFRALIST_QUERIES.DIRECTION,
          INFRALIST_QUERIES.DIRECTION_SECTIONS,
          INFRALIST_QUERIES.SECTION,
          INFRALIST_QUERIES.EQUIPMENT_LIST
        ]
      })
      .catch(catchCompleteSignInfralistErrors)
      .finally(() => {
        if (isEmptyCheck || typeof isEmptyCheck === 'undefined') {
          setLoading(false);
        } else {
          setLoadingWithoutEquipment(false);
        }
        closeCompleteCheckPopupWithoutEquipment();
        closeCompleteCheckPopup();
      });
  };

  return {
    completeCheckInfralist,
    completeCheckLoading: loading,
    completeCheckLoadingWithoutEquipment: loadingWithoutEquipment,
    isCompleteCheckPopupVisible,
    isCompleteCheckPopupVisibleWithoutEquipment,
    openCompleteCheckPopupWithoutEquipment,
    openCompleteCheckPopup,
    closeCompleteCheckPopupWithoutEquipment,
    closeCompleteCheckPopup
  };
};
