import React, { useCallback, useMemo } from 'react';
import { useContextStore } from '@proscom/prostore-react';
import { noop } from 'lodash-es';
import InputGroup from '../../../../common/Inputs/InputGroup';
import { CustomFastField } from '../../../../common/Inputs/CustomFastField';
import TableInput from '../../../../common/Table/TableInput';
import { EMDASH } from '../../../../utils/constants';
import { TableAutocompleteWrapper } from '../../../../common/Inputs/TableAutocompleteAsyncWrapper';
import { useCurrentUser } from '../../../../store/useCurrentUser';
import { tryParseIso } from '../../../../utils/date';

export const purchaseInfralistColumns = (getItem = noop) => {
  const convertInfralistSuggestions = (data) => {
    return (
      data.infralistSectionRvpoEquipments?.list.map(
        ({ id, name_rvpo, equipment_status }) => ({
          value: id,
          label: name_rvpo,
          equipment_status: equipment_status?.name
        })
      ) || []
    );
  };

  return [
    {
      editComponent: ({ ...props }) => (
        <TableAutocompleteWrapper
          {...props}
          getItem={getItem}
          convertQuerySuggestion={convertInfralistSuggestions}
        />
      ),
      render: ({ purchase_equipment }) => {
        const label = purchase_equipment.label;
        return label ? label : EMDASH;
      },
      field: 'purchase_equipment',
      title: 'Наименование оборудования (РВПО)'
    },
    {
      editComponent: ({ rowData = {} }) => {
        const { purchase_equipment } = rowData;
        if (purchase_equipment?.equipment_status) {
          return <div>{purchase_equipment.equipment_status}</div>;
        }
        return null;
      },
      render: ({ purchase_equipment }) => {
        if (purchase_equipment?.equipment_status) {
          return <div>{purchase_equipment.equipment_status}</div>;
        }
        return null;
      },
      field: 'purchase_equipment_status',
      title: 'Статус'
    }
  ];
};

export function PurchaseInfralistEditField({ title, name, year }) {
  const user = useCurrentUser();
  const currentRegion = user.region;
  const purchaseStore = useContextStore('STORE_PURCHASE');
  const yearParsed = tryParseIso(year);

  const getItem = useCallback(
    (paginationFilter) => {
      const filter = {
        ...paginationFilter.filter
      };
      if (currentRegion) {
        filter.region_id = currentRegion.id;
      }
      if (yearParsed) {
        filter.year = yearParsed.getFullYear();
      }
      return purchaseStore.getInfralistsSuggestion({
        ...paginationFilter,
        filter
      });
    },
    [currentRegion, purchaseStore, yearParsed]
  );

  const column = useMemo(() => {
    return purchaseInfralistColumns(getItem);
  }, [getItem]);

  return (
    <InputGroup>
      <CustomFastField
        name={name}
        columns={column}
        component={TableInput}
        tableName={title}
      />
    </InputGroup>
  );
}
