import React, { useMemo } from 'react';
import BreadCrumbs, {
  loadingBreadcrumb
} from '../../../common/BreadCrumbs/BreadCrumbs';
import { useRegionalProject } from '../../../store/regionalProject/useRegionalProject';
import { getProjectBreadCrumbs } from '../../projects/project/ProjectBreadCrumbs';

export function getRoadmapBreadCrumbs({ projectId, projectName }) {
  if (projectId) {
    return [
      ...getProjectBreadCrumbs(),
      {
        label: loadingBreadcrumb(projectName),
        to: `/projects/${projectId}`
      },
      {
        label: 'Дорожные карты',
        to: `/projects/${projectId}?tab=roadmaps`
      }
    ];
  }

  return [
    {
      label: 'Реестр дорожных карт',
      to: '/roadmaps'
    }
  ];
}

export const RoadmapBreadCrumbs = ({ projectId }) => {
  const projectQuery = useRegionalProject(projectId);
  const project = projectQuery.state.data;

  const breadCrumbsLinks = useMemo(() => {
    return getRoadmapBreadCrumbs({
      projectId,
      projectName: project?.name
    });
  }, [project, projectId]);

  return <BreadCrumbs links={breadCrumbsLinks} />;
};
