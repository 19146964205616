import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRetriableGraphql } from '../../../common/useRetriableGraphqlVars';
import { GET_INFRALIST_NOTIFICATION_COUNT } from '../../../graphql/queries/notifications';

const incomingNotificationsOptions = {
  query: GET_INFRALIST_NOTIFICATION_COUNT,
  mapData: (data) => data.amountOfUnreadInfralistNotifications
};

export function useInfralistNotificationsCount() {
  return useRetriableGraphql({
    queryOptions: incomingNotificationsOptions,
    options: {
      apolloOptions: {
        fetchPolicy: 'network-only'
      }
    }
  });
}

export function useInfralistNotificationsCountWithInterval(infralistId) {
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const client = useContextApolloClient();
  const intervalId = useRef();

  const fetch = useCallback(() => {
    return client
      .query({
        ...incomingNotificationsOptions,
        fetchPolicy: 'network-only',
        variables: {
          infralist_id: infralistId
        }
      })
      .then((response) => {
        setCount(response?.data?.amountOfUnreadInfralistNotifications ?? 0);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [client, infralistId]);

  useEffect(() => {
    setIsLoading(true);
    fetch();
    intervalId.current = window.setInterval(fetch, 5000);
    return () => {
      if (intervalId.current) {
        window.clearInterval(intervalId.current);
      }
    };
  }, [fetch]);

  return {
    isNotificationCountLoading: isLoading,
    count,
    update: fetch,
    setCount
  };
}
