import { Prompt } from 'react-router-dom';
import { Form } from 'formik';
import React, { useMemo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useContextStore } from '@proscom/prostore-react';
import { toast } from 'react-toastify';
import {
  compareFinancial,
  formatCurrency,
  sumNumbersNullish
} from '../../../../utils/number';
import { Pof } from '../../../../store/pof/Pof';
import { useCurrentUser } from '../../../../store/useCurrentUser';
import { queryLoaderInline } from '../../../../common/QueryLoader';
import { useGroupedPurchaseScheduleSum } from '../../../../store/purchase/useGroupedPurchaseScheduleSum';
import { ActionButton } from '../../../../common/Form/ActionButton';
import { STORE_POF } from '../../../../store/names';
import SubTable from '../../../../common/SubTable/SubTable';
import InputGroup from '../../../../common/Inputs/InputGroup';
import { FormHeader } from '../../../../common/Form/Form';
import { showGraphqlErrorToast } from '../../../../graphql/graphqlErrors';
import { Signal } from '../../../../common/Inputs/Signals';
import { PofStatus } from '../PofStatus';
import { FileActionButton } from '../../../../common/Form/FileInputButton';
import { PofXlsx } from '../../../../store/pof/PofXlsx';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { PofAuth } from '../../../../store/pof/PofAuth';
import {
  usePofFinances,
  usePofFinancesTotal
} from '../../../../store/pof/pofQueries';
import { useRetriableExpensesDirections } from '../../../../store/expensesDirection/useExpensesDirections';
import { useMemoSuggestionsFull } from '../../../../utils/suggestions';
import { parseXlsxFile } from '../../../../utils/data/xlsx';
import { NewAutocompleteInput } from '../../../../common/InputsNew/NewAutocompleteInput';
import { NewTextInput } from '../../../../common/InputsNew/NewTextInput';
import { hideImport } from '../../../../config';
import { getName } from '../../../../utils/data/label';
import { User } from '../../../../store/role/User';
import { PofQuartersFields } from './PofQuartersFields';

function ButtonImportXlsx({ onSelectFile }) {
  return (
    <FileActionButton
      onSelectFile={onSelectFile}
      buttonProps={{ variant: 'text' }}
      inputProps={{
        accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }}
    >
      Импорт из XLSX
    </FileActionButton>
  );
}

export const PofEditForm = ({
  onBackClick,
  formHeaderData,
  isSubmitting,
  status,
  editor,
  values,
  createForm,
  pofRegionalDocument,
  setValues
}) => {
  const pofStore = useContextStore(STORE_POF);
  const groupedPurchaseScheduleSumQuery = useGroupedPurchaseScheduleSum(
    values,
    formHeaderData.regionId,
    'month'
  );
  const groupedPurchaseScheduleSum = groupedPurchaseScheduleSumQuery.state.data;

  const financesQuery = usePofFinances(values);
  const finances = financesQuery.state.data;

  const expensesDirectionsQuery = useRetriableExpensesDirections({
    regional_project_ids: formHeaderData.projectId,
    implementation_years: [+formHeaderData.year]
  });
  const expensesDirections = useMemoSuggestionsFull(
    expensesDirectionsQuery.state.data
  );

  const expensesDirectionsLoadStatus = expensesDirectionsQuery.check.spinner;

  const user = useCurrentUser();

  const availableStatuses = useMemo(
    () => PofAuth.getAvailableStatuses(pofRegionalDocument, user),
    [pofRegionalDocument, user]
  );
  const statusOptions = Pof.status.useOptions(availableStatuses);

  const canSeeTotalFinances = PofAuth.canSeeTotalFinances(
    pofRegionalDocument,
    user
  );
  const canEditStatus = statusOptions.length > 0;
  const canEditValues = PofAuth.canEditValues(pofRegionalDocument, user);

  const financesTotalQuery = usePofFinancesTotal(canSeeTotalFinances && values);
  const financesTotal = financesTotalQuery.state.data;

  const total = sumNumbersNullish(...values.values.map((v) => v.value));

  const isDifferenceMbtTotal =
    compareFinancial(finances?.mbt?.plan, total) !== 0;

  const handleImportXlsx = async (files) => {
    const fields = {};

    if (createForm) {
      fields.expenses_direction_id = expensesDirections;
    }

    if (canEditValues) {
      fields.values = true;
    }

    if (canEditStatus) {
      fields.status = true;
      fields.comment_type = true;
    }

    return await parseXlsxFile(files, {
      parseWorkbook: PofXlsx.parseXlsxPof,
      getUpdates: (data) => PofXlsx.getUpdates(data, fields),
      applyUpdates: (updates) => setValues({ ...values, ...updates })
    });
  };

  const loadActualPof = () => {
    return pofStore
      .getActualPof(
        values.expenses_direction_id,
        formHeaderData.year,
        formHeaderData.regionId
      )
      .then((pofRegionalDocument) => {
        if (!pofRegionalDocument) {
          toast.error(
            'Утвержденные данные за предыдущие месяцы этого года не найдены'
          );
        } else {
          const actualPof = Pof.toForm(pofRegionalDocument);
          setValues({
            ...values,
            values: actualPof.values
          });
        }
      })
      .catch((error) => showGraphqlErrorToast(error));
  };

  const blockSaving =
    isDifferenceMbtTotal && user && user.role === User.Role.REGION_ADMIN;

  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <Form noValidate>
        <FormHeader
          onBackClick={onBackClick}
          disabled={blockSaving || isSubmitting}
          editor={editor}
          isSubmitting={isSubmitting}
          entityName={`Документ`}
          actions={
            !hideImport && <ButtonImportXlsx onSelectFile={handleImportXlsx} />
          }
        />
        <SubTable title="Общие сведения" editVariant>
          <TableRow>
            <TableCell>Субъект Российской Федерации</TableCell>
            <TableCell>{formHeaderData.regionName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Год заполнения</TableCell>
            <TableCell>{formHeaderData.year}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Месяц заполнения</TableCell>
            <TableCell>{formHeaderData.monthName}</TableCell>
          </TableRow>
          {!createForm && (
            <>
              <TableRow>
                <TableCell>Автор</TableCell>
                <TableCell>{formHeaderData.author}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Региональный проект</TableCell>
                <TableCell>{getName(formHeaderData.federalProject)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Результат регионального проекта</TableCell>
                <TableCell>{formHeaderData.result}</TableCell>
              </TableRow>
            </>
          )}
          {!createForm && !canEditStatus && (
            <>
              <TableRow>
                <TableCell>Статус</TableCell>
                <TableCell>
                  <PofStatus status={formHeaderData.status} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Комментарий ФПО</TableCell>
                <TableCell>{formHeaderData.fpoComment}</TableCell>
              </TableRow>
            </>
          )}
        </SubTable>
        {canEditStatus && (
          <InputGroup>
            <NewAutocompleteInput
              name="status"
              label="Статус"
              suggestions={statusOptions}
              fullWidth
              required
            />
            <NewTextInput
              name="comment_fpo"
              multiline
              label="Примечание (причины, обоснование)"
              fullWidth
              required
            />
          </InputGroup>
        )}
        <InputGroup>
          <NewAutocompleteInput
            name="expenses_direction_id"
            label="Направление расходов"
            suggestionsLoading={expensesDirectionsLoadStatus}
            suggestions={expensesDirections}
            fullWidth
            required
            isDisabled={!createForm}
          />
          {canEditValues && (
            <ActionButton
              onClick={loadActualPof}
              title="Загрузить последние утвержденные данные"
              disabled={!values.expenses_direction_id}
            />
          )}
        </InputGroup>
        <SubTable editVariant>
          <TableRow>
            <TableCell>
              Общий объем средств МБТ из федерального бюджета
            </TableCell>
            <TableCell>
              {queryLoaderInline(financesQuery) ||
                Pof.formatData(finances, finances?.mbt?.plan)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Сумма за все кварталы</TableCell>
            <TableCell>
              {formatCurrency(total)}
              {isDifferenceMbtTotal && (
                <Signal>
                  Имеется отклонение общего объема средств МБТ из федерального
                  бюджета от суммы за все кварталы
                </Signal>
              )}
            </TableCell>
          </TableRow>
        </SubTable>
        <PofQuartersFields
          name="values"
          monthValues={values.values}
          canEditValues={canEditValues}
          groupedPurchaseScheduleSum={groupedPurchaseScheduleSum}
        />
        <SubTable>
          <TableRow>
            <TableCell>
              Израсходовано средств бюджета субъекта РФ (кассовый расход)
              (федеральный бюджет)
            </TableCell>
            <TableCell>
              {queryLoaderInline(financesQuery) ||
                Pof.formatData(finances, finances?.mbt?.otfg_kr_total)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Сумма заключенных государственных контрактов, договоров, в том
              числе муниципальных договоров и контрактов (федеральный бюджет)
            </TableCell>
            <TableCell>
              {queryLoaderInline(financesQuery) ||
                Pof.formatData(finances, finances?.purchases.contract_summ_sfb)}
            </TableCell>
          </TableRow>
          {canSeeTotalFinances && (
            <>
              <TableRow>
                <TableCell>
                  Израсходовано средств бюджета субъекта РФ (кассовый расход)
                  (федеральный бюджет + бюджет субъекта РФ)
                </TableCell>
                <TableCell>
                  {queryLoaderInline(financesTotalQuery) ||
                    Pof.formatData(
                      financesTotal,
                      financesTotal?.total?.cash_expenses
                    )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Сумма заключенных государственных контрактов, договоров, в том
                  числе муниципальных договоров и контрактов (федеральный бюджет
                  + бюджет субъекта РФ)
                </TableCell>
                <TableCell>
                  {queryLoaderInline(financesTotalQuery) ||
                    Pof.formatData(
                      financesTotal,
                      sumNumbersNullish(
                        financesTotal?.purchases.contract_summ_sfb,
                        financesTotal?.purchases.contract_summ_skbsrf
                      )
                    )}
                </TableCell>
              </TableRow>
            </>
          )}
        </SubTable>
      </Form>
    </>
  );
};
