import { isEqual, pick } from 'lodash-es';
import {
  endOfWeek,
  getQuarter,
  isBefore,
  isValid,
  isWithinInterval,
  parseISO
} from 'date-fns';
import {
  compareFinancial,
  formatCurrency,
  subtractNumbers,
  sumNumbersNullish,
  zeroToNull
} from '../../utils/number';
import { setFieldSignal } from '../../utils/data/signals';
import { addWeekToDateString } from '../../utils/data/date';
import { NEQ } from '../../utils/constants';
import { applyDefaults } from '../../utils/applyDefaults';
import { User } from '../role/User';
import { makeOptionator } from '../../utils/data/label';
import { EnumHelpers } from '../../utils/EnumHelpers';
import { formatDateIso, reformatDate } from '../../utils/date';

const PolyFieldType = {
  String: 5,
  Ref: 8
};

const purchaseCreateFields = [
  // Поля модели
  'regional_project_id',
  'expenses_direction_id',
  'grbs_id',
  'grbs_string',
  'customer_id',
  'customer_id_type',
  'customer_type',
  'customer_string',
  'item',
  'supplier_inn',
  'supplier_kpp',
  'supplier_name',
  'supplier_determination_id',
  'financing_total',
  'financing_sfb',
  'financing_skbsrf',
  'financing_mb',
  'financing_vb',
  'contract_summ_total',
  'contract_summ_sfb',
  'contract_summ_skbsrf',
  'contract_summ_mb',
  'contract_summ_vb',
  'deviation_reason',
  'application_date_planned_start',
  'application_date_planned_end',
  'application_date_fact',
  'eis_publication_planned_start',
  'eis_publication_planned_end',
  'eis_publication_fact',
  'eis_number',
  'purchase_link',
  'contract_sign_planned_start',
  'contract_sign_planned_end',
  'contract_sign_fact',
  'contract_summ_domestic',
  'delivery_planned',
  'description',
  'rvpo_equipment_ids',

  // Поля инпута
  'application_date_changes',
  'eis_publication_changes',
  'contract_sign_changes',
  'purchase_stages',
  'payment_schedules',
  'equipment_and_software',
  'purchase_components',
  'year',
  'cancel_status',
  'fulfilled_obligations_fb',
  'fulfilled_obligations_rb',
  'fulfilled_obligations_mb',
  'fulfilled_obligations_vb'
];

const purchaseChangesMapper = ({ date_start, reason }, index) => ({
  date_start,
  date_end: addWeekToDateString(date_start),
  reason,
  change_id: index + 1,
  line_number: index + 1
});

const purchaseChangesContractMapper = ({ date_start, reason }, index) => ({
  ...purchaseChangesMapper({ date_start, reason }, index),
  date_end: date_start
});

const purchaseStagesMapper = (
  { date_end, date_start, tableData, sum_plan, sum_fact, __typename, ...rest },
  index
) => ({
  date_start,
  date_end,
  sum_plan,
  sum_fact,
  ...rest,
  stage_id: index + 1,
  line_number: index + 1
});

const purchaseComponentsMapper = ({
  amount,
  cost,
  cost_domestic,
  purchase_component_category_id
}) => ({
  amount,
  cost,
  cost_domestic: cost_domestic || 0,
  purchase_component_category_id
});

const paymentSchedulesMapper = (
  { type_payment, date_execution_payment, plan, fact, diff_fact_plan, reason },
  index
) => ({
  type_payment,
  date_execution_payment,
  plan,
  fact,
  diff_fact_plan,
  reason,
  payment_schedule_id: index + 1,
  line_number: index + 1
});

const nullableFields = [];

const dateFieldsWithFact = {
  application_date: {
    startName: 'application_date_planned_start',
    endName: 'application_date_planned_end',
    factName: 'application_date_fact'
  },
  eis_publication: {
    startName: 'eis_publication_planned_start',
    endName: 'eis_publication_planned_end',
    factName: 'eis_publication_fact'
  },
  contract_sign: {
    startName: 'contract_sign_planned_end',
    endName: 'contract_sign_planned_end',
    factName: 'contract_sign_fact'
  }
};
const deviationFieldsWithText = {
  financing_deviation_sfb:
    'Средства из федерального бюджета в рамках цены контракта/договора превышают средства федерального бюджета в рамках начальной (максимальной) цены контракта',
  financing_deviation_skbsrf:
    'Средства из бюджета субъекта РФ в рамках цены контракта/договора превышают средства бюджета субъекта РФ в рамках начальной (максимальной) цены контракта',
  financing_deviation_vb:
    'Внебюджетные средства субъекта РФ в рамках цены контракта/договора превышают внебюджетные средства субъекта РФ в рамках начальной (максимальной) цены контракта',
  financing_deviation_total:
    'Цена контракта/договора превышает начальную (максимальную) цену контракта'
};

const factDateEpicSignalMessage = 'Фактический срок меньше планового';
const factDateIntervalSignalMessage =
  'Фактический срок не попадает в плановые сроки';
const lastQuarterMessage =
  'Высокие риски несвоевременного кассового исполнения';

export class Purchase {
  static equipmentAndSoftware = {
    NoRF: 'NoRF',
    NotApplicable: 'NotApplicable',
    RF: 'RF'
  };

  static Status = {
    CONTRACTED: 'CONTRACTED',
    PLANNED: 'PLANNED',
    CANCELED: 'CANCELED',
    PARTIALLY_CANCELED: 'PARTIALLY_CANCELED',
    PUBLISHED: 'PUBLISHED'
  };

  static status = new EnumHelpers(this.Status, {
    CONTRACTED: 'Законтрактована',
    PLANNED: 'Запланирована',
    CANCELED: 'Расторгнута',
    PARTIALLY_CANCELED: 'Частично расторгнута',
    PUBLISHED: 'Объявлена'
  });

  static CancelStatus = {
    CANCELED: 'CANCELED',
    PARTIALLY_CANCELED: 'PARTIALLY_CANCELED'
  };

  static cancelStatus = new EnumHelpers(this.CancelStatus, {
    CANCELED: 'Расторгнута',
    PARTIALLY_CANCELED: 'Частично расторгнута'
  });

  static getEquipmentAndSoftware(status) {
    switch (status) {
      case Purchase.equipmentAndSoftware.NoRF:
        return 'Не РФ';
      case Purchase.equipmentAndSoftware.RF:
        return 'РФ';
      case Purchase.equipmentAndSoftware.NotApplicable:
        return 'Не относится';
      default:
        return;
    }
  }

  static equipmentAndSoftwareOptions = [
    Purchase.equipmentAndSoftware.NoRF,
    Purchase.equipmentAndSoftware.NotApplicable,
    Purchase.equipmentAndSoftware.RF
  ].map(makeOptionator(Purchase.getEquipmentAndSoftware));

  static canConfirmForDate(user) {
    return User.hasPermission(user, 'purchase.confirm_actuality');
  }

  static canCrud(user) {
    return User.hasPermission(user, 'purchase.crud');
  }

  static getGrbsName(purchase) {
    return purchase.grbs ? purchase.grbs.name_full : purchase.grbs_string || '';
  }

  static getCustomerNameDisplay(purchase) {
    return purchase.customer
      ? purchase.customer.name_full
      : purchase.customer_grbs
      ? purchase.customer_grbs.name_full
      : purchase.customer_string
      ? purchase.customer_string
      : this.getGrbsName(purchase);
  }

  static getSupplierDeterminationInfo(purchase) {
    return purchase.supplier_determination
      ? {
          supplier_determination_name: purchase.supplier_determination.name,
          supplier_determination_law_name:
            purchase.supplier_determination.law &&
            purchase.supplier_determination.law.name
        }
      : {};
  }

  static getUrl(purchase) {
    return (
      `/projects/${purchase.regional_project_id}` +
      `/expenseGroups/${purchase.expenses_direction_id}` +
      `/purchases/${purchase.id}`
    );
  }

  static getShortUrl(purchase) {
    return `/purchases/${purchase.id}`;
  }

  static calcDerived(purchase) {
    const financing_total = sumNumbersNullish(
      purchase.financing_sfb,
      purchase.financing_skbsrf,
      purchase.financing_mb,
      purchase.financing_vb
    );
    const contract_summ_total = sumNumbersNullish(
      purchase.contract_summ_sfb,
      purchase.contract_summ_skbsrf,
      purchase.contract_summ_mb,
      purchase.contract_summ_vb
    );
    const financing_deviation_sfb = subtractNumbers(
      purchase.financing_sfb,
      purchase.contract_summ_sfb
    );
    const financing_deviation_skbsrf = subtractNumbers(
      purchase.financing_skbsrf,
      purchase.contract_summ_skbsrf
    );
    const financing_deviation_mb = subtractNumbers(
      purchase.financing_mb,
      purchase.contract_summ_mb
    );
    const financing_deviation_vb = subtractNumbers(
      purchase.financing_vb,
      purchase.contract_summ_vb
    );
    const financing_deviation_total = subtractNumbers(
      financing_total,
      contract_summ_total
    );

    const fulfilled_obligations =
      sumNumbersNullish(
        purchase.fulfilled_obligations_fb,
        purchase.fulfilled_obligations_rb,
        purchase.fulfilled_obligations_mb,
        purchase.fulfilled_obligations_vb
      ) || 0;

    return {
      financing_total,
      contract_summ_total,
      financing_deviation_sfb,
      financing_deviation_skbsrf,
      financing_deviation_mb,
      financing_deviation_vb,
      financing_deviation_total,
      contract_sign_planned_start: purchase.contract_sign_planned_end,
      fulfilled_obligations
    };
  }

  static toForm({
    financing_vb,
    contract_summ_sfb,
    contract_summ_skbsrf,
    contract_summ_mb,
    contract_summ_vb,
    version,
    purchase_components,
    rvpo_equipments,
    year,
    ...purchase
  }) {
    const rvpoEquipments = (rvpo_equipments || []).map((item) => ({
      purchase_equipment: {
        value: item.id,
        label: item.name_rvpo,
        equipment_status: item.equipment_status?.name
      }
    }));

    const purchaseWithContractNullables = {
      ...purchase,
      ...Purchase.getSupplierDeterminationInfo(purchase),
      customer_string: purchase.customer_string || '',
      grbs_string: purchase.grbs_string || '',
      financing_vb: zeroToNull(financing_vb),
      contract_summ_sfb: zeroToNull(contract_summ_sfb),
      contract_summ_skbsrf: zeroToNull(contract_summ_skbsrf),
      contract_summ_mb: zeroToNull(contract_summ_mb),
      contract_summ_vb: zeroToNull(contract_summ_vb),
      customer_name_display: Purchase.getCustomerNameDisplay(purchase),
      version: version + 1,
      rvpo_equipments: rvpoEquipments,
      year: year?.toString(),
      cancel_status: Purchase.cancelStatus.getExisting(purchase.status)
    };

    return {
      purchase_components: purchase_components || [],
      ...purchaseWithContractNullables,
      ...Purchase.calcDerived(purchaseWithContractNullables)
    };
  }

  static fromForm({
    application_date_changes,
    eis_publication_changes,
    contract_sign_changes,
    payment_schedules,
    purchase_stages,
    grbs_id,
    grbs_string,
    customer_id,
    customer_id_type,
    customer_string,
    customer,
    purchase_components,
    rvpo_equipments,
    year,
    ...purchase
  }) {
    const grbsObj = grbs_id
      ? {
          grbs_id,
          grbs_string: ''
        }
      : {
          grbs_id: null,
          grbs_string
        };
    const customerObj = customer_id
      ? {
          customer_id,
          customer_type: PolyFieldType.Ref,
          customer_string: '',
          customer_id_type
        }
      : {
          customer_id: null,
          customer_type: PolyFieldType.String,
          customer_string: customer_string,
          customer_id_type: null
        };
    let convertedPurchase = {
      ...purchase,

      ...grbsObj,
      ...customerObj,

      ...Purchase.calcDerived(purchase),

      application_date_changes: application_date_changes
        ? application_date_changes.map(purchaseChangesMapper)
        : [],
      eis_publication_changes: eis_publication_changes
        ? eis_publication_changes.map(purchaseChangesMapper)
        : [],
      contract_sign_changes: contract_sign_changes
        ? contract_sign_changes.map(purchaseChangesContractMapper)
        : [],
      payment_schedules: payment_schedules
        ? payment_schedules.map(paymentSchedulesMapper)
        : [],
      purchase_stages: purchase_stages
        ? purchase_stages.map(purchaseStagesMapper)
        : null,
      purchase_components:
        purchase_components?.map(purchaseComponentsMapper) || [],
      rvpo_equipment_ids:
        rvpo_equipments
          ?.map((item) => item.purchase_equipment?.value)
          .filter((x) => x) || [],
      year: year ? +year : undefined
    };

    convertedPurchase = applyDefaults(convertedPurchase, {
      financing_total: 0,
      financing_sfb: 0,
      financing_skbsrf: 0,
      financing_mb: 0,
      financing_vb: 0,

      contract_summ_total: 0,
      contract_summ_sfb: 0,
      contract_summ_skbsrf: 0,
      contract_summ_mb: 0,
      contract_summ_vb: 0,

      fulfilled_obligations: 0,
      fulfilled_obligations_fb: 0,
      fulfilled_obligations_rb: 0,
      fulfilled_obligations_mb: 0,
      fulfilled_obligations_vb: 0
    });

    nullableFields.forEach((fieldName) => {
      if (convertedPurchase[fieldName] === null) {
        delete convertedPurchase[fieldName];
      }
    });

    return convertedPurchase;
  }

  static filterUpdateFields(data) {
    return pick(data, ['id', ...purchaseCreateFields]);
  }

  static filterCreateFields(data) {
    return pick(data, purchaseCreateFields);
  }

  static checkSignals(purchase) {
    const signals = { fields: {} };
    Purchase.checkFactDateSignals(purchase, signals);
    Purchase.checkApplicationDateStartSignal(
      purchase,
      signals,
      'application_date_planned_start'
    );
    Purchase.checkDeviationSignals(purchase, signals);
    Purchase.checkScheduleSignals(purchase, signals);
    return signals;
  }

  static checkFactDateSignals(purchase, signals) {
    // eslint-disable-next-line no-unused-vars
    for (const { startName, endName, factName } of Object.values(
      dateFieldsWithFact
    )) {
      const isoDates = [
        purchase[startName],
        purchase[endName],
        purchase[factName]
      ].map((date) => parseISO(date));
      if (isoDates.some((date) => !isValid(date))) {
        continue;
      }
      const [start, end, fact] = isoDates;
      if (isBefore(start, end) || isEqual(start, end)) {
        if (factName === 'contract_sign_fact') {
          if (fact < end) {
            setFieldSignal(signals, factName, {
              text: factDateEpicSignalMessage
            });
          }
        } else if (
          !isWithinInterval(fact, {
            start,
            end
          })
        ) {
          setFieldSignal(signals, factName, {
            text: factDateIntervalSignalMessage
          });
        }
      }
    }
  }

  static checkApplicationDateStartSignal(purchase, signals, fieldName) {
    const date = parseISO(purchase[fieldName]);
    if (isValid(date) && getQuarter(date) === 4) {
      setFieldSignal(signals, fieldName, {
        text: lastQuarterMessage
      });
    }
  }

  static checkDeviationSignals(purchase, signals) {
    const derived = Purchase.calcDerived(purchase);
    // eslint-disable-next-line no-unused-vars
    for (const [field, text] of Object.entries(deviationFieldsWithText)) {
      const value = derived[field];
      if (value < 0) {
        setFieldSignal(signals, field, {
          text
        });
      }
    }
  }

  static checkScheduleSignals(purchase, signals) {
    const { contract_summ_sfb, payment_schedules } = purchase;
    const sum = Number(
      payment_schedules
        ? payment_schedules
            .map((pay) => pay.plan)
            .reduce((acc, val) => +acc + +val, 0)
        : 0
    );

    if (
      contract_summ_sfb > 0 &&
      compareFinancial(sum, contract_summ_sfb) !== 0
    ) {
      setFieldSignal(signals, 'payment_schedules', {
        text:
          `Имеется отклонение плановой суммы по федеральному бюджету от цены контракта/договора по федеральному бюджету` +
          ` (${formatCurrency(sum)} ${NEQ} ${formatCurrency(
            contract_summ_sfb
          )})`
      });
    }
  }

  static getNearestFridayDate() {
    const end = endOfWeek(new Date(), { weekStartsOn: 1 });
    const [day, month, year] = reformatDate(formatDateIso(end)).split('.');

    return [day - 2, month, year].join('.');
  }
}
