import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fab from '@material-ui/core/Fab';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useInfralistNotificationsCountWithInterval } from '../../../../store/infralist/notifications/useInfralistNotificationsCount';
import { useSortPager } from '../../../../utils/hooks/useSortPager';
import { ComponentWithTooltip } from '../../../ComponentWithTooltip/ComponentWithTooltip';
import { NotificationPanel } from '../../../NotificationPanel/NotificationPanel';
import s from './HeaderNotifications.module.scss';

export const HeaderNotifications = ({ infralistId }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const oldCount = useRef(0);
  const animationTimeOut = useRef();
  // Анимация иконки при добавлении новых уведомлений
  const [isAnimated, setIsAnimated] = useState(false);
  const { pagination, loadMore, setFilter, filter, setPage } = useSortPager({
    itemsPerPage: 10,
    initialParams: {
      filter: {
        entity_ids: [infralistId],
        entity_types: 'Infralist'
      }
    }
  });

  const { count, setCount } = useInfralistNotificationsCountWithInterval(
    infralistId
  );

  useEffect(() => {
    if (count > oldCount.current) {
      setIsAnimated(true);
      animationTimeOut.current = window.setTimeout(() => {
        setIsAnimated(false);
      }, 2000);
    }
    oldCount.current = count;

    return () => window.clearTimeout(animationTimeOut.current);
  }, [count]);

  const toggleNotificationPanel = () => {
    setIsOpened(!isOpened);
    setTooltipVisible(false);

    if (isOpened) {
      setPage(0);
    }
  };
  const closeNotificationPanel = () => {
    setIsOpened(false);
    setPage(0);
  };

  const isFirefox = window.navigator.userAgent
    .toLocaleLowerCase()
    .includes('firefox');

  return (
    <ClickAwayListener onClickAway={closeNotificationPanel}>
      <div>
        <div className={s.notificationContainer}>
          <Fab
            onClick={toggleNotificationPanel}
            className={classNames(s.notificationButton, {
              [s.active]: isOpened
            })}
          >
            <ComponentWithTooltip
              // в firefox подсказка вылазит за границу экрана
              placement={isFirefox ? 'bottom-end' : 'bottom'}
              isTooltipExists={!!count && !isOpened}
              open={!!count && tooltipVisible}
              title={
                <span className={s.tooltipText}>
                  У Вас есть новые уведомления
                </span>
              }
              className={s.tooltipWrapper}
            >
              <NotificationsNoneIcon
                className={classNames(s.notificationIcon, {
                  _animated: isAnimated
                })}
              />
              {count && count !== 0 ? (
                <span className={s.notificationsNotRead} />
              ) : null}
            </ComponentWithTooltip>
          </Fab>
        </div>
        <div
          className={classNames(s.popup, {
            _opened: isOpened
          })}
        >
          {isOpened && (
            <NotificationPanel
              quantityNew={count ?? 0}
              infralistId={infralistId}
              pagination={pagination}
              filter={filter}
              setFilter={setFilter}
              loadMore={loadMore}
              setCount={setCount}
              onClose={closeNotificationPanel}
            />
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};
