import React from 'react';
import * as Yup from 'yup';
import { isValid } from 'date-fns';
import isNil from 'lodash-es/isNil';
import { Indicator } from '../../store/indicator/Indicator';
import { AdditionalData } from '../../store/additionalData/AdditionalData';
import { checkString } from '../string';
import {
  ParentalAssistanceGuideline,
  resultPartGuideLine
} from '../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import { Purchase } from '../../store/purchase/Purchase';
import { isNumberLike } from '../helpers';
import { ContestDocsMonitoringEntity } from '../../store/contestDocsMonitoring/ContestDocsMonitoringEntity';
import { ContestDocsForSSMonitoringEntity } from '../../store/contestDocsForSSMonitoring/ContestDocsForSSMonitoringEntity';
import { SubsidiaryMonitoringEntity } from '../../store/subsidiaryMonitoring/SubsidiaryMonitoringEntity';
import { toFixed } from '../number';

export const requiredMessage = 'Поле должно быть заполнено';
export const numberTypeError = 'Поле должно содержать числовое значение';
export const dateTypeError =
  'Значение должно соответствовать формату: ДД.ММ.ГГГГ';
const getMaxLengthMessage = (length) =>
  `Поле должно содержать не более ${length} символов`;
const requiredFileMessage = 'Прикрепите файл';
const negativeValueMessage = 'Значение должно быть положительным';
const gtZeroValueMessage = 'Значение должно быть больше нуля';
const endDateMessage = 'Дата окончания должна быть не меньше даты начала';
const emailMessage = 'Введите корректный адрес электронной почты';
const urlMessage = 'Введите корректный адрес сайта';
//const factDateMessage = 'Фактическая дата должна попадать в указанный интервал';

export const validationMessages = {
  requiredMessage,
  negativeValueMessage,
  gtZeroValueMessage,
  endDateMessage,
  emailMessage,
  urlMessage
};

export function lazyValidation(cb) {
  return Yup.lazy((values) => cb(values));
}

const validateEndDate = (startDate) =>
  isValid(startDate)
    ? Yup.date().min(startDate, endDateMessage)
    : Yup.string().nullable();

const validateStringField = (field, checkFunc = (val) => val, errorMessage) => {
  return Yup.string()
    .nullable()
    .when(field, {
      is: checkFunc,
      then: Yup.string().required(errorMessage || requiredMessage),
      otherwise: Yup.string()
    });
};
const validateArrayField = (field, checkFunc = (val) => val, errorMessage) => {
  return Yup.array()
    .nullable()
    .when(field, {
      is: checkFunc,
      then: Yup.array()
        .required(errorMessage || requiredMessage)
        .min(1),
      otherwise: Yup.array()
    });
};
const validateNumberField = (field, checkFunc = (val) => val) => {
  return Yup.number()
    .nullable()
    .when(field, {
      is: checkFunc,
      then: Yup.number().required(requiredMessage).typeError(numberTypeError),
      otherwise: Yup.number().typeError(numberTypeError)
    });
};
const validateNumberWithPrecision = (precisionField) => {
  return Yup.string()
    .test('isNumberLike', numberTypeError, (value) => {
      return isNil(value) || isNumberLike(value);
    })
    .when([precisionField, 'fill_type'], (precision, fill_type, schema) => {
      if (fill_type === 'CALCULATED' || !isNumberLike(precision)) return schema;

      return schema.test({
        name: 'digitsPrecision',
        message: `Количество знаков после запятой должно быть равным ${precision}`,
        test: (value) => {
          if (!isNumberLike(value) || +value === 0) return true;
          const digits = value.toString().split('.')[1];
          return (digits?.length || 0) === precision;
        }
      });
    });
};

const validateNumber = Yup.number().nullable().typeError(numberTypeError);

const validateStringRequired = Yup.string()
  .required(requiredMessage)
  .nullable();

const FulfilledObligationsError = 'Одно из четырех полей должно быть заполнено';
const validateFulfilledObligations = () => {
  return Yup.number().when(['cancel_status', 'fulfilled_obligations'], {
    is: (status, total) =>
      status === Purchase.CancelStatus.PARTIALLY_CANCELED && (total || 0) <= 0,
    then: Yup.number()
      .moreThan(0, FulfilledObligationsError)
      .required(FulfilledObligationsError),
    otherwise: Yup.number().nullable()
  });
};

export const purchaseValidationSchema = Yup.object().shape(
  {
    regional_project_id: Yup.string().required(requiredMessage).nullable(),
    expenses_direction_id: Yup.string().required(requiredMessage).nullable(),
    // отключено по требованию заказчика
    // equipment_and_software: Yup.string().required(requiredMessage).nullable(),
    grbs_string: Yup.string()
      .nullable()
      .when('grbs_id', {
        is: (id) => !id || id.length === 0,
        then: Yup.string().required('Одно из двух полей должно быть заполнено'),
        otherwise: Yup.string()
      }),
    grbs_id: Yup.string()
      .nullable()
      .when('grbs_string', {
        is: (id) => !id || id.length === 0,
        then: Yup.string().required('Одно из двух полей должно быть заполнено'),
        otherwise: Yup.string()
      }),
    customer_string: Yup.string()
      .nullable()
      .when('customer_id', {
        is: (id) => !id || id.length === 0,
        then: Yup.string().required('Одно из трёх полей должно быть заполнено'),
        otherwise: Yup.string()
      }),
    customer_id: Yup.string()
      .nullable()
      .when('customer_string', {
        is: (id) => !id || id.length === 0,
        then: Yup.string().required('Одно из трёх полей должно быть заполнено'),
        otherwise: Yup.string()
      }),
    item: Yup.string().required(requiredMessage).nullable(),
    supplier_determination_id: Yup.string()
      .required(requiredMessage)
      .nullable(),
    financing_sfb: Yup.number().min(0, negativeValueMessage).nullable(),
    financing_skbsrf: Yup.number().min(0, negativeValueMessage).nullable(),
    financing_mb: Yup.number().min(0, negativeValueMessage).nullable(),
    financing_vb: Yup.number().min(0, negativeValueMessage).nullable(),
    contract_summ_sfb: Yup.number().min(0, negativeValueMessage).nullable(),
    contract_summ_skbsrf: Yup.number().min(0, negativeValueMessage).nullable(),
    contract_summ_mb: Yup.number().min(0, negativeValueMessage).nullable(),
    contract_summ_vb: Yup.number().min(0, negativeValueMessage).nullable(),
    application_date_planned_start: Yup.date().nullable(),
    application_date_planned_end: Yup.date()
      .nullable()
      .when('application_date_planned_start', validateEndDate),
    eis_publication_planned_start: Yup.date().nullable(),
    eis_publication_planned_end: Yup.date()
      .nullable()
      .when('eis_publication_planned_start', validateEndDate),
    contract_sign_planned_start: Yup.date().nullable(),
    contract_sign_planned_end: Yup.date().nullable(),
    purchase_link: Yup.string().url('Должно быть ссылкой'),
    delivery_planned: Yup.date().required(requiredMessage).nullable(),

    cancel_status: Yup.string().nullable(),
    fulfilled_obligations_fb: validateFulfilledObligations(),
    fulfilled_obligations_rb: validateFulfilledObligations(),
    fulfilled_obligations_mb: validateFulfilledObligations(),
    fulfilled_obligations_vb: validateFulfilledObligations(),

    eis_number: Yup.number().nullable().typeError(numberTypeError)
  },
  [
    ['customer_string', 'customer_id'],
    ['grbs_string', 'grbs_id']
  ]
);

export const SubsidiaryEntityEditValidationSchema = Yup.object().shape(
  {
    region_id: validateStringRequired,
    owner_roadmap_id: validateStringRequired,
    supervisor: Yup.object()
      .shape({
        surname: validateStringRequired,
        first_name: validateStringRequired,
        patronymic: validateStringRequired,
        phones: Yup.array()
          .of(Yup.string().required(requiredMessage))
          .required(requiredMessage)
          .nullable(),
        emails: Yup.array()
          .of(Yup.string().email(emailMessage).required(requiredMessage))
          .required(requiredMessage)
          .nullable()
      })
      .nullable(),
    se_type_id: validateStringRequired,
    name_full: validateStringRequired,
    is_small_school: Yup.boolean()
      .nullable()
      .when('is_small_school_field_required', {
        is: true,
        then: Yup.boolean().required(requiredMessage)
      }),
    base_organization_id: validateStringField(
      'base_organization_name',
      (name) => !name || name.length === 0,
      'Одно из двух полей должно быть заполнено'
    ),
    base_organization_name: validateStringField(
      'base_organization_id',
      (id) => !id || id.length === 0,
      'Одно из двух полей должно быть заполнено'
    ),
    inn: validateStringRequired,
    kpp: validateStringRequired,
    address: validateStringField(
      'showAddressFields',
      (showAddressFields) => !showAddressFields
    ),
    // building: validateStringField(
    //   ['base_organization_name', 'showAddressFields'],
    //   (name, showAddressFields) => name?.length > 0 || showAddressFields
    // ),
    latitude: validateNumber,
    longitude: validateNumber,
    index: validateStringField(
      ['base_organization_name', 'showAddressFields'],
      (name, showAddressFields) => name?.length > 0 || showAddressFields
    ),
    region_string: validateStringField(
      ['base_organization_name', 'showAddressFields'],
      (name, showAddressFields) => name?.length > 0 || showAddressFields
    ),
    locality: validateStringField(
      ['base_organization_name', 'showAddressFields'],
      (name, showAddressFields) => name?.length > 0 || showAddressFields
    ),
    street: validateStringField(
      ['base_organization_name', 'showAddressFields'],
      (name, showAddressFields) => name?.length > 0 || showAddressFields
    ),
    house: validateStringField(
      ['base_organization_name', 'showAddressFields'],
      (name, showAddressFields) => name?.length > 0 || showAddressFields
    )
  },
  ['base_organization_id', 'base_organization_name']
);

export const indicatorValidationSchema = (indicator, user) => {
  const indicatorSchema = {
    owner_roadmap_indicator_id: Yup.string()
      .required(requiredMessage)
      .nullable(),
    year: Yup.number().required(requiredMessage).nullable(),
    month: Yup.number().required(requiredMessage).nullable(),
    subsidiary_entity_id: Yup.string().required(requiredMessage).nullable()
  };

  if (Indicator.canApprove(user, indicator)) {
    return Yup.object().shape(
      {
        ...indicatorSchema,
        status: Yup.string().required(requiredMessage).nullable(),
        comment_fpo: Yup.string()
          .nullable()
          .when('status', {
            is: (id) => id === Indicator.Status.RETURNED,
            then: Yup.string().required(requiredMessage),
            otherwise: Yup.string()
          })
      },
      [['status']]
    );
  }

  return Yup.object().shape(indicatorSchema);
};

export const FederalEventEditValidationSchema = Yup.object().shape({
  responsible_person: Yup.object().shape({
    emails: Yup.array().of(Yup.string().email(emailMessage)).nullable()
  })
});

export const userValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(emailMessage)
    .test('email-or-login', 'Необходимо задать почту или логин', function (
      value
    ) {
      return !!(this.parent.login || value);
    })
    .nullable(),
  name: Yup.string().required(requiredMessage).nullable(),
  login: Yup.string()
    .test('email-or-login', 'Необходимо задать почту или логин', function (
      value
    ) {
      return !!(this.parent.email || value);
    })
    .nullable(),
  roles: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required('Необходимо задать роль')
    })
  ),
  new_password: Yup.string(),
  repeat_password: Yup.string().when('new_password', {
    is: (password) => checkString(password, false),
    then: Yup.string()
      .required(requiredMessage)
      .oneOf([Yup.ref('new_password')], 'Пароли должны совпадать')
  })
});

export const notificationValidationSchema = Yup.object().shape({
  recipients: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string()
      })
    )
    .nullable()
    .required(requiredMessage),
  content: Yup.string().required(requiredMessage),
  subject: Yup.string().required(requiredMessage)
});

export const classesScheduleValidationSchema = (classesSchedule, user) => {
  const classesScheduleSchema = {
    subsidiary_entity_id: Yup.string().required(requiredMessage).nullable(),
    fact_date: Yup.date().required(requiredMessage).nullable()
  };

  if (AdditionalData.canApprove(user, classesSchedule)) {
    return Yup.object().shape(
      {
        ...classesScheduleSchema,
        status: Yup.string().required(requiredMessage).nullable(),
        comment_fpo: Yup.string()
          .nullable()
          .when('status', {
            is: (id) => id === AdditionalData.Status.RETURNED,
            then: Yup.string().required(requiredMessage),
            otherwise: Yup.string()
          })
      },
      [['status']]
    );
  }

  return Yup.object().shape(classesScheduleSchema);
};

const ParentalAssistanceGuidelinesRegionalBaseFieldValidation = {
  paid_service_delivery_description: validateStringField(
    'paid_service_possibility'
  ),
  maximum_waiting_time: validateNumberField('maximum_waiting_time_exists'),
  problems_in_new_service_introduction_description: validateStringField(
    'problems_in_new_service_introduction_exist'
  ),
  services_quality_control_methodology: validateStringField(
    'services_quality_is_being_controlled'
  ),
  approved_roadmap: validateArrayField(
    'new_service_implementation_roadmap_exist',
    (x) => x,
    requiredFileMessage
  ),
  possible_time_choice: Yup.boolean().nullable().required(requiredMessage),
  possible_format_choice: Yup.boolean().nullable().required(requiredMessage),
  possible_specialist_choice: Yup.boolean()
    .nullable()
    .required(requiredMessage),
  possible_consultation_topic_choice: Yup.boolean()
    .nullable()
    .required(requiredMessage),
  possible_nwh_services_provisioning: Yup.boolean()
    .nullable()
    .required(requiredMessage),
  possible_weekend_services_provisioning: Yup.boolean()
    .nullable()
    .required(requiredMessage)
};

const ParentalAssistanceGuidelinesOrganizationalBaseFieldValidation = {
  paid_consultations_for_parents_description: validateStringField(
    'paid_consultations_for_parents'
  ),
  services_quality_control_methodology: validateStringField(
    'services_quality_is_being_controlled_on_portal',
    (value) => value === ParentalAssistanceGuideline.yesNoSuggestions[1].value
  ),
  problems_in_new_service_introduction_description: validateStringField(
    'problems_in_new_service_introduction_exist'
  ),
  approved_mediaplan: validateArrayField(
    'has_approved_mediaplan',
    (x) => x,
    requiredFileMessage
  )
};

// Добавляем валидацию для гайдлайнов на 3 раздел
for (let filed of resultPartGuideLine) {
  ParentalAssistanceGuidelinesRegionalBaseFieldValidation[
    filed
  ] = Yup.number().required(requiredMessage).nullable();

  ParentalAssistanceGuidelinesOrganizationalBaseFieldValidation[
    filed
  ] = Yup.number().required(requiredMessage).nullable();
}

export const ParentalAssistanceGuidelinesRegionalValidationSchema = Yup.object().shape(
  ParentalAssistanceGuidelinesRegionalBaseFieldValidation
);

export const ParentalAssistanceGuidelinesOrganizationalValidationSchema = Yup.object().shape(
  ParentalAssistanceGuidelinesOrganizationalBaseFieldValidation
);

export const MentoringCoverageSchema = Yup.object().shape({
  comment: Yup.string().max(1000, getMaxLengthMessage(1000)),
  percentage: Yup.number().required(requiredMessage).nullable()
});

export const NewOrganizationParentalValidationSchema = Yup.object().shape({
  region_id: Yup.string().required(requiredMessage)
});

export const ContestDocsMonitoringEntityDraftValidationSchema = Yup.object().shape(
  {
    roadmaps: Yup.array().of(
      Yup.object().shape({
        indicators: Yup.array().of(
          Yup.object().shape({
            value: validateNumberWithPrecision('precision').nullable(),
            components: Yup.array().of(
              Yup.object().shape({
                value: Yup.number().nullable().typeError(numberTypeError)
              })
            )
          })
        )
      })
    )
  }
);
export const ContestDocsMonitoringEntityApprovalValidationSchema = Yup.object().shape(
  {
    analytical_information: Yup.string().required(requiredMessage),
    roadmaps: Yup.array().of(
      Yup.object().shape({
        indicators: Yup.array().of(
          Yup.object().shape({
            value: validateNumberWithPrecision('precision').when('fill_type', {
              is: ContestDocsMonitoringEntity.IndicatorFillType.MANUAL,
              then: Yup.string().required(requiredMessage),
              otherwise: Yup.string().nullable()
            }),
            components: Yup.array().of(
              Yup.object().shape({
                value: Yup.number()
                  .typeError(numberTypeError)
                  .required(requiredMessage)
              })
            )
          })
        )
      })
    )
  }
);
export const ContestDocsMonitoringExportValidationSchema = Yup.object().shape({
  FIO: Yup.string().required(requiredMessage),
  position: Yup.string().required(requiredMessage)
});

export const ContestDocsForSSMonitoringEntityDraftValidationSchema = Yup.object().shape(
  {
    indicators: Yup.array().of(
      Yup.object().shape({
        value: validateNumberWithPrecision('precision').nullable(),
        components: Yup.array().of(
          Yup.object().shape({
            value: Yup.number().nullable().typeError(numberTypeError)
          })
        )
      })
    )
  }
);
export const ContestDocsForSSMonitoringEntityApprovalValidationSchema = Yup.object().shape(
  {
    analytical_information: Yup.string().required(requiredMessage),
    indicators: Yup.array().of(
      Yup.object().shape({
        value: validateNumberWithPrecision('precision').when('fill_type', {
          is: ContestDocsForSSMonitoringEntity.IndicatorFillType.MANUAL,
          then: Yup.string().required(requiredMessage),
          otherwise: Yup.string().nullable()
        }),
        components: Yup.array().of(
          Yup.object().shape({
            value: Yup.number()
              .typeError(numberTypeError)
              .required(requiredMessage)
          })
        )
      })
    )
  }
);
export const ContestDocsForSSMonitoringExportValidationSchema = Yup.object().shape(
  {
    FIO: Yup.string().required(requiredMessage),
    position: Yup.string().required(requiredMessage)
  }
);

export const SubsidiaryMonitoringEntityDraftValidationSchema = Yup.object().shape(
  {
    indicators: Yup.array().of(
      Yup.object().shape({
        value: validateNumberWithPrecision('precision').nullable(),
        components: Yup.array().of(
          Yup.object().shape({
            value: Yup.number().nullable().typeError(numberTypeError)
          })
        )
      })
    ),
    // trainings: Yup.array().of(
    //   Yup.object().shape({
    //     amount: Yup.number().nullable().typeError(numberTypeError),
    //     hours: Yup.number().nullable().typeError(numberTypeError),
    //     date: Yup.date().nullable().typeError(dateTypeError)
    //   })
    // ),
    additional_fields: Yup.array().of(
      Yup.object().shape({
        value_date: Yup.date().nullable().typeError(dateTypeError),
        value_number: Yup.number().nullable().typeError(numberTypeError),
        value_string: Yup.string()
          .nullable()
          .when('is_link', {
            is: true,
            then: Yup.string().url(urlMessage)
          })
      })
    ),
    organization_head_email: Yup.string().nullable().email(emailMessage)
  }
);
export const SubsidiaryMonitoringEntityApprovalValidationSchema = Yup.object().shape(
  {
    analytical_information: Yup.string().required(requiredMessage),
    indicators: Yup.array().of(
      Yup.object().shape({
        value: validateNumberWithPrecision('precision').when('fill_type', {
          is: SubsidiaryMonitoringEntity.IndicatorFillType.MANUAL,
          then: Yup.string().required(requiredMessage),
          otherwise: Yup.string().nullable()
        }),
        note: Yup.string().when(
          ['value', 'plan_value'],
          (value, plan_value, schema) => {
            if (toFixed((value || 0) - (plan_value || 0)) !== 0) {
              return schema.required(
                'Поле должно быть заполнено при наличии отклонения от планового значения индикатора'
              );
            } else return schema;
          }
        ),
        components: Yup.array().of(
          Yup.object().shape({
            value: Yup.number()
              .typeError(numberTypeError)
              .required(requiredMessage)
          })
        )
      })
    ),
    // trainings: Yup.array().of(
    //   Yup.object().shape({
    //     amount: Yup.number()
    //       .typeError(numberTypeError)
    //       .required(requiredMessage),
    //     hours: Yup.number()
    //       .typeError(numberTypeError)
    //       .required(requiredMessage),
    //     date: Yup.string().required(requiredMessage),
    //     name: Yup.string().required(requiredMessage),
    //     organization_name: Yup.string().required(requiredMessage)
    //   })
    // ),
    additional_fields: Yup.array().of(
      Yup.object().shape({
        value_date: Yup.mixed().when('data_type', {
          is: SubsidiaryMonitoringEntity.AditionalFieldDataType.DATE,
          then: Yup.date().required(requiredMessage).typeError(dateTypeError)
        }),
        value_number: Yup.mixed().when('data_type', {
          is: SubsidiaryMonitoringEntity.AditionalFieldDataType.NUMBER,
          then: Yup.number()
            .required(requiredMessage)
            .typeError(numberTypeError)
        }),
        value_string: Yup.string()
          .when('is_link', {
            is: true,
            then: Yup.string().url(urlMessage)
          })
          .when('data_type', {
            is: SubsidiaryMonitoringEntity.AditionalFieldDataType.STRING,
            then: Yup.string().required(requiredMessage)
          })
      })
    ),
    organization_head_email: Yup.string()
      .email(emailMessage)
      .required(requiredMessage),
    organization_head_name: Yup.string().required(requiredMessage),
    organization_head_patronymic: Yup.string().required(requiredMessage),
    organization_head_phone: Yup.string().required(requiredMessage),
    organization_head_surname: Yup.string().required(requiredMessage)
  }
);
export const SubsidiaryMonitoringExportValidationSchema = Yup.object().shape({
  FIO: Yup.string().required(requiredMessage),
  position: Yup.string().required(requiredMessage)
});
