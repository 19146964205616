import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import classNames from 'classnames';
import SubTable from '../../../../common/SubTable/SubTable';
import { getName } from '../../../../utils/data/label';
import s from '../RpImplementationForecastPage.module.scss';

export const ResultForecasts = ({ resultForecasts }) => {
  return (
    <SubTable title="Прогноз по результатам" cols={5}>
      <TableRow>
        <TableCell className={classNames(s.tableCell, s.tableCellMedium)}>
          Региональный проект
        </TableCell>
        <TableCell className={classNames(s.tableCell, s.tableCellMedium)}>
          Название результата
        </TableCell>
        <TableCell className={classNames(s.tableCell, s.tableCellSmall)}>
          Плановое значение по результату на 2020 год
        </TableCell>
        <TableCell className={classNames(s.tableCell, s.tableCellSmall)}>
          Прогнозное значение по результату на 2020 год
        </TableCell>
        <TableCell className={classNames(s.tableCell, s.tableCellLarge)}>
          Обоснование изменений
        </TableCell>
      </TableRow>
      {resultForecasts?.map((resultForecast, iResultForecast) => (
        <TableRow key={iResultForecast}>
          <TableCell className={classNames(s.tableCell, s.tableCellMedium)}>
            {getName(resultForecast.federal_project)}
          </TableCell>
          <TableCell className={classNames(s.tableCell, s.tableCellMedium)}>
            {getName(resultForecast.result_federal)}
          </TableCell>
          <TableCell className={classNames(s.tableCell, s.tableCellLarge)}>
            {resultForecast.plan_value}
          </TableCell>
          <TableCell className={classNames(s.tableCell, s.tableCellLarge)}>
            {resultForecast.forecast_value}
          </TableCell>
          <TableCell className={classNames(s.tableCell, s.tableCellLarge)}>
            {resultForecast.changes_justification}
          </TableCell>
        </TableRow>
      ))}
    </SubTable>
  );
};
