import React, { useCallback, useMemo, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useContextStore } from '@proscom/prostore-react';
import { STORE_FORM } from '../../../store/names';
import { useForm } from '../../../store/form/formQueries';
import BreadCrumbs from '../../../common/BreadCrumbs/BreadCrumbs';
import { queryLoaderWithExistenceCheck } from '../../../common/QueryLoader';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { PageActionsBar } from '../../../common/PageActionsBar/PageActionsBar';
import { getName } from '../../../utils/data/label';
import { EditButton } from '../../../common/Form/EditButton/EditButton';
import { DeleteButtonWithConfirmation } from '../../../common/Form/DeleteButtonWithConfirmation';
import SubTable from '../../../common/SubTable/SubTable';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../graphql/graphqlErrors';
import { Form } from '../../../store/form/Form';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import { getFormBreadCrumbs } from './getFormBreadCrumbs';

function FormFields({ form }) {
  return (
    <SubTable
      title="Поля"
      cols={4}
      head={
        <TableRow>
          <TableCell>№</TableCell>
          <TableCell>Наименование</TableCell>
          <TableCell>Тип</TableCell>
          <TableCell>Сущность</TableCell>
        </TableRow>
      }
    >
      {form.fields.map((field, iField) => {
        return (
          <TableRow key={iField}>
            <TableCell>{+field.order + 1}</TableCell>
            <TableCell>{field.name}</TableCell>
            <TableCell>{Form.inputType.getName(field.input_type)}</TableCell>
            <TableCell>
              {Form.entityClass.getName(field.entity_class)}
            </TableCell>
          </TableRow>
        );
      })}
    </SubTable>
  );
}

export default function FormPage({ match, history }) {
  const itemStore = useContextStore(STORE_FORM);
  const { formId } = match.params;

  const itemQuery = useForm(formId);
  const item = itemQuery.state.data;

  const [isDeleting, setDeleting] = useState(false);

  const deleteItem = useCallback(() => {
    setDeleting(true);
    return itemStore
      .deleteForm(formId)
      .then((result) => {
        setTimeout(() => {
          history.push('/users');
        }, 0);
      })
      .catch((error) => {
        showGraphqlErrorToast(error, mutationErrors.deleteObject);
      })
      .finally(() => setDeleting(false));
  }, [formId, history, itemStore]);

  const breadCrumbs = useMemo(() => getFormBreadCrumbs(), []);

  return (
    <>
      <HeadTitle entity={getName(item)} type={'Форма'} />
      <BreadCrumbs links={breadCrumbs} />
      {queryLoaderWithExistenceCheck(itemQuery) || (
        <>
          <PageTitle title={getName(item)} withBackBtn />
          <PageActionsBar
            editButton={<EditButton editLink={`${match.url}/edit`} />}
            deleteButton={
              <DeleteButtonWithConfirmation
                onDelete={deleteItem}
                isDeleting={isDeleting}
                entityName={'форму'}
              />
            }
          />
          <FormFields form={item} />
        </>
      )}
    </>
  );
}
