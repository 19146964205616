import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import {
  QUERY_POF_FINANCES,
  QUERY_POF_FINANCES_TOTAL,
  QUERY_POF_REGIONAL_DOCUMENT,
  QUERY_POF_REGIONAL_DOCUMENTS
} from '../../graphql/queries/pofs';

const pofFinancesQueryOptions = {
  query: QUERY_POF_FINANCES,
  skipQuery: skipIfNull(null)
};

const pofFinancesTotalQueryOptions = {
  query: QUERY_POF_FINANCES_TOTAL,
  skipQuery: skipIfNull(null)
};

const pofRegionalDocumentQueryOptions = {
  query: QUERY_POF_REGIONAL_DOCUMENT,
  mapData: (result) => result.pofRegionalDocument,
  skipQuery: skipIfNull(null)
};

const pofRegionalDocumentsQueryOptions = {
  query: QUERY_POF_REGIONAL_DOCUMENTS,
  mapData: (result) => result.pofRegionalDocuments,
  skipQuery: skipIfNull(null)
};

function getPofFinancesFilter(pof) {
  return pof && pof.expenses_direction_id
    ? {
        years: pof.year,
        yearMonth: pof.year + '-' + pof.month,
        expensesDirectionIds: pof.expenses_direction_id,
        regionIds: pof.region_id
      }
    : null;
}

export function usePofFinances(pof) {
  return useRetriableGraphql({
    queryOptions: pofFinancesQueryOptions,
    variables: getPofFinancesFilter(pof)
  });
}

export function usePofFinancesTotal(pof) {
  return useRetriableGraphql({
    queryOptions: pofFinancesTotalQueryOptions,
    variables: getPofFinancesFilter(pof)
  });
}

export function usePofRegionalDocument(id) {
  return useRetriableGraphql({
    queryOptions: pofRegionalDocumentQueryOptions,
    variables: id
      ? {
          id
        }
      : null
  });
}

export function usePofRegionalDocuments(filter, sorting) {
  return useRetriableGraphql({
    queryOptions: pofRegionalDocumentsQueryOptions,
    variables: filter
      ? {
          filter,
          sorting
        }
      : null
  });
}
