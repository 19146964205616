import { clearApolloQueryCache } from '../../utils/apollo';
import {
  MUTATION_CREATE_FORM,
  MUTATION_CREATE_FORM_DOCUMENT,
  MUTATION_DELETE_FORM,
  MUTATION_DELETE_FORM_DOCUMENT,
  MUTATION_UPDATE_FORM,
  MUTATION_UPDATE_FORM_DOCUMENT
} from '../../graphql/mutations/forms';
import { Form } from './Form';
import { FormDocument } from './FormDocument';

export class FormStore {
  constructor({ client }) {
    this.client = client;
  }

  queriesToClear = ['forms', 'form', 'formDocuments', 'formDocument'];

  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  updateForm(data) {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_FORM,
      variables: {
        input: Form.filterUpdateFields(data)
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  }

  createForm(data) {
    return this.client.mutate({
      mutation: MUTATION_CREATE_FORM,
      variables: {
        input: Form.filterCreateFields(data)
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  }

  deleteForm = (data) => {
    return this.client.mutate({
      mutation: MUTATION_DELETE_FORM,
      variables: {
        id: data
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  };

  updateFormDocument(data) {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_FORM_DOCUMENT,
      variables: {
        input: FormDocument.filterUpdateFields(data)
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  }

  createFormDocument(data) {
    return this.client.mutate({
      mutation: MUTATION_CREATE_FORM_DOCUMENT,
      variables: {
        input: FormDocument.filterCreateFields(data)
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  }

  deleteFormDocument = (id) => {
    return this.client.mutate({
      mutation: MUTATION_DELETE_FORM_DOCUMENT,
      variables: {
        id
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  };
}
