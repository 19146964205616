import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_EQUIPMENT } from '../../graphql/queries/infralistEquipment';

const queryOptions = {
  query: QUERY_GET_EQUIPMENT,
  mapData: (result) => result.infralistRvpoEquipment,
  skipQuery: skipIfNull(null)
};
export const useEquipmentItem = (id) => {
  const response = useRetriableGraphql({
    queryOptions,
    variables: {
      id
    }
  });

  return response.state.data;
};
