import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { removeLastPathItem } from '../../../../../utils/url';
import { FederalEventEditValidationSchema } from '../../../../../utils/data/validation';
import { getFederalEventBreadCrumbs } from '../getFederalEventBreadCrumbs';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../../graphql/graphqlErrors';
import { queryLoader } from '../../../../../common/QueryLoader';
import { useRoadmapEventWithoutRegion } from '../../../../../store/roadmap/useRoadmapEvent';
import PageTitle from '../../../../../common/PageTitle/PageTitle';
import BreadCrumbs from '../../../../../common/BreadCrumbs/BreadCrumbs';
import { STORE_ROADMAP_EVENT } from '../../../../../store/names';
import { useOtherEditor } from '../../../../../common/Form/useOtherEditor';
import { FederalEvent } from '../../../../../store/roadmap/FederalEvent';
import { FORM_STATUS_SUBMITTED } from '../../../../../utils/data/form';
import { HeadTitle } from '../../../../../common/HeadTitle/HeadTitle';
import { getName } from '../../../../../utils/data/label';
import { FederalEventEditForm } from './FederalEventEditForm';

export default function FederalEventEditPage({ match, history }) {
  const { eventId, roadmapId } = match.params;
  const backLink = removeLastPathItem(match.url);

  const roadmapEventStore = useContextStore(STORE_ROADMAP_EVENT);
  const editor = useOtherEditor('roadmapEvent', eventId);

  const roadmapEventQuery = useRoadmapEventWithoutRegion(eventId, true);
  const event = roadmapEventQuery.state.data;

  console.log({ event });

  const breadCrumbsLinks = useMemo(
    () =>
      getFederalEventBreadCrumbs({
        roadmapId,
        roadmapName: event?.roadmap?.name_full
      }),
    [roadmapId, event]
  );

  return (
    <>
      <HeadTitle
        type="Федеральное мероприятие"
        entity={getName(event)}
        pageType={HeadTitle.PageType.edit}
      />
      <BreadCrumbs links={breadCrumbsLinks} />
      <PageTitle title={getName(event)} />
      {queryLoader(roadmapEventQuery) || (
        <Formik
          validationSchema={FederalEventEditValidationSchema}
          initialValues={FederalEvent.toForm(event)}
          onSubmit={(values, actions) => {
            const event = FederalEvent.fromForm(values);
            roadmapEventStore
              .updateFederalEvent(event)
              .then((result) => {
                actions.setStatus(FORM_STATUS_SUBMITTED);
                history.goBack(
                  FederalEvent.getFederalEventUrl(result.data.result)
                );
              })
              .catch((error) => {
                showGraphqlErrorToast(error, mutationErrors.updateObject);
              })
              .then(() => {
                actions.setSubmitting(false);
              });
          }}
        >
          {(formikProps) => (
            <FederalEventEditForm
              {...formikProps}
              onBackClick={() => history.goBack(backLink)}
              event={event}
              editor={editor}
            />
          )}
        </Formik>
      )}
    </>
  );
}
