import React from 'react';
import { CustomFastField } from '../../../../../common/Inputs/CustomFastField';
import TableInput from '../../../../../common/Table/TableInput';
import InputGroup from '../../../../../common/Inputs/InputGroup';
import { RpImplementationForecast } from '../../../../../store/rpImplementationForecast/RpImplementationForecast';
import { TableTextInput } from '../../../../../common/Inputs/TextInput';
import { getName } from '../../../../../utils/data/label';

const roadmapForecastsColumns = [
  {
    editComponent: ({ rowData }) => {
      return <span>{getName(rowData.federal_project)}</span>;
    },
    title: 'Региональный проект',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellMedium,
    render: ({ result }) => (
      <span>{getName(result?.owner_task?.federal_project)}</span>
    )
  },
  {
    editComponent: ({ rowData }) => {
      return <span>{getName(rowData)}</span>;
    },
    field: 'roadmap_id',
    title: 'Дорожная карта',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellMedium,
    render: (row) => <span>{getName(row)}</span>
  },
  {
    editComponent: TableTextInput,
    field: 'suggestions',
    title: 'Предложения по внесению изменений',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellLarge
  }
];

export const RoadmapForecastsFields = () => {
  return (
    <InputGroup>
      <CustomFastField
        name="roadmap_forecasts"
        columns={roadmapForecastsColumns}
        component={TableInput}
        canAdd={false}
        canDelete={false}
        tableName="Изменение в ДК"
      />
    </InputGroup>
  );
};
