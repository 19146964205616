import gql from 'graphql-tag';

export const QUERY_GET_MENTORING_COVERAGE = gql`
  query getMentoringCoverage($id: String!) {
    mentoringCoverage(id: $id) {
      id
      region_id
      region {
        id
        name
      }
      percentage
      comment
      status
    }
  }
`;

export const QUERY_GET_MENTORING_COVERAGE_BY_REGION = gql`
  query getMentoringCoverageByRegion($regionId: String!) {
    mentoringCoverageByRegionId(region_id: $regionId) {
      id
      region_id
      region {
        id
        name
      }
      percentage
      comment
      status
    }
  }
`;

export const QUERY_GET_MENTORING_COVERAGES = gql`
  query getMentoringCoverages($filter: MentoringCoverageFilter) {
    mentoringCoverageList(filter: $filter) {
      id
      region_id
      region {
        id
        name
      }
      percentage
      comment
      status
    }
  }
`;
