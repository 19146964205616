import { useState } from 'react';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../graphql/graphqlErrors';
import { useDeleteConfirmation } from '../Form/useDeleteConfirmation';

export function useDeleteRow(
  deleteFn,
  entityName,
  onApply = undefined,
  beforeApply = undefined
) {
  const [isDeleting, setDeleting] = useState(false);

  const deleteForm = (id) => {
    setDeleting(true);
    if (beforeApply) {
      setTimeout(beforeApply, 1);
    }
    return deleteFn(id)
      .catch((error) => {
        showGraphqlErrorToast(error, mutationErrors.deleteObject);
        setDeleting(false);
      })
      .finally(() => {
        if (onApply) {
          onApply();
        }

        setDeleting(false);
      });
  };

  const [dialog, openDialog] = useDeleteConfirmation(deleteForm, {
    entityName: entityName
  });

  return [isDeleting, dialog, openDialog];
}
