import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { format } from 'date-fns';
import { useStore } from '@proscom/prostore-react';
import {
  analyzeLink,
  enableForecastForm,
  enableIndicatorForecastForm,
  enableMentoringCoverageForm,
  enableParentalAssistanceGuidelinesForm,
  enablePofRegistry,
  hideCustomForms,
  hideInformation,
  hideSubsidiaryEntities,
  hideUsers,
  isOrgContestDocsForm,
  isRegionalContestDocsForm
} from '../../config';
import { ReactComponent as DrawerLogo } from '../../assets/icons/logo-bw.svg';
import { STORE_AUTH } from '../../store/names';
import { User } from '../../store/role/User';

import { ListItemLink } from './ListItemLink';
import { SidebarSubItemList } from './SidebarSubItemList';
import s from './Sidebar.module.scss';

const isAdmin = (user) => User.isAdmin(user);
const isFederalAdmin = (user) =>
  user?.role === User.Role.FEDERAL_ADMIN || isAdmin(user);
const isRegionAdmin = (user) => user?.role === User.Role.REGION_ADMIN; // || isAdmin(user);
const isOrganizationAdmin = (user) =>
  user?.role === User.Role.ORGANIZATION_ADMIN || isAdmin(user);
const isFederalOrRegionalAdmin = (user) =>
  isFederalAdmin(user) || isRegionAdmin(user);

const locationStartsWith = (prefix) => (match, location) =>
  location.pathname.startsWith(prefix);
const userHasPermission = (...permissions) => (user) =>
  User.hasPermission(user, ...permissions);

const navItems = [
  {
    label: 'Региональные проекты',
    to: '/projects',
    isActive: locationStartsWith('/projects'),
    isShow: userHasPermission('regional_project.access_page')
  },
  {
    label: 'Закупки',
    to: '/purchases',
    isActive: locationStartsWith('/purchases'),
    isShow: userHasPermission('purchase.access_page')
  },
  {
    label: 'Дорожные карты',
    to: '/roadmaps',
    isActive: locationStartsWith('/roadmaps'),
    isShow: userHasPermission('roadmap_event.access_page')
  },
  {
    label: 'Региональные мероприятия',
    to: '/regionalRoadmapEvents',
    isActive: locationStartsWith('/regionalRoadmapEvents'),
    isShow: userHasPermission('regional_event.access_page')
  },
  {
    label: 'Субсидиарные сущности',
    to: '/subsidiaryEntities',
    isActive: locationStartsWith('/subsidiaryEntities'),
    isShow: (user) =>
      !hideSubsidiaryEntities &&
      userHasPermission('subsidiary_entity.access_page')(user)
  },
  {
    label: 'ПОФ',
    to: '/pofs',
    isActive: locationStartsWith('/pofs'),
    isShow: (user) =>
      enablePofRegistry && userHasPermission('pof.access_page')(user)
  },
  {
    label: 'Формы сбора',
    isShow: (user) => isRegionAdmin(user),
    subItems: [
      {
        label: 'Прогноз реализации РП',
        to: '/rpImplementationForecast',
        isActive: locationStartsWith('/rpImplementationForecast'),
        isShow: (user) =>
          enableForecastForm &&
          isRegionAdmin(user) &&
          userHasPermission('rp_forecast.access_page')(user)
      },
      {
        label: 'Прогнозы показателей проекта "Молодые профессионалы"',
        to: '/indicatorForecasts',
        isActive: locationStartsWith('/indicatorForecasts'),
        isShow: (user) =>
          enableIndicatorForecastForm &&
          isRegionAdmin(user) &&
          userHasPermission('rp_forecast.access_page')(user)
      },
      {
        label: 'Перечень вопросов по апробации методических рекомендаций',
        to: '/parentalAssistanceGuidelines',
        isActive: locationStartsWith('/parentalAssistanceGuidelines'),
        isShow: (user) =>
          enableParentalAssistanceGuidelinesForm &&
          isRegionAdmin(user) &&
          userHasPermission(
            'parental_assistance_guideline_regional.access_page'
          )(user)
      },
      {
        label: 'Охват наставничеством',
        to: '/mentoringCoverage',
        isActive: locationStartsWith('/mentoringCoverage'),
        isShow: (user) =>
          enableMentoringCoverageForm &&
          isRegionAdmin(user) &&
          userHasPermission('mentoring_coverage.access_page')(user)
      }
    ]
  },
  // {
  //   label: 'Мониторинг скорости интернета',
  //   to: '/monitoring',
  //   isActive: locationStartsWith('/monitoring'),
  //   isShow: userHasPermission('organization.access_monitoring')
  // },
  {
    label: 'Пользователи',
    to: '/users',
    isActive: locationStartsWith('/users'),
    isShow: (user) =>
      (!hideUsers && userHasPermission('user.crud', 'user.crud_all')(user)) ||
      isAdmin(user)
  },
  {
    label: 'Роли',
    to: '/roles',
    isActive: locationStartsWith('/roles'),
    isShow: (user) =>
      (!hideUsers && userHasPermission('role.crud', 'role.crud_all')(user)) ||
      isAdmin(user)
  },
  {
    label: 'Формы',
    to: '/fillForms',
    isActive: locationStartsWith('/fillForms'),
    isShow: (user) =>
      (!hideCustomForms && userHasPermission('form.access_fill_page')(user)) ||
      isAdmin(user)
  },
  {
    label: 'Управление формами',
    to: '/forms',
    isActive: locationStartsWith('/forms'),
    isShow: (user) =>
      (!hideCustomForms && userHasPermission('form.crud')(user)) ||
      isAdmin(user)
  },
  {
    label: 'Аналитическая подсистема ↗',
    href: analyzeLink,
    isShow: userHasPermission('link_to_analytical_subsystem.access')
  },
  {
    label: 'Ежемесячный мониторинг',
    isShow: (user) => true,
    subItems: [
      {
        label: 'Информация в рамках конкурсной документации',
        to: '/contestDocsMonitoring',
        isActive: locationStartsWith('/contestDocsMonitoring'),
        isShow: userHasPermission(
          (isRegionalContestDocsForm &&
            'monthly_monitoring.tender_documentation.view') ||
            (isOrgContestDocsForm &&
              'monthly_monitoring.tender_documentation_for_ss.view')
        )
      },
      {
        label: 'Форма мониторинга по субсидиарным сущностям',
        to: '/subsidiaryMonitoring',
        isActive: locationStartsWith('/subsidiaryMonitoring'),
        isShow: (user) => {
          return (
            userHasPermission(
              'monthly_monitoring.subsidiary_entity.view',
              'monthly_monitoring.subsidiary_entity.crud',
              'monthly_monitoring.subsidiary_entity.crud_own'
            )(user) ||
            (userHasPermission('monthly_monitoring.subsidiary_entity.view_own')(
              user
            ) &&
              !!user.organization_id)
          );
        }
      }
    ]
  },
  {
    label: 'Информация',
    to: '/information',
    isActive: locationStartsWith('/information'),
    isShow: (user) =>
      !hideInformation && userHasPermission('information.access')(user)
  }
];

const Sidebar = ({ location }) => {
  const [auth] = useStore(STORE_AUTH);
  const date = format(new Date(), 'dd.MM.yyyy');

  const user = auth && auth.authData && auth.authData.user;

  return (
    <Drawer
      classes={{ paper: s.Paper }}
      className={s.Drawer}
      variant="permanent"
      anchor="left"
    >
      <div className={s.Logo}>
        <DrawerLogo className={s.Icon} />
        <p className={s.Text}>Подсистема сбора и консолидации данных</p>
      </div>
      {user && (
        <List component="nav" className={s.Navigation}>
          {navItems.map(({ label, isShow, subItems, ...item }, iItem) => {
            if (subItems) {
              return (
                <SidebarSubItemList
                  key={iItem}
                  label={label}
                  isShow={isShow}
                  subItems={subItems}
                  location={location}
                  user={user}
                  {...item}
                />
              );
            }
            return (
              isShow(user) && (
                <ListItemLink key={iItem} primary={label} {...item} />
              )
            );
          })}
        </List>
      )}
      <div className={s.ActualDate}>
        <p className={s.SmallText}>Дата актуальности данных</p>
        <b className={s.Text}>{date}</b>
      </div>
    </Drawer>
  );
};

export default Sidebar;
