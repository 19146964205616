import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FastField, Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { CircularProgress } from '@material-ui/core';
import { useContextStore, useStore } from '@proscom/prostore-react';

import { TextInput } from '../../common/Inputs/TextInput';
import { STORE_AUTH, STORE_LOGIN } from '../../store/names';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../graphql/graphqlErrors';

import s from './LoginPage.module.scss';

const LoginPageForm = ({ isSubmitting }) => (
  <Form className={s.Form}>
    <h3 className={s.FormTitle}>Войдите в личный кабинет</h3>
    <FastField
      required
      name="email"
      label="Логин"
      component={TextInput}
      autocomplete="username"
      variant="standard"
      InputProps={{
        classes: {
          root: s.Input
        }
      }}
    />
    <FastField
      required
      name="password"
      label="Пароль"
      type="password"
      component={TextInput}
      autocomplete="current-password"
      variant="standard"
    />
    <Button
      classes={{
        root: s.SubmitButton
      }}
      disabled={isSubmitting}
      color="primary"
      variant="contained"
      type="submit"
    >
      {!isSubmitting ? (
        'Войти'
      ) : (
        <CircularProgress size={18} color="secondary" />
      )}
    </Button>
    <p className={s.UserAgreementText}>
      Нажав здесь, вы соглашаетесь с нашим{' '}
      <Link
        component={RouterLink}
        to={'/'}
        color="textSecondary"
        underline="always"
      >
        Пользовательским соглашением
      </Link>
    </p>
  </Form>
);

const LoginPage = ({ history }) => {
  const [, authStore] = useStore(STORE_AUTH);
  const store = useContextStore(STORE_LOGIN);

  return (
    <div>
      <div className={s.Paper}>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={(values, actions) => {
            authStore
              .loginWithEmail(values.email, values.password)
              .then(() => {
                setTimeout(() => {
                  if (store.state.targetPath) {
                    history.push(store.state.targetPath);
                    store.setTargetPath(null);
                  } else {
                    history.push('/');
                  }
                }, 0);
              })
              .catch((e) => showGraphqlErrorToast(e, mutationErrors.login))
              .then(() => {
                actions.setSubmitting(false);
              });
          }}
          render={(formikProps) => <LoginPageForm {...formikProps} />}
        />
      </div>
      {/*<p className={s.OuterLink}>*/}
      {/*  <Link*/}
      {/*    component={RouterLink}*/}
      {/*    to={'/restore'}*/}
      {/*    color="textSecondary"*/}
      {/*    underline="always"*/}
      {/*  >*/}
      {/*    Забыли свой пароль?*/}
      {/*  </Link>*/}
      {/*</p>*/}
    </div>
  );
};

export default LoginPage;
