import { HELLIP } from './constants';

/**
 * Обрезает текст до заданного числа символов +- fuzziness
 * Старается обрезать по пробелу
 *
 * @param text
 * @param maxLength
 * @param fuzziness - количество символов до maxLength, где искать пробел
 */
export function shortenText(text, maxLength = 30, fuzziness = 5) {
  if (!text) {
    return text;
  }
  const textString = text + '';
  if (textString.length > maxLength) {
    let spaceIndex = -1;
    // Находим самый левый из группы самых правых пробельных символов
    for (let i = maxLength - 1; i >= maxLength - fuzziness; i--) {
      if (/\s/.test(textString[i])) {
        spaceIndex = i;
      } else if (spaceIndex !== -1) {
        break;
      }
    }
    if (spaceIndex >= 0) {
      return textString.slice(0, spaceIndex) + HELLIP;
    }
    return textString.slice(0, maxLength) + HELLIP;
  }
  return textString;
}

export function checkString(string, defaultValue = null) {
  if (typeof string === 'string' && string.length > 0) {
    return string;
  }
  return defaultValue;
}

export function removeXMLInvalidChars(str = '') {
  // eslint-disable-next-line no-control-regex
  const regex = /((?:[\x00-\x08\x0B\f\x0E-\x1F\uFFFD\uFFFE\uFFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF]))/g;
  return String(str).replace(regex, '');
}
