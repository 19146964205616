import gql from 'graphql-tag';

export const QUERY_GET_STAFF_SCHEDULE_GUIDELINES = gql`
  query staffScheduleGuidelines($filter: StaffScheduleGuidelineFilterInput) {
    staffScheduleGuidelines(filter: $filter) {
      id
      amount_of_staff
      amount_of_staff_string
      occupation
      staff_category
      comment
      number
    }
  }
`;
