import { enableStaffSchedules } from '../../config';

export const EVENT_TYPE_READONLY = 'readonly';
export const EVENT_TYPE_SIMPLE = 'simple';
export const EVENT_TYPE_SUBSIDIARY_ENTITIES = 'subsidiary_entities';
export const EVENT_TYPE_INFRALIST = 'infralist';
export const EVENT_TYPE_INFRALIST_SS = 'infralist_ss';
export const EVENT_TYPE_OPERATIONAL_EXPENSES = 'operational_expenses';
export const EVENT_TYPE_OUTBUDGET_EXPENSES = 'outbudget_expenses';
export const EVENT_TYPE_PURCHASES = 'purchases';
export const EVENT_TYPE_STAFF = 'staff';
export const EVENT_TYPE_STAFF_SS_TYPE = 'staff_ss_type';
export const EVENT_TYPE_SIMPLE_SS = 'simple_ss';
export const EVENT_TYPE_PHOTOS = 'photos';

export function isStaffSchedules(type) {
  return (
    enableStaffSchedules &&
    (type === EVENT_TYPE_STAFF || type === EVENT_TYPE_STAFF_SS_TYPE)
  );
}

export function isInfralist(type) {
  return type === EVENT_TYPE_INFRALIST || type === EVENT_TYPE_INFRALIST_SS;
}
