import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { createStringifyErrorMessagesLink } from './links/stringifyErrorMessages';
import { createRetryOnNetworkErrorLink } from './links/retryOnNetworkError';
import { createWaitForTokenRefreshLink } from './links/waitForTokenRefresh';
import { createCheckAuthenticationErrorLink } from './links/checkAuthenticationError';
import { createSetAccessTokenContextLink } from './links/setAccessTokenContext';

export function createApolloCache({ restoreCache } = {}) {
  const cache = new InMemoryCache({
    dataIdFromObject: (object) => {
      const defaultCacheKey = defaultDataIdFromObject(object);
      switch (object.__typename) {
        // кэш должен быть уникальный для каждой версии оборудования
        case 'InfralistRvpoEquipmentType': {
          if (object.infralist_version_id) {
            return `${defaultCacheKey}:${object.infralist_version_id}`;
          }
          return defaultCacheKey;
        }
        // кэш должен быть уникальный для каждой записи об изменении оборудования
        case 'InfralistRvpoEquipmentVersionInfoType': {
          return defaultCacheKey
            ? `${defaultCacheKey}:${object.infralist_change_log_id}`
            : null;
        }
        default:
          return defaultCacheKey;
      }
    }
  });

  if (restoreCache) {
    cache.restore(restoreCache);
  }

  return cache;
}

export function createSimpleBrowserClient({ uri }) {
  const stringifyErrorMessages = createStringifyErrorMessagesLink();

  const sendHttpRequest = createHttpLink({
    uri
  });

  return new ApolloClient({
    link: ApolloLink.from([stringifyErrorMessages, sendHttpRequest]),
    cache: createApolloCache()
  });
}

export function createBrowserClientWithAuth({ uri, authStore, wsUri }) {
  const stringifyErrorMessages = createStringifyErrorMessagesLink();
  const retryOnNetworkError = createRetryOnNetworkErrorLink();
  const waitForTokenRefresh = createWaitForTokenRefreshLink(authStore);
  const checkAuthenticationError = createCheckAuthenticationErrorLink(
    authStore
  );
  const setAccessTokenContext = createSetAccessTokenContextLink(authStore);
  const sendHttpRequest = createHttpLink({
    uri
  });

  const wsLink = new WebSocketLink({
    uri: wsUri,
    options: {
      reconnect: true,
      connectionParams: () => {
        return {
          authorization: `Bearer ${authStore.state.authData?.accessToken}`
        };
      }
    }
  });

  return new ApolloClient({
    link: ApolloLink.from([
      stringifyErrorMessages,
      retryOnNetworkError,
      waitForTokenRefresh,
      checkAuthenticationError,
      setAccessTokenContext,
      ApolloLink.split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        sendHttpRequest
      )
    ]),
    cache: createApolloCache()
  });
}
