import React, { useMemo } from 'react';
import { useExpensesDirectionsForProject } from '../../../../store/expensesDirection/useExpensesDirections';
import { queryLoader } from '../../../../common/QueryLoader';
import Table from '../../../../common/Table/Table';
import { useCurrentUser } from '../../../../store/useCurrentUser';
import { Purchase } from '../../../../store/purchase/Purchase';
import { useUrlQuery } from '../../../../common/useUrlQuery';
import { useProjectExpensesDirectionsFilter } from './useProjectExpensesDirectionsFilter';

const expensesDirectionsMapper = (year) => (
  { __typename, name_full, ...rest },
  index
) => {
  return {
    order: index + 1,
    name: name_full,
    queries: {
      year
    },
    ...rest
  };
};

const projectPageColumns = [
  { label: '№', key: 'order', isThin: true },
  { label: 'Код', key: 'code', isThin: true },
  {
    label: 'Направление расходов (мероприятие)',
    key: 'name',
    isWide: true,
    isBold: true
  },
  // todo остаток по ПОФ временно скрыт
  // { label: 'Остаток ПОФ, руб.', key: 'pof_rest' },
  { label: 'Количество закупок', key: 'count_purchases', isThin: true }
];

export const ProjectExpensesDirections = ({
  projectId,
  location,
  history,
  project,
  projectYear
}) => {
  const [query, changeQuery] = useUrlQuery(location, history);
  const [filterComponent, filter] = useProjectExpensesDirectionsFilter(
    changeQuery,
    project,
    projectYear
  );

  const expensesDirectionsQuery = useExpensesDirectionsForProject(
    projectId,
    filter.year,
    filter.year
  );

  const expensesDirections = useMemo(() => {
    return expensesDirectionsQuery.state.data?.map(
      expensesDirectionsMapper(projectYear)
    );
  }, [expensesDirectionsQuery.state.data, projectYear]);

  const user = useCurrentUser();
  const canCrud = Purchase.canCrud(user);

  return (
    queryLoader(expensesDirectionsQuery) || (
      <Table
        addButtonText="Добавить закупку"
        addFieldRoute={`/projects/${projectId}/purchase/create`}
        columns={projectPageColumns}
        className="project-page"
        data={expensesDirections}
        hasAddField={canCrud}
        rowRedirectRoute={`/projects/${projectId}/expenseGroups/`}
        filter={filterComponent}
      />
    )
  );
};
