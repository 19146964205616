import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_SUBSIDIARY_ENTITY_ROADMAP_INDICATORS } from '../../graphql/queries/indicators';

const subsidiaryEntityRoadmapIndicatorsQueryOptions = {
  query: QUERY_SUBSIDIARY_ENTITY_ROADMAP_INDICATORS,
  mapData: (result) => ({
    data: result.subsidiaryEntityRoadmapIndicators
  }),
  skipQuery: skipIfNull(null)
};

export function useSubsidiaryEntityRoadmapIndicators(filter, sorting) {
  return useRetriableGraphqlVars({
    queryOptions: subsidiaryEntityRoadmapIndicatorsQueryOptions,
    variableCreator: [
      () =>
        filter
          ? {
              filter,
              sorting
            }
          : null,
      [filter, sorting]
    ]
  });
}
