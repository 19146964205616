import React from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { removeLastPathItem } from '../../../../utils/url';
import { RoleBreadCrumbs } from '../RoleBreadCrumbs';
import { RoleEditForm } from '../edit/RoleEditForm';
import { ROLE_STORE } from '../../../../store/names';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../graphql/graphqlErrors';
import { Role } from '../../../../store/role/Role';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';

export default function RoleCreatePage({ match, history }) {
  const roleStore = useContextStore(ROLE_STORE);
  const backLink = removeLastPathItem(match.url);

  const initialValues = {
    name: '',
    permissions: []
  };
  return (
    <>
      <HeadTitle type="Роль" pageType={HeadTitle.PageType.create} />
      <RoleBreadCrumbs />
      <h2>Создание роли</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          const user = Role.fromForm(values);
          roleStore
            .createRole(user)
            .then((result) => {
              actions.setStatus(FORM_STATUS_SUBMITTED);
              setTimeout(() => {
                history.push(Role.getUrl(result.data.result));
              }, 0);
            })
            .catch((error) => {
              showGraphqlErrorToast(error, mutationErrors.updateObject);
            })
            .then(() => {
              actions.setSubmitting(false);
            });
        }}
        render={(formikProps) => (
          <RoleEditForm
            {...formikProps}
            onBackClick={() => history.goBack(backLink)}
          />
        )}
      />
    </>
  );
}
