import gql from 'graphql-tag';

export const QUERY_INFRALIST = gql`
  query infralist($id: ID!) {
    infralist(id: $id) {
      id
      roadmap_event {
        id
        name
        roadmap_id
        year
      }
      infralist_status {
        id
        name
      }
      roadmap {
        id
        name
      }
      regional_roadmap_event {
        id
        name
        region_id
      }
      type
      subsidiary_entity {
        id
        name_full
        base_organization_name
        base_organization {
          name_short
          name_full
          id
        }
      }
      editable
      check_re_complete_available
      has_section_without_rvpo_equipments
    }
  }
`;

export const QUERY_INFRALIST_VERSION = gql`
  query infralistVersion($infralist_version_id: ID!) {
    infralistVersion(infralist_version_id: $infralist_version_id) {
      id
      infralist_status {
        id
        name
      }
    }
  }
`;

export const QUERY_INFRALIST_DIRECTIONS = gql`
  query infralistDirections(
    $input: InfralistDirectionPageInput!
    $infralist_id: ID!
  ) {
    infralistDirections(input: $input) {
      list {
        id
        name
        status_change_time(infralist_id: $infralist_id)
        status(infralist_id: $infralist_id) {
          id
          name
        }
        check_status(infralist_id: $infralist_id) {
          id
          name
          updated_at
        }
        approvement_status(infralist_id: $infralist_id) {
          id
          name
        }
        checked_users(infralist_id: $infralist_id) {
          id
          name
        }
        direction_sum(infralist_id: $infralist_id)
        has_commentaries(infralist_id: $infralist_id)
      }
    }
  }
`;

export const QUERY_INFRALIST_DIRECTION = gql`
  query infralistDirection($direction_id: ID!, $infralist_id: ID!) {
    infralistDirection(id: $direction_id) {
      id
      name
      status(infralist_id: $infralist_id) {
        id
        name
      }
      checked_users(infralist_id: $infralist_id) {
        id
        name
      }
      direction_sum(infralist_id: $infralist_id)
      description
      status_change_time(infralist_id: $infralist_id)
      check_status(infralist_id: $infralist_id) {
        id
        name
      }
      approvement_status(infralist_id: $infralist_id) {
        id
        name
      }
    }
  }
`;

export const QUERY_INFRALIST_SECTION = gql`
  query infralistSection($section_id: ID!, $infralist_id: ID!) {
    infralistSection(id: $section_id) {
      id
      name
      section_sum(infralist_id: $infralist_id)
      status_change_time(infralist_id: $infralist_id)
      checked_users(infralist_id: $infralist_id) {
        id
        name
      }
      status(infralist_id: $infralist_id) {
        id
        name
      }
      check_status(infralist_id: $infralist_id) {
        id
        name
      }
      approvement_status(infralist_id: $infralist_id) {
        id
        name
      }
    }
  }
`;

export const QUERY_INFRALIST_DIRECTION_SECTIONS = gql`
  query infralistDirectionSections(
    $input: InfralistSectionPageInput!
    $infralist_id: ID!
  ) {
    infralistDirectionSections(input: $input) {
      list {
        id
        name
        status_change_time(infralist_id: $infralist_id)
        status(infralist_id: $infralist_id) {
          id
          name
        }
        check_status(infralist_id: $infralist_id) {
          id
          name
          updated_at
        }
        approvement_status(infralist_id: $infralist_id) {
          id
          name
        }
        checked_users(infralist_id: $infralist_id) {
          id
          name
        }
        section_sum(infralist_id: $infralist_id)
        has_commentaries(infralist_id: $infralist_id)
      }
    }
  }
`;

export const QUERY_INFRALIST_VERSION_LIST = gql`
  query infralistVersions($filter: InfralistVersionFilterInput) {
    infralistVersions(filter: $filter) {
      id
      created_at
      infralist_status {
        id
        name
      }
    }
  }
`;

export const QUERY_INFRALIST_VERSION_DIRECTIONS = gql`
  query infralistVersionDirections($version_id: ID!) {
    infralistVersionDirections(infralist_version_id: $version_id) {
      id
      name
      status_change_time_by_version(infralist_version_id: $version_id)
      status_by_version(infralist_version_id: $version_id) {
        id
        name
      }
      check_status_by_version(infralist_version_id: $version_id) {
        id
        name
      }
      approvement_status_by_version(infralist_version_id: $version_id) {
        id
        name
      }
      direction_sum_by_version(infralist_version_id: $version_id)
      checked_users_by_version(infralist_version_id: $version_id) {
        id
        name
      }
    }
  }
`;

export const QUERY_VERSION_DIRECTION_SECTIONS = gql`
  query infralistVersionDirectionSections(
    $version_id: ID!
    $direction_id: ID!
  ) {
    infralistVersionDirectionSections(
      infralist_version_id: $version_id
      direction_id: $direction_id
    ) {
      id
      name
      status_change_time_by_version(infralist_version_id: $version_id)
      status_by_version(infralist_version_id: $version_id) {
        id
        name
      }
      check_status_by_version(infralist_version_id: $version_id) {
        id
        name
      }
      approvement_status_by_version(infralist_version_id: $version_id) {
        id
        name
      }
      section_sum_by_version(infralist_version_id: $version_id)
      checked_users_by_version(infralist_version_id: $version_id) {
        id
        name
      }
    }
  }
`;

export const QUERY_INFRALIST_VERSION_DIRECTION = gql`
  query infralistDirection($direction_id: ID!, $version_id: ID!) {
    infralistDirection(id: $direction_id) {
      id
      name
      status_by_version(infralist_version_id: $version_id) {
        id
        name
      }
      check_status_by_version(infralist_version_id: $version_id) {
        id
        name
      }
      approvement_status_by_version(infralist_version_id: $version_id) {
        id
        name
      }
      direction_sum_by_version(infralist_version_id: $version_id)
    }
  }
`;

export const QUERY_INFRALIST_VERSION_SECTION = gql`
  query infralistSection($section_id: ID!, $version_id: ID!) {
    infralistSection(id: $section_id) {
      id
      name
      status_by_version(infralist_version_id: $version_id) {
        id
        name
      }
      check_status_by_version(infralist_version_id: $version_id) {
        id
        name
      }
      approvement_status_by_version(infralist_version_id: $version_id) {
        id
        name
      }
      section_sum_by_version(infralist_version_id: $version_id)
    }
  }
`;

export const QUERY_INFRALIST_CHANGE_LOG_DATELIST = gql`
  query infralistChangeLogDateList($infralist_id: ID!) {
    infralistChangeLogDateList(infralist_id: $infralist_id)
  }
`;

export const QUERY_INFRALIST_CHANGE_LOGS = gql`
  query infralistChangeLogs($input: InfralistChangePageInput!) {
    infralistChangeLogs(input: $input) {
      header {
        totalCount
        page
        onePage
        hasNext
      }
      list {
        id
        updated_at
        user_id
        rvpo_equipment_changes_count
        commited_user_type
        commited_user {
          id
          name
        }
        infralist {
          id
          subsidiary_entity {
            id
            name_full
            inn
            kpp
            base_organization {
              id
              name_full
            }
          }
        }
        rvpo_equipment {
          id
          name_rvpo
          fpo_equipment {
            id
            name
          }
        }
        direction {
          id
          name
        }
        section {
          id
          name
        }
      }
    }
  }
`;

export const QUERY_INFRALIST_CHANGE_LOG = gql`
  query infralistChangeLog($id: ID!) {
    infralistChangeLog(id: $id) {
      id
      infralist {
        id
        subsidiary_entity {
          id
          name_full
          inn
          kpp
          base_organization {
            id
            name_full
          }
        }
      }
      rvpo_equipment {
        id
        name_rvpo
        description_rvpo
        unit
        model_rvpo
        quantity
        price
        equipment_external_url
        description
        full_price
        equipment_approvement_status {
          id
          name
        }
        check_status {
          id
          name
        }
        fpo_equipment {
          id
          name
          fpo_description
        }
      }
      rvpo_equipment_version_data {
        id
        infralist_change_log_id
        name_rvpo
        description_rvpo
        unit
        model_rvpo
        quantity
        price
        equipment_external_url
        description
        full_price
        equipment_approvement_status {
          id
          name
        }
        subsidiary_entity_data {
          id
          name_full
          inn
          kpp
          base_organization {
            id
            name_full
          }
        }
        check_status {
          id
          name
        }
        fpo_equipment {
          id
          name
          fpo_description
        }
      }
    }
  }
`;

export const QUERY_INFRALIST_CHANGE_LOG_DIRECTIONS = gql`
  query infralistChangeLogDirections(
    $input: InfralistChangeLogDirectionsPageInput!
  ) {
    infralistChangeLogDirections(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

export const QUERY_INFRALIST_CHANGE_LOG_SECTION = gql`
  query infralistChangeLogSections(
    $input: InfralistChangeLogSectionsPageInput!
  ) {
    infralistChangeLogSections(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

export const QUERY_INFRALIST_CHANGE_LOG_FPO_EQUIPMENTS = gql`
  query infralistChangeLogFpoEquipments(
    $input: InfralistChangeLogFpoEquipmentsPageInput!
  ) {
    infralistChangeLogFpoEquipments(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

export const QUERY_CHECK_APPROVMENT = gql`
  query checkNotApproveSection($infralist_id: ID!) {
    checkNotApproveSection(infralist_id: $infralist_id)
  }
`;
