import { Prompt } from 'react-router';
import React, { useEffect, useMemo } from 'react';
import { FastField, Form } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FormHeader } from '../../../../../../common/Form/Form';
import SubTable from '../../../../../../common/SubTable/SubTable';
import InputGroup from '../../../../../../common/Inputs/InputGroup';
import {
  CurrencyInput,
  TextInput
} from '../../../../../../common/Inputs/TextInput';
import { DateCell } from '../../../../../../common/TableCells';
import { Indicator } from '../../../../../../store/indicator/Indicator';
import { useCurrentUser } from '../../../../../../store/useCurrentUser';
import { useRoadmapIndicators } from '../../../../../../store/roadmap/useRoadmapIndicators';
import { useMemoSuggestions } from '../../../../../../utils/suggestions';
import { useSubsidiaryEntitiesForFilter } from '../../../../../../store/subsidiaryEntity/useSubsidiaryEntities';
import { useRoadmapIndicator } from '../../../../../../store/roadmap/useRoadmapIndicator';
import { queryLoaderInline } from '../../../../../../common/QueryLoader';
import { User } from '../../../../../../store/role/User';
import { FORM_STATUS_SUBMITTED } from '../../../../../../utils/data/form';
import { NewAutocompleteInput } from '../../../../../../common/InputsNew/NewAutocompleteInput';
import { SubsidiaryEntity } from '../../../../../../store/subsidiaryEntity/SubsidiaryEntity';
import { SubsidiaryEntityOrganizationInfo } from '../../../../../subsidiaryEntities/SubsidiaryEntityOrganizationInfo';
import { monthsSuggestions } from '../../../../../../utils/data/date';
import { useSubsidiaryEntityYearOfCreation } from '../../../../../../store/subsidiaryEntity/useSubsidiaryEntity';
import { useCreateYearsSuggestions } from '../../../../../../store/subsidiaryEntity/useCreateYearsSuggestions';

export const IndicatorEditForm = ({
  onBackClick,
  status,
  isSubmitting,
  editor,
  project,
  values,
  touched,
  errors,
  match,
  setValues,
  setFieldValue,
  indicator
}) => {
  const { projectId, roadmapId } = match.params;

  const roadmapIndicatorsFilter = useMemo(
    () => ({
      regional_project_id: projectId,
      roadmap_id: roadmapId
    }),
    [projectId, roadmapId]
  );
  const roadmapIndicatorsQuery = useRoadmapIndicators(roadmapIndicatorsFilter);
  const roadmapIndicators = roadmapIndicatorsQuery.state.data?.data;
  const roadmapIndicatorsSuggestions = useMemoSuggestions(roadmapIndicators);

  const subsidiaryEntitiesFilter = useMemo(
    () =>
      project
        ? {
            region_ids: project?.region?.id,
            owner_roadmap_ids: roadmapId
          }
        : null,
    [project, roadmapId]
  );
  const entitiesQuery = useSubsidiaryEntitiesForFilter(
    subsidiaryEntitiesFilter
  );
  const entities = entitiesQuery.state.data?.list.map((entity) => ({
    ...entity,
    name_full: SubsidiaryEntity.getCompleteName(entity)
  }));
  const entitiesSuggestions = useMemoSuggestions(entities);
  const selectedEntity =
    values.subsidiary_entity_id &&
    entities?.find((entity) => entity.id === values.subsidiary_entity_id);

  const roadmapIndicatorQuery = useRoadmapIndicator(
    values.owner_roadmap_indicator_id
  );
  const roadmapIndicator = roadmapIndicatorQuery.state.data;

  const handleIndicator = (value, name) => {
    const indicatorName = roadmapIndicatorsSuggestions.find(
      (option) => option.value === value
    )?.label;
    setValues({
      ...values,
      [name]: value,
      name_full: indicatorName
    });
  };

  const subsidiaryEntityYearOfCreationQuery = useSubsidiaryEntityYearOfCreation(
    values.subsidiary_entity_id
  );
  const subsidiaryEntityYearOfCreation =
    subsidiaryEntityYearOfCreationQuery.state.data?.year_of_creation;
  const yearSuggestions = useCreateYearsSuggestions(
    subsidiaryEntityYearOfCreation,
    values.year
  );
  useEffect(() => {
    if (subsidiaryEntityYearOfCreation > values.year) {
      setFieldValue('year', subsidiaryEntityYearOfCreation);
    }
  }, [subsidiaryEntityYearOfCreation, values.year, setFieldValue]);

  const user = useCurrentUser();
  const canApprove = Indicator.canApprove(user, indicator);

  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <Form>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting}
          editor={editor}
          isSubmitting={isSubmitting}
          entityName={`Индикатор`}
        />
        <SubTable title="Общие сведения" editVariant>
          <TableRow>
            <TableCell>Регион</TableCell>
            <TableCell>{project?.region?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Организация</TableCell>
            <TableCell>
              <SubsidiaryEntityOrganizationInfo
                organization={selectedEntity?.base_organization}
              />
            </TableCell>
          </TableRow>
          {!canApprove && (
            <>
              <TableRow>
                <TableCell>Статус</TableCell>
                <TableCell>{Indicator.getStatus(values.status)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Комментарий ФПО</TableCell>
                <TableCell>{values.comment_fpo}</TableCell>
              </TableRow>
            </>
          )}
        </SubTable>
        <SubTable title="Данные индикатора ДК" editVariant>
          <TableRow>
            <TableCell>Название индикатора</TableCell>
            <TableCell>{values.name_full}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Единица измерения</TableCell>
            <TableCell>
              {queryLoaderInline(roadmapIndicatorQuery) ||
                roadmapIndicator?.unit?.name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Минимальное значение в год</TableCell>
            <TableCell>
              {queryLoaderInline(roadmapIndicatorQuery) ||
                Indicator.getMinimumText(roadmapIndicator)}
            </TableCell>
          </TableRow>
          {roadmapIndicator?.plan_roadmap_indicator?.explanation && (
            <TableRow>
              <TableCell>Пояснение к минимальному значению в год</TableCell>
              <TableCell>
                {roadmapIndicator?.plan_roadmap_indicator?.explanation}
              </TableCell>
            </TableRow>
          )}
        </SubTable>
        <InputGroup>
          <NewAutocompleteInput
            label="Индикатор ДК"
            name="owner_roadmap_indicator_id"
            handleChange={handleIndicator}
            hasCopyBtn={false}
            suggestions={roadmapIndicatorsSuggestions}
            suggestionsLoading={roadmapIndicatorsQuery.check.spinner}
            fullWidth
            required
          />
          <NewAutocompleteInput
            label="Субсидиарная сущность"
            name="subsidiary_entity_id"
            suggestions={entitiesSuggestions}
            suggestionsLoading={entitiesQuery.check.spinner}
            hasCopyBtn={false}
            fullWidth
            required
          />
          <NewAutocompleteInput
            label="Год"
            name="year"
            suggestions={yearSuggestions}
            hasCopyBtn={false}
            fullWidth
            required
          />
          <NewAutocompleteInput
            label="Месяц"
            name="month"
            suggestions={monthsSuggestions}
            hasCopyBtn={false}
            fullWidth
            required
          />
        </InputGroup>
        {canApprove && (
          <InputGroup>
            <NewAutocompleteInput
              label="Статус"
              name="status"
              suggestions={Indicator.editStatusOptions}
              hasCopyBtn={false}
              fullWidth
              required
            />
            <FastField
              name="comment_fpo"
              label="Комментарий ФПО"
              multiline
              component={TextInput}
              errorMessage={touched.comment_fpo && errors.comment_fpo}
              fullWidth
              dependentFields={['status']}
            />
          </InputGroup>
        )}
        <InputGroup>
          <FastField
            name="value_from_tender_documentation"
            label="Значение из конкурсной документации"
            component={CurrencyInput}
            decimalScale={2}
            fixedDecimalScale={true}
            unit=""
          />
          <FastField
            name="value_fact"
            label="Значение факт"
            component={CurrencyInput}
            decimalScale={2}
            fixedDecimalScale={true}
            unit=""
          />
        </InputGroup>
        <SubTable editVariant>
          <TableRow>
            <TableCell>Автор</TableCell>
            <TableCell>{User.getUserName(values.author_user)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Дата создания</TableCell>
            <DateCell value={values.created_at} />
          </TableRow>
          <TableRow>
            <TableCell>Дата редактирования</TableCell>
            <DateCell value={values.updated_at} />
          </TableRow>
          <TableRow>
            <TableCell>Версия</TableCell>
            <TableCell>{values.version}</TableCell>
          </TableRow>
        </SubTable>
      </Form>
    </>
  );
};
