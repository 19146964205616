import React, { useCallback, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Form } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { FormHeader } from '../../../common/Form/Form';
import InputGroup from '../../../common/Inputs/InputGroup';
import SubTable from '../../../common/SubTable/SubTable';
import { yearsSuggestions } from '../../../utils/data/date';
import { EMDASH } from '../../../utils/constants';
import AutocompleteBaseInput from '../../../common/Inputs/Selects/AutocompleteBaseInput';
import Loader from '../../../common/Loader/Loader';
import { useUserRole } from '../../../store/role/useUserRole';
import { useGeneralConfirmation } from '../../../common/Form/useGeneralConfirmation';
import { ContestDocsForSSMonitoringEntity } from '../../../store/contestDocsForSSMonitoring/ContestDocsForSSMonitoringEntity';
import { ContestDocsMonitoringFormEditable } from './ContestDocsMonitoringFormEditable';
import { ContestDocsMonitoringFormPreview } from './ContestDocsMonitoringFormPreview';
import { ContestDocsEntityValidationDialog } from './ContestDocsEntityValidationDialog';
import s from './ContestDocsMonitoringForm.module.scss';

export const ContestDocsMonitoringForm = ({
  onBackClick,
  isSubmitting,
  editor,
  dirty,
  isValid,
  touched,
  errors,
  values,
  submitForm,
  resetForm,
  setFieldValue,
  filter,
  regionsSuggestions,
  regionsLoading,
  federalProjectsSuggestions,
  federalProjectsLoading,
  subsidiaryEntitiesSuggestions,
  subsidiaryEntitiesLoading,
  roadmapsSuggestions,
  selectedRoadmapId,
  entity,
  entityLoading,
  entityDeleting,
  exportLoading,
  onFilterChange,
  onDelete,
  onRequestEdit,
  onAllowEdit,
  onRejectEdit,
  onExport,
  onExportHistoryOpen
}) => {
  const user = useCurrentUser();
  const { isRegionAdmin } = useUserRole(user);
  const [isValidationError, setIsValidationError] = useState(false);

  const entityStatus = entity?.status;
  const isApproved =
    entityStatus === ContestDocsForSSMonitoringEntity.Status.APPROVED;
  const isDraft =
    entityStatus === ContestDocsForSSMonitoringEntity.Status.DRAFT;
  const isReturned =
    entityStatus === ContestDocsForSSMonitoringEntity.Status.RETURNED;
  const isEditRequested =
    entityStatus === ContestDocsForSSMonitoringEntity.Status.EDIT_REQUESTED;

  const canEdit = ContestDocsForSSMonitoringEntity.canEdit(user, entity);
  const canRevoke = ContestDocsForSSMonitoringEntity.canRevoke(user);

  const editable = canEdit && (isDraft || isReturned);

  const handleApprove = useCallback(
    (status) => {
      if (dirty) {
        resetForm();
      }
      setFieldValue('submitStatus', status, false);
      setTimeout(submitForm, 0);
    },
    [dirty, submitForm, resetForm, setFieldValue]
  );

  const handleSubmit = useCallback(() => {
    setFieldValue(
      'submitStatus',
      ContestDocsForSSMonitoringEntity.formSubmitStatus.Submit,
      false
    );
  }, [setFieldValue]);

  const [approveDialog, openApproveDialog] = useGeneralConfirmation(
    handleApprove,
    {
      title: 'Вы уверены, что хотите утвердить форму?',
      children:
        dirty && 'При данном действии все несохраненные данные будут утеряны!'
    }
  );

  const isApprove =
    values['submitStatus'] ===
    ContestDocsForSSMonitoringEntity.formSubmitStatus.Approve;

  useEffect(() => {
    if (isApprove && !isValid) {
      setIsValidationError(true);
    }
  }, [isApprove, isValid]);

  return (
    <>
      <Prompt
        // when={entity && editable && status !== FORM_STATUS_SUBMITTED}
        when={!!entity && editable && !entityDeleting}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />

      <ContestDocsEntityValidationDialog
        isOpen={isValidationError}
        onCancel={() => setIsValidationError(false)}
      >
        {isApprove &&
          'Вы не можете утвердить форму, пока не заполнены все обязательные поля'}
      </ContestDocsEntityValidationDialog>

      <Form noValidate>
        <FormHeader
          backButtonText={'К списку'}
          onBackClick={onBackClick}
          disabled={isSubmitting}
          editor={editor}
          editable={editable}
          isSubmitting={isSubmitting}
          onSubmitClick={handleSubmit}
          actions={
            entity && (
              <>
                {(isApproved || isEditRequested) && (
                  <Button
                    color="default"
                    variant="outlined"
                    onClick={() => onExport(entity.id)}
                  >
                    {exportLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Экспортировать'
                    )}
                  </Button>
                )}

                <Button
                  color="default"
                  variant="outlined"
                  onClick={onExportHistoryOpen}
                >
                  История экспорта
                </Button>

                {canEdit && (isDraft || isReturned) && (
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => onDelete(entity.id)}
                  >
                    Удалить
                  </Button>
                )}

                {canEdit && (isDraft || isReturned) && (
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() =>
                      openApproveDialog(
                        ContestDocsForSSMonitoringEntity.formSubmitStatus
                          .Approve
                      )
                    }
                  >
                    Утвердить
                  </Button>
                )}

                {canEdit && isApproved && (
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => onRequestEdit(entity.id)}
                  >
                    Запросить редактирование
                  </Button>
                )}

                {canRevoke && isEditRequested && (
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => onRejectEdit(entity.id)}
                  >
                    Запретить редактирование
                  </Button>
                )}

                {canRevoke && isEditRequested && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => onAllowEdit(entity.id)}
                  >
                    Разрешить редактирование
                  </Button>
                )}
              </>
            )
          }
        />

        <SubTable title="Общие сведения">
          <TableRow>
            <TableCell>Статус</TableCell>
            <TableCell>
              {ContestDocsForSSMonitoringEntity.statusLabel[values.status] ||
                EMDASH}
            </TableCell>
          </TableRow>
        </SubTable>

        <InputGroup>
          <AutocompleteBaseInput
            label="Год"
            suggestions={yearsSuggestions}
            value={filter.year}
            hasCopyBtn={false}
            isClearable={false}
            fullWidth
            required
            onChange={(value) => onFilterChange('year', value)}
          />
          {!isRegionAdmin && regionsSuggestions?.length > 1 && (
            <AutocompleteBaseInput
              label="Регион"
              suggestions={regionsSuggestions}
              suggestionsLoading={regionsLoading}
              value={filter.region_id}
              hasCopyBtn={false}
              isClearable={false}
              fullWidth
              required
              onChange={(value) => onFilterChange('region_id', value)}
            />
          )}
          <AutocompleteBaseInput
            label="Федеральный проект"
            suggestions={federalProjectsSuggestions}
            suggestionsLoading={federalProjectsLoading}
            value={filter.federal_project_id}
            hasCopyBtn={false}
            isClearable={false}
            fullWidth
            required
            onChange={(value) => onFilterChange('federal_project_id', value)}
          />
          <AutocompleteBaseInput
            label="Субсидиарная сущность"
            suggestions={subsidiaryEntitiesSuggestions}
            suggestionsLoading={subsidiaryEntitiesLoading}
            value={filter.subsidiary_entity_id}
            hasCopyBtn={false}
            isClearable={false}
            fullWidth
            required
            onChange={(value) => onFilterChange('subsidiary_entity_id', value)}
          />
          {selectedRoadmapId && (
            <AutocompleteBaseInput
              label="Дорожная карта"
              suggestions={roadmapsSuggestions}
              suggestionsLoading={subsidiaryEntitiesLoading}
              value={selectedRoadmapId}
              hasCopyBtn={false}
              isClearable={false}
              fullWidth
              disabled
            />
          )}
        </InputGroup>

        <SubTable>
          <TableRow>
            <TableCell colSpan={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={!!values.showPreviousValue}
                    onChange={(e) => {
                      setFieldValue('showPreviousValue', e.target.checked);
                    }}
                  />
                }
                label="Отображать фактическое значение предыдущего периода"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={!!values.showValueDiff}
                    onChange={(e) => {
                      setFieldValue('showValueDiff', e.target.checked);
                    }}
                  />
                }
                label="Отображать отклонение фактического значения от значения предыдущего периода"
              />
            </TableCell>
          </TableRow>
        </SubTable>

        {(entityLoading && (
          <Loader className={s.ContestDocsMonitoringForm__loader} />
        )) ||
          (entity ? (
            editable ? (
              <ContestDocsMonitoringFormEditable
                entity={entity}
                values={values}
                touched={touched}
                errors={errors}
              />
            ) : (
              <ContestDocsMonitoringFormPreview
                entity={entity}
                values={values}
              />
            )
          ) : (
            <h3>Нет информации по выбранным фильтрам</h3>
          ))}
      </Form>

      {approveDialog}
    </>
  );
};
