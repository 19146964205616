import React, { useMemo } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { reformatDate } from '../../../../utils/date';
import { getSubsidiaryLabel } from './changeLogUtils';
import s from './ResultBlock.module.scss';

const ResultRow = ({ label, value }) => (
  <div className={s.row}>
    <p className={s.label}>{label}</p>
    <p className={s.value}>{value}</p>
  </div>
);

export const ResultBlock = ({ active, changeLog, setCurrentChangeLogId }) => {
  const userNameWithType = useMemo(() => {
    let userNameWithTypeLocal = changeLog.commited_user?.name;

    const translatedUserRole = {
      RVPO: 'РВПО',
      FPO: 'ФПО'
    }[changeLog.commited_user_type];

    if (translatedUserRole) {
      userNameWithTypeLocal = `${translatedUserRole}: ${changeLog.commited_user?.name}`;
    }
    return userNameWithTypeLocal;
  }, [changeLog]);

  return (
    <Paper
      variant="outlined"
      square
      className={classNames(s.root, active && s.active)}
    >
      <div className={s.titleContainer}>
        <span className={classNames(s.title, s.value)}>
          {reformatDate(changeLog.updated_at)}
        </span>
        <span
          title={changeLog.commited_user?.name}
          className={classNames(s.title, s.label)}
        >
          {userNameWithType}
        </span>
        <Button
          color="primary"
          variant="outlined"
          className={s.openButton}
          onClick={() => setCurrentChangeLogId(changeLog.id)}
        >
          Смотреть
        </Button>
      </div>

      <ResultRow
        label="Субсидиарная сущность"
        value={getSubsidiaryLabel(changeLog.infralist.subsidiary_entity)}
      />

      <ResultRow label="Направление" value={changeLog.direction.name} />

      <ResultRow label="Раздел" value={changeLog.section?.name} />

      <ResultRow
        label="Наименование оборудования (ФПО)"
        value={changeLog.rvpo_equipment?.fpo_equipment?.name}
      />

      <ResultRow
        label="Количество внесенных изменений"
        value={changeLog.rvpo_equipment_changes_count}
      />
    </Paper>
  );
};
