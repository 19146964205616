import gql from 'graphql-tag';

export const GET_INCOMING_NOTIFICATIONS = gql`
  query getIncomingNotification($input: NotificationsPageInput) {
    incomingNotifications(input: $input) {
      header {
        hasNext
        onePage
        page
        totalCount
      }
      list {
        id
        sender_id
        sender {
          id
          name
          login
        }
        sent_at
        type
        section
        subject
        read
        read_at
      }
    }
  }
`;

export const GET_INFRALIST_NOTIFICATIONS = gql`
  query getIncomingNotification($input: NotificationsPageInput) {
    incomingNotifications(input: $input) {
      header {
        hasNext
        onePage
        page
        totalCount
      }
      list {
        id
        sender_id
        sender {
          id
          name
          login
        }
        sent_at
        type
        section
        subject
        read
        read_at
        template_fields
        content
      }
    }
  }
`;

export const GET_NOTIFICATION = gql`
  query notification($id: Float!) {
    notification(id: $id) {
      id
      sender_id
      sent_at
      type
      section
      subject
      content
      sender {
        id
        login
      }
      read
      read_at
      content
      subject
      entity_id
    }
  }
`;

export const GET_NOTIFICATION_COUNT = gql`
  query amountOfUnreadNotifications {
    amountOfUnreadNotifications
  }
`;

export const GET_INFRALIST_NOTIFICATION_COUNT = gql`
  query amountOfUnreadInfralistNotifications($infralist_id: ID!) {
    amountOfUnreadInfralistNotifications(infralist_id: $infralist_id)
  }
`;
