import React, { useMemo } from 'react';
import { Prompt } from 'react-router';
import { FastField, Form } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FormHeader } from '../../../../../../common/Form/Form';
import { FORM_STATUS_SUBMITTED } from '../../../../../../utils/data/form';
import { AdditionalData } from '../../../../../../store/additionalData/AdditionalData';
import SubTable from '../../../../../../common/SubTable/SubTable';
import InputGroup from '../../../../../../common/Inputs/InputGroup';
import { NewAutocompleteInput } from '../../../../../../common/InputsNew/NewAutocompleteInput';
import { FormDateInput } from '../../../../../../common/Inputs/DateInput';
import { useUnfilledClassesScheduleSubsidiaryEntities } from '../../../../../../store/subsidiaryEntity/useSubsidiaryEntities';
import { SubsidiaryEntity } from '../../../../../../store/subsidiaryEntity/SubsidiaryEntity';
import { Indicator } from '../../../../../../store/indicator/Indicator';
import { useCurrentUser } from '../../../../../../store/useCurrentUser';
import { NewTextInput } from '../../../../../../common/InputsNew/NewTextInput';
import { makeOptionator } from '../../../../../../utils/data/label';

export const AdditionalDataEditForm = ({
  onBackClick,
  status,
  isSubmitting,
  editor,
  roadmapId,
  values,
  regionId,
  classesSchedule,
  year
}) => {
  const subsidiaryEntitiesFilter = useMemo(
    () => ({
      region_ids: regionId,
      roadmap_ids: roadmapId,
      years: +year || classesSchedule?.year
    }),
    [regionId, roadmapId, year, classesSchedule]
  );
  const entitiesQuery = useUnfilledClassesScheduleSubsidiaryEntities(
    subsidiaryEntitiesFilter
  );
  const entitiesData = entitiesQuery.state.data;
  const classesEntity = classesSchedule?.subsidiary_entity;

  const entitiesSuggestions = useMemo(() => {
    const result = entitiesData?.map(
      makeOptionator(SubsidiaryEntity.getCompleteName)
    );

    /* Т.к. в режиме создания мы должны отображать только те СС по которым еще не
    создано ни одного графика, а в режиме редактирования надо показать + уже
    выбранный, то если есть данные добавляем в селектор текущий СС*/
    if (result && classesEntity) {
      result.push({
        value: classesEntity.id,
        label: SubsidiaryEntity.getCompleteName(classesEntity)
      });
    }

    return result;
  }, [entitiesData, classesEntity]);

  const user = useCurrentUser();
  const canApprove = AdditionalData.canApprove(user, classesSchedule);

  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <Form>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting}
          editor={editor}
          isSubmitting={isSubmitting}
          entityName={`График начала занятий`}
        />
        {!canApprove && (
          <SubTable title="Общие сведения" editVariant>
            <TableRow>
              <TableCell>Статус</TableCell>
              <TableCell>
                {AdditionalData.status.getName(values.status)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Комментарий ФПО</TableCell>
              <TableCell>{values.comment_fpo}</TableCell>
            </TableRow>
          </SubTable>
        )}
        <InputGroup>
          {canApprove && (
            <>
              <NewAutocompleteInput
                label="Статус"
                name="status"
                suggestions={AdditionalData.status.getOptions()}
                hasCopyBtn={false}
                isClearable={false}
                fullWidth
                required
              />
              <NewTextInput
                name="comment_fpo"
                label="Комментарий ФПО"
                multiline
                fullWidth
              />
            </>
          )}
          <NewAutocompleteInput
            label="Субсидиарная сущность"
            name="subsidiary_entity_id"
            suggestions={entitiesSuggestions}
            suggestionsLoading={entitiesQuery.check.spinner}
            hasCopyBtn={false}
            isClearable={false}
            fullWidth
            required
          />
          <FastField
            label="Предварительная дата"
            component={FormDateInput}
            value={`09.01.${year || classesSchedule?.year}`}
            disabled
          />
          <FastField
            label="Фактическая дата"
            name="fact_date"
            component={FormDateInput}
            required
          />
        </InputGroup>
        <SubTable editVariant>
          <TableRow>
            <TableCell>Версия</TableCell>
            <TableCell>{values.version}</TableCell>
          </TableRow>
        </SubTable>
      </Form>
    </>
  );
};
