import { skipIfNull } from '@proscom/prostore';
import { useGraphqlWatchQuery } from '@proscom/prostore-apollo-react';
import {
  QUERY_CONTEST_DOCS_FOR_SS_MONITORING_ENTITY,
  QUERY_CONTEST_DOCS_MONITORING_ENTITY
} from '../../graphql/queries/contestDocsMonitoring';

const contestDocsMonitoringEntityQueryOptions = {
  query: QUERY_CONTEST_DOCS_MONITORING_ENTITY,
  mapData: (data) => data.monthlyMonitoringTenderDocumentation,
  skipQuery: skipIfNull(null)
};

export function useContestDocsMonitoringEntity(input) {
  return useGraphqlWatchQuery({
    queryOptions: contestDocsMonitoringEntityQueryOptions,
    variables: input ? { input } : null,
    options: {
      apolloOptions: {
        fetchPolicy: 'network-only'
      }
    }
  });
}
