import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useCommentDate } from '../../routes/infralist/comments/components/Comment/useCommentDate';
import { useInfralistNotifications } from '../../store/infralist/notifications/useInfralistNotifications';
import { useNotificationOnClick } from '../../store/notification/useNotifications';
import { BlockWatchScroll } from '../BlockWatchScroll/BlockWatchScroll';
import Loader from '../Loader/Loader';
import { useNotificationLink } from './useNotificationLink';
import s from './NotificationPanel.module.scss';

const NotificationItem = ({ data, setRead, infralistId }) => {
  const { getNotification } = useNotificationOnClick(data.id);
  const link = useNotificationLink(data, infralistId);
  const formattedDate = useCommentDate(data?.sent_at);

  const onCLick = () => {
    setRead(data.id);
    getNotification();
  };

  return (
    <Link
      to={{
        pathname: `${link}/${data?.template_fields?.channel}`,
        state: {
          notificationId: data?.id
        }
      }}
      className={s.Item}
      onClick={onCLick}
    >
      <div className={s.IndicatorWrapper}>
        {!data.read && <div className={s.Indicator} />}
      </div>
      <div className={s.ItemContent}>
        <div className={s.ItemTitle}>{data.subject}</div>
        <div className={s.ItemDescription}>{data.content}</div>
        <div className={s.ItemDate}>{formattedDate}</div>
      </div>
    </Link>
  );
};

export const NotificationPanel = ({
  infralistId,
  pagination,
  filter,
  setFilter,
  loadMore,
  setCount,
  quantityNew,
  onClose
}) => {
  const {
    notifications,
    header,
    setRead,
    isLoading
  } = useInfralistNotifications({
    pagination,
    infralistId,
    filter,
    setCount
  });
  const scrolledBlockRef = useRef();

  const toggleReadedMode = () => {
    if (scrolledBlockRef.current) {
      scrolledBlockRef.current.scrollTo(0, 0);
    }
    setFilter({
      read: filter.read === undefined ? false : undefined
    });
  };

  const canLoadMore = header?.hasNext;

  const handleRead = (id) => {
    setRead(id);
    onClose();
  };

  return (
    <>
      <div className={s.Header}>
        <p className={s.HeaderTitle}>Уведомления</p>
        <p className={s.New}>{quantityNew} новых</p>
      </div>
      <BlockWatchScroll
        onScrollEndY={() => !isLoading && canLoadMore && loadMore()}
        className={s.Main}
        blockRef={scrolledBlockRef}
      >
        {notifications.map((item, index) => (
          <NotificationItem
            data={item}
            key={index}
            setRead={handleRead}
            infralistId={infralistId}
          />
        ))}
      </BlockWatchScroll>
      <div className={s.Footer} onClick={toggleReadedMode}>
        <span>
          {filter.read === undefined ? 'Скрыть' : 'Показать'} прочитанные
        </span>
        <Loader
          centered={true}
          size={20}
          style={{ margin: 0, display: isLoading ? 'inline-block' : 'none' }}
        />
      </div>
    </>
  );
};

NotificationPanel.propTypes = {
  quantityNew: PropTypes.number
};
