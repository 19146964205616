import React, { useCallback, useMemo } from 'react';
import { useMemoSuggestions } from '../../../../utils/suggestions';
import { useSubsidiaryEntitiesForFilter } from '../../../../store/subsidiaryEntity/useSubsidiaryEntities';
import { SubsidiaryEntity } from '../../../../store/subsidiaryEntity/SubsidiaryEntity';
import AutocompleteBaseInput from '../../../../common/Inputs/Selects/AutocompleteBaseInput';
import { yearsSuggestions } from '../../../../utils/data/date';
import { AdditionalData } from '../../../../store/additionalData/AdditionalData';
import { useRegions } from '../../../../store/subsidiaryEntity/useSubsidiaryEntity';

export const useAdditionalDataListFilter = (
  query,
  changeQuery,
  roadmapId,
  defaultYear,
  projectId
) => {
  const filter = {
    subsidiary_entity_ids: query.subsidiary_entity_id,
    years: +(query.year || defaultYear),
    status: query.status,
    region_ids: query.regionId,
    roadmap_ids: roadmapId,
    regional_project_ids: projectId
  };

  const setFilterValue = useCallback(
    (value, name) =>
      changeQuery({
        [name]: value,
        page: 0
      }),
    [changeQuery]
  );

  const subsidiaryEntitiesFilter = useMemo(
    () => ({
      region_ids: filter.region_ids,
      owner_roadmap_ids: roadmapId,
      regional_project_ids: projectId,
      year: filter.years + ''
    }),
    [filter.region_ids, filter.years, roadmapId, projectId]
  );
  const entitiesQuery = useSubsidiaryEntitiesForFilter(
    subsidiaryEntitiesFilter
  );
  const entities = entitiesQuery.state.data?.list.map((entity) => ({
    ...entity,
    name_full: SubsidiaryEntity.getCompleteName(entity)
  }));
  const entitiesSuggestions = useMemoSuggestions(entities);

  const regionsQuery = useRegions();
  const regions = regionsQuery.state.data;
  const regionsSuggestions = useMemoSuggestions(regions);

  const filterComponent = (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {!projectId && (
        <AutocompleteBaseInput
          onChange={setFilterValue}
          hasCopyBtn={false}
          label="Регион"
          name="regionId"
          suggestions={regionsSuggestions}
          suggestionsLoading={regionsQuery.check.spinner}
          value={filter.region_ids}
          variant="standard"
        />
      )}
      <AutocompleteBaseInput
        name="subsidiary_entity_id"
        label="Субсидиарная сущность"
        variant="standard"
        value={filter.subsidiary_entity_ids}
        onChange={setFilterValue}
        hasCopyBtn={false}
        suggestions={entitiesSuggestions}
        suggestionsLoading={entitiesQuery.check.spinner}
      />
      <AutocompleteBaseInput
        name="year"
        label="Год"
        variant="standard"
        value={String(filter.years || defaultYear)}
        onChange={setFilterValue}
        hasCopyBtn={false}
        suggestions={yearsSuggestions}
      />
      <AutocompleteBaseInput
        name="status"
        label="Статус"
        variant="standard"
        value={filter.status}
        onChange={setFilterValue}
        hasCopyBtn={false}
        suggestions={AdditionalData.status.getOptions()}
      />
    </div>
  );
  return [filter, filterComponent];
};
