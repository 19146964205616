import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_SUBSIDIARY_ENTITY_ROADMAP_INDICATOR } from '../../graphql/queries/indicators';

const subsidiaryEntityRoadmapIndicatorQueryOptions = {
  query: QUERY_SUBSIDIARY_ENTITY_ROADMAP_INDICATOR,
  mapData: (result) => result.subsidiaryEntityRoadmapIndicator,
  skipQuery: skipIfNull(null)
};

export function useSubsidiaryEntityRoadmapIndicator(id, networkOnly = false) {
  return useRetriableGraphqlVars({
    queryOptions: subsidiaryEntityRoadmapIndicatorQueryOptions,
    variableCreator: [
      () =>
        id
          ? {
              id
            }
          : null,
      [id]
    ],
    options: {
      apolloOptions: {
        fetchPolicy: networkOnly ? 'network-only' : 'cache-first'
      }
    }
  });
}
