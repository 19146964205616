import React from 'react';
import classNames from 'classnames';
import s from './Signals.module.scss';

export function Signals({ signals }) {
  if (!signals) return null;
  return (
    <>
      {signals.map((signal, iSignal) => (
        <p key={iSignal} className={s.SignalText}>
          {signal.text}
        </p>
      ))}
    </>
  );
}

export function Signal({ style, className, warning, ...props }) {
  return (
    <p
      style={style}
      className={classNames(s.SignalText, warning && s.warning, className)}
      {...props}
    />
  );
}
