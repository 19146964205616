import { skipIfNull } from '@proscom/prostore';
import { useGraphqlQueryVars } from '@proscom/prostore-apollo-react';
import { QUERY_REGIONAL_PROJECTS_AVAILABLE_FOR_MONTHLY_MONITORING_TENDER_DOCUMENTATION } from '../../graphql/queries/contestDocsMonitoring';

const availableRegionalProjectsQueryOptions = {
  query: QUERY_REGIONAL_PROJECTS_AVAILABLE_FOR_MONTHLY_MONITORING_TENDER_DOCUMENTATION,
  mapData: (data) =>
    data.regionalProjectsAvailableForMonthlyMonitoringTenderDocumentation,
  skipQuery: skipIfNull(null)
};

export function useAvailableRegionalProjectsQueryOptions(input) {
  return useGraphqlQueryVars({
    queryOptions: availableRegionalProjectsQueryOptions,
    variableCreator: [() => (input ? { input } : null), [input]]
  });
}
