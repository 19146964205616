import { clearApolloQueryCache } from '../../utils/apollo';
import { MUTATION_CREATE_INFRALIST } from '../../graphql/mutations/infralist';

/** @typedef {Object} createInfralist
 * @property {String|Number} id
 * @property {"AGREED"|"NOT_AGREED"|"NOT_FORMED"|"NOT_SIGNED"|"ON_APPROVAL"|"ON_CHECK"|"ON_SIGNING"} infralist_status_id
 * @property {String} regional_roadmap_event_id
 * @property {String} subsidiary_entity_id
 * @property {"INFRALIST"|"INFRALIST_SS"} type
 */

export class InfralistStore {
  constructor({ client }) {
    this.client = client;
  }

  queriesToClear = ['eventInfralists', 'roadmapEvent'];

  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  /**
   *
   * @param {createInfralist} data
   * @returns {*|Promise<FetchResult<any>>}
   */
  createInfralist(data) {
    return this.client.mutate({
      mutation: MUTATION_CREATE_INFRALIST,
      variables: {
        input: data
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  }
}
