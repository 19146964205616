import { useEffect, useState } from 'react';
import { useContextStore } from '@proscom/prostore-react';
import { STORE_EDITING } from '../../store/names';

export function useOtherEditor(type, id) {
  const store = useContextStore(STORE_EDITING);
  const [editor, setEditor] = useState(null);
  useEffect(() => {
    let timeoutId = null;

    function updateEditing() {
      store
        .setEditing(type, id)
        .then((user) => {
          setEditor(user || null);
        })
        .catch((e) => console.error(e));

      timeoutId = setTimeout(() => updateEditing(), 30000);
    }

    updateEditing();
    return () => timeoutId && clearTimeout(timeoutId);
  }, [id, type, store]);

  return editor;
}
