import React, { useState } from 'react';
import { SortLabel } from './SortLabel';
import { Sorting } from './Sorting';

// TODO надо как-нибудь допилить возможность сортироваться одновременно по нескольким колонкам
const getInitialState = (columns, isActiveFields, multiSort = false) => {
  if (!columns || !Array.isArray(columns)) return {};
  const state = columns.reduce((acc, column) => {
    acc[column.field] = Sorting.SortDirection.ASC;
    return acc;
  }, {});
  state.isActiveFields = isActiveFields || [columns[0].field];
  return state;
};

export const useSorting = (columns) => {
  const [state, setState] = useState(getInitialState(columns));

  const sorting = columns
    .filter((column) => state.isActiveFields.includes(column.field))
    .map((column) => ({
      direction: state[column.field],
      field: column.field
    }));

  const sortableColumns = columns.map((column) => ({
    ...column,
    label: (
      <SortLabel
        field={column.field}
        label={Sorting.sortFields.getName(column.field)}
        state={state}
        setState={setState}
      />
    )
  }));

  return [sortableColumns, sorting];
};
