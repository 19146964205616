import React, { useMemo } from 'react';
import { isNumber } from 'lodash-es';
import { isNumeric } from 'rxjs/internal-compatibility';
import TableInput from '../../../../common/Table/TableInput';
import { CustomFastField } from '../../../../common/Inputs/CustomFastField';
import { TableCurrencyInput } from '../../../../common/Inputs/TextInput';
import { formatCurrency } from '../../../../utils/number';
import { formatValueUnit } from '../../../../utils/formatNumber';
import { TableSelectWrapper } from '../../../../common/Inputs/TableSelectWrapper';
import { usePurchaseComponentCategories } from '../../../../store/purchase/usePurchaseComponentCategories';
import { queryLoader } from '../../../../common/QueryLoader';
import InputGroup from '../../../../common/Inputs/InputGroup';
import { NoWrap } from '../../../../common/NoWrap';
import { getId, getName, makeOptionator } from '../../../../utils/data/label';
import { EMDASH } from '../../../../utils/constants';
import { Signal } from '../../../../common/Inputs/Signals';

export const getPurchaseCompositionColumns = (purchaseComponentCategories) => {
  const suggestions = purchaseComponentCategories?.map(
    makeOptionator(getName, getId)
  );

  const getRowCategory = (row) =>
    row.purchase_component_category ||
    purchaseComponentCategories.find(
      (cat) => cat.id === row.purchase_component_category_id
    );

  return [
    {
      editComponent: TableSelectWrapper(suggestions, {
        width: 'auto',
        maxWidth: '50vw'
      }),
      field: 'purchase_component_category_id',
      title: 'Категория',
      required: true,
      render: (row) => {
        const category = getRowCategory(row);
        return getName(category) || '—';
      }
    },
    {
      editComponent: function (props) {
        // Костыль для того, чтобы в поле "Количество мебели" нельзя было заполнить количество
        const category = getRowCategory(props.rowData);
        if (category?.code === '9') {
          return false;
        }
        return <TableCurrencyInput {...props} />;
      },
      field: 'amount',
      title: 'Количество, шт.',
      required: (row) => {
        const categoryCode = getRowCategory(row)?.code;
        if (categoryCode === '9') {
          return false;
        }
        return !isNumber(row?.amount);
      },
      render: (row) => {
        const category = getRowCategory(row);
        if (category?.code === '9') {
          return EMDASH;
        }
        return <NoWrap>{formatValueUnit(row.amount, ' шт.', 0)}</NoWrap>;
      }
    },
    {
      editComponent: TableCurrencyInput,
      field: 'cost',
      title: 'Сумма, руб.',
      required: (row) => !isNumber(row?.cost),
      render: (row) => <NoWrap>{formatCurrency(row.cost)}</NoWrap>
    },
    {
      editComponent: ({ columnDef, onChange, value, rowData }) => (
        <>
          <TableCurrencyInput
            onChange={onChange}
            value={value}
            columnDef={columnDef}
            rowData={rowData}
            errorMessage={
              rowData.cost_domestic > rowData.cost && (
                <Signal style={{ maxWidth: '400px' }}>
                  Сумма средств за товары отечественного производства не может
                  превышать общую сумму по текущей категории закупки
                </Signal>
              )
            }
          />
          {}
        </>
      ),
      field: 'cost_domestic',
      title: 'в т.ч. отечественного производства, руб.',
      required: (row) => {
        return !isNumeric(row?.cost_domestic) || row.cost_domestic > row.cost;
      },
      render: (row) => <NoWrap>{formatCurrency(row.cost_domestic)}</NoWrap>
    }
  ];
};

export const PurchaseComponentsField = ({ title }) => {
  const purchaseComponentCategoriesQuery = usePurchaseComponentCategories();
  const purchaseComponentCategories =
    purchaseComponentCategoriesQuery.state.data;

  const columns = useMemo(() => {
    return getPurchaseCompositionColumns(purchaseComponentCategories);
  }, [purchaseComponentCategories]);

  return (
    <InputGroup>
      {queryLoader(purchaseComponentCategoriesQuery) || (
        <CustomFastField
          name="purchase_components"
          columns={columns}
          component={TableInput}
          tableName={title}
        />
      )}
    </InputGroup>
  );
};
