import React from 'react';

/**
 * Добавляет обертку в виде html-элемента и отслеживает его scroll события. Используется для подгрузки элементов при скроле.
 * @param tag - используемый HTML-элемент для обертки, по умолчанию div
 * @param onScrollEndY - запускает callback при прокрутке до конца элемента
 * @param onScroll - стандартный обработчик события
 * @param blockRef - если нужно получить ref элемента `tag`
 * @param props - все остальные параметры идут к элементу `tag`
 */
export const BlockWatchScroll = ({
  tag = 'div',
  children,
  onScrollEndY = () => {},
  onScroll = () => {},
  blockRef,
  ...props
}) => {
  props.onScroll = (e) => {
    onScroll(e);
    const el = e.currentTarget;
    // если элемент прокручен до конца
    if (el.scrollTop >= el.scrollHeight - el.offsetHeight) {
      onScrollEndY();
    }
  };
  if (blockRef) {
    props.ref = blockRef;
  }
  return React.createElement(tag, props, children);
};
