import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SubTable from '../../../common/SubTable/SubTable';
import { ParentalAssistanceGuideline } from '../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import { SimpleFileView } from '../common/FileUpload/FileEditableComponent';

export const FilesRegionalSection = ({ data }) => {
  return (
    <>
      <SubTable title="I. Нормативно - правовое регулирование порядка оказания услуг">
        <TableRow>
          <TableCell>
            1. Перечислите нормативные акты, регламентирующие порядок оказания
            услуги на территории субъекта Российской Федерации, в том числе
            актов, устанавливающих размер нормативных затрат (в случае оказания
            услуги в рамках государственного задания). Представьте копии
            указанных нормативных актов.
          </TableCell>
          <TableCell>
            {data.list_of_service_regulations?.map((document, key) => {
              return <SimpleFileView key={key} document={document} />;
            })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            2. Утверждена ли дорожная карта по внедрению на территории субъекта
            Российской Федерации новой услуги. Представьте (при наличии) копию
            утвержденной дорожной карты
          </TableCell>
          <TableCell>
            {ParentalAssistanceGuideline.getYesOrNo(
              data.new_service_implementation_roadmap_exist
            )}
            {data.approved_roadmap && (
              <SimpleFileView document={data.approved_roadmap} />
            )}
          </TableCell>
        </TableRow>
      </SubTable>
    </>
  );
};
