import React, { useState } from 'react';
import classNames from 'classnames';
import { Button } from '@material-ui/core';
import { useInfralistCollectedCommentAccess } from '../../../../../store/infralist/comments/useInfralistCollectedCommentAccess';
import { useUserRoleByPermissions } from '../../../../../utils/hooks/useUserRoleByPermissions';
import { CommentPopup } from '../CommentPopup/CommentPopup';
import { useCommentDate } from './useCommentDate';
import { useCommentStatus } from './useCommentStatus';
import s from './Comment.module.scss';

export const CollectedComment = ({
  comments,
  infralistStatus,
  acceptComment,
  rejectComment,
  isEditEnabledByEventStatus
}) => {
  const { getRole } = useUserRoleByPermissions();
  const lastCommentUser = comments[comments.length - 1]?.commentary_user;
  const role = getRole(lastCommentUser);
  const date = comments[comments.length - 1]?.created_at;
  const status = comments[comments.length - 1]?.accept_status;
  const [accept, setAccept] = useState(false);
  const [refuse, setRefuse] = useState(false);
  const ids = comments.map((item) => item.id);
  const { canSeeActions } = useInfralistCollectedCommentAccess(
    infralistStatus,
    isEditEnabledByEventStatus
  );
  const formattedDate = useCommentDate(date);
  const commentStatus = useCommentStatus(status);

  const onAccept = ({ content }) => {
    return acceptComment({ content, ids }).then(() => setAccept(false));
  };

  const onReject = ({ content }) => {
    return rejectComment({ content, ids }).then(() => setRefuse(false));
  };

  return (
    <div>
      <div>
        <div className={s.Comment}>
          <div className={s.CommentHeader}>
            <div className={classNames(s.CommentName, s.Text)}>
              {role?.type === 'fpo'
                ? 'ФПО: '
                : `РВПО: ${lastCommentUser?.name}`}
            </div>
            <div className={s.CommentDate}>{formattedDate}</div>
          </div>
          <div>
            {comments.map((item, index) => (
              <div
                className={classNames(s.Text, s.CollectedComment)}
                key={index}
              >
                <div
                  className={classNames(s.CommentName, s.Text, s.CommentNumber)}
                >
                  {`${index + 1}.`}
                </div>
                <div className={s.CommentText}>{item.content}</div>
              </div>
            ))}
          </div>
        </div>
        {status && commentStatus}
      </div>
      {canSeeActions(comments) && (
        <div className={s.Buttons}>
          <Button
            variant={'contained'}
            color={'primary'}
            className={s.Button}
            onClick={() => setAccept(true)}
          >
            Принять
          </Button>
          <Button
            variant={'outlined'}
            className={s.Button}
            onClick={() => setRefuse(true)}
          >
            Отклонить
          </Button>
        </div>
      )}
      <CommentPopup
        open={accept}
        onCancel={() => setAccept(false)}
        title={'Вы действительно хотите принять к устранению замечание ФПО?'}
        description={'Вы можете написать комментарий'}
        placeholder={'Комментарий ...'}
        onSubmit={onAccept}
      />
      <CommentPopup
        open={refuse}
        onCancel={() => setRefuse(false)}
        title={'Вы действительно хотите отклонить замечание ФПО?'}
        useValidation
        description={
          'Для подтверждения действия необходимо указать причину отказа'
        }
        placeholder={'Укажите причину отказа ...'}
        onSubmit={onReject}
      />
    </div>
  );
};
