import { useCallback, useState } from 'react';
import { SORT_ORDER_MAP } from '../../common/infralist/InfralistTable/useTableManager';

/**
 * Хук для управления постраничкой, фильтрами и сортировкой
 * @param itemsPerPage {number}
 * @param initialParams {{pagination: {page: number, onePage: number}, sorting: {}, filter: {}}}
 * @return {{filter: any, setSort: (function(*): void), pagination: {onePage: number, page: number}, loadMore: loadMore, sorting: any, reset: reset, params: ({filter: undefined, pagination: {onePage: number, page: number}, sorting: undefined}), setFilter: setFilter, setPage: setPage}}
 */
export const useSortPager = ({ itemsPerPage = 10, initialParams }) => {
  const [params, setParams] = useState({
    pagination: {
      page: 0,
      onePage: itemsPerPage
    },
    sorting: undefined,
    filter: undefined,
    ...initialParams
  });

  /**
   * Устанавливает сортировку таблицы по коду.
   * При первом клике делает сортировку ASC
   * При втором DESC
   * При третьем отключает сортировку по этому полю
   * @param {string} field
   */
  const setSort = useCallback(
    (field) => {
      let nextSort;
      if (field !== params.sorting?.field) {
        // установка первой сортировки
        nextSort = {
          field,
          direction: SORT_ORDER_MAP.ASC
        };
      } else {
        if (params.sorting?.direction === SORT_ORDER_MAP.ASC) {
          // установка второй сортировки
          nextSort = {
            field,
            direction: SORT_ORDER_MAP.DESC
          };
        } else if (params.sorting?.direction === SORT_ORDER_MAP.DESC) {
          // отключение сортировки
          nextSort = undefined;
        }
      }
      setParams({
        ...params,
        sorting: nextSort,
        pagination: {
          ...params.pagination,
          page: 0
        }
      });
    },
    [params]
  );

  const loadMore = useCallback(() => {
    setParams((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page: prevState.pagination.page + 1
      }
    }));
  }, []);

  const setFilter = useCallback((nextFilter) => {
    setParams((prevState) => ({
      pagination: {
        ...prevState.pagination,
        page: 0
      },
      filter: {
        ...(prevState.filter ?? {}),
        ...nextFilter
      },
      sorting: prevState.sorting
    }));
  }, []);

  const reset = () => {
    setParams({
      pagination: {
        page: 0,
        onePage: itemsPerPage
      },
      filter: undefined,
      sorting: undefined
    });
  };

  const setPage = (nextPage) => {
    setParams({
      ...params,
      pagination: {
        ...params.pagination,
        page: nextPage
      }
    });
  };

  return {
    pagination: params.pagination,
    sorting: params.sorting,
    filter: params.filter,
    params,
    setSort,
    loadMore,
    reset,
    setFilter,
    setPage
  };
};
