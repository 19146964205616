import React from 'react';
import { useFieldFast } from './useFieldFast';
import { NewNumberField } from './NewNumberField';

export function NewFloatInput(props) {
  const [field, meta, helpers] = useFieldFast(props);

  return (
    <NewNumberField
      onChange={helpers.setValue}
      value={meta.value}
      unit={''}
      {...props}
    />
  );
}
