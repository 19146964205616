import { useEffect } from 'react';

export const useSetMonth = (query, changeQuery) => {
  const currentDate = new Date();
  const currentYear = String(currentDate.getFullYear());
  const currentMonth = String(currentDate.getMonth() + 1);
  const year = String(query.year || currentYear);
  const maxMonth =
    currentYear === year
      ? currentDate.getDate() < 15
        ? currentMonth - 1
        : currentMonth
      : year > currentYear
      ? 0
      : 12;
  const maxRealMonth = maxMonth || 1;
  const month = String(Math.min(maxRealMonth, query.month || maxRealMonth));

  useEffect(() => {
    if (month !== query.month) {
      changeQuery(
        {
          month
        },
        true
      );
    }
  }, [changeQuery, month, query.month]);

  return [year, month, currentYear, maxMonth];
};
