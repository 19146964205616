import ReactNumberFormat from 'react-number-format';
import React, { useCallback } from 'react';

export function NumberFormatField({ inputRef, onChange, unit, ...other }) {
  const handleValueChange = useCallback(
    (values) => {
      onChange(values.floatValue);
    },
    [onChange]
  );

  return (
    <ReactNumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={handleValueChange}
      thousandSeparator=" "
      decimalSeparator=","
      suffix={unit}
    />
  );
}
