import React, { useEffect, useMemo } from 'react';
import { Prompt } from 'react-router-dom';
import { FastField, Field, Form, useField } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useCurrentUser } from '../../../../store/useCurrentUser';
import {
  useOrganization,
  useRegions
} from '../../../../store/subsidiaryEntity/useSubsidiaryEntity';
import { useRoadmaps } from '../../../../store/roadmap/useRoadmaps';
import { ActionButton } from '../../../../common/Form/ActionButton';
import { validateNumeric } from '../../../../utils/number';
import { TextInput } from '../../../../common/Inputs/TextInput';
import InputGroup from '../../../../common/Inputs/InputGroup';
import { FormHeader } from '../../../../common/Form/Form';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { User } from '../../../../store/role/User';
import { NewAutocompleteInput } from '../../../../common/InputsNew/NewAutocompleteInput';
import { useSeTypes } from '../../../../store/subsidiaryEntity/useSeTypes';
import { useMemoSuggestions } from '../../../../utils/suggestions';
import { CustomFastField } from '../../../../common/Inputs/CustomFastField';
import { OrganizationInput } from './OrganizationInput';
import s from './SubsidiaryEntityEditForm.module.scss';

const addressFields = [
  'index',
  'region_string',
  'locality',
  'street',
  'house',
  'building',
  'latitude',
  'longitude'
];

const addressDefaultData = {
  index: '',
  locality: '',
  street: '',
  house: '',
  building: '',
  latitude: '',
  longitude: '',
  region_string: ''
};

const isSmallSchoolOptions = [
  {
    label: 'Да',
    value: true
  },
  {
    label: 'Нет',
    value: false
  }
];

export const SubsidiaryEntityEditForm = ({
  onBackClick,
  isSubmitting,
  editor,
  status,
  touched,
  errors,
  values,
  subsidiaryEntity,
  setValues,
  setFieldValue,
  availableYears,
  createForm
}) => {
  const user = useCurrentUser();
  // const [{ value }, meta, { setValue }] = useField('is_small_school_field_required');

  const regionsQuery = useRegions();
  const allRegions = regionsQuery.state.data;
  const regions = useMemo(() => User.getRegions(user, allRegions), [
    user,
    allRegions
  ]);

  const seTypesQuery = useSeTypes(
    values.owner_roadmap_id && {
      roadmap_ids: values.owner_roadmap_id
    }
  );
  const seTypes = seTypesQuery.state.data;

  const roadmapsQuery = useRoadmaps({
    region_id: values.region_id,
    years: [+values.year_of_creation]
  });
  const roadmaps = roadmapsQuery.state.data?.data;

  const canEditRegion = regions?.length > 1;

  const organizationQuery = useOrganization(values.base_organization_id);
  const organization = organizationQuery.state.data;

  const regionsSuggestions = useMemoSuggestions(regions);
  const seTypesSuggestions = useMemoSuggestions(seTypes);
  const roadmapsSuggestions = useMemoSuggestions(roadmaps);

  const currRoadmapId = values?.owner_roadmap_id;
  const currentRoadmap = useMemo(() => {
    return roadmaps?.find((rm) => rm.id === currRoadmapId);
  }, [roadmaps, currRoadmapId]);

  useEffect(() => {
    setFieldValue(
      'is_small_school_field_required',
      !!currentRoadmap?.is_small_school_field_required
    );
  }, [setFieldValue, currentRoadmap]);

  useEffect(() => {
    if (values.base_organization_name) {
      setFieldValue('showAddressFields', true);
    } else {
      setFieldValue('showAddressFields', false);
    }
  }, [setFieldValue, values.base_organization_name]);

  useEffect(() => {
    if (values.base_organization_id) {
      setFieldValue('base_organization_name', '');
    }
  }, [setFieldValue, values.base_organization_id]);

  const isManualAddress = useMemo(() => {
    return addressFields.some((field) => values[field]);
  }, [values]);

  useEffect(() => {
    if (isManualAddress) {
      setFieldValue('showAddressFields', true);
    }
  }, [isManualAddress, setFieldValue]);

  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <Form noValidate>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting}
          editor={editor}
          isSubmitting={isSubmitting}
        />
        <InputGroup title="Общие сведения">
          <NewAutocompleteInput
            name="year_of_creation"
            label="Год"
            suggestions={availableYears}
            fullWidth
            required
            disabled={!createForm}
            isClearable={false}
          />
          <NewAutocompleteInput
            name="region_id"
            label="Субъект Российской Федерации"
            suggestions={regionsSuggestions}
            fullWidth
            required
            isDisabled={!canEditRegion}
          />
          <NewAutocompleteInput
            name="owner_roadmap_id"
            label="Дорожная карта"
            suggestions={roadmapsSuggestions}
            fullWidth
            isDisabled={!values.region_id || !values.year_of_creation}
            required
          />
          <NewAutocompleteInput
            name="se_type_id"
            label="Тип"
            suggestions={seTypesSuggestions}
            fullWidth
            required
            isDisabled={!values.owner_roadmap_id}
          />
          <NewAutocompleteInput
            name="is_small_school"
            label="Малокомплектная школа"
            suggestions={isSmallSchoolOptions}
            fullWidth
            required
            isClearable
            isDisabled={!values.owner_roadmap_id}
          />
        </InputGroup>
        <InputGroup>
          <FastField
            name="name_full"
            label="Название субсидиарной сущности"
            component={TextInput}
            required
            fullWidth
            errorMessage={touched.name_full && errors.name_full}
            multiline
          />
        </InputGroup>
        <InputGroup title="Название образовательной организации">
          <Field
            name="base_organization_id"
            component={OrganizationInput}
            errorMessage={
              touched.base_organization_id && errors.base_organization_id
            }
            regionId={values.region_id}
            defaultValue={subsidiaryEntity?.base_organization}
            required
          />
          <CustomFastField
            name="base_organization_name"
            label="Ввод вручную"
            component={TextInput}
            fullWidth
            errorMessage={
              touched.base_organization_name && errors.base_organization_name
            }
            disabled={!!values.base_organization_id}
            multiline
            dependentFields={['base_organization_id']}
          />
        </InputGroup>
        <InputGroup>
          {!values.showAddressFields && (
            <FastField
              name="address"
              label="Адрес"
              component={TextInput}
              fullWidth
              errorMessage={touched.address && errors.address}
              multiline
              disabled
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={values.showAddressFields}
                onChange={(e) => {
                  setValues({
                    ...values,
                    showAddressFields: e.target.checked
                  });
                }}
                color="primary"
                disabled={!!values.base_organization_name || isManualAddress}
              />
            }
            label="Ввести адрес вручную"
            className={s.gutterBottom}
          />
          {values.showAddressFields && (
            <div className={s.gutterBottom}>
              <FastField
                name="index"
                label="Индекс"
                validate={validateNumeric}
                required
                component={TextInput}
                fullWidth
                errorMessage={touched.index && errors.index}
                multiline
              />
              <FastField
                name="region_string"
                label="Регион"
                component={TextInput}
                required
                fullWidth
                errorMessage={touched.region_string && errors.region_string}
                multiline
                dependentFields={['base_organization_name']}
              />
              <FastField
                name="locality"
                label="Город"
                component={TextInput}
                required
                fullWidth
                errorMessage={touched.locality && errors.locality}
                multiline
                dependentFields={['base_organization_name']}
              />
              <FastField
                name="street"
                label="Улица"
                component={TextInput}
                required
                fullWidth
                errorMessage={touched.street && errors.street}
                multiline
                dependentFields={['base_organization_name']}
              />
              <FastField
                name="house"
                label="Дом"
                component={TextInput}
                required
                fullWidth
                errorMessage={touched.house && errors.house}
                multiline
                dependentFields={['base_organization_name']}
              />
              <FastField
                name="building"
                label="Корпус/Строение"
                component={TextInput}
                fullWidth
                errorMessage={touched.building && errors.building}
                multiline
              />
              <FastField
                name="latitude"
                label="Широта"
                component={TextInput}
                fullWidth
                errorMessage={touched.latitude && errors.latitude}
                multiline
              />
              <p className={s.info}>
                Координаты необходимы для корректного отображения организации на
                карте РФ
              </p>
              <FastField
                name="longitude"
                label="Долгота"
                component={TextInput}
                fullWidth
                errorMessage={touched.longitude && errors.longitude}
                multiline
              />
              <p className={s.info}>
                Координаты необходимы для корректного отображения организации на
                карте РФ
              </p>
            </div>
          )}
          <FastField
            name="inn"
            label="ИНН"
            validate={validateNumeric}
            required
            component={TextInput}
            fullWidth
            errorMessage={touched.inn && errors.inn}
            inputProps={{ maxLength: 12 }}
          />
          <FastField
            name="kpp"
            label="КПП"
            validate={validateNumeric}
            required
            component={TextInput}
            fullWidth
            errorMessage={touched.kpp && errors.kpp}
            inputProps={{ maxLength: 9 }}
          />
          <ActionButton
            title="Скопировать из организации"
            onClick={() => {
              setValues({
                ...values,
                ...addressDefaultData,
                inn: organization?.inn,
                kpp: organization?.kpp,
                address: organization?.bs_address,
                showAddressFields: false
              });
            }}
            disabled={!organization}
          />
        </InputGroup>
        <InputGroup title={`Руководитель субсидиарной сущности`}>
          <FastField
            name="supervisor.surname"
            label="Фамилия"
            component={TextInput}
            required
            errorMessage={
              touched.supervisor?.surname && errors.supervisor?.surname
            }
            fullWidth
          />
          <FastField
            name="supervisor.first_name"
            label="Имя"
            component={TextInput}
            required
            errorMessage={
              touched.supervisor?.first_name && errors.supervisor?.first_name
            }
            fullWidth
          />
          <FastField
            name="supervisor.patronymic"
            label="Отчество"
            component={TextInput}
            required
            errorMessage={
              touched.supervisor?.patronymic && errors.supervisor?.patronymic
            }
            fullWidth
          />
          <FastField
            name="supervisor.phones[0]"
            label="Мобильный телефон"
            component={TextInput}
            required
            inputProps={{ maxLength: 20 }}
            errorMessage={
              (touched.supervisor?.phones && errors.supervisor?.phones) ||
              (touched.supervisor?.phones?.[0] &&
                errors.supervisor?.phones?.[0])
            }
            fullWidth
          />
          <FastField
            name="supervisor.emails[0]"
            label="Email"
            component={TextInput}
            required
            errorMessage={
              (touched.supervisor?.emails && errors.supervisor?.emails) ||
              (touched.supervisor?.emails?.[0] &&
                errors.supervisor?.emails?.[0])
            }
            fullWidth
          />
        </InputGroup>
      </Form>
    </>
  );
};
