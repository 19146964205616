import React from 'react';
import s from './List.module.scss';

export const List = ({ list, mapData = (item) => item }) => {
  return (
    <>
      <ul className={s.ListWithDescription}>
        {list?.map((item) => (
          <li className={s.ListWithDescription__item}>{mapData(item)}</li>
        ))}
      </ul>
    </>
  );
};
