import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import React, { useMemo, useState } from 'react';
import { Autocomplete } from '../../../../../common/infralist/FormFields/Autocomplete/Autocomplete';
import { LabelField } from '../../../../../common/infralist/FormFields/LabelField/LabelField';
import { FormikAutocomplete } from '../../../../../common/infralist/FormikFormFields/FormikAutocomplete/FormikAutocomplete';
import { FormikCurrency } from '../../../../../common/infralist/FormikFormFields/FormikCurrency';
import { FormikNumber } from '../../../../../common/infralist/FormikFormFields/FormikNumber';
import { FormikTextField } from '../../../../../common/infralist/FormikFormFields/FormikTextField/FormikTextField';
import {
  EQUIPMENT_STATUSES,
  equipmentStatusService
} from '../../../../../store/infralist/equipmentStatusService';
import { infralistCheckStatusService } from '../../../../../store/infralist/statuses/infralistCheckStatusService';
import { useEquipmentCheckStatuses } from '../../../../../store/infralist/useEquipmentCheckStatuses';
import {
  transformEquipmentNameListToMap,
  useEquipmentNameList
} from '../../../../../store/infralist/useEquipmentNameList';
import { useEquipmentStatuses } from '../../../../../store/infralist/useEquipmentStatuses';
import {
  useInfralistSectionUserAccesses,
  useInfralistUserAccesses
} from '../../../../../store/infralist/useInfralistUserAccesses';
import { localeNumberFormat } from '../../../../../utils/localeNumberFormat';
import s from '../InfralistSection.module.scss';

export const useInfralistSectionTableColumns = ({
  isSpecificationsHidden,
  sectionId,
  submitSignEquipment,
  submitCheckEquipment,
  submitApproveEquipment,
  sectionStatus,
  infralistSectionCheckStatus,
  checkEditEquipments,
  infralistStatus,
  infralistSectionApproveStatus,
  isEditEnabledByEventStatus
}) => {
  const {
    canFetchEquipmentStatuses,
    canFetchEquipmentCheckStatuses
  } = useInfralistUserAccesses();
  const {
    canSign,
    canCheck,
    showCheckActions,
    showStatusColumn,
    showStatusSignColumn,
    showCheckColumn,
    showApproveColumn,
    canApprove,
    showApproveActions
  } = useInfralistSectionUserAccesses({
    sectionStatus,
    infralistSectionCheckStatus,
    infralistStatus,
    infralistSectionApproveStatus,
    isEditEnabledByEventStatus
  });
  const { statusListOptions } = useEquipmentStatuses(canFetchEquipmentStatuses);
  const { checkStatusListOptions } = useEquipmentCheckStatuses(
    canFetchEquipmentCheckStatuses
  );
  const equipmentNameList = useEquipmentNameList(sectionId);
  const [
    processingStatusEquipmentId,
    setProcessingStatusEquipmentId
  ] = useState();
  return useMemo(() => {
    const equipmentMap = transformEquipmentNameListToMap(equipmentNameList);
    return [
      { label: '№', renderCell: (dataItem, index) => index + 1, width: 46 },
      {
        label: 'Наименование оборудования (ФПО)',
        renderCell: (dataItem) => (
          <div className={s.formattedColumn}>
            <Typography gutterBottom={true} variant={'inherit'} component={'p'}>
              <b>{equipmentMap.get(dataItem.fpo_equipment_id).label}</b>
            </Typography>
            {!isSpecificationsHidden &&
              equipmentMap.get(dataItem.fpo_equipment_id).description}
          </div>
        ),
        renderEditCell: (dataItem, dataItemKey, values) => {
          return (
            <>
              <Typography gutterBottom={true} component={'div'}>
                <FormikAutocomplete
                  name={'fpo_equipment_id'}
                  options={equipmentNameList}
                  searchable={false}
                  hasCopyBtn={true}
                />
              </Typography>
              {!isSpecificationsHidden &&
                equipmentMap.get(values.fpo_equipment_id).description}
            </>
          );
        },
        width: '2fr'
      },
      {
        label: 'Наименование оборудования (РВПО)',
        renderCell: (dataItem) => (
          <div className={s.formattedColumn}>
            <Typography gutterBottom={true} variant={'inherit'} component={'p'}>
              <b>{dataItem.name_rvpo}</b>
            </Typography>
            {!isSpecificationsHidden && dataItem.description_rvpo}
          </div>
        ),
        renderEditCell: () => (
          <>
            <Typography gutterBottom={true} component={'div'}>
              <FormikTextField
                name={'name_rvpo'}
                options={equipmentMap}
                searchable={true}
              />
            </Typography>
            <LabelField label={'Описание оборудования (РВПО)'} size={'min'}>
              <FormikTextField
                multiline={true}
                name={'description_rvpo'}
                placeholder={'Описание оборудования (РВПО)'}
              />
            </LabelField>
          </>
        ),
        width: '2fr'
      },
      {
        label: 'Примерная модель (РВПО)',
        renderCell: (dataItem) => dataItem.model_rvpo,
        renderEditCell: () => (
          <FormikTextField
            name={'model_rvpo'}
            placeholder={'Примерная модель (РВПО)'}
            multiline
          />
        ),
        width: '1fr'
      },
      {
        label: 'Кол-во',
        renderCell: (dataItem) =>
          `${localeNumberFormat({ number: dataItem.quantity })} ${
            dataItem.unit
          }`,
        renderEditCell: () => (
          <>
            <Typography gutterBottom={true} component={'div'}>
              <FormikNumber name={'quantity'} />
            </Typography>
            <LabelField label={'Ед. изм.'} size={'min'}>
              <FormikTextField name={'unit'} />
            </LabelField>
          </>
        ),
        width: '0.8fr'
      },
      {
        label: 'Цена, руб.',
        renderCell: (dataItem) =>
          localeNumberFormat({
            number: dataItem.price,
            maxLength: 2,
            minLength: 2
          }),
        renderEditCell: () => <FormikCurrency name={'price'} />,
        sort: 'price',
        width: '1fr'
      },
      {
        label: 'Стоимость, руб.',
        renderCell: (dataItem) =>
          localeNumberFormat({
            number: dataItem.price * dataItem.quantity,
            minLength: 2,
            maxLength: 2
          }),
        renderEditCell: (dataItem, dataItemKey, values) =>
          localeNumberFormat({
            number: values.price * values.quantity,
            maxLength: 2,
            minLength: 2
          }),
        sort: 'full_price',
        width: '1fr'
      },
      {
        label: 'Ссылка на оборудование',
        renderCell: (dataItem) =>
          dataItem.equipment_external_url?.split('\n').map((link, index) => (
            <Link
              target="_blank"
              display={'block'}
              href={link}
              title={link}
              color="inherit"
              underline={'always'}
              key={`${link + index}`}
              style={{
                whiteSpace: 'nowrap'
              }}
            >
              {link}
            </Link>
          )),
        renderEditCell: () => (
          <FormikTextField
            name="equipment_external_url"
            placeholder={'Ссылка на оборудование'}
            isValueTrimmed
            multiline
          />
        )
      },
      // Статус формирования
      {
        label: 'Статус',
        renderCell: (dataItem) => {
          return equipmentStatusService.getColoredName(
            dataItem?.equipment_status?.name
          );
        },
        sort: 'status',
        width: '1fr',
        isActive: showStatusColumn
      },
      // Статус подписания
      {
        label: 'Статус',
        renderCell: (dataItem) => {
          const canEditEquipment = checkEditEquipments(dataItem);
          // Когда инфралист не согласован нужно показывать Подписан для согласованного оборудования
          // Если оборудование было согласовано, то логично что оно было подписано
          if (
            [
              EQUIPMENT_STATUSES.APPROVED,
              EQUIPMENT_STATUSES.APPROVED_WITH_COMMENTS
            ].includes(dataItem.equipment_status?.name)
          ) {
            return equipmentStatusService.getColoredName(
              EQUIPMENT_STATUSES.SIGNED
            );
          }

          if (!canEditEquipment) {
            return equipmentStatusService.getColoredName(
              dataItem.equipment_status?.name
            );
          }

          return (
            <Autocomplete
              name={'Status'}
              options={statusListOptions}
              value={dataItem.equipment_status?.id}
              onChange={(nextStatusId) => {
                setProcessingStatusEquipmentId(dataItem.id);
                submitSignEquipment({
                  equipmentId: dataItem.id,
                  statusId: nextStatusId
                }).finally(() => {
                  setProcessingStatusEquipmentId(undefined);
                });
              }}
              placeholder={'Выбрать статус'}
              disabled={
                !canEditEquipment || dataItem.id === processingStatusEquipmentId
              }
            />
          );
        },
        sort: 'status',
        width: 175,
        isActive: showStatusSignColumn
      },
      // Статус проверки
      {
        label: 'Результат проверки',
        renderCell: (dataItem) => {
          const canEditEquipment = checkEditEquipments(dataItem);
          if (!showCheckActions) {
            return infralistCheckStatusService.getColoredName(
              dataItem.check_status?.name
            );
          }
          return (
            <Autocomplete
              name={'Status'}
              options={checkStatusListOptions}
              value={dataItem.check_status?.id}
              onChange={(nextStatusId) => {
                setProcessingStatusEquipmentId(dataItem.id);
                submitCheckEquipment({
                  equipmentId: dataItem.id,
                  statusId: nextStatusId
                }).finally(() => {
                  setProcessingStatusEquipmentId(undefined);
                });
              }}
              placeholder={'Выбрать статус'}
              disabled={
                !canEditEquipment ||
                !canCheck ||
                dataItem.id === processingStatusEquipmentId
              }
            />
          );
        },
        sort: 'check_status',
        width: 175,
        isActive: showCheckColumn
      },
      {
        label: 'Статус согласования',
        renderCell: (dataItem) => {
          // Кто не может редактировать, видит просто результат согласования текстом
          if (!showApproveActions) {
            return equipmentStatusService.getColoredName(
              dataItem?.equipment_approvement_status?.name
            );
          } else {
            return (
              <Autocomplete
                name={'Status'}
                options={statusListOptions}
                value={dataItem.equipment_approvement_status?.id}
                onChange={(nextStatusId) => {
                  setProcessingStatusEquipmentId(dataItem.id);
                  submitApproveEquipment({
                    equipmentId: dataItem.id,
                    statusId: nextStatusId
                  }).finally(() => {
                    setProcessingStatusEquipmentId(undefined);
                  });
                }}
                placeholder={'Выбрать статус'}
                disabled={
                  !canApprove || dataItem.id === processingStatusEquipmentId
                }
              />
            );
          }
        },
        sort: 'approvement_status',
        width: 175,
        isActive: showApproveColumn
      }
    ];
  }, [
    canApprove,
    canCheck,
    checkEditEquipments,
    checkStatusListOptions,
    equipmentNameList,
    isSpecificationsHidden,
    processingStatusEquipmentId,
    showApproveActions,
    showApproveColumn,
    showCheckActions,
    showCheckColumn,
    showStatusColumn,
    showStatusSignColumn,
    statusListOptions,
    submitApproveEquipment,
    submitCheckEquipment,
    submitSignEquipment
  ]);
};
