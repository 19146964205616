import { parse as parseUrl } from 'url';
import { createBrowserHistory } from 'history';
import { getParentUrl } from './utils/url';

function increaseDepth(state = {}) {
  return {
    ...state,
    depth: (state?.depth || 0) + 1
  };
}

export function createAppHistory() {
  const baseHistory = createBrowserHistory();

  function getLocation() {
    return baseHistory.location;
  }

  function resolveParentUrl() {
    return getParentUrl(getLocation().pathname);
  }

  function goBack(parent = null, preserved = null) {
    const curLocation = getLocation();
    const appDepth = (curLocation.state && curLocation.state.depth) || 0;
    if (appDepth > 0) {
      baseHistory.goBack();
    } else {
      let url = parent;
      if (parent === null) {
        url = resolveParentUrl();
      }
      if (preserved) {
        url = preserved.createLink(url);
      }

      if (typeof url === 'string') {
        const parsed = parseUrl(url);
        baseHistory.push({
          state: curLocation.state,
          pathname: parsed.pathname,
          search: parsed.search
        });
      } else {
        baseHistory.push({
          state: curLocation.state,
          ...url
        });
      }
    }
  }

  function pushState(state) {
    const curLocation = getLocation();
    baseHistory.push({
      state: {
        ...increaseDepth(curLocation.state),
        ...(state || {})
      }
    });
  }

  function push(location) {
    const curLocation = getLocation();
    const state = increaseDepth(curLocation.state);
    if (typeof location === 'string') {
      const parsed = parseUrl(location);
      baseHistory.push({
        state,
        pathname: parsed.pathname,
        search: parsed.search
      });
    } else {
      baseHistory.push({
        state,
        ...location
      });
    }
  }

  function replace(location) {
    const curLocation = getLocation();
    const state = curLocation.state;
    if (typeof location === 'string') {
      const parsed = parseUrl(location);
      baseHistory.replace({
        state,
        pathname: parsed.pathname,
        search: parsed.search
      });
    } else {
      baseHistory.replace({
        state,
        ...location
      });
    }
  }

  function replaceQuery(query) {
    const location = getLocation();
    replace({
      ...location,
      query: {
        ...location.query,
        ...query
      }
    });
  }

  function pushWithQuery(pathname) {
    push({
      pathname,
      query: {
        ...getLocation().query
      }
    });
  }

  return {
    ...baseHistory,
    goBack,
    push,
    replace,
    replaceQuery,
    pushWithQuery,
    getLocation,
    pushState
  };
}
