import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Signal } from '../../../../common/Inputs/Signals';
import { DeleteButton } from '../../../../common/DeleteButton';
import { CancelButton } from '../../../../common/CancelButton';
import { getFullFileName } from '../../../../utils/data/file';
import SubTable from '../../../../common/SubTable/SubTable';
import { apiUrl } from '../../../../config';
import { formatFileSize } from '../../../../utils/formatNumber';
import { number } from '../../../../utils/number';
import s from './DownloadFileInput.module.scss';

function FileName({ document, fileName, file, baseLink }) {
  return (
    <TableRow>
      <TableCell style={{ padding: '5px', border: 'none' }}>
        {document.isNew ? (
          <span>{fileName}</span>
        ) : (
          <a
            className={'external'}
            href={`${apiUrl}/files/universalDocumentFile/${document.id}`}
          >
            {fileName}
          </a>
        )}
        {(file.file_size || document.file_size) && (
          <div>
            <span className={s.filePropCaptionStyle}>Размер: </span>
            <span>{formatFileSize(number(file.file_size))}</span>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
}

export const SimpleDocumentFile = ({ document }) => {
  const fileId = document.id;

  if (!fileId) {
    return (
      <div>
        <Signal>Ошибка. Файл недоступен</Signal>
      </div>
    );
  }

  const fileName = getFullFileName(document);

  return (
    <div>
      <SubTable cols={1}>
        <FileName
          document={document}
          file={document}
          fileName={fileName}
          baseLink={'/files/universalDocumentFile'}
        />
      </SubTable>
    </div>
  );
};

export const DocumentUploadFileSimple = ({
  documentId,
  document,
  onDelete,
  onCancelUpload,
  canUploadDocuments,
  disabled,
  ...props
}) => {
  const errorMessage = document?.uploadingFile?.errorMessage;

  return (
    <div>
      {(document.uploadingFile &&
        ((document.uploadingFile.error && (
          <>
            <Signal>{errorMessage}</Signal>
            <DeleteButton onClick={onDelete} />
          </>
        )) ||
          (document.uploadingFile.uploading && (
            <div className={s.wrapper}>
              <LinearProgress
                className={s.progressBar}
                variant="determinate"
                value={document.uploadingFile.progress}
              />
              <CancelButton onClick={onCancelUpload} />
            </div>
          )))) || (
        <div className={s.wrapper}>
          <SimpleDocumentFile
            documentId={documentId}
            document={document}
            disabled={disabled}
            {...props}
          />
          <DeleteButton disabled={disabled} onClick={onDelete} />
        </div>
      )}
    </div>
  );
};

export const SimpleFileEditable = (props) => {
  const { document, ...otherProps } = props;

  return <DocumentUploadFileSimple document={document} {...otherProps} />;
};

export const SimpleFileView = ({ document, showDocumentStatus = true }) => {
  if (!document) {
    return (
      <div>
        <Signal>Ошибка. Файл недоступен</Signal>
      </div>
    );
  }
  const fileName = document.file_name;

  return (
    <div className={s.fileField}>
      <div className={s.gutterBottomStyle}>
        <a
          className={'external'}
          href={`${apiUrl}/files/universalDocumentFile/${document.id}`}
        >
          {fileName}
        </a>
      </div>
      {document.file_size && (
        <div>
          <span className={s.filePropCaptionStyle}>Размер: </span>
          <span>{formatFileSize(number(document.file_size))}</span>
        </div>
      )}
      {document.description && (
        <div>
          <span className={s.filePropptionStyle}>Описание: </span>
          <span>{document.description}</span>
        </div>
      )}
    </div>
  );
};
