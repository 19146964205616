import React, { useMemo } from 'react';
import BreadCrumbs from '../../../common/BreadCrumbs/BreadCrumbs';
import { infralistRoutes } from '../../../store/infralist/routes/infralistRoutes';
import { useInfralistVersion } from '../../../store/infralist/versions/useInfralistVersion';
import { getInfralistPageBreadCrumbs } from '../InfralistPageBreadCrumbs';

export function getInfralistDirectionBreadCrumbs({
  roadmapName,
  infralistId,
  generatePathBasedOnVersion
}) {
  return [
    ...getInfralistPageBreadCrumbs({ roadmapName }),
    {
      label: 'Формирование и согласование перечня оборудования',
      to: generatePathBasedOnVersion(infralistRoutes.INFRALIST, { infralistId })
    }
  ];
}

export const InfralistDirectionBreadCrumbs = ({ infralistId, roadmapName }) => {
  const { generatePathBasedOnVersion } = useInfralistVersion();

  const breadCrumbsLinks = useMemo(() => {
    return getInfralistDirectionBreadCrumbs({
      roadmapName,
      infralistId,
      generatePathBasedOnVersion
    });
  }, [generatePathBasedOnVersion, infralistId, roadmapName]);

  return <BreadCrumbs links={breadCrumbsLinks} />;
};
