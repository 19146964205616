import isNumber from 'lodash-es/isNumber';
import isNil from 'lodash-es/isNil';
import { formatValueUnit } from './formatNumber';
import { unitRuble } from './constants';
import { isNumberLike } from './helpers';

export const number = (x) => (isNumber(x) ? +x : 0);
export const toFloat = (x) => {
  const parsed = Number.parseFloat(x);
  return !Number.isNaN(parsed) ? parsed : 0;
};

export const sumNumbers = (first, second) =>
  isNumber(first) && isNumber(second) ? first + second : null;

const sum = (args) => {
  return args.reduce((acc, val) => acc + val);
};

export const sumNumbersNullish = (...args) => {
  if (args.some((arg) => isNumber(arg))) {
    return sum(args.map(number));
  } else {
    return null;
  }
};

export const subtractNumbers = (first, second) =>
  isNumber(first) && isNumber(second) ? first - second : null;

export const zeroToNull = (x) => (x === 0 ? null : x);
export const zeroToString = (x) => (x === 0 ? '' : '' + x);
export const formatCurrency = (value) => formatValueUnit(value, unitRuble);
export const renderPlanFactDiff = ({ plan, fact }) =>
  formatCurrency(subtractNumbers(plan, fact));

export const validateNumberRegexp = new RegExp(
  /^([-+]?)([0-9]*)((\.[0-9]*)?)$/
);
export function validateNumeric(value) {
  if (!value) return;
  if (!value.toString().match(validateNumberRegexp)) {
    return 'Значение должно содержать только цифры';
  }
}

export function toFixed(value, digits, defaultValue) {
  if (!isNumberLike(value)) return defaultValue ?? null;
  return !isNil(digits) ? (+value).toFixed(digits) : value;
}

export function compareFinancial(a, b) {
  const aa = a || 0;
  const bb = b || 0;
  const af = aa.toFixed(2);
  const bf = bb.toFixed(2);
  return af === bf ? 0 : aa < bb ? -1 : 1;
}
