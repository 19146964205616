import { useMemo } from 'react';
import { pick } from 'lodash-es';
import { getName } from '../../utils/data/label';
import { EnumHelpers } from '../../utils/EnumHelpers';
import { formatNumber } from '../../utils/formatNumber';
import { compareSimple, makeComparator } from '../../utils/sorting';
import { User } from '../role/User';
import { useMbtFinancesGrouped } from './useMbtFinances';

const rpImplementationForecastFields = [
  'region_id',
  'agreement_forecasts',
  'indicator_forecasts',
  'passport_forecasts',
  'roadmap_forecasts',
  'subsidy_forecasts',
  'result_forecasts',
  'status'
];

const agreementForecastsFields = [
  'federal_project_id',
  'agreement_number',
  'suggestions'
];

const indicatorForecastsFields = [
  'federal_project_id',
  'indicator_id',
  'forecast_value',
  'changes_justification',
  'forecast_value_entered',
  'annotation'
];

const passportForecastsFields = ['federal_project_id', 'suggestions'];

const roadmapForecastsFields = ['roadmap_id', 'suggestions'];

const subsidyForecastsFields = [
  'federal_project_id',
  'expenses_direction_id',
  'forecast_value',
  'annotation',
  'forecast_value_entered'
];

const resultForecastsFields = [
  'federal_project_id',
  'result_federal_id',
  'forecast_value',
  'changes_justification',
  'plan_value'
];

export class RpImplementationForecast {
  static Status = {
    APPROVED_BY_REGION: 'APPROVED_BY_REGION',
    DRAFT: 'DRAFT'
  };

  static status = new EnumHelpers(this.Status, {
    APPROVED_BY_REGION: 'Утверждено регионом',
    DRAFT: 'Черновик'
  });

  static editTableStyles = {
    tableCellSmall: {
      width: '100px'
    },
    tableCellMedium: {
      minWidth: '140px',
      maxWidth: '250px'
    },
    tableCellLarge: {
      minWidth: '140px',
      maxWidth: '350px'
    }
  };

  static getFederalProjectsOptions(regionalProject) {
    return useMemo(() => {
      if (!regionalProject) return null;
      return regionalProject.map((project) => ({
        value: project.federal_project?.id,
        label: getName(project.federal_project)
      }));
    }, [regionalProject]);
  }

  static filterUpdateFields(data) {
    return pick(data, ['id', ...rpImplementationForecastFields]);
  }

  static getUrl(rpImplementationForecast) {
    return `/rpImplementationForecast/${rpImplementationForecast.id}`;
  }

  static canEdit(rpImplementationForecast, user) {
    return (
      (User?.hasPermission(user, 'rp_forecast.crud') &&
        rpImplementationForecast?.status ===
          RpImplementationForecast.Status.DRAFT) ||
      User.isAdmin(user)
    );
  }

  static canApprove(rpImplementationForecast, user) {
    return (
      rpImplementationForecast?.status ===
        RpImplementationForecast.Status.DRAFT &&
      (User?.hasPermission(user, 'rp_forecast.crud') || User.isAdmin(user))
    );
  }

  static toForm(data, projects, roadmaps, expensesDirections, indicators) {
    const indicatorForecastsMap = (item) => {
      const values = item.values ? item.values[0] : null;
      const regionalProject = values?.plan_data?.regional_project;
      const federal_project = regionalProject?.federal_project;
      const period = values?.period;
      const value = formatNumber(values?.value_number, 10, true);

      const indicatorForecasts = data?.indicator_forecasts?.find(
        (i_f) =>
          i_f.federal_project_id === federal_project.id &&
          i_f?.indicator_id === values.indicator_id
      );

      return {
        id: item.id,
        name: getName(item),
        unit: item.unit,
        forecast_value: '',
        changes_justification: '',
        annotation: '',
        federal_project,
        value,
        ...indicatorForecasts,
        ...{
          forecast_value: indicatorForecasts?.forecast_value_entered
            ? indicatorForecasts.forecast_value
            : ''
        }
      };
    };

    const subsidyForecastsMap = (expensesDirection) => {
      const subsidyForecast = data?.subsidy_forecasts?.find(
        (item) =>
          item.federal_project_id === expensesDirection.federal_project_id &&
          item.expenses_direction_id === expensesDirection.id
      );
      return {
        expenses_direction: {
          code: expensesDirection.code,
          id: expensesDirection.id,
          name_full: expensesDirection.name_full
        },
        federal_project: { ...expensesDirection.federal_project },
        forecast_value: expensesDirection.forecast_value || '',
        annotation: expensesDirection.annotation || '',
        ...subsidyForecast,
        ...{
          forecast_value: subsidyForecast?.forecast_value_entered
            ? subsidyForecast.forecast_value
            : ''
        }
      };
    };

    const passportForecastsMap = (project) => {
      const passportForecast = data?.passport_forecasts?.find(
        (item) => item.federal_project_id === project.federal_project_id
      );
      return {
        ...project,
        suggestions: project.suggestions || '',
        ...passportForecast
      };
    };

    const roadmapForecastsMap = (roadmap) => {
      const roadmapForecast = data?.roadmap_forecasts?.find(
        (item) => item.roadmap_id === roadmap.id
      );
      return {
        ...roadmap,
        suggestions: roadmap.suggestions || '',
        ...roadmapForecast
      };
    };

    return {
      ...data,
      agreement_forecasts: data.agreement_forecasts
        ? data.agreement_forecasts
        : [],
      indicator_forecasts: indicators?.map(indicatorForecastsMap)?.sort(
        makeComparator(
          compareSimple,
          (indicator) => +indicator.federal_project.project_code,
          (indicator) => indicator.code
        )
      ),
      subsidy_forecasts: expensesDirections?.map(subsidyForecastsMap),
      passport_forecasts: projects?.map(passportForecastsMap),
      roadmap_forecasts: roadmaps
        ?.map(roadmapForecastsMap)
        ?.sort(makeComparator(compareSimple, (roadmap) => roadmap.code)),
      result_forecasts: data.result_forecasts || []
    };
  }

  static fromForm({
    region_id,
    agreement_forecasts,
    passport_forecasts,
    roadmap_forecasts,
    subsidy_forecasts,
    indicator_forecasts,
    result_forecasts,
    ...rest
  }) {
    const agreementForecasts =
      agreement_forecasts?.map((item) =>
        pick(item, agreementForecastsFields)
      ) || [];

    const passportForecasts =
      passport_forecasts?.map((item) => pick(item, passportForecastsFields)) ||
      [];

    const roadmapForecasts =
      roadmap_forecasts?.map((item) =>
        pick({ ...item, roadmap_id: item.id }, roadmapForecastsFields)
      ) || [];

    const subsidyForecasts =
      subsidy_forecasts?.map((item) =>
        pick(
          {
            ...item,
            forecast_value: !item.forecast_value ? 0 : item.forecast_value,
            federal_project_id: item.federal_project.id,
            expenses_direction_id: item.expenses_direction.id,
            forecast_value_entered:
              item.forecast_value === 0 ? true : !!item.forecast_value
          },
          subsidyForecastsFields
        )
      ) || [];

    const indicatorForecasts =
      indicator_forecasts?.map((item) =>
        pick(
          {
            ...item,
            forecast_value: !item.forecast_value ? 0 : item.forecast_value,
            federal_project_id: item.federal_project.id,
            indicator_id: item.id,
            forecast_value_entered:
              item.forecast_value === 0 ? true : !!item.forecast_value
          },
          indicatorForecastsFields
        )
      ) || [];

    const resultForecasts =
      result_forecasts?.map((item) => pick(item, resultForecastsFields)) || [];

    return {
      region_id,
      agreement_forecasts: agreementForecasts,
      passport_forecasts: passportForecasts,
      roadmap_forecasts: roadmapForecasts,
      subsidy_forecasts: subsidyForecasts,
      indicator_forecasts: indicatorForecasts,
      result_forecasts: resultForecasts,
      ...rest
    };
  }

  static useMbtFinancesGrouped({ region_id }) {
    return useMbtFinancesGrouped(
      { region_ids: region_id, hasPlanYearly: true, year: 2020 },
      'expenses_direction'
    );
  }

  static getMbtFinance(expenses_direction, mbtFinances) {
    return mbtFinances.find(
      (mbtFinance) =>
        mbtFinance.expenses_direction_id === expenses_direction?.id
    )?.plan_yearly_clear;
  }
}
