import { pick } from 'lodash-es';
import { EnumHelpers } from '../../utils/EnumHelpers';

const notificationField = ['recipient_ids', 'content', 'section', 'subject'];

export class Notifications {
  static Type = {
    mail: 'mail',
    notification: 'notification',
    all: 'all'
  };

  static type = new EnumHelpers(this.Type, {
    mail: 'Почта',
    notification: 'Система уведомлений',
    all: 'Везде'
  });

  static Sections = {
    POF: 'POF',
    PURCHASE: 'PURCHASE',
    ROADMAP: 'ROADMAP',
    OTHER: 'OTHER'
  };

  static sections = new EnumHelpers(this.Sections, {
    POF: 'Раздел "ПОФ"',
    PURCHASE: 'Раздел "Закупки"',
    ROADMAP: 'Раздел "Дорожные карты"',
    OTHER: 'Другой раздел'
  });

  static filterCreateFields(data) {
    return pick(data, notificationField);
  }

  static toForm(input) {
    return {
      ...input
    };
  }

  static fromForm({ recipients, ...data }) {
    return {
      ...data,
      recipient_ids: recipients
        ?.map((item) => +item.value || null)
        .filter((x) => x)
    };
  }
}
