import React from 'react';

import { Formik } from 'formik';

import { useContextStore } from '@proscom/prostore-react';

import { userValidationSchema } from '../../../../utils/data/validation';
import { UserBreadCrumbs } from '../UserBreadCrumbs';
import { removeLastPathItem } from '../../../../utils/url';
import { UserEditForm } from '../edit/UserEditForm';
import { User } from '../../../../store/role/User';
import {
  getGraphQLErrorInfo,
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../graphql/graphqlErrors';

import { USER_STORE } from '../../../../store/names';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { useCurrentUser } from '../../../../store/useCurrentUser';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';

export function parseUserDuplicateError(error) {
  const errorInfo = getGraphQLErrorInfo(error);
  if (errorInfo.statusCode === 422) {
    if (errorInfo.message === 'login already taken') {
      return {
        field: 'login',
        statusCode: 422,
        message: 'Данный логин уже существует, выберите другой'
      };
    }
    if (errorInfo.message === 'email already taken') {
      return {
        field: 'email',
        statusCode: 422,
        message: 'Данный email уже существует, выберите другой'
      };
    }
  }
  return null;
}

export default function UserCreatePage({ match, history }) {
  const backLink = removeLastPathItem(match.url);
  const userStore = useContextStore(USER_STORE);

  const initialValues = {
    name: '',
    email: '',
    login: '',
    roles: [
      {
        id: undefined
      }
    ],
    new_password: undefined,
    repeat_password: undefined
  };

  const currentUser = useCurrentUser();

  return (
    <>
      <HeadTitle type="Пользователь" pageType={HeadTitle.PageType.create} />
      <UserBreadCrumbs />
      <h2>Создание пользователя</h2>
      <Formik
        validationSchema={userValidationSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          const user = User.fromForm(values, currentUser);
          userStore
            .createUser(user)
            .then((result) => {
              actions.setStatus(FORM_STATUS_SUBMITTED);
              setTimeout(() => {
                history.push(User.getUrl(result.data.result));
              }, 0);
            })
            .catch((error) => {
              const duplicateError = parseUserDuplicateError(error);
              if (duplicateError) {
                actions.setFieldError(
                  duplicateError.field,
                  duplicateError.message
                );
              }
              showGraphqlErrorToast(
                error,
                mutationErrors.updateObject,
                duplicateError
              );
            })
            .then(() => {
              actions.setSubmitting(false);
            });
        }}
        render={(formikProps) => (
          <UserEditForm
            {...formikProps}
            onBackClick={() => history.goBack(backLink)}
          />
        )}
      />
    </>
  );
}
