import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import {
  QUERY_ORGANIZATION,
  QUERY_REGIONS,
  QUERY_SUBSIDIARY_ENTITY,
  QUERY_SUBSIDIARY_ENTITY_YEAR_OF_CREATION
} from '../../graphql/queries/subsidiaryEntity';

const subsidiaryEntityQueryOptions = {
  query: QUERY_SUBSIDIARY_ENTITY,
  mapData: (result) => result.subsidiaryEntity,
  skipQuery: skipIfNull(null)
};

const subsidiaryEntityYearOfCreationQueryOptions = {
  query: QUERY_SUBSIDIARY_ENTITY_YEAR_OF_CREATION,
  mapData: (result) => result.subsidiaryEntity,
  skipQuery: skipIfNull(null)
};

const regionsQueryOptions = {
  query: QUERY_REGIONS,
  mapData: (result) => result.regions
};

const organizationQueryOptions = {
  query: QUERY_ORGANIZATION,
  mapData: (result) => result.organization,
  skipQuery: skipIfNull(null)
};

export function useSubsidiaryEntity(id, networkOnly = false) {
  return useRetriableGraphqlVars({
    queryOptions: subsidiaryEntityQueryOptions,
    variableCreator: [
      () =>
        id
          ? {
              id
            }
          : null,
      [id]
    ],
    options: {
      apolloOptions: {
        fetchPolicy: networkOnly ? 'network-only' : 'cache-first'
      }
    }
  });
}

export function useSubsidiaryEntityYearOfCreation(id) {
  return useRetriableGraphqlVars({
    queryOptions: subsidiaryEntityYearOfCreationQueryOptions,
    variableCreator: [
      () =>
        id
          ? {
              id
            }
          : null,
      [id]
    ]
  });
}

export function useRegions(ids) {
  return useRetriableGraphqlVars({
    queryOptions: regionsQueryOptions,
    variableCreator: [
      () => ({
        ids: ids
      }),
      [ids]
    ]
  });
}

export function useOrganization(id) {
  return useRetriableGraphqlVars({
    queryOptions: organizationQueryOptions,
    variableCreator: [
      () =>
        id
          ? {
              id
            }
          : null,
      [id]
    ]
  });
}
