import React from 'react';

import { queryLoaderWithExistenceCheck } from '../../../common/QueryLoader';
import { useRegionalRoadmapEvent } from '../../../store/roadmap/useRegionalRoadmapEvent';

import RegionalEventPage from './RegionalEventPage';

export default function RegistryRegionalEventPage({ match }) {
  const { roadmapId, eventId, regionalEventId } = match.params;

  const regionalRoadmapEventQuery = useRegionalRoadmapEvent(
    regionalEventId,
    true
  );
  const regionalEvent = regionalRoadmapEventQuery.state.data;
  const event = regionalEvent?.roadmap_event;

  return (
    queryLoaderWithExistenceCheck(regionalRoadmapEventQuery) || (
      <RegionalEventPage
        event={event}
        regionalEvent={regionalEvent}
        regionalEventId={regionalEventId}
        currentUrl={match.url}
        roadmapId={roadmapId}
        eventId={eventId}
      />
    )
  );
}
