import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SubTable from '../../../common/SubTable/SubTable';
import { DateCell } from '../../../common/TableCells';

export function PurchaseChanges({ title, items, oneDateOnly }) {
  if (!items || items.length === 0) return null;
  return (
    <SubTable title={title} cols={4}>
      <TableRow>
        <TableCell>№</TableCell>
        {!oneDateOnly && <TableCell>Дата начала перенесенного срока</TableCell>}
        <TableCell>
          Дата {!oneDateOnly && 'окончания'} перенесенного срока
        </TableCell>
        <TableCell>Причина (обоснование)</TableCell>
      </TableRow>
      {items.map((change, index) => (
        <TableRow key={index}>
          <TableCell>{change.change_id}</TableCell>
          {!oneDateOnly && <DateCell value={change.date_start} />}
          <DateCell value={change.date_end} />
          <TableCell>{change.reason}</TableCell>
        </TableRow>
      ))}
    </SubTable>
  );
}
