import React from 'react';
import { StatusService } from '../../utils/StatusService';
import colors from '../../colors.scss';

export const EQUIPMENT_STATUSES = {
  NOT_FORMED: 'Не сформировано',
  FORMED: 'Сформировано',
  NOT_SIGNED: 'Не подписано',
  SIGNED: 'Подписано',
  APPROVED: 'Согласовано',

  ON_APPROVAL: 'На согласовании',
  WAS_NOT_IN_APPROVE: 'Не согласовывалось',
  APPROVED_WITH_COMMENTS: 'Согласовано с замечаниями',
  NOT_APPROVED: 'Не согласовано'
};

export const equipmentStatusService = new StatusService({
  colors: {
    [EQUIPMENT_STATUSES.NOT_FORMED]: colors.graytable,
    [EQUIPMENT_STATUSES.FORMED]: colors.greenBright,
    [EQUIPMENT_STATUSES.NOT_SIGNED]: colors.red,
    [EQUIPMENT_STATUSES.SIGNED]: colors.greenBright,
    [EQUIPMENT_STATUSES.APPROVED]: colors.greenBright,
    [EQUIPMENT_STATUSES.NOT_APPROVED]: colors.red,
    [EQUIPMENT_STATUSES.APPROVED_WITH_COMMENTS]: colors.yellowLight,
    [EQUIPMENT_STATUSES.ON_APPROVAL]: colors.blue
  },
  defaultStatus: EQUIPMENT_STATUSES.NOT_FORMED,
  emptyStatus: EQUIPMENT_STATUSES.WAS_NOT_IN_APPROVE
});
