import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash-es';

/**
 * Хук для отслеживания изменений как useEffect, только с глубоким сравнением зависимостей
 * !!! Может вызывать зависания с большими данными, надо использовать осторожно
 *
 * @param callback - функция запускаемая при изменении
 * @param deps {[]} - массив зависимостей
 */
export const useEffectWithDeepCompare = (callback, deps = []) => {
  const previewDeps = useRef();
  const cleanupFunction = useRef();

  const hasChanges =
    !previewDeps.current ||
    deps.find((dep, index) => !isEqual(dep, previewDeps.current[index]));

  previewDeps.current = deps;

  if (hasChanges) {
    if (cleanupFunction.current) {
      cleanupFunction.current();
    }
    cleanupFunction.current = callback();
  }

  useEffect(() => {
    return () => {
      cleanupFunction.current && cleanupFunction.current();
    };
  }, []);
};
