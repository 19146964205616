import React from 'react';
import { useUrlQuery } from '../../../../common/useUrlQuery';
import { getCurrentYear } from '../../../../utils/data/date';
import { YearSelect } from '../../../../common/YearSelect';
import { RoadmapsTable } from '../../../roadmaps/RoadmapsTable';

export const ProjectRoadmaps = ({
  location,
  history,
  project,
  projectYear
}) => {
  const [query, changeQuery] = useUrlQuery(location, history);
  const year = query.year || getCurrentYear();

  return (
    <>
      <YearSelect
        label="Год"
        year={projectYear}
        years={project?.implementation_years}
        onChange={changeQuery}
      />
      <RoadmapsTable
        projectId={project.id}
        year={projectYear}
        filter={{
          federal_project_id: project.federal_project_id,
          region_id: project.region_id,
          implementation_years: +year
        }}
      />
    </>
  );
};
