import { pick } from 'lodash-es';
import { DocumentStatus } from './documentStatus';

const roadmapEventDocumentFields = [
  'id',
  'subsidiary_entity_id',
  'photo_category_id',
  'document_slot_id',
  'type',
  'name',
  'content',
  'number',
  'license_expiration_date',
  'date_approval',

  // Поля инпута
  'comment_fpo',
  'status',
  'connected_file_id'
];

const federalEventFields = [
  'id',
  'annotation',
  'fact_term',
  'region_id',
  'documents'
];

const responsiblePersonFields = [
  'id',
  'surname',
  'first_name',
  'patronymic',
  'phones',
  'emails'
];

export class FederalEvent {
  static filterUpdateFields(data) {
    return {
      ...pick(data, federalEventFields),
      responsible_person: pick(data.responsible_person, responsiblePersonFields)
    };
  }

  static getUrl(federalEvent) {
    return `/federalEvents/${federalEvent.id}`;
  }

  static getFederalEventUrl(roadmapEvent) {
    return `/roadmaps/${roadmapEvent.roadmap_id}/events/${roadmapEvent.id}`;
  }

  static fromForm({ responsible_person, roadmap_event_documents, ...fields }) {
    const convertedDocuments = (roadmap_event_documents || [])
      .filter(
        (document) =>
          (document.connected_file && document.connected_file.id) ||
          document.content
      )
      .map((document) => ({
        comment_fpo: '',
        status: DocumentStatus.status.NOT_APPROVED,
        ...pick(document, roadmapEventDocumentFields),
        connected_file_id: document.connected_file
          ? document.connected_file.id
          : undefined
      }));

    return {
      ...fields,
      responsible_person: {
        first_name: '',
        surname: '',
        patronymic: '',
        ...pick(responsible_person, responsiblePersonFields),
        occupation: responsible_person?.individual_data?.occupations || '',
        department: ''
      },
      documents: convertedDocuments
    };
  }

  static toForm({ responsible_person, ...event }) {
    return {
      ...event,
      responsible_person: responsible_person || {}
    };
  }
}
