import React from 'react';
import Dialog from '../../common/Dialog';

export function SubsidiaryEntityValidationDialog({ onCancel, isOpen }) {
  return (
    <Dialog
      open={isOpen}
      showAcceptButton={false}
      titleRefuse="OK"
      handleClose={onCancel}
    >
      Вы не можете утвердить субсидиарную сущность, если все обязательные поля
      не заполнены
    </Dialog>
  );
}
