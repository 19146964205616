import { BehaviorStore } from '@proscom/prostore';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash-es';
import { addSeconds, isBefore, isValid } from 'date-fns';
import { pairwise } from 'rxjs/operators';
import { SubscriptionManager } from '../utils/SubscriptionManager';
import {
  MUTATION_LOGIN_WITH_EMAIL,
  MUTATION_LOGOUT,
  MUTATION_USE_REFRESH_TOKEN
} from '../graphql/mutations/auth';
import { singleton } from '../utils/singleton';
import {
  getGraphQLErrorInfo,
  getParsedErrorMessage
} from '../graphql/graphqlErrors';
import { tryParseIso } from '../utils/date';
import { SingleTimeoutManager } from '../common/SingleTimeoutManager';
import { userUpdateIntervalSeconds } from '../config';
import { LOCAL_STORAGE_AUTH } from './LocalStorageStore';

export const ERROR_REFRESH_TOKEN_INVALID =
  'Сессия истекла. Пожалуйста, войдите заново';

const clearState = {
  accessToken: null,
  refreshToken: null,
  user: null
};

export class AuthStore extends BehaviorStore {
  localStorageStore;
  client;
  sub = new SubscriptionManager();
  refreshTimeout = new SingleTimeoutManager();

  constructor({ localStorageStore, client }) {
    super({
      authData: null,
      loaded: false,
      error: null
    });

    this.localStorageStore = localStorageStore;
    this.client = client;
  }

  subscribe() {
    this.sub.subscribe(this.state$.pipe(pairwise()), this._handleStateChange);
    this.sub.subscribe(
      this.localStorageStore.get$(LOCAL_STORAGE_AUTH),
      this._handleLocalStorageChange
    );
  }

  unsubscribe() {
    this.sub.destroy();
    this.refreshTimeout.clear();
  }

  _handleStateChange = ([oldState, state]) => {
    const { authData } = state;
    if (authData?.refreshToken?.expires_at) {
      let timeToRefresh = 0;

      const expiresAt = tryParseIso(authData.refreshToken.expires_at);
      if (expiresAt) {
        timeToRefresh = Math.max(expiresAt - new Date() - 60000, 0);
      }

      const updatedAt = tryParseIso(authData.updated_at);
      const timeBeforeNextUpdate = Math.max(
        addSeconds(updatedAt, userUpdateIntervalSeconds) - new Date(),
        0
      );
      timeToRefresh = Math.min(timeToRefresh, timeBeforeNextUpdate);
      // todo тут надо рефрешить только пользователя, т.к. токен еще актуален
      //  либо рефрешить так, чтобы ошибка не разлогинивала пользователя

      if (oldState.loaded === false && state.loaded === true) {
        timeToRefresh = 0;
      }

      this.refreshTimeout.set(() => {
        this.refreshToken();
      }, timeToRefresh);
    } else {
      this.refreshTimeout.clear();
    }
  };

  _handleLocalStorageChange = (authData) => {
    if (!isEqual(authData, this.state.authData)) {
      if (authData && isRefreshTokenValid(authData.refreshToken)) {
        this.setState({
          authData
        });
      } else if (this.state.loaded) {
        this._setError(ERROR_REFRESH_TOKEN_INVALID);
      } else {
        this.setState({
          authData: null
        });
      }
    }

    if (!this.state.loaded) {
      this.setState({
        loaded: true
      });
    }
  };

  _saveToLocalStorage(authData) {
    this.localStorageStore.setItem(LOCAL_STORAGE_AUTH, {
      accessToken: authData.accessToken,
      refreshToken: authData.refreshToken,
      user: authData.user,
      updated_at: authData.updated_at
    });
  }

  _setAuthenticationData(authData) {
    authData.updated_at = new Date().toISOString();
    this.setState({ authData });
    this._saveToLocalStorage(authData);
  }

  _setError(error) {
    this._setAuthenticationData(clearState);
    this.setState({
      error
    });
    const errorMessage = getParsedErrorMessage(error);
    toast.error(errorMessage);
  }

  async logOut() {
    try {
      const { authData } = this.state;
      const token = authData?.refreshToken?.token;

      let result = null;
      if (token) {
        result = await this.client.mutate({
          mutation: MUTATION_LOGOUT,
          variables: {
            token
          }
        });
      }

      setTimeout(() => {
        window.location.reload();
      }, 0);

      return result;
    } finally {
      this._setAuthenticationData(clearState);
    }
  }

  async loginWithEmail(email, password) {
    const result = await this.client.mutate({
      mutation: MUTATION_LOGIN_WITH_EMAIL,
      variables: {
        email,
        password
      }
    });
    if (result.error) {
      throw result;
    }
    const authData = result.data.authData;
    this._setAuthenticationData(authData);
    return authData;
  }

  async _useRefreshToken(token) {
    try {
      const result = await this.client.mutate({
        mutation: MUTATION_USE_REFRESH_TOKEN,
        variables: {
          token
        }
      });

      const authData = result.data.authData;
      this._setAuthenticationData(authData);
      return authData;
    } catch (error) {
      const parsedError = getGraphQLErrorInfo(error);
      this._setError(error);
      return null;
    }
  }

  async _performTokenRefresh() {
    const { refreshToken } = this.state.authData || {};

    if (!isRefreshTokenValid(refreshToken)) {
      this._setError(ERROR_REFRESH_TOKEN_INVALID);
      return;
    }

    return await this._useRefreshToken(refreshToken.token);
  }

  refreshToken = singleton(() => this._performTokenRefresh());

  isRefreshingToken() {
    return !!this.refreshToken.promise;
  }

  canRefreshToken() {
    const { authData } = this.state;
    return isRefreshTokenValid(authData && authData.refreshToken);
  }

  isLoggedIn() {
    const { authData } = this.state;
    return authData && authData.accessToken;
  }
}

/**
 * Проверка устаревания долгосрочного токена
 */
export function isRefreshTokenValid(refreshToken) {
  if (!refreshToken || !refreshToken.token || !refreshToken.expires_at) {
    return false;
  }
  const expirationDate = tryParseIso(refreshToken.expires_at);
  return isValid(expirationDate) && isBefore(new Date(), expirationDate);
}

export function getAuthRegionId(auth) {
  const user = auth.authData && auth.authData.user;
  return user && user.region && user.region.id;
}
