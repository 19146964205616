import { clearApolloQueryCache } from '../../utils/apollo';
import {
  MUTATION_ALLOW_EDIT_CONTEST_DOCS_FOR_SS_ENTITY,
  MUTATION_APPROVE_CONTEST_DOCS_FOR_SS_ENTITY,
  MUTATION_DELETE_CONTEST_DOCS_FOR_SS_ENTITY,
  MUTATION_REJECT_EDIT_CONTEST_DOCS_FOR_SS_ENTITY,
  MUTATION_REQUEST_EDIT_CONTEST_DOCS_FOR_SS_ENTITY,
  MUTATION_UPDATE_CONTEST_DOCS_FOR_SS_ENTITY
} from '../../graphql/mutations/contestDocsForSSMonitoring';

export class ContestDocsForSSMonitoringStore {
  constructor({ client }) {
    this.client = client;
  }

  queriesToClear = ['tenderDocumentationForSSPage', 'tenderDocumentationForSS'];

  queriesToRefetch = [
    'tenderDocumentationForSSPage',
    'tenderDocumentationForSS'
  ];

  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  update = (input) => {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_CONTEST_DOCS_FOR_SS_ENTITY,
      variables: {
        input
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };

  delete = (id) => {
    return this.client.mutate({
      mutation: MUTATION_DELETE_CONTEST_DOCS_FOR_SS_ENTITY,
      variables: {
        id
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };

  approve = (id) => {
    return this.client.mutate({
      mutation: MUTATION_APPROVE_CONTEST_DOCS_FOR_SS_ENTITY,
      variables: {
        id
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };

  requestEdit = (id) => {
    return this.client.mutate({
      mutation: MUTATION_REQUEST_EDIT_CONTEST_DOCS_FOR_SS_ENTITY,
      variables: {
        id
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };

  allowEdit = (id) => {
    return this.client.mutate({
      mutation: MUTATION_ALLOW_EDIT_CONTEST_DOCS_FOR_SS_ENTITY,
      variables: {
        id
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };

  rejectEdit = (id) => {
    return this.client.mutate({
      mutation: MUTATION_REJECT_EDIT_CONTEST_DOCS_FOR_SS_ENTITY,
      variables: {
        id
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };
}
