import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import s from './SearchForm.module.scss';

export const SearchForm = ({
  changeQuery,
  initialInput,
  label = 'Поиск по проектам',
  className,
  ...props
}) => {
  const [searchInput, setSearchInput] = useState(initialInput || '');
  useEffect(() => setSearchInput(initialInput || ''), [initialInput]);

  const handleChange = (e) => {
    !e.target.value && changeQuery({ search: '' });
    setSearchInput(e.target.value);
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        changeQuery({ search: searchInput });
      }}
      className={classNames(s.SearchForm, className)}
      {...props}
    >
      <TextField
        label={label}
        name="searchInput"
        type="search"
        className={s.SearchInput}
        autoFocus
        value={searchInput}
        onChange={handleChange}
      />
      <button className={s.SearchButton} type="submit">
        <SearchIcon className={s.SearchIcon} />
      </button>
    </form>
  );
};
