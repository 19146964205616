import React, { useCallback, useEffect, useMemo } from 'react';
import { getCurrentYear, yearsSuggestions } from '../../utils/data/date';
import { useMemoSuggestions } from '../../utils/suggestions';
import AutocompleteBaseInput from '../../common/Inputs/Selects/AutocompleteBaseInput';
import { queryLoader } from '../../common/QueryLoader';
import { useSubsidiaryEntitiesAvailableForContestDocsMonitoring } from '../../store/subsidiaryEntity/useSubsidiaryEntities';
import { SubsidiaryEntity } from '../../store/subsidiaryEntity/SubsidiaryEntity';
import { ContestDocsForSSMonitoringEntity } from '../../store/contestDocsForSSMonitoring/ContestDocsForSSMonitoringEntity';
import { useUserRole } from '../../store/role/useUserRole';
import { useRegions } from '../../store/subsidiaryEntity/useSubsidiaryEntity';
import { User } from '../../store/role/User';
import { useFederalProjectsAvailableForContestDocsMonitoring } from '../../store/federalProject/useFederalProjects';
import { useRoadmapsAvailableForContestDocsMonitoring } from '../../store/roadmap/useRoadmaps';

export const useContestDocsMonitoringFilter = (query, changeQuery, user) => {
  const { isRegionAdmin } = useUserRole(user);

  const year = query.year || getCurrentYear().toString();

  const regionsQuery = useRegions();
  const regions = regionsQuery.state.data;
  const availableRegions = regions && User.getRegions(user, regions);
  const regionsSuggestions = useMemoSuggestions(availableRegions);

  const federalProjectsQuery = useFederalProjectsAvailableForContestDocsMonitoring(
    {
      year: +year
    }
  );
  const federalProjects = federalProjectsQuery.state.data;
  const federalProjectsSuggestions = useMemoSuggestions(federalProjects);

  const regionId = isRegionAdmin
    ? user?.region?.id
    : query.region_id || regionsSuggestions?.[0]?.value;
  const federalProjectId =
    query.federal_project_id || federalProjectsSuggestions?.[0]?.value;

  const filter = {
    region_id: regionId || undefined,
    federal_project_id: federalProjectId || undefined,
    roadmap_id: query.roadmap_id || undefined,
    subsidiary_entity_id: query.subsidiary_entity_id || undefined,
    status: query.status,
    year: +year
  };

  const setFilterValue = useCallback(
    (value, name) => {
      const queryObj = {
        [name]: value ?? undefined,
        page: 0
      };
      return changeQuery(queryObj);
    },
    [changeQuery]
  );

  useEffect(() => {
    setFilterValue(year, 'year');
  }, [year, setFilterValue]);

  useEffect(() => {
    if (regionId) {
      setFilterValue(regionId, 'region_id');
    }
  }, [regionId, setFilterValue]);

  const roadmapsFilter = useMemo(() => {
    if (!filter.year || !filter.federal_project_id) return null;
    return {
      federal_project_id: filter.federal_project_id,
      year: filter.year
    };
  }, [filter]);
  const roadmapsQuery = useRoadmapsAvailableForContestDocsMonitoring(
    roadmapsFilter
  );
  const roadmaps = roadmapsQuery.state.data;
  const roadmapsSuggestions = useMemoSuggestions(roadmaps);

  const subsidiaryEntitiesFilter = useMemo(() => {
    if (!filter.year || !filter.federal_project_id) return null;
    return {
      region_id: filter.region_id,
      federal_project_id: filter.federal_project_id,
      roadmap_id: filter.roadmap_id,
      year: filter.year
    };
  }, [filter]);
  const subsidiaryEntitiesQuery = useSubsidiaryEntitiesAvailableForContestDocsMonitoring(
    subsidiaryEntitiesFilter
  );
  const querySubsidiaryEntities = subsidiaryEntitiesQuery.state.data;
  const subsidiaryEntities = useMemo(() => {
    return querySubsidiaryEntities?.map((entity) => ({
      ...entity,
      name_full: SubsidiaryEntity.getCompleteName(entity)
    }));
  }, [querySubsidiaryEntities]);
  const subsidiaryEntitiesSuggestions = useMemoSuggestions(subsidiaryEntities);

  const filterComponent = queryLoader(regionsQuery) || (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Год"
        name="year"
        suggestions={yearsSuggestions}
        value={year}
        variant="standard"
        isClearable={false}
      />
      {!isRegionAdmin && regionsSuggestions?.length > 1 && (
        <AutocompleteBaseInput
          onChange={setFilterValue}
          hasCopyBtn={false}
          label="Регион"
          name="region_id"
          suggestions={regionsSuggestions}
          suggestionsLoading={regionsQuery.check.spinner}
          value={filter.region_id}
          variant="standard"
          disabled={isRegionAdmin}
          isClearable={false}
        />
      )}
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Федеральный проект"
        name="federal_project_id"
        suggestions={federalProjectsSuggestions}
        suggestionsLoading={federalProjectsQuery.check.spinner}
        value={filter.federal_project_id}
        variant="standard"
        isClearable={false}
      />
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Дорожная карта"
        name="roadmap_id"
        suggestions={roadmapsSuggestions}
        suggestionsLoading={roadmapsQuery.check.spinner}
        value={filter.roadmap_id}
        variant="standard"
        resetIfNotExists={true}
        disabled={!roadmapsFilter}
      />
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Субсидиарная сущность"
        name="subsidiary_entity_id"
        suggestions={subsidiaryEntitiesSuggestions}
        suggestionsLoading={subsidiaryEntitiesQuery.check.spinner}
        value={filter.subsidiary_entity_id}
        variant="standard"
        resetIfNotExists={true}
        disabled={!subsidiaryEntitiesFilter}
      />
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Статус"
        name="status"
        suggestions={ContestDocsForSSMonitoringEntity.statusSuggestions}
        value={filter.status}
        variant="standard"
      />
    </div>
  );

  return [filter, filterComponent];
};
