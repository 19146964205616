import { loadingBreadcrumb } from '../../../../common/BreadCrumbs/BreadCrumbs';
import { getFillFormBreadCrumbs } from '../getFillFormBreadCrumbs';

export function getFormDocumentBreadCrumbs({ formId, formName }) {
  return [
    ...getFillFormBreadCrumbs(),
    {
      label: loadingBreadcrumb(formName),
      to: `/fillForms/${formId}`
    }
  ];
}
