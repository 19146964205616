import { formatDistanceToNow, differenceInHours } from 'date-fns';
import { ru } from 'date-fns/locale';
import { reformatDatetime } from '../../../../../utils/date';

export const useCommentDate = (dateString) => {
  const diff = Math.abs(differenceInHours(new Date(), new Date(dateString)));
  return diff >= 24
    ? reformatDatetime(dateString)
    : formatDistanceToNow(new Date(dateString), {
        locale: ru,
        addSuffix: true
      });
};
