import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import {
  MUTATION_APPROVE_ALL_EQUIPMENTS,
  MUTATION_APPROVE_EQUIPMENT,
  MUTATION_COMPLETE_APPROVEMENT
} from '../../graphql/mutations/equipment';
import { clearApolloQueryCache } from '../../utils/apollo';
import { INFRALIST_QUERIES } from './infralistQueries';

export const useApprovement = () => {
  const client = useContextApolloClient();

  const approveAllEquipments = ({ infralistId, sectionId }) => {
    return client.mutate({
      mutation: MUTATION_APPROVE_ALL_EQUIPMENTS,
      variables: {
        infralist_id: infralistId,
        section_id: sectionId
      },
      update: (store) =>
        clearApolloQueryCache(store, [
          INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT
        ])
    });
  };

  const approveRvpoEquipments = ({ infralistId, equipemntId, statusId }) => {
    return client.mutate({
      mutation: MUTATION_APPROVE_EQUIPMENT,
      variables: {
        infralist_id: infralistId,
        rvpo_equipment_id: equipemntId,
        status_id: statusId
      },
      update: (store) =>
        clearApolloQueryCache(store, [
          INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT
        ])
    });
  };

  const completeApprovment = ({ infralistId, sectionId }) => {
    return client.mutate({
      mutation: MUTATION_COMPLETE_APPROVEMENT,
      variables: {
        infralist_id: infralistId,
        section_id: sectionId
      },
      update: (store) =>
        clearApolloQueryCache(store, [
          INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT
        ]),
      refetchQueries: [INFRALIST_QUERIES.SECTION]
    });
  };

  return { completeApprovment, approveAllEquipments, approveRvpoEquipments };
};
