import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useState } from 'react';
import { MUTATION_COMPLETE_SIGNING_DIRECTION } from '../../../graphql/mutations/equipment';
import { INFRALIST_QUERIES } from '../infralistQueries';

export const useCompleteSigningDirection = () => {
  const client = useContextApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  const completeSigningDirection = ({ infralistId, directionId }) => {
    setIsLoading(true);
    return client
      .mutate({
        mutation: MUTATION_COMPLETE_SIGNING_DIRECTION,
        variables: {
          infralist_id: infralistId,
          direction_id: directionId
        },
        refetchQueries: [
          INFRALIST_QUERIES.DIRECTION_SECTIONS,
          INFRALIST_QUERIES.DIRECTION
        ],
        update: (store) => {
          store.reset();
        }
      })
      .finally(() => setIsLoading(false));
  };

  return {
    isLoadingCompleteSigningDirection: isLoading,
    completeSigningDirection
  };
};
