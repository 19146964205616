import React from 'react';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import { useRegionalRoadmapEvent } from '../../../../store/roadmap/useRegionalRoadmapEvent';
import RegionalEventEditPage from './RegionalEventEditPage';

export default function RegistryToRegionalEventEdit({ match, history }) {
  const { roadmapId, eventId, regionalEventId } = match.params;

  const regionalRoadmapEventQuery = useRegionalRoadmapEvent(
    regionalEventId,
    true
  );
  const regionalEvent = regionalRoadmapEventQuery.state.data || {};
  const event = regionalEvent?.roadmap_event;

  return (
    queryLoaderWithExistenceCheck(regionalRoadmapEventQuery) || (
      <RegionalEventEditPage
        event={event}
        regionalEvent={regionalEvent}
        currentUrl={match.url}
        history={history}
        roadmapId={roadmapId}
        eventId={eventId}
      />
    )
  );
}
