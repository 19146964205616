import React from 'react';
import { FieldArray } from 'formik';
import { Spoiler } from '../../../../common/Spoiler/Spoiler';
import { ContestDocsIndicatorPreview } from '../ContestDocsIndicator/ContestDocsIndicatorPreview';

export const ContestDocsRoadmapPreview = ({ index, roadmap }) => {
  const indicators = roadmap.roadmap_indicators;
  const hasIndicators = !!indicators && indicators.length > 0;

  return hasIndicators ? (
    <Spoiler title={`${roadmap.roadmap?.name}:`}>
      <FieldArray name={`roadmaps[${index}].indicators`}>
        {() =>
          indicators?.map((ind, iInd) => (
            <ContestDocsIndicatorPreview
              key={iInd}
              index={iInd}
              rmIndex={index}
              indicator={ind}
            />
          ))
        }
      </FieldArray>
    </Spoiler>
  ) : null;
};
