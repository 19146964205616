import React, { useCallback, useMemo, useState } from 'react';
import { useGraphqlWatchQuery } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { format } from 'date-fns';
import { debounce } from 'lodash-es';
import TextField from '@material-ui/core/TextField';
import { Popup } from '../Popup/Popup';
import { queryLoader } from '../QueryLoader';
import { parseApiDate, removeTimezoneFromDate } from '../../utils/data/date';
import { DATE_FORMAT_RUSSIAN } from '../../utils/date';
import { EMDASH } from '../../utils/constants';
import s from './ExportHistoryPopup.module.scss';

export const ExportHistoryPopup = ({ open, onClose, title, id, query }) => {
  const [searchVal, setSearchVal] = useState('');
  const [search, setSearch] = useState('');

  const handleInputChange = useCallback((value) => {
    setSearchVal(value);
  }, []);

  const handleSearchChange = useCallback(
    debounce((value) => {
      setSearch(value);
    }, 500),
    []
  );

  const queryOptions = useMemo(() => {
    return {
      query: query || '',
      mapData: (data) => data.list,
      skipQuery: skipIfNull(null)
    };
  }, [query]);

  const variables = useMemo(() => {
    return open && id
      ? {
          filter: {
            monitoring_document_id: id,
            search
          }
        }
      : null;
  }, [open, id, search]);

  const historyQuery = useGraphqlWatchQuery({
    queryOptions,
    variables,
    options: {
      apolloOptions: {
        fetchPolicy: 'network-only'
      }
    }
  });
  // const historyQueryLoading = historyQuery.check.spinner;
  const historyList = historyQuery.state.data;
  const hasHistoryList = !!historyList && historyList.length > 0;

  return (
    <Popup title={title || 'История экспорта'} open={open} onClose={onClose}>
      <div className={s.Search}>
        <TextField
          className={s.Search__input}
          label={'Поиск по хешу'}
          type="search"
          autoFocus
          value={searchVal}
          onChange={(e) => {
            handleInputChange(e.target.value);
            handleSearchChange(e.target.value);
          }}
        />
      </div>

      {queryLoader(historyQuery) ||
        (hasHistoryList ? (
          <div className={s.List}>
            {historyList.map((item, iItem) => {
              const userName =
                item.c_user?.user?.name || item.c_user?.user?.login;

              const d = removeTimezoneFromDate(parseApiDate(item.export_date));
              const data = d && format(d, DATE_FORMAT_RUSSIAN);

              const hash = item.hash_string;

              return (
                <div key={iItem} className={s.HistoryItem}>
                  <div className={s.HistoryItem__date}>{data || EMDASH}</div>
                  {userName && (
                    <div className={s.HistoryItem__name}>{userName}</div>
                  )}
                  <div className={s.HistoryItem__hash}>{hash || EMDASH}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <div>Нет данных</div>
        ))}
    </Popup>
  );
};
