import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { QUERY_INFRALIST } from '../../graphql/queries/infralist';

const infralistQueryOptions = {
  query: QUERY_INFRALIST,
  mapData: (result) => result.infralist,
  skipQuery: skipIfNull(null)
};

export function useInfralist(infralistId) {
  return useRetriableGraphql({
    queryOptions: infralistQueryOptions,
    variables: {
      id: infralistId
    }
  });
}
