import React, { useMemo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FastField } from 'formik';
import { EMDASH } from '../../../../utils/constants';
import TableInput from '../../../../common/Table/TableInput';
import SubTable from '../../../../common/SubTable/SubTable';
import { isNumberLike } from '../../../../utils/helpers';
import { toFixed } from '../../../../utils/number';

const componentsColumns = [
  {
    field: 'name_full',
    width: '56%',
    title: 'Компонент'
  },
  {
    field: 'unit',
    title: 'Единица измерения'
  },
  {
    field: 'value',
    title: 'Фактическое значение',
    type: 'numeric',
    render: ({ value, precision }) => {
      return toFixed(value, precision) ?? EMDASH;
    }
  }
];

export const SubsidiaryMonitoringIndicatorPreview = ({ index, indicator }) => {
  const indicatorUnit = indicator?.roadmap_indicator?.unit?.name_local;
  const value = indicator?.value;
  const plan_value = indicator?.plan_value;
  const precision = indicator?.roadmap_indicator?.precision || 0;

  const valueWithPrecision = useMemo(() => {
    return toFixed(value, precision);
  }, [value, precision]);

  const indicatorValuesDiff = useMemo(() => {
    if (!isNumberLike(value)) return null;
    return toFixed(value - (plan_value || 0), precision);
  }, [value, plan_value, precision]);

  const components = indicator?.components;
  const hasComponents = !!components && components.length > 0;

  return (
    <SubTable title={`Индикатор - ${indicator.roadmap_indicator.name_full}`}>
      <TableRow>
        <TableCell>Единица измерения</TableCell>
        <TableCell>{indicatorUnit || EMDASH}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          Плановое значение в целом по субъекту РФ на конец отчетного периода
        </TableCell>
        <TableCell>{plan_value ?? EMDASH}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell>Достигнутое значение в целом по субъекту РФ</TableCell>
        <TableCell>{valueWithPrecision ?? EMDASH}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell>Отклонение от планового значения индикатора</TableCell>
        <TableCell>{indicatorValuesDiff ?? EMDASH}</TableCell>
      </TableRow>

      {hasComponents && (
        <TableRow>
          <TableCell colSpan={2}>
            <FastField
              name={`indicators[${index}].components`}
              tableName="Компоненты расчета индикатора"
              columns={componentsColumns}
              component={TableInput}
              canAdd={false}
              canUpdate={false}
              canDelete={false}
            />
          </TableCell>
        </TableRow>
      )}

      <TableRow>
        <TableCell>Примечание</TableCell>
        <TableCell>{indicator?.note || EMDASH}</TableCell>
      </TableRow>
    </SubTable>
  );
};
