import gql from 'graphql-tag';

export const QUERY_GET_PURCHASE_COMPONENT_CATEGORIES = gql`
  query purchaseComponentCategories {
    purchaseComponentCategories {
      id
      code
      name
      name_full
    }
  }
`;
