import gql from 'graphql-tag';
import { FRAGMENT_ORGANIZATION_FOR_LIST } from './organizations';

export const FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT = gql`
  fragment subsidiaryEntityForSelect on SubsidiaryEntityType {
    id
    name_full
    base_organization {
      ...organizationForList
    }
  }

  ${FRAGMENT_ORGANIZATION_FOR_LIST}
`;

export const FRAGMENT_SUBSIDIARY_ENTITY = gql`
  fragment subsidiaryEntity on SubsidiaryEntityType {
    ...subsidiaryEntityForSelect

    code
    form_of_incorporation
    base_organization_id
    base_organization_name

    address
    index
    region_string
    locality
    street
    house
    building
    latitude
    longitude
    is_approved
    is_small_school

    inn
    kpp

    region {
      id
      name
    }
    owner_roadmap_id
    region_id

    supervisor {
      id
      first_name
      surname
      patronymic
      phones
      emails
    }
    version
    year_of_creation

    se_type {
      id
      code
      name
      name_full
    }
  }

  ${FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT}
`;

export const QUERY_SUBSIDIARY_ENTITY = gql`
  query subsidiaryEntity($id: ID!) {
    subsidiaryEntity(id: $id) {
      ...subsidiaryEntity
    }
  }
  ${FRAGMENT_SUBSIDIARY_ENTITY}
`;

export const QUERY_SUBSIDIARY_ENTITY_YEAR_OF_CREATION = gql`
  query subsidiaryEntityYearOfCreation($id: ID!) {
    subsidiaryEntity(id: $id) {
      year_of_creation
    }
  }
`;

export const QUERY_REGIONS = gql`
  query regions($ids: [ID!]) {
    regions(ids: $ids) {
      id
      code
      name
      code_nalog
    }
  }
`;

export const QUERY_ORGANIZATION = gql`
  query organization($id: ID!) {
    organization(id: $id) {
      ...organizationForList
    }
  }

  ${FRAGMENT_ORGANIZATION_FOR_LIST}
`;
