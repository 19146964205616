import { skipIfNull } from '@proscom/prostore';

import {
  useIntervalGraphql,
  useRetriableGraphqlVars
} from '../../common/useRetriableGraphqlVars';
import {
  QUERY_GET_EVENT_INFRALISTS,
  QUERY_GET_EVENT_INFRALISTS_STATUS
} from '../../graphql/queries/eventInfralists';

const eventInfralistsQueryOptions = {
  query: QUERY_GET_EVENT_INFRALISTS,
  mapData: (result) => result.infralists,
  skipQuery: skipIfNull(null)
};

const eventInfralistsStatusQueryOptions = {
  query: QUERY_GET_EVENT_INFRALISTS_STATUS,
  mapData: (result) => result.infralists,
  skipQuery: skipIfNull(null)
};

export function useEventInfralists(regional_roadmap_event_id) {
  return useRetriableGraphqlVars({
    queryOptions: eventInfralistsQueryOptions,
    variableCreator: [
      () =>
        regional_roadmap_event_id
          ? {
              filter: {
                regional_roadmap_event_ids: [regional_roadmap_event_id]
              }
            }
          : null,
      [regional_roadmap_event_id]
    ],
    options: {
      apolloOptions: {
        fetchPolicy: 'network-only'
      }
    }
  });
}

export function useEventInfralistsStatus(regional_roadmap_event_id) {
  return useIntervalGraphql(
    {
      queryOptions: eventInfralistsStatusQueryOptions,
      variableCreator: [
        () =>
          regional_roadmap_event_id
            ? {
                filter: {
                  regional_roadmap_event_ids: [regional_roadmap_event_id]
                }
              }
            : null,
        [regional_roadmap_event_id]
      ],
      options: {
        apolloOptions: {
          fetchPolicy: 'network-only'
        }
      }
    },
    60000
  );
}
