import React from 'react';
import { isTableFieldRequired } from '../Table/TableInput';
import { TableSelectInput } from './SelectInput';

export const TableSelectWrapper = (
  options,
  inputStyle = { width: '114px' }
) => ({ value, onChange, rowData, columnDef }) => {
  const error =
    isTableFieldRequired(rowData, columnDef) && !value
      ? 'Должно быть заполнено'
      : undefined;

  return (
    <TableSelectInput
      value={value}
      onChange={onChange}
      options={options}
      error={error}
      style={inputStyle}
    />
  );
};
