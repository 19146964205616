import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SubTable from '../../../../../common/SubTable/SubTable';

export const ResultInfo = React.memo(function ResultInfo({ event }) {
  return (
    <SubTable title="Результат мероприятия" editVariant>
      <TableRow>
        <TableCell>Наименование результата мероприятия</TableCell>
        <TableCell>{event.plan_roadmap?.event_result || ''}</TableCell>
      </TableRow>
    </SubTable>
  );
});
