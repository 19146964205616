import {
  MUTATION_CREATE_POF_REGIONAL_DOCUMENT,
  MUTATION_DELETE_POF_REGIONAL_DOCUMENT,
  MUTATION_UPDATE_POF_REGIONAL_DOCUMENT
} from '../../graphql/mutations/pofs';
import { clearApolloQueryCache } from '../../utils/apollo';
import { QUERY_GET_ACTUAL_POF } from '../../graphql/queries/pofs';
import { Pof } from './Pof';

export class PofStore {
  constructor({ client }) {
    this.client = client;
  }

  queriesToClear = ['pofRegionalDocuments', 'expensesDirections'];

  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  updatePof(data) {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_POF_REGIONAL_DOCUMENT,
      variables: {
        input: Pof.filterUpdateFields(data)
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  }

  createPof(data) {
    return this.client.mutate({
      mutation: MUTATION_CREATE_POF_REGIONAL_DOCUMENT,
      variables: {
        input: Pof.filterUpdateFields(data)
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  }

  deletePof = (data) => {
    return this.client.mutate({
      mutation: MUTATION_DELETE_POF_REGIONAL_DOCUMENT,
      variables: {
        id: data
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  };

  sendForApproval(id) {
    return this.updatePof({
      id,
      status: Pof.Status.APPROVAL
    });
  }

  approve(id) {
    return this.updatePof({
      id,
      status: Pof.Status.APPROVED
    });
  }

  getActualPof(expenses_direction_id, year, region_id) {
    return this.client
      .query({
        fetchPolicy: 'network-only',
        query: QUERY_GET_ACTUAL_POF,
        variables: {
          filter: {
            year: String(year),
            expenses_direction_ids: [expenses_direction_id],
            region_ids: [region_id],
            actual: true
          }
        }
      })
      .then((data) => {
        return data.data.pofRegionalDocuments?.[0];
      });
  }
}
