import React from 'react';
import { FastField } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import classNames from 'classnames';
import SubTable from '../../../common/SubTable/SubTable';
import { CaptionCell } from '../common/CaptionCell';
import { TextInput, TextInputNumeric } from '../../../common/Inputs/TextInput';
import { SubTableHeading } from '../../../common/SubTable/SubTableHeading';
import { sumNumbersNullish } from '../../../utils/number';
import s from '../ParentalAssistanceGuidelinesRegional/ParentalAssistanceGuidelinesRegionalPage.module.scss';

export const ResultPart = ({ values, errors, touched }) => {
  return (
    <SubTable
      title="III. Результаты оказания услуг. Заполните таблицу"
      cols={3}
    >
      <TableRow>
        <TableCell>Показатель</TableCell>
        <TableCell>2019 год</TableCell>
        <TableCell>2020 год (на 01.11.2020 г.)</TableCell>
      </TableRow>
      <TableRow>
        <CaptionCell className={s.questionCell}>
          1. Количество оказанных услуг всего (единиц)
        </CaptionCell>
        <CaptionCell className={s.valueCell}>
          {sumNumbersNullish(
            values.amount_of_lended_f2f_services_2019,
            values.amount_of_lended_remote_services_2019,
            values.amount_of_lended_field_services_2019,
            values.amount_of_lended_another_format_services_2019
          ) || 0}
        </CaptionCell>
        <CaptionCell className={s.valueCell}>
          {sumNumbersNullish(
            values.amount_of_lended_f2f_services_2020,
            values.amount_of_lended_remote_services_2020,
            values.amount_of_lended_field_services_2020,
            values.amount_of_lended_another_format_services_2020
          ) || 0}
        </CaptionCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          - в формате очных консультаций
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_lended_f2f_services_2019"
            errorMessage={
              touched.amount_of_lended_f2f_services_2019 &&
              errors.amount_of_lended_f2f_services_2019
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_lended_f2f_services_2020"
            errorMessage={
              touched.amount_of_lended_f2f_services_2020 &&
              errors.amount_of_lended_f2f_services_2020
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          - в формате дистанционных консультаций
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_lended_remote_services_2019"
            errorMessage={
              touched.amount_of_lended_remote_services_2019 &&
              errors.amount_of_lended_remote_services_2019
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_lended_remote_services_2020"
            errorMessage={
              touched.amount_of_lended_remote_services_2020 &&
              errors.amount_of_lended_remote_services_2020
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          - в формате выездных консультаций
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_lended_field_services_2019"
            errorMessage={
              touched.amount_of_lended_field_services_2019 &&
              errors.amount_of_lended_field_services_2019
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_lended_field_services_2020"
            errorMessage={
              touched.amount_of_lended_field_services_2020 &&
              errors.amount_of_lended_field_services_2020
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          - в ином формате (если предусмотрено)
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_lended_another_format_services_2019"
            errorMessage={
              touched.amount_of_lended_another_format_services_2019 &&
              errors.amount_of_lended_another_format_services_2019
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_lended_another_format_services_2020"
            errorMessage={
              touched.amount_of_lended_another_format_services_2020 &&
              errors.amount_of_lended_another_format_services_2020
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <CaptionCell className={s.questionCell}>
          2. Количество оказанных услуг всего (единиц)
        </CaptionCell>
        <CaptionCell className={s.valueCell}>
          {sumNumbersNullish(
            values.amount_of_lended_free_services_2019,
            values.amount_of_lended_paid_services_2019
          ) || 0}
        </CaptionCell>
        <CaptionCell className={s.valueCell}>
          {sumNumbersNullish(
            values.amount_of_lended_free_services_2020,
            values.amount_of_lended_paid_services_2020
          ) || 0}
        </CaptionCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          - в том числе без взимания платы
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_lended_free_services_2019"
            errorMessage={
              touched.amount_of_lended_free_services_2019 &&
              errors.amount_of_lended_free_services_2019
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_lended_free_services_2020"
            errorMessage={
              touched.amount_of_lended_free_services_2020 &&
              errors.amount_of_lended_free_services_2020
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          - в том числе на платной основе
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_lended_paid_services_2019"
            errorMessage={
              touched.amount_of_lended_paid_services_2019 &&
              errors.amount_of_lended_paid_services_2019
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_lended_paid_services_2020"
            errorMessage={
              touched.amount_of_lended_paid_services_2020 &&
              errors.amount_of_lended_paid_services_2020
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <CaptionCell className={s.questionCell}>
          3. Количество получателей услуги, всего (человек)
        </CaptionCell>
        <CaptionCell className={s.valueCell}>
          {sumNumbersNullish(
            values.amount_of_onetime_service_recipients_2019,
            values.amount_of_multipletimes_service_recipients_2019
          ) || 0}
        </CaptionCell>
        <CaptionCell className={s.valueCell}>
          {sumNumbersNullish(
            values.amount_of_onetime_service_recipients_2020,
            values.amount_of_multipletimes_service_recipients_2020
          ) || 0}
        </CaptionCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          - в том числе обратившихся единовременно
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_onetime_service_recipients_2019"
            errorMessage={
              touched.amount_of_onetime_service_recipients_2019 &&
              errors.amount_of_onetime_service_recipients_2019
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_onetime_service_recipients_2020"
            errorMessage={
              touched.amount_of_onetime_service_recipients_2020 &&
              errors.amount_of_onetime_service_recipients_2020
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          - в том числе обратившихся более одного раза
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_multipletimes_service_recipients_2019"
            errorMessage={
              touched.amount_of_multipletimes_service_recipients_2019 &&
              errors.amount_of_multipletimes_service_recipients_2019
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_multipletimes_service_recipients_2020"
            errorMessage={
              touched.amount_of_multipletimes_service_recipients_2020 &&
              errors.amount_of_multipletimes_service_recipients_2020
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <CaptionCell className={s.questionCell}>
          4. Количество специалистов, оказывающих услуги, всего (человек)
        </CaptionCell>
        <CaptionCell className={s.valueCell}>
          {sumNumbersNullish(
            values.amount_of_specialists_in_state_2019,
            values.amount_of_contract_specialists_2019
          ) || 0}
        </CaptionCell>
        <CaptionCell className={s.valueCell}>
          {sumNumbersNullish(
            values.amount_of_specialists_in_state_2020,
            values.amount_of_contract_specialists_2020
          ) || 0}
        </CaptionCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          - в том числе состоящих в штате организации
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_specialists_in_state_2019"
            errorMessage={
              touched.amount_of_specialists_in_state_2019 &&
              errors.amount_of_specialists_in_state_2019
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_specialists_in_state_2020"
            errorMessage={
              touched.amount_of_specialists_in_state_2020 &&
              errors.amount_of_specialists_in_state_2020
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          - в том числе привлеченных по договору
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_contract_specialists_2019"
            errorMessage={
              touched.amount_of_contract_specialists_2019 &&
              errors.amount_of_contract_specialists_2019
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_contract_specialists_2020"
            errorMessage={
              touched.amount_of_contract_specialists_2020 &&
              errors.amount_of_contract_specialists_2020
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <CaptionCell className={classNames(s.questionCell, s.title)}>
          5. Количество специалистов, оказывающих услуги, прошедших обучение по
          дополнительной профессиональной программе для специалистов, всего
          (человек)
        </CaptionCell>
        <TableCell>
          <FastField
            name="amount_of_prepared_specialists_2019"
            errorMessage={
              touched.amount_of_prepared_specialists_2019 &&
              errors.amount_of_prepared_specialists_2019
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_prepared_specialists_2020"
            errorMessage={
              touched.amount_of_prepared_specialists_2020 &&
              errors.amount_of_prepared_specialists_2020
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
      </TableRow>

      <SubTableHeading cols={3}>
        6. Количество отказов в предоставлении услуги, основные причины отказов
      </SubTableHeading>
      <TableRow>
        <TableCell className={s.question}>всего (ед.)</TableCell>
        <TableCell>
          <FastField
            name="amount_of_service_rejections_2019"
            errorMessage={
              touched.amount_of_service_rejections_2019 &&
              errors.amount_of_service_rejections_2019
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
        <TableCell>
          <FastField
            name="amount_of_service_rejections_2020"
            errorMessage={
              touched.amount_of_service_rejections_2020 &&
              errors.amount_of_service_rejections_2020
            }
            component={TextInputNumeric}
            unit=""
            fullWidth
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell className={s.question}>
          причины отказов в предоставлении услуги без взимания платы.
        </TableCell>
        <TableCell>
          <FastField
            name="service_rejections_description_2019"
            errorMessage={
              touched.service_rejections_description_2019 &&
              errors.service_rejections_description_2019
            }
            label="Укажите причины"
            component={TextInput}
            multiline
            fullWidth
          />
        </TableCell>
        <TableCell>
          <FastField
            name="service_rejections_description_2020"
            errorMessage={
              touched.service_rejections_description_2020 &&
              errors.service_rejections_description_2020
            }
            label="Укажите причины"
            component={TextInput}
            multiline
            fullWidth
          />
        </TableCell>
      </TableRow>
    </SubTable>
  );
};
