import React from 'react';
import { FastField } from 'formik';
import InputGroup from '../../../../../common/Inputs/InputGroup';
import { reformatDate } from '../../../../../utils/date';
import { FormDateInput } from '../../../../../common/Inputs/DateInput';
import s from '../EventEditPage.module.scss';

export const TermsInfo = React.memo(function TermsInfo({
  event,
  canEditFactTerm
}) {
  return (
    <InputGroup title="Сроки">
      {event.plan_term ? (
        <div className={s.GutterBottom}>
          <p className={s.Title}>Плановый:</p>
          <p className={s.NormalText}>{reformatDate(event.plan_term)}</p>
        </div>
      ) : (
        <div className={s.GutterBottom}>
          <p className={s.Title}>Плановый:</p>
          <p className={s.NormalText}>
            {event.plan_roadmap?.periodicity_string || ''}
          </p>
        </div>
      )}
      <div>
        <p className={s.Title}>Фактический:</p>
        <FastField
          name="fact_term"
          component={FormDateInput}
          label="Фактический срок"
          disabled={!canEditFactTerm}
        />
      </div>
    </InputGroup>
  );
});
