import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useContextStore } from '@proscom/prostore-react';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { useMentoringCoverage } from '../../../store/mentoringCoverage/useMentoringCoverage';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { ParentalAssistanceGuideline } from '../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import { PageActionsBar } from '../../../common/PageActionsBar/PageActionsBar';
import { EditButton } from '../../../common/Form/EditButton/EditButton';
import { queryLoaderWithExistenceCheck } from '../../../common/QueryLoader';
import SubTable from '../../../common/SubTable/SubTable';
import { getName } from '../../../utils/data/label';
import { MentoringCoverage } from '../../../store/mentoringCoverage/MentoringCoverage';
import { ActionButton } from '../../../common/Form/ActionButton';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../graphql/graphqlErrors';
import { STORE_MENTORING_COVERAGE } from '../../../store/names';
const title = 'Охват наставничеством';

export default function RegionMentoringCoverage({ match, history }) {
  const mentoringCoverageStore = useContextStore(STORE_MENTORING_COVERAGE);
  const { mentoringCoverageId } = match.params;

  const mentoringCoverageQuery = useMentoringCoverage(mentoringCoverageId);
  const mentoringCoverage = mentoringCoverageQuery.state.data;

  const canEdit =
    mentoringCoverage?.status === ParentalAssistanceGuideline.Status.DRAFT;

  return (
    <>
      <HeadTitle title={title} />
      <PageTitle title={title} withBackBtn />
      <PageActionsBar
        actions={
          canEdit && (
            <ActionButton
              onClick={() =>
                mentoringCoverageStore
                  .approve(mentoringCoverage.id)
                  .then(() => {
                    mentoringCoverageQuery.load();
                  })
                  .catch((error) => {
                    showGraphqlErrorToast(error, mutationErrors.updateObject);
                  })
              }
              title="Утвердить"
            />
          )
        }
        editButton={canEdit && <EditButton editLink={`${match.url}/edit`} />}
      />

      {queryLoaderWithExistenceCheck(mentoringCoverageQuery) || (
        <>
          <SubTable title="Общие сведения">
            <TableRow>
              <TableCell>Регион</TableCell>
              <TableCell>{getName(mentoringCoverage.region)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Статус</TableCell>
              <TableCell>
                {MentoringCoverage.status.getName(mentoringCoverage.status)}
              </TableCell>
            </TableRow>
          </SubTable>
          <SubTable title={'Охват наставничеством'}>
            <TableRow>
              <TableCell>
                Доля обучающихся, вовлеченных в различные формы наставничества
                (2020г. - не менее 5%)
              </TableCell>
              <TableCell>{mentoringCoverage.percentage}%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Комментарий</TableCell>
              <TableCell>{mentoringCoverage.comment}</TableCell>
            </TableRow>
          </SubTable>
        </>
      )}
    </>
  );
}
