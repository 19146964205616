import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

export const IndicatorCheckboxHead = ({
  allChecked,
  indeterminate,
  indicators,
  setSelected
}) => {
  return (
    <Checkbox
      color="primary"
      checked={allChecked}
      indeterminate={indeterminate}
      onChange={(e) => {
        if (allChecked) {
          setSelected([]);
        } else {
          setSelected(
            indicators.map((indicator) => ({
              id: indicator.id,
              status: indicator.status
            }))
          );
        }
      }}
    />
  );
};
