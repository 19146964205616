import { skipIfNull } from '@proscom/prostore';
import { QUERY_INFRALIST_CHANGE_LOG_DATELIST } from '../../../../graphql/queries/infralist';
import { useRetriableGraphql } from '../../../../common/useRetriableGraphqlVars';
import { reformatDate } from '../../../../utils/date';

const infralistChangeLogDateListQueryOptions = {
  query: QUERY_INFRALIST_CHANGE_LOG_DATELIST,
  mapData: (result) =>
    result.infralistChangeLogDateList.map((date) => ({
      label: reformatDate(date),
      value: date
    })),
  skipQuery: skipIfNull(null)
};

export const useChangeLogDatesOptions = (infralistId) => {
  const query = useRetriableGraphql({
    queryOptions: infralistChangeLogDateListQueryOptions,
    variables: { infralist_id: infralistId }
  });

  return {
    changeLogDateOptions: query.state.data ?? [],
    isLoadingChangeLogDateOptions: query.state.loading
  };
};
