import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { useForm } from '../../../../store/form/formQueries';
import { Form } from '../../../../store/form/Form';
import { handleFormSubmission } from '../../../../utils/data/form';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import { STORE_FORM } from '../../../../store/names';
import { removeLastPathItem } from '../../../../utils/url';
import BreadCrumbs from '../../../../common/BreadCrumbs/BreadCrumbs';
import { getFormBreadCrumbs } from '../getFormBreadCrumbs';
import { useOtherEditor } from '../../../../common/Form/useOtherEditor';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { getName } from '../../../../utils/data/label';
import { FormEditForm } from './FormEditForm';

export default function FormEditPage({ match, history }) {
  const backLink = removeLastPathItem(match.url);
  const { formId } = match.params;

  const itemStore = useContextStore(STORE_FORM);
  const editor = useOtherEditor('item', formId);

  const itemQuery = useForm(formId);
  const item = itemQuery.state.data;

  const breadCrumbs = useMemo(() => getFormBreadCrumbs(), []);

  return (
    <>
      <HeadTitle
        entity={getName(item)}
        type={'Форма'}
        pageType={HeadTitle.PageType.edit}
      />
      <BreadCrumbs links={breadCrumbs} />
      <h2>Редактирование формы</h2>
      {queryLoaderWithExistenceCheck(itemQuery) || (
        <Formik
          initialValues={Form.toForm(item)}
          onSubmit={(values, actions) => {
            const form = Form.fromForm(values);
            handleFormSubmission(
              itemStore.updateForm(form),
              actions,
              (result) => history.goBack(Form.getUrl(result.data.result))
            );
          }}
          render={(formikProps) => (
            <FormEditForm
              {...formikProps}
              onBackClick={() => history.goBack(backLink)}
            />
          )}
        />
      )}
    </>
  );
}
