import gql from 'graphql-tag';

export const SUBSCRIPTION_LOCK_SECTION_OR_EQUIPMENT = gql`
  subscription onEditLock($input: [EditLockInputType!]!) {
    onEditLock(input: $input) {
      user_id
      object_id
      object_type
    }
  }
`;
