import { useCallback, useRef, useState } from 'react';
import { mutationErrors, showGraphqlErrorToast } from '../../graphql/graphqlErrors';

export const formUnloadHandler = (e) => {
  e.preventDefault();
  e.returnValue = '';
};

export const FORM_STATUS_SUBMITTED = 'submitted';

export function handleFormSubmission(promise, actions, navigate) {
  return promise
    .then((result) => {
      actions.setStatus(FORM_STATUS_SUBMITTED);
      setTimeout(() => {
        navigate(result);
      }, 0);
    })
    .catch((error) => {
      showGraphqlErrorToast(error, mutationErrors.updateObject);
    })
    .then(() => {
      actions.setSubmitting(false);
    });
}

export function useDeleting(onDelete, onAfterDelete) {
  const latestRef = useRef({});
  latestRef.current.onDelete = onDelete;
  latestRef.current.onAfterDelete = onAfterDelete;

  const [isDeleting, setDeleting] = useState(false);
  const deleteItem = useCallback(() => {
    setDeleting(true);
    latestRef.current
      .onDelete()
      .then(() => {
        setTimeout(latestRef.current.onAfterDelete, 0);
      })
      .catch((error) => {
        showGraphqlErrorToast(error, mutationErrors.deleteObject);
      })
      .finally(() => setDeleting(false));
  }, []);
  return [isDeleting, deleteItem];
}
