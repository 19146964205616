import React from 'react';
import { Prompt } from 'react-router-dom';
import { Form } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { FormHeader } from '../../../../common/Form/Form';
import InputGroup from '../../../../common/Inputs/InputGroup';
import { getName } from '../../../../utils/data/label';
import SubTable from '../../../../common/SubTable/SubTable';
import { IndicatorForecast } from '../../../../store/indicatorForecasts/IndicatorForecast';
import { NewAutocompleteInput } from '../../../../common/InputsNew/NewAutocompleteInput';
import { NewTextInput } from '../../../../common/InputsNew/NewTextInput';
import { NewFloatInput } from '../../../../common/InputsNew/NewFloatInput';

export const IndicatorForecastEditForm = ({
  onBackClick,
  isSubmitting,
  editor,
  status,
  touched,
  errors,
  values
}) => {
  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <Form noValidate>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting}
          editor={editor}
          isSubmitting={isSubmitting}
        />
        <SubTable title="Общие сведения" editVariant>
          <TableRow>
            <TableCell>{IndicatorForecast.parts.region.name}</TableCell>
            <TableCell>{getName(values.region)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {IndicatorForecast.parts.federal_project.name}
            </TableCell>
            <TableCell>{getName(values.federal_project)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{IndicatorForecast.parts.indicator.name}</TableCell>
            <TableCell>{getName(values.indicator)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {IndicatorForecast.parts.indicator_plan_value.name}
            </TableCell>
            <TableCell>{values.indicator_plan_value}</TableCell>
          </TableRow>
        </SubTable>
        <InputGroup>
          <NewAutocompleteInput
            name="status"
            label={IndicatorForecast.parts.status.name}
            suggestions={IndicatorForecast.status.getOptions()}
            fullWidth
            required
          />
        </InputGroup>
        <InputGroup
          title={IndicatorForecast.parts.indicator_forecast_value.name}
        >
          <NewFloatInput name="indicator_forecast_value" fullWidth />
        </InputGroup>
        {values.form_variant === IndicatorForecast.FormVariant.PEOPLE && (
          <>
            <InputGroup title={IndicatorForecast.parts.people_demo.name}>
              <NewFloatInput name="people_demo" fullWidth />
            </InputGroup>
            <InputGroup title={IndicatorForecast.parts.people_total.name}>
              <NewFloatInput name="people_total" fullWidth />
            </InputGroup>
          </>
        )}
        {values.form_variant === IndicatorForecast.FormVariant.ORGS && (
          <>
            <InputGroup title={IndicatorForecast.parts.orgs_demo.name}>
              <NewFloatInput name="orgs_demo" fullWidth />
            </InputGroup>
            <InputGroup title={IndicatorForecast.parts.orgs_total.name}>
              <NewFloatInput name="orgs_total" fullWidth />
            </InputGroup>
          </>
        )}
        <InputGroup>
          <NewTextInput
            name="comment"
            label={IndicatorForecast.parts.comment.name}
            fullWidth
            multiline
          />
        </InputGroup>
      </Form>
    </>
  );
};
