import { joinNonEmpty } from '../../utils/data/label';

export class Organization {
  static getName(organization) {
    return organization && (organization.name_short || organization.name_full);
  }

  static getCompleteName(organization) {
    if (!organization) return null;
    const { inn, kpp } = organization;
    const name = this.getName(organization);
    let braces = joinNonEmpty([inn && `ИНН ${inn}`, kpp && `КПП ${kpp}`], ', ');
    braces = braces && `(${braces})`;
    return joinNonEmpty([name, braces], ' ');
  }
}
