import React from 'react';
import { Prompt } from 'react-router';
import { FastField, Form } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FormHeader } from '../../../../../common/Form/Form';
import SubTable from '../../../../../common/SubTable/SubTable';
import { TextInput } from '../../../../../common/Inputs/TextInput';
import InputGroup from '../../../../../common/Inputs/InputGroup';
import { DateCell } from '../../../../../common/TableCells';
import { compareSimple, makeComparator } from '../../../../../utils/sorting';
import { FORM_STATUS_SUBMITTED } from '../../../../../utils/data/form';
import { FormDateInput } from '../../../../../common/Inputs/DateInput';
import { DocumentsEditField } from '../../../../regionalRoadmapEvents/regionalEvent/DocumentsEditField';

export const FederalEventEditForm = ({
  event,
  onBackClick,
  editor,
  isSubmitting,
  status,
  values,
  touched,
  errors,
  ...props
}) => {
  const documentSlots = (event?.roadmap_event_document_slots || [])
    .slice()
    .sort(makeComparator(compareSimple, (slot) => slot.code))
    .filter((slot) => slot.federal);

  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <Form>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting}
          editor={editor}
          isSubmitting={isSubmitting}
          entityName={`Федеральное мероприятие`}
        />
      </Form>
      <SubTable title="Общие сведения" editVariant>
        <TableRow>
          <TableCell>Название мероприятия</TableCell>
          <TableCell>{event.name_full}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Результат</TableCell>
          <TableCell>{event.roadmap?.result?.name_full}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Год</TableCell>
          <TableCell>{event.year}</TableCell>
        </TableRow>
      </SubTable>
      <InputGroup>
        <FastField
          name="event_result"
          label="Результат мероприятия"
          component={TextInput}
          fullWidth
        />
        {!event.plan_roadmap?.periodicity_string && (
          <FastField
            name="plan_term"
            label="Плановый срок"
            component={FormDateInput}
          />
        )}
      </InputGroup>
      {event.responsibility_level_vo && (
        <InputGroup
          title={`Ответственный за реализацию. Ведомственный проектный офис проекта "Образование"`}
        >
          <FastField
            name="responsible_person.surname"
            label="Фамилия"
            component={TextInput}
            fullWidth
          />
          <FastField
            name="responsible_person.first_name"
            label="Имя"
            component={TextInput}
            fullWidth
          />
          <FastField
            name="responsible_person.patronymic"
            label="Отчество"
            component={TextInput}
            fullWidth
          />
          <FastField
            name="responsible_person.individual_data.occupation"
            label="Должность"
            component={TextInput}
            fullWidth
          />
          <FastField
            name="responsible_person.phones[0]"
            label="Мобильный телефон"
            component={TextInput}
            inputProps={{ maxLength: 20 }}
            fullWidth
          />
          <FastField
            name="responsible_person.emails[0]"
            label="Email"
            component={TextInput}
            fullWidth
            errorMessage={
              touched.responsible_person?.emails?.[0] &&
              errors.responsible_person?.emails?.[0]
            }
          />
        </InputGroup>
      )}
      <DocumentsEditField
        name="roadmap_event_documents"
        slots={documentSlots}
        // todo разобраться, почему в базе нет данных
        showSlots={event.display_document_slots || true}
        canUploadArbitrary={event.upload_arbitrary_documents || true}
        canUploadDocuments={true}
        showDocumentStatus={false}
        title="Документы ведомственного проектного офиса"
      />
      <SubTable editVariant>
        <TableRow>
          <TableCell>Дата заполнения</TableCell>
          <DateCell value={event.created_at} />
        </TableRow>
        <TableRow>
          <TableCell>Дата обновления</TableCell>
          <DateCell value={event.updated_at} />
        </TableRow>
        <TableRow>
          <TableCell>Версия</TableCell>
          <TableCell>{event.version}</TableCell>
        </TableRow>
      </SubTable>
    </>
  );
};
