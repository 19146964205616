import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { Form } from '../../../../../store/form/Form';
import { handleFormSubmission } from '../../../../../utils/data/form';
import BreadCrumbs from '../../../../../common/BreadCrumbs/BreadCrumbs';
import { STORE_FORM } from '../../../../../store/names';
import { removeLastPathItem } from '../../../../../utils/url';
import { getFormDocumentBreadCrumbs } from '../getFormDocumentBreadCrumbs';
import { useForm } from '../../../../../store/form/formQueries';
import { FormDocumentEditForm } from '../edit/FormDocumentEditForm';
import { queryLoader } from '../../../../../common/QueryLoader';
import { FormDocument } from '../../../../../store/form/FormDocument';
import { HeadTitle } from '../../../../../common/HeadTitle/HeadTitle';
import { getName } from '../../../../../utils/data/label';

const initialValues = {
  data: {}
};

export default function FormDocumentCreatePage({ match, history }) {
  const backLink = removeLastPathItem(match.url);
  const { formId } = match.params;

  const formStore = useContextStore(STORE_FORM);

  const formQuery = useForm(formId);
  const form = formQuery.state.data;

  const breadCrumbs = useMemo(
    () =>
      getFormDocumentBreadCrumbs({
        formId,
        formName: form?.name
      }),
    [formId, form]
  );

  return (
    <>
      <HeadTitle pageType={HeadTitle.PageType.create} type={getName(form)} />
      <BreadCrumbs links={breadCrumbs} />
      <h2>Создание документа формы "{form?.name}"</h2>
      {queryLoader(formQuery) || (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            const form = FormDocument.fromForm(values, { form_id: formId });
            handleFormSubmission(
              formStore.createFormDocument(form),
              actions,
              (result) => history.goBack(Form.getUrl(result.data.result))
            );
          }}
          render={(formikProps) => (
            <FormDocumentEditForm
              {...formikProps}
              form={form}
              onBackClick={() => history.goBack(backLink)}
            />
          )}
        />
      )}
    </>
  );
}
