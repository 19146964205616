import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import { TextField } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { SimpleFileStatusEditable } from './TypeEditableComponents';
import s from './EventPage.module.scss';

export const EditDocumentsWithoutSlots = ({
  documents,
  onCancelUpload,
  onDelete,
  onDocumentChange,
  canEditStatus,
  canUploadDocuments,
  eventStatus,
  entities
}) => {
  return documents.map((document) => {
    const documentId = document.id;
    return (
      <TableRow key={documentId}>
        <TableCell className={s.cellStyle}>
          <TextField
            label="Имя документа"
            className={s.textInputStyle}
            fullWidth
            value={document.name || ''}
            onChange={(e) =>
              onDocumentChange(documentId, { name: e.target.value })
            }
            disabled={!canUploadDocuments}
          />
        </TableCell>
        <TableCell className={s.cellStyle}>
          <SimpleFileStatusEditable
            documentId={documentId}
            document={document}
            onCancelUpload={onCancelUpload}
            onDelete={onDelete}
            canEditStatus={canEditStatus}
            canUploadDocuments={canUploadDocuments}
            onDocumentChange={onDocumentChange}
            eventStatus={eventStatus}
            entities={entities}
          />
        </TableCell>
      </TableRow>
    );
  });
};
