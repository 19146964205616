import { BehaviorStore } from '@proscom/prostore';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { SingleIntervalManager } from '../../common/SingleIntervalManager';
import { EMDASH } from '../../utils/constants';
import { GET_NOTIFICATION_COUNT } from '../../graphql/queries/notifications';
import { SubscriptionManager } from '../../utils/SubscriptionManager';

export class NotificationCountStore extends BehaviorStore {
  refreshTimeout = new SingleIntervalManager();
  sub = new SubscriptionManager();

  constructor({ client, authStore }) {
    super({
      count: EMDASH
    });

    this.client = client;
    this.authStore = authStore;
  }

  subscribe() {
    this.sub.subscribe(
      this.authStore.state$.pipe(
        map((state) => !!state.authData?.accessToken),
        distinctUntilChanged()
      ),
      this.startInterval
    );
  }

  unsubscribe() {
    this.sub.destroy();
    this.refreshTimeout.clear();
  }

  startInterval = (isAuth) => {
    if (isAuth) {
      const milliseconds = 60 * 1000;
      this.updateNotificationCount();
      this.refreshTimeout.setDelay(milliseconds);
      this.refreshTimeout.set(() => {
        this.updateNotificationCount();
      });
    } else {
      this.refreshTimeout.clear();
    }
  };

  async updateNotificationCount() {
    try {
      const countQuery = await this.client.query({
        fetchPolicy: 'network-only',
        query: GET_NOTIFICATION_COUNT
      });
      const count = countQuery.data?.amountOfUnreadNotifications;
      this.setState({ count: count });
    } catch {}
  }
}
