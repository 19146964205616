import { useMemo } from 'react';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { getSyncEntityQueryOptions } from './entities';

export function useEntityList(entity, variables) {
  const queryOptions = useMemo(() => getSyncEntityQueryOptions(entity), [
    entity
  ]);

  return useRetriableGraphql({
    queryOptions,
    variables
  });
}
