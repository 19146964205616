import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';

export const PreventChangePage = ({ when }) => {
  useEffect(() => {
    const preventClosing = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };
    if (when) {
      window.addEventListener('beforeunload', preventClosing);
    }
    return () => {
      window.removeEventListener('beforeunload', preventClosing);
    };
  }, [when]);
  return (
    <Prompt
      when={when}
      message="Вы уверены, что хотите покинуть страницу? Несохраненные данные будут потеряны"
    />
  );
};

PreventChangePage.propTypes = {
  when: PropTypes.bool
};
