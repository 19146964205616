import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { ROADMAP_INDICATOR } from '../../graphql/queries/indicators';

const roadmapIndicatorQueryOptions = {
  query: ROADMAP_INDICATOR,
  mapData: (result) => result.roadmapIndicator,
  skipQuery: skipIfNull(null)
};

export function useRoadmapIndicator(id) {
  return useRetriableGraphqlVars({
    queryOptions: roadmapIndicatorQueryOptions,
    variableCreator: [
      () =>
        id
          ? {
              id
            }
          : null,
      [id]
    ]
  });
}
