import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import s from './LabelField.module.scss';

export const LabelField = ({ label, children, size = 'normal', className }) => (
  <div>
    <span
      className={classNames(s.Label, className, {
        [s.sizeMin]: size === 'min',
        [s.sizeNormal]: size === 'normal'
      })}
    >
      {label}
    </span>
    {children}
  </div>
);

LabelField.propTypes = {
  label: PropTypes.elementType,
  size: PropTypes.oneOf(['min', 'normal']),
  className: PropTypes.string
};
