import { skipIfNull } from '@proscom/prostore';
import { QUERY_GET_ROADMAP_EVENT_DOCUMENTS } from '../../graphql/queries/roadmapEvents';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';

const roadmapEventDocumentsQueryOptions = {
  query: QUERY_GET_ROADMAP_EVENT_DOCUMENTS,
  mapData: (result) => result.roadmapEventDocuments,
  skipQuery: skipIfNull(null)
};

export function useRoadmapEventPhotoQueryOptions(roadmapId, regionId) {
  return useRetriableGraphqlVars({
    queryOptions: roadmapEventDocumentsQueryOptions,
    variableCreator: [
      () =>
        roadmapId && regionId
          ? {
              filter: {
                roadmap_ids: roadmapId,
                region_ids: regionId,
                type: 'PHOTO'
              }
            }
          : null,
      [roadmapId, regionId]
    ]
  });
}
