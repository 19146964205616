import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../../common/useRetriableGraphqlVars';
import { QUERY_INFRALIST_VERSION_DIRECTION } from '../../../graphql/queries/infralist';

const infralistDirectionsQueryOptions = {
  query: QUERY_INFRALIST_VERSION_DIRECTION,
  mapData: (result) => result.infralistDirection,
  skipQuery: skipIfNull(null)
};

export function useVersionDirection({ directionId, versionId }) {
  return useRetriableGraphql({
    queryOptions: infralistDirectionsQueryOptions,
    variables: {
      direction_id: directionId,
      version_id: versionId
    }
  });
}
