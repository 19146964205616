import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useState } from 'react';
import { MUTATION_CHECK_EQUIPMENT_SECTION } from '../../../graphql/mutations/equipment';
import { clearApolloQueryCache } from '../../../utils/apollo';
import { INFRALIST_QUERIES } from '../infralistQueries';

export const useCheckEquipmentSection = () => {
  const client = useContextApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  const checkEquipmentSection = ({ infralistId, sectionId }) => {
    setIsLoading(true);
    return client.mutate({
      mutation: MUTATION_CHECK_EQUIPMENT_SECTION,
      variables: {
        infralist_id: infralistId,
        section_id: sectionId
      },
      update: (store) => {
        clearApolloQueryCache(store, [
          INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT
        ]);
        setIsLoading(false);
      },
      refetchQueries: [INFRALIST_QUERIES.SECTION]
    });
  };

  return {
    isCheckingEquipmentSection: isLoading,
    checkEquipmentSection
  };
};
