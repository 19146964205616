import React, { useMemo } from 'react';
import BreadCrumbs, {
  loadingBreadcrumb
} from '../../../common/BreadCrumbs/BreadCrumbs';
import { getProjectBreadCrumbs } from '../../projects/project/ProjectBreadCrumbs';

export function getPofBreadCrumbs({ projectId, projectName }) {
  if (projectId)
    return [
      ...getProjectBreadCrumbs(),
      {
        label: loadingBreadcrumb(projectName),
        to: `/projects/${projectId}`
      },
      {
        label: 'ПОФ',
        to: `/projects/${projectId}?tab=pofs`
      }
    ];
  return [
    {
      label: 'ПОФ',
      to: '/pofs'
    }
  ];
}

export const PofBreadCrumbs = ({ project, projectId }) => {
  const breadCrumbsLinks = useMemo(() => {
    return getPofBreadCrumbs({
      projectId,
      projectName: project?.name
    });
  }, [projectId, project]);

  return <BreadCrumbs links={breadCrumbsLinks} />;
};
