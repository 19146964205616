import React from 'react';
import { Prompt } from 'react-router-dom';
import { FastField, Form } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { FormHeader } from '../../../../common/Form/Form';
import { getName } from '../../../../utils/data/label';
import SubTable from '../../../../common/SubTable/SubTable';
import { CurrencyInput, TextInput } from '../../../../common/Inputs/TextInput';
import { MentoringCoverage } from '../../../../store/mentoringCoverage/MentoringCoverage';
import s from '../RegionMentoringCoveragePage.module.scss';

export default function RegionMentoringCoverageEditForm({
  onBackClick,
  isSubmitting,
  status,
  touched,
  errors,
  region
}) {
  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <Form noValidate>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting}
          isSubmitting={isSubmitting}
          classWrapper={s.FormHeader}
        />
        <SubTable title="Общие сведения" editVariant>
          <TableRow>
            <TableCell>Регион</TableCell>
            <TableCell>{getName(region)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Статус</TableCell>
            <TableCell>
              {MentoringCoverage.status.getName(MentoringCoverage.Status.DRAFT)}
            </TableCell>
          </TableRow>
        </SubTable>
        <SubTable title={'Охват наставничеством'}>
          <TableRow>
            <TableCell className={s.question}>
              Доля обучающихся, вовлеченных в различные формы наставничества
              (2020г. - не менее 5%)
            </TableCell>
            <TableCell>
              <FastField
                name="percentage"
                component={CurrencyInput}
                fixedDecimalScale={true}
                decimalScale={2}
                allowNegative={false}
                allowString={true}
                unit={'%'}
                errorMessage={touched.percentage && errors.percentage}
                label="Введите число"
                fullWidth
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={s.question}>Комментарий</TableCell>
            <TableCell>
              <FastField
                name="comment"
                component={TextInput}
                label="Не более 1000 символов"
                errorMessage={touched.comment && errors.comment}
                multiline
                fullWidth
              />
            </TableCell>
          </TableRow>
        </SubTable>
      </Form>
    </>
  );
}
