import React, { useMemo } from 'react';
import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { NewAutocompleteInput } from '../../../common/InputsNew/NewAutocompleteInput';
import { useEntityList } from '../../../store/form/useEntityList';
import { NewAutocompleteAsyncInput } from '../../../common/InputsNew/NewAutocompleteAsyncInput';
import ErrorMessage from '../../../common/ErrorMessage/ErrorMessage';
import {
  getAsyncEntityLoader,
  mapEntityOptions,
  syncEntities
} from '../../../store/form/entities';
import { NewAutocompleteAsyncField } from '../../../common/Inputs/Selects/NewAutocompleteAsyncField';
import AutocompleteBaseInput from '../../../common/Inputs/Selects/AutocompleteBaseInput';

function FormSelectSyncInput({ entity, filter, isField, ...props }) {
  const query = useEntityList(entity);

  const queryData = query.state.data;
  const data = useMemo(() => {
    if (!queryData || !filter) {
      return mapEntityOptions(queryData);
    }

    return filter(queryData);
  }, [queryData, filter]);

  const Base = isField ? AutocompleteBaseInput : NewAutocompleteInput;

  return (
    <Base
      {...props}
      suggestions={data}
      suggestionsLoading={query.check.spinner}
    />
  );
}

function FormSelectAsyncInput({
  entity,
  variables,
  filter,
  isField,
  ...props
}) {
  const client = useContextApolloClient('default');

  const [loadOptions, loadOption] = useMemo(() => {
    return getAsyncEntityLoader({ entity, client, variables, filter });
  }, [entity, client, variables, filter]);

  if (!loadOptions || !loadOption) {
    return <ErrorMessage error={`Неизвестная сущность '${entity}'`} />;
  }

  const Base = isField ? NewAutocompleteAsyncField : NewAutocompleteAsyncInput;

  return <Base {...props} loadOptions={loadOptions} loadOption={loadOption} />;
}

export function FormSelectInput({ entity, ...props }) {
  if (syncEntities.includes(entity)) {
    return <FormSelectSyncInput entity={entity} {...props} />;
  }
  return <FormSelectAsyncInput entity={entity} {...props} />;
}
