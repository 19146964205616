import gql from 'graphql-tag';

export const FRAGMENT_ROLE_PERMISSION = gql`
  fragment permission on RolePermissionType {
    id
    base_permission_id
    object_type
    object_id
  }
`;

export const QUERY_GET_ROLES = gql`
  query roles($input: CustomRolePageInput!) {
    roles(input: $input) {
      list {
        id
        code
        name
        permissions {
          ...permission
        }
      }
    }
  }
  ${FRAGMENT_ROLE_PERMISSION}
`;

export const FRAGMENT_ROLE = gql`
  fragment role on CustomRoleType {
    id
    code
    name
    permissions {
      ...permission
    }
  }
  ${FRAGMENT_ROLE_PERMISSION}
`;

export const QUERY_GET_ROLE = gql`
  query role($id: ID!) {
    role(id: $id) {
      ...role
    }
  }

  ${FRAGMENT_ROLE}
`;
