import React from 'react';
import { FastField } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SubTable from '../../../common/SubTable/SubTable';
import { ParentalAssistanceGuideline } from '../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import { FormDateInput } from '../../../common/Inputs/DateInput';
import { NewAutocompleteInput } from '../../../common/InputsNew/NewAutocompleteInput';
import { TextInput, TextInputNumeric } from '../../../common/Inputs/TextInput';
import { UniversalDocumentCell } from '../common/FileUpload/DownloadFileInput';
import { PossibleChoiceFields } from './PossibleChoiceFields';
import { ServiceDeliveryFormatsFields } from './ServiceDeliveryFormatsFields';
import s from '../ParentalAssistanceGuidelinesRegional/ParentalAssistanceGuidelinesRegionalPage.module.scss';

export const ProcedureRegionalPart = ({
  values,
  setValue,
  touched,
  errors
}) => {
  return (
    <SubTable title="II. Порядок оказания услуг" editVariant>
      <TableRow>
        <TableCell className={s.question}>
          1. С какого момента в Вашем субъекте Российской Федерации оказывается
          услуга (дата начала оказания услуг).
        </TableCell>
        <TableCell>
          <FastField
            name="date_of_service_start"
            component={FormDateInput}
            fullWidth
          />
          <FastField
            name="date_of_service_start_description"
            component={TextInput}
            label="Комментарии (при наличии)"
            fullWidth
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          2. Опишите механизм предоставления услуги. Опишите источники
          финансирования организаций, оказывающих услуги на территории субъекта
        </TableCell>
        <TableCell>
          <FastField
            name="service_delivery_mechanism"
            component={TextInput}
            label="Описание механизма"
            multiline
            fullWidth
          />
          <NewAutocompleteInput
            label="Источники (можно выбрать несколько)"
            name="sources_of_financing"
            suggestions={ParentalAssistanceGuideline.sourcesOfFinancing.getOptions()}
            handleChange={setValue}
            hasCopyBtn={false}
            fullWidth
            isMulti
            useObject
          />
          <FastField
            name="other_sources_of_financing"
            component={TextInput}
            label="Другие источники (опишите при наличии)"
            multiline
            fullWidth
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          3. Перечислите виды консультаций, предоставляемые в Вашем субъекте
          Российской Федерации без взимания платы
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            label="Виды консультаций (можно выбрать несколько)"
            name="types_of_free_consultations"
            suggestions={ParentalAssistanceGuideline.typesOfFreeConsultations.getOptions()}
            handleChange={setValue}
            hasCopyBtn={false}
            fullWidth
            isMulti
            useObject
          />
        </TableCell>
      </TableRow>
      <ServiceDeliveryFormatsFields
        setValue={setValue}
        title="4. Перечислите форматы предоставления услуги в Вашем субъекте Российской Федерации"
      />
      <TableRow>
        <TableCell className={s.question}>
          5. Перечислите категории родителей (законных представителей), в
          отношении которых предусмотрены особые права в части условий получения
          услуги (например, право на получение выездной консультации, право на
          получение услуги в большем объеме в течение года и др.), а также какие
          особые права предусмотрены для них.
        </TableCell>
        <TableCell>
          <FastField
            name="special_categories_of_legal_representatives"
            component={TextInput}
            label="Категории получателей услуг"
            multiline
            fullWidth
          />
          <FastField
            name="special_categories_of_legal_representatives_description"
            component={TextInput}
            label="Опишите какие именно особые права предусмотрены"
            multiline
            fullWidth
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          6. Предусмотрена ли возможность предоставления услуги на платной
          основе? Опишите условия и порядок ее предоставления.
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="paid_service_possibility"
            label={'Выбор из списка'}
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            hasCopyBtn={false}
            fullWidth
          />
          {values.paid_service_possibility && (
            <FastField
              name="paid_service_delivery_description"
              component={TextInput}
              label="Опишите условия предоставления услуги на платной основе"
              multiline
              fullWidth
              errorMessage={
                touched.paid_service_delivery_description &&
                errors.paid_service_delivery_description
              }
            />
          )}
        </TableCell>
      </TableRow>
      <PossibleChoiceFields startOfNumerate={7} />
      <TableRow>
        <TableCell className={s.question}>
          9. Установлена ли максимальная продолжительность ожидания получения
          услуги с момента записи (дней)?
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="maximum_waiting_time_exists"
            label={'Выбор из списка'}
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            hasCopyBtn={false}
            fullWidth
          />
          {values.maximum_waiting_time_exists && (
            <FastField
              name="maximum_waiting_time"
              component={TextInputNumeric}
              label="Максимальная продолжительность дней"
              unit=""
              fullWidth
              errorMessage={
                touched.maximum_waiting_time && errors.maximum_waiting_time
              }
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          10. Проводится ли на территории субъекта Российской Федерации
          информационно-разъяснительная деятельность, направленная на доведение
          до родителей (законных представителей) детей информации о возможности
          получения услуги? Опишите реализуемые меры, перечислите информационные
          каналы и перечень вопросов, по которым получатели услуг получают
          информацию.
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="information_is_being_distributed"
            label={'Выбор из списка'}
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            hasCopyBtn={false}
            fullWidth
          />
          <FastField
            name="information_distribution_description"
            component={TextInput}
            label="Опишите реализуемые меры, перечень вопросов, ссылки на источники информации"
            multiline
            fullWidth
          />
          <UniversalDocumentCell
            name={'information_distribution_protocols'}
            title={'Прикрепите файлы (при наличии)'}
            canEditDescription={false}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          11. Проводится ли оценка качества оказываемых услуг? Опишите методику
          и порядок оценки качества услуг (при наличии)
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="services_quality_is_being_controlled"
            label={'Выбор из списка'}
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            hasCopyBtn={false}
            fullWidth
          />
          {values.services_quality_is_being_controlled && (
            <FastField
              name="services_quality_control_methodology"
              component={TextInput}
              label="Необходимо описать методику оценки (коротко)"
              errorMessage={
                touched.services_quality_control_methodology &&
                errors.services_quality_control_methodology
              }
              multiline
              fullWidth
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          12. Представьте описание лучших практик оказания услуг, в том числе с
          описанием типовых ситуаций и типовых консультаций.
        </TableCell>
        <TableCell>
          <FastField
            name="best_practices_of_services_provision"
            label="Опишите лучшие практики (типовые консультации) оказания услуг"
            component={TextInput}
            multiline
            fullWidth
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          13. Проблемы, возникающие при использовании методических рекомендаций
          при внедрении новой услуги на территории субъекта Российской Федерации
          (при наличии), а также предложения по дальнейшему использованию
          методических рекомендаций.
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="problems_in_new_service_introduction_exist"
            label="Возникают ли проблемы?"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            hasCopyBtn={false}
            fullWidth
          />
          {values.problems_in_new_service_introduction_exist && (
            <FastField
              label="Описание проблем"
              name="problems_in_new_service_introduction_description"
              component={TextInput}
              errorMessage={
                touched.problems_in_new_service_introduction_description &&
                errors.problems_in_new_service_introduction_description
              }
              multiline
              fullWidth
            />
          )}
          <FastField
            label="Предложения по дальнейшему использованию методических рекомендаций"
            name="problems_in_new_service_introduction_suggestions"
            component={TextInput}
            multiline
            fullWidth
          />
        </TableCell>
      </TableRow>
    </SubTable>
  );
};
