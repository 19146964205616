import React, { useCallback, useState } from 'react';
import {
  showGraphqlErrorToast,
  STATUS_CODE_GRAPHQL
} from '../../../../graphql/graphqlErrors';
import { SubsidiaryMonitoringExportDialog } from './SubsidiaryMonitoringExportDialog';

export function useSubsidiaryMonitoringExportDialog(
  confirmationFn,
  dialogTitle,
  errorMessage,
  onApply = undefined
) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const openDialog = useCallback((data) => {
    setData(data);
  }, []);

  const handleClose = useCallback(() => setData(null), []);

  const handleConfirm = useCallback(
    (formData) => {
      setLoading(true);
      return confirmationFn(data, formData)
        .catch((error) => {
          showGraphqlErrorToast(error, {
            statusCode: STATUS_CODE_GRAPHQL,
            message: errorMessage
          });
        })
        .finally(() => {
          setLoading(false);
          if (onApply) {
            onApply();
          }
        });
    },
    [confirmationFn, errorMessage, onApply, data]
  );

  const dialog = (
    <SubsidiaryMonitoringExportDialog
      title={dialogTitle}
      open={!!data}
      onConfirm={handleConfirm}
      onClose={handleClose}
    />
  );

  return [loading, dialog, openDialog];
}
