import React, { useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useContextStore } from '@proscom/prostore-react';
import { CircularProgress } from '@material-ui/core';
import PageTitle from '../../common/PageTitle/PageTitle';
import { useCurrentUser } from '../../store/useCurrentUser';
import {
  QueryPagination,
  useQueryPagination,
  useQueryPaginationCount
} from '../../common/usePagination';
import { useUrlQuery } from '../../common/useUrlQuery';
import Table from '../../common/Table/Table';
import Menu from '../../common/Menu/Menu';
import { SUBSIDIARY_MONITORING_STORE } from '../../store/names';
import { useDeleteRow } from '../../common/Table/useDeleteRow';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';
import { useConfirmRow } from '../../common/Table/useConfirmRow';
import { SubsidiaryEntityNameCell } from '../subsidiaryEntities/SubsidiaryEntityNameCell';
import { createPreservedUrl } from '../../utils/links';
import { useSubsidiaryMonitoringPage } from '../../store/subsidiaryMonitoring/useSubsidiaryMonitoringPage';
import { SubsidiaryMonitoringEntity } from '../../store/subsidiaryMonitoring/SubsidiaryMonitoringEntity';
import { useGeneralConfirmationRow } from '../../common/Table/useGeneralConfirmationRow';
import { SubsidiaryMonitoringEntityApprovalValidationSchema } from '../../utils/data/validation';
import { ContestDocsMonitoringEntity } from '../../store/contestDocsMonitoring/ContestDocsMonitoringEntity';
import { useSubsidiaryMonitoringFilter } from './useSubsidiaryMonitoringFilter';
import { SubsidiaryMonitoringEntityValidationDialog } from './SubsidiaryMonitoringForm/SubsidiaryMonitoringEntityValidationDialog';

const ssMonitoringEntitiesColumns = [
  { label: '№', key: 'order', isThin: true },
  {
    label: 'Регион',
    key: 'subsidiary_entity.region.name',
    isWide: true,
    isBreak: true
  },
  {
    label: 'Дорожная карта',
    key: 'subsidiary_entity.owner_roadmap.name_full',
    isWide: true,
    isBreak: true
  },
  {
    label: 'Субсидиарная сущность',
    key: (entity) => {
      return <SubsidiaryEntityNameCell entity={entity.subsidiary_entity} />;
    },
    isWide: true,
    isBold: true
  },
  {
    label: 'Статус',
    key: (entity) => SubsidiaryMonitoringEntity.statusLabel[entity.status],
    isBreak: true
  }
];

const ssMonitoringEntitiesColumnsForOrg = [
  { label: '№', key: 'order', isThin: true },
  {
    label: 'Субсидиарная сущность',
    key: (entity) => {
      return <SubsidiaryEntityNameCell entity={entity.subsidiary_entity} />;
    },
    isWide: true,
    isBold: true
  },
  {
    label: 'Статус',
    key: (entity) => SubsidiaryMonitoringEntity.statusLabel[entity.status],
    isBreak: true
  }
];

export const subsidiaryMonitoringPageTitle =
  'Форма мониторинга по субсидиарным сущностям';

function SubsidiaryMonitoringPageComp({ user, history, location, match }) {
  const entityStore = useContextStore(SUBSIDIARY_MONITORING_STORE);
  const [isValidationError, setIsValidationError] = useState(false);

  const canCreate = SubsidiaryMonitoringEntity.canEdit(user);
  const canViewOwn = SubsidiaryMonitoringEntity.canViewOwn(user);

  const [query, changeQuery] = useUrlQuery(location, history);
  const [filter, filterComponent] = useSubsidiaryMonitoringFilter(
    query,
    changeQuery,
    user,
    canViewOwn
  );
  const pagination = useQueryPagination(query, changeQuery, 50);

  const entitiesQuery = useSubsidiaryMonitoringPage(filter, pagination.params);

  useQueryPaginationCount(pagination, entitiesQuery);
  const entitiesQueryData = entitiesQuery.state.data;
  const data = useMemo(() => {
    if (!entitiesQueryData) {
      return null;
    }
    const { list, header } = entitiesQueryData;
    return list.map((entity, iEntity) => ({
      ...entity,
      order: 1 + iEntity + header.page * header.onePage,
      queries: {
        year: filter.year,
        month: filter.month,
        region_id: entity.subsidiary_entity?.region?.id,
        roadmap_id: entity.subsidiary_entity?.owner_roadmap?.id,
        subsidiary_entity_id: entity.subsidiary_entity?.id
      }
    }));
  }, [entitiesQueryData, filter.year, filter.month]);

  const creationLink = useMemo(() => {
    return createPreservedUrl(`${match.url}/edit/`, {
      year: filter.year,
      month: filter.month,
      region_id: filter.region_id,
      roadmap_id: filter.roadmap_id
    });
  }, [match, filter]);

  const menuComponent = useMemo(
    () =>
      function MenuComponent({ row }) {
        const [isDeleting, deleteDialog, openDeleteDialog] = useDeleteRow(
          entityStore.delete,
          'форму'
        );

        const [isApproving, approveDialog, openApproveDialog] = useConfirmRow(
          entityStore.approve,
          'форму'
        );

        const [
          isSendForApprovalLoading,
          sendForApprovalDialog,
          openSendForApprovalDialog
        ] = useGeneralConfirmationRow(
          entityStore.sendForApproval,
          'Вы уверены, что хотите отправить форму на утверждение?',
          'Возникла ошибка при отправке формы на утверждение. Попробуйте еще раз'
        );

        const [
          isRequestEditLoading,
          requestEditDialog,
          openRequestEditDialog
        ] = useGeneralConfirmationRow(
          entityStore.requestEdit,
          'Вы уверены, что хотите запросить редактирование формы?',
          'Возникла ошибка при запросе редактирования формы. Попробуйте еще раз'
        );

        const [
          isAllowEditLoading,
          allowEditDialog,
          openAllowEditDialog
        ] = useGeneralConfirmationRow(
          entityStore.return,
          'Вы уверены, что хотите разрешить редактирование формы?',
          'Возникла ошибка при разрешении редактирования формы. Попробуйте еще раз'
        );

        const [
          isRejectEditLoading,
          rejectEditDialog,
          openRejectEditDialog
        ] = useGeneralConfirmationRow(
          entityStore.rejectEdit,
          'Вы уверены, что хотите запретить редактирование формы?',
          'Возникла ошибка при запрете редактирования формы. Попробуйте еще раз'
        );

        const [
          isReturnLoading,
          returnDialog,
          openReturnDialog
        ] = useGeneralConfirmationRow(
          entityStore.return,
          'Вы уверены, что хотите вернуть форму на доработку?',
          'Возникла ошибка при возвращении формы на доработку. Попробуйте еще раз'
        );

        const menu = [];
        const status = row.status;
        const isApproved =
          status === SubsidiaryMonitoringEntity.Status.APPROVED;
        const isOnApproval =
          status === SubsidiaryMonitoringEntity.Status.ON_APPROVAL;
        const isDraft = status === SubsidiaryMonitoringEntity.Status.DRAFT;
        const isReturned =
          status === SubsidiaryMonitoringEntity.Status.RETURNED;
        const isEditRequested =
          status === ContestDocsMonitoringEntity.Status.EDIT_REQUESTED;

        const canEdit = SubsidiaryMonitoringEntity.canEdit(user, row);
        const canApprove = SubsidiaryMonitoringEntity.canApprove(user);
        const canRevoke = SubsidiaryMonitoringEntity.canRevoke(user);

        if (canApprove && (isDraft || isReturned || isOnApproval)) {
          menu.push({
            title: 'Утвердить',
            onClick: () => {
              SubsidiaryMonitoringEntityApprovalValidationSchema.validate(
                SubsidiaryMonitoringEntity.toForm(row)
              )
                .then(() => {
                  openApproveDialog(row.id);
                })
                .catch((err) => {
                  console.log(err);
                  setIsValidationError(true);
                });
            }
          });
        }

        if (canEdit && !canApprove && (isDraft || isReturned)) {
          menu.push({
            title: 'Отправить на утверждение',
            onClick: () => {
              SubsidiaryMonitoringEntityApprovalValidationSchema.validate(
                SubsidiaryMonitoringEntity.toForm(row)
              )
                .then(() => {
                  openSendForApprovalDialog(row.id);
                })
                .catch((err) => {
                  console.log(err);
                  setIsValidationError(true);
                });
            }
          });
        }

        if (canEdit && !canApprove && isApproved) {
          menu.push({
            title: 'Запросить редактирование',
            onClick: () => openRequestEditDialog(row.id)
          });
        }

        if (canRevoke && isEditRequested) {
          menu.push({
            title: 'Запретить редактирование',
            onClick: () => openRejectEditDialog(row.id)
          });
        }

        if (canRevoke && isEditRequested) {
          menu.push({
            title: 'Разрешить редактирование',
            onClick: () => openAllowEditDialog(row.id)
          });
        }

        if (canRevoke && (isApproved || isOnApproval)) {
          menu.push({
            title: 'Вернуть на доработку',
            onClick: () => openReturnDialog(row.id)
          });
        }

        if (canEdit && (isDraft || isReturned)) {
          menu.push({
            title: 'Удалить',
            onClick: () => openDeleteDialog(row.id)
          });
        }

        if (menu.length === 0) {
          return false;
        }

        const loading =
          isDeleting ||
          isApproving ||
          isSendForApprovalLoading ||
          isRequestEditLoading ||
          isAllowEditLoading ||
          isRejectEditLoading ||
          isReturnLoading;

        return (
          <>
            {loading ? (
              <CircularProgress size={18} />
            ) : (
              <Menu iconColor="default" icon={MoreHorizIcon} options={menu} />
            )}
            {deleteDialog}
            {approveDialog}
            {sendForApprovalDialog}
            {requestEditDialog}
            {allowEditDialog}
            {rejectEditDialog}
            {returnDialog}
          </>
        );
      },
    [entityStore, user]
  );

  return (
    <>
      <HeadTitle title={subsidiaryMonitoringPageTitle} />
      <PageTitle withBackBtn={false} title={subsidiaryMonitoringPageTitle} />
      <SubsidiaryMonitoringEntityValidationDialog
        isOpen={isValidationError}
        onCancel={() => setIsValidationError(false)}
      >
        Вы не можете утвердить форму, пока не заполнены все обязательные поля
      </SubsidiaryMonitoringEntityValidationDialog>
      <Table
        columns={
          canViewOwn
            ? ssMonitoringEntitiesColumnsForOrg
            : ssMonitoringEntitiesColumns
        }
        isLoading={entitiesQuery.check.spinner}
        data={data}
        filter={filterComponent}
        hasAddField={canCreate}
        addButtonText="Создать"
        addFieldRoute={creationLink}
        renderNoData={() => <h3>Нет форм мониторинга по выбранным фильтрам</h3>}
        rowRedirectRoute={`${match.url}/edit/`}
        menuComponent={menuComponent}
        pagination={<QueryPagination pagination={pagination} />}
      />
    </>
  );
}

export default function SubsidiaryMonitoringPage({ history, location, match }) {
  const user = useCurrentUser();
  const canView = SubsidiaryMonitoringEntity.canView(user);

  if (!canView) {
    return <Redirect to={'/subsidiaryMonitoring/edit/'} />;
  }

  return (
    <SubsidiaryMonitoringPageComp
      user={user}
      history={history}
      location={location}
      match={match}
    />
  );
}
