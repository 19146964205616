import { pick } from 'lodash-es';
import { mapEntityOptions } from '../form/entities';
import { Organization } from '../organization/Organization';
import { EnumHelpers } from '../../utils/EnumHelpers';
import { checkString } from '../../utils/string';

const userFields = [
  'email',
  'login',
  'name',
  'new_password',
  'permissions',
  'role_ids',
  'region_code_nalog',
  'organization_id',
  'role'
];

export class User {
  static Role = {
    ADMIN: 'ADMIN',
    FEDERAL_ADMIN: 'FEDERAL_ADMIN',
    REGION_ADMIN: 'REGION_ADMIN',
    ORGANIZATION_ADMIN: 'ORGANIZATION_ADMIN'
  };

  static role = new EnumHelpers(this.Role, {
    ADMIN: 'Администратор',
    FEDERAL_ADMIN: 'Администратор ФПО',
    REGION_ADMIN: 'Администратор РВПО',
    ORGANIZATION_ADMIN: 'Администратор образовательной организации'
  });

  static getRoleAdminPanelName(user) {
    if (user.role === this.Role.ADMIN) {
      return 'ЛК администратора';
    }
    if (user.role === this.Role.FEDERAL_ADMIN) {
      return 'ЛК федерального проектного офиса';
    }
    if (user.role === this.Role.REGION_ADMIN) {
      return `ЛК регионального ведомственного проектного офиса ${
        user.region?.name ?? ''
      }`;
    }
    if (user.role === this.Role.ORGANIZATION_ADMIN) {
      return `ЛК организации ${Organization.getName(user.organization)}`;
    }
  }

  static getUserPermissions(user) {
    const permissions = {};
    if (!user) {
      return permissions;
    }

    if (user.roles) {
      for (const role of user.roles) {
        if (!role.permissions) {
          continue;
        }
        permissions[role.name] = [];
        for (const perm of role.permissions) {
          permissions[role.name].push(perm);
        }
      }
    }

    if (user.permissions) {
      permissions.userPermissions = [];
      for (const perm of user.permissions) {
        permissions.userPermissions.push(perm);
      }
    }
    return permissions;
  }

  static getUserName(user) {
    return user
      ? user.name || user.email || user.login || (user.id && `id${user.id}`)
      : null;
  }

  static getUser(auth) {
    return auth.authData && auth.authData && auth.authData.user;
  }

  /**
   * Преобразовывает формат прав из запроса описания в формат пользовательских прав
   * @param allPermission
   * @returns {[]}
   */
  static getAdminPermission(allPermission) {
    const result = [];
    if (!allPermission) {
      return result;
    }

    for (const perm of allPermission) {
      const permission = {
        base_permission_id: perm.id
      };
      if (perm.type === 'OBJECT') {
        permission.object_type = 'all';
      }
      result.push(permission);
    }
    return result;
  }

  static getAllPermissionsMap(user, permissions) {
    const result = {};

    const setPermissionToObject = (permission) => {
      const { object_type, object_id } = permission;
      const baseId = permission.base_permission_id;
      if (result[baseId]) {
        if (object_type === 'all') {
          result[baseId].object_type = 'all';
        } else if (object_id) {
          result[baseId].object_ids.add(object_id);
        }
      } else {
        result[baseId] = {
          ...permission,
          object_id: undefined,
          object_ids: new Set(object_id ? [object_id] : [])
        };
      }
    };

    if (user?.roles) {
      for (const role of user.roles) {
        if (!role.permissions) {
          continue;
        }
        for (const permission of role.permissions) {
          setPermissionToObject(permission);
        }
      }
    }
    if (user?.permissions) {
      for (const permission of user.permissions) {
        setPermissionToObject(permission);
      }
    }
    if (permissions) {
      for (const perm of permissions) {
        setPermissionToObject(perm);
      }
    }
    return result;
  }

  static getPermissionsFromMap(permissionsMap) {
    const result = [];
    for (const permission of Object.values(permissionsMap)) {
      const { base_permission_id, object_type, object_ids } = permission;
      if (object_type === 'all') {
        result.push({
          base_permission_id,
          object_type
        });
      } else if (object_ids.size > 0) {
        for (const object_id of object_ids) {
          result.push({
            base_permission_id,
            object_id
          });
        }
      } else {
        result.push({
          base_permission_id
        });
      }
    }
    return result;
  }

  static getObjects(user, objects, permission) {
    if (this.isAdmin(user)) {
      return objects;
    } else {
      const userPermissions = this.getAllPermissionsMap(user);
      const permissionInfo = userPermissions[permission];
      if (!permissionInfo) {
        return [];
      } else if (permissionInfo.object_type === 'all') {
        return objects;
      } else if (permissionInfo.object_ids) {
        return objects?.filter((object) =>
          permissionInfo.object_ids.has(object.id)
        );
      } else {
        return [];
      }
    }
  }

  static getRegions(user, regions) {
    if (!regions) return regions;
    const permissionRegions = this.getObjects(user, regions, 'region.access');
    const userRegion = regions.find(
      (r) => user?.region_code_nalog === r.code_nalog
    );
    const alreadyIncluded = permissionRegions?.some(
      (r) => user?.region_code_nalog === r.code_nalog
    );
    if (userRegion && !alreadyIncluded) {
      return [userRegion, ...(permissionRegions || [])];
    } else {
      return permissionRegions;
    }
  }

  static getObjectOptions(user, objects, permission) {
    if (this.isAdmin(user)) {
      const objectOptions = mapEntityOptions(objects) || [];
      return [{ value: 'all', label: 'Все' }, ...objectOptions];
    } else {
      const userPermissions = this.getAllPermissionsMap(user);
      const permissionInfo = userPermissions[permission];
      if (!permissionInfo) {
        return null;
      } else if (permissionInfo.object_type === 'all') {
        const objectOptions = mapEntityOptions(objects) || [];
        return [{ value: 'all', label: 'Все' }, ...objectOptions];
      } else if (permissionInfo.object_ids) {
        const userObjectIds = permissionInfo.object_ids;
        const filtered = objects?.filter((object) =>
          userObjectIds.has(object.id)
        );
        return mapEntityOptions(filtered);
      } else {
        return null;
      }
    }
  }

  static getRole(user) {
    return {
      isAdmin: user?.role === User.Role.ADMIN,
      isRegionAdmin: user?.role === User.Role.REGION_ADMIN,
      isFederalAdmin: user?.role === User.Role.FEDERAL_ADMIN,
      isOrganizationAdmin: user?.role === User.Role.ORGANIZATION_ADMIN
    };
  }

  static isAdmin(user) {
    return user?.role === this.Role.ADMIN;
  }

  /**
   * Проверяет наличие хотя бы одного из переданных прав у пользователя
   * @param user - пользователь
   * @param permissions - права
   * @returns {boolean} - есть ли у пользователя право
   */
  static hasPermission(user, ...permissions) {
    if (!user) {
      return false;
    }

    if (this.isAdmin(user)) {
      return true;
    }

    const isRequested = (p) => permissions.includes(p.base_permission_id);

    return !!(
      user.roles?.some((r) => r.permissions?.some(isRequested)) ||
      user.permissions?.some(isRequested)
    );
  }

  static hasObjectPermission(user, permission, object_id) {
    if (!user) {
      return false;
    }

    if (this.isAdmin(user)) {
      return true;
    }

    const isRequested = (p) =>
      p.base_permission_id === permission &&
      (p.object_type === 'all' || p.object_id === object_id);

    return !!(
      user.roles?.some((r) => r.permissions?.some(isRequested)) ||
      user.permissions?.some(isRequested)
    );
  }

  static filterUpdateFields(data) {
    return pick(data, ['id', ...userFields]);
  }

  static filterCreateFields(data) {
    return pick(data, userFields);
  }

  static toForm(user) {
    return {
      ...user,
      new_password: undefined,
      repeat_password: undefined
    };
  }

  static fromForm({ roles, role, login, email, ...fields }, user) {
    return {
      ...fields,
      login: checkString(login, undefined),
      email: checkString(email, undefined),
      permissions: fields.permissions
        ? fields.permissions.map((perm) => ({
            base_permission_id: perm.base_permission_id,
            object_id: perm.object_id,
            object_type: perm.object_type
          }))
        : undefined,
      role: role || user?.role || User.Role.ADMIN,
      role_ids: roles[0].id
    };
  }

  static getUrl(user) {
    return `/users/${user.id}`;
  }
}
