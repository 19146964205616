import React, { useMemo } from 'react';
import Table from '../../../../common/Table/Table';
import { RegionalEvent } from '../../../../store/roadmap/RegionalEvent';
import { useEntityList } from '../../../../store/form/useEntityList';
import { useMemoSuggestions } from '../../../../utils/suggestions';
import AutocompleteBaseInput from '../../../../common/Inputs/Selects/AutocompleteBaseInput';
import { useUrlQuery } from '../../../../common/useUrlQuery';

const regionsColumns = [
  { label: '№', key: 'order', isThin: true },
  {
    label: 'Регион',
    key: 'region.name',
    isBold: true,
    isWide: true
  },
  { label: 'Статус регионального мероприятия', key: 'statusName', isWide: true }
];

export const Regions = ({
  match,
  regionalRoadmapEvents,
  location,
  history
}) => {
  const [query, changeQuery] = useUrlQuery(location, history);

  const { roadmapId, eventId } = match.params;
  const { federal_district_id } = query;

  const federalDistrictQuery = useEntityList('FederalDistrict');
  const federalDistrict = useMemo(() => {
    if (!federalDistrictQuery) return [];
    return federalDistrictQuery.state.data;
  }, [federalDistrictQuery]);
  const federalDistrictSuggestions = useMemoSuggestions(federalDistrict);

  const regions = useMemo(
    () =>
      regionalRoadmapEvents
        .filter((regionalRoadmapEvent) => {
          if (!federal_district_id) return true;
          return (
            regionalRoadmapEvent.region.federal_district_id ===
            federal_district_id
          );
        })
        .map((regionalRoadmapEvent, iOrder) => ({
          ...regionalRoadmapEvent,
          statusName: RegionalEvent.status.getName(regionalRoadmapEvent.status),
          order: iOrder + 1
        })),
    [regionalRoadmapEvents, federal_district_id]
  );

  return (
    <>
      <AutocompleteBaseInput
        label="Федеральный округ"
        name="federal_district_id"
        value={federal_district_id}
        onChange={(value, name) =>
          changeQuery({
            [name]: value
          })
        }
        suggestions={federalDistrictSuggestions}
        hasCopyBtn={false}
        variant="standard"
      />
      <Table
        columns={regionsColumns}
        data={regions}
        rowRedirectRoute={`/roadmaps/${roadmapId}/events/${eventId}/regions/`}
      />
    </>
  );
};
