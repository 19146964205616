import gql from 'graphql-tag';

import { FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT } from './subsidiaryEntity';
import { FRAGMENT_USER_FOR_LIST } from './users';
import { QUERY_GET_FEDERAL_PROJECTS_AVAILABLE_FOR_CONTEST_DOCS_MONITORING } from './federalProjects';

export const QUERY_GET_FEDERAL_PROJECT_ROADMAPS = gql`
  query federalProjectRoadmaps($filter: RoadmapFilterInput) {
    federalProjectRoadmaps(filter: $filter) {
      id
      code
      name_full
      is_small_school_field_required
    }
  }
`;

export const QUERY_GET_ROADMAP = gql`
  query roadmap($id: ID!) {
    roadmap(id: $id) {
      id
      code
      name
      name_full
      roadmap_years {
        year
      }
      is_small_school_field_required
    }
  }
`;

export const QUERY_GET_ROADMAP_EVENTS_FOR_FILTER = gql`
  query roadmapEventsForFilter($roadmap_id: ID, $year: Int) {
    roadmapEventsPage(
      input: { filter: { roadmap_id: $roadmap_id, year: $year } }
    ) {
      list {
        id
        name
        name_full
      }
    }
  }
`;

export const QUERY_GET_ROADMAP_EVENTS = gql`
  query roadmapEvents(
    $filter: RoadmapEventsFilter
    $sorting: [RoadmapEventsSorting!]
  ) {
    roadmapEventsPage(input: { filter: $filter, sorting: $sorting }) {
      list {
        id
        code
        name
        name_full
        year
        plan_term
        roadmap_id
        permissions {
          edit
        }
      }
    }
  }
`;

export const FRAGMENT_REGION = gql`
  fragment region on RegionType {
    id
    name
  }
`;

export const FRAGMENT_RESPONSIBLE_PERSON = gql`
  fragment responsiblePerson on IndividualType {
    id
    first_name
    surname
    patronymic
    emails
    phones
  }
`;

export const FRAGMENT_ROADMAP_EVENT_DOCUMENTS = gql`
  fragment roadmapEventDocuments on RoadmapEventDocumentType {
    id
    name
    status
    event_id
    comment_fpo
    document_slot_id
    type
    number
    license_expiration_date
    date_approval
    content
    photo_category_id
    subsidiary_entity_id
    subsidiary_entity {
      ...subsidiaryEntityForSelect
    }
    connected_file {
      id
      name
      file_size
      file_extension
    }
  }

  ${FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT}
`;

export const FRAGMENT_ROADMAP = gql`
  fragment roadmap on RoadmapType {
    id
    name_full
    roadmap_years {
      year
      result {
        id
        name_full
        expenses_direction {
          id
        }
      }
    }
  }
`;

export const QUERY_GET_ROADMAP_EVENT_DOCUMENTS = gql`
  query getRoadmapEventDocuments($filter: RoadmapEventDocumentsFilterInput) {
    roadmapEventDocuments(filter: $filter) {
      ...roadmapEventDocuments
    }
  }
  ${FRAGMENT_ROADMAP_EVENT_DOCUMENTS}
`;

export const FRAGMENT_ROADMAP_EVENT_DOCUMENT_SLOTS = gql`
  fragment roadmapEventDocumentSlots on RoadmapEventDocumentSlotType {
    code
    id
    name
    name_full
    type
    federal
    code_name
  }
`;

export const FRAGMENT_REGIONAL_ROADMAP_EVENT = gql`
  fragment RegionalRoadmapEvent on RegionalRoadmapEventType {
    id
    code
    name_full
    fact_term
    status
    annotation
    version
    created_at
    updated_at
    event_type
    comment_fpo
    approver {
      ...userForList
    }
    approved_at
    region {
      ...region
    }
    regional_project {
      id
      name
    }
    responsible_person {
      ...responsiblePerson
      individual_data {
        department
        occupation
      }
    }
    roadmap_event_documents {
      ...roadmapEventDocuments
    }
    roadmap_event {
      id
      roadmap_id
    }
    staff_schedules {
      id
      occupation
      amount_of_staff
      staff_schedule_guideline_id
      subsidiary_entity_id
      stuff_schedule_guideline {
        id
        amount_of_staff
        amount_of_staff_string
        occupation
        staff_category
        comment
        number
      }
    }
    permissions {
      approve
      edit
      sendForApproval
    }
  }

  ${FRAGMENT_REGION}
  ${FRAGMENT_RESPONSIBLE_PERSON}
  ${FRAGMENT_ROADMAP_EVENT_DOCUMENTS}
  ${FRAGMENT_USER_FOR_LIST}
  ${FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT}
`;

export const FRAGMENT_ROADMAP_EVENT = gql`
  fragment roadmapEvent on RoadmapEventType {
    id
    code
    name_full
    responsibility_level_rf_subject
    responsibility_level_vo
    display_document_slots
    upload_arbitrary_documents
    roadmap_id
    year
    event_type
    event_result
    plan_term
    responsible_person {
      ...responsiblePerson
      individual_data {
        occupation
      }
    }

    roadmap {
      ...roadmap
    }
    plan_roadmap {
      event_result
      term
      periodicity_string
    }
    roadmap_event_document_slots {
      ...roadmapEventDocumentSlots
    }
    roadmap_event_documents {
      ...roadmapEventDocuments
    }
  }

  ${FRAGMENT_RESPONSIBLE_PERSON}
  ${FRAGMENT_ROADMAP}
  ${FRAGMENT_ROADMAP_EVENT_DOCUMENT_SLOTS}
  ${FRAGMENT_ROADMAP_EVENT_DOCUMENTS}
`;

export const QUERY_GET_REGIONAL_ROADMAP_EVENT = gql`
  query regionalRoadmapEvent($id: ID!) {
    regionalRoadmapEvent(id: $id) {
      ...RegionalRoadmapEvent
      roadmap_event {
        ...roadmapEvent
      }
    }
  }

  ${FRAGMENT_REGIONAL_ROADMAP_EVENT}
  ${FRAGMENT_ROADMAP_EVENT}
`;

export const QUERY_GET_ROADMAP_EVENT = gql`
  query roadmapEvent($id: ID!, $filter: RegionalRoadmapEventsFilter) {
    roadmapEvent(id: $id) {
      ...roadmapEvent
      regional_roadmap_events(filter: $filter) {
        ...RegionalRoadmapEvent
      }
    }
  }

  ${FRAGMENT_ROADMAP_EVENT}
  ${FRAGMENT_REGIONAL_ROADMAP_EVENT}
`;

export const QUERY_GET_ROADMAP_EVENT_WITH_REGION_EVENTS_LIST = gql`
  query roadmapEventWithRegionEventsList(
    $id: ID!
    $filter: RegionalRoadmapEventsFilter
  ) {
    roadmapEvent(id: $id) {
      ...roadmapEvent
      regional_roadmap_events(filter: $filter) {
        id
        status
        region {
          id
          name
          federal_district_id
        }
      }
      version
      year
      permissions {
        edit
      }
    }
  }

  ${FRAGMENT_ROADMAP_EVENT}
`;

export const QUERY_GET_ROADMAP_EVENTS_FOR_MONITORING = gql`
  query roadmapsForSubsidiaryEntityMonitoring(
    $input: SubsidiaryEntityMonitoringRoadmapsSearch!
  ) {
    roadmapsForSubsidiaryEntityMonitoring(args: $input) {
      id
      name
      name_full
    }
  }
`;

export const QUERY_GET_ROADMAPS_AVAILABLE_FOR_CONTEST_DOCS_MONITORING = gql`
  query roadmapsAvailableForTenderDocumentationForSS(
    $input: TenderDocumentationForSSRoadmapsSearch!
  ) {
    roadmapsAvailableForTenderDocumentationForSS(filter: $input) {
      id
      name
      name_full
    }
  }
`;
