import React, { useMemo } from 'react';
import { useRoadmaps } from '../../../store/roadmap/useRoadmaps';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { useRegions } from '../../../store/subsidiaryEntity/useSubsidiaryEntity';
import { useRetriableRegionalProjects } from '../../../store/regionalProject/useRegionalProjects';
import { User } from '../../../store/role/User';
import { NewAutocompleteInput } from '../../InputsNew/NewAutocompleteInput';
import { useForms } from '../../../store/form/formQueries';

export function PermissionObjectSelector({
  label,
  query,
  permission,
  nothingMessage = 'Нет доступных объектов',
  ...props
}) {
  const user = useCurrentUser();
  const items =
    query.state.data?.data || query.state.data?.list || query.state.data;

  const suggestions = useMemo(
    () => items && User.getObjectOptions(user, items, permission),
    [user, items, permission]
  );

  return (
    <NewAutocompleteInput
      suggestionsLoading={query.check.spinner}
      suggestions={suggestions}
      isClearable={false}
      hasCopyBtn={false}
      fullWidth
      isDisabled={!(suggestions?.length !== 0)}
      errorMessage={
        !query.check.spinner && !(suggestions?.length !== 0) && nothingMessage
      }
      {...props}
    />
  );
}

export const RoadmapSelector = (props) => {
  const user = useCurrentUser();
  const roadmapsQuery = useRoadmaps({
    region_id: user?.region?.id
  });
  return (
    <PermissionObjectSelector
      label="Дорожная карта"
      query={roadmapsQuery}
      permission={props.permission ? props.permission : 'roadmap.access'}
      nothingMessage="Нет доступных дорожных карт"
      {...props}
    />
  );
};

export const RegionSelector = (props) => {
  const regionsQuery = useRegions();
  return (
    <PermissionObjectSelector
      label="Субъект РФ"
      query={regionsQuery}
      permission="region.access"
      nothingMessage="Нет доступных субъектов РФ"
      {...props}
    />
  );
};

export const RegionalProjectsSelector = (props) => {
  const user = useCurrentUser();
  const regionalProjectsQuery = useRetriableRegionalProjects({
    region_ids: user?.region?.id,
    has_pof: true
  });
  return (
    <PermissionObjectSelector
      label="Региональный проект"
      query={regionalProjectsQuery}
      permission="regional_project.access"
      nothingMessage="Нет доступных региональных проектов"
      {...props}
    />
  );
};

export const FormSelector = ({ permission = 'form.fill', ...props }) => {
  const query = useForms(
    permission === 'form.fill_all'
      ? {
          canFillAll: true
        }
      : {
          canFill: true
        }
  );
  return (
    <PermissionObjectSelector
      label="Форма"
      query={query}
      permission={permission}
      nothingMessage="Нет доступных форм"
      {...props}
    />
  );
};
