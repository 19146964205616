import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'formik';
import { Autocomplete } from '../../FormFields/Autocomplete/Autocomplete';

export const FormikAutocomplete = ({
  name,
  options = [],
  searchable,
  onChange,
  placeholder,
  hasCopyBtn
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => (
        <Autocomplete
          name={name}
          options={options}
          searchable={searchable}
          onChange={(v) => {
            form.setFieldValue(name, v);
            if (onChange) {
              onChange(v);
            }
          }}
          placeholder={placeholder}
          value={field.value}
          error={meta.touched && meta.error}
          hasCopyBtn={hasCopyBtn}
        />
      )}
    </Field>
  );
};

FormikAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any,
      value: PropTypes.string
    })
  ),
  searchable: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  hasCopyBtn: PropTypes.bool
};
