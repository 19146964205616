import React, { useCallback, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { stringifyUrl } from 'query-string';
import { useContextStore } from '@proscom/prostore-react';
import { toast } from 'react-toastify';
import { useCurrentUser } from '../../store/useCurrentUser';
import { useOtherEditor } from '../../common/Form/useOtherEditor';
import { useContestDocsForSSMonitoringEntity } from '../../store/contestDocsForSSMonitoring/useContestDocsForSSMonitoringEntity';
import { useUrlQuery } from '../../common/useUrlQuery';
import { getCurrentYear } from '../../utils/data/date';
import {
  CONTEST_DOCS_FOR_SS_MONITORING_STORE,
  STORE_FILE_TOKEN
} from '../../store/names';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../graphql/graphqlErrors';
import { useDeleteRow } from '../../common/Table/useDeleteRow';
import {
  ContestDocsForSSMonitoringEntityApprovalValidationSchema,
  ContestDocsForSSMonitoringEntityDraftValidationSchema,
  lazyValidation
} from '../../utils/data/validation';
import { useGeneralConfirmationRow } from '../../common/Table/useGeneralConfirmationRow';
import { downloadRestFile } from '../../utils/AxiosHelpers';
import { SubsidiaryMonitoringEntity } from '../../store/subsidiaryMonitoring/SubsidiaryMonitoringEntity';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';
import PageTitle from '../../common/PageTitle/PageTitle';
import { ExportHistoryPopup } from '../../common/ExportHistoryPopup/ExportHistoryPopup';
import { QUERY_CONTEST_DOCS_FOR_SS_MONITORING_EXPORT_HISTORY } from '../../graphql/queries/contestDocsMonitoring';
import { useUserRole } from '../../store/role/useUserRole';
import { ContestDocsForSSMonitoringEntity } from '../../store/contestDocsForSSMonitoring/ContestDocsForSSMonitoringEntity';
import { SubsidiaryEntity } from '../../store/subsidiaryEntity/SubsidiaryEntity';
import { useSubsidiaryEntitiesAvailableForContestDocsMonitoring } from '../../store/subsidiaryEntity/useSubsidiaryEntities';
import { useRegions } from '../../store/subsidiaryEntity/useSubsidiaryEntity';
import { User } from '../../store/role/User';
import { useFederalProjectsAvailableForContestDocsMonitoring } from '../../store/federalProject/useFederalProjects';
import { useMemoSuggestions } from '../../utils/suggestions';
import { ContestDocsMonitoringForm } from './ContestDocsMonitoringForm/ContestDocsMonitoringForm';
import { useContestDocsExportDialog } from './ContestDocsExport/useContestDocsExportDialog';
import { contestDocsForSSMonitoringPageTitle } from './ContestDocsMonitoringPage';

export default function ContestDocsMonitoringFormPage({
  match,
  history,
  location
}) {
  const { entityId } = match.params;
  const [query, changeQuery] = useUrlQuery(location, history);

  const user = useCurrentUser();
  const { isRegionAdmin } = useUserRole(user);

  const [exportHistoryOpen, setExportHistoryOpen] = useState(false);

  const entityStore = useContextStore(CONTEST_DOCS_FOR_SS_MONITORING_STORE);
  const tokenStore = useContextStore(STORE_FILE_TOKEN);

  const [isDeleting, deleteDialog, openDeleteDialog] = useDeleteRow(
    entityStore.delete,
    'форму',
    () =>
      changeQuery({
        region_id: undefined,
        federal_project_id: undefined,
        subsidiary_entity_id: undefined
      })
  );
  const [
    isRequestEditLoading,
    requestEditDialog,
    openRequestEditDialog
  ] = useGeneralConfirmationRow(
    entityStore.requestEdit,
    'Вы уверены, что хотите запросить редактирование формы?',
    'Возникла ошибка при запросе редактирования формы. Попробуйте еще раз'
  );
  const [
    isAllowEditLoading,
    allowEditDialog,
    openAllowEditDialog
  ] = useGeneralConfirmationRow(
    entityStore.allowEdit,
    'Вы уверены, что хотите разрешить редактирование формы?',
    'Возникла ошибка при разрешении редактирования формы. Попробуйте еще раз'
  );
  const [
    isRejectEditLoading,
    rejectEditDialog,
    openRejectEditDialog
  ] = useGeneralConfirmationRow(
    entityStore.rejectEdit,
    'Вы уверены, что хотите запретить редактирование формы?',
    'Возникла ошибка при запрете редактирования формы. Попробуйте еще раз'
  );
  const [
    exportLoading,
    exportDialog,
    openExportDialog
  ] = useContestDocsExportDialog(
    async (id, data) => {
      const accessToken = await tokenStore.createTenderDocumentationForSSReportOneTimeToken();
      const url = stringifyUrl({
        url: `/monthly_monitoring/tender_documentation_for_ss/${id}`,
        query: data
      });
      await downloadRestFile({
        method: 'get',
        responseUrl: url,
        accessToken
      });
    },
    'Для экспорта формы укажите должность и ФИО лица, подписывающего документ',
    'Возникла ошибка при экспорте формы. Попробуйте еще раз'
  );

  const year = query.year || getCurrentYear().toString();

  const regionsQuery = useRegions();
  const regions = regionsQuery.state.data;
  const regionsLoading = regionsQuery.check.spinner;
  const availableRegions = useMemo(() => User.getRegions(user, regions), [
    user,
    regions
  ]);
  const regionsSuggestions = useMemoSuggestions(availableRegions);

  const federalProjectsQuery = useFederalProjectsAvailableForContestDocsMonitoring(
    {
      year: +year
    }
  );
  const federalProjects = federalProjectsQuery.state.data;
  const federalProjectsLoading = federalProjectsQuery.check.spinner;
  const federalProjectsSuggestions = useMemoSuggestions(federalProjects);

  const region_id = isRegionAdmin
    ? user?.region?.id
    : query.region_id || regionsSuggestions?.[0]?.value;
  const federal_project_id =
    query.federal_project_id || federalProjectsSuggestions?.[0]?.value;
  const subsidiary_entity_id = query.subsidiary_entity_id;

  const subsidiaryEntitiesFilter = useMemo(() => {
    if (!year || !federal_project_id) return null;
    return {
      year: +year,
      region_id,
      federal_project_id
    };
  }, [region_id, federal_project_id, year]);
  const subsidiaryEntitiesQuery = useSubsidiaryEntitiesAvailableForContestDocsMonitoring(
    subsidiaryEntitiesFilter
  );
  const querySubsidiaryEntities = subsidiaryEntitiesQuery.state.data;
  const subsidiaryEntitiesLoading = subsidiaryEntitiesQuery.check.spinner;
  const subsidiaryEntities = useMemo(() => {
    return querySubsidiaryEntities?.map((entity) => ({
      ...entity,
      name_full: SubsidiaryEntity.getCompleteName(entity)
    }));
  }, [querySubsidiaryEntities]);
  const subsidiaryEntitiesSuggestions = useMemoSuggestions(subsidiaryEntities);

  const roadmaps = useMemo(() => {
    return querySubsidiaryEntities?.map((se) => se.owner_roadmap);
  }, [querySubsidiaryEntities]);
  const roadmapsSuggestions = useMemoSuggestions(roadmaps);
  const selectedRoadmapId = useMemo(() => {
    if (!subsidiary_entity_id) return null;
    return querySubsidiaryEntities?.find((se) => se.id === subsidiary_entity_id)
      ?.owner_roadmap?.id;
  }, [subsidiary_entity_id, querySubsidiaryEntities]);

  const filter = {
    year,
    region_id,
    federal_project_id,
    subsidiary_entity_id
  };

  const input = useMemo(() => {
    return !isDeleting && year && federal_project_id && subsidiary_entity_id
      ? {
          subsidiary_entity_id,
          federal_project_id,
          year: +year
        }
      : null;
  }, [isDeleting, subsidiary_entity_id, federal_project_id, year]);
  const entityQuery = useContestDocsForSSMonitoringEntity(input);
  const entityQueryLoading = entityQuery.check.spinner;
  const entityQueryData = entityQuery.state.data;
  const entityQueryDataId = entityQueryData?.id;

  const handleChangeFilter = useCallback(
    (name, value) => {
      const obj = {
        [name]: value
      };

      if (
        name === 'region_id' ||
        name === 'federal_project_id' ||
        name === 'year'
      ) {
        obj.subsidiary_entity_id = undefined;
      }

      changeQuery(obj);
    },
    [changeQuery]
  );

  const handleSubmit = useCallback(
    (data, actions) => {
      const input = ContestDocsForSSMonitoringEntity.fromForm(data);

      const isSubmit =
        data['submitStatus'] ===
        ContestDocsForSSMonitoringEntity.formSubmitStatus.Submit;
      const isApprove =
        data['submitStatus'] ===
        ContestDocsForSSMonitoringEntity.formSubmitStatus.Approve;

      if (isSubmit) {
        entityStore
          .update(input)
          .then((result) => {
            // actions.setStatus(FORM_STATUS_SUBMITTED);
            // setTimeout(() => {
            //   history.replace('/contestDocsMonitoring');
            // }, 0);
            toast.success('Форма успешно сохранена');
          })
          .catch((error) => {
            showGraphqlErrorToast(error, mutationErrors.updateObject);
          })
          .then(() => {
            actions.setSubmitting(false);
            // actions.resetForm();
          });
      }
      if (isApprove) {
        entityStore
          .approve(entityQueryDataId)
          .catch((error) => {
            showGraphqlErrorToast(error, mutationErrors.approveObject);
          })
          .then(() => {
            actions.setSubmitting(false);
            // actions.resetForm();
          });
      }
    },
    [entityStore, entityQueryDataId]
  );

  const handleGoBack = useCallback(() => {
    history.replace('/contestDocsMonitoring');
  }, [history]);

  const initialValues = useMemo(() => {
    return ContestDocsForSSMonitoringEntity.toForm(entityQueryData);
  }, [entityQueryData]);

  const editor = useOtherEditor(
    'tenderDocumentationForSS',
    entityId || entityQueryDataId
  );

  const loading =
    isDeleting ||
    isRequestEditLoading ||
    isAllowEditLoading ||
    isRejectEditLoading;

  return (
    <>
      <HeadTitle title={contestDocsForSSMonitoringPageTitle} />
      <PageTitle
        withBackBtn={false}
        title={contestDocsForSSMonitoringPageTitle}
      />
      <Formik
        validationSchema={lazyValidation((values) => {
          const isApproval =
            values['submitStatus'] ===
            SubsidiaryMonitoringEntity.formSubmitStatus.Approve;
          return isApproval
            ? ContestDocsForSSMonitoringEntityApprovalValidationSchema
            : ContestDocsForSSMonitoringEntityDraftValidationSchema;
        })}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        render={(formikProps) => (
          <ContestDocsMonitoringForm
            {...formikProps}
            entity={entityQueryData}
            entityLoading={entityQueryLoading || loading}
            entityDeleting={isDeleting}
            exportLoading={exportLoading}
            editor={editor}
            filter={filter}
            regionsSuggestions={regionsSuggestions}
            regionsLoading={regionsLoading}
            federalProjectsSuggestions={federalProjectsSuggestions}
            federalProjectsLoading={federalProjectsLoading}
            subsidiaryEntitiesSuggestions={subsidiaryEntitiesSuggestions}
            subsidiaryEntitiesLoading={subsidiaryEntitiesLoading}
            roadmapsSuggestions={roadmapsSuggestions}
            selectedRoadmapId={selectedRoadmapId}
            onFilterChange={handleChangeFilter}
            onBackClick={handleGoBack}
            onDelete={openDeleteDialog}
            onRequestEdit={openRequestEditDialog}
            onAllowEdit={openAllowEditDialog}
            onRejectEdit={openRejectEditDialog}
            onExport={openExportDialog}
            onExportHistoryOpen={() => setExportHistoryOpen(true)}
          />
        )}
      />
      <ExportHistoryPopup
        id={entityId || entityQueryDataId}
        query={QUERY_CONTEST_DOCS_FOR_SS_MONITORING_EXPORT_HISTORY}
        open={exportHistoryOpen}
        onClose={() => setExportHistoryOpen(false)}
      />
      {deleteDialog}
      {requestEditDialog}
      {allowEditDialog}
      {rejectEditDialog}
      {exportDialog}
    </>
  );
}
