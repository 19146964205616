import React from 'react';
import { useContextStore, useStore } from '@proscom/prostore-react';
import { Redirect } from 'react-router';
import { STORE_AUTH, STORE_LOGIN } from '../store/names';
import HasNoAccessPage from './hasNoAccessPage/HasNoAccessPage';

export function RequireAuth(Component, checkAccess = false) {
  return function RequireAuthComponent(props) {
    const [auth, authStore] = useStore(STORE_AUTH);
    const store = useContextStore(STORE_LOGIN);

    if (!auth.loaded) return;
    if (!authStore.isLoggedIn()) {
      store.setTargetPath(props.location.pathname);
      return <Redirect to={'/login'} />;
    }

    if (checkAccess && !checkAccess(auth)) {
      return <HasNoAccessPage />;
    }

    return <Component {...props} />;
  };
}
