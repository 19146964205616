import { useStore } from '@proscom/prostore-react';
import { useMemo } from 'react';
import { useUserRoleByPermissions } from '../../../utils/hooks/useUserRoleByPermissions';
import { STORE_AUTH } from '../../names';
import { User } from '../../role/User';

export const useGenerateCommentListForGeneralBranch = (comments) => {
  const { getRole } = useUserRoleByPermissions();
  const [auth] = useStore(STORE_AUTH);
  const loggedUser = User.getUser(auth);
  const loggedUserRole = getRole(loggedUser);

  return useMemo(() => {
    const generalBranchComments = [];

    // пробегаемся по комментариям и разделяем на виды
    // либо сохраняем все в объединенный коммент, либо оставляем отдельным элементом
    comments.forEach((item) => {
      const commentRole = getRole(item?.commentary_user);
      const lastBranch =
        generalBranchComments[generalBranchComments.length - 1];
      const isLastBunchNotAccepted = !(
        lastBranch &&
        lastBranch?.type === 'bunch' &&
        lastBranch?.status.includes('not_accepted')
      );
      if (loggedUserRole?.type === commentRole?.type) {
        generalBranchComments.push({
          type: 'single',
          comments: [item],
          status: item?.accept_status,
          canDeleted: isLastBunchNotAccepted
        });
      } else {
        if (lastBranch && lastBranch?.type === 'bunch') {
          generalBranchComments[generalBranchComments.length - 1].comments.push(
            item
          );
        } else {
          generalBranchComments.push({
            type: 'bunch',
            comments: [item],
            status: item?.accept_status,
            canDeleted: false
          });
        }
      }
    });

    return generalBranchComments;
  }, [comments, getRole, loggedUserRole]);
};
