import React from 'react';
import AutocompleteBaseInput from '../Inputs/Selects/AutocompleteBaseInput';
import { useFieldFast } from './useFieldFast';

export const NewAutocompleteInput = function NewAutocompleteInput({
  handleChange,
  errorMessage,
  ...props
}) {
  const [field, meta, helpers] = useFieldFast(props);

  return (
    <AutocompleteBaseInput
      {...field}
      onChange={handleChange || helpers.setValue}
      errorMessage={errorMessage || (meta.touched && meta.error)}
      {...props}
    />
  );
};
