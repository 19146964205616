import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { QUERY_GROUPED_PURCHASE_SCHEDULE_SUM } from '../../graphql/queries/pofs';

const groupedPurchaseScheduleSumQueryOptions = {
  query: QUERY_GROUPED_PURCHASE_SCHEDULE_SUM,
  mapData: (result) => result.groupedPurchaseScheduleSum,
  skipQuery: skipIfNull(null)
};

function getGroupedPurchaseScheduleSumFilter(pofData, regionId, group_by) {
  return pofData && pofData.expenses_direction_id
    ? {
        filter: {
          expenses_direction_ids: pofData.expenses_direction_id,
          region_ids: regionId,
          year: pofData.year
        },
        group_by: group_by
      }
    : null;
}

export function useGroupedPurchaseScheduleSum(pofData, regionId, group_by) {
  return useRetriableGraphql({
    queryOptions: groupedPurchaseScheduleSumQueryOptions,
    variables: getGroupedPurchaseScheduleSumFilter(pofData, regionId, group_by)
  });
}
