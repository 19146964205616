import gql from 'graphql-tag';
import { RP_IMPLEMENTATION_FORECAST } from '../queries/rpImplementationForecasts';

export const MUTATION_UPDATE_CREATE_OR_UPDATE_RPIMPLEMENTATION_FORECAST = gql`
  mutation createOrUpdateRpImplementationForecast(
    $input: RpImplementationForecastInput!
  ) {
    result: createOrUpdateRpImplementationForecast(input: $input) {
      ...RpImplementationForecast
    }
  }

  ${RP_IMPLEMENTATION_FORECAST}
`;
