import { useEffect } from 'react';

const useScrollToError = ({ errors, isSubmitting, isValid }) => {
  useEffect(() => {
    const keys = Object.keys(errors);
    if (keys.length > 0 && isSubmitting && !isValid) {
      const idSelector = `[id="${keys[0]}"]`; //для случая автокомплитов, у которых инпут по имени скрыт
      const nameSelector = `[name="${keys[0]}"]`;
      let errorElement = document.querySelector(idSelector);
      if (!errorElement) {
        errorElement = document.querySelector(nameSelector);
      }
      errorElement.focus();
      const elementRect = errorElement.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - window.innerHeight / 2;
      window.scrollTo(0, middle);
      //errorElement.scrollIntoView({}); не используется, т.к. в safari нет поддержки options
    }
  }, [isSubmitting, isValid, errors]);
};

export default useScrollToError;
