import React from 'react';
import { Prompt } from 'react-router-dom';
import { Form as FormikForm } from 'formik';
import { FormHeader } from '../../../../../common/Form/Form';
import { FORM_STATUS_SUBMITTED } from '../../../../../utils/data/form';
import { FormDocumentFields } from './FormDocumentFields';

export const FormDocumentEditForm = ({
  form,
  onBackClick,
  status,
  isSubmitting,
  editor,
  values
}) => {
  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <FormikForm noValidate>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting}
          editor={editor}
          isSubmitting={isSubmitting}
        />
        <FormDocumentFields form={form} values={values} />
      </FormikForm>
    </>
  );
};
