import { useStore } from '@proscom/prostore-react';
import axios from 'axios';
import { generatePath } from 'react-router-dom';
import { apiUrl } from '../../config';
import { STORE_AUTH } from '../../store/names';

/**
 * - для direction: {infralistIdid, directionId}
 * - для section: {infralistId, directionId, sectionId}
 * - для equipment: {infralistId, directionId, sectionId, equipmentId}
 * - для infralist - скачивание комментов всего инфралиста: { infralistId }
 */
const pathTemplates = {
  direction: '/files/infralist/:infralistId/direction/:directionId/comments',
  section:
    '/files/infralist/:infralistId/direction/:directionId/section/:sectionId/comments',
  equipment:
    '/files/infralist/:infralistId/direction/:directionId/section/:sectionId/equipment/:equipmentId/comments',
  infralist: '/files/infralist/:infralistId/comments'
};

/**
 * @description Хук для скачивания комментариев направления/раздела/оборудования
 * @param {'direction' | 'section' | 'equipment' | 'infralist'} commentScope - вид комментариев
 * @param pathParams - список id, необходимых для шаблона запроса, см {@link pathTemplates}
 * @returns {function(commentScope: String, pathParams: Object): Promise<void>}
 */

export const useExportInfralistComments = (commentScope, pathParams) => {
  const [_, authStore] = useStore(STORE_AUTH);

  const exportCommentaries = (commentType, fileType) => {
    return axios
      .get(
        `${apiUrl}${generatePath(pathTemplates[commentScope], pathParams)}`,
        {
          responseType: 'blob',
          params: {
            type: commentType,
            file_type: fileType
          },
          headers: {
            Authorization: `Bearer ${authStore.state.authData?.accessToken}`
          }
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${commentScope}-comments.${fileType}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  return exportCommentaries;
};
