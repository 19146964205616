import { flatten, uniq } from 'lodash-es';
import { INFRALIST_PERMISSIONS } from '../../store/infralist/useInfralistUserAccesses';
import { User } from '../../store/role/User';

export const useUserRoleByPermissions = () => {
  const getRole = (user) => {
    const userPermissions =
      user?.permissions?.map((item) => item.base_permission_id) ?? [];
    const rolePermissions =
      uniq(
        flatten(
          user?.roles?.map((role) =>
            role.permissions?.map((item) => item.base_permission_id)
          )
        )
      ) ?? [];
    const allRoles = [...userPermissions, ...rolePermissions];

    // Пользователю может быть назначена только 1 из ролей
    // роли расположены в порядке убывания
    let isFPO2 = false;
    let isFPO1 = false;
    let isRVPO2 = false;
    let isRVPO1 = false;
    let isRandomFpo = false;
    let isRandomRVPO = false;

    if (
      user?.role === User.Role.ADMIN ||
      allRoles.includes(INFRALIST_PERMISSIONS.APPROVE)
    ) {
      isFPO2 = true;
    } else if (allRoles.includes(INFRALIST_PERMISSIONS.DIRECTION_CHECK)) {
      isFPO1 = true;
    } else if (allRoles.includes(INFRALIST_PERMISSIONS.SIGN)) {
      isRVPO2 = true;
    } else if (allRoles.includes(INFRALIST_PERMISSIONS.POSITION_FILLING)) {
      isRVPO1 = true;
    } else if (allRoles.includes(INFRALIST_PERMISSIONS.VIEW_FEDERAL)) {
      isRandomFpo = true;
    } else if (allRoles.includes(INFRALIST_PERMISSIONS.VIEW_REGIONAL)) {
      isRandomRVPO = true;
    }
    const roles = [
      {
        name: 'rvpo1',
        type: 'rvpo',
        existed: isRVPO1
      },
      {
        name: 'rvpo2',
        type: 'rvpo',
        existed: isRVPO2 || isRandomRVPO
      },
      {
        name: 'fpo1',
        type: 'fpo',
        existed: isFPO1
      },
      {
        name: 'fpo2',
        type: 'fpo',
        existed: isFPO2 || isRandomFpo
      }
    ];

    return roles.find((item) => item.existed);
  };

  return { getRole };
};
