import React from 'react';
import InputGroup from '../../../../../common/Inputs/InputGroup';
import { NewTextInput } from '../../../../../common/InputsNew/NewTextInput';

export const ResponsiblePersonInfo = React.memo(function ResponsiblePersonInfo({
  event,
  canEditRegionalField
}) {
  return (
    event.responsibility_level_rf_subject && (
      <InputGroup
        title={`Ответственный за реализацию. Субъект Российской Федерации`}
      >
        <NewTextInput
          name="responsible_person.surname"
          label="Фамилия"
          fullWidth
          disabled={!canEditRegionalField}
        />
        <NewTextInput
          name="responsible_person.first_name"
          label="Имя"
          fullWidth
          disabled={!canEditRegionalField}
        />
        <NewTextInput
          name="responsible_person.patronymic"
          label="Отчество"
          fullWidth
          disabled={!canEditRegionalField}
        />
        <NewTextInput
          name="responsible_person.individual_data.occupation"
          label="Должность"
          fullWidth
          disabled={!canEditRegionalField}
        />
        <NewTextInput
          name="responsible_person.individual_data.department"
          label="Отдел/Департамент"
          fullWidth
          disabled={!canEditRegionalField}
        />
        <NewTextInput
          name="responsible_person.phones[0]"
          label="Мобильный телефон"
          inputProps={{ maxLength: 20 }}
          fullWidth
          disabled={!canEditRegionalField}
        />
        <NewTextInput
          name="responsible_person.emails[0]"
          label="Email"
          fullWidth
          disabled={!canEditRegionalField}
        />
      </InputGroup>
    )
  );
});
