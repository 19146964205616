import React from 'react';
import BreadCrumbs from '../../../common/BreadCrumbs/BreadCrumbs';

export function getProjectBreadCrumbs() {
  return [
    {
      label: 'Региональные проекты',
      to: '/projects'
    }
  ];
}

export const ProjectBreadCrumbs = () => {
  const breadCrumbsLinks = getProjectBreadCrumbs();

  return <BreadCrumbs links={breadCrumbsLinks} />;
};
