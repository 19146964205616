import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import {
  MUTATION_ACCEPT_COMMENTARIES,
  MUTATION_REJECT_COMMENTARIES
} from '../../../graphql/mutations/comment';
import { INFRALIST_QUERIES } from '../infralistQueries';

export const useMakeDecisionEquipmentCommentaries = () => {
  const client = useContextApolloClient();

  const acceptComment = ({ content, equipmentId, ids }) => {
    return client.mutate({
      mutation: MUTATION_ACCEPT_COMMENTARIES,
      variables: {
        content,
        comment_ids: ids,
        rvpo_equipment_id: equipmentId
      },
      refetchQueries: [INFRALIST_QUERIES.RVPO_EQUIPMENT_GENERAL_COMMENTARIES]
    });
  };

  const rejectComment = ({ content, equipmentId, ids }) => {
    return client.mutate({
      mutation: MUTATION_REJECT_COMMENTARIES,
      variables: {
        content,
        comment_ids: ids,
        rvpo_equipment_id: equipmentId
      },
      refetchQueries: [INFRALIST_QUERIES.RVPO_EQUIPMENT_GENERAL_COMMENTARIES]
    });
  };

  return { acceptComment, rejectComment };
};
