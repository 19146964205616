import React, { useCallback, useState } from 'react';
import Dialog from '../Dialog';

export function useGeneralConfirmation(onConfirm, dialogProps = {}) {
  const [data, setData] = useState(null);
  const open = useCallback((data) => {
    setData(data);
  }, []);
  const handleClose = useCallback(() => setData(null), []);

  const dialog = (
    <Dialog
      handleAccept={() => {
        handleClose();
        onConfirm(data);
      }}
      open={!!data}
      handleClose={handleClose}
      {...dialogProps}
    />
  );

  return [dialog, open];
}
