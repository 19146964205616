import gql from 'graphql-tag';
import { FRAGMENT_ROLE_PERMISSION } from './roles';

export const FRAGMENT_USER_FOR_LIST = gql`
  fragment userForList on UserType {
    id
    name
    email
    login
  }
`;

export const FRAGMENT_USER = gql`
  fragment user on UserType {
    id
    name
    email
    login
    role
    organization_id
    organization {
      id
      name_full
    }
    region_code_nalog
    region {
      id
      name
    }
    permissions {
      ...permission
    }
    roles {
      id
      code
      creator_user_id
      name
      permissions {
        ...permission
      }
    }
  }

  ${FRAGMENT_ROLE_PERMISSION}
`;

export const QUERY_GET_USERS = gql`
  query users($input: UsersPageInput!) {
    users(input: $input) {
      header {
        hasNext
        onePage
        page
        totalCount
      }
      list {
        id
        name
        email
        login
        role
        roles {
          id
          name
        }
      }
    }
  }
`;

export const QUERY_GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...user
    }
  }

  ${FRAGMENT_USER}
`;
