import React from 'react';
import Button from '@material-ui/core/Button';
import RetryIcon from '@material-ui/icons/Loop';
import { getGraphQLErrorInfo, getParsedErrorMessage } from '../../graphql/graphqlErrors';
import s from './ErrorMessage.module.scss';

const ErrorMessage = ({ error, load }) => {
  const parsedError = getGraphQLErrorInfo(error);
  const message = getParsedErrorMessage(parsedError);
  return (
    <div className={s.Container}>
      <p className={s.ErrorText}>{message}</p>
      {load && (
        <Button
          className={s.Button}
          color="primary"
          variant="contained"
          type="submit"
          onClick={load}
        >
          <RetryIcon className={s.Icon} />
          <span>Повторить запрос</span>
        </Button>
      )}
    </div>
  );
};

export default ErrorMessage;
