import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { QUERY_GET_SUPPLIER_DETERMINATIONS } from '../../graphql/queries/supplierDeterminations';

const supplierDeterminationsQueryOptions = {
  query: QUERY_GET_SUPPLIER_DETERMINATIONS,
  mapData: (result) => result.supplierDeterminations
};

export function useSupplierDeterminations() {
  return useGraphqlQuery({
    queryOptions: supplierDeterminationsQueryOptions
  });
}
