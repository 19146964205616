import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { FastField, FieldArray } from 'formik';
import { TableTextInput, TextInput } from '../../../common/Inputs/TextInput';
import InputGroup from '../../../common/Inputs/InputGroup';
import TableInput from '../../../common/Table/TableInput';
import { parseApiDate, removeTimezoneFromDate } from '../../../utils/data/date';
import { DATE_FORMAT_RUSSIAN } from '../../../utils/date';
import { TableDateInput } from '../../../common/Inputs/DateInput';
import { validateNumeric } from '../../../utils/number';
import SubTable from '../../../common/SubTable/SubTable';
import { tryNumberLike } from '../../../utils/helpers';
import { EMDASH } from '../../../utils/constants';
import { SubsidiaryMonitoringIndicatorEditable } from './SubsidiaryMonitoringIndicator/SubsidiaryMonitoringIndicatorEditable';
import { SubsidiaryMonitoringInfoItemEditable } from './SubsidiaryMonitoringInfo/SubsidiaryMonitoringInfoItemEditable';
import { SubsidiaryMonitoringOrganizationEditable } from './SubsidiaryMonitoringOrganization/SubsidiaryMonitoringOrganizationEditable';

const trainingsColumns = [
  {
    field: 'index',
    title: '№',
    width: '5%',
    editComponent: () => null,
    render: ({ tableData }) => {
      return tryNumberLike(tableData.id + 1, EMDASH);
    }
  },
  {
    field: 'name',
    title: 'Название программы повышения квалификации, количество часов',
    editComponent: TableTextInput,
    render: ({ name }) => {
      return name || EMDASH;
    }
  },
  {
    field: 'date',
    title: 'Дата выдачи удостоверения о повышении квалификации',
    type: 'date',
    editComponent: TableDateInput,
    render: ({ date }) => {
      const d = removeTimezoneFromDate(parseApiDate(date));
      return (d && format(d, DATE_FORMAT_RUSSIAN)) || EMDASH;
    }
  },
  // {
  //   field: 'hours',
  //   title: 'Количество часов',
  //   // initialEditValue: '0',
  //   editComponent: TableTextInput,
  //   render: ({ hours: value }) => {
  //     return tryNumberLike(value, EMDASH);
  //   },
  //   validate: ({ hours: value }) => {
  //     const error = validateNumeric(value);
  //     return (value && error) || true;
  //   }
  // },
  {
    field: 'organization_name',
    title: 'Название организации, реализующей программу',
    editComponent: TableTextInput,
    render: ({ organization_name }) => {
      return organization_name || EMDASH;
    }
  },
  {
    field: 'amount',
    title: 'Численность слушателей программы из ОО',
    // initialEditValue: '0',
    editComponent: TableTextInput,
    render: ({ amount: value }) => {
      return tryNumberLike(value, EMDASH);
    },
    validate: ({ amount: value }) => {
      const error = validateNumeric(value);
      return (value && error) || true;
    }
  },
  {
    field: 'note',
    title: 'Примечание',
    editComponent: TableTextInput,
    render: ({ note }) => {
      return note || '';
    }
  }
];

export const SubsidiaryMonitoringFormEditable = ({
  entity,
  values,
  touched,
  errors
}) => {
  const indicators = entity.indicators;
  const addFields = entity.additional_fields;
  const hasIndicators = !!indicators && indicators.length > 0;
  const hasAddFields = !!addFields && addFields.length > 0;

  const trainingsTouched = touched?.trainings;
  const trainingsErrors = errors?.trainings;
  const trainingsError =
    trainingsTouched?.length > 0 && trainingsErrors?.length > 0;

  const trainingsCols = useMemo(() => {
    return trainingsColumns.map((col) => ({
      ...col,
      required: trainingsError
    }));
  }, [trainingsError]);

  return (
    <>
      <SubsidiaryMonitoringOrganizationEditable
        entity={entity}
        touched={touched}
        errors={errors}
      />

      {hasAddFields && (
        <>
          <SubTable title={'Дополнительная информация:'} cols={1} />
          <FieldArray name={'additional_fields'}>
            {() =>
              values?.additional_fields?.map((field, iField) => (
                <SubsidiaryMonitoringInfoItemEditable
                  key={iField}
                  index={iField}
                  field={field}
                  touched={touched}
                  errors={errors}
                />
              ))
            }
          </FieldArray>
        </>
      )}

      {entity?.subsidiary_entity?.owner_roadmap
        ?.participating_in_staff_training && (
        <InputGroup>
          <FastField
            name="trainings"
            tableName="Данные о повышении квалификации сотрудников"
            columns={trainingsCols}
            actionsColumnIndex={8}
            component={TableInput}
            errorMessage={
              trainingsError && 'Все обязательные поля должны быть заполнены'
            }
          />
        </InputGroup>
      )}

      {hasIndicators && (
        <>
          <SubTable title={'Данные по индикаторам:'} cols={1} />
          <FieldArray name={'indicators'}>
            {() =>
              indicators?.map((ind, iInd) => (
                <SubsidiaryMonitoringIndicatorEditable
                  key={iInd}
                  index={iInd}
                  indicator={ind}
                  values={values}
                  touched={touched}
                  errors={errors}
                />
              ))
            }
          </FieldArray>
        </>
      )}

      <InputGroup>
        <FastField
          name="analytical_information"
          label="Аналитическая информация"
          fullWidth
          component={TextInput}
          multiline
          required
          errorMessage={
            touched.analytical_information && errors.analytical_information
          }
        />
      </InputGroup>
    </>
  );
};
