import React, { Fragment } from 'react';
import { Pof } from '../../../../store/pof/Pof';
import CurrencyDisplayField from '../../../../common/Inputs/CurrencyDisplayField';
import InputGroup from '../../../../common/Inputs/InputGroup';
import { monthNames, quarterNames } from '../../../../utils/data/date';
import { Signal } from '../../../../common/Inputs/Signals';
import { compareFinancial, formatCurrency } from '../../../../utils/number';
import { NewCurrencyInput } from '../../../../common/InputsNew/NewCurrencyInput';
import { NewTextInput } from '../../../../common/InputsNew/NewTextInput';
import s from './PofEditPage.module.scss';

export const PofQuartersFields = ({
  name,
  canEditValues,
  groupedPurchaseScheduleSum,
  monthValues
}) => {
  const quarters = Pof.getQuarters(
    Pof.monthInitialValues.map((value, iMonthValues) => {
      const purchaseScheduleMonth = groupedPurchaseScheduleSum?.find(
        (item) => item.month === iMonthValues + 1
      );

      const isDifference =
        compareFinancial(
          monthValues[iMonthValues]?.value,
          purchaseScheduleMonth?.sum
        ) !== 0;

      return (
        <Fragment key={iMonthValues}>
          <div className={s.rowStyle}>
            <NewCurrencyInput
              name={`${name}[${iMonthValues}].value`}
              label={monthNames[iMonthValues]}
              disabled={!canEditValues}
              className={s.inputStyle}
              decimalScale={2}
              fixedDecimalScale={true}
              variant={'standard'}
            />
            <p className={s.sum}>
              <span>{`Сумма платежей за ${monthNames[iMonthValues]}`}</span>
              <span>{formatCurrency(purchaseScheduleMonth?.sum || 0)}</span>
            </p>
            <NewTextInput
              name={`${name}[${iMonthValues}].comment`}
              multiline
              label="Комментарий"
              fullWidth
              disabled={!canEditValues}
              variant={'standard'}
            />
          </div>
          {isDifference && (
            <Signal warning>
              Имеется отклонение суммы платежей от значения ПОФ за{' '}
              {monthNames[iMonthValues]}
            </Signal>
          )}
        </Fragment>
      );
    })
  );

  const quartersTotal = Pof.getQuarters(monthValues).map((quarterValues) =>
    quarterValues.reduce((sum, target) => sum + (target.value || 0), 0)
  );

  return quarters.map((quarter, indexQuarter) => {
    return (
      <InputGroup title={quarterNames[indexQuarter]} key={indexQuarter}>
        <CurrencyDisplayField
          value={quartersTotal[indexQuarter]}
          label="Всего"
          unit="руб."
        />
        {quarter}
        <p className={s.info}>
          В случае если в предыдущие месяцы запрошенный объем финансирования не
          был использован, то следует этот объем перенести на последующие месяцы
          и скорректировать предыдущие месяцы в соответствии с фактическим
          использованием
        </p>
      </InputGroup>
    );
  });
};
