import gql from 'graphql-tag';
import { FRAGMENT_USER } from './users';

const FRAGMENT_EQUIPMENT_COMMENTARY_RESPONSE = gql`
  fragment InfralistEquipmentCommentary on InfralistRvpoEquipmentCommentaryType {
    id
    user_id
    content
    type
    accept_status
    description
    predefined_id
    code
    main_data_area
    created_at
    updated_at
    commentary_user {
      ...user
    }
  }
  ${FRAGMENT_USER}
`;

const FRAGMENT_DIRECTION_COMMENTARY_RESPONSE = gql`
  fragment InfralistDirectionCommentary on InfralistDirectionCommentaryType {
    id
    user_id
    content
    type
    accept_status
    description
    predefined_id
    code
    main_data_area
    created_at
    updated_at
    commentary_user {
      ...user
    }
  }
  ${FRAGMENT_USER}
`;

const FRAGMENT_SECTION_COMMENTARY_RESPONSE = gql`
  fragment InfralistSectionCommentary on InfralistSectionCommentaryType {
    id
    user_id
    content
    type
    accept_status
    description
    predefined_id
    code
    main_data_area
    created_at
    updated_at
    commentary_user {
      ...user
    }
  }
  ${FRAGMENT_USER}
`;

export const QUERY_GET_EQUIPMENT_RVPO_COMMENTS = gql`
  query infralistRvpoEquipmentRvpoCommentaries($rvpo_equipment_id: ID!) {
    infralistRvpoEquipmentRvpoCommentaries(
      rvpo_equipment_id: $rvpo_equipment_id
    ) {
      ...InfralistEquipmentCommentary
      rvpo_equipment_id
    }
  }
  ${FRAGMENT_EQUIPMENT_COMMENTARY_RESPONSE}
`;

export const QUERY_GET_EQUIPMENT_FPO_COMMENTS = gql`
  query infralistRvpoEquipmentFpoCommentaries($rvpo_equipment_id: ID!) {
    infralistRvpoEquipmentFpoCommentaries(
      rvpo_equipment_id: $rvpo_equipment_id
    ) {
      ...InfralistEquipmentCommentary
      rvpo_equipment_id
    }
  }
  ${FRAGMENT_EQUIPMENT_COMMENTARY_RESPONSE}
`;

export const QUERY_GET_EQUIPMENT_GENERAL_COMMENTS = gql`
  query infralistRvpoEquipmentGeneralCommentaries($rvpo_equipment_id: ID!) {
    infralistRvpoEquipmentGeneralCommentaries(
      rvpo_equipment_id: $rvpo_equipment_id
    ) {
      ...InfralistEquipmentCommentary
      rvpo_equipment_id
    }
  }
  ${FRAGMENT_EQUIPMENT_COMMENTARY_RESPONSE}
`;

export const QUERY_GET_DIRECTION_RVPO_COMMENTS = gql`
  query infralistDirectionRvpoCommentaries(
    $infralist_id: ID!
    $direction_id: ID!
  ) {
    infralistDirectionRvpoCommentaries(
      infralist_id: $infralist_id
      direction_id: $direction_id
    ) {
      ...InfralistDirectionCommentary
      direction_id
    }
  }
  ${FRAGMENT_DIRECTION_COMMENTARY_RESPONSE}
`;

export const QUERY_GET_DIRECTION_FPO_COMMENTS = gql`
  query infralistDirectionFpoCommentaries(
    $infralist_id: ID!
    $direction_id: ID!
  ) {
    infralistDirectionFpoCommentaries(
      infralist_id: $infralist_id
      direction_id: $direction_id
    ) {
      ...InfralistDirectionCommentary
      direction_id
    }
  }
  ${FRAGMENT_DIRECTION_COMMENTARY_RESPONSE}
`;

export const QUERY_GET_DIRECTION_GENERAL_COMMENTS = gql`
  query infralistDirectionGeneralCommentaries(
    $infralist_id: ID!
    $direction_id: ID!
  ) {
    infralistDirectionGeneralCommentaries(
      infralist_id: $infralist_id
      direction_id: $direction_id
    ) {
      ...InfralistDirectionCommentary
      direction_id
    }
  }
  ${FRAGMENT_DIRECTION_COMMENTARY_RESPONSE}
`;

export const QUERY_GET_SECTION_RVPO_COMMENTS = gql`
  query infralistSectionRvpoCommentaries($infralist_id: ID!, $section_id: ID!) {
    infralistSectionRvpoCommentaries(
      infralist_id: $infralist_id
      section_id: $section_id
    ) {
      ...InfralistSectionCommentary
      section_id
    }
  }
  ${FRAGMENT_SECTION_COMMENTARY_RESPONSE}
`;

export const QUERY_GET_SECTION_FPO_COMMENTS = gql`
  query infralistSectionFpoCommentaries($infralist_id: ID!, $section_id: ID!) {
    infralistSectionFpoCommentaries(
      infralist_id: $infralist_id
      section_id: $section_id
    ) {
      ...InfralistSectionCommentary
      section_id
    }
  }
  ${FRAGMENT_SECTION_COMMENTARY_RESPONSE}
`;

export const QUERY_GET_SECTION_GENERAL_COMMENTS = gql`
  query infralistSectionGeneralCommentaries(
    $infralist_id: ID!
    $section_id: ID!
  ) {
    infralistSectionGeneralCommentaries(
      infralist_id: $infralist_id
      section_id: $section_id
    ) {
      ...InfralistSectionCommentary
      section_id
    }
  }
  ${FRAGMENT_SECTION_COMMENTARY_RESPONSE}
`;
