import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { REGIONAL_ROADMAP_EVENTS } from '../../graphql/queries/regionalRoadmapEvents';

const regionalRoadmapEventsQueryOptions = {
  query: REGIONAL_ROADMAP_EVENTS,
  mapData: (result) => result.regionalRoadmapEvents,
  skipQuery: skipIfNull(null)
};

export function useRegionalRoadmapEvents(filter, paginationParams, sorting) {
  return useRetriableGraphqlVars({
    queryOptions: regionalRoadmapEventsQueryOptions,
    variableCreator: [
      () =>
        filter
          ? {
              input: {
                filter: filter,
                pagination: paginationParams,
                sorting: sorting
              }
            }
          : null,
      [filter, paginationParams]
    ]
  });
}
