import gql from 'graphql-tag';

export const REGIONAL_ROADMAP_EVENTS = gql`
  query regionalRoadmapEvents($input: RegionalRoadmapEventsPageInput!) {
    regionalRoadmapEvents(input: $input) {
      header {
        hasNext
        onePage
        page
        totalCount
      }
      list {
        id
        code
        name_full
        region {
          id
          name
        }
        roadmap_event {
          id
          code
          name_full
          plan_term
          event_type
          roadmap {
            id
            name_full
          }
        }
        status
        status_changed_at
        permissions {
          approve
          edit
        }
      }
    }
  }
`;
