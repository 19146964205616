import React from 'react';
import shallowEqual from 'shallowequal';
import Button from '@material-ui/core/Button';
import { Dropdown } from '../../../../common/Dropdown/Dropdown';
import { DropdownTitle } from '../../../../common/Dropdown/DropdownTitle';
import { shallowEqualArrays } from '../../../../utils/shallowEqualArrays';
import { useArchiveDownloadingFile } from '../../downloadArchiveFile';
import { getName } from '../../../../utils/data/label';
import { enableArchiveDownload } from '../../../../config';
import { SubsidiaryEntityTitle } from './SubsidiaryEntityTitle';
import { SubsidiaryEntityPhotosCategories } from './SubsidiaryEntityPhotosCategories';
import s from './EventPhotos.module.scss';

export const SubsidiaryEntityPhotos = React.memo(
  function SubsidiaryEntityPhotos({
    entity,
    seTypes,
    regionEventId,
    documents,
    otherDocuments,
    ...restProps
  }) {
    const { editProps } = restProps;
    const entityTypeId = entity?.se_type_id || null;
    const entityType =
      seTypes?.find((type) => type.id === entityTypeId) || null;

    const currentEventDocuments = documents || [];
    const otherEventDocuments = otherDocuments || [];

    const categoryProps = {
      entity: entity,
      entityId: entity?.id || null,
      entityType: entityType,
      regionEventId: regionEventId
    };

    const { handleArchiveDownload, isDownloading } = useArchiveDownloadingFile(
      regionEventId,
      getName(entity),
      entity
        ? {
            subsidiaryEntityIds: [entity.id]
          }
        : {
            subsidiaryEntityIsNull: true
          }
    );

    return (
      <Dropdown
        className={s.Entity}
        top={(props) => (
          <DropdownTitle {...props}>
            <SubsidiaryEntityTitle entity={entity} entityType={entityType} />
          </DropdownTitle>
        )}
      >
        {({ opened, setOpened }) =>
          opened && (
            <>
              {otherEventDocuments.length > 0 ? (
                <div className={s.Entity__otherDocuments}>
                  <div className={s.Entity__information}>
                    Фотографии других мероприятий
                  </div>
                  <SubsidiaryEntityPhotosCategories
                    {...categoryProps}
                    documents={otherEventDocuments}
                    disabled
                    {...restProps}
                  />
                </div>
              ) : null}
              <div className={s.Entity__documents}>
                <div className={s.Entity__information}>
                  Фотографии мероприятия
                </div>
                <SubsidiaryEntityPhotosCategories
                  {...categoryProps}
                  documents={currentEventDocuments}
                  {...restProps}
                />
              </div>
              {enableArchiveDownload &&
                (currentEventDocuments?.length > 0 ||
                  otherEventDocuments?.length > 0) &&
                !editProps && (
                  <div className={s.downloadButton__entity}>
                    <Button
                      variant="outlined"
                      component="span"
                      className={s.downloadLinkButton}
                      onClick={handleArchiveDownload}
                      disabled={isDownloading}
                    >
                      {isDownloading ? 'Скачивание' : 'Скачать архивом'}
                    </Button>
                  </div>
                )}
            </>
          )
        }
      </Dropdown>
    );
  },
  (prevProps, nextProps) => {
    return shallowEqual(prevProps, nextProps, (prev, next, key) => {
      // Сравниваем некоторые пропы на втором уровне глубины для отсечения большего количества поддеревьев
      if (key === 'editProps') {
        return shallowEqual(prev, next);
      } else if (key === 'documents' || key === 'otherDocuments') {
        return shallowEqualArrays(prev, next);
      } else {
        return undefined;
      }
    });
  }
);
