import gql from 'graphql-tag';

export const GET_QUERY_MBT_FINANCES_GROUPED = gql`
  query mbtFinancesGrouped(
    $filter: MbtFinancesQueryFilter!
    $groupBy: [MbtFinancesGroupField!]!
  ) {
    mbtFinancesGrouped(filter: $filter, groupBy: $groupBy) {
      plan_yearly_clear
      expenses_direction_id
    }
  }
`;
