import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { useFormDocument } from '../../../../../store/form/formDocumentQueries';
import { STORE_FORM } from '../../../../../store/names';
import { removeLastPathItem } from '../../../../../utils/url';
import BreadCrumbs from '../../../../../common/BreadCrumbs/BreadCrumbs';
import { queryLoaderWithExistenceCheck } from '../../../../../common/QueryLoader';
import { FormDocument } from '../../../../../store/form/FormDocument';
import { handleFormSubmission } from '../../../../../utils/data/form';
import { getFormDocumentBreadCrumbs } from '../getFormDocumentBreadCrumbs';
import { useOtherEditor } from '../../../../../common/Form/useOtherEditor';
import { HeadTitle } from '../../../../../common/HeadTitle/HeadTitle';
import { getName } from '../../../../../utils/data/label';
import { FormDocumentEditForm } from './FormDocumentEditForm';

export default function FormDocumentEditPage({ match, history }) {
  const backLink = removeLastPathItem(match.url);
  const { formId, documentId } = match.params;

  const formStore = useContextStore(STORE_FORM);
  const editor = useOtherEditor('formDocument', formId);

  const documentQuery = useFormDocument(documentId);
  const document = documentQuery.state.data;
  const form = document?.form;

  const breadCrumbs = useMemo(
    () =>
      getFormDocumentBreadCrumbs({
        formId,
        formName: form?.name
      }),
    [formId, form]
  );

  const initialValues = useMemo(() => FormDocument.toForm(document), [
    document
  ]);

  return (
    <>
      <HeadTitle
        entity={FormDocument.getName(document, form)}
        type={getName(form)}
        pageType={HeadTitle.PageType.edit}
      />
      <BreadCrumbs links={breadCrumbs} />
      <h2>Редактирование документа формы "{form?.name}"</h2>
      {queryLoaderWithExistenceCheck(documentQuery) ||
        (form && (
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              const form = FormDocument.fromForm(values);
              handleFormSubmission(
                formStore.updateFormDocument(form),
                actions,
                (result) =>
                  history.goBack(FormDocument.getUrl(result.data.result))
              );
            }}
            render={(formikProps) => (
              <FormDocumentEditForm
                {...formikProps}
                form={form}
                onBackClick={() => history.goBack(backLink)}
                editor={editor}
              />
            )}
          />
        ))}
    </>
  );
}
