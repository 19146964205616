import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select, { components } from 'react-select';
import CloseIcon from '@material-ui/icons/Close';
import { reactSelectHelpers } from '../../utils/form/reactSelectHelpers';
import s from './MultiSelectInline.module.scss';

export const MultiSelectInline = ({
  value,
  onChange,
  options,
  name,
  searchable,
  error,
  placeholder = 'Выбрать из списка...',
  disabled,
  className,
  isLoading
}) => {
  const selectRef = useRef();

  return (
    <div className={classNames(s.root, className)}>
      {value?.length > 0 && (
        <span className={s.placeholder}>{placeholder}</span>
      )}
      <Select
        // для разработки: можно держать меню всегда открытым
        // menuIsOpen={true}
        ref={selectRef}
        value={value}
        options={options}
        isMulti
        name={name}
        onChange={onChange}
        isClearable={false}
        isSearchable={searchable}
        menuPortalTarget={document.body}
        placeholder={placeholder}
        noOptionsMessage={() => 'Нет вариантов'}
        isLoading={isLoading}
        closeMenuOnScroll={true}
        // filterOption исправляет ошибку с поиском по значению
        // https://stackoverflow.com/questions/51938587/react-select-type-to-search-access-to-filteroption-resulting-array
        filterOption={reactSelectHelpers.filterOption}
        // исправление ошибки при выборе первого значения по клику на пробел
        // https://stackoverflow.com/questions/53396697/react-select-v2-on-space-press-selects-first-value
        onKeyDown={(e) => {
          if (e.keyCode === 32 && !selectRef.current.state.inputValue) {
            e.preventDefault();
          }
        }}
        styles={{
          placeholder: (provided) => ({
            ...provided,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '100%',
            paddingRight: 4
          }),
          container: (provided, state) => ({
            ...provided,
            border: `1px solid #${!!error ? 'F44336' : 'D1D0D8'}`,
            borderRadius: state.selectProps.menuIsOpen ? '6px 6px 0 0' : 6
          }),
          control: (provided, state) => ({
            ...provided,
            minHeight: 34,
            outline: 'none',
            fontSize: 12,
            border: 'none',
            borderRadius: state.selectProps.menuIsOpen ? '6px 6px 0 0' : 6,
            boxShadow: 'none',
            '&:hover': {
              outline: 'none'
            },
            ...(state.selectProps.menuIsOpen
              ? {
                  borderBottom: '1px solid #F5F6F7'
                }
              : {})
          }),
          indicatorSeparator: () => ({
            display: 'none'
          }),
          menu: (provided) => ({
            ...provided,
            margin: 0,
            borderRadius: '0 0 6px 6px'
          }),
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 10
          }),
          option: (provided, state) => ({
            ...provided,
            minHeight: 36,
            padding: '5px 16px',
            fontSize: 12,
            lineHeight: '16px',
            display: 'flex',
            alignItems: 'center',
            background: state.isFocused ? '#F5F6F7' : '#fff',
            color: '#3F4752',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }),
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: 'transparent'
          }),
          multiValueRemove: (provided) => ({
            ...provided,
            '&:hover': {
              color: '#444',
              backgroundColor: 'transparent'
            }
          })
        }}
        components={{
          MultiValueLabel: ({ children, ...props }) => (
            <components.MultiValueLabel {...props}>
              <span style={{ color: props?.data?.color }} className={s.option}>
                {props?.data?.label}
              </span>
            </components.MultiValueLabel>
          ),
          MultiValueRemove: ({ children, ...props }) => (
            <components.MultiValueRemove {...props}>
              <CloseIcon className={s.remove} />
            </components.MultiValueRemove>
          )
        }}
        isDisabled={disabled}
      />
    </div>
  );
};

MultiSelectInline.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array
};
