import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useState } from 'react';
import { MUTATION_COMPLETE_APPROVEMENT } from '../../../graphql/mutations/equipment';
import { INFRALIST_QUERIES } from '../infralistQueries';

export const useCompleteApprovement = () => {
  const client = useContextApolloClient();
  const [
    isLoadingCompleteApprovement,
    setIsLoadingCompleteApprovement
  ] = useState(false);
  const [
    isLoadingCompleteApprovementWithoutEquipment,
    setIsLoadingCompleteApprovementWithoutEquipment
  ] = useState(false);

  const completeApprovement = ({ infralistId, sectionId, isApprove }) => {
    if (isApprove || typeof isApprove === 'undefined') {
      setIsLoadingCompleteApprovement(true);
    } else setIsLoadingCompleteApprovementWithoutEquipment(true);
    return client
      .mutate({
        mutation: MUTATION_COMPLETE_APPROVEMENT,
        variables: {
          infralist_id: infralistId,
          section_id: sectionId,
          is_approve: isApprove
        },
        update: (store) => {
          store.reset();
        },
        refetchQueries: [INFRALIST_QUERIES.SECTION]
      })
      .finally(() => {
        if (isApprove || typeof isApprove === 'undefined') {
          setIsLoadingCompleteApprovement(false);
        } else setIsLoadingCompleteApprovementWithoutEquipment(false);
      });
  };

  return {
    isLoadingCompleteApprovement,
    isLoadingCompleteApprovementWithoutEquipment,
    completeApprovement
  };
};
