import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_CLASSES_SCHEDULE } from '../../graphql/queries/classesSchedules';

const classesScheduleQueryOptions = {
  query: QUERY_CLASSES_SCHEDULE,
  mapData: (result) => result.classesSchedule,
  skipQuery: skipIfNull(null)
};

export function useClassesSchedule(id) {
  return useRetriableGraphqlVars({
    queryOptions: classesScheduleQueryOptions,
    variableCreator: [
      () =>
        id
          ? {
              id
            }
          : null,
      [id]
    ]
  });
}
