import React, { useState } from 'react';
import { INFRALIST_EXPORT_TYPES } from '../../routes/infralist/useExportInfralist';
import { Popup } from '../Popup/Popup';
import { ProgressButton } from '../ProgressButton';
import s from './ExportModal.module.scss';

export const ExportModal = ({ open, onClose, onExport, isDoubleDocx }) => {
  const [loading, setLoading] = useState({
    docx: false,
    xlsx: false,
    pdf: false,
    docx_without_price: false
  });

  const updateState = (type, value) => {
    setLoading((prev) => ({
      ...prev,
      [type]: value
    }));
  };

  const handleExport = (type, ext) => {
    updateState(type, true);
    if (onExport) {
      onExport(type, ext).finally(() => updateState(type, false));
    }
  };

  return (
    <Popup
      open={open}
      onClose={onClose}
      title={'Выберите формат для скачивания:'}
    >
      {isDoubleDocx ? (
        <div className={s.TwoColumnsButtons}>
          <ProgressButton
            variant={'contained'}
            color={'primary'}
            isLoading={loading.xlsx}
            onClick={() => handleExport(INFRALIST_EXPORT_TYPES.XLSX)}
          >
            XLSX
          </ProgressButton>
          <ProgressButton
            variant={'contained'}
            color={'primary'}
            isLoading={loading.docx}
            onClick={() => handleExport(INFRALIST_EXPORT_TYPES.DOCX)}
          >
            DOCX (с ценой)
          </ProgressButton>
          <ProgressButton
            variant={'contained'}
            color={'primary'}
            isLoading={loading.pdf}
            onClick={() => handleExport(INFRALIST_EXPORT_TYPES.PDF)}
          >
            PDF
          </ProgressButton>
          <ProgressButton
            variant={'contained'}
            color={'primary'}
            isLoading={loading.docx_without_price}
            onClick={() =>
              handleExport(INFRALIST_EXPORT_TYPES.DOCX_WITHOUT_PRICE, 'docx')
            }
          >
            DOCX (без цены)
          </ProgressButton>
        </div>
      ) : (
        <div className={s.Buttons}>
          <ProgressButton
            variant={'contained'}
            color={'primary'}
            isLoading={loading.xlsx}
            onClick={() => handleExport(INFRALIST_EXPORT_TYPES.XLSX)}
          >
            XLSX
          </ProgressButton>
          <ProgressButton
            variant={'contained'}
            color={'primary'}
            isLoading={loading.docx}
            onClick={() => handleExport(INFRALIST_EXPORT_TYPES.DOCX)}
          >
            DOCX
          </ProgressButton>
          <ProgressButton
            variant={'contained'}
            color={'primary'}
            isLoading={loading.pdf}
            onClick={() => handleExport(INFRALIST_EXPORT_TYPES.PDF)}
          >
            PDF
          </ProgressButton>
        </div>
      )}
    </Popup>
  );
};
