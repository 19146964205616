export const infralistRoutes = {
  INFRALIST: '/infralist/:infralistId',
  DIRECTION: '/infralist/:infralistId/directions/:directionId',
  SECTION:
    '/infralist/:infralistId/directions/:directionId/sections/:sectionId',
  CREATE_EQUIPMENT:
    '/infralist/:infralistId/directions/:directionId/sections/:sectionId/create',

  // журнал изменений
  CHANGE_LOGS: '/infralist-change-logs/:infralistId',

  // версии
  INFRALIST_VERSION: '/infralist/:infralistId/version/:versionId',
  DIRECTION_VERSION:
    '/infralist/:infralistId/version/:versionId/directions/:directionId',
  SECTION_VERSION:
    '/infralist/:infralistId/version/:versionId/directions/:directionId/sections/:sectionId',

  // комментарии
  EQUIPMENT_COMMENT:
    '/infralist-comments/:infralistId/directions/:directionId/sections/:sectionId/equipment/:equipmentId/:type',
  DIRECTION_COMMENT:
    '/infralist-comments/:infralistId/direction/:directionId/:type',
  SECTION_COMMENT:
    '/infralist-comments/:infralistId/directions/:directionId/section/:sectionId/:type'
};
