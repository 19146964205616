import React, { useCallback, useMemo, useState } from 'react';
import { useContextStore } from '@proscom/prostore-react';
import { TableCell, TableRow } from '@material-ui/core';
import { STORE_FORM } from '../../../../store/names';
import BreadCrumbs from '../../../../common/BreadCrumbs/BreadCrumbs';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import PageTitle from '../../../../common/PageTitle/PageTitle';
import { PageActionsBar } from '../../../../common/PageActionsBar/PageActionsBar';
import { EditButton } from '../../../../common/Form/EditButton/EditButton';
import { DeleteButtonWithConfirmation } from '../../../../common/Form/DeleteButtonWithConfirmation';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../graphql/graphqlErrors';
import { useFormDocument } from '../../../../store/form/formDocumentQueries';
import { FormDocument } from '../../../../store/form/FormDocument';
import SubTable from '../../../../common/SubTable/SubTable';
import { User } from '../../../../store/role/User';
import { DateCell } from '../../../../common/TableCells';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { getName } from '../../../../utils/data/label';
import { FormDocumentDisplayField } from './FormDocumentDisplayField';
import { getFormDocumentBreadCrumbs } from './getFormDocumentBreadCrumbs';

function FormDocumentFields({ document, form }) {
  const { data } = document;
  return (
    <SubTable>
      {form.fields.map((field, iField) => {
        return (
          <TableRow key={iField}>
            <TableCell>{field.name}</TableCell>
            <TableCell>
              <FormDocumentDisplayField value={data[field.key]} field={field} />
            </TableCell>
          </TableRow>
        );
      })}
    </SubTable>
  );
}

export default function FormDocumentPage({ match, history }) {
  const [redirectAfterDeletionUrl] = match.url.split('/documents');
  const itemStore = useContextStore(STORE_FORM);
  const { formId, documentId } = match.params;

  const documentQuery = useFormDocument(documentId);
  const document = documentQuery.state.data;
  const form = document?.form;

  const [isDeleting, setDeleting] = useState(false);

  const deleteItem = useCallback(() => {
    setDeleting(true);
    return itemStore
      .deleteFormDocument(documentId)
      .then((result) => {
        setTimeout(() => {
          history.push(redirectAfterDeletionUrl);
        }, 0);
      })
      .catch((error) => {
        showGraphqlErrorToast(error, mutationErrors.deleteObject);
      })
      .finally(() => setDeleting(false));
  }, [documentId, history, itemStore, redirectAfterDeletionUrl]);

  const breadCrumbs = useMemo(
    () =>
      getFormDocumentBreadCrumbs({
        formId,
        formName: form?.name
      }),
    [formId, form]
  );

  return (
    <>
      <HeadTitle
        entity={FormDocument.getName(document, form)}
        type={getName(form)}
      />
      <BreadCrumbs links={breadCrumbs} />
      {queryLoaderWithExistenceCheck(documentQuery) ||
        (form && (
          <>
            <PageTitle
              title={
                FormDocument.getName(document, form) ||
                (form.fields.length > 0 && (
                  <FormDocumentDisplayField
                    value={document.data?.[form.fields[0]?.key]}
                    field={form.fields[0]}
                  />
                ))
              }
              withBackBtn
            />
            <PageActionsBar
              editButton={<EditButton editLink={`${match.url}/edit`} />}
              deleteButton={
                <DeleteButtonWithConfirmation
                  onDelete={deleteItem}
                  isDeleting={isDeleting}
                  entityName={'документ'}
                />
              }
            />
            {form && <FormDocumentFields document={document} form={form} />}
            <SubTable>
              <TableRow>
                <TableCell>Автор</TableCell>
                <TableCell>{User.getUserName(document.author)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Версия</TableCell>
                <TableCell>{document.version}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Дата создания</TableCell>
                <DateCell value={document.created_at} />
              </TableRow>
              <TableRow>
                <TableCell>Дата обновления</TableCell>
                <DateCell value={document.updated_at} />
              </TableRow>
            </SubTable>
          </>
        ))}
    </>
  );
}
