import React, { useMemo, useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { stringify } from 'query-string';
import { useContextStore } from '@proscom/prostore-react';
import { CircularProgress } from '@material-ui/core';
import Menu from '../../../../common/Menu/Menu';
import Table from '../../../../common/Table/Table';
import { useUrlQuery } from '../../../../common/useUrlQuery';
import { useSubsidiaryEntityRoadmapIndicators } from '../../../../store/subsidiaryEntity/useSubsidiaryEntityRoadmapIndicators';
import { useRegionalProject } from '../../../../store/regionalProject/useRegionalProject';
import { Indicator as IndicatorsData } from '../../../../store/indicator/Indicator';
import { formatNumber } from '../../../../utils/formatNumber';
import { getCurrentMonth, getCurrentYear } from '../../../../utils/data/date';
import { useCurrentUser } from '../../../../store/useCurrentUser';
import { INDICATOR_STORE } from '../../../../store/names';
import { useDeleteRow } from '../../../../common/Table/useDeleteRow';
import { ActionButton } from '../../../../common/Form/ActionButton';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../graphql/graphqlErrors';
import { Signal } from '../../../../common/Inputs/Signals';
import { useSorting } from '../../../../common/Table/Sorting/useSorting';
import { reformatDate } from '../../../../utils/date';
import { Sorting } from '../../../../common/Table/Sorting/Sorting';
import { useIndicatorsFilter } from './useIndicatorsFilter';
import { useIndicatorsColumns } from './useIndicatorsColumn';
import s from '../Indicators.module.scss';

export const Indicators = ({ match, location, history }) => {
  const indicatorStore = useContextStore(INDICATOR_STORE);
  const [query, changeQuery] = useUrlQuery(location, history);
  const { projectId, roadmapId } = match.params;
  const defaultYear = getCurrentYear();
  const defaultMonth = getCurrentMonth();

  const projectQuery = useRegionalProject(projectId);
  const project = projectQuery.state.data;
  const regionId = project?.region?.id;

  const [filter, filterComponent] = useIndicatorsFilter(
    query,
    changeQuery,
    projectId,
    roadmapId,
    defaultYear,
    defaultMonth,
    regionId
  );

  const [sortableColumns, sorting] = useSorting([
    {
      field: Sorting.SortFields.status_changed_at,
      key: (row) => reformatDate(row.status_changed_at),
      isThin: true
    }
  ]);

  const indicatorsQuery = useSubsidiaryEntityRoadmapIndicators(filter, sorting);
  const indicators = useMemo(() => {
    if (!indicatorsQuery.state.data?.data) return;
    return indicatorsQuery.state.data?.data.map(
      (
        {
          subsidiary_entity,
          value_from_tender_documentation,
          value_fact,
          ...indicator
        },
        iIndicator
      ) => ({
        ...indicator,
        minValue: IndicatorsData.getMinimumText(
          indicator.owner_roadmap_indicator
        ),
        value_from_tender_documentation: formatNumber(
          value_from_tender_documentation
        ),
        value_fact: formatNumber(value_fact),
        order: iIndicator + 1,
        statusName: IndicatorsData.getStatus(indicator.status),
        ssNameObject: {
          name_full: subsidiary_entity?.name_full,
          base_organization: subsidiary_entity?.base_organization
        }
      })
    );
  }, [indicatorsQuery.state.data]);

  const user = useCurrentUser();
  const canCreateIndicator = IndicatorsData.canCreateIndicator(user);

  const menuComponent = useMemo(
    () =>
      function MenuComponent({ row }) {
        const [isDeleting, dialog, openDialog] = useDeleteRow(
          indicatorStore.deleteIndicator,
          'индикатор'
        );

        const canEditIndicator = IndicatorsData.canEditIndicator(user, row);
        const canDeleteIndicator = IndicatorsData.canDeleteIndicator(user, row);

        const actions = [];

        if (canEditIndicator) {
          actions.push({
            title: 'Редактировать',
            onClick: () =>
              history.push(`${match.url}/indicators/${row.id}/edit`)
          });
        }
        if (canDeleteIndicator) {
          actions.push({
            title: 'Удалить',
            onClick: () => openDialog(row.id)
          });
        }

        if (actions.length === 0) {
          return false;
        }

        return (
          <>
            {isDeleting ? (
              <CircularProgress size={18} />
            ) : (
              <Menu
                iconColor="default"
                icon={MoreHorizIcon}
                options={actions}
              />
            )}
            {dialog}
          </>
        );
      },
    [history, match, indicatorStore, user]
  );

  const rowRedirectRoute = projectId
    ? `/projects/${projectId}/roadmaps/${roadmapId}/indicators/`
    : `/roadmaps/${roadmapId}/indicators/`;

  const [selected, setSelected] = useState([]);
  const indicatorsColumn = useIndicatorsColumns(
    indicators,
    selected,
    setSelected,
    sortableColumns
  );
  const canApprove = IndicatorsData.canApproveMultipleIndicators(
    user,
    selected
  );

  return (
    <>
      <Signal warning>
        Необходимо заполнить данные по индикаторам до 03 числа каждого месяца
      </Signal>
      <Table
        query={indicatorsQuery}
        addButtonText="Создать"
        addFieldRoute={
          rowRedirectRoute +
          'create?' +
          stringify({
            year: filter.year || defaultYear,
            indicatorId: filter.roadmap_indicator_id,
            subsidiaryEntityId: filter.subsidiary_entity_id,
            month: filter.month
          })
        }
        hasAddField={canCreateIndicator}
        actions={
          <>
            <ActionButton
              title="Скопировать данные прошлого месяца"
              onClick={() => {
                indicatorStore
                  .copyIndicators({
                    month: +filter.month,
                    region_id: regionId,
                    roadmap_id: roadmapId,
                    year: +filter.year
                  })
                  .catch((error) => {
                    showGraphqlErrorToast(error, mutationErrors.updateObject);
                  });
              }}
              disabled={!(filter.month && filter.year && roadmapId && regionId)}
              style={{ marginTop: 16 }}
            />
            <ActionButton
              onClick={() => {
                indicatorStore
                  .approveMultipleIndicators(
                    selected
                      .filter(
                        (indicator) =>
                          indicator.status ===
                          IndicatorsData.Status.ON_CONFIRMATION
                      )
                      .map((indicator) => indicator.id)
                  )
                  .catch((error) => {
                    showGraphqlErrorToast(error, mutationErrors.updateObject);
                  });
                setSelected([]);
              }}
              title="Утвердить выделенные"
              className={s.approveButton}
              disabled={!canApprove}
            />
          </>
        }
        columns={indicatorsColumn}
        data={indicators}
        rowRedirectRoute={rowRedirectRoute}
        filter={filterComponent}
        menuComponent={menuComponent}
      />
    </>
  );
};
