import React, { useMemo } from 'react';
import {
  EVENT_TYPE_PHOTOS,
  EVENT_TYPE_SIMPLE_SS
} from '../../../../../store/roadmap/roadmapEvents';
import { RegionalEvent } from '../../../../../store/roadmap/RegionalEvent';
import { useSubsidiaryEntitiesForFilter } from '../../../../../store/subsidiaryEntity/useSubsidiaryEntities';
import { useMemoSuggestions } from '../../../../../utils/suggestions';
import { EventDocuments } from '../../EventDocuments';
import { EventPhotosField } from '../../photos/EventPhotosField';
import { DocumentsEditField } from '../../DocumentsEditField';
import { SubsidiaryEntity } from '../../../../../store/subsidiaryEntity/SubsidiaryEntity';

export const DocumentsInfo = React.memo(function DocumentsInfo({
  event,
  regionalEvent,
  region,
  roadmap,
  canEditStatus,
  canUploadDocuments,
  staffError,
  setHaveReason,
  haveReason,
  status
}) {
  const isPhotos = event.event_type === EVENT_TYPE_PHOTOS;

  const { showSlots, federalSlots, regionalSlots } = useMemo(() => {
    return RegionalEvent.getSlots(event);
  }, [event]);

  const federalDocuments = event.roadmap_event_documents;

  const entitiesQuery = useSubsidiaryEntitiesForFilter({
    region_ids: region.id,
    owner_roadmap_ids: roadmap.id,
    year: event.year + ''
  });
  const entities = entitiesQuery.state.data?.list;
  const entitiesSuggestions = useMemoSuggestions(
    entities,
    SubsidiaryEntity.getCompleteName
  );

  return (
    <>
      {((showSlots && federalSlots?.length > 0) ||
        federalDocuments?.length > 0) && (
        <EventDocuments
          slots={federalSlots}
          documents={federalDocuments}
          showSlots={showSlots}
          showDocumentStatus={false}
          title="Документы ведомственного проектного офиса"
          editVariant
        />
      )}
      {isPhotos ? (
        <EventPhotosField
          name="roadmap_event_documents"
          canEditStatus={canEditStatus}
          canUploadDocuments={canUploadDocuments}
          eventStatus={status}
          // entities={entities}
          event={event}
          regionalEvent={regionalEvent}
        />
      ) : (
        <DocumentsEditField
          name="roadmap_event_documents"
          slots={regionalSlots}
          showSlots={showSlots}
          canUploadArbitrary={event.upload_arbitrary_documents || true}
          canEditStatus={canEditStatus}
          canUploadDocuments={canUploadDocuments}
          eventStatus={status}
          entities={{
            entitiesSuggestions: entitiesSuggestions,
            entitiesStatus: entitiesQuery.check.spinner,
            canEditEntities: event.event_type === EVENT_TYPE_SIMPLE_SS
          }}
          title="Документы"
          staffError={staffError}
          setHaveReason={setHaveReason}
          haveReason={haveReason}
        />
      )}
    </>
  );
});
