import { Formik } from 'formik';
import React, { useMemo } from 'react';
import { useContextStore } from '@proscom/prostore-react';
import { removeLastPathItem } from '../../../../utils/url';
import { useSubsidiaryEntity } from '../../../../store/subsidiaryEntity/useSubsidiaryEntity';
import { SubsidiaryEntity } from '../../../../store/subsidiaryEntity/SubsidiaryEntity';
import { SUBSIDIARY_ENTITY_STORE } from '../../../../store/names';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../graphql/graphqlErrors';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import { SubsidiaryEntityEditValidationSchema } from '../../../../utils/data/validation';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { SubsidiaryEntityBreadCrumbs } from '../SubsidiaryEntityBreadCrumbs';
import { useOtherEditor } from '../../../../common/Form/useOtherEditor';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { getName } from '../../../../utils/data/label';
import { yearsSuggestions } from '../../../../utils/data/date';
import { SubsidiaryEntityEditForm } from './SubsidiaryEntityEditForm';

export default function SubsidiaryEntityEditPage({ history, match }) {
  const backLink = removeLastPathItem(match.url);
  const { subsidiaryEntityId } = match.params;

  const editor = useOtherEditor('subsidiaryEntity', subsidiaryEntityId);

  const subsidiaryEntityStore = useContextStore(SUBSIDIARY_ENTITY_STORE);

  const subsidiaryEntityQuery = useSubsidiaryEntity(subsidiaryEntityId, true);
  const subsidiaryEntity = useMemo(() => {
    if (!subsidiaryEntityQuery.state.data) return {};
    return subsidiaryEntityQuery.state.data;
  }, [subsidiaryEntityQuery.state.data]);

  const initialValues = useMemo(
    () => SubsidiaryEntity.toForm(subsidiaryEntity),
    [subsidiaryEntity]
  );

  const availableYears = useMemo(() => {
    return yearsSuggestions.filter(
      (y) => +y.value === +initialValues.year_of_creation
    );
  }, [initialValues]);

  return (
    <>
      <HeadTitle
        type="Субсидиарная сущность"
        entity={getName(subsidiaryEntity)}
        pageType={HeadTitle.PageType.edit}
      />
      <SubsidiaryEntityBreadCrumbs />
      <h2>Редактирование субсидиарной сущности</h2>
      {queryLoaderWithExistenceCheck(subsidiaryEntityQuery) || (
        <Formik
          validationSchema={SubsidiaryEntityEditValidationSchema}
          initialValues={{
            ...initialValues
          }}
          onSubmit={(values, actions) => {
            subsidiaryEntityStore
              .updateSubsidiaryEntity(SubsidiaryEntity.fromForm(values))
              .then((result) => {
                actions.setStatus(FORM_STATUS_SUBMITTED);
                setTimeout(() => {
                  history.goBack(SubsidiaryEntity.getUrl(result.data.result));
                }, 0);
              })
              .catch((error) => {
                showGraphqlErrorToast(error, mutationErrors.updateObject);
              })
              .then(() => {
                actions.setSubmitting(false);
              });
          }}
          render={(formikProps) => (
            <SubsidiaryEntityEditForm
              {...formikProps}
              onBackClick={() => history.goBack(backLink)}
              subsidiaryEntity={subsidiaryEntity}
              editor={editor}
              availableYears={availableYears}
            />
          )}
        />
      )}
    </>
  );
}
