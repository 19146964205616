import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useContextStore } from '@proscom/prostore-react';
import { apiUrl } from '../../config';
import { STORE_FILE_TOKEN } from '../../store/names';
import { showGraphqlErrorToast } from '../../graphql/graphqlErrors';

/**
 * @typedef {Object} filtersType
 * @property {String[]|null|undefined} subsidiaryEntityIds - Фильтр по СС
 * @property {String[]|null|undefined} photoCategoryIds - Фильтр по Категории фото
 * @property {String[]|null|undefined} slotIds - Фильтр по слотам
 */

/**
 * Скачивание архива документов регионального мероприятия ДК
 * @param id - id регионального мероприятия дорожной карты
 * @param name - Имя конечного архива
 * @param accessToken - токен авторизации
 * @param {filtersType} filters - Фильтрация входных файлов
 * @returns {Promise<void>}
 */
export async function downloadArchiveFile(id, name, accessToken, filters) {
  try {
    if (!id) {
      return;
    }
    const cancelToken = axios.CancelToken.source();
    const response = await axios.request({
      method: 'post',
      url: apiUrl + `/files/regionalRoadmapEvents/${id}/documents`,
      cancelToken: cancelToken.token,
      responseType: 'blob',
      data: filters,
      params: {
        token: accessToken
      }
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank reefer';
    if (!name) {
      const urlFileName = decodeURIComponent(
        response.headers['content-disposition']
      ).split('filename=')[1];
      link.setAttribute('download', urlFileName);
    } else {
      link.setAttribute('download', `${name}.zip`);
    }
    link.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    if (error.response.status === 404) {
      showGraphqlErrorToast('Файлы не найдены');
    } else {
      showGraphqlErrorToast(error);
    }
  }
}

/**
 * Скачивание архива документов регионального мероприятия ДК
 * @param regionalEventId - id регионального мероприятия дорожной карты
 * @param eventName - Имя конечного архива
 * @param {filtersType} filters - Фильтрация входных файлов {@param callback
 * @link filtersType}
 * @returns {{isDownloading: boolean, handleArchiveDownload: function}}
 */
export const useArchiveDownloadingFile = (
  regionalEventId,
  eventName = null,
  filters = {},
  callback = () => {}
) => {
  const tokenStore = useContextStore(STORE_FILE_TOKEN);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleArchiveDownload = useCallback(
    async (funcFilters = {}) => {
      setIsDownloading(true);
      try {
        const accessToken = await tokenStore.createRoadmapDocumentsArchiveOneTimeToken(
          regionalEventId
        );
        const compareFilters = {
          ...filters,
          ...funcFilters
        };
        downloadArchiveFile(
          regionalEventId,
          eventName,
          accessToken,
          compareFilters
        ).then(() => {
          setIsDownloading(false);
          if (callback) {
            callback();
          }
        });
      } catch (error) {
        setIsDownloading(false);
      }
    },
    [callback, eventName, filters, regionalEventId, tokenStore]
  );
  return { handleArchiveDownload, isDownloading };
};
