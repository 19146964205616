import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { reactSelectHelpers } from '../../utils/form/reactSelectHelpers';
import s from './MultiSelectBlock.module.scss';

export const MultiSelectBlock = ({
  value,
  onChange,
  options,
  name,
  searchable,
  error,
  placeholder = 'Выбрать из списка...',
  disabled,
  className,
  isLoading
}) => {
  const selectRef = useRef();

  return (
    <div className={className}>
      <Select
        // для разработки: можно держать меню всегда открытым
        // menuIsOpen={true}
        ref={selectRef}
        value={value}
        options={options}
        isMulti
        name={name}
        onChange={onChange}
        className={s.Select}
        isClearable={false}
        isSearchable={searchable}
        menuPortalTarget={document.body}
        placeholder=""
        noOptionsMessage={() => 'Нет вариантов'}
        isLoading={isLoading}
        closeMenuOnScroll={true}
        styles={{
          placeholder: (provided) => ({
            ...provided,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '100%',
            paddingRight: 4
          }),
          container: (provided, state) => ({
            ...provided,
            border: `1px solid #${!!error ? 'F44336' : 'D1D0D8'}`,
            borderRadius: state.selectProps.menuIsOpen ? '6px 6px 0 0' : 6
          }),
          control: (provided, state) => ({
            ...provided,
            height: 34,
            minHeight: 34,
            outline: 'none',
            fontSize: 12,
            border: 'none',
            borderRadius: state.selectProps.menuIsOpen ? '6px 6px 0 0' : 6,
            boxShadow: 'none',
            '&:hover': {
              outline: 'none'
            },
            ...(state.selectProps.menuIsOpen
              ? {
                  borderBottom: '1px solid #F5F6F7'
                }
              : {})
          }),
          indicatorSeparator: () => ({
            display: 'none'
          }),
          menu: (provided) => ({
            ...provided,
            margin: 0,
            borderRadius: '0 0 6px 6px'
          }),
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 10
          }),
          option: (provided, state) => ({
            ...provided,
            minHeight: 36,
            padding: '5px 16px',
            fontSize: 12,
            lineHeight: '16px',
            display: 'flex',
            alignItems: 'center',
            background: state.isFocused ? '#F5F6F7' : '#fff',
            color: '#3F4752',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          })
        }}
        // filterOption исправляет ошибку с поиском по значению
        // https://stackoverflow.com/questions/51938587/react-select-type-to-search-access-to-filteroption-resulting-array
        filterOption={reactSelectHelpers.filterOption}
        // исправление ошибки при выборе первого значения по клику на пробел
        // https://stackoverflow.com/questions/53396697/react-select-v2-on-space-press-selects-first-value
        onKeyDown={(e) => {
          if (e.keyCode === 32 && !selectRef.current.state.inputValue) {
            e.preventDefault();
          }
        }}
        components={{
          DropdownIndicator: () => <AddIcon className={s.dropdownIndicator} />,
          ValueContainer: ({ children, ...props }) => (
            <components.ValueContainer {...props}>
              {!props?.selectProps?.menuIsOpen && placeholder}
              {children}
            </components.ValueContainer>
          ),
          // не выводить выбранные элементы внутри инпута
          MultiValueContainer: () => null
        }}
        isDisabled={disabled}
      />
      {value &&
        value.map((valueItem) => (
          <p key={valueItem.value} className={s.option}>
            {valueItem.label}
            <CloseIcon
              className={s.deleteIcon}
              onClick={() =>
                onChange(value.filter((v) => valueItem.value !== v.value))
              }
            />
          </p>
        ))}
    </div>
  );
};

MultiSelectBlock.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array
};
