import gql from 'graphql-tag';

export const QUERY_GET_PARENTAL_ASSISTANCE_GUIDELINES_ORGANIZATIONAL = gql`
  query getParentalAssistanceGuidelinesOrganizational(
    $filter: ParentalAssistanceGuidelinesOrganizationalFilter
  ) {
    parentalAssistanceGuidelinesOrganizational(filter: $filter) {
      id
    }
  }
`;
