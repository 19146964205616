import React, { useMemo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import classNames from 'classnames';
import SubTable from '../../../../common/SubTable/SubTable';
import { getName } from '../../../../utils/data/label';
import { formatCurrency } from '../../../../utils/number';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import { compareSimple, makeComparator } from '../../../../utils/sorting';
import { RpImplementationForecast } from '../../../../store/rpImplementationForecast/RpImplementationForecast';
import s from '../RpImplementationForecastPage.module.scss';

export const SubsidyForecasts = ({ region_id, subsidyForecasts }) => {
  const mbtFinancesGroupedQuery = RpImplementationForecast.useMbtFinancesGrouped(
    { region_id }
  );
  const mbtFinances = mbtFinancesGroupedQuery.state.data;

  const sortedForecasts = useMemo(() => {
    if (!subsidyForecasts) return subsidyForecasts;

    return subsidyForecasts.slice().sort(
      makeComparator(
        compareSimple,
        (s) => s.federal_project?.project_code,
        (s) => s.expenses_direction?.code
      )
    );
  }, [subsidyForecasts]);

  return (
    <>
      {queryLoaderWithExistenceCheck(mbtFinancesGroupedQuery) || (
        <SubTable title="Информация по субсидии" cols={6}>
          <TableRow>
            <TableCell className={classNames(s.tableCell, s.tableCellMedium)}>
              Региональный проект
            </TableCell>
            <TableCell className={classNames(s.tableCell, s.tableCellSmall)}>
              Код направления расходов{' '}
            </TableCell>
            <TableCell className={classNames(s.tableCell, s.tableCellLarge)}>
              Наименование направления расходов
            </TableCell>
            <TableCell className={classNames(s.tableCell, s.tableCellSmall)}>
              Сумма субсидии на 2020 год
            </TableCell>
            <TableCell className={classNames(s.tableCell, s.tableCellSmall)}>
              Прогнозная сумма освоения субсидии на конец 2020 года
            </TableCell>
            <TableCell className={classNames(s.tableCell, s.tableCellLarge)}>
              Примечание
            </TableCell>
          </TableRow>
          {sortedForecasts?.map((subsidyForecast, iSubsidyForecast) => (
            <TableRow key={iSubsidyForecast}>
              <TableCell className={classNames(s.tableCell, s.tableCellMedium)}>
                {getName(subsidyForecast.federal_project)}
              </TableCell>
              <TableCell className={classNames(s.tableCell, s.tableCellSmall)}>
                {subsidyForecast.expenses_direction.code}
              </TableCell>
              <TableCell className={classNames(s.tableCell, s.tableCellLarge)}>
                {getName(subsidyForecast.expenses_direction)}
              </TableCell>
              <TableCell className={classNames(s.tableCell, s.tableCellSmall)}>
                {formatCurrency(
                  RpImplementationForecast.getMbtFinance(
                    subsidyForecast.expenses_direction,
                    mbtFinances
                  )
                )}
              </TableCell>
              <TableCell className={classNames(s.tableCell, s.tableCellSmall)}>
                {subsidyForecast.forecast_value_entered &&
                  formatCurrency(subsidyForecast.forecast_value)}
              </TableCell>
              <TableCell className={classNames(s.tableCell, s.tableCellLarge)}>
                {subsidyForecast.annotation}
              </TableCell>
            </TableRow>
          ))}
        </SubTable>
      )}
    </>
  );
};
