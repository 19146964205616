import { useState } from 'react';
import {
  showGraphqlErrorToast,
  STATUS_CODE_GRAPHQL
} from '../../graphql/graphqlErrors';
import { useGeneralConfirmation } from '../Form/useGeneralConfirmation';

export function useGeneralConfirmationRow(
  confirmationFn,
  dialogTitle,
  errorMessage,
  onApply = undefined
) {
  const [loading, setLoading] = useState(false);

  const confirmationForm = (id) => {
    setLoading(true);
    return confirmationFn(id)
      .catch((error) => {
        showGraphqlErrorToast(error, {
          statusCode: STATUS_CODE_GRAPHQL,
          message: errorMessage
        });
      })
      .finally(() => {
        setLoading(false);
        if (onApply) {
          onApply();
        }
      });
  };

  const [dialog, openDialog] = useGeneralConfirmation(confirmationForm, {
    title: dialogTitle
  });

  return [loading, dialog, openDialog];
}
