import { useMemo } from 'react';
import { useUserRoleByPermissions } from '../../../utils/hooks/useUserRoleByPermissions';
import { COMMENT_STATUSES, COMMENT_TYPES } from '../commentEntities';
import { INFRALIST_STATUSES } from '../infralistStatusService';
import { useInfralistUserAccesses } from '../useInfralistUserAccesses';

/**
 *
 * @param infralistStatus
 * @param isEditEnabledByEventStatus
 * @returns {{canSeeFpo, canCreate: function(commentType: string, comments: Array): boolean, canSeeRvpo}}
 */
export const useInfralistCommentsUserAccesses = (
  infralistStatus,
  isEditEnabledByEventStatus
) => {
  const {
    isRVPO1,
    isRVPO2,
    isFPO1,
    isFPO2,
    isRandomRVPO,
    isRandomFpo
  } = useInfralistUserAccesses();
  const { getRole } = useUserRoleByPermissions();

  const accesses = useMemo(() => {
    const canCreate = (commentType, comments) => {
      const isRVPO2CanModifyComment = [
        INFRALIST_STATUSES.ON_SIGNING,
        INFRALIST_STATUSES.NOT_APPROVED
      ].includes(infralistStatus);
      const isRVPO1CanModifyComment = [
        INFRALIST_STATUSES.NOT_FORMED,
        INFRALIST_STATUSES.NOT_SIGNED
      ].includes(infralistStatus);
      if (
        ((isRVPO1 && isRVPO1CanModifyComment) ||
          (isRVPO2 && isRVPO2CanModifyComment)) &&
        commentType === COMMENT_TYPES.RVPO &&
        isEditEnabledByEventStatus
      ) {
        return true;
      }
      const isFPO1CanModifyComment = [INFRALIST_STATUSES.ON_CHECKING].includes(
        infralistStatus
      );
      const isFPO2CanModifyComment = [
        INFRALIST_STATUSES.ON_APPROVAL,
        INFRALIST_STATUSES.APPROVED,
        INFRALIST_STATUSES.APPROVED_WITH_COMMENTS
      ].includes(infralistStatus);
      if (
        ((isFPO1 && isFPO1CanModifyComment) ||
          (isFPO2 && isFPO2CanModifyComment)) &&
        commentType === COMMENT_TYPES.FPO &&
        isEditEnabledByEventStatus
      ) {
        return true;
      }

      const lastComment =
        comments.length === 0 ? undefined : comments[comments.length - 1];
      const lastCommentRole = getRole(lastComment?.commentary_user);

      if ((isFPO1 || isFPO2) && commentType === COMMENT_TYPES.GENERAL) {
        const isFpoCanModify =
          (isFPO2 && isFPO2CanModifyComment) ||
          (isFPO1 && isFPO1CanModifyComment);

        if (!isFpoCanModify) {
          return false;
        }

        /**
         * ФПО Может писать
         * - если нет комментов
         * - если последний коммент от ФПО
         * - если последний коммент от РВПО и он был принят ФПО
         */
        return (
          (!lastComment ||
            lastCommentRole?.type === COMMENT_TYPES.FPO ||
            lastComment?.accept_status === COMMENT_STATUSES.ACCEPTED_BY_FPO) &&
          isEditEnabledByEventStatus
        );
      }

      if ((isRVPO1 || isRVPO2) && commentType === COMMENT_TYPES.GENERAL) {
        // РВПО может писать только когда ответила на коммент ФПО с помощью кнопки "Отклонить"
        return (
          isRVPO2 &&
          isRVPO2CanModifyComment &&
          lastCommentRole?.type === COMMENT_TYPES.RVPO &&
          isEditEnabledByEventStatus
        );
      }

      return false;
    };

    return {
      canCreate,
      canSeeRvpo: isRVPO1 || isRVPO2 || isRandomRVPO,
      canSeeFpo: isFPO1 || isFPO2 || isRandomFpo
    };
  }, [
    isRVPO1,
    isRVPO2,
    isRandomRVPO,
    isFPO1,
    isFPO2,
    isRandomFpo,
    infralistStatus,
    getRole,
    isEditEnabledByEventStatus
  ]);

  return accesses;
};
