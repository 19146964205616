import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clean } from '../utils/object';

export function Routes(routes) {
  return function RoutesComp({ location, match }) {
    const pathPrefix = (match && match.path) || '';
    const fixPath = (path) => `${pathPrefix}${path}`;
    return (
      <Switch location={location}>
        {routes.map((props, i) => {
          const path = props.path
            ? props.path && Array.isArray(props.path)
              ? props.path.map(fixPath)
              : fixPath(props.path)
            : undefined;
          const newProps = clean({
            ...props,
            path
          });
          return <Route key={i} {...newProps} />;
        })}
      </Switch>
    );
  };
}
