import React from 'react';
import { Redirect } from 'react-router-dom';
import { Routes } from '../common/Routes';
import { INFRALIST_PERMISSIONS } from '../store/infralist/useInfralistUserAccesses';
import { User } from '../store/role/User';
import { isOrgContestDocsForm, isRegionalContestDocsForm } from '../config';
import { DefaultLayout, LoginLayout } from './DefaultLayout';
import IndexPage from './index/IndexPage';
import InfralistRoot from './infralist/InfralistRoot';
import ProjectsPage from './projects/ProjectsPage';
import ProjectPage from './projects/project/ProjectPage';
import ExpenseGroupPage from './projects/project/expenseGroups/expenseGroup/ExpenseGroupPage';
import NotFoundPage from './notFound/NotFoundPage';
import PurchasePage from './purchases/purchase/PurchasePage';
import PurchaseEditPage from './purchases/purchase/edit/PurchaseEditPage';
import PurchaseCreatePage from './purchases/purchase/create/PurchaseCreatePage';
import MonitoringPage from './monitoring/MonitoringPage';
import LoginPage from './login/LoginPage';
import { RequireAuth } from './RequireAuth';
import PurchasesPage from './purchases/PurchasesPage';
import RoadmapPage from './roadmaps/roadmap/RoadmapPage';
import ProjectRegionalEventPage from './regionalRoadmapEvents/regionalEvent/ProjectRegionalEventPage';
import RegistryRegionalEventPage from './regionalRoadmapEvents/regionalEvent/RegistryRegionalEventPage';
import ProjectRegionalEventEditPage from './regionalRoadmapEvents/regionalEvent/edit/ProjectToRegionalEventEdit';
import RegistryRegionalEventEditPage from './regionalRoadmapEvents/regionalEvent/edit/RegistryToRegionalEventEdit';
import PofsPage from './pofs/PofsPage';
import PofPage from './pofs/pof/PofPage';
import PofEditPage from './pofs/pof/edit/PofEditPage';
import PofCreatePage from './pofs/pof/create/PofCreatePage';
import SubsidiaryEntitiesPage from './subsidiaryEntities/SubsidiaryEntitiesPage';
import SubsidiaryEntityPage from './subsidiaryEntities/subsidiaryEntity/SubsidiaryEntityPage';
import SubsidiaryEntityEditPage from './subsidiaryEntities/subsidiaryEntity/edit/SubsidiaryEntityEditPage';
import SubsidiaryEntityCreatePage from './subsidiaryEntities/subsidiaryEntity/create/SubsidiaryEntityCreatePage';
import FederalEventPage from './roadmaps/roadmap/federalEvent/FederalEventPage';
import FederalEventEditPage from './roadmaps/roadmap/federalEvent/edit/FederalEventEditPage';
import RoadmapsPage from './roadmaps/RoadmapsPage';
import IndicatorPage from './roadmaps/roadmap/indicators/indicator/IndicatorPage';
import IndicatorEditPage from './roadmaps/roadmap/indicators/indicator/edit/IndicatorEditPage';
import IndicatorCreatePage from './roadmaps/roadmap/indicators/indicator/create/IndicatorCreatePage';
import HasNoAccessPage from './hasNoAccessPage/HasNoAccessPage';
import InformationPage from './information/InformationPage';
import UsersPage from './users/UsersPage';
import UserPage from './users/user/UserPage';
import UserEditPage from './users/user/edit/UserEditPage';
import UserCreatePage from './users/user/create/UserCreatePage';
import RolesPage from './roles/RolesPage';
import RolePage from './roles/role/RolePage';
import RoleEditPage from './roles/role/edit/RoleEditPage';
import RoleCreatePage from './roles/role/create/RoleCreatePage';
import RegionalRoadmapEventsPage from './regionalRoadmapEvents/RegionalRoadmapEventsPage';
import FormsPage from './forms/FormsPage';
import FormPage from './forms/form/FormPage';
import FormEditPage from './forms/form/edit/FormEditPage';
import FormCreatePage from './forms/form/create/FormCreatePage';
import FillFormsPage from './fillForms/FillFormsPage';
import FillFormPage from './fillForms/form/FillFormPage';
import FormDocumentPage from './fillForms/form/document/FormDocumentPage';
import FormDocumentEditPage from './fillForms/form/document/edit/FormDocumentEditPage';
import FormDocumentCreatePage from './fillForms/form/document/create/FormDocumentCreatePage';
import AdditionalDataPage from './roadmaps/roadmap/additionalDataList/additionalData/AdditionalDataPage';
import AdditionalDataEditPage from './roadmaps/roadmap/additionalDataList/additionalData/edit/AdditionalDataEditPage';
import AdditionalDataCreatePage from './roadmaps/roadmap/additionalDataList/additionalData/create/AdditionalDataCreatePage';
import RpImplementationForecastsPage from './rpImplementationForecasts/RpImplementationForecastsPage';
import RpImplementationForecastPage from './rpImplementationForecasts/rpImplementationForecast/RpImplementationForecastPage';
import RpImplementationForecastEditPage from './rpImplementationForecasts/rpImplementationForecast/edit/RpImplementationForecastEditPage';
import IndicatorForecastsPage from './indicatorForecasts/IndicatorForecastsPage';
import IndicatorForecastPage from './indicatorForecasts/indicatorForecast/IndicatorForecastPage';
import IndicatorForecastEditPage from './indicatorForecasts/indicatorForecast/edit/IndicatorForecastEditPage';
import NotificationsPage from './notifications/NotificationsPage';
import NotificationPage from './notifications/Notification/NotificationPage';
import NotificationCreatePage from './notifications/Notification/create/NotificationCreatePage';
import ParentalAssistanceGuidelines from './ParentalAssistanceGuidelines/ParentalAssistanceGuidelines';
import ParentalAssistanceGuidelinesRegionalEditPage from './ParentalAssistanceGuidelines/ParentalAssistanceGuidelinesRegional/edit/ParentalAssistanceGuidelinesRegionalEditPage';
import ParentalAssistanceGuidelinesRegionalPage from './ParentalAssistanceGuidelines/ParentalAssistanceGuidelinesRegional/ParentalAssistanceGuidelinesRegionalPage';
import ParentalAssistanceGuidelinesOrganizationalEditPage from './ParentalAssistanceGuidelines/ParentalAssistanceGuidelinesOrganizational/edit/ParentalAssistanceGuidelinesOrganizationalEditPage';
import OrganizationalParentalAssistanceGuidelinesPage from './ParentalAssistanceGuidelines/OrganizationalParentalAssistanceGuidelinesPage';
import ParentalAssistanceGuidelinesOrganizationalPage from './ParentalAssistanceGuidelines/ParentalAssistanceGuidelinesOrganizational/ParentalAssistanceGuidelinesOrganizationalPage';
import ParentalAssistanceGuidelinesOrganizationalCreatePage from './ParentalAssistanceGuidelines/ParentalAssistanceGuidelinesOrganizational/create/ParentalAssistanceGuidelinesOrganizationalCreatePage';
import ParentalAssistanceGuidelinesRegionalCreatePage from './ParentalAssistanceGuidelines/ParentalAssistanceGuidelinesRegional/create/ParentalAssistanceGuidelinesRegionalCreatePage';
import MentoringCoveragePage from './mentoringCoverage/MentoringCoveragePage';
import RegionMentoringCoverageEdit from './mentoringCoverage/RegionMentoringCoverage/edit/RegionMentoringCoverageEditPage';
import RegionMentoringCoverageCreate from './mentoringCoverage/RegionMentoringCoverage/create/RegionMentoringCoverageCreatePage';
import ReportMentoringCoverage from './mentoringCoverage/ReportMentoringCoverage/ReportMentoringCoveragePage';
import RegionMentoringCoverage from './mentoringCoverage/RegionMentoringCoverage/RegionMentoringCoveragePage';
import SubsidiaryMonitoringPage from './subsidiaryMonitoring/SubsidiaryMonitoringPage';
import ContestDocsMonitoringPage from './contestDocsMonitoring/ContestDocsMonitoringPage';
import ContestDocsForSSMonitoringPage from './contestDocsForSSMonitoring/ContestDocsMonitoringPage';
import SubsidiaryMonitoringFormPage from './subsidiaryMonitoring/SubsidiaryMonitoringFormPage';
import ContestDocsMonitoringFormPage from './contestDocsMonitoring/ContestDocsMonitoringFormPage';
import ContestDocsForSSMonitoringFormPage from './contestDocsForSSMonitoring/ContestDocsMonitoringFormPage';

const userHasRole = (roles) => (auth) => {
  const user = User.getUser(auth);
  if (!user) return false;
  return roles.includes(user?.role);
};

const userAdmin = userHasRole([User.Role.ADMIN]);

const userFederalAdmin = userHasRole([
  User.Role.ADMIN,
  User.Role.FEDERAL_ADMIN
]);

const userRegionAdmin = userHasRole([User.Role.REGION_ADMIN]);

const userRegionOrFederalAdmin = userHasRole([
  User.Role.ADMIN,
  User.Role.REGION_ADMIN,
  User.Role.FEDERAL_ADMIN
]);

const userOrganizationAdmin = userHasRole([
  User.Role.ADMIN,
  User.Role.ORGANIZATION_ADMIN
]);

const userHasAnyPermission = (...permissions) => (auth) => {
  const user = User.getUser(auth);
  return User.hasPermission(user, ...permissions);
};

const routes = [
  {
    path: '/',
    exact: true,
    component: DefaultLayout(RequireAuth(IndexPage))
  },
  {
    path: '/notifications',
    exact: true,
    component: DefaultLayout(RequireAuth(NotificationsPage))
  },
  {
    path: '/notifications/create',
    exact: true,
    component: DefaultLayout(RequireAuth(NotificationCreatePage))
  },
  {
    path: '/notifications/:id',
    exact: true,
    component: DefaultLayout(RequireAuth(NotificationPage))
  },
  {
    path: '/users',
    exact: true,
    component: DefaultLayout(
      RequireAuth(UsersPage, userHasAnyPermission('user.crud', 'user.crud_all'))
    )
  },
  {
    path: '/users/create',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        UserCreatePage,
        userHasAnyPermission('user.crud', 'user.crud_all')
      )
    )
  },
  {
    path: '/users/:userId',
    exact: true,
    component: DefaultLayout(
      RequireAuth(UserPage, userHasAnyPermission('user.crud', 'user.crud_all'))
    )
  },
  {
    path: '/users/:userId/edit',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        UserEditPage,
        userHasAnyPermission('user.crud', 'user.crud_all')
      )
    )
  },
  {
    path: '/roles',
    exact: true,
    component: DefaultLayout(
      RequireAuth(RolesPage, userHasAnyPermission('role.crud', 'role.crud_all'))
    )
  },
  {
    path: '/roles/create',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        RoleCreatePage,
        userHasAnyPermission('role.crud', 'role.crud_all')
      )
    )
  },
  {
    path: '/roles/:roleId',
    exact: true,
    component: DefaultLayout(
      RequireAuth(RolePage, userHasAnyPermission('role.crud', 'role.crud_all'))
    )
  },
  {
    path: '/roles/:roleId/edit',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        RoleEditPage,
        userHasAnyPermission('role.crud', 'role.crud_all')
      )
    )
  },
  {
    path: '/purchases',
    exact: true,
    component: DefaultLayout(
      RequireAuth(PurchasesPage, userHasAnyPermission('purchase.access_page'))
    )
  },
  {
    path: '/monitoring',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        MonitoringPage,
        userHasAnyPermission('organization.access_monitoring')
      )
    )
  },
  {
    path: '/rpImplementationForecast',
    exact: true,
    component: DefaultLayout(
      RequireAuth(RpImplementationForecastsPage, (auth) => {
        return (
          userHasAnyPermission('rp_forecast.access_page') &&
          userRegionAdmin(auth)
        );
      })
    )
  },
  {
    path: '/rpImplementationForecast/:rpImplementationForecastId',
    exact: true,
    component: DefaultLayout(
      RequireAuth(RpImplementationForecastPage, (auth) => {
        return (
          userHasAnyPermission('rp_forecast.access_page') &&
          userRegionAdmin(auth)
        );
      })
    )
  },
  {
    path: ['/rpImplementationForecast/:rpImplementationForecastId/edit'],
    exact: true,
    component: DefaultLayout(
      RequireAuth(RpImplementationForecastEditPage, (auth) => {
        return (
          userHasAnyPermission('rp_forecast.crud') && userRegionAdmin(auth)
        );
      })
    )
  },
  {
    path: '/indicatorForecasts',
    exact: true,
    component: DefaultLayout(
      RequireAuth(IndicatorForecastsPage, (auth) => {
        return (
          userHasAnyPermission('rp_forecast.access_page') &&
          userRegionAdmin(auth)
        );
      })
    )
  },
  {
    path: '/indicatorForecasts/:indicatorForecastId',
    exact: true,
    component: DefaultLayout(
      RequireAuth(IndicatorForecastPage, (auth) => {
        return (
          userHasAnyPermission('rp_forecast.access_page') &&
          userRegionAdmin(auth)
        );
      })
    )
  },
  {
    path: ['/indicatorForecasts/:indicatorForecastId/edit'],
    exact: true,
    component: DefaultLayout(
      RequireAuth(IndicatorForecastEditPage, (auth) => {
        return (
          userHasAnyPermission('rp_forecast.crud') && userRegionAdmin(auth)
        );
      })
    )
  },
  {
    path: '/regionalRoadmapEvents/',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        RegionalRoadmapEventsPage,
        userHasAnyPermission('regional_event.access_page')
      )
    )
  },
  {
    path: '/projects',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        ProjectsPage,
        userHasAnyPermission('regional_project.access_page')
      )
    )
  },
  {
    path: '/projects/:projectId',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        ProjectPage,
        userHasAnyPermission('regional_project.access_page')
      )
    )
  },
  {
    path: [
      '/projects/:projectId/expenseGroups',
      '/projects/:projectId/purchases'
    ],
    exact: true,
    component: ({ match }) => <Redirect to={`/projects/${match.params.id}`} />
  },
  {
    path: '/projects/:projectId/expenseGroups/:expenseGroupId',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        ExpenseGroupPage,
        userHasAnyPermission('purchase.access_page')
      )
    )
  },
  {
    path: '/projects/:projectId/expenseGroups/:id/purchases',
    exact: true,
    component: ({ match }) => (
      <Redirect
        to={`/projects/${match.params.projectId}/expenseGroups/${match.params.id}`}
      />
    )
  },
  {
    path: [
      '/projects/:projectId/expenseGroups/:expenseGroupId/purchases/:id',
      '/purchases/:id'
    ],
    exact: true,
    component: DefaultLayout(
      RequireAuth(PurchasePage, userHasAnyPermission('purchase.access_page'))
    )
  },
  {
    path: [
      '/projects/:projectId/expenseGroups/:expenseGroupId/purchases/:id/edit',
      '/purchases/:id/edit'
    ],
    exact: true,
    component: DefaultLayout(
      RequireAuth(PurchaseEditPage, userHasAnyPermission('purchase.crud'))
    )
  },
  {
    path: '/projects/:projectId/roadmaps',
    exact: true,
    component: ({ match }) => (
      <Redirect to={`/projects/${match.params.projectId}?tab=roadmaps`} />
    )
  },
  {
    path: ['/projects/:projectId/roadmaps/:roadmapId', '/roadmaps/:roadmapId'],
    exact: true,
    component: DefaultLayout(
      RequireAuth(RoadmapPage, userHasAnyPermission('roadmap.access_page'))
    )
  },
  {
    path: '/projects/:projectId/roadmaps/:roadmapId/events',
    exact: true,
    component: ({ match }) => (
      <Redirect
        to={`/projects/${match.params.projectId}/roadmaps/${match.params.roadmapId}?tab=events`}
      />
    )
  },
  {
    path: '/projects/:projectId/roadmaps/:roadmapId/events/:eventId',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        ProjectRegionalEventPage,
        userHasAnyPermission('regional_event.access_page')
      )
    )
  },
  {
    path: [
      '/roadmaps/:roadmapId/events/:eventId/regions/:regionalEventId',
      '/regionalRoadmapEvents/:regionalEventId'
    ],
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        RegistryRegionalEventPage,
        userHasAnyPermission('regional_event.access_page')
      )
    )
  },
  {
    path: '/projects/:projectId/roadmaps/:roadmapId/events/:eventId/edit',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        ProjectRegionalEventEditPage,
        userHasAnyPermission('regional_event.edit', 'regional_event.approve')
      )
    )
  },
  {
    path: [
      '/roadmaps/:roadmapId/events/:eventId/regions/:regionalEventId/edit',
      '/regionalRoadmapEvents/:regionalEventId/edit'
    ],
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        RegistryRegionalEventEditPage,
        userHasAnyPermission('regional_event.edit', 'regional_event.approve')
      )
    )
  },
  {
    path: '/projects/:projectId/roadmaps/:roadmapId/indicators',
    exact: true,
    component: ({ match }) => (
      <Redirect
        to={`/projects/${match.params.projectId}/roadmaps/${match.params.roadmapId}?tab=indicators`}
      />
    )
  },
  {
    path: '/roadmaps/:roadmapId/indicators',
    exact: true,
    component: ({ match }) => (
      <Redirect to={`/roadmaps/${match.params.roadmapId}?tab=indicators`} />
    )
  },
  {
    path: [
      '/projects/:projectId/roadmaps/:roadmapId/indicators/create',
      '/roadmaps/:roadmapId/indicators/create'
    ],
    exact: true,
    component: DefaultLayout(
      RequireAuth(IndicatorCreatePage, userHasAnyPermission('indicator.crud'))
    )
  },
  {
    path: [
      '/projects/:projectId/roadmaps/:roadmapId/indicators/:indicatorId',
      '/roadmaps/:roadmapId/indicators/:indicatorId'
    ],
    exact: true,
    component: DefaultLayout(
      RequireAuth(IndicatorPage, userHasAnyPermission('indicator.access_page'))
    )
  },
  {
    path: [
      '/projects/:projectId/roadmaps/:roadmapId/indicators/:indicatorId/edit',
      '/roadmaps/:roadmapId/indicators/:indicatorId/edit'
    ],
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        IndicatorEditPage,
        userHasAnyPermission('indicator.crud', 'indicator.approve')
      )
    )
  },
  {
    path: '/projects/:projectId/roadmaps/:roadmapId/additionalData',
    exact: true,
    component: ({ match }) => (
      <Redirect
        to={`/projects/${match.params.projectId}/roadmaps/${match.params.roadmapId}?tab=additionalData`}
      />
    )
  },
  {
    path: '/roadmaps/:roadmapId/additionalData',
    exact: true,
    component: ({ match }) => (
      <Redirect to={`/roadmaps/${match.params.roadmapId}?tab=additionalData`} />
    )
  },
  {
    path: [
      '/projects/:projectId/roadmaps/:roadmapId/additionalData/create',
      '/roadmaps/:roadmapId/additionalData/create'
    ],
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        AdditionalDataCreatePage,
        userHasAnyPermission('indicator.crud')
      )
    )
  },
  {
    path: [
      '/projects/:projectId/roadmaps/:roadmapId/additionalData/:additionalDataId',
      '/roadmaps/:roadmapId/additionalData/:additionalDataId'
    ],
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        AdditionalDataPage,
        userHasAnyPermission('indicator.access_page')
      )
    )
  },
  {
    path: [
      '/projects/:projectId/roadmaps/:roadmapId/additionalData/:additionalDataId/edit',
      '/roadmaps/:roadmapId/additionalData/:additionalDataId/edit'
    ],
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        AdditionalDataEditPage,
        userHasAnyPermission('indicator.crud', 'indicator.approve')
      )
    )
  },
  {
    path: [
      '/purchase/create',
      '/projects/:projectId/purchase/create',
      '/projects/:projectId/expenseGroups/:expenseGroupId/purchase/create'
    ],
    exact: true,
    component: DefaultLayout(
      RequireAuth(PurchaseCreatePage, userHasAnyPermission('purchase.crud'))
    )
  },
  {
    path: '/subsidiaryEntities',
    exact: true,
    component: DefaultLayout(
      RequireAuth(SubsidiaryEntitiesPage, userRegionOrFederalAdmin)
    )
  },
  {
    path: '/subsidiaryEntities/create',
    exact: true,
    component: DefaultLayout(
      RequireAuth(SubsidiaryEntityCreatePage, userRegionOrFederalAdmin)
    )
  },
  {
    path: '/subsidiaryEntities/:subsidiaryEntityId',
    exact: true,
    component: DefaultLayout(
      RequireAuth(SubsidiaryEntityPage, userRegionOrFederalAdmin)
    )
  },
  {
    path: '/subsidiaryEntities/:subsidiaryEntityId/edit',
    exact: true,
    component: DefaultLayout(
      RequireAuth(SubsidiaryEntityEditPage, userRegionOrFederalAdmin)
    )
  },
  {
    path: '/pofs',
    exact: true,
    component: DefaultLayout(
      RequireAuth(PofsPage, userHasAnyPermission('pof.access_page'))
    )
  },
  {
    path: '/projects/:projectId/pofs',
    exact: true,
    component: ({ match }) => (
      <Redirect to={`/projects/${match.params.projectId}?tab=pofs`} />
    )
  },
  {
    path: ['/pofs/create', '/projects/:projectId/pofs/create'],
    exact: true,
    component: DefaultLayout(
      RequireAuth(PofCreatePage, userHasAnyPermission('pof.crud'))
    )
  },
  {
    path: ['/pofs/:pofId', '/projects/:projectId/pofs/:pofId'],
    exact: true,
    component: DefaultLayout(
      RequireAuth(PofPage, userHasAnyPermission('pof.access_page'))
    )
  },
  {
    path: ['/pofs/:pofId/edit', '/projects/:projectId/pofs/:pofId/edit'],
    exact: true,
    component: DefaultLayout(
      RequireAuth(PofEditPage, userHasAnyPermission('pof.crud', 'pof.approve'))
    )
  },
  {
    path: '/roadmaps',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        RoadmapsPage,
        userHasAnyPermission('roadmap_event.access_page')
      )
    )
  },
  {
    path: '/roadmaps/:roadmapId/events/:eventId',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        FederalEventPage,
        userHasAnyPermission('roadmap_event.access_page')
      )
    )
  },
  {
    path: '/roadmaps/:roadmapId/events/:eventId/edit',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        FederalEventEditPage,
        userHasAnyPermission('roadmap_event.edit')
      )
    )
  },
  {
    path: '/fillForms',
    exact: true,
    component: DefaultLayout(
      RequireAuth(FillFormsPage, userHasAnyPermission('form.access_fill_page'))
    )
  },
  {
    path: '/fillForms/:formId',
    exact: true,
    component: DefaultLayout(
      RequireAuth(FillFormPage, userHasAnyPermission('form.access_fill_page'))
    )
  },
  {
    path: '/fillForms/:formId/documents/create',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        FormDocumentCreatePage,
        userHasAnyPermission('form.access_fill_page')
      )
    )
  },
  {
    path: '/fillForms/:formId/documents/:documentId',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        FormDocumentPage,
        userHasAnyPermission('form.access_fill_page')
      )
    )
  },
  {
    path: '/fillForms/:formId/documents/:documentId/edit',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        FormDocumentEditPage,
        userHasAnyPermission('form.access_fill_page')
      )
    )
  },
  {
    path: '/forms',
    exact: true,
    component: DefaultLayout(
      RequireAuth(FormsPage, userHasAnyPermission('form.crud'))
    )
  },
  {
    path: '/forms/create',
    exact: true,
    component: DefaultLayout(
      RequireAuth(FormCreatePage, userHasAnyPermission('form.crud'))
    )
  },
  {
    path: '/forms/:formId',
    exact: true,
    component: DefaultLayout(
      RequireAuth(FormPage, userHasAnyPermission('form.crud'))
    )
  },
  {
    path: '/forms/:formId/edit',
    exact: true,
    component: DefaultLayout(
      RequireAuth(FormEditPage, userHasAnyPermission('form.crud'))
    )
  },
  {
    path: '/mentoringCoverage',
    exact: true,
    component: DefaultLayout(
      RequireAuth(MentoringCoveragePage, (auth) => {
        return auth?.authData?.user?.role === User.Role.REGION_ADMIN;
      })
    )
  },
  {
    path: '/mentoringCoverage/report',
    exact: true,
    component: DefaultLayout(
      RequireAuth(ReportMentoringCoverage, (auth) => {
        return auth?.authData?.user?.role === User.Role.REGION_ADMIN;
      })
    )
  },
  {
    path: '/mentoringCoverage/create',
    exact: true,
    component: DefaultLayout(
      RequireAuth(RegionMentoringCoverageCreate, (auth) => {
        return auth?.authData?.user?.role === User.Role.REGION_ADMIN;
      })
    )
  },
  {
    path: '/mentoringCoverage/:mentoringCoverageId',
    exact: true,
    component: DefaultLayout(
      RequireAuth(RegionMentoringCoverage, (auth) => {
        return auth?.authData?.user?.role === User.Role.REGION_ADMIN;
      })
    )
  },
  {
    path: '/mentoringCoverage/:mentoringCoverageId/edit',
    exact: true,
    component: DefaultLayout(
      RequireAuth(RegionMentoringCoverageEdit, (auth) => {
        return auth?.authData?.user?.role === User.Role.REGION_ADMIN;
      })
    )
  },
  {
    path: '/parentalAssistanceGuidelines',
    exact: true,
    component: DefaultLayout(
      RequireAuth(ParentalAssistanceGuidelines, (auth) => {
        return (
          userHasAnyPermission(
            'parental_assistance_guideline_regional.access_page'
          ) && auth?.authData?.user?.role === User.Role.REGION_ADMIN
        );
      })
    )
  },
  {
    path: '/parentalAssistanceGuidelines/create',
    exact: true,
    component: DefaultLayout(
      RequireAuth(ParentalAssistanceGuidelinesRegionalCreatePage, (auth) => {
        return (
          userHasAnyPermission('parental_assistance_guideline_regional.crud') &&
          auth?.authData?.user?.role === User.Role.REGION_ADMIN
        );
      })
    )
  },
  {
    path: '/parentalAssistanceGuidelines/:guidelineId',
    exact: true,
    component: DefaultLayout(
      RequireAuth(ParentalAssistanceGuidelinesRegionalPage, (auth) => {
        return (
          userHasAnyPermission(
            'parental_assistance_guideline_regional.access_page'
          ) && auth?.authData?.user?.role === User.Role.REGION_ADMIN
        );
      })
    )
  },
  {
    path: '/parentalAssistanceGuidelines/:guidelineId/edit',
    exact: true,
    component: DefaultLayout(
      RequireAuth(ParentalAssistanceGuidelinesRegionalEditPage, (auth) => {
        return (
          userHasAnyPermission('parental_assistance_guideline_regional.crud') &&
          auth?.authData?.user?.role === User.Role.REGION_ADMIN
        );
      })
    )
  },
  {
    path: '/nkoGuidelines',
    exact: true,
    component: OrganizationalParentalAssistanceGuidelinesPage
  },
  {
    path: '/nkoGuidelines/create',
    exact: true,
    component: ParentalAssistanceGuidelinesOrganizationalCreatePage
  },
  {
    path: '/nkoGuidelines/:guidelineId',
    exact: true,
    component: ParentalAssistanceGuidelinesOrganizationalPage
  },
  {
    path: '/nkoGuidelines/:guidelineId/edit',
    exact: true,
    component: ParentalAssistanceGuidelinesOrganizationalEditPage
  },
  {
    path: '/contestDocsMonitoring',
    exact: true,
    component: DefaultLayout(
      isRegionalContestDocsForm
        ? RequireAuth(
            ContestDocsMonitoringPage,
            userHasAnyPermission('monthly_monitoring.tender_documentation.view')
          )
        : RequireAuth(
            ContestDocsForSSMonitoringPage,
            userHasAnyPermission(
              'monthly_monitoring.tender_documentation_for_ss.view'
            )
          )
    )
  },
  {
    path: [
      '/contestDocsMonitoring/edit/:entityId',
      '/contestDocsMonitoring/edit'
    ],
    exact: true,
    component: DefaultLayout(
      isRegionalContestDocsForm
        ? RequireAuth(
            ContestDocsMonitoringFormPage,
            userHasAnyPermission('monthly_monitoring.tender_documentation.view')
          )
        : RequireAuth(
            ContestDocsForSSMonitoringFormPage,
            userHasAnyPermission(
              'monthly_monitoring.tender_documentation_for_ss.view'
            )
          )
    )
  },
  {
    path: '/subsidiaryMonitoring',
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        SubsidiaryMonitoringPage,
        userHasAnyPermission(
          'monthly_monitoring.subsidiary_entity.view',
          'monthly_monitoring.subsidiary_entity.view_own',
          'monthly_monitoring.subsidiary_entity.crud',
          'monthly_monitoring.subsidiary_entity.crud_own'
        )
      )
    )
  },
  {
    path: [
      '/subsidiaryMonitoring/edit/:entityId',
      '/subsidiaryMonitoring/edit'
    ],
    exact: true,
    component: DefaultLayout(
      RequireAuth(
        SubsidiaryMonitoringFormPage,
        userHasAnyPermission(
          'monthly_monitoring.subsidiary_entity.view',
          'monthly_monitoring.subsidiary_entity.view_own',
          'monthly_monitoring.subsidiary_entity.crud',
          'monthly_monitoring.subsidiary_entity.crud_own'
        )
      )
    )
  },
  {
    path: [
      '/infralist/:infralistId/version/:versionId',
      '/infralist/:infralistId/',
      '/infralist-comments/:infralistId',
      '/infralist-change-logs/:infralistId'
    ],
    component: RequireAuth(
      InfralistRoot,
      userHasAnyPermission(
        INFRALIST_PERMISSIONS.VIEW_FEDERAL,
        INFRALIST_PERMISSIONS.VIEW_REGIONAL
      )
    )
  },
  {
    path: '/information',
    exact: true,
    component: DefaultLayout(RequireAuth(InformationPage))
  },
  {
    path: '/login',
    exact: true,
    component: LoginLayout(LoginPage)
  },
  {
    path: '/no-access',
    exact: true,
    component: HasNoAccessPage
  },
  {
    component: DefaultLayout(NotFoundPage)
  }
];

const AppRoutes = Routes(routes);

export default AppRoutes;
