import gql from 'graphql-tag';

export const FRAGMENT_FORM = gql`
  fragment form on CustomFormType {
    id
    name
    creator_user_id
    created_at
    updated_at
    fields {
      id
      key
      name
      order
      created_at
      updated_at
      input_type
      entity_class
    }
  }
`;

export const QUERY_GET_FORMS = gql`
  query forms($input: CustomFormPageInput!) {
    forms(input: $input) {
      header {
        hasNext
        onePage
        page
        totalCount
      }
      list {
        ...form
      }
    }
  }

  ${FRAGMENT_FORM}
`;

export const QUERY_GET_FORM = gql`
  query form($id: ID!) {
    form(id: $id) {
      ...form
    }
  }

  ${FRAGMENT_FORM}
`;

export const FRAGMENT_FORM_DOCUMENT = gql`
  fragment formDocument on CustomFormDocumentType {
    id
    form_id
    author_id
    data
    version
    created_at
    updated_at
    author {
      id
      email
      login
      name
    }
  }
`;

export const QUERY_GET_FORM_DOCUMENTS = gql`
  query formDocuments($input: CustomFormDocumentPageInput!) {
    formDocuments(input: $input) {
      header {
        hasNext
        onePage
        page
        totalCount
      }
      list {
        ...formDocument
      }
    }
  }

  ${FRAGMENT_FORM_DOCUMENT}
`;

export const QUERY_GET_FORM_DOCUMENT = gql`
  query formDocument($id: ID!) {
    formDocument(id: $id) {
      ...formDocument
      form {
        ...form
      }
    }
  }

  ${FRAGMENT_FORM_DOCUMENT}
  ${FRAGMENT_FORM}
`;
