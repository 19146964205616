import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import { useRegions } from '../../../store/subsidiaryEntity/useSubsidiaryEntity';
import { useRoadmap } from '../../../store/roadmap/useRoadmap';
import { getName } from '../../../utils/data/label';
import { useRegionalProject } from '../../../store/regionalProject/useRegionalProject';
import { useForm } from '../../../store/form/formQueries';

export const RoadmapName = ({ id }) => {
  const roadmapQuery = useRoadmap(id);
  const roadmap = roadmapQuery.state.data;

  return <TableCell>{getName(roadmap)}</TableCell>;
};

export const RegionName = ({ id }) => {
  const regionsQuery = useRegions(id);
  const region = regionsQuery.state.data;

  return <TableCell>{getName(region?.[0])}</TableCell>;
};

export const RegionalProjectName = ({ id }) => {
  const projectQuery = useRegionalProject(id);
  const project = projectQuery.state.data;

  return <TableCell>{getName(project)}</TableCell>;
};

export const FormName = ({ id }) => {
  const query = useForm(id);
  const item = query.state.data;
  return <TableCell>{getName(item)}</TableCell>;
};
