import React from 'react';

export function SubsidiaryEntityOrganizationInfo({ organization }) {
  if (!organization) {
    return false;
  }

  return (
    <>
      <span>{organization.name_full}</span>{' '}
      <span>(ИНН: {organization.inn})</span>{' '}
      <span>(КПП: {organization.kpp})</span>
      <br />
      <span>{organization.bs_address}</span>
    </>
  );
}
