import { validateNumberRegexp } from './number';

export function tryParseJson(json, defaultValue = null) {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.error('Error parsing json', json, e);
    return defaultValue;
  }
}

export function isValidNumber(n) {
  return typeof n == 'number' && !isNaN(n) && isFinite(n);
}

export function isNumberLike(n) {
  return !isNaN(parseFloat(n)) && !!n.toString().match(validateNumberRegexp);
}

export function tryNumberLike(value, defaultValue, convertToNumber = false) {
  if (isNumberLike(value)) {
    return convertToNumber ? +value : value;
  }
  return defaultValue;
}

export function tryNumber(value, defaultValue) {
  const number = +value;
  if (isValidNumber(number)) {
    return number;
  }
  return defaultValue;
}

export function delayPromise(time = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export function getFirstOfArray(array, defaultValue = undefined) {
  if (Array.isArray(array) && array.length > 0) {
    return array[0];
  }
  return defaultValue;
}
