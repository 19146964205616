import gql from 'graphql-tag';

export const QUERY_GET_EXPENSES_DIRECTIONS = gql`
  query expensesDirections($input: ExpensesDirectionsPageInput!) {
    expensesDirections(input: $input) {
      list {
        id
        name_full
        code
      }
    }
  }
`;

export const QUERY_GET_EXPENSES_DIRECTIONS_RICH_LIST = gql`
  query expensesDirectionsRichList($input: ExpensesDirectionsPageInput!) {
    expensesDirections(input: $input) {
      list {
        id
        name_full
        code
        federal_project_id
        federal_project {
          id
          name_full
          project_code
        }
      }
    }
  }
`;

export const QUERY_GET_EXPENSES_DIRECTIONS_FOR_PROJECT = gql`
  query expensesDirectionsForProject(
    $regionalProjectId: ID!
    $implementation_years: [Int!]
    $delivery_years: [Int!]
  ) {
    expensesDirections(
      input: {
        filter: {
          regional_project_ids: [$regionalProjectId]
          implementation_years: $implementation_years
        }
      }
    ) {
      list {
        id
        name_full
        code
        count_purchases(
          regional_project_ids: [$regionalProjectId]
          delivery_years: $delivery_years
        )
        pof_rest(regional_project_id: $regionalProjectId)
      }
    }
  }
`;

export const QUERY_GET_EXPENSES_DIRECTION = gql`
  query expensesDirection($id: ID!) {
    expensesDirection(id: $id) {
      id
      name_full
      code
      result {
        id
        name_full
      }
      implementation_years
    }
  }
`;

export const QUERY_GET_RP_INDICATORS = gql`
  query rpIndicators($regionId: ID!, $year: Int!) {
    indicators(filter: { general: false, region_ids: [$regionId] }) {
      id
      code
      name_full
      unit {
        id
        name
      }
      values(years: [$year], region_ids: [$regionId]) {
        indicator_id
        value_number
        period {
          id
          name
          start
          end
        }
        plan_data {
          id
          regional_project {
            id
            name
            name_full
            federal_project {
              id
              name
              name_full
              project_code
            }
          }
        }
      }
    }
  }
`;
