import { COMMENT_STATUSES } from '../commentEntities';
import { INFRALIST_STATUSES } from '../infralistStatusService';
import { useInfralistUserAccesses } from '../useInfralistUserAccesses';

/**
 *
 * @param infralistStatus
 * @returns {{canSeeActions: (function(comments: Array): boolean)}}
 */
export const useInfralistCollectedCommentAccess = (
  infralistStatus,
  isEditEnabledByEventStatus
) => {
  const { isRVPO2, isFPO2 } = useInfralistUserAccesses();
  const canFPOMakeDicision = INFRALIST_STATUSES.ON_APPROVAL === infralistStatus;
  const canRVPOMakeDecision =
    INFRALIST_STATUSES.NOT_APPROVED === infralistStatus;

  const canSeeActions = (comments) => {
    const lastComment =
      comments.length === 0 ? undefined : comments[comments.length - 1];

    if (
      isFPO2 &&
      canFPOMakeDicision &&
      lastComment?.accept_status === COMMENT_STATUSES.EMPTY
    ) {
      return true;
    }

    return (
      isRVPO2 &&
      canRVPOMakeDecision &&
      lastComment?.accept_status === COMMENT_STATUSES.EMPTY &&
      comments.length > 0 &&
      isEditEnabledByEventStatus
    );
  };

  return { canSeeActions };
};
