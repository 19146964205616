import Avatar from '@material-ui/core/Avatar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useStore } from '@proscom/prostore-react';
import { padStart } from 'lodash-es';
import React from 'react';
import russiaSrc from '../../../../assets/avatar-template.jpg';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../graphql/graphqlErrors';
import { STORE_AUTH } from '../../../../store/names';
import { User } from '../../../../store/role/User';
import Menu from '../../../Menu/Menu';
import s from './HeaderMain.module.scss';

export const HeaderMain = () => {
  const [auth, authStore] = useStore(STORE_AUTH);

  if (!authStore.isLoggedIn()) return false;
  const user = auth.authData.user;
  const regionCodeRaw = user.region && user.region.code_nalog;
  const regionCode = regionCodeRaw && padStart(regionCodeRaw, 2, '0');
  const regionSrc = regionCode ? `/img/regions/${regionCode}.png` : russiaSrc;

  return (
    <div className={s.Header__element}>
      <Avatar alt="Герб" src={regionSrc} className={s.Avatar} />
      <div className={s.CabCaption}>
        <p className={s.CabOffice}>
          {user ? User.getRoleAdminPanelName(user) : 'Личный кабинет'}
        </p>
        <p className={s.CabWorker}>{user.name}</p>
      </div>
      <Menu
        iconColor="inherit"
        icon={KeyboardArrowDownIcon}
        options={[
          {
            title: 'Выход',
            onClick: () =>
              authStore
                .logOut()
                .catch((e) => showGraphqlErrorToast(e, mutationErrors.logOut))
          }
        ]}
      />
    </div>
  );
};
