import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import format from 'date-fns/format';
import { ru } from 'date-fns/locale';
import SubTable from '../../../common/SubTable/SubTable';
import { ParentalAssistanceGuideline } from '../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import { ListWithDescription } from '../common/ListWithDescription';
import { Description } from '../common/Description';
import { List } from '../common/List';
import { DATE_FORMAT_RUSSIAN } from '../../../utils/date';
import { parseApiDate, removeTimezoneFromDate } from '../../../utils/data/date';
import { SimpleFileView } from '../common/FileUpload/FileEditableComponent';
import { ServiceDeliveryFormats } from './ServiceDeliveryFormats';
import { PossibleChoice } from './PossibleChoice';
import s from '../ParentalAssistanceGuidelines.module.scss';

const TableDateCell = ({
  value,
  format: formatText = DATE_FORMAT_RUSSIAN,
  locale = ru
}) => {
  const d = removeTimezoneFromDate(parseApiDate(value));
  return <p>{d && format(d, formatText, { locale })}</p>;
};

export const ProcedureRegionalSection = ({ data }) => {
  return (
    <SubTable title="II. Порядок оказания услуг">
      <TableRow>
        <TableCell>
          1. С какого момента в Вашем субъекте Российской Федерации оказывается
          услуга (дата начала оказания услуг).
        </TableCell>
        <TableCell>
          <TableDateCell value={data.date_of_service_start} />
          <Description
            descriptionTitle="Комментарии (при наличии)"
            descriptionText={data.date_of_service_start_description}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          2. Опишите механизм предоставления услуги. Опишите источники
          финансирования организаций, оказывающих услуги на территории субъекта
        </TableCell>
        <TableCell>
          <Description
            descriptionTitle="Описание механизма"
            descriptionText={data.service_delivery_mechanism}
          />
          <Description descriptionTitle="Источники финансирования" />
          <List
            list={data.sources_of_financing}
            mapData={(item) =>
              ParentalAssistanceGuideline.sourcesOfFinancing.getName(
                item.toUpperCase()
              )
            }
          />
          <Description
            descriptionTitle="Другие источники"
            descriptionText={data.other_sources_of_financing}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          3. Перечислите виды консультаций, предоставляемые в Вашем субъекте
          Российской Федерации без взимания платы
        </TableCell>
        <TableCell>
          <List
            list={data.types_of_free_consultations}
            mapData={(item) =>
              ParentalAssistanceGuideline.typesOfFreeConsultations.getName(
                item.toUpperCase()
              )
            }
          />
        </TableCell>
      </TableRow>
      <ServiceDeliveryFormats
        data={data}
        title="4. Перечислите форматы предоставления услуги в Вашем субъекте Российской Федерации"
      />
      <TableRow>
        <TableCell>
          5. Перечислите категории родителей (законных представителей), в
          отношении которых предусмотрены особые права в части условий получения
          услуги (например, право на получение выездной консультации, право на
          получение услуги в большем объеме в течение года и др.), а также какие
          особые права предусмотрены для них.
        </TableCell>
        <TableCell className={s.paragraphBlock}>
          {data.special_categories_of_legal_representatives}
          <Description
            descriptionTitle="Описание"
            descriptionText={
              data.special_categories_of_legal_representatives_description
            }
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          6. Предусмотрена ли возможность предоставления услуги на платной
          основе? Опишите условия и порядок ее предоставления.
        </TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(
            data.paid_service_possibility
          )}
          <Description
            descriptionTitle="Описание"
            descriptionText={data.paid_service_delivery_description}
          />
        </TableCell>
      </TableRow>
      <PossibleChoice data={data} startOfList={7} />
      <TableRow>
        <TableCell>
          9. Установлена ли максимальная продолжительность ожидания получения
          услуги с момента записи (дней)?
        </TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(
            data.maximum_waiting_time_exists
          )}
          <Description
            descriptionTitle="Сколько дней (дней)"
            descriptionText={data.maximum_waiting_time}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          10. Проводится ли на территории субъекта Российской Федерации
          информационно-разъяснительная деятельность, направленная на доведение
          до родителей (законных представителей) детей информации о возможности
          получения услуги? Опишите реализуемые меры, перечислите информационные
          каналы и перечень вопросов, по которым получатели услуг получают
          информацию.
        </TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(
            data.information_is_being_distributed
          )}
          <Description
            descriptionTitle="Описание"
            descriptionText={data.information_distribution_description}
          />
          <div className={s.files}>
            {data.information_distribution_protocols?.map((document) => {
              return <SimpleFileView document={document} />;
            })}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          11. Проводится ли оценка качества оказываемых услуг? Опишите методику
          и порядок оценки качества услуг
        </TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(
            data.services_quality_is_being_controlled
          )}
          <Description
            descriptionTitle="Методика оценки (коротко)"
            descriptionText={data.services_quality_control_methodology}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          12. Представьте описание лучших практик оказания услуг, в том числе с
          описанием типовых ситуаций и типовых консультаций.
        </TableCell>
        <TableCell className={s.paragraphBlock}>
          {data.best_practices_of_services_provision}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          13. Проблемы, возникающие при использовании методических рекомендаций
          при внедрении новой услуги на территории субъекта Российской Федерации
          (при наличии), а также предложения по дальнейшему использованию
          методических рекомендаций.
        </TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(
            data.problems_in_new_service_introduction_exist
          )}
          <Description
            descriptionTitle="Описание проблем"
            descriptionText={
              data.problems_in_new_service_introduction_description
            }
          />
          <Description
            descriptionTitle="Предложения по дальнейшему использованию методических рекомендаций"
            descriptionText={
              data.problems_in_new_service_introduction_suggestions
            }
          />
        </TableCell>
      </TableRow>
    </SubTable>
  );
};
