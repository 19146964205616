import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as DownloadButtonIcon } from '../../../assets/icons/Download.svg';
import { useClickOutside } from '../../../utils/useClickOutside';
import Loader from '../../Loader/Loader';
import s from './DownloadButton.module.scss';

export function DownloadButton({ loader, onClick, files = [], ...props }) {
  const [menuShowed, changeMenuStatus] = useState(false);
  const buttonRef = useRef(null);
  const popupRef = useRef(null);
  const [position, setPosition] = useState({ left: 0 });

  const hideMenuOnClickOutside = useCallback(() => {
    if (menuShowed) {
      changeMenuStatus(false);
    }
  }, [menuShowed]);

  const handleClickButton = useCallback(() => {
    changeMenuStatus(!menuShowed);
  }, [menuShowed]);

  useClickOutside(hideMenuOnClickOutside, [buttonRef, popupRef]);

  useEffect(() => {
    if (popupRef.current) {
      const position = popupRef.current?.getBoundingClientRect();
      const button = buttonRef.current?.getBoundingClientRect();
      const windowWidth = document.body.clientWidth;
      const windowHeight = document.body.clientHeight;

      let newPosition = {};

      if (windowWidth - button.left < position.width) {
        newPosition.right = 0;
      } else {
        newPosition.left = 0;
      }
      if (position.bottom > windowHeight) {
        newPosition.top = -8 - position.height + 'px';
      }

      setPosition(newPosition);
    }
  }, [menuShowed]);

  return (
    <div className={s.DownloadButton}>
      <Tooltip title={'Загрузить'} aria-label="download">
        <button
          className={classNames(s.DownloadButton__button, {
            [s.DownloadButton__button_active]: menuShowed || loader
          })}
          ref={buttonRef}
          onClick={handleClickButton}
        >
          {loader ? <Loader size={'auto'} /> : <DownloadButtonIcon />}
        </button>
      </Tooltip>
      {menuShowed && (
        <div
          ref={popupRef}
          style={{ ...position }}
          className={s.DownloadButton__files}
        >
          {files.map((file) => (
            <button
              className={s.DownloadButton__file}
              onClick={() => {
                changeMenuStatus(false);
                onClick(file);
              }}
            >
              <div>{file.name}</div>
              <DownloadButtonIcon />
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
