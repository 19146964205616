import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';
import { Autocomplete } from '../../../../../common/infralist/FormFields/Autocomplete/Autocomplete';
import { equipmentStatusService } from '../../../../../store/infralist/equipmentStatusService';
import {
  INFRALIST_STATUSES,
  infralistStatusService
} from '../../../../../store/infralist/infralistStatusService';
import { infralistCheckStatusService } from '../../../../../store/infralist/statuses/infralistCheckStatusService';
import { useEquipmentCheckStatuses } from '../../../../../store/infralist/useEquipmentCheckStatuses';
import { useEquipmentStatuses } from '../../../../../store/infralist/useEquipmentStatuses';
import {
  useInfralistSectionUserAccesses,
  useInfralistUserAccesses
} from '../../../../../store/infralist/useInfralistUserAccesses';
import { localeNumberFormat } from '../../../../../utils/localeNumberFormat';
import { useEquipmentActions } from '../../../direction/section/hooks/useEquipmentActions';
import { CommentInfoLine } from '../../components/CommentInfoLine/CommentInfoLine';
import s from './EquipmentInfo.module.scss';

export const EquipmentInfoStatic = ({
  data,
  equipmentMap,
  infralistId,
  infralistSectionCheckStatus,
  sectionStatus,
  equipmentId,
  canEdit,
  infralistStatus,
  infralistSectionApproveStatus,
  isEditEnabledByEventStatus
}) => {
  const fpoEquipment = equipmentMap.get(data?.fpo_equipment_id);
  const {
    canFetchEquipmentStatuses,
    canFetchEquipmentCheckStatuses
  } = useInfralistUserAccesses();
  const {
    showStatusColumn,
    showStatusSignColumn,
    canSign,
    showApproveColumn,
    showApproveActions,
    canApprove,
    showCheckColumn,
    showCheckActions,
    canCheck
  } = useInfralistSectionUserAccesses({
    sectionStatus,
    infralistSectionCheckStatus,
    infralistStatus,
    infralistSectionApproveStatus,
    isEditEnabledByEventStatus
  });
  const { statusListOptions } = useEquipmentStatuses(canFetchEquipmentStatuses);
  const [
    processingStatusEquipmentId,
    setProcessingStatusEquipmentId
  ] = useState();

  const {
    submitSignEquipment,
    submitApproveEquipment,
    submitCheckEquipment
  } = useEquipmentActions({
    infralistId
  });

  const { checkStatusListOptions } = useEquipmentCheckStatuses(
    canFetchEquipmentCheckStatuses
  );

  // если раздел На согласовании и у оборудования нет статуса, то показываем статус раздела
  // иначе показываем статус согласования оборудования
  const approveStatusText =
    sectionStatus === INFRALIST_STATUSES.ON_APPROVAL &&
    equipmentStatusService.checkIsEmpty(
      data?.equipment_approvement_status?.name
    )
      ? infralistStatusService.getColoredName(sectionStatus)
      : equipmentStatusService.getColoredName(
          data?.equipment_approvement_status?.name
        );

  return (
    <div>
      <CommentInfoLine
        label={'Наименование оборудования (ФПО)'}
        title={fpoEquipment?.label}
        description={fpoEquipment?.description}
      />
      <CommentInfoLine
        label={'Наименование оборудования (РВПО)'}
        title={data?.name_rvpo}
        description={data?.description_rvpo}
      />
      <CommentInfoLine label={'Примерная модель'} title={data?.model_rvpo} />
      <CommentInfoLine
        label={'Количество'}
        title={[data?.quantity, data?.unit].filter(Boolean).join(' ')}
      />
      <CommentInfoLine
        label={'Цена, руб.'}
        title={localeNumberFormat({
          number: data?.price,
          minLength: 2,
          maxLength: 2
        })}
      />
      <CommentInfoLine
        label={'Стоимость, руб.'}
        title={localeNumberFormat({
          number: data?.price * data?.quantity,
          minLength: 2,
          maxLength: 2
        })}
      />
      <CommentInfoLine
        label={'Ссылка на оборудование'}
        title={data?.equipment_external_url?.split('\n').map((link, index) => (
          <a
            href={link ?? '#'}
            target={'_blank'}
            key={`${link + index}`}
            rel={'noopener noreferrer'}
            className={classNames(s.link, s.text)}
          >
            {link}
          </a>
        ))}
      />

      {/* Статус */}
      {showStatusColumn && (
        <CommentInfoLine
          label={'Статус'}
          title={equipmentStatusService.getColoredName(
            data?.equipment_status?.name
          )}
        />
      )}

      {/* Статус подписания */}
      {showStatusSignColumn && (
        <CommentInfoLine
          label={'Статус'}
          title={
            <>
              {!canEdit &&
                equipmentStatusService.getColoredName(
                  data?.equipment_status?.name
                )}
              {canEdit && (
                <Autocomplete
                  name={'Status'}
                  options={statusListOptions}
                  value={data?.equipment_status?.id}
                  onChange={(nextStatusId) => {
                    setProcessingStatusEquipmentId(equipmentId);
                    submitSignEquipment({
                      equipmentId,
                      statusId: nextStatusId
                    }).finally(() => {
                      setProcessingStatusEquipmentId(undefined);
                    });
                  }}
                  placeholder={'Выбрать статус'}
                  disabled={
                    !canSign || equipmentId === processingStatusEquipmentId
                  }
                  searchable={false}
                />
              )}
            </>
          }
        />
      )}

      {/* Статус проверки */}
      {showCheckColumn && (
        <CommentInfoLine
          label={'Результат проверки'}
          title={
            <>
              {!showCheckActions &&
                infralistCheckStatusService.getColoredName(
                  data?.check_status?.name
                )}

              {showCheckActions && (
                <Autocomplete
                  name={'Status'}
                  options={checkStatusListOptions}
                  value={data?.check_status?.id}
                  onChange={(nextStatusId) => {
                    setProcessingStatusEquipmentId(equipmentId);
                    submitCheckEquipment({
                      equipmentId,
                      statusId: nextStatusId
                    }).finally(() => {
                      setProcessingStatusEquipmentId(undefined);
                    });
                  }}
                  placeholder={'Выбрать статус'}
                  disabled={
                    !canEdit ||
                    !canCheck ||
                    equipmentId === processingStatusEquipmentId
                  }
                  searchable={false}
                />
              )}
            </>
          }
        />
      )}

      {/* Статус согласования */}
      {showApproveColumn && (
        <CommentInfoLine
          label={'Статус согласования'}
          title={
            <>
              {!showApproveActions && approveStatusText}

              {showApproveActions && (
                <Autocomplete
                  name={'Status'}
                  options={statusListOptions}
                  value={data?.equipment_approvement_status?.id}
                  onChange={(nextStatusId) => {
                    setProcessingStatusEquipmentId(equipmentId);
                    submitApproveEquipment({
                      equipmentId,
                      statusId: nextStatusId
                    }).finally(() => {
                      setProcessingStatusEquipmentId(undefined);
                    });
                  }}
                  placeholder={'Выбрать статус'}
                  disabled={
                    !canApprove || equipmentId === processingStatusEquipmentId
                  }
                  searchable={false}
                />
              )}
            </>
          }
        />
      )}
    </div>
  );
};

EquipmentInfoStatic.propTypes = {
  data: PropTypes.object
};
