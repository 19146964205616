import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_FORM, QUERY_GET_FORMS } from '../../graphql/queries/forms';

const formsQueryOptions = {
  query: QUERY_GET_FORMS,
  mapData: (r) => r.forms,
  skipQuery: skipIfNull(null)
};

const formQueryOptions = {
  query: QUERY_GET_FORM,
  mapData: (result) => result.form,
  skipQuery: skipIfNull(null)
};

export function useForms(filter, pagination) {
  return useRetriableGraphql({
    queryOptions: formsQueryOptions,
    variables: filter
      ? {
          input: {
            filter,
            pagination
          }
        }
      : null
  });
}

export function useForm(id) {
  return useRetriableGraphql({
    queryOptions: formQueryOptions,
    variables: id
      ? {
          id
        }
      : null
  });
}
