import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { infralistCheckStatusService } from '../../store/infralist/statuses/infralistCheckStatusService';
import { EMDASH } from '../../utils/constants';
import styles from './InfralistStatus.module.scss';

export const InfralistCheckStatus = ({ status = EMDASH, isLoading }) => {
  return (
    <div
      className={classNames(styles.root)}
      style={{
        color: infralistCheckStatusService.getColor(status)
      }}
    >
      <span className={styles.statusPoint} />
      <span className={styles.statusText}>{isLoading ? '...' : status}</span>
    </div>
  );
};

InfralistCheckStatus.propTypes = {
  status: PropTypes.string
};
