import { TextField } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { isValid } from 'date-fns';
import { dateToBackendString } from '../../../utils/data/date';
import { DeleteButton } from '../../../common/DeleteButton';
import { Signal } from '../../../common/Inputs/Signals';
import { validateUrl } from '../../../utils/url';
import { DocumentStatus } from '../../../store/roadmap/documentStatus';
import { makeOptionator } from '../../../utils/data/label';
import { RegionalEvent } from '../../../store/roadmap/RegionalEvent';
import AutocompleteBaseInput from '../../../common/Inputs/Selects/AutocompleteBaseInput';
import SubTable from '../../../common/SubTable/SubTable';
import { DocumentUploadFileStatus } from './DocumentUploadFileStatus';
import { FileEntity } from './DocumentFile';
import s from './EventPage.module.scss';

export const SimpleFileStatusEditable = (props) => {
  const {
    document,
    documentId,
    onCancelUpload,
    onDelete,
    ...otherProps
  } = props;

  return (
    <DocumentUploadFileStatus
      documentId={documentId}
      document={document}
      onDelete={() => onDelete(documentId)}
      onCancelUpload={() => onCancelUpload(documentId)}
      {...otherProps}
    />
  );
};

export const FileWithNumberAndApprovalDateEditable = (props) => {
  const {
    document,
    documentId,
    onCancelUpload,
    onDelete,
    ...otherProps
  } = props;

  const { onDocumentChange, canUploadDocuments } = otherProps;

  const minimalDate = new Date('2000-01-01');
  const [date, setDate] = useState(null);

  return (
    <div className={s.documentBoxStyle}>
      <DocumentUploadFileStatus
        document={document}
        documentId={documentId}
        onDelete={() => onDelete(documentId)}
        onCancelUpload={() => onCancelUpload(documentId)}
        {...otherProps}
      />
      <TextField
        label="Номер документа"
        className={s.textInputStyle}
        fullWidth
        value={document.number || ''}
        onChange={(e) =>
          onDocumentChange(documentId, { number: e.target.value })
        }
        disabled={!canUploadDocuments}
      />
      <KeyboardDatePicker
        clearable
        label="Дата утверждения"
        value={document.date_approval || date}
        onChange={(date) => {
          if (!date) {
            setDate(null);
          } else if (isValid(date)) {
            setDate(date);
            onDocumentChange(documentId, {
              date_approval: dateToBackendString(date)
            });
          }
        }}
        minDate={minimalDate}
        format="dd.MM.yyyy"
        disabled={!canUploadDocuments}
      />
    </div>
  );
};

export const FileWithLicenseEditable = (props) => {
  const {
    document,
    documentId,
    onCancelUpload,
    onDelete,
    onDocumentChange,
    canEditStatus,
    canUploadDocuments,
    disabled,
    entities,
    eventStatus
  } = props;
  const minimalDate = new Date('2000-01-01');
  const [dateApproval, setDateApproval] = useState(null);
  const [licenseExpirationDate, setLicenseExpirationDate] = useState(null);

  const handleSubsidiaryEntityChange = useCallback(
    (value) => onDocumentChange(documentId, { subsidiary_entity_id: value }),
    [documentId, onDocumentChange]
  );

  return (
    <div className={s.documentBoxStyle}>
      <DocumentUploadFileStatus
        document={document}
        documentId={documentId}
        onDelete={() => onDelete(documentId)}
        onCancelUpload={() => onCancelUpload(documentId)}
        canEditStatus={canEditStatus}
        canUploadDocuments={canUploadDocuments}
        onDocumentChange={onDocumentChange}
        eventStatus={eventStatus}
      />
      {entities?.canEditEntities && (
        <FileEntity
          entitiesStatus={entities?.entitiesStatus}
          entitiesSuggestions={entities?.entitiesSuggestions}
          disabled={disabled}
          onChange={handleSubsidiaryEntityChange}
          document={document}
        />
      )}
      <TextField
        label="Номер лицензии"
        className={s.textInputStyle}
        fullWidth
        value={document.number || ''}
        onChange={(e) =>
          onDocumentChange(documentId, { number: e.target.value })
        }
        disabled={!canUploadDocuments}
      />
      <KeyboardDatePicker
        clearable
        label="Срок окончания"
        value={document.license_expiration_date || licenseExpirationDate}
        onChange={(date) => {
          if (!date) {
            setLicenseExpirationDate(null);
          } else if (isValid(date)) {
            setLicenseExpirationDate(date);
            onDocumentChange(documentId, {
              license_expiration_date: dateToBackendString(date)
            });
          }
        }}
        minDate={minimalDate}
        format="dd.MM.yyyy"
        disabled={!canUploadDocuments}
      />
      <KeyboardDatePicker
        clearable
        label="Дата утверждения"
        value={document.date_approval || dateApproval}
        onChange={(date) => {
          if (!date) {
            setDateApproval(null);
          } else if (isValid(date)) {
            setDateApproval(date);
            onDocumentChange(documentId, {
              date_approval: dateToBackendString(date)
            });
          }
        }}
        minDate={minimalDate}
        format="dd.MM.yyyy"
        disabled={!canUploadDocuments}
      />
    </div>
  );
};

export const DocumentLinkEditable = (props) => {
  const {
    document,
    documentId,
    onDelete,
    onDocumentChange,
    canUploadDocuments,
    canEditStatus,
    eventStatus,
    disabled,
    entities
  } = props;

  const error = !validateUrl(document.content) && 'Некорректная ссылка';

  const statusOptions = [
    DocumentStatus.status.NOT_APPROVED,
    DocumentStatus.status.APPROVED
  ].map(makeOptionator(DocumentStatus.getStatusName));

  const fpoError =
    eventStatus === RegionalEvent.Status.NOT_COMPLETED &&
    document.status === DocumentStatus.status.NOT_APPROVED &&
    document.comment_fpo === '';

  const handleSubsidiaryEntityChange = useCallback(
    (value) => onDocumentChange(documentId, { subsidiary_entity_id: value }),
    [documentId, onDocumentChange]
  );

  return (
    <div className={s.documentLinkBoxStyle}>
      <div className={s.linkInputStyle}>
        <TextField
          label="Ссылка"
          className={s.textInputStyle}
          fullWidth
          value={document.content || ''}
          onChange={(e) =>
            onDocumentChange(documentId, { content: e.target.value })
          }
          disabled={!canUploadDocuments}
        />
        {error && <Signal className={s.textInputStyle}>{error}</Signal>}
      </div>
      {entities?.canEditEntities && (
        <FileEntity
          entitiesStatus={entities?.entitiesStatus}
          entitiesSuggestions={entities?.entitiesSuggestions}
          disabled={disabled}
          onChange={handleSubsidiaryEntityChange}
          document={document}
        />
      )}
      {canEditStatus && (
        <>
          <AutocompleteBaseInput
            value={document.status}
            label="Статус"
            suggestions={statusOptions}
            variant="standard"
            onChange={(value) =>
              onDocumentChange(documentId, { status: value })
            }
            hasCopyBtn={false}
            isClearable={false}
          />
          <TextField
            label="Комментарий ФПО"
            className={s.textInputStyle}
            fullWidth
            value={document.comment_fpo || ''}
            onChange={(e) =>
              onDocumentChange(documentId, {
                comment_fpo: e.target.value
              })
            }
            required={fpoError}
            error={fpoError}
            helperText={fpoError && 'Поле должно быть заполнено'}
          />
        </>
      )}
      {canUploadDocuments && (
        <DeleteButton onClick={() => onDelete(documentId)} />
      )}
    </div>
  );
};
