import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_SIMPLE_REGIONS } from '../../graphql/queries/regions';

const simpleRegionsQueryOptions = {
  query: QUERY_GET_SIMPLE_REGIONS,
  mapData: (result) => result.simpleRegions,
  skipQuery: skipIfNull(null)
};

export function useSimpleRegions() {
  return useRetriableGraphqlVars({
    queryOptions: simpleRegionsQueryOptions,
    variableCreator: [() => ({})]
  });
}
