import gql from 'graphql-tag';
import { FRAGMENT_INDICATOR_FORECAST } from '../queries/indicatorForecasts';

export const MUTATION_UPDATE_INDICATOR_FORECAST = gql`
  mutation updateIndicatorForecast($input: IndicatorForecastInputFields!) {
    result: updateIndicatorForecast(input: $input) {
      ...indicatorForecast
    }
  }
  ${FRAGMENT_INDICATOR_FORECAST}
`;
