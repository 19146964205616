import React from 'react';
import { TableInputView } from '../../../common/Table/TableInputView';
import { getPurchaseCompositionColumns } from './edit/PurchaseComponentsField';

export const PurchaseComponents = ({ title, purchaseComponents }) => {
  const columns = getPurchaseCompositionColumns(null);

  return (
    <TableInputView title={title} columns={columns} rows={purchaseComponents} />
  );
};
