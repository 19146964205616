import gql from 'graphql-tag';

export const MUTATION_UPDATE_CONTEST_DOCS_FOR_SS_ENTITY = gql`
  mutation updateTenderDocumentationForSS(
    $input: TenderDocumentationForSSUpdateInput!
  ) {
    updateTenderDocumentationForSS(input: $input) {
      id
      status
    }
  }
`;

export const MUTATION_DELETE_CONTEST_DOCS_FOR_SS_ENTITY = gql`
  mutation deleteTenderDocumentationForSS($id: ID!) {
    deleteTenderDocumentationForSS(id: $id)
  }
`;

export const MUTATION_APPROVE_CONTEST_DOCS_FOR_SS_ENTITY = gql`
  mutation approveTenderDocumentationForSS($id: ID!) {
    approveTenderDocumentationForSS(id: $id) {
      id
      status
    }
  }
`;

export const MUTATION_REQUEST_EDIT_CONTEST_DOCS_FOR_SS_ENTITY = gql`
  mutation requestEditForTenderDocumentationForSS($id: ID!) {
    requestEditForTenderDocumentationForSS(id: $id) {
      id
      status
    }
  }
`;

export const MUTATION_ALLOW_EDIT_CONTEST_DOCS_FOR_SS_ENTITY = gql`
  mutation allowEditForTenderDocumentationForSS($id: ID!) {
    allowEditForTenderDocumentationForSS(id: $id) {
      id
      status
    }
  }
`;

export const MUTATION_REJECT_EDIT_CONTEST_DOCS_FOR_SS_ENTITY = gql`
  mutation rejectEditForTenderDocumentationForSS($id: ID!) {
    rejectEditForTenderDocumentationForSS(id: $id) {
      id
      status
    }
  }
`;

export const MUTATION_CREATE_CONTEST_DOCS_FOR_SS_REPORT_FILE_TOKEN = gql`
  mutation createTenderDocumentationForSSReportOneTimeToken {
    createTenderDocumentationForSSReportOneTimeToken
  }
`;
