import axios from 'axios';
import React, { useCallback, useRef } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { TextField } from '@material-ui/core';
import { apiUrl } from '../../../../config';
import { getAxiosErrorMessage } from '../../../../graphql/graphqlErrors';
import { splitFileName } from '../../../../utils/data/file';
import { useFieldFast } from '../../../../common/InputsNew/useFieldFast';
import { FileInputButton } from '../../../../common/Form/FileInputButton';
import { RegionalEvent } from '../../../../store/roadmap/RegionalEvent';
import SubTable from '../../../../common/SubTable/SubTable';
import { Signal } from '../../../../common/Inputs/Signals';
import { SimpleFileEditable } from './FileEditableComponent';
import s from './DownloadFileInput.module.scss';

let globalDocumentId = 1;

const uploadFile = async (file, currentDocumentId, changeDocument, path) => {
  const formData = new FormData();
  formData.append('file', file, file.name);

  const cancelToken = axios.CancelToken.source();
  try {
    const response = await axios.request({
      method: 'post',
      url: apiUrl + path,
      cancelToken: cancelToken.token,
      data: formData,
      onUploadProgress: (p) => {
        changeDocument(currentDocumentId, (doc) => ({
          ...doc,
          uploadingFile: {
            ...doc.uploadingFile,
            progress: Math.round((p.loaded * 100) / p.total),
            cancel: cancelToken
          }
        }));
      }
    });
    changeDocument(currentDocumentId, (doc) => ({
      ...doc,
      id: response.data,
      connected_file: {
        ...doc.connected_file,
        id: response.data
      },
      uploadingFile: {
        uploading: false,
        uploaded: true,
        progress: 100,
        error: false
      }
    }));
  } catch (error) {
    changeDocument(currentDocumentId, (doc) => ({
      ...doc,
      uploadingFile: {
        ...doc.uploadingFile,
        uploading: false,
        uploaded: false,
        error: error,
        errorMessage: getAxiosErrorMessage(error)
      }
    }));
  }
};

function createNewDocument(file, slot, type, documentProps) {
  const newDocumentId = globalDocumentId++ + '';
  let aborted = false;

  const [name, file_extension] = splitFileName(file.name);

  const newDocument = {
    id: newDocumentId,
    isNew: true,
    name: file.name,
    connected_file: {
      name,
      file_extension,
      file_size: file.size
    },
    uploadingFile: {
      uploading: true,
      uploaded: false,
      progress: 0,
      error: false
    },
    ...documentProps
  };

  return { newDocumentId, aborted, newDocument };
}

export function useDocumentsField({ name }) {
  const [, meta, { setValue }] = useFieldFast(name);
  const documents = meta.value || [];

  const setDocuments = setValue;
  const documentsRef = useRef(documents);
  documentsRef.current = documents;

  const changeDocument = useCallback(
    (id, callback) => {
      const documents = documentsRef.current;
      setDocuments(
        documents.map((doc) => {
          if (doc.id === id) {
            return callback(doc);
          }
          return doc;
        })
      );
    },
    [setDocuments]
  );

  const handleFileChange = useCallback(
    (event, type, slot = undefined, documentProps = undefined) => {
      const files = Object.values(event.target.files).map((file) => {
        let { newDocumentId, aborted, newDocument } = createNewDocument(
          file,
          slot,
          type,
          documentProps
        );

        return {
          file,
          documentId: newDocumentId,
          aborted,
          fileOptions: newDocument
        };
      });

      const documents = documentsRef.current;
      const newDocument = files.map((file) => file.fileOptions);
      setDocuments([...documents, ...newDocument]);

      files.forEach((file) => {
        if (!file.aborted) {
          uploadFile(
            file.file,
            file.documentId,
            changeDocument,
            '/upload/universalDocumentFile'
          );
        }
      });

      // Очищаем инпут, чтобы можно было выбрать тот же самый файл еще раз
      // Иначе onChange не вызовется
      event.target.value = null;
    },
    [changeDocument, setDocuments]
  );

  const handleAddLink = useCallback(
    (type = undefined, slot = undefined) => {
      const newDocumentId = globalDocumentId++ + '';

      const newDocument = {
        id: newDocumentId,
        isNew: true,
        content: '',
        document_slot_id: slot ? slot.id : undefined,
        type
      };

      const documents = documentsRef.current;
      setDocuments([...documents, newDocument]);
    },
    [setDocuments]
  );

  const handleDocumentChange = useCallback(
    (id, changes) => {
      changeDocument(id, (doc) => ({
        ...doc,
        ...changes
      }));
    },
    [changeDocument]
  );

  const handleDocumentDelete = useCallback(
    (id) => {
      const documents = documentsRef.current;
      setDocuments(documents.filter((doc) => doc.id !== id));
    },
    [setDocuments]
  );

  const handleCancelUpload = useCallback(
    (id) => {
      const documents = documentsRef.current;
      const document = documents.find((doc) => doc.id === id);
      document.uploadingFile.cancel.cancel('Загрузка отменена');
      handleDocumentDelete(id);
    },
    [handleDocumentDelete]
  );

  return {
    documents,
    documentsRef,
    changeDocument,
    handleFileChange,
    handleAddLink,
    handleDocumentChange,
    handleDocumentDelete,
    handleCancelUpload
  };
}

export const AssistanceDocumentsEditField = ({
  canEditStatus = true,
  canUploadDocuments = true,
  documents,
  handleFileChange,
  handleDocumentChange,
  handleDocumentDelete,
  handleCancelUpload,
  canEditDescription = true
}) => {
  return documents.map((document) => {
    const documentId = document.id;
    return (
      <TableRow key={documentId}>
        {canEditDescription && (
          <TableCell className={s.cellStyle}>
            <TextField
              label="Наименование документа (дата и номер)"
              className={s.textInputStyle}
              fullWidth
              value={document.description || ''}
              onChange={(e) =>
                handleDocumentChange(documentId, {
                  description: e.target.value
                })
              }
            />
          </TableCell>
        )}
        <TableCell className={s.cellStyle}>
          <SimpleFileEditable
            documentId={documentId}
            document={document}
            onCancelUpload={() => handleCancelUpload(documentId)}
            onDelete={() => handleDocumentDelete(documentId)}
            canEditStatus={canEditStatus}
            canUploadDocuments={true}
            onDocumentChange={handleDocumentChange}
          />
        </TableCell>
      </TableRow>
    );
  });
};

export const UniversalDocumentBase = ({
  name,
  title,
  canEditStatus = true,
  canUploadDocuments = true,
  entities,
  isMulti = true,
  buttonTitle,
  ...rest
}) => {
  const {
    documents,
    handleFileChange,
    handleDocumentChange,
    handleDocumentDelete,
    handleCancelUpload
  } = useDocumentsField({ name });

  return (
    <>
      <AssistanceDocumentsEditField
        name={name}
        entities={entities}
        canEditStatus={canEditStatus}
        canUploadDocuments={canUploadDocuments}
        documents={documents}
        handleFileChange={handleFileChange}
        handleDocumentChange={handleDocumentChange}
        handleDocumentDelete={handleDocumentDelete}
        handleCancelUpload={handleCancelUpload}
        {...rest}
      />
      {(isMulti || documents.length === 0) && (
        <TableRow>
          <TableCell colSpan={2}>
            <div className={s.wrapper}>
              <FileInputButton
                onSelectFile={(e) =>
                  handleFileChange(e, RegionalEvent.DocType.SIMPLE)
                }
              >
                {buttonTitle || 'Загрузить'}
              </FileInputButton>
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export const UniversalDocumentCell = ({
  title,
  errorMessage,
  children,
  ...props
}) => {
  return (
    <SubTable className={s.cellFiles} editVariant>
      <TableCell className={s.question}>{title}</TableCell>
      {errorMessage && <Signal>{errorMessage}</Signal>}
      <UniversalDocumentBase {...props} />
      {children}
    </SubTable>
  );
};

export const UniversalDocumentTable = ({ title, errorMessage, ...props }) => {
  return (
    <SubTable className={s.files}>
      <TableCell className={s.question}>{title}</TableCell>
      {errorMessage && <Signal>{errorMessage}</Signal>}
      <TableCell>
        <UniversalDocumentBase {...props} />
      </TableCell>
    </SubTable>
  );
};

export const UniversalDocumentSimple = ({ title, errorMessage, ...props }) => {
  return (
    <div className={s.files}>
      {errorMessage && <Signal>{errorMessage}</Signal>}
      <UniversalDocumentBase {...props} />
    </div>
  );
};
