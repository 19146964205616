import React, { useMemo } from 'react';
import BreadCrumbs, {
  loadingBreadcrumb
} from '../../../../../common/BreadCrumbs/BreadCrumbs';
import { getRoadmapBreadCrumbs } from '../../RoadmapBreadCrumbs';

export function getAdditionalDataBreadCrumbs({
  projectId,
  projectName,
  roadmapId,
  roadmapName
}) {
  if (projectId) {
    return [
      ...getRoadmapBreadCrumbs({ projectId, projectName }),
      {
        label: loadingBreadcrumb(roadmapName),
        to: `/projects/${projectId}/roadmaps/${roadmapId}`
      },
      {
        label: 'Дополнительные данные',
        to: `/projects/${projectId}/roadmaps/${roadmapId}?tab=additionalData`
      }
    ];
  }

  return [
    ...getRoadmapBreadCrumbs({}),
    {
      label: loadingBreadcrumb(roadmapName),
      to: `/roadmaps/${roadmapId}`
    },
    {
      label: 'Дополнительные данные',
      to: `/roadmaps/${roadmapId}?tab=additionalData`
    }
  ];
}

export const AdditionalDataBreadCrumbs = ({
  project,
  projectId,
  roadmap,
  roadmapId
}) => {
  const breadCrumbsLinks = useMemo(
    () =>
      getAdditionalDataBreadCrumbs({
        projectId,
        projectName: project?.name,
        roadmapId,
        roadmapName: roadmap?.name_full
      }),
    [project, projectId, roadmap, roadmapId]
  );

  return <BreadCrumbs links={breadCrumbsLinks} />;
};
