import { Helmet } from 'react-helmet';
import React from 'react';
import { EMDASH } from '../../utils/constants';
import { joinNonEmpty } from '../../utils/data/label';
import { shortenText } from '../../utils/string';

export const HeadTitle = ({ title, type, entity, tab, pageType }) => {
  const extendedTitle = joinNonEmpty(
    [shortenText(entity), pageType, type],
    ` ${EMDASH} `
  );

  return <Helmet title={title || extendedTitle} />;
};

HeadTitle.PageType = {
  edit: 'Редактирование',
  create: 'Создание'
};
