import React, { useMemo } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { useRoadmap } from '../../../store/roadmap/useRoadmap';
import { useUrlQuery } from '../../../common/useUrlQuery';
import { hideIndicators, hideAdditionalData } from '../../../config';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { User } from '../../../store/role/User';
import { useUrlTabs } from '../../../common/useUrlTabs';
import NotFoundPage from '../../notFound/NotFoundPage';
import { getName } from '../../../utils/data/label';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import { queryLoader } from '../../../common/QueryLoader';
import AdditionalDataListPage from './additionalDataList/AdditionalDataListPage';
import { Indicators } from './indicators/Indicators';
import { RoadmapBreadCrumbs } from './RoadmapBreadCrumbs';
import { Events } from './events/Events';
import { RegionalEvents } from './events/RegionalEvents';
import s from '../../projects/project/ProjectPage.module.scss';

const PageTab = {
  EVENTS: 'events',
  INDICATORS: 'indicators',
  ADDITIONAL_DATA: 'additionalData'
};

const getTabName = (tab) => {
  return tab === 'events'
    ? 'Мероприятия'
    : tab === 'indicators'
    ? 'Индикаторы'
    : '';
};

const RoadmapPage = ({ match, history, location }) => {
  const { projectId, roadmapId } = match.params;
  const [query, changeQuery] = useUrlQuery(location, history);

  const roadmapQuery = useRoadmap(roadmapId);
  const roadmap = roadmapQuery.state.data;
  const roadmapYear = +query.year || roadmap?.roadmap_years[0].year;

  const user = useCurrentUser();
  const availableTabs = useMemo(() => {
    const result = [];

    if (
      User.hasPermission(
        user,
        projectId ? 'regional_event.access_page' : 'roadmap_event.access_page'
      )
    ) {
      result.push({ label: 'Мероприятия', value: PageTab.EVENTS });
    }

    if (User.hasPermission(user, 'indicator.access_page') && !hideIndicators) {
      result.push({
        label: 'Индикаторы',
        value: PageTab.INDICATORS
      });
    }

    if (!hideAdditionalData) {
      result.push({
        label: 'Дополнительные данные',
        value: PageTab.ADDITIONAL_DATA
      });
    }

    return result;
  }, [projectId, user]);

  const [openedTab, setTabsValue] = useUrlTabs(
    availableTabs,
    query,
    changeQuery
  );

  return (
    <>
      <HeadTitle
        type="Дорожная карта"
        entity={getName(roadmap)}
        tab={getTabName(openedTab)}
      />
      <RoadmapBreadCrumbs projectId={projectId} />
      <PageTitle title={getName(roadmap)} withBackBtn />
      <Tabs
        value={openedTab}
        indicatorColor="primary"
        textColor="inherit"
        centered
        className={s.Tabs}
        onChange={(e, value) => setTabsValue(value)}
      >
        {availableTabs.map((tab, iTab) => (
          <Tab key={iTab} {...tab} />
        ))}
      </Tabs>
      {queryLoader(roadmapQuery) ||
        (openedTab === PageTab.EVENTS ? (
          projectId ? (
            <RegionalEvents
              match={match}
              location={location}
              history={history}
              roadmap={roadmap}
              roadmapYear={roadmapYear}
            />
          ) : (
            <Events
              match={match}
              location={location}
              history={history}
              roadmap={roadmap}
              roadmapYear={roadmapYear}
            />
          )
        ) : openedTab === PageTab.INDICATORS ? (
          <Indicators match={match} location={location} history={history} />
        ) : openedTab === PageTab.ADDITIONAL_DATA ? (
          <AdditionalDataListPage
            match={match}
            location={location}
            history={history}
          />
        ) : (
          <NotFoundPage />
        ))}
    </>
  );
};

export default RoadmapPage;
