import { clearApolloQueryCache } from '../../utils/apollo';
import { MUTATION_CREATE_NON_COMMERCIAL_ORGANIZATION } from '../../graphql/mutations/parentalAssistanceGuidelines/nonCommercialOrganization';
import { MUTATION_UPDATE_OR_CREATE_PARENTAL_ASSISTANCE_GUIDELINES_ORGANIZATIONAL } from '../../graphql/mutations/parentalAssistanceGuidelines/parentalAssistanceGuidelinesOrganizational';
import { MUTATION_UPDATE_OR_CREATE_PARENTAL_ASSISTANCE_GUIDELINES_REGIONAL } from '../../graphql/mutations/parentalAssistanceGuidelines/parentalAssistanceGuidelinesRegional';
import { ParentalAssistanceGuideline } from './ParentalAssistanceGuideline';

export class ParentalAssistanceGuidelineStore {
  constructor({ client }) {
    this.client = client;
  }

  queriesToClear = [
    'nonCommercialOrganizations',
    'parentalAssistanceGuidelinesOrganizational',
    'parentalAssistanceGuidelineOrganizational',
    'parentalAssistanceGuidelinesRegional',
    'parentalAssistanceGuidelineRegional'
  ];

  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  createNonCommercialOrganization(data) {
    return this.client.mutate({
      mutation: MUTATION_CREATE_NON_COMMERCIAL_ORGANIZATION,
      variables: {
        input: ParentalAssistanceGuideline.filterCreateNonCommercialOrganizationFields(
          data
        )
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  }

  createParentalAssistanceGuidelinesOrganizational(data) {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_OR_CREATE_PARENTAL_ASSISTANCE_GUIDELINES_ORGANIZATIONAL,
      variables: {
        input: ParentalAssistanceGuideline.filterCreateOrganizationalFields(
          data
        )
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  }

  updateParentalAssistanceGuidelinesOrganizational(data) {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_OR_CREATE_PARENTAL_ASSISTANCE_GUIDELINES_ORGANIZATIONAL,
      variables: {
        input: ParentalAssistanceGuideline.filterUpdateOrganizationalFields(
          data
        )
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  }

  createParentalAssistanceGuidelinesRegional(data) {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_OR_CREATE_PARENTAL_ASSISTANCE_GUIDELINES_REGIONAL,
      variables: {
        input: ParentalAssistanceGuideline.filterCreateRegionalFields(data)
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  }

  updateParentalAssistanceGuidelinesRegional(data) {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_OR_CREATE_PARENTAL_ASSISTANCE_GUIDELINES_REGIONAL,
      variables: {
        input: ParentalAssistanceGuideline.filterUpdateRegionalFields(data)
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  }
}
