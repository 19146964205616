import { groupBy, pick } from 'lodash-es';
import {
  compareDateStrings,
  compareSimple,
  makeComparator
} from '../../utils/sorting';
import { makeOptionator } from '../../utils/data/label';
import { User } from '../role/User';
import { EnumHelpers } from '../../utils/EnumHelpers';
import { DocumentStatus } from './documentStatus';

const responsiblePersonFields = [
  // Поля модели
  'first_name',
  'surname',
  'patronymic',
  'emails',
  'phones',

  // Поля инпута
  'occupation',
  'department'
];

const roadmapEventDocumentFields = [
  'id',
  'subsidiary_entity_id',
  'photo_category_id',
  'document_slot_id',
  'type',
  'name',
  'content',
  'number',
  'license_expiration_date',
  'date_approval',

  // Поля инпута
  'comment_fpo',
  'status',
  'connected_file_id'
];

const regionalRoadmapEventFields = [
  // Поля модели
  'id',
  'annotation',
  'fact_term',
  'region_id',
  'responsible_person',
  'responsible_person_id',
  'roadmap_event_id',
  'comment_fpo',
  'infralist_ids',

  // Поля инпута
  'documents',
  'status',
  'staff_schedules'
];

const federalRoadmapEventFields = [
  'documents',
  'responsible_person',
  'roadmap_id',
  'year',
  'plan_term',
  'event_result'
];

export class RegionalEvent {
  static Status = {
    APPROVAL: 'APPROVAL',
    COMPLETED: 'COMPLETED',
    NOT_COMPLETED: 'NOT_COMPLETED',
    RETURNED: 'RETURNED'
  };

  static status = new EnumHelpers(this.Status, {
    APPROVAL: 'На утверждении',
    COMPLETED: 'Выполнено',
    NOT_COMPLETED: 'Не выполнено',
    RETURNED: 'Возвращено на доработку'
  });

  static getStatusOptions = (user) => {
    let statuses = [
      RegionalEvent.Status.APPROVAL,
      RegionalEvent.Status.COMPLETED,
      RegionalEvent.Status.RETURNED
    ];

    if (User.isAdmin(user)) {
      statuses.push(RegionalEvent.Status.NOT_COMPLETED);
    }

    return statuses.map(makeOptionator(RegionalEvent.status.getName));
  };

  static DocType = {
    // Простой
    SIMPLE: 'SIMPLE',
    // С номером и датой утверждения
    WITH_NUMBER_AND_APPROVAL_DATE: 'WITH_NUMBER_AND_APPROVAL_DATE',
    // Лицензия
    LICENSE: 'LICENSE',
    // Фотография
    PHOTO: 'PHOTO',
    // Ссылка
    LINK: 'LINK'
  };

  static DocTypeReserve = {
    // Простой
    SIMPLE: 'Простой',
    // С номером и датой утверждения
    WITH_NUMBER_AND_APPROVAL_DATE: 'С номером и датой утверждения',
    // Лицензия
    LICENSE: 'Лицензия',
    // Фотография
    PHOTO: 'Фотография',
    // Ссылка
    LINK: 'Ссылка'
  };

  static sortComparator = makeComparator(
    compareDateStrings,
    (s) => s.roadmap_event?.plan_term || null
  );

  static normalizeDocType(type) {
    for (const [key, value] of Object.entries(this.DocType)) {
      if (value === type) {
        return this.DocType[key];
      }
    }
    for (const [key, value] of Object.entries(this.DocTypeReserve)) {
      if (value === type) {
        return this.DocType[key];
      }
    }
    return this.DocType.SIMPLE;
  }

  static filterUpdateRegionalFields(data) {
    return pick(data, ['id', ...regionalRoadmapEventFields]);
  }

  static filterUpdateFederalFields(data) {
    return pick(data, ['id', ...federalRoadmapEventFields]);
  }

  static canEditRegionalField(user) {
    return User.hasPermission(user, 'regional_event.edit');
  }

  static canEditStatus(user) {
    return User.hasPermission(user, 'regional_event.approve');
  }

  static canUploadDocuments(user) {
    return User.hasPermission(user, 'regional_event.edit');
  }

  static canEditFactTerm(user) {
    return User.hasPermission(user, 'regional_event.approve');
  }

  static canEditEvent(user, event) {
    return event.permissions?.edit || event.permissions?.approve;
  }

  static canSeeFederalEvent(user) {
    return User.hasPermission(user, 'roadmap_event.access_page');
  }

  static fromForm(
    {
      roadmap_event_documents,
      responsible_person,
      staff_schedules,
      infralists,
      ...fields
    },
    { eventId, regionId, regionalId },
    user
  ) {
    const infralistIds = infralists?.map((i) => i.id) || undefined;

    console.log('infralists: ', infralists);
    console.log('infralistIds: ', infralistIds);

    const convertedDocuments = (roadmap_event_documents || [])
      .filter((document) => document.connected_file?.id || document.content)
      .map((document) => ({
        comment_fpo: '',
        status: DocumentStatus.status.NOT_APPROVED,
        ...pick(document, roadmapEventDocumentFields),
        connected_file_id: document.connected_file?.id || undefined
      }));

    const responsiblePerson = responsible_person && {
      ...pick(responsible_person, responsiblePersonFields),
      occupation: responsible_person.individual_data?.occupation || '',
      department: responsible_person.individual_data?.department || ''
    };

    const values = Object.values(staff_schedules || {});
    const staffSchedulesUpLevel = values.reduce((acc, cur) => {
      return acc.concat(cur);
    }, []);
    const staffSchedules = staffSchedulesUpLevel.map(
      ({
        subsidiary_entity_id,
        staff_schedule_guideline_id,
        occupation,
        amount_of_staff
      }) => ({
        subsidiary_entity_id,
        staff_schedule_guideline_id,
        occupation,
        amount_of_staff: +amount_of_staff,
        year: fields.year
      })
    );

    const result = {
      ...fields,
      id: fields.id || regionalId,
      infralist_ids: infralistIds,
      responsible_person: responsiblePerson,
      documents: convertedDocuments,
      region_id: regionId,
      roadmap_event_id: eventId,
      staff_schedules: staffSchedules.length !== 0 ? staffSchedules : undefined
    };

    if (!RegionalEvent.canEditFactTerm(user)) {
      delete result.fact_term;
    }

    return result;
  }

  static toForm({ staff_schedules, ...regionalEvent }, event) {
    const staffSchedules = staff_schedules.map((staff_schedule) => ({
      ...staff_schedule,
      amount_of_staff: staff_schedule.amount_of_staff + ''
    }));

    staffSchedules.sort(
      (a, b) =>
        +a.stuff_schedule_guideline.number - +b.stuff_schedule_guideline.number
    );

    const grouped = groupBy(
      staffSchedules,
      (staff_schedule) => staff_schedule.subsidiary_entity_id
    );

    return {
      ...regionalEvent,
      staff_schedules: grouped,
      year: event.year
    };
  }

  static getUrl(regionalEvent, regionalProjectId) {
    if (regionalProjectId) {
      return (
        `/projects/${regionalProjectId}` +
        `/roadmaps/${regionalEvent.roadmap_event.roadmap_id}` +
        `/events/${regionalEvent.roadmap_event.id}`
      );
    }
    return (
      `/roadmaps/${regionalEvent.roadmap_event.roadmap_id}` +
      `/events/${regionalEvent.roadmap_event.id}` +
      `/regions/${regionalEvent.id}`
    );
  }

  static getSlots(event) {
    const documentSlots = (event?.roadmap_event_document_slots || [])
      .slice()
      .sort(makeComparator(compareSimple, (slot) => slot.code));

    // впринципе это поле не нужно, т.к. если есть слоты, то они должны отображаться
    // в базе заполнено плохо, поэтому здесь true
    const showSlots = event.display_document_slots || true;

    const federalSlots = documentSlots.filter((slot) => slot.federal);
    const regionalSlots = documentSlots.filter((slot) => !slot.federal);

    return { showSlots, federalSlots, regionalSlots };
  }
}
