import { pick } from 'lodash-es';
import { EnumHelpers } from '../../utils/EnumHelpers';
import { toFloat } from '../../utils/number';

export class MentoringCoverage {
  static mentoringCoverageFields = ['percentage', 'comment'];

  static Status = {
    DRAFT: 'DRAFT',
    APPROVED: 'APPROVED'
  };
  static status = new EnumHelpers(this.Status, {
    DRAFT: 'Черновик',
    APPROVED: 'Утверждено'
  });

  static toMentoringCoverageForm = (data) => ({ ...data });

  static fromMentoringCoverageForm = ({ percentage, ...data }) => {
    return {
      percentage: toFloat(percentage),
      ...data
    };
  };

  static mentoringCoverageUpdateFields(data) {
    return pick(data, ['id', ...this.mentoringCoverageFields]);
  }

  static mentoringCoverageCreateFields(data) {
    return pick(data, ['region_id', ...this.mentoringCoverageFields]);
  }

  static getMentoringCoverageUrl = (id) => `/mentoringCoverage/${id}`;
}
