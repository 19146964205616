import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useState } from 'react';
import { MUTATION_INFRALIST_NOT_SIGN_ALL } from '../../../graphql/mutations/infralist';
import { INFRALIST_QUERIES } from '../infralistQueries';
import { useDialog } from '../../../utils/hooks/useDialog';
import { showGraphqlErrorToast } from '../../../graphql/graphqlErrors';
import { clearApolloQueryCache } from '../../../utils/apollo';

export const useNotSignAll = (infralistId) => {
  const client = useContextApolloClient();
  const [loading, setLoading] = useState(false);
  const { open, isOpen, close } = useDialog();

  const notSignAll = () => {
    setLoading(true);
    clearApolloQueryCache(client.store.getCache(), [
      INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT
    ]);
    return client
      .mutate({
        mutation: MUTATION_INFRALIST_NOT_SIGN_ALL,
        variables: {
          infralist_id: infralistId
        },
        refetchQueries: [INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT]
      })
      .catch(showGraphqlErrorToast)
      .finally(() => {
        setLoading(false);
        open();
      });
  };

  return {
    notSignAll,
    notSignAllLoading: loading,
    isNotSignAllPopupVisible: isOpen,
    closeNotSignAllPopup: close
  };
};
