import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

export const DeleteButton = React.memo(
  ({ onClick, size = 'small', ...props }) => (
    <IconButton aria-label="delete" onClick={onClick} {...props}>
      <DeleteIcon fontSize={size} />
    </IconButton>
  )
);
