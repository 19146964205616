import React, { useMemo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import { groupBy } from 'lodash-es';
import { useSubsidiaryEntities } from '../../../../store/subsidiaryEntity/useSubsidiaryEntities';
import { queryLoader } from '../../../../common/QueryLoader';
import { useSeTypes } from '../../../../store/subsidiaryEntity/useSeTypes';
import { useRoadmapEventPhotoQueryOptions } from '../../../../store/roadmap/useRoadmapEventDocuments';
import { useArchiveDownloadingFile } from '../../downloadArchiveFile';
import SubTable from '../../../../common/SubTable/SubTable';
import { getName } from '../../../../utils/data/label';
import { enableArchiveDownload } from '../../../../config';
import { SubsidiaryEntityPhotos } from './SubsidiaryEntityPhotos';
import s from './EventPhotos.module.scss';

export function EventPhotos({
  event,
  regionalEvent,
  documents,
  title = 'Фотографии',
  editProps
}) {
  const approvedPhotosQuery = useRoadmapEventPhotoQueryOptions(
    event.roadmap_id,
    regionalEvent?.region?.id
  );
  const approvedPhotos = approvedPhotosQuery.state.data;

  const regionalDocuments = documents || regionalEvent.roadmap_event_documents;
  const seTypesQuery = useSeTypes({
    roadmap_ids: event.roadmap_id
  });
  const seTypes = seTypesQuery.state.data;

  const entitiesQuery = useSubsidiaryEntities(
    regionalEvent.region && {
      owner_roadmap_ids: event.roadmap_id,
      region_ids: regionalEvent.region.id,
      year: event.year + ''
    }
  );
  const entities = entitiesQuery.state.data?.list;

  const entitiesOtherDocuments = useMemo(() => {
    const otherDocuments =
      approvedPhotos?.filter((photo) => photo.event_id !== regionalEvent.id) ||
      [];
    return groupBy(
      otherDocuments || [],
      (doc) => doc.subsidiary_entity_id || null
    );
  }, [approvedPhotos, regionalEvent.id]);

  const entitiesDocuments = useMemo(
    () =>
      groupBy(
        regionalDocuments || [],
        (doc) => doc.subsidiary_entity_id || null
      ),
    [regionalDocuments]
  );

  const { handleArchiveDownload, isDownloading } = useArchiveDownloadingFile(
    regionalEvent.id,
    getName(regionalEvent)
  );

  return (
    <div className={s.Panel}>
      <div className={s.Panel__title}>{title}</div>
      <div className={s.EventPhotos__entities}>
        {queryLoader(entitiesQuery) ||
          queryLoader(seTypesQuery) ||
          queryLoader(approvedPhotosQuery) ||
          (entities.length === 0 && (
            <div className={s.EventPhotos__noEntities}>
              Нет субсидиарных сущностей
            </div>
          )) ||
          entities.map((entity, iEntity) => {
            return (
              <SubsidiaryEntityPhotos
                key={iEntity}
                entity={entity}
                seTypes={seTypes}
                documents={entitiesDocuments[entity.id]}
                otherDocuments={entitiesOtherDocuments[entity.id]}
                editProps={editProps}
                eventId={event.id}
                regionEventId={regionalEvent.id}
              />
            );
          })}
        {entitiesDocuments[null] &&
          (queryLoader(seTypesQuery) || queryLoader(approvedPhotosQuery) || (
            <SubsidiaryEntityPhotos
              seTypes={seTypes}
              documents={entitiesDocuments[null]}
              otherDocuments={entitiesOtherDocuments[null]}
              editProps={editProps}
              eventId={event.id}
              regionEventId={regionalEvent.id}
            />
          ))}
      </div>
      {enableArchiveDownload && !editProps && (
        <SubTable>
          <TableRow>
            <TableCell className={s.cellStyle}>
              Скачивание документов мероприятия
            </TableCell>
            <TableCell>
              <div className={s.downloadButton}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={s.downloadLinkButton}
                  onClick={handleArchiveDownload}
                  disabled={isDownloading}
                >
                  {isDownloading ? 'Скачивание' : 'Скачать архивом'}
                </Button>
              </div>
            </TableCell>
          </TableRow>
        </SubTable>
      )}
    </div>
  );
}
