import gql from 'graphql-tag';

export const QUERY_GET_PERMISSIONS = gql`
  query permissions {
    permissions {
      id
      name
      type
    }
  }
`;
