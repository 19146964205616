import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useEffect, useState } from 'react';
import { GET_INFRALIST_NOTIFICATIONS } from '../../../graphql/queries/notifications';

export function useInfralistNotifications({
  pagination,
  infralistId,
  filter,
  setCount
}) {
  const client = useContextApolloClient();
  const [notifications, setNotifications] = useState([]);
  const [header, setHeader] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const setRead = (id) => {
    const index = notifications.findIndex((item) => id === item.id);
    if (!notifications[index].read) {
      setCount((prev) => prev - 1);
    }
    notifications[index].read = true;
    setNotifications([...notifications]);
  };

  useEffect(() => {
    setIsLoading(true);
    client
      .query({
        query: GET_INFRALIST_NOTIFICATIONS,
        variables: {
          input: {
            pagination,
            filter
          }
        },
        fetchPolicy: 'network-only'
      })
      .then((result) => {
        if (result?.data?.incomingNotifications?.header?.page === 0) {
          setNotifications(result.data.incomingNotifications?.list);
        } else {
          setNotifications((prev) =>
            prev.concat(result.data.incomingNotifications?.list)
          );
        }
        setHeader(result?.data?.incomingNotifications?.header);
      })
      .finally(() => setIsLoading(false));
  }, [client, pagination, infralistId, filter]);

  return {
    notifications,
    header,
    setRead,
    isLoading
  };
}
