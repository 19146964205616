import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useContextStore } from '@proscom/prostore-react';
import { useRpImplementationForecast } from '../../../store/rpImplementationForecast/useRpImplementationForecast';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { getName } from '../../../utils/data/label';
import { queryLoaderWithExistenceCheck } from '../../../common/QueryLoader';
import { PageActionsBar } from '../../../common/PageActionsBar/PageActionsBar';
import { EditButton } from '../../../common/Form/EditButton/EditButton';
import SubTable from '../../../common/SubTable/SubTable';
import { DateCell } from '../../../common/TableCells';
import { RpImplementationForecast } from '../../../store/rpImplementationForecast/RpImplementationForecast';
import { ActionButton } from '../../../common/Form/ActionButton';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../graphql/graphqlErrors';
import { RP_IMPLEMENTATION_FORECAST_STORE } from '../../../store/names';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { Signal } from '../../../common/Inputs/Signals';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import { PassportForecasts } from './forecastsTables/PassportForecasts';
import { AgreementForecasts } from './forecastsTables/AgreementForecasts';
import { RoadmapForecasts } from './forecastsTables/RoadmapForecasts';
import { SubsidyForecasts } from './forecastsTables/SubsidyForecasts';
import { IndicatorForecasts } from './forecastsTables/IndicatorForecasts';
import { ResultForecasts } from './forecastsTables/ResultForecasts';
import s from './RpImplementationForecastPage.module.scss';

const title = 'Прогноз Реализации РП';

export default function RpImplementationForecastPage({ match }) {
  const rpImplementationForecastStore = useContextStore(
    RP_IMPLEMENTATION_FORECAST_STORE
  );

  const { rpImplementationForecastId } = match.params;

  const rpImplementationForecastQuery = useRpImplementationForecast(
    {
      id: rpImplementationForecastId
    },
    true
  );
  const rpImplementationForecast = rpImplementationForecastQuery.state.data;

  const user = useCurrentUser();
  const canEdit = RpImplementationForecast.canEdit(
    rpImplementationForecast,
    user
  );
  const canApprove = RpImplementationForecast.canApprove(
    rpImplementationForecast,
    user
  );

  return (
    <>
      <HeadTitle title={title} />
      <PageTitle title={title} />
      <Signal className={s.warning}>
        Внимание! Работа в форме (заполнение, сохранение, утверждение данных)
        должна производиться в одной открытой вкладке браузера одним
        пользователем. Одновременная работа нескольких сессий может привести к
        перезаписи данных, которые были заполнены в другой вкладке или сохранены
        другим пользователем.
      </Signal>
      {queryLoaderWithExistenceCheck(rpImplementationForecastQuery) || (
        <>
          <PageActionsBar
            editButton={
              canEdit && <EditButton editLink={`${match.url}/edit`} />
            }
            actions={
              canApprove && (
                <ActionButton
                  onClick={() =>
                    rpImplementationForecastStore
                      .approve(rpImplementationForecast.id)
                      .catch((error) => {
                        showGraphqlErrorToast(
                          error,
                          mutationErrors.updateObject
                        );
                      })
                  }
                  title="Утвердить"
                />
              )
            }
          />
          <SubTable title="Общие сведения">
            <TableRow>
              <TableCell>Регион</TableCell>
              <TableCell>{getName(rpImplementationForecast.region)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Статус</TableCell>
              <TableCell>
                {RpImplementationForecast.status.getName(
                  rpImplementationForecast.status
                )}
              </TableCell>
            </TableRow>
          </SubTable>
          <IndicatorForecasts
            indicatorForecasts={rpImplementationForecast.indicator_forecasts}
            regionId={rpImplementationForecast.region_id}
          />
          <ResultForecasts
            resultForecasts={rpImplementationForecast.result_forecasts}
          />
          <SubsidyForecasts
            subsidyForecasts={rpImplementationForecast.subsidy_forecasts}
            region_id={rpImplementationForecast.region_id}
          />
          <AgreementForecasts
            agreementForecasts={rpImplementationForecast.agreement_forecasts}
          />
          <PassportForecasts
            passportForecasts={rpImplementationForecast.passport_forecasts}
          />
          <RoadmapForecasts
            roadmapForecasts={rpImplementationForecast.roadmap_forecasts}
          />
          <SubTable>
            <TableRow>
              <TableCell>Дата утверждения</TableCell>
              <DateCell value={rpImplementationForecast.approved_at} />
            </TableRow>
            <TableRow>
              <TableCell>Дата обновления</TableCell>
              <DateCell value={rpImplementationForecast.updated_at} />
            </TableRow>
            <TableRow>
              <TableCell>Версия</TableCell>
              <TableCell>{rpImplementationForecast.version}</TableCell>
            </TableRow>
          </SubTable>
        </>
      )}
    </>
  );
}
