import { useMemo } from 'react';
import { getFullLabel, getName, makeOptionator } from './data/label';

export function useMemoSuggestions(data, labelFn = getName) {
  return useMemo(() => {
    if (data) {
      return data.map(makeOptionator(labelFn, (item) => item.id));
    }
    return null;
  }, [data, labelFn]);
}

export function useMemoSuggestionsFull(data) {
  return useMemoSuggestions(data, getFullLabel);
}
