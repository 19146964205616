import gql from 'graphql-tag';

export const QUERY_GET_INTERNET_SPEED_IN_NODES = gql`
  query internetSpeedInNodes($filter: InternetSpeedInNodeFilter) {
    internetSpeedInNodes(filter: $filter) {
      computer_number
      download_speed
      date
      organization_id
      upload_speed
    }
  }
`;
