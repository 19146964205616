import url from 'url';
import { parse as parseQuery, stringify as formatQuery } from 'query-string';

export function createPreservedLinkBase(
  link,
  query,
  filteredQuery = {},
  exclude = []
) {
  const linkParsed = url.parse(link);
  const queryObj = {
    ...filteredQuery,
    ...parseQuery(linkParsed.search),
    ...query
  };
  for (const key of exclude) {
    delete queryObj[key];
  }
  const newQuery = formatQuery(queryObj);
  return {
    ...linkParsed,
    query: newQuery,
    search: newQuery && '?' + newQuery
  };
}

export function createPreservedUrl(...args) {
  return url.format(createPreservedLinkBase(...args));
}
