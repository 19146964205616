import { StatusService } from '../../../utils/StatusService';
import colors from '../../../colors.scss';

// статусы проверки инфралиста
export const INFRALIST_CHECK_STATUSES = {
  CHECKED_SUCCESS: 'Замечаний нет',
  CHECKED_WITH_COMMENTS: 'Есть замечания',
  NOT_CHECKED: 'Не проверялось'
};

export const infralistCheckStatusService = new StatusService({
  colors: {
    [INFRALIST_CHECK_STATUSES.CHECKED_SUCCESS]: colors.greenBright,
    [INFRALIST_CHECK_STATUSES.CHECKED_WITH_COMMENTS]: colors.yellowLight
  },
  emptyStatus: INFRALIST_CHECK_STATUSES.NOT_CHECKED
});
