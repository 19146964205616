import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SubTable from '../../../common/SubTable/SubTable';
import { SimpleFileView } from '../common/FileUpload/FileEditableComponent';
import { Description } from '../common/Description';

export const FilesOrganizationalSection = ({ data }) => {
  return (
    <SubTable title="I. Нормативно - правовое регулирование порядка оказания услуг">
      <TableRow>
        <TableCell>
          1. Перечень локальных нормативных актов, регламентирующих порядок
          предоставления услуги. Представьте копии указанных нормативных актов.
        </TableCell>
        <TableCell>
          {data.list_of_local_service_regulations?.map((document) => {
            return <SimpleFileView document={document} />;
          })}
          <Description
            descriptionTitle="Комментарий (при наличии)"
            descriptionText={data.list_of_local_service_regulations_description}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          2. Перечень региональных и (или) муниципальных нормативных актов,
          регламентирующих порядок предоставления услуги на территории
          осуществления деятельности организации/Службы (при наличии).
          Представьте копии указанных нормативных актов.
        </TableCell>
        <TableCell>
          {data.list_of_regional_service_regulations?.map((document) => {
            return <SimpleFileView document={document} />;
          })}
          <Description
            descriptionTitle="Комментарий (при наличии)"
            descriptionText={
              data.list_of_regional_service_regulations_description
            }
          />
        </TableCell>
      </TableRow>
    </SubTable>
  );
};
