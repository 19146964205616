import { useCallback } from 'react';
import { parse as parseQuery, stringify as stringifyQuery } from 'query-string';
import { useValueRef } from './useValueRef';

export function useUrlQuery(location, history) {
  const query = parseQuery(location.search);
  const ref = useValueRef({ location, query });

  const changeQuery = useCallback(
    (changes, replace = false) => {
      const newLocation = {
        ...ref.current.location,
        search: stringifyQuery({
          ...ref.current.query,
          ...changes
        })
      };

      if (replace) {
        history.replace(newLocation);
      } else {
        history.push(newLocation);
      }
    },
    [ref, history]
  );

  return [query, changeQuery];
}
