import React from 'react';
import BreadCrumbs from '../../../common/BreadCrumbs/BreadCrumbs';

export function getSubsidiaryEntityBreadCrumbs() {
  return [
    {
      label: 'Реестр субсидиарных сущностей',
      to: '/subsidiaryEntities'
    }
  ];
}

export const SubsidiaryEntityBreadCrumbs = () => {
  const breadCrumbsLinks = getSubsidiaryEntityBreadCrumbs();

  return <BreadCrumbs links={breadCrumbsLinks} />;
};
