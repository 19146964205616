import { useCallback } from 'react';
import { omit } from 'lodash-es';
import { showGraphqlErrorToast } from '../../../../../graphql/graphqlErrors';
import { useEquipmentMutations } from '../../../../../store/infralist/useEquipmentMutations';

export const useEquipmentActions = ({
  onItemUpdate = () => {},
  onItemDelete = () => {},
  infralistId
}) => {
  const {
    deleteEquipment,
    editEquipment,
    signEquipment,
    checkEquipment,
    approveEquipment
  } = useEquipmentMutations();

  const submitDeleteEquipment = (equipmentIdToDelete) => {
    return deleteEquipment(equipmentIdToDelete)
      .then(() => {
        onItemDelete(equipmentIdToDelete);
      })
      .catch(showGraphqlErrorToast);
  };

  const submitEditEquipment = (data) => {
    // обновляем поля кроме статусов
    const payload = omit(data, [
      'checked_user_id',
      'equipment_approvement_status',
      'check_status',
      'equipment_status'
    ]);
    return editEquipment(payload).then(() => {
      onItemUpdate(payload);
    });
  };

  const submitSignEquipment = useCallback(
    ({ equipmentId, statusId }) => {
      return signEquipment({ statusId, equipmentId, infralistId }).then(
        (response) => {
          const updatedEquipment = response.data.signingRvpoEquipment;
          onItemUpdate(updatedEquipment);
        }
      );
    },
    [infralistId, onItemUpdate, signEquipment]
  );

  const submitCheckEquipment = useCallback(
    ({ equipmentId, statusId }) => {
      return checkEquipment({ statusId, equipmentId }).then((response) => {
        const updatedEquipment = response.data.checkRvpoEquipmentById;
        onItemUpdate(updatedEquipment);
      });
    },
    [checkEquipment, onItemUpdate]
  );

  const submitApproveEquipment = useCallback(
    ({ equipmentId, statusId }) => {
      return approveEquipment({ statusId, equipmentId }).then((response) => {
        const updatedEquipment = response.data.setRvpoEquipmentApproveStatus;
        onItemUpdate(updatedEquipment);
      });
    },
    [approveEquipment, onItemUpdate]
  );

  return {
    submitDeleteEquipment,
    submitEditEquipment,
    submitSignEquipment,
    submitCheckEquipment,
    submitApproveEquipment
  };
};
