import { useState } from 'react';

export const useInfralistEquipmentFilter = (setCurrentPage) => {
  const [statusesFilter, setStatusesFilter] = useState(null);
  const [checkStatusesFilter, setCheckStatusesFilter] = useState(null);
  const [approvementStatusesFilter, setApprovementStatusesFilter] = useState(
    null
  );

  return {
    statusesFilter,
    checkStatusesFilter,
    approvementStatusesFilter,
    onSetApprovementStatusesFilter: (value) => {
      setApprovementStatusesFilter(value);
      setCurrentPage(0);
    },
    onSetStatusesFilter: (value) => {
      setStatusesFilter(value);
      setCurrentPage(0);
    },
    onSetCheckStatusesFilter: (value) => {
      setCheckStatusesFilter(value);
      setCurrentPage(0);
    }
  };
};
