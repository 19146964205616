import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, ToastContainer } from 'react-toastify';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import s from './Toast.module.scss';

const CloseButton = () => (
  <IconButton color="inherit">
    <CloseIcon />
  </IconButton>
);

const Toast = () => {
  return (
    <ToastContainer
      autoClose={5000}
      closeButton={<CloseButton />}
      hideProgressBar={true}
      position="bottom-center"
      toastClassName={s.Toast}
      transition={Slide}
    />
  );
};

export default Toast;
