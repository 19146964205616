import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_ROADMAP } from '../../graphql/queries/roadmapEvents';

const roadmapQueryOptions = {
  query: QUERY_GET_ROADMAP,
  mapData: (result) => {
    const roadmap_years = result.roadmap?.roadmap_years.sort(
      (a, b) => a.year - b.year
    );

    return {
      ...result.roadmap,
      roadmap_years
    };
  },
  skipQuery: skipIfNull(null)
};

export function useRoadmap(requestData) {
  return useRetriableGraphqlVars({
    queryOptions: roadmapQueryOptions,
    variableCreator: [
      () =>
        requestData
          ? {
              id: requestData
            }
          : null,
      [requestData]
    ]
  });
}
