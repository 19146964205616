import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { Indicator } from '../../../../../../store/indicator/Indicator';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../../../graphql/graphqlErrors';
import { IndicatorEditForm } from '../edit/IndicatorEditForm';
import { removeLastPathItem } from '../../../../../../utils/url';
import { INDICATOR_STORE } from '../../../../../../store/names';
import { useRegionalProject } from '../../../../../../store/regionalProject/useRegionalProject';
import { useUrlQuery } from '../../../../../../common/useUrlQuery';
import { queryLoader } from '../../../../../../common/QueryLoader';
import { indicatorValidationSchema } from '../../../../../../utils/data/validation';
import { useCurrentUser } from '../../../../../../store/useCurrentUser';
import { IndicatorBreadCrumbs } from '../IndicatorBreadCrumbs';
import { FORM_STATUS_SUBMITTED } from '../../../../../../utils/data/form';
import { HeadTitle } from '../../../../../../common/HeadTitle/HeadTitle';

export default function IndicatorCreatePage({ match, location, history }) {
  const indicatorStore = useContextStore(INDICATOR_STORE);
  const backLink = removeLastPathItem(match.url);
  const [query] = useUrlQuery(location, history);

  const { projectId, roadmapId } = match.params;
  const { indicatorId, subsidiaryEntityId, year, month } = query;

  const projectQuery = useRegionalProject(projectId);
  const project = useMemo(() => {
    if (!projectQuery.state.data) return {};
    return projectQuery.state.data;
  }, [projectQuery.state.data]);

  const initialValue = {
    year: +year,
    month: +month,
    name_full: '',
    owner_roadmap_indicator_id: indicatorId,
    subsidiary_entity_id: subsidiaryEntityId,
    region_id: project?.region_id,
    status: 'NOT_TRANSFERRED',
    comment_fpo: '',
    value_fact: '',
    value_from_tender_documentation: ''
  };

  const user = useCurrentUser();
  const validationSchema = useMemo(
    () => indicatorValidationSchema(initialValue, user),
    [initialValue, user]
  );

  return (
    <>
      <HeadTitle type="Индикатор" pageType={HeadTitle.PageType.create} />
      <IndicatorBreadCrumbs
        projectId={projectId}
        project={project}
        roadmapId={roadmapId}
      />
      <h2>Создание индикатора</h2>
      {queryLoader(projectQuery) || (
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValue}
          onSubmit={(values, actions) => {
            const indicator = Indicator.fromForm(values);
            indicatorStore
              .createIndicator(indicator)
              .then((result) => {
                actions.setStatus(FORM_STATUS_SUBMITTED);
                setTimeout(() => {
                  history.push(
                    Indicator.getUrl(result.data.result, projectId, roadmapId)
                  );
                }, 0);
              })
              .catch((error) => {
                showGraphqlErrorToast(error, mutationErrors.updateObject);
              })
              .then(() => {
                actions.setSubmitting(false);
              });
          }}
        >
          {(formikProps) => (
            <IndicatorEditForm
              {...formikProps}
              onBackClick={() => history.goBack(backLink)}
              project={project}
              match={match}
            />
          )}
        </Formik>
      )}
    </>
  );
}
