import React from 'react';
import PropTypes from 'prop-types';
import s from './Inputs.module.scss';

const InputGroup = ({ children, title }) => {
  return (
    <div className={s.InputGroup}>
      {title && <h4 className={s.InputGroupTitle}>{title}</h4>}
      {children}
    </div>
  );
};

InputGroup.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

export default InputGroup;
