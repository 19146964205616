import { pick } from 'lodash-es';
import { getName } from '../../utils/data/label';

export class FormDocument {
  static documentFields = ['form_id', 'data'];

  static filterUpdateFields(data) {
    return pick(data, ['id', ...this.documentFields]);
  }

  static filterCreateFields(data) {
    return pick(data, this.documentFields);
  }

  static getName(document, form) {
    if (!document) {
      return null;
    }

    const { data } = document;
    const name = getName(data);
    if (name) {
      return name;
    }

    if (data.naimenovanie) {
      return data.naimenovanie;
    }

    return null;
  }

  static toForm(document) {
    return {
      ...document
    };
  }

  static fromForm(document, params) {
    return {
      ...document,
      data: JSON.stringify(document.data),
      ...params
    };
  }

  static getUrl(document) {
    return `/fillForms/${document.form_id}/documents/${document.id}`;
  }
}
