import { skipIfNull } from '@proscom/prostore';

import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import {
  QUERY_GET_ROADMAP_EVENTS,
  QUERY_GET_ROADMAP_EVENTS_FOR_FILTER
} from '../../graphql/queries/roadmapEvents';

const roadmapFederalEventsQueryOptions = {
  query: QUERY_GET_ROADMAP_EVENTS,
  mapData: (result) => result.roadmapEventsPage.list,
  skipQuery: skipIfNull(null)
};

const roadmapFederalEventsForFilterQueryOptions = {
  query: QUERY_GET_ROADMAP_EVENTS_FOR_FILTER,
  mapData: (result) => result.roadmapEventsPage.list,
  skipQuery: skipIfNull(null)
};

export function useRoadmapFederalEvents(filter, sorting) {
  return useRetriableGraphqlVars({
    queryOptions: roadmapFederalEventsQueryOptions,
    variableCreator: [
      () => ({
        filter,
        sorting
      }),
      [filter, sorting]
    ]
  });
}

export function useRoadmapFederalEventsForFilter(roadmapId, year) {
  return useRetriableGraphqlVars({
    queryOptions: roadmapFederalEventsForFilterQueryOptions,
    variableCreator: [
      () => ({
        roadmap_id: roadmapId,
        year: year
      }),
      [roadmapId, year]
    ]
  });
}
