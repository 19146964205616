import { skipIfNull } from '@proscom/prostore';
import { useGraphqlWatchQueryVars } from '@proscom/prostore-apollo-react';
import {
  QUERY_GET_PURCHASE,
  QUERY_GET_PURCHASE_WITH_SIGNALS
} from '../../graphql/queries/purchases';

const purchaseQueryOptions = {
  query: QUERY_GET_PURCHASE,
  mapData: (result) => result.purchase,
  skipQuery: skipIfNull(null)
};

export function usePurchase(id, networkOnly = false) {
  return useGraphqlWatchQueryVars({
    queryOptions: purchaseQueryOptions,
    variableCreator: [
      () => ({
        id
      }),
      [id]
    ],
    options: {
      apolloOptions: {
        fetchPolicy: networkOnly ? 'network-only' : 'cache-first'
      }
    }
  });
}

const purchaseWithSignalsQueryOptions = {
  query: QUERY_GET_PURCHASE_WITH_SIGNALS,
  skipQuery: skipIfNull(null)
};

export function usePurchaseWithSignals(id, networkOnly = false) {
  return useGraphqlWatchQueryVars({
    queryOptions: purchaseWithSignalsQueryOptions,
    variableCreator: [
      () => ({
        id
      }),
      [id]
    ],
    options: {
      apolloOptions: {
        fetchPolicy: networkOnly ? 'network-only' : 'cache-first'
      }
    }
  });
}
