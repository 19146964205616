// eslint-disable-next-line no-unused-vars
function env(variable, defaultValue) {
  if (typeof variable === 'undefined') {
    return defaultValue;
  }
  return variable;
}

function requireEnv(variable, name) {
  if (typeof variable === 'undefined') {
    throw new Error(`Variable ${name} is required`);
  }
  return variable;
}

function booleanEnv(variable) {
  return env(variable, false) === 'true';
}

export const apiUrl = requireEnv(
  process.env.REACT_APP_API_URL,
  'REACT_APP_API_URL'
);

export const wsUrl = requireEnv(
  process.env.REACT_APP_WS_URL,
  'REACT_APP_WS_URL'
);

export const apiGraphqlUrl = apiUrl + '/graphql';
export const wsGraphqlUrl = wsUrl + '/graphql';

export const analyzeLink = requireEnv(
  process.env.REACT_APP_ANALYZE_LINK,
  'REACT_APP_ANALYZE_LINK'
);

export const filesUrl = requireEnv(
  process.env.REACT_APP_FILES_URL,
  'REACT_APP_FILES_URL'
);

export const hideRoadmaps = booleanEnv(process.env.REACT_APP_HIDE_ROADMAPS);

export const hideSubsidiaryEntities = booleanEnv(
  process.env.REACT_APP_HIDE_SUBSIDIARY_ENTITIES
);

export const hideIndicators = booleanEnv(process.env.REACT_APP_HIDE_INDICATORS);

export const hideInformation = booleanEnv(
  process.env.REACT_APP_HIDE_INFORMATION
);

export const hideUsers = booleanEnv(process.env.REACT_APP_HIDE_USERS);

export const hideCustomForms = booleanEnv(
  process.env.REACT_APP_HIDE_CUSTOM_FORMS
);

export const hideImport = booleanEnv(process.env.REACT_APP_HIDE_IMPORT);

export const hideAdditionalData = booleanEnv(
  process.env.REACT_APP_HIDE_ADDITIONAL_DATA
);

export const enableNewPurchaseFields = booleanEnv(
  process.env.REACT_APP_ENABLE_NEW_PURCHASE_FIELDS
);

export const enableForecastForm = booleanEnv(
  process.env.REACT_APP_ENABLE_RP_FORECAST_FORM
);

export const enableIndicatorForecastForm = booleanEnv(
  process.env.REACT_APP_ENABLE_INDICATOR_FORECAST_FORM
);

export const enableMentoringCoverageForm = booleanEnv(
  process.env.REACT_APP_ENABLE_MENTORING_COVERAGE_FORM
);

export const enableParentalAssistanceGuidelinesForm = booleanEnv(
  process.env.REACT_APP_ENABLE_PARENTAL_ASSISTANCE_GUIDELINES_FORM
);

export const enablePofRegistry = booleanEnv(
  process.env.REACT_APP_ENABLE_POF_REGISTRY
);

export const userUpdateIntervalSeconds = env(
  process.env.REACT_APP_USER_UPDATE_INTERVAL_SECONDS,
  600
);

export const enableStaffSchedules = booleanEnv(
  process.env.REACT_APP_ENABLE_STAFF_SCHEDULE
);

export const enableNotificationsIcon =
  env(process.env.REACT_APP_ENABLE_NOTIFICATIONS_ICON, false) === 'true';

export const enableArchiveDownload =
  env(process.env.REACT_APP_ENABLE_ARCHIVE_DOWNLOAD, false) === 'true';

export const isInfralistTestModeEnable = booleanEnv(
  process.env.REACT_APP_INFRALIST_TEST_MODE
);

export const yandexMetrikaId = env(process.env.REACT_APP_METRIKA_ID, '');

// Тип формы моиниторинга для отображения: REGIONAL | ORGANIZATION
const contestDocsFormType =
  env(process.env.REACT_APP_CONTEST_DOCS_FORM_TYPE) || 'REGIONAL';
export const isRegionalContestDocsForm = contestDocsFormType === 'REGIONAL';
export const isOrgContestDocsForm = contestDocsFormType === 'ORGANIZATION';
