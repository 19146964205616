import React from 'react';
import { CustomFastField } from '../../../../../common/Inputs/CustomFastField';
import TableInput from '../../../../../common/Table/TableInput';
import InputGroup from '../../../../../common/Inputs/InputGroup';
import { RpImplementationForecast } from '../../../../../store/rpImplementationForecast/RpImplementationForecast';
import { TableTextInput } from '../../../../../common/Inputs/TextInput';
import { getName } from '../../../../../utils/data/label';

const passportForecastsColumns = [
  {
    editComponent: ({ rowData }) => {
      return <span>{getName(rowData.federal_project)}</span>;
    },
    field: 'federal_project_id',
    title: 'Региональный проект',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellMedium,
    render: ({ federal_project }) => <span>{getName(federal_project)}</span>
  },
  {
    editComponent: TableTextInput,
    field: 'suggestions',
    title: 'Предложения по внесению изменений',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellLarge
  }
];

export const PassportForecastsFields = () => {
  return (
    <InputGroup>
      <CustomFastField
        name="passport_forecasts"
        columns={passportForecastsColumns}
        component={TableInput}
        canAdd={false}
        canDelete={false}
        tableName="Изменения в паспорт РП"
      />
    </InputGroup>
  );
};
