import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../../../common/useRetriableGraphqlVars';
import { QUERY_GET_EQUIPMENT_GENERAL_COMMENTS } from '../../../../graphql/queries/infralistComment';

const options = {
  query: QUERY_GET_EQUIPMENT_GENERAL_COMMENTS,
  mapData: (result) => result.infralistRvpoEquipmentGeneralCommentaries,
  skipQuery: skipIfNull(null)
};

export function useFetchEquipmentGeneralComments(equipmentId) {
  const response = useRetriableGraphql({
    queryOptions: options,
    variables: {
      rvpo_equipment_id: equipmentId
    },
    options: {
      apolloOptions: {
        fetchPolicy: 'network-only'
      }
    }
  });
  const data = response.state.data;

  return data ?? [];
}
