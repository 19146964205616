import { skipIfNull } from '@proscom/prostore';
import { useGraphqlWatchQuery } from '@proscom/prostore-apollo-react';
import { QUERY_SUBSIDIARY_MONITORING_ENTITY } from '../../graphql/queries/subsidiaryMonitoring';

const subsidiaryMonitoringEntityQueryOptions = {
  query: QUERY_SUBSIDIARY_MONITORING_ENTITY,
  mapData: (data) => data.subsidiaryEntityMonitoring,
  skipQuery: skipIfNull(null)
};

export function useSubsidiaryMonitoringEntity(input) {
  return useGraphqlWatchQuery({
    queryOptions: subsidiaryMonitoringEntityQueryOptions,
    variables: input ? { input } : null,
    options: {
      apolloOptions: {
        fetchPolicy: 'network-only'
      }
    }
  });
}
