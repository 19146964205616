import React, { useCallback, useMemo } from 'react';
import AutocompleteBaseInput from '../../../../../common/Inputs/Selects/AutocompleteBaseInput';
import { yearsSuggestions } from '../../../../../utils/data/date';
import { Purchase } from '../../../../../store/purchase/Purchase';

export const useExpenseGroupFilter = (
  query,
  changeQuery,
  projectId,
  id,
  implementationYears
) => {
  const year = +query.year || implementationYears?.[0] || undefined;

  const filter = {
    regional_project_ids: projectId,
    expenses_direction_ids: id,
    implementation_years: year ? [year] : undefined,
    status: query.status
  };

  const setFilterValue = useCallback(
    (value, name) =>
      changeQuery({
        [name]: value,
        page: 0
      }),
    [changeQuery]
  );

  const years = useMemo(() => {
    return (
      implementationYears?.map((y) => ({
        label: y,
        value: y
      })) || []
    );
  }, [implementationYears]);

  const filterComponent = (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Год"
        name="year"
        suggestions={years}
        value={year}
        variant="standard"
        isClearable={false}
      />
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Статус"
        name="status"
        suggestions={Purchase.status.getOptions()}
        value={filter.status}
        variant="standard"
      />
    </div>
  );
  return [filterComponent, filter];
};
