import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import React, { Fragment } from 'react';
import { getMonth, quarterNames } from '../../../utils/data/date';
import { formatCurrency } from '../../../utils/number';
import SubTable from '../../../common/SubTable/SubTable';
import { Pof } from '../../../store/pof/Pof';
import { SubTableHeading } from '../../../common/SubTable/SubTableHeading';
import { Signal } from '../../../common/Inputs/Signals';
import tableStyle from '../../../common/SubTable/SubTable.module.scss';

export const PofQuarters = ({
  values,
  editVariant,
  groupedPurchaseScheduleSum
}) => {
  const quarters = Pof.getQuarters(values);

  return (
    <SubTable editVariant={editVariant} cols={4}>
      <TableRow>
        <TableCell classes={{ root: tableStyle.SubTableHeader }} />
        <TableCell classes={{ root: tableStyle.SubTableHeader }}>
          Объем средств
        </TableCell>
        <TableCell classes={{ root: tableStyle.SubTableHeader }}>
          Сумма платежей
        </TableCell>
        <TableCell classes={{ root: tableStyle.SubTableHeader }}>
          Комментарий
        </TableCell>
      </TableRow>
      {quarters.map((quarter, indexQuarter) => {
        const quarterTotal = quarter.reduce(
          (sum, target) => sum + target.value,
          0
        );
        const quarterItems = quarter.map((item, indexItem) => {
          const purchaseScheduleMonth =
            groupedPurchaseScheduleSum &&
            groupedPurchaseScheduleSum.find(
              (scheduleMonth) => scheduleMonth.month === item.month
            );

          const isDifference = !(
            item.value === (purchaseScheduleMonth?.sum || 0)
          );
          return (
            <TableRow key={indexItem}>
              <TableCell>{getMonth(item.month - 1)}</TableCell>
              <TableCell>
                {formatCurrency(item.value)}
                {isDifference && (
                  <Signal warning>
                    Имеется отклонение суммы платежей от значения ПОФ за{' '}
                    {getMonth(item.month - 1)}
                  </Signal>
                )}
              </TableCell>
              <TableCell>
                {formatCurrency(purchaseScheduleMonth?.sum || 0)}
              </TableCell>
              <TableCell style={{ maxWidth: '400px' }}>
                {item.comment}
              </TableCell>
            </TableRow>
          );
        });

        return (
          <Fragment key={indexQuarter}>
            <SubTableHeading cols={4}>
              {quarterNames[indexQuarter]}
            </SubTableHeading>
            <TableRow>
              <TableCell>Всего</TableCell>
              <TableCell colSpan={3}>{formatCurrency(quarterTotal)}</TableCell>
            </TableRow>
            {quarterItems}
          </Fragment>
        );
      })}
    </SubTable>
  );
};
