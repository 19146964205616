import gql from 'graphql-tag';

export const QUERY_GET_PARENTAL_ASSISTANCE_GUIDELINE_REGIONAL = gql`
  query getParentalAssistanceGuidelineRegional($id: ID!) {
    parentalAssistanceGuidelineRegional(id: $id) {
      id
      region {
        id
        name
        code_nalog
      }
      region_id
      non_commercial_organizations_info {
        free_services
        kind
        organization_name
        paid_services
        parental_assistance_guideline_id
      }
      list_of_service_regulations {
        id
        file_name
        description
      }
      information_distribution_protocols {
        id
        file_name
      }
      approved_roadmap {
        id
        file_name
      }
      amount_of_contract_specialists_2019
      amount_of_contract_specialists_2020
      amount_of_lended_another_format_services_2019
      amount_of_lended_another_format_services_2020
      amount_of_lended_f2f_services_2019
      amount_of_lended_f2f_services_2020
      amount_of_lended_field_services_2019
      amount_of_lended_field_services_2020
      amount_of_lended_free_services_2019
      amount_of_lended_free_services_2020
      amount_of_lended_paid_services_2019
      amount_of_lended_paid_services_2020
      amount_of_lended_remote_services_2019
      amount_of_lended_remote_services_2020
      amount_of_multipletimes_service_recipients_2019
      amount_of_multipletimes_service_recipients_2020
      amount_of_onetime_service_recipients_2019
      amount_of_onetime_service_recipients_2020
      amount_of_prepared_specialists_2019
      amount_of_prepared_specialists_2020
      amount_of_service_rejections_2019
      amount_of_service_rejections_2020
      amount_of_specialists_in_state_2019
      amount_of_specialists_in_state_2020
      date_of_service_start
      date_of_service_start_description
      free_service_recipient_groups
      information_distribution_description
      information_is_being_distributed
      maximum_waiting_time
      maximum_waiting_time_exists
      other_service_delivery_formats
      other_sources_of_financing
      paid_service_delivery_description
      paid_service_possibility
      possible_consultation_topic_choice
      possible_format_choice
      possible_nwh_services_provisioning
      possible_specialist_choice
      possible_time_choice
      possible_weekend_services_provisioning
      problems_in_new_service_introduction_description
      problems_in_new_service_introduction_exist
      problems_in_new_service_introduction_suggestions
      service_delivery_formats
      service_delivery_mechanism
      new_service_implementation_roadmap_exist
      best_practices_of_services_provision
      service_rejections_description_2019
      service_rejections_description_2020
      services_quality_control_methodology
      services_quality_is_being_controlled
      sources_of_financing
      special_categories_of_legal_representatives
      special_categories_of_legal_representatives_description
      types_of_free_consultations
      status
    }
  }
`;
