import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useState } from 'react';
import { MUTATION_BIND_SUBSIDIARY_ENTITY } from '../../graphql/mutations/subsidiaryEntity';
import { INFRALIST_QUERIES } from './infralistQueries';

export const useBindSubsidiaryEntity = (infralistId) => {
  const client = useContextApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  const bindSubsidiaryEntity = (subsidiaryId) => {
    setIsLoading(true);
    return client
      .mutate({
        mutation: MUTATION_BIND_SUBSIDIARY_ENTITY,
        variables: {
          infralist_id: infralistId,
          subsidiary_id: subsidiaryId
        },
        refetchQueries: [INFRALIST_QUERIES.INFRALIST]
      })
      .finally(() => setIsLoading(false));
  };

  return { bindSubsidiaryEntity, isLoadingBinding: isLoading };
};
