import { useMemo } from 'react';
import {
  infralistStatusService,
  INFRALIST_STATUSES
} from '../../../store/infralist/infralistStatusService';
import { infralistCheckStatusService } from '../../../store/infralist/statuses/infralistCheckStatusService';
import { useInfralistStatusUserAccesses } from '../../../store/infralist/useInfralistUserAccesses';
import { EMDASH } from '../../../utils/constants';
import { reformatDatetime } from '../../../utils/date';
import { formatNumber } from '../../../utils/formatNumber';
import { getInfralistDataField } from '../../../utils/infralist';

export const useInfralistPageColumns = ({ infralistStatus, versionPage }) => {
  const { showRVPOColumns, showFPOColumns } = useInfralistStatusUserAccesses(
    infralistStatus
  );

  const columns = useMemo(() => {
    return [
      {
        label: '№',
        renderCell: (dataItem, dataItemKey) => dataItemKey + 1,
        width: 46
      },
      {
        label: 'Наименование направления',
        renderCell: (dataItem) => dataItem.name,
        width: '2fr'
      },
      {
        label: 'Стоимость, руб.',
        renderCell: (dataItem) =>
          formatNumber(
            getInfralistDataField(dataItem, 'direction_sum', versionPage)
          ),
        width: '1fr',
        sort: 'full_price'
      },
      // Формирование и подписание
      {
        label: 'Статус',
        renderCell: (dataItem) => {
          return infralistStatusService.getColoredName(
            getInfralistDataField(dataItem, 'status', versionPage)?.name
          );
        },
        width: '1fr',
        isActive: showRVPOColumns,
        sort: 'status'
      },
      // Проверка, согласование
      {
        label: 'Результат проверки',
        renderCell: (dataItem) => {
          if (
            infralistStatus === INFRALIST_STATUSES.ON_CHECKING &&
            !getInfralistDataField(dataItem, 'check_status', versionPage)?.name
          ) {
            return infralistStatusService.getColoredName(
              INFRALIST_STATUSES.ON_CHECKING
            );
          } else {
            return infralistCheckStatusService.getColoredName(
              getInfralistDataField(dataItem, 'check_status', versionPage)
                ?.name ?? EMDASH
            );
          }
        },
        width: '1fr',
        isActive: showFPOColumns,
        sort: 'check_status'
      },
      {
        label: 'ФИО проверяющего',
        renderCell: (dataItem) =>
          getInfralistDataField(dataItem, 'checked_users', versionPage)
            ?.map((user) => user.name)
            .join(', '),
        width: '1fr',
        isActive: showFPOColumns
      },
      {
        label: 'Статус согласования',
        renderCell: (dataItem) => {
          return infralistStatusService.getColoredName(
            getInfralistDataField(dataItem, 'approvement_status', versionPage)
              ?.name,
            [
              INFRALIST_STATUSES.ON_APPROVAL,
              INFRALIST_STATUSES.APPROVED,
              INFRALIST_STATUSES.APPROVED_WITH_COMMENTS,
              INFRALIST_STATUSES.NOT_APPROVED
            ]
          );
        },
        width: '1fr',
        isActive: showFPOColumns,
        sort: 'status'
      },
      {
        label: 'Дата изменения статуса',
        renderCell: (dataItem) => {
          // показывать дату только для сформированных разделов
          return reformatDatetime(
            getInfralistDataField(dataItem, 'status', versionPage)?.name !==
              INFRALIST_STATUSES.NOT_FORMED &&
              getInfralistDataField(dataItem, 'status_change_time', versionPage)
          );
        },
        width: '1fr',
        sort: 'check_status_update'
      }
    ];
  }, [infralistStatus, versionPage, showFPOColumns, showRVPOColumns]);

  return columns;
};
