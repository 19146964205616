import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_PARENTAL_ASSISTANCE_GUIDELINE_REGIONAL } from '../../graphql/queries/parentalAssistanceGuidelines/parentalAssistanceGuidelineRegional';

const ParentalAssistanceGuidelineRegionalQueryOptions = {
  query: QUERY_GET_PARENTAL_ASSISTANCE_GUIDELINE_REGIONAL,
  mapData: (result) => result.parentalAssistanceGuidelineRegional,
  skipQuery: skipIfNull(null)
};

export function useParentalAssistanceGuidelineRegional(id) {
  return useRetriableGraphqlVars({
    queryOptions: ParentalAssistanceGuidelineRegionalQueryOptions,
    // TODO refetchQuery не срабатывает, пока сделаем без кеша. Нужно разобраться
    options: {
      apolloOptions: {
        fetchPolicy: 'network-only'
      }
    },
    variableCreator: [
      () =>
        id
          ? {
              id
            }
          : null,
      [id]
    ]
  });
}
