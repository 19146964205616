import {
  endOfWeek,
  format,
  isBefore,
  isEqual,
  isSameWeek,
  isValid,
  parseISO,
  startOfWeek,
  startOfDay,
  endOfDay
} from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import { EMDASH } from './constants';
import nomRuLocale from './fnsNomRuLocale';
import { parseApiDate, removeTimezoneFromDate } from './data/date';

export const DATE_FORMAT_RUSSIAN = 'dd.MM.yyyy';
export const DATE_TIME_FORMAT_RUSSIAN = 'dd.MM.yyyy HH:mm:ss';
export const DATE_FORMAT_ISO_DATE = 'yyyy-MM-dd';
export const DATE_FORMAT_MONTH = 'MMMM yyyy';

export function reformatDate(isoDate, defaultValue = EMDASH) {
  if (!isoDate) return defaultValue;
  const date = parseISO(isoDate);
  if (!date) return defaultValue;
  return format(date, DATE_FORMAT_RUSSIAN);
}

export function reformatDateWithoutTimezone(date, defaultValue = EMDASH) {
  if (!date) return defaultValue;
  return format(
    removeTimezoneFromDate(parseApiDate(date)),
    DATE_FORMAT_RUSSIAN
  );
}

export function lStartOfWeek(date) {
  return startOfWeek(date, { weekStartsOn: 1 });
}

export function lEndOfWeek(date) {
  return endOfWeek(date, { weekStartsOn: 1 });
}

export function lIsSameWeek(dateLeft, dateRight) {
  return isSameWeek(dateLeft, dateRight, { weekStartsOn: 1 });
}

export function lFormat(date, formatString, options = {}) {
  return format(date, formatString, { ...options, locale: ruLocale });
}

export function lnFormat(date, formatString, options = {}) {
  return format(date, formatString, { ...options, locale: nomRuLocale });
}

export function formatDateIso(date) {
  if (!date) {
    return 'Некорректная дата';
  }
  return format(date, DATE_FORMAT_ISO_DATE);
}

export function isBeforeOrEqual(dateLeft, dateRight) {
  return isBefore(dateLeft, dateRight) || isEqual(dateLeft, dateRight);
}

export function tryParseIso(string) {
  if (!string) return null;
  const date = parseISO(string);
  if (isValid(date)) {
    return date;
  }
  return null;
}

export function reformatDatetime(isoDate, defaultValue = EMDASH) {
  if (!isoDate) return defaultValue;
  const date = parseISO(isoDate);
  if (!date) return defaultValue;
  return format(date, DATE_TIME_FORMAT_RUSSIAN);
}

export function getStartOfDayString(isoDate) {
  return startOfDay(new Date(isoDate)).toISOString();
}

export function getEndOfDayString(isoDate) {
  return endOfDay(new Date(isoDate)).toISOString();
}
