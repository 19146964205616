import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useStore } from '@proscom/prostore-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SUBSCRIPTION_LOCK_SECTION_OR_EQUIPMENT } from '../../../graphql/subscriptions/equipment';
import { useDialog } from '../../../utils/hooks/useDialog';
import { STORE_AUTH } from '../../names';
import { useLockActions } from './useLockActions';

export const useSubscribeSingleSectionOrEquipmentLock = ({
  objectId,
  objectType
}) => {
  const [auth] = useStore(STORE_AUTH);
  const userId = auth?.authData?.user?.id;

  const [blocked, setBlocked] = useState(false);
  // хранение предыдущего пользователя, которые редактировал этот элемент
  const prevEditUserId = useRef();
  const client = useContextApolloClient();
  const { lockEditing, unlockEditing } = useLockActions();
  const {
    isOpen: isRefreshPagePopupVisible,
    open: openRefreshPagePopup,
    close: closeRefreshPagePopup
  } = useDialog();

  // обработка событий подписки
  const handleEditSubscriptionEvent = useCallback(
    (value) => {
      const subscriptionUserId = value?.data?.onEditLock?.user_id;

      // если редактируется другим пользователем, блокируем редактирование
      const isBlocked =
        subscriptionUserId !== '' && subscriptionUserId !== userId;
      setBlocked(isBlocked);

      // если ранее редактировал другой пользователь, показываем попап
      if (
        subscriptionUserId === '' &&
        prevEditUserId.current &&
        prevEditUserId.current !== '' &&
        prevEditUserId.current !== userId
      ) {
        openRefreshPagePopup();
      }

      // обновим предыдущего пользователя
      prevEditUserId.current = subscriptionUserId;
    },
    [openRefreshPagePopup, userId]
  );

  useEffect(() => {
    const subscription = client
      .subscribe({
        query: SUBSCRIPTION_LOCK_SECTION_OR_EQUIPMENT,
        variables: {
          input: [
            {
              object_id: objectId,
              object_type: objectType
            }
          ]
        }
      })
      .subscribe(handleEditSubscriptionEvent);

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [objectType, objectId, client, auth, userId, handleEditSubscriptionEvent]);

  return {
    blocked,
    lockEditing: () =>
      lockEditing({
        objectType,
        objectId
      }),
    unlockEditing: () =>
      unlockEditing({
        objectType,
        objectId
      }),
    isRefreshPagePopupVisible,
    closeRefreshPagePopup
  };
};
