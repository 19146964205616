import Button from '@material-ui/core/Button';
import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { ExportModal } from '../../../../../common/ExportModal/ExportModal';
import { COMMENT_TYPES } from '../../../../../store/infralist/commentEntities';
import { useInfralistCommentsUserAccesses } from '../../../../../store/infralist/comments/useInfralistCommentsUserAccesses';
import { useDialog } from '../../../../../utils/hooks/useDialog';
import s from './MessageHistory.module.scss';

const commentTypeTitles = {
  fpo: 'ФПО',
  rvpo: 'РВПО',
  general: 'ФПО+РВПО'
};

export const MessageHistory = ({
  commentType,
  status,
  baseUrl,
  exportCommentaries,
  downloadDisabled
}) => {
  const { canSeeRvpo, canSeeFpo } = useInfralistCommentsUserAccesses(status);
  const history = useHistory();
  const {
    isOpen: isExportCommentariesOpen,
    open: openExport,
    close: closeExport
  } = useDialog();

  const goToCommentBranch = (type) => {
    history.push(`${baseUrl}/${type}`);
  };

  const onExport = (fileType) => {
    if (exportCommentaries) {
      return exportCommentaries(commentType, fileType).finally(closeExport);
    }
  };

  return (
    <div className={s.Container}>
      <div className={s.Title}>История сообщений</div>
      <div className={s.List}>
        <div
          className={classNames(
            s.Item,
            commentType === COMMENT_TYPES.GENERAL && s.ActiveItem
          )}
          onClick={() => goToCommentBranch(COMMENT_TYPES.GENERAL)}
        >
          {commentTypeTitles['general']}
        </div>
        {canSeeFpo && (
          <div
            className={classNames(
              s.Item,
              commentType === COMMENT_TYPES.FPO && s.ActiveItem
            )}
            onClick={() => goToCommentBranch(COMMENT_TYPES.FPO)}
          >
            {commentTypeTitles['fpo']}
          </div>
        )}
        {canSeeRvpo && (
          <div
            className={classNames(
              s.Item,
              commentType === COMMENT_TYPES.RVPO && s.ActiveItem
            )}
            onClick={() => goToCommentBranch(COMMENT_TYPES.RVPO)}
          >
            {commentTypeTitles['rvpo']}
          </div>
        )}
      </div>
      <div className={s.DownloadTitle}>
        Вы можете скачать историю <br />
        сообщений “ФПО+РВПО”
      </div>
      <Button
        color={'secondary'}
        variant="contained"
        onClick={openExport}
        disabled={downloadDisabled}
      >
        Скачать
      </Button>
      {isExportCommentariesOpen && (
        <ExportModal
          open={isExportCommentariesOpen}
          onClose={closeExport}
          onExport={onExport}
        />
      )}
    </div>
  );
};
