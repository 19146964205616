import gql from 'graphql-tag';

export const QUERY_GET_EQUIPMENT_NAME_LIST = gql`
  query fpoEquipmentList($section_id: ID!) {
    fpoEquipmentList(section_id: $section_id) {
      id
      name
      section_id
      fpo_description
    }
  }
`;

export const QUERY_GET_EQUIPMENT = gql`
  query infralistRvpoEquipment($id: ID!) {
    infralistRvpoEquipment(id: $id) {
      id
      fpo_equipment_id
      section_id
      infralist_id
      checked_user_id
      name_rvpo
      description_rvpo
      model_rvpo
      quantity
      price
      unit
      equipment_external_url
      predefined_id
      code
      main_data_area
      description
      created_at
      updated_at

      equipment_status_id
      equipment_status {
        id
        name
      }

      check_status_id
      check_status {
        id
        name
      }

      equipment_approvement_status_id
      equipment_approvement_status {
        id
        name
      }
    }
  }
`;

export const QUERY_RVPO_EQUIPMENTS_LIST = gql`
  query infralistSectionRvpoEquipments(
    $input: InfralistRvpoEquipmentPageInput!
  ) {
    infralistSectionRvpoEquipments(input: $input) {
      header {
        totalCount
        page
        hasNext
      }
      list {
        equipment_status {
          id
          name
        }

        check_status {
          id
          name
        }

        equipment_approvement_status {
          id
          name
        }

        id
        fpo_equipment_id
        section_id
        infralist_id
        checked_user_id
        name_rvpo
        description_rvpo
        model_rvpo
        quantity
        price
        equipment_external_url
        predefined_id
        code
        main_data_area
        description
        created_at
        updated_at
        unit
        has_commentaries
      }
    }
  }
`;

export const QUERY_VERSION_RVPO_EQUIPMENTS_LIST = gql`
  query infralistVersionRvpoEquipments(
    $input: InfralistVersionRvpoEquipmentPageInput!
  ) {
    infralistVersionRvpoEquipments(input: $input) {
      header {
        page
        hasNext
        totalCount
      }
      list {
        id
        infralist_version_id
        equipment_status {
          id
          name
        }

        check_status {
          id
          name
        }

        equipment_approvement_status {
          id
          name
        }
        fpo_equipment_id
        section_id
        infralist_id
        checked_user_id
        name_rvpo
        description_rvpo
        model_rvpo
        quantity
        price
        equipment_external_url
        predefined_id
        code
        main_data_area
        description
        created_at
        updated_at
        unit
      }
    }
  }
`;
