import { MUTATION_UPDATE_OR_CREATE_REGIONAL_ROADMAP_EVENT } from '../../graphql/mutations/events';
import { clearApolloQueryCache } from '../../utils/apollo';
import { MUTATION_UPDATE_ROADMAP_EVENT } from '../../graphql/mutations/roadmapEvent';
import { RegionalEvent } from './RegionalEvent';

export class RoadmapEventStore {
  constructor({ client }) {
    this.client = client;
  }

  queriesToClear = [
    'roadmapEvents',
    'federalProjectRoadmaps',
    'regionalProjects',
    'roadmapEvent',
    'staffScheduleGuidelines'
  ];

  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  updateRegionalEvent(data) {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_OR_CREATE_REGIONAL_ROADMAP_EVENT,
      variables: {
        input: RegionalEvent.filterUpdateRegionalFields(data)
      },
      refetchQueries: ['roadmapEvent'],
      update: this.clearCache
    });
  }

  updateFederalEvent(data) {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_ROADMAP_EVENT,
      variables: {
        input: RegionalEvent.filterUpdateFederalFields(data)
      },
      refetchQueries: ['roadmapEvent'],
      update: this.clearCache
    });
  }

  sendForApproval(eventId, regionId) {
    return this.updateRegionalEvent({
      region_id: regionId,
      roadmap_event_id: eventId,
      status: RegionalEvent.Status.APPROVAL
    });
  }
}
