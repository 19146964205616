import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../../common/useRetriableGraphqlVars';
import { QUERY_INFRALIST_VERSION } from '../../../graphql/queries/infralist';

const infralistVersionQueryOptions = {
  query: QUERY_INFRALIST_VERSION,
  mapData: (result) => result.infralistVersion,
  skipQuery: skipIfNull(null)
};

export function useInfralistVersionData(infralistVersionId) {
  return useRetriableGraphql({
    queryOptions: infralistVersionQueryOptions,
    variables: {
      infralist_version_id: infralistVersionId
    }
  });
}
