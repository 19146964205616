import React from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { toast } from 'react-toastify';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { notificationValidationSchema } from '../../../../utils/data/validation';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../graphql/graphqlErrors';
import { getNotificationBreadCrumbs } from '../../getNotificationBreadCrumbs';
import BreadCrumbs from '../../../../common/BreadCrumbs/BreadCrumbs';
import { STORE_NOTIFICATION_STORE } from '../../../../store/names';
import { Notifications } from '../../../../store/notification/Notification';
import { NotificationEditForm } from './NotificationEditForm';

function NotificationCreatePage({ history }) {
  const roadmapEventStore = useContextStore(STORE_NOTIFICATION_STORE);
  const breadCrumbs = getNotificationBreadCrumbs();
  const initialValues = {
    role: '',
    recipients: [],
    content: '',
    section: Notifications.Sections.POF,
    subject: ''
  };
  const backLink = '/notifications';

  return (
    <>
      <HeadTitle type="Пользователь" pageType={HeadTitle.PageType.create} />
      <BreadCrumbs links={breadCrumbs} />
      <h2>Отправление уведомления</h2>
      <Formik
        validationSchema={notificationValidationSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          const notification = Notifications.fromForm(values);
          roadmapEventStore
            .createMessage(notification)
            .then(() => {
              actions.setStatus(FORM_STATUS_SUBMITTED);
              setTimeout(() => {
                history.push('/notifications');
              }, 0);
              toast.success('Сообщение отправлено.');
            })
            .catch((error) => {
              showGraphqlErrorToast(error, mutationErrors.createObject);
            })
            .then(() => {
              actions.setSubmitting(false);
            });
        }}
        render={(formikProps) => (
          <NotificationEditForm
            {...formikProps}
            onBackClick={() => history.goBack(backLink)}
          />
        )}
      />
    </>
  );
}

export default NotificationCreatePage;
