import React, { useEffect } from 'react';
import { useCurrentUser } from '../../store/useCurrentUser';
import { useMentoringCoverageByRegionId } from '../../store/mentoringCoverage/useMentoringCoverage';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';
import PageTitle from '../../common/PageTitle/PageTitle';
import { User } from '../../store/role/User';

const title = 'Охват наставничеством';

export default function MentoringCoverage({ match, history }) {
  const user = useCurrentUser();
  const regionId = user?.region?.id;

  const mentoringCoverageQuery = useMentoringCoverageByRegionId(regionId);
  const mentoringCoverage = mentoringCoverageQuery.state.data;

  useEffect(() => {
    if (User.isAdmin(user) || user.role === User.Role.FEDERAL_ADMIN) {
      history.push(`/mentoringCoverage/report`);
    } else {
      if (!mentoringCoverageQuery.check.spinner && regionId) {
        if (!mentoringCoverage) {
          history.replace(`/mentoringCoverage/create`);
        } else {
          history.replace(`/mentoringCoverage/${mentoringCoverage.id}`);
        }
      }
    }
  }, [
    history,
    mentoringCoverage,
    mentoringCoverageQuery.check.spinner,
    regionId,
    user
  ]);

  return (
    <>
      <HeadTitle title={title} />
      <PageTitle withBackBtn={false} title={title} />
    </>
  );
}
