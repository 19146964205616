import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router';
import styles from './PageTitlePanel.module.scss';

export const PageTitlePanel = ({
  title,
  description,
  actions,
  withBackBtn = true,
  backUrl
}) => {
  const history = useHistory();
  const back = () => history.push(backUrl);
  return (
    <div className={styles.root}>
      <div>
        <div className={styles.titleGroup}>
          {withBackBtn && (
            <IconButton
              size={'small'}
              className={styles.BackButton}
              onClick={back}
            >
              <ArrowBackIcon />
            </IconButton>
          )}

          <h2 className={styles.title}>{title || '...'}</h2>
        </div>
        <div className={styles.Description}>{description}</div>
      </div>
      <div>{actions}</div>
    </div>
  );
};

PageTitlePanel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.element,
  actions: PropTypes.element,
  withBackBtn: PropTypes.bool
};
