import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useState } from 'react';
import { MUTATION_COMPLETE_SIGNING } from '../../../graphql/mutations/equipment';
import { INFRALIST_QUERIES } from '../infralistQueries';

export const useCompleteSigning = () => {
  const client = useContextApolloClient();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingWithoutEquipment, setIsLoadingWithoutEquipment] = useState(
    false
  );

  const completeSigning = ({ infralistId, sectionId, isEmptySigning }) => {
    if (isEmptySigning || typeof isEmptySigning === 'undefined') {
      setIsLoading(true);
    } else {
      setIsLoadingWithoutEquipment(true);
    }
    return client
      .mutate({
        mutation: MUTATION_COMPLETE_SIGNING,
        variables: {
          infralist_id: infralistId,
          section_id: sectionId,
          is_empty_signing: isEmptySigning
        },
        update: (store) => {
          store.reset();
        },
        refetchQueries: [INFRALIST_QUERIES.SECTION]
      })
      .finally(() => {
        if (isEmptySigning || typeof isEmptySigning === 'undefined') {
          setIsLoading(false);
        } else {
          setIsLoadingWithoutEquipment(false);
        }
      });
  };

  return {
    isLoadingCompleteSigning: isLoading,
    completeSigning,
    isLoadCompleteSigningWithoutEquipment: isLoadingWithoutEquipment
  };
};
