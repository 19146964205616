import gql from 'graphql-tag';

export const MUTATION_CREATE_EQUIPMENT_COMMENTARY = gql`
  mutation createRvpoEquipmentCommentary(
    $input: InfralistRvpoEquipmentCommentaryInputType!
  ) {
    createRvpoEquipmentCommentary(input: $input) {
      id
    }
  }
`;

export const MUTATION_UPDATE_EQUIPMENT_COMMENTARY = gql`
  mutation updateRvpoEquipmentCommentary(
    $input: InfralistRvpoEquipmentCommentaryInputType!
  ) {
    updateRvpoEquipmentCommentary(input: $input) {
      id
    }
  }
`;

export const MUTATION_DELETE_EQUIPMENT_COMMENTARY = gql`
  mutation deleteRvpoEquipmentCommentary($id: ID!) {
    deleteRvpoEquipmentCommentary(id: $id)
  }
`;

export const MUTATION_ACCEPT_COMMENTARIES = gql`
  mutation acceptRvpoEquipmentCommentary(
    $content: String
    $comment_ids: [ID!]!
    $rvpo_equipment_id: ID!
  ) {
    acceptRvpoEquipmentCommentary(
      content: $content
      comment_ids: $comment_ids
      rvpo_equipment_id: $rvpo_equipment_id
    ) {
      id
    }
  }
`;

export const MUTATION_REJECT_COMMENTARIES = gql`
  mutation rejectRvpoEquipmentCommentary(
    $content: String!
    $comment_ids: [ID!]!
    $rvpo_equipment_id: ID!
  ) {
    rejectRvpoEquipmentCommentary(
      content: $content
      comment_ids: $comment_ids
      rvpo_equipment_id: $rvpo_equipment_id
    ) {
      id
    }
  }
`;

export const MUTATION_CREATE_DIRECTION_COMMENT = gql`
  mutation createDirectionCommentary(
    $input: InfralistDirectionCommentaryInputType!
  ) {
    createDirectionCommentary(input: $input) {
      id
    }
  }
`;

export const MUTATION_UPDATE_DIRECTION_COMMENT = gql`
  mutation updateDirectionCommentary(
    $input: InfralistDirectionCommentaryInputType!
  ) {
    updateDirectionCommentary(input: $input) {
      id
    }
  }
`;

export const MUTATION_DELETE_DIRECTION_COMMENTARY = gql`
  mutation deleteDirectionCommentary($id: ID!) {
    deleteDirectionCommentary(id: $id)
  }
`;

export const MUTATION_CREATE_SECTION_COMMENT = gql`
  mutation createSectionCommentary(
    $input: InfralistSectionCommentaryInputType!
  ) {
    createSectionCommentary(input: $input) {
      id
    }
  }
`;

export const MUTATION_UPDATE_SECTION_COMMENT = gql`
  mutation updateSectionCommentary(
    $input: InfralistSectionCommentaryInputType!
  ) {
    updateSectionCommentary(input: $input) {
      id
    }
  }
`;

export const MUTATION_DELETE_SECTION_COMMENTARY = gql`
  mutation deleteSectionCommentary($id: ID!) {
    deleteSectionCommentary(id: $id)
  }
`;

export const MUTATION_ACCEPT_SECTION_COMMENTARY = gql`
  mutation acceptSectionCommentary(
    $content: String
    $comment_ids: [ID!]!
    $infralist_id: ID!
    $section_id: ID!
  ) {
    acceptSectionCommentary(
      content: $content
      comment_ids: $comment_ids
      infralist_id: $infralist_id
      section_id: $section_id
    ) {
      id
    }
  }
`;

export const MUTATION_REJECT_SECTION_COMMENTARY = gql`
  mutation rejectSectionCommentary(
    $content: String!
    $comment_ids: [ID!]!
    $infralist_id: ID!
    $section_id: ID!
  ) {
    rejectSectionCommentary(
      content: $content
      comment_ids: $comment_ids
      infralist_id: $infralist_id
      section_id: $section_id
    ) {
      id
    }
  }
`;

export const MUTATION_ACCEPT_DIRECTION_COMMENTARY = gql`
  mutation acceptDirectionCommentary(
    $content: String
    $comment_ids: [ID!]!
    $infralist_id: ID!
    $direction_id: ID!
  ) {
    acceptDirectionCommentary(
      content: $content
      comment_ids: $comment_ids
      infralist_id: $infralist_id
      direction_id: $direction_id
    ) {
      id
    }
  }
`;

export const MUTATION_REJECT_DIRECTION_COMMENTARY = gql`
  mutation rejectDirectionCommentary(
    $content: String!
    $comment_ids: [ID!]!
    $infralist_id: ID!
    $direction_id: ID!
  ) {
    rejectDirectionCommentary(
      content: $content
      comment_ids: $comment_ids
      infralist_id: $infralist_id
      direction_id: $direction_id
    ) {
      id
    }
  }
`;

export const MUTATION_MARK_EQUIPMENT_COMMENTARIES_AS_READ = gql`
  mutation markRvpoEquipmentCommentariesAsRead(
    $commentary_ids: [ID!]!
    $infralist_id: ID!
  ) {
    markRvpoEquipmentCommentariesAsRead(
      commentary_ids: $commentary_ids
      infralist_id: $infralist_id
    )
  }
`;

export const MUTATION_MARK_SECTION_COMMENTARIES_AS_READ = gql`
  mutation markSectionCommentariesAsRead(
    $commentary_ids: [ID!]!
    $infralist_id: ID!
  ) {
    markSectionCommentariesAsRead(
      commentary_ids: $commentary_ids
      infralist_id: $infralist_id
    )
  }
`;

export const MUTATION_MARK_DIRECTION_COMMENTARIES_AS_READ = gql`
  mutation markDirectionCommentariesAsRead(
    $commentary_ids: [ID!]!
    $infralist_id: ID!
  ) {
    markDirectionCommentariesAsRead(
      commentary_ids: $commentary_ids
      infralist_id: $infralist_id
    )
  }
`;
