import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useCallback } from 'react';
import {
  MUTATION_MARK_DIRECTION_COMMENTARIES_AS_READ,
  MUTATION_MARK_EQUIPMENT_COMMENTARIES_AS_READ,
  MUTATION_MARK_SECTION_COMMENTARIES_AS_READ
} from '../../../graphql/mutations/comment';

export const useCommentReadMutations = ({ infralistId }) => {
  const client = useContextApolloClient();

  const markEquipmentCommentsAsRead = useCallback(
    ({ commentIds }) => {
      return client.mutate({
        mutation: MUTATION_MARK_EQUIPMENT_COMMENTARIES_AS_READ,
        variables: {
          commentary_ids: commentIds,
          infralist_id: infralistId
        }
      });
    },
    [client, infralistId]
  );

  const markSectionCommentsAsRead = useCallback(
    ({ commentIds }) => {
      return client.mutate({
        mutation: MUTATION_MARK_SECTION_COMMENTARIES_AS_READ,
        variables: {
          commentary_ids: commentIds,
          infralist_id: infralistId
        }
      });
    },
    [client, infralistId]
  );

  const markDirectionCommentsAsRead = useCallback(
    ({ commentIds }) => {
      return client.mutate({
        mutation: MUTATION_MARK_DIRECTION_COMMENTARIES_AS_READ,
        variables: {
          commentary_ids: commentIds,
          infralist_id: infralistId
        }
      });
    },
    [client, infralistId]
  );

  return {
    markEquipmentCommentsAsRead,
    markSectionCommentsAsRead,
    markDirectionCommentsAsRead
  };
};
