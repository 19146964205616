import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import Dialog from '../../../../../common/Dialog';
import { FormikTextField } from '../../../../../common/infralist/FormikFormFields/FormikTextField/FormikTextField';
import { ProgressButton } from '../../../../../common/ProgressButton';
import { COMMENT_TYPES } from '../../../../../store/infralist/commentEntities';
import { useGenerateCommentListForGeneralBranch } from '../../../../../store/infralist/comments/useGenerateCommentListForGeneralBranch';
import { useInfralistCommentsUserAccesses } from '../../../../../store/infralist/comments/useInfralistCommentsUserAccesses';
import { validationMessages } from '../../../../../utils/data/validation';
import { Comment } from '../Comment/Comment';
import { CollectedComment } from '../Comment/CollectedComment';
import s from './CommentList.module.scss';

const validationSchema = yup.object().shape({
  content: yup
    .string()
    .required(validationMessages.requiredMessage)
    .trim(validationMessages.requiredMessage)
});

export function CommentList({
  data = [],
  createComment,
  updateComment,
  deleteComment,
  status,
  commentType,
  acceptComment,
  rejectComment,
  isEditEnabledByEventStatus
}) {
  const [currentId, setCurrentId] = useState(undefined);
  const [deleteModal, setDeleteModal] = useState(false);
  const { canCreate } = useInfralistCommentsUserAccesses(
    status,
    isEditEnabledByEventStatus
  );
  const collectedComments = useGenerateCommentListForGeneralBranch(data);
  const [loading, setLoading] = useState(false);

  const commentListRef = useRef();

  const onSubmit = (data, actions) => {
    setLoading(true);
    createComment(data)
      .then(() => actions.resetForm())
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const commentListBlock = commentListRef.current;
    if (
      data.length > 0 &&
      commentListBlock &&
      commentListBlock.scrollHeight > 0
    ) {
      commentListBlock.scrollTo(
        0,
        commentListBlock.scrollHeight - commentListBlock.offsetHeight
      );
    }
  }, [data.length]);

  return (
    <div className={s.CommentListContainer}>
      <div className={s.CommentList} ref={commentListRef}>
        {commentType === COMMENT_TYPES.GENERAL
          ? collectedComments.map((item, index) =>
              item.type === 'bunch' ? (
                <CollectedComment
                  key={index}
                  comments={item.comments}
                  infralistStatus={status}
                  acceptComment={acceptComment}
                  rejectComment={rejectComment}
                  isEditEnabledByEventStatus={isEditEnabledByEventStatus}
                />
              ) : (
                item?.comments.map((comment, commentIndex) => (
                  <Comment
                    data={comment}
                    key={`${commentIndex}-${index}`}
                    onDelete={() => {
                      setCurrentId(comment.id);
                      setDeleteModal(true);
                    }}
                    canDeleted={item.canDeleted}
                    onEdit={updateComment}
                    isEditEnabledByEventStatus={isEditEnabledByEventStatus}
                  />
                ))
              )
            )
          : data.map((item) => (
              <Comment
                data={item}
                key={item.id}
                canDeleted
                onDelete={() => {
                  setCurrentId(item.id);
                  setDeleteModal(true);
                }}
                onEdit={updateComment}
                isEditEnabledByEventStatus={isEditEnabledByEventStatus}
              />
            ))}
      </div>
      {canCreate(commentType, data) && (
        <Formik
          initialValues={{
            content: ''
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(form) => (
            <Form>
              <FormikTextField
                multiline
                name={'content'}
                placeholder={'Комментарий...'}
              />
              <ProgressButton
                color={'secondary'}
                className={s.CommentButton}
                type={'submit'}
                isLoading={loading}
              >
                Добавить
              </ProgressButton>
              <Button color={'default'} onClick={() => form.resetForm()}>
                Отменить
              </Button>
            </Form>
          )}
        </Formik>
      )}
      <Dialog
        title={'Вы уверены, что хотите удалить комментарий?'}
        open={deleteModal}
        handleAccept={() => {
          deleteComment(currentId);
          setCurrentId(undefined);
          setDeleteModal(false);
        }}
        handleClose={() => {
          setCurrentId(undefined);
          setDeleteModal(false);
        }}
      />
    </div>
  );
}

CommentList.propTypes = {
  data: PropTypes.array,
  createComment: PropTypes.func,
  updateComment: PropTypes.func,
  deleteComment: PropTypes.func,
  canModifyOwn: PropTypes.func,
  canModifyAll: PropTypes.bool
};
