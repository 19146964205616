import { BehaviorStore } from '@proscom/prostore';

export class LoginStore extends BehaviorStore {
  constructor() {
    super({
      targetPath: null
    });
  }

  setTargetPath = (path) => {
    this.setState({ targetPath: path });
  };
}
