import React from 'react';
import { CustomFastField } from '../../../../../common/Inputs/CustomFastField';
import TableInput from '../../../../../common/Table/TableInput';
import InputGroup from '../../../../../common/Inputs/InputGroup';
import { RpImplementationForecast } from '../../../../../store/rpImplementationForecast/RpImplementationForecast';
import {
  TableCurrencyInput,
  TableTextInput
} from '../../../../../common/Inputs/TextInput';
import { formatCurrency } from '../../../../../utils/number';
import { NoWrap } from '../../../../../common/NoWrap';

const getSubsidyForecastsColumns = (mbtFinances) => {
  return [
    {
      editComponent: ({ rowData }) => {
        return <span>{rowData.federal_project?.name_full}</span>;
      },
      title: 'Региональный проект',
      headerStyle: RpImplementationForecast.editTableStyles.tableCellMedium,
      render: ({ federal_project }) => <span>{federal_project?.name_full}</span>
    },
    {
      editComponent: ({ rowData }) => {
        return <span>{rowData.expenses_direction?.code}</span>;
      },
      title: 'Код направления расходов',
      headerStyle: RpImplementationForecast.editTableStyles.tableCellSmall,
      render: ({ expenses_direction }) => (
        <span>{expenses_direction?.code}</span>
      )
    },
    {
      editComponent: ({ rowData }) => {
        return <span>{rowData.expenses_direction?.name_full}</span>;
      },
      title: 'Наименование направления расходов',
      headerStyle: RpImplementationForecast.editTableStyles.tableCellLarge,
      render: ({ expenses_direction }) => (
        <span>{expenses_direction?.name_full}</span>
      )
    },
    {
      editComponent: ({ rowData }) => {
        return (
          <NoWrap>
            {formatCurrency(
              RpImplementationForecast.getMbtFinance(
                rowData.expenses_direction,
                mbtFinances
              )
            )}
          </NoWrap>
        );
      },
      title: 'Сумма субсидии на 2020 год',
      headerStyle: RpImplementationForecast.editTableStyles.tableCellSmall,
      render: ({ expenses_direction }) => {
        return (
          <NoWrap>
            {formatCurrency(
              RpImplementationForecast.getMbtFinance(
                expenses_direction,
                mbtFinances
              )
            )}
          </NoWrap>
        );
      }
    },
    {
      editComponent: TableCurrencyInput,
      field: 'forecast_value',
      title: 'Прогнозная сумма освоения субсидии на конец 2020 года',
      headerStyle: RpImplementationForecast.editTableStyles.tableCellSmall,
      render: ({ forecast_value }) =>
        forecast_value === '' ? '' : formatCurrency(forecast_value)
    },
    {
      editComponent: TableTextInput,
      field: 'annotation',
      title: 'Примечание',
      headerStyle: RpImplementationForecast.editTableStyles.tableCellLarge
    }
  ];
};

export const SubsidyForecastsFields = ({ mbtFinances }) => {
  return (
    <InputGroup>
      <CustomFastField
        name="subsidy_forecasts"
        columns={getSubsidyForecastsColumns(mbtFinances)}
        component={TableInput}
        canAdd={false}
        canDelete={false}
        tableName="Информация по субсидии"
      />
    </InputGroup>
  );
};
