import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import classNames from 'classnames';
import SubTable from '../../../../common/SubTable/SubTable';
import { getName } from '../../../../utils/data/label';
import s from '../RpImplementationForecastPage.module.scss';

export const AgreementForecasts = ({ agreementForecasts }) => {
  return (
    <SubTable title="Изменения в соглашение" cols={3}>
      <TableRow>
        <TableCell className={classNames(s.tableCell, s.tableCellMedium)}>
          Региональный проект
        </TableCell>
        <TableCell className={classNames(s.tableCell, s.tableCellMedium)}>
          Номер соглашения
        </TableCell>
        <TableCell className={classNames(s.tableCell, s.tableCellLarge)}>
          Предложения
        </TableCell>
      </TableRow>
      {agreementForecasts?.map((agreementForecast, iAgreementForecast) => (
        <TableRow key={iAgreementForecast}>
          <TableCell className={classNames(s.tableCell, s.tableCellMedium)}>
            {getName(agreementForecast.federal_project)}
          </TableCell>
          <TableCell className={classNames(s.tableCell, s.tableCellMedium)}>
            {agreementForecast.agreement_number}
          </TableCell>
          <TableCell className={classNames(s.tableCell, s.tableCellLarge)}>
            {agreementForecast.suggestions}
          </TableCell>
        </TableRow>
      ))}
    </SubTable>
  );
};
