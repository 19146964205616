import React, { useCallback } from 'react';
import { generatePath } from 'react-router-dom';
import { TableContextMenu } from '../../../../../common/infralist/InfralistTable/TableContextMenu/TableContextMenu';
import { infralistRoutes } from '../../../../../store/infralist/routes/infralistRoutes';
import { useInfralistSectionCheckers } from '../../../../../store/infralist/useInfralistUserAccesses';
import { useVisibilityCommentLink } from '../../../../../store/infralist/useVisibilityCommentLink';
import { CommentLink } from '../../../CommentLink';

export const useInfralistSectionTableActions = ({
  infralistId,
  setEditRow,
  setDeleteItemId,
  directionId,
  sectionId,
  sectionStatus,
  infralistSectionCheckStatus,
  infralistStatus,
  checkIsBlocked,
  lockEditing,
  isEditEnabledByEventStatus
}) => {
  const { canSeeCommentsIfHasComments } = useVisibilityCommentLink(
    infralistStatus
  );

  const { checkEditEquipments } = useInfralistSectionCheckers({
    sectionStatus,
    infralistSectionCheckStatus,
    infralistStatus,
    isEditEnabledByEventStatus
  });
  return useCallback(
    (dataItem) => {
      const showEditContextMenu = checkEditEquipments(dataItem);
      const isBlocked = checkIsBlocked(dataItem.id);
      return (
        <>
          {!!showEditContextMenu && (
            <TableContextMenu
              options={[
                {
                  disabled: isBlocked,
                  title: 'Редактировать',
                  isTooltipExists: isBlocked,
                  onClick: () => {
                    lockEditing({
                      objectId: dataItem.id,
                      objectType: 'Equipment'
                    });
                    setEditRow(dataItem.id);
                  },
                  tooltipTitle:
                    'Данная позиция оборудования уже редактируется, попробуйте позже'
                },
                {
                  disabled: isBlocked,
                  title: 'Удалить',
                  onClick: () => setDeleteItemId(dataItem.id),
                  tooltipTitle:
                    'Данная позиция оборудования уже редактируется, попробуйте позже',
                  isTooltipExists: isBlocked
                }
              ]}
            />
          )}
          {canSeeCommentsIfHasComments && !dataItem.has_commentaries ? null : (
            <CommentLink
              href={generatePath(infralistRoutes.EQUIPMENT_COMMENT, {
                infralistId,
                directionId,
                sectionId,
                equipmentId: dataItem.id,
                type: 'general'
              })}
              notRead={dataItem.has_commentaries}
            />
          )}
        </>
      );
    },
    [
      checkEditEquipments,
      canSeeCommentsIfHasComments,
      infralistId,
      directionId,
      sectionId,
      setEditRow,
      setDeleteItemId,
      checkIsBlocked,
      lockEditing
    ]
  );
};
