import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Form } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { useRegions } from '../../../store/subsidiaryEntity/useSubsidiaryEntity';
import { User } from '../../../store/role/User';
import { useMemoSuggestions } from '../../../utils/suggestions';
import { FormHeader } from '../../../common/Form/Form';
import InputGroup from '../../../common/Inputs/InputGroup';
import SubTable from '../../../common/SubTable/SubTable';
import {
  useSubsidiaryEntitiesForFilter,
  useSubsidiaryEntitiesForMonitoring
} from '../../../store/subsidiaryEntity/useSubsidiaryEntities';
import { SubsidiaryEntity } from '../../../store/subsidiaryEntity/SubsidiaryEntity';
import { yearsSuggestions } from '../../../utils/data/date';
import {
  useRoadmaps,
  useRoadmapsForMonitoring
} from '../../../store/roadmap/useRoadmaps';
import { EMDASH } from '../../../utils/constants';
import { SubsidiaryMonitoringEntity } from '../../../store/subsidiaryMonitoring/SubsidiaryMonitoringEntity';
import AutocompleteBaseInput from '../../../common/Inputs/Selects/AutocompleteBaseInput';
import Loader from '../../../common/Loader/Loader';
import { useUserRole } from '../../../store/role/useUserRole';
import { ContestDocsMonitoringEntity } from '../../../store/contestDocsMonitoring/ContestDocsMonitoringEntity';
import { useGeneralConfirmation } from '../../../common/Form/useGeneralConfirmation';
import { SubsidiaryMonitoringFormEditable } from './SubsidiaryMonitoringFormEditable';
import { SubsidiaryMonitoringFormPreview } from './SubsidiaryMonitoringFormPreview';
import { SubsidiaryMonitoringEntityValidationDialog } from './SubsidiaryMonitoringEntityValidationDialog';
import s from './SubsidiaryMonitoringForm.module.scss';

export const SubsidiaryMonitoringForm = ({
  onBackClick,
  isSubmitting,
  isValid,
  editor,
  dirty,
  touched,
  errors,
  values,
  submitForm,
  resetForm,
  setFieldValue,
  filter,
  entity,
  entityLoading,
  entityDeleting,
  exportLoading,
  onFilterChange,
  onDelete,
  onRequestEdit,
  onAllowEdit,
  onRejectEdit,
  onReturn,
  onExport,
  onExportHistoryOpen
}) => {
  const user = useCurrentUser();
  const { isRegionAdmin, isOrganizationAdmin } = useUserRole(user);
  const [isValidationError, setIsValidationError] = useState(false);
  const { year, region_id, roadmap_id } = filter;

  const regionsQuery = useRegions();
  const allRegions = regionsQuery.state.data;
  const allRegionsLoading = regionsQuery.check.spinner;
  const regions = useMemo(() => User.getRegions(user, allRegions), [
    user,
    allRegions
  ]);
  const regionsSuggestions = useMemoSuggestions(regions);

  const roadmapFilter = useMemo(() => {
    return region_id && year
      ? {
          form_year: +year,
          region_id
        }
      : null;
  }, [region_id, year]);
  const roadmapsQuery = useRoadmapsForMonitoring(roadmapFilter);
  const roadmaps = roadmapsQuery.state.data;
  const roadmapsLoading = roadmapsQuery.check.spinner;
  const roadmapsSuggestions = useMemoSuggestions(roadmaps);

  const subsidiaryEntitiesFilter = useMemo(() => {
    if (isOrganizationAdmin) {
      return year
        ? {
            form_year: +year
          }
        : null;
    } else {
      return region_id && roadmap_id && year
        ? {
            form_year: +year,
            region_id,
            roadmap_id
          }
        : null;
    }
  }, [region_id, year, roadmap_id, isOrganizationAdmin]);
  const subsidiaryEntitiesQuery = useSubsidiaryEntitiesForMonitoring(
    subsidiaryEntitiesFilter
  );
  const subsidiaryEntitiesLoading = subsidiaryEntitiesQuery.check.spinner;
  const querySubsidiaryEntities = subsidiaryEntitiesQuery.state.data;
  const subsidiaryEntities = useMemo(() => {
    return querySubsidiaryEntities?.map((entity) => ({
      ...entity,
      name_full: SubsidiaryEntity.getCompleteName(entity)
    }));
  }, [querySubsidiaryEntities]);
  const subsidiaryEntitiesSuggestions = useMemoSuggestions(subsidiaryEntities);
  const subsidiaryEntityCreationYear = useMemo(() => {
    return subsidiaryEntities?.find(
      (se) => se.id === filter.subsidiary_entity_id
    )?.year_of_creation;
  }, [filter.subsidiary_entity_id, subsidiaryEntities]);

  const canEdit = SubsidiaryMonitoringEntity.canEdit(user, entity);
  const canApprove = SubsidiaryMonitoringEntity.canApprove(user);
  const canRevoke = SubsidiaryMonitoringEntity.canRevoke(user);

  const entityStatus = entity?.status;
  const isApproved =
    entityStatus === SubsidiaryMonitoringEntity.Status.APPROVED;
  const isOnApproval =
    entityStatus === SubsidiaryMonitoringEntity.Status.ON_APPROVAL;
  const isDraft = entityStatus === SubsidiaryMonitoringEntity.Status.DRAFT;
  const isReturned =
    entityStatus === SubsidiaryMonitoringEntity.Status.RETURNED;
  const isEditRequested =
    entityStatus === ContestDocsMonitoringEntity.Status.EDIT_REQUESTED;

  const editable = canEdit && (isDraft || isReturned);

  const handleApprove = useCallback(
    (status) => {
      if (dirty) {
        resetForm();
      }
      setFieldValue('submitStatus', status, false);
      setTimeout(submitForm, 0);
    },
    [dirty, submitForm, resetForm, setFieldValue]
  );

  const handleSubmit = useCallback(() => {
    setFieldValue(
      'submitStatus',
      SubsidiaryMonitoringEntity.formSubmitStatus.Submit,
      false
    );
  }, [setFieldValue]);

  const [approveDialog, openApproveDialog] = useGeneralConfirmation(
    handleApprove,
    {
      title: 'Вы уверены, что хотите утвердить форму?',
      children:
        dirty && 'При данном действии все несохраненные данные будут утеряны!'
    }
  );
  const [
    sendForApprovalDialog,
    openSendForApprovalDialog
  ] = useGeneralConfirmation(handleApprove, {
    title: 'Вы уверены, что хотите отправить форму на утверждение?',
    children:
      dirty && 'При данном действии все несохраненные данные будут утеряны!'
  });

  const isApprove =
    values['submitStatus'] ===
    SubsidiaryMonitoringEntity.formSubmitStatus.Approve;
  const isSendForApproval =
    values['submitStatus'] ===
    SubsidiaryMonitoringEntity.formSubmitStatus.SendForApproval;
  const isApprovingStatus = isApprove || isSendForApproval;

  useEffect(() => {
    if (isApprovingStatus && !isValid) {
      setIsValidationError(true);
      // setFieldValue(
      //   'submitStatus',
      //   SubsidiaryMonitoringEntity.formSubmitStatus.Submit,
      //   false
      // );
    }
  }, [isApprovingStatus, isValid]);

  return (
    <>
      <Prompt
        // when={entity && status !== FORM_STATUS_SUBMITTED}
        when={!!entity && editable && !entityDeleting}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />

      <SubsidiaryMonitoringEntityValidationDialog
        isOpen={isValidationError}
        onCancel={() => setIsValidationError(false)}
      >
        {isApprove &&
          'Вы не можете утвердить форму, пока не заполнены все обязательные поля'}
        {isSendForApproval &&
          'Вы не можете отправить форму на утверждение форму, пока не заполнены все обязательные поля'}
      </SubsidiaryMonitoringEntityValidationDialog>

      <Form noValidate>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting}
          editor={editor}
          editable={editable}
          isSubmitting={isSubmitting}
          onSubmitClick={handleSubmit}
          actions={
            entity && (
              <>
                {(isApproved || isEditRequested) && (
                  <Button
                    color="default"
                    variant="outlined"
                    onClick={() => onExport(entity.id)}
                  >
                    {exportLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Экспортировать'
                    )}
                  </Button>
                )}

                <Button
                  color="default"
                  variant="outlined"
                  onClick={onExportHistoryOpen}
                >
                  История экспорта
                </Button>

                {canEdit && (isDraft || isReturned) && (
                  <Button
                    color="secondary"
                    variant="outlined"
                    disabled={isSubmitting}
                    onClick={() => onDelete(entity.id)}
                  >
                    Удалить
                  </Button>
                )}

                {canApprove && (isDraft || isReturned || isOnApproval) && (
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={() =>
                      openApproveDialog(
                        SubsidiaryMonitoringEntity.formSubmitStatus.Approve
                      )
                    }
                  >
                    Утвердить
                  </Button>
                )}

                {canEdit && !canApprove && (isDraft || isReturned) && (
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={() =>
                      openSendForApprovalDialog(
                        SubsidiaryMonitoringEntity.formSubmitStatus
                          .SendForApproval
                      )
                    }
                  >
                    Отправить на утверждение
                  </Button>
                )}

                {canEdit && !canApprove && isApproved && (
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={() => onRequestEdit(entity.id)}
                  >
                    Запросить редактирование
                  </Button>
                )}

                {canRevoke && isEditRequested && (
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={() => onRejectEdit(entity.id)}
                  >
                    Запретить редактирование
                  </Button>
                )}

                {canRevoke && isEditRequested && (
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={() => onAllowEdit(entity.id)}
                  >
                    Разрешить редактирование
                  </Button>
                )}

                {canRevoke && (isApproved || isOnApproval) && (
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={() => onReturn(entity.id)}
                  >
                    Вернуть на доработку
                  </Button>
                )}
              </>
            )
          }
        />

        <SubTable title="Общие сведения">
          <TableRow>
            <TableCell>Статус</TableCell>
            <TableCell>
              {SubsidiaryMonitoringEntity.statusLabel[values.status] || EMDASH}
            </TableCell>
          </TableRow>
        </SubTable>

        <InputGroup>
          <AutocompleteBaseInput
            label="Год"
            suggestions={yearsSuggestions}
            value={filter.year}
            hasCopyBtn={false}
            fullWidth
            required
            isClearable={false}
            onChange={(value) => onFilterChange('year', value)}
          />
          <AutocompleteBaseInput
            label="Месяц"
            suggestions={SubsidiaryMonitoringEntity.monthSuggestions}
            value={filter.month}
            hasCopyBtn={false}
            fullWidth
            required
            isClearable={false}
            onChange={(value) => onFilterChange('month', value)}
          />
          {!isOrganizationAdmin && (
            <>
              <AutocompleteBaseInput
                label="Регион"
                suggestions={regionsSuggestions}
                suggestionsLoading={allRegionsLoading}
                value={filter.region_id}
                hasCopyBtn={false}
                fullWidth
                required
                disabled={isRegionAdmin}
                onChange={(value) => onFilterChange('region_id', value)}
              />
              <AutocompleteBaseInput
                label="Дорожная карта"
                suggestions={roadmapsSuggestions}
                suggestionsLoading={roadmapsLoading}
                value={filter.roadmap_id}
                hasCopyBtn={false}
                fullWidth
                required
                disabled={!roadmapFilter}
                // resetIfNotExists={true}
                placeholder={!roadmapFilter && 'Сначала укажите год и регион'}
                onChange={(value) => onFilterChange('roadmap_id', value)}
              />
            </>
          )}
          <AutocompleteBaseInput
            label="Полное наименование субсидиарной сущности"
            suggestions={subsidiaryEntitiesSuggestions}
            suggestionsLoading={subsidiaryEntitiesLoading}
            value={filter.subsidiary_entity_id}
            hasCopyBtn={false}
            fullWidth
            required
            disabled={!subsidiaryEntitiesFilter}
            // resetIfNotExists={true}
            placeholder={
              !subsidiaryEntitiesFilter &&
              (isOrganizationAdmin
                ? 'Сначала укажите год'
                : 'Сначала укажите год, регион и дорожную карту')
            }
            hintMessage={
              subsidiaryEntityCreationYear &&
              `Год создания СС: ${subsidiaryEntityCreationYear}`
            }
            onChange={(value) => onFilterChange('subsidiary_entity_id', value)}
          />
        </InputGroup>

        {(entityLoading && (
          <Loader className={s.SubsidiaryMonitoringForm__loader} />
        )) ||
          (entity ? (
            editable ? (
              <SubsidiaryMonitoringFormEditable
                entity={entity}
                values={values}
                touched={touched}
                errors={errors}
              />
            ) : (
              <SubsidiaryMonitoringFormPreview entity={entity} />
            )
          ) : (
            <h3>Нет информации по выбранным фильтрам</h3>
          ))}
      </Form>

      {approveDialog}
      {sendForApprovalDialog}
    </>
  );
};
