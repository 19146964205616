import React from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { SubsidiaryEntityEditValidationSchema } from '../../../../utils/data/validation';
import { SubsidiaryEntityEditForm } from '../edit/SubsidiaryEntityEditForm';
import { removeLastPathItem } from '../../../../utils/url';
import { useCurrentUser } from '../../../../store/useCurrentUser';
import { SubsidiaryEntity } from '../../../../store/subsidiaryEntity/SubsidiaryEntity';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../graphql/graphqlErrors';
import { SUBSIDIARY_ENTITY_STORE } from '../../../../store/names';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { SubsidiaryEntityBreadCrumbs } from '../SubsidiaryEntityBreadCrumbs';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import {
  getCurrentYear,
  subsidiaryEntitiesYearsSuggestions
} from '../../../../utils/data/date';

export default function SubsidiaryEntityCreatePage({
  match,
  history,
  location
}) {
  const backLink = removeLastPathItem(match.url);
  const user = useCurrentUser();
  const subsidiaryEntityStore = useContextStore(SUBSIDIARY_ENTITY_STORE);

  const initialValues = {
    address: '',
    name_full: '',
    base_organization_id: '',
    base_organization_name: '',
    index: '',
    region_string: user.region?.name || '',
    locality: '',
    street: '',
    house: '',
    building: '',
    latitude: '',
    longitude: '',
    inn: '',
    kpp: '',
    showAddressFields: false,
    region_id: user.region?.id || '',
    owner_roadmap_id: '',
    supervisor: {
      surname: '',
      first_name: '',
      patronymic: '',
      phones: [''],
      emails: ['']
    },
    year_of_creation: getCurrentYear().toString()
  };

  return (
    <>
      <HeadTitle
        type="Субсидиарная сущность"
        pageType={HeadTitle.PageType.create}
      />
      <SubsidiaryEntityBreadCrumbs />
      <h2>Создание субсидиарной сущности</h2>
      <Formik
        validationSchema={SubsidiaryEntityEditValidationSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          subsidiaryEntityStore
            .createSubsidiaryEntity(SubsidiaryEntity.fromForm(values))
            .then((result) => {
              actions.setStatus(FORM_STATUS_SUBMITTED);
              setTimeout(() => {
                history.push(SubsidiaryEntity.getUrl(result.data.result));
              }, 0);
            })
            .catch((error) => {
              showGraphqlErrorToast(error, mutationErrors.updateObject);
            })
            .then(() => {
              actions.setSubmitting(false);
            });
        }}
        render={(formikProps) => (
          <SubsidiaryEntityEditForm
            {...formikProps}
            onBackClick={() => history.goBack(backLink)}
            createForm
            availableYears={subsidiaryEntitiesYearsSuggestions}
          />
        )}
      />
    </>
  );
}
