export const INFRALIST_QUERIES = {
  // infralist
  INFRALIST: 'infralist',

  // список направлений
  DIRECTIONS_LIST: 'infralistDirections',
  // 1 направление
  DIRECTION: 'infralistDirection',

  // список разделов
  DIRECTION_SECTIONS: 'infralistDirectionSections',
  // 1 раздел
  SECTION: 'infralistSection',

  // наименование оборудования
  EQUIPMENT_LIST: 'fpoEquipmentList',

  // список оборудования
  INFRALIST_SECTION_RVPO_EQUIPMENT: 'infralistSectionRvpoEquipments',
  // 1 оборудование
  RVPO_EQUIPMENT: 'infralistRvpoEquipment',

  // комментарии section
  SECTION_RVPO_COMMENTARIES: 'infralistSectionRvpoCommentaries',
  SECTION_FPO_COMMENTARIES: 'infralistSectionFpoCommentaries',
  SECTION_GENERAL_COMMENTARIES: 'infralistSectionGeneralCommentaries',

  // комментарии equipment
  RVPO_EQUIPMENT_RVPO_COMMENTARIES: 'infralistRvpoEquipmentRvpoCommentaries',
  RVPO_EQUIPMENT_FPO_COMMENTARIES: 'infralistRvpoEquipmentFpoCommentaries',
  RVPO_EQUIPMENT_GENERAL_COMMENTARIES:
    'infralistRvpoEquipmentGeneralCommentaries',

  // комментарии direction
  DIRECTION_RVPO_COMMENTARIES: 'infralistDirectionRvpoCommentaries',
  DIRECTION_FPO_COMMENTARIES: 'infralistDirectionFpoCommentaries',
  DIRECTION_GENERAL_COMMENTARIES: 'infralistDirectionGeneralCommentaries'
};
