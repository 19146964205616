import { toast } from 'react-toastify';
import {
  createFieldParser,
  findClosestSuggestion,
  parseEnum
} from '../../utils/data/xlsx';
import { Pof } from './Pof';

export class PofXlsx {
  static parseXlsxPof = async (wb) => {
    const sheetName = wb.SheetNames[0];
    const sheet = wb.Sheets[sheetName];

    const parser = await createFieldParser(sheet);

    const data = {
      region_name: sheet['B1']?.v,
      expenses_direction_name: sheet['B2']?.v,
      year: sheet['B3']?.v,
      month_name: sheet['B4']?.v,
      federal_project_name: sheet['B5']?.v,
      result_name: sheet['B6']?.v,
      status: sheet['B7']?.v,
      comment_fpo: sheet['B8']?.v,
      values: []
    };

    const valuesStartRow = 10;
    for (let q = 0; q < 4; q++) {
      for (let m = 0; m < 3; m++) {
        const row = valuesStartRow + q * 4 + m;
        const d = {
          value: parser.getNumber(1, row),
          comment: parser.get(2, row),
          month: q * 3 + m + 1
        };

        data.values.push(d);
      }
    }

    return data;
  };

  static async getUpdates(data, fields) {
    const update = {};

    for (const field of Object.keys(fields)) {
      if (field === 'values') {
        update.values = data.values;
      } else if (field === 'status') {
        update.status = parseEnum(Pof.status, data.status);
      } else if (field === 'comment_fpo') {
        update.comment_fpo = data.comment_fpo;
      } else if (field === 'expenses_direction_id') {
        const option = findClosestSuggestion(
          data.expenses_direction_name,
          fields[field]
        );
        if (option) {
          update.expenses_direction_id = option.value;
        } else {
          toast.warn(`Неизвестное значение '${data.expenses_direction_name}'`);
        }
      }
    }

    return update;
  }
}
