import React, { useCallback } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SubTable from '../SubTable/SubTable';
import { useFieldFast } from '../InputsNew/useFieldFast';
import { ActionButton } from './ActionButton';
import s from './Permissions/Permissions.module.scss';

export function FormArrayInput({
  title,
  titleAdd,
  cols,
  head,
  getNewValue,
  deleteValue,
  itemComponent: Item,
  ...props
}) {
  const [field, meta, helpers] = useFieldFast(props);
  const items = meta.value;
  const { setValue } = helpers;

  const handleAdd = useCallback(() => {
    const value = getNewValue(items);
    if (value) {
      setValue([...items, value]);
    }
  }, [items, getNewValue, setValue]);

  const handleDelete = useCallback(
    (index) => {
      const processed = deleteValue(items, index);
      if (processed) {
        setValue(processed);
      }
    },
    [deleteValue, items, setValue]
  );

  return (
    <SubTable title={title} cols={cols} head={head} editVariant>
      {items?.map((item, index) => {
        return (
          <Item
            key={index}
            item={item}
            index={index}
            onDelete={handleDelete}
            name={`${props.name}[${index}]`}
          />
        );
      })}
      <TableRow>
        <TableCell className={s.buttonCell} colSpan={cols}>
          <ActionButton onClick={handleAdd} title={titleAdd} />
        </TableCell>
      </TableRow>
    </SubTable>
  );
}
