import { getRoadmapBreadCrumbs } from '../RoadmapBreadCrumbs';

export function getFederalEventBreadCrumbs({
  projectId,
  projectName,
  roadmapId,
  roadmapName
}) {
  return [
    ...getRoadmapBreadCrumbs({
      projectId,
      projectName
    }),
    {
      label: roadmapName || '...',
      to: `/roadmaps/${roadmapId}`
    },
    {
      label: 'Мероприятия',
      to: `/roadmaps/${roadmapId}?tab=events`
    }
  ];
}
