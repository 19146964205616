import React, { useCallback } from 'react';
import AutocompleteBaseInput from '../../../../common/Inputs/Selects/AutocompleteBaseInput';
import { yearsSuggestions } from '../../../../utils/data/date';
import { YearSelect } from '../../../../common/YearSelect';

export const useProjectExpensesDirectionsFilter = (
  changeQuery,
  project,
  projectYear
) => {
  const filter = {
    year: projectYear
  };

  const filterComponent = (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <YearSelect
        label="Год"
        year={filter.year}
        years={project?.implementation_years}
        onChange={changeQuery}
      />
    </div>
  );
  return [filterComponent, filter];
};
