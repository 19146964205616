import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import classNames from 'classnames';
import SubTable from '../../../../common/SubTable/SubTable';
import { getName } from '../../../../utils/data/label';
import s from '../RpImplementationForecastPage.module.scss';

export const RoadmapForecasts = ({ roadmapForecasts }) => {
  return (
    <SubTable title="Изменение в ДК" cols={2}>
      <TableRow>
        <TableCell className={classNames(s.tableCell, s.tableCellMedium)}>
          Дорожная карта
        </TableCell>
        <TableCell className={classNames(s.tableCell, s.tableCellLarge)}>
          Предложения
        </TableCell>
      </TableRow>
      {roadmapForecasts?.map((roadmapForecast, iRoadmapForecast) => (
        <TableRow key={iRoadmapForecast}>
          <TableCell className={classNames(s.tableCell, s.tableCellMedium)}>
            {getName(roadmapForecast.roadmap)}
          </TableCell>
          <TableCell className={classNames(s.tableCell, s.tableCellLarge)}>
            {roadmapForecast.suggestions}
          </TableCell>
        </TableRow>
      ))}
    </SubTable>
  );
};
