import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { FormEditForm } from '../edit/FormEditForm';
import { Form } from '../../../../store/form/Form';
import { handleFormSubmission } from '../../../../utils/data/form';
import { STORE_FORM } from '../../../../store/names';
import { removeLastPathItem } from '../../../../utils/url';
import BreadCrumbs from '../../../../common/BreadCrumbs/BreadCrumbs';
import { getFormBreadCrumbs } from '../getFormBreadCrumbs';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';

const initialValues = {
  name: '',
  fields: []
};

export default function FormCreatePage({ match, history }) {
  const backLink = removeLastPathItem(match.url);
  const itemStore = useContextStore(STORE_FORM);

  const breadCrumbs = useMemo(() => getFormBreadCrumbs(), []);

  return (
    <>
      <HeadTitle pageType={HeadTitle.PageType.create} type="Форма" />
      <BreadCrumbs links={breadCrumbs} />
      <h2>Создание формы</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          const form = Form.fromForm(values);
          handleFormSubmission(itemStore.createForm(form), actions, (result) =>
            history.goBack(Form.getUrl(result.data.result))
          );
        }}
        render={(formikProps) => (
          <FormEditForm
            {...formikProps}
            onBackClick={() => history.goBack(backLink)}
          />
        )}
      />
    </>
  );
}
