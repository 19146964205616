import React, { useCallback, useState } from 'react';
import Link from '@material-ui/core/Link';
import { generatePath } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '../../../../common/Dialog';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { Popup } from '../../../../common/Popup/Popup';
import { useTableManager } from '../../../../common/infralist/InfralistTable/useTableManager';
import { infralistRoutes } from '../../../../store/infralist/routes/infralistRoutes';
import { useDirection } from '../../../../store/infralist/useDirection';
import { useInfralist } from '../../../../store/infralist/useInfralist';
import { useInfralistEquipmentList } from '../../../../store/infralist/useInfralistEquipmentList';
import {
  useInfralistSectionUserAccesses,
  useInfralistSectionCheckers
} from '../../../../store/infralist/useInfralistUserAccesses';
import { useSection } from '../../../../store/infralist/useSection';
import { InfralistCheckStatus } from '../../InfralistCheckStatus';
import { InfralistStatus } from '../../InfralistStatus';
import { PageTitlePanel } from '../../PageTitlePanel';
import { InfralistTable } from '../../../../common/infralist/InfralistTable/InfralistTable';
import { useSubscribeEquipmentList } from '../../../../store/infralist/subscription/useSubscribeEquipmentList';
import { FiltersEquipment } from './components/FiltersEquipment';
import { InfralistSectionActions } from './components/InfralistSectionActions';
import { InfralistSectionBreadCrumbs } from './components/InfralistSectionBreadCrumbs';
import { useEquipmentActions } from './hooks/useEquipmentActions';
import { useInfralistSectionTableActions } from './hooks/useInfralistSectionTableActions';
import { useInfralistSectionTableColumns } from './hooks/useInfralistSectionTableColumns';
import { useInfralistSectionValidationSchema } from './hooks/useInfralistSectionValidationSchema';
import styles from './InfralistSection.module.scss';

export default function InfralistSection() {
  const { infralistId, directionId, sectionId } = useParams();

  // section
  const sectionQuery = useSection(sectionId, infralistId);
  const section = {
    spinner: sectionQuery.check.spinner,
    data: sectionQuery.state.data
  };
  const infralistSectionStatus = section.data?.status?.name;
  const infralistSectionCheckStatus = section.data?.check_status?.name;
  const infralistSectionApproveStatus = section.data?.approvement_status?.name;

  // direction
  const directionQuery = useDirection(directionId, infralistId);
  const direction = directionQuery.state?.data;

  // infralist
  const infralistQuery = useInfralist(infralistId);
  const infralistStatus = infralistQuery.state.data?.infralist_status?.name;

  // accesses
  const { canEdit, showCheckStatusInHeader } = useInfralistSectionUserAccesses({
    sectionStatus: infralistSectionStatus,
    infralistSectionCheckStatus,
    infralistStatus,
    infralistSectionApproveStatus,
    isEditEnabledByEventStatus: infralistQuery.state.data?.editable
  });
  const { checkEditEquipments } = useInfralistSectionCheckers({
    sectionStatus: infralistSectionStatus,
    infralistSectionCheckStatus,
    infralistStatus,
    isEditEnabledByEventStatus: infralistQuery.state.data?.editable
  });

  const [blockEditingPopup, setBlockEditingPopup] = useState(false);

  // table
  const {
    editRowId,
    setEditRow,
    deleteItemId,
    setDeleteItemId,
    clearEditRow
  } = useTableManager();

  // equipment list
  const {
    data: equipmentList,
    spinner: infralistEquipmentSpinner,
    sorting,
    loadMore,
    setSort,
    updateEquipmentInList,
    resetEquipmentList,
    deleteEquipmentFromList,
    onSetStatusesFilter,
    onSetCheckStatusesFilter,
    onSetApprovementStatusesFilter,
    approvementStatusesFilter,
    checkStatusesFilter,
    statusesFilter
  } = useInfralistEquipmentList({
    sectionId,
    infralistId
  });
  const {
    checkIsBlocked,
    lockEditing,
    unlockEditing,
    isEquipmentEditing,
    closeRefreshPagePopup,
    isRefreshPagePopupVisible
  } = useSubscribeEquipmentList(equipmentList);

  const equipmentDeleteSuccessHandler = useCallback(
    (equipmentId) => {
      deleteEquipmentFromList(equipmentId);
      setDeleteItemId(undefined);
    },
    [deleteEquipmentFromList, setDeleteItemId]
  );

  // equipment actions
  const {
    submitEditEquipment,
    submitDeleteEquipment,
    submitSignEquipment,
    submitCheckEquipment,
    submitApproveEquipment
  } = useEquipmentActions({
    infralistId,
    onItemDelete: equipmentDeleteSuccessHandler,
    onItemUpdate: updateEquipmentInList
  });

  const lockEquipmentEditing = ({ objectId, objectType }) => {
    return lockEditing({ objectType, objectId }).catch(() => {
      setBlockEditingPopup(true);
      clearEditRow();
    });
  };

  // table row validation
  const validationSchema = useInfralistSectionValidationSchema();
  // table actions
  const getActions = useInfralistSectionTableActions({
    infralistId,
    setEditRow,
    setDeleteItemId,
    sectionId,
    directionId,
    sectionStatus: infralistSectionStatus,
    infralistSectionCheckStatus,
    infralistStatus,
    checkIsBlocked,
    lockEditing: lockEquipmentEditing,
    isEditEnabledByEventStatus: infralistQuery.state.data?.editable
  });

  // table columns
  const [isSpecificationsHidden, setIsSpecificationsHidden] = useState(false);
  const columns = useInfralistSectionTableColumns({
    isSpecificationsHidden,
    sectionId,
    submitSignEquipment,
    submitCheckEquipment,
    submitApproveEquipment,
    sectionStatus: infralistSectionStatus,
    infralistSectionCheckStatus,
    checkEditEquipments,
    infralistStatus,
    infralistSectionApproveStatus,
    isEditEnabledByEventStatus: infralistQuery.state.data?.editable
  });
  const createEquipmentLink = `/infralist/${infralistId}/directions/${directionId}/sections/${sectionId}/create`;

  const onEditRow = (rowId) => {
    if (rowId) {
      lockEquipmentEditing({
        objectType: 'Equipment',
        objectId: rowId
      });
    } else if (editRowId) {
      unlockEditing({
        objectType: 'Equipment',
        objectId: editRowId
      });
    }
    setEditRow(rowId);
  };

  const closeBlockPopup = () => {
    setBlockEditingPopup(false);
  };

  return (
    <>
      <HeadTitle title={section.data?.name} />
      <InfralistSectionBreadCrumbs
        roadmapName={infralistQuery.state.data?.roadmap?.name}
        infralistId={infralistId}
        directionId={directionId}
        directionName={direction?.name}
      />
      <PageTitlePanel
        title={section.data?.name}
        backUrl={generatePath(infralistRoutes.DIRECTION, {
          infralistId,
          directionId
        })}
        description={
          showCheckStatusInHeader && infralistSectionCheckStatus ? (
            <InfralistCheckStatus
              isLoading={section.spinner}
              status={infralistSectionCheckStatus}
            />
          ) : (
            <InfralistStatus
              isLoading={section.spinner}
              status={infralistSectionStatus}
            />
          )
        }
        actions={
          <InfralistSectionActions
            equipmentsListNotEmpty={!!equipmentList?.length}
            infralistId={infralistId}
            sectionId={sectionId}
            sectionStatus={infralistSectionStatus}
            onCallReset={resetEquipmentList}
            directionId={directionId}
            infralistSectionCheckStatus={infralistSectionCheckStatus}
            isEquipmentEditing={!!editRowId}
            infralistStatus={infralistStatus}
            infralistSectionApproveStatus={infralistSectionApproveStatus}
            isEditingBlocked={isEquipmentEditing}
            isEditEnabledByEventStatus={infralistQuery.state.data?.editable}
          />
        }
      />

      <FiltersEquipment
        sum={section.data?.section_sum}
        statuses={statusesFilter}
        setStatuses={onSetStatusesFilter}
        checkStatuses={checkStatusesFilter}
        approvementStatuses={approvementStatusesFilter}
        setApprovementStatuses={onSetApprovementStatusesFilter}
        setCheckStatuses={onSetCheckStatusesFilter}
      />

      <div className={styles.actionLine}>
        <Button
          variant="contained"
          color={'primary'}
          component={RouterLink}
          to={createEquipmentLink}
          disabled={!canEdit}
        >
          <AddIcon className={styles.addButtonIcon} />
          Добавить оборудование
        </Button>
        <FormControlLabel
          control={
            <Checkbox
              checked={isSpecificationsHidden}
              onChange={() =>
                setIsSpecificationsHidden(!isSpecificationsHidden)
              }
              name="isSpecificationsHidden"
              color="primary"
              size="small"
            />
          }
          classes={{
            label: styles.hideCharacteristicsCheckbox
          }}
          label="Скрыть характеристики"
        />
      </div>
      <InfralistTable
        activeSortField={sorting?.field}
        activeSortOrder={sorting?.direction}
        onChangeSort={setSort}
        columns={columns}
        data={equipmentList}
        isLoading={infralistEquipmentSpinner}
        validationSchema={validationSchema}
        emptyMessage={
          <>
            {statusesFilter ||
            checkStatusesFilter ||
            approvementStatusesFilter ? (
              'Оборудование, удовлетворяющее условиям фильтрации, не найдено'
            ) : (
              <>
                Вы еще не добавили еще ни одного оборудования{' '}
                {canEdit && (
                  <Link
                    to={createEquipmentLink}
                    component={RouterLink}
                    variant="body2"
                  >
                    Добавить оборудование
                  </Link>
                )}
              </>
            )}
          </>
        }
        getActions={getActions}
        editRowId={editRowId}
        onUpdateRow={submitEditEquipment}
        onChangeEditRowId={onEditRow}
        onLoadMore={loadMore}
        checkItemEditAccess={(value) =>
          checkEditEquipments(value) && !checkIsBlocked(value?.id)
        }
        minWidth={1200}
      />
      <Dialog
        title={'Вы действительно хотите удалить оборудование?'}
        open={!!deleteItemId}
        handleAccept={() => submitDeleteEquipment(deleteItemId)}
        handleClose={() => setDeleteItemId(undefined)}
      >
        После подтверждения позиция оборудования будет удалена безвозвратно.
      </Dialog>
      <Popup
        open={blockEditingPopup}
        onClose={closeBlockPopup}
        title={'Оборудование уже редактируется'}
      >
        Данная позиция оборудования уже редактируется, попробуйте позже
      </Popup>
      <Popup
        open={isRefreshPagePopupVisible}
        onClose={closeRefreshPagePopup}
        title={'Оборудование было на редактировании, перезагрузите страницу.'}
      />
    </>
  );
}
