import React, { useEffect, useMemo, useState } from 'react';
import {
  RadioButton,
  RadioButtonGroup
} from '../../../../common/Inputs/RadioButtonGroup';
import { useSupplierDeterminations } from '../../../../store/purchase/useSupplierDeterminations';
import AutocompleteBaseInput from '../../../../common/Inputs/Selects/AutocompleteBaseInput';

function getLawsFromMap(lawsMap) {
  if (!lawsMap) return null;
  const values = Object.values(lawsMap);
  values.sort((a, b) => (!a ? 1 : !b ? -1 : a.id > b.id ? 1 : -1));
  return values;
}

const mapSuggestion = (s) => ({
  label: s.law ? `${s.law.name}. ${s.name}` : s.name,
  value: s.id,
  law_id: s.law_id
});

const getLawIdValue = (item) => {
  if (!item) return '';
  if (item.law_id) return item.law_id;
  return 'null';
};

export function SupplierDeterminationInput({
  field: { value: inputValue, name, onBlur },
  form: { setFieldValue: handleChange },
  label,
  errorMessage,
  required
}) {
  const query = useSupplierDeterminations();
  const options = query.state.data;
  const suggestionsLoading = query.check.spinner;

  const item = options && options.find((item) => item.id === inputValue);

  const [selected, setSelected] = useState(getLawIdValue(item));

  const optionsFiltered = selected
    ? options.filter(({ law_id }) => '' + law_id === selected)
    : options;

  const suggestions = optionsFiltered && optionsFiltered.map(mapSuggestion);

  useEffect(() => {
    if (item) {
      setSelected(getLawIdValue(item));
    }
  }, [item]);

  useEffect(() => {
    if (optionsFiltered && optionsFiltered.length === 1) {
      const value = optionsFiltered[0].id;
      if (value !== inputValue) {
        handleChange(name, value);
      }
    }
  });

  const handleRadioButtonClick = (e) => {
    if (e.target.value === selected) {
      setSelected('');
    }
  };

  const handleGroupChange = (e) => {
    setSelected(e.target.value);
    if (e.target.value !== selected) {
      handleChange(name, null);
    }
  };

  const handleValueChange = (item) => {
    handleChange(name, item);
    if (item) {
      if (typeof item === 'string') {
        setSelected(item);
      } else if (item.law_id !== selected) {
        setSelected(getLawIdValue(item));
      }
    }
  };

  const lawsMap = useMemo(() => {
    const map =
      options &&
      options.reduce((acc, { law, law_id }) => {
        if (!acc[law_id]) {
          acc[law_id] = law;
        }
        return acc;
      }, {});
    map && delete map.null;
    return map;
  }, [options]);

  const laws = getLawsFromMap(lawsMap);

  return (
    <>
      {laws && laws.length && (
        <RadioButtonGroup
          id="supplierDeterminationInput__radioGroup"
          value={selected}
          onChange={handleGroupChange}
          onClick={handleRadioButtonClick}
        >
          {laws.map((law, index) => {
            if (!law) {
              return (
                <RadioButton
                  key={index}
                  label="Универсальная заявка"
                  value="null"
                />
              );
            }
            return <RadioButton key={index} label={law.name} value={law.id} />;
          })}
          <RadioButton label="Другое" value="null" />
        </RadioButtonGroup>
      )}
      <AutocompleteBaseInput
        name={name}
        label={label}
        suggestions={suggestions}
        suggestionsLoading={suggestionsLoading}
        value={inputValue}
        onChange={handleValueChange}
        onBlur={onBlur}
        required={required}
        errorMessage={errorMessage}
        fullWidth
      />
    </>
  );
}
