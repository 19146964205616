import React from 'react';
import { StylesProvider } from '@material-ui/styles';
import { Helmet } from 'react-helmet';
import AppRoutes from './routes';
import Toast from './common/Toast/Toast';
import { EMDASH } from './utils/constants';
import { UserTracker } from './UserTracker';

export default function App() {
  return (
    <StylesProvider injectFirst>
      <Helmet
        defaultTitle="НП Образование"
        titleTemplate={`%s ${EMDASH} НП Образование`}
      />
      <AppRoutes />
      <Toast />
      <UserTracker />
    </StylesProvider>
  );
}
