import gql from 'graphql-tag';

export const MUTATION_CREATE_MENTION_COVERAGE = gql`
  mutation createMentionCoverage($input: CreateMentoringCoverageInput!) {
    result: createMentoringCoverage(input: $input) {
      id
    }
  }
`;

export const MUTATION_UPDATE_MENTION_COVERAGE = gql`
  mutation createMentionCoverage($input: UpdateMentoringCoverageInput!) {
    result: updateMentoringCoverage(input: $input) {
      id
    }
  }
`;

export const MUTATION_CREATE_MENTORING_COVERAGE_TOKEN = gql`
  mutation createMentoringCoverageReportOneTimeToken {
    createMentoringCoverageReportOneTimeToken
  }
`;
