import { useMemo } from 'react';
import { useUrlValue } from './useUrlValue';

export function useUrlTabs(availableTabs, query, changeQuery) {
  const [queryTab, setQueryTab] = useUrlValue(
    query,
    changeQuery,
    'tab',
    availableTabs[0]?.value,
    true
  );

  const openedTab = useMemo(() => {
    return availableTabs.find((t) => t.value === queryTab)?.value;
  }, [queryTab, availableTabs]);

  return [openedTab, setQueryTab];
}
