export function shallowEqualArrays(a1, a2) {
  if (a1 === a2) return true;
  if (!Array.isArray(a1) || !Array.isArray(a2)) return false;
  if (a1.length !== a2.length) return false;
  let i = 0;
  for (; i < a1.length; i++) {
    if (a1[i] !== a2[i]) return false;
  }
  return true;
}
