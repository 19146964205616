import gql from 'graphql-tag';
import { FRAGMENT_PAGINATION_HEADER } from './common';

export const FRAGMENT_PURCHASE_CHANGE = gql`
  fragment PurchaseChange on PurchaseChangeType {
    purchase_id
    change_id
    line_number
    date_start
    date_end
    created_at
    updated_at
    reason
  }
`;

export const FRAGMENT_PURCHASE_STAGE = gql`
  fragment PurchaseStage on PurchaseStageType {
    purchase_id
    stage_id
    line_number
    sum_fact
    sum_plan
    date_start
    date_end
    date_fact
  }
`;

export const FRAGMENT_PURCHASE_PAYMENT = gql`
  fragment PurchasePayment on PurchasePaymentScheduleType {
    purchase_id
    payment_schedule_id
    type_payment
    date_execution_payment
    plan
    fact
    diff_fact_plan
    reason
  }
`;

export const FRAGMENT_PURCHASE_COMPONENTS = gql`
  fragment PurchaseComponents on PurchaseComponentType {
    amount
    cost
    cost_domestic
    purchase_component_category {
      id
      code
      name
      name_full
    }
    purchase_component_category_id
  }
`;

const FRAGMENT_PURCHASE_COMMON = gql`
  fragment PurchaseCommon on PurchaseType {
    id
    regional_project_id
    expenses_direction_id
    grbs_id
    grbs_string
    customer_id
    customer_id_type
    customer_type
    customer_string
    item
    supplier_inn
    supplier_kpp
    supplier_name
    supplier_determination_id
    financing_total
    financing_sfb
    financing_skbsrf
    financing_mb
    financing_vb
    contract_summ_total
    contract_summ_sfb
    contract_summ_skbsrf
    contract_summ_mb
    contract_summ_vb
    contract_summ_domestic
    deviation_reason
    application_date_planned_start
    application_date_planned_end
    application_date_fact
    eis_number
    eis_publication_planned_start
    eis_publication_planned_end
    eis_publication_fact
    purchase_link
    contract_sign_planned_start
    contract_sign_planned_end
    contract_sign_fact
    delivery_planned
    description
    year
    fulfilled_obligations
    fulfilled_obligations_fb
    fulfilled_obligations_mb
    fulfilled_obligations_rb
    fulfilled_obligations_vb
  }
`;

export const FRAGMENT_PURCHASE = gql`
  fragment Purchase on PurchaseType {
    ...PurchaseCommon

    application_date_changes: purchase_changes(variant: APPLICATION_DATE) {
      ...PurchaseChange
    }
    eis_publication_changes: purchase_changes(variant: EIS_PUBLICATION) {
      ...PurchaseChange
    }
    contract_sign_changes: purchase_changes(variant: CONTRACT_SIGN) {
      ...PurchaseChange
    }

    purchase_stages {
      ...PurchaseStage
    }

    payment_schedules {
      ...PurchasePayment
    }

    purchase_components {
      ...PurchaseComponents
    }

    equipment_and_software
    version
    date_time
    created_at
    updated_at
    number
    status

    grbs {
      id
      code
      name_full
    }
    customer {
      id
      name_short
      name_full
    }
    customer_grbs {
      id
      name_full
    }
    regional_project {
      id
      name
      region_id
      region {
        id
        name
      }
    }
    rvpo_equipments {
      id
      code
      name_rvpo
      equipment_status {
        id
        name
      }
      check_status {
        id
        name
      }
    }
    expenses_direction {
      id
      code
      name_full
    }
    supplier_determination {
      id
      code
      name
      law {
        id
        name
      }
    }
  }

  ${FRAGMENT_PURCHASE_COMMON}
  ${FRAGMENT_PURCHASE_CHANGE}
  ${FRAGMENT_PURCHASE_STAGE}
  ${FRAGMENT_PURCHASE_PAYMENT}
  ${FRAGMENT_PURCHASE_COMPONENTS}
`;

export const FRAGMENT_PURCHASE_SHORT = gql`
  fragment PurchaseShort on PurchaseType {
    id
    number
    status
    grbs_string
    customer_string
    item
    grbs {
      id
      name_full
    }
    customer_grbs {
      id
      name_full
    }
    customer {
      id
      name_full
    }
  }
`;

export const QUERY_GET_PURCHASES = gql`
  query purchases($input: PurchasePageInput!) {
    purchases(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        ...PurchaseShort
      }
    }
  }
  ${FRAGMENT_PAGINATION_HEADER}
  ${FRAGMENT_PURCHASE_SHORT}
`;

export const QUERY_GET_PURCHASE = gql`
  query purchase($id: ID!) {
    purchase(id: $id) {
      ...Purchase
    }
  }
  ${FRAGMENT_PURCHASE}
`;

export const QUERY_GET_PURCHASE_WITH_SIGNALS = gql`
  query purchaseWithSignals($id: ID!) {
    purchase(id: $id) {
      ...Purchase
    }
    signals(purchaseId: $id) {
      fields
    }
  }
  ${FRAGMENT_PURCHASE}
`;

export const QUERY_GET_SIGNALS = gql`
  query signals($purchase: PurchaseInput) {
    signals(purchaseInput: $purchase) {
      fields
    }
  }
`;

export const QUERY_GET_EDITING_USER = gql`
  query editingUser($entity: String!) {
    editingUser(entity: $entity) {
      id
      name
      login
      email
    }
  }
`;

export const QUERY_GET_LATEST_PURCHASE_CONFIRMATION = gql`
  query latestPurchaseConfirmation($filter: PurchaseConfirmationFilter!) {
    latestPurchaseConfirmation(filter: $filter) {
      confirmation_date
    }
  }
`;

export const QUERY_GET_INFRALISTS_EQUIPMENTS = gql`
  query getInfralistEquipment($input: InfralistRvpoEquipmentPageInput!) {
    infralistSectionRvpoEquipments(input: $input) {
      header {
        hasNext
        onePage
        page
        totalCount
      }
      list {
        id
        name_rvpo
        equipment_status {
          id
          name
        }
        check_status {
          id
          name
        }
      }
    }
  }
`;
