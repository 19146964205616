import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { QUERY_INFRALIST_DIRECTIONS } from '../../graphql/queries/infralist';

const infralistDirectionsQueryOptions = {
  query: QUERY_INFRALIST_DIRECTIONS,
  mapData: (result) => result.infralistDirections,
  skipQuery: skipIfNull(null)
};

export function useInfralistDirections(input) {
  const response = useRetriableGraphql({
    queryOptions: infralistDirectionsQueryOptions,
    variables: {
      input,
      infralist_id: input.filter.infralist_id
    }
  });

  return {
    data: response.state.data,
    isLoading: response.check.spinner
  };
}
