import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MaterialMenu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import s from './Menu.module.scss';

const defaultOptions = ['Test1', 'Test2', 'Test3', 'Test4'];

const Menu = ({ icon: Icon, iconColor, classes, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        color={iconColor}
        onClick={handleClick}
        classes={{ root: s.IconButton }}
      >
        <Icon />
      </IconButton>
      <MaterialMenu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: 200
          }
        }}
      >
        {options.map(({ title, onClick }, index) => (
          <MenuItem key={index} onClick={onClick}>
            {title}
          </MenuItem>
        ))}
      </MaterialMenu>
    </>
  );
};

Menu.defaultProps = {
  classes: '',
  iconColor: 'inherit',
  options: defaultOptions
};

Menu.propTypes = {
  classes: PropTypes.string,
  icon: PropTypes.shape().isRequired,
  iconColor: PropTypes.string,
  options: PropTypes.array
};

export default Menu;
