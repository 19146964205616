import { useState } from 'react';
import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { MUTATION_INFRALIST_COMPLETE_NOT_APPROVE } from '../../../graphql/mutations/infralist';
import { INFRALIST_QUERIES } from '../infralistQueries';

export const useCompleteNotApprove = () => {
  const client = useContextApolloClient();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingWithoutEquipment, setIsLoadingWithoutEquipment] = useState(
    false
  );

  const completeNotApprove = ({ infralistId, isEmptyAgreed }) => {
    if (isEmptyAgreed || typeof isEmptyAgreed === 'undefined') {
      setIsLoading(true);
    } else {
      setIsLoadingWithoutEquipment(true);
    }
    return client
      .mutate({
        mutation: MUTATION_INFRALIST_COMPLETE_NOT_APPROVE,
        variables: {
          infralist_id: infralistId,
          is_empty_agreed: isEmptyAgreed
        },
        refetchQueries: [
          INFRALIST_QUERIES.INFRALIST,
          INFRALIST_QUERIES.DIRECTIONS_LIST,
          INFRALIST_QUERIES.DIRECTION,
          INFRALIST_QUERIES.DIRECTION_SECTIONS,
          INFRALIST_QUERIES.SECTION,
          INFRALIST_QUERIES.EQUIPMENT_LIST
        ],
        update: (store) => {
          store.reset();
        }
      })
      .finally(() => {
        if (isEmptyAgreed || typeof isEmptyAgreed === 'undefined') {
          setIsLoading(false);
        } else {
          setIsLoadingWithoutEquipment(false);
        }
      });
  };

  return {
    isLoadingCompleteNotApprove: isLoading,
    isLoadingCompleteNotApproveWithoutEquipment: isLoadingWithoutEquipment,
    completeNotApprove
  };
};
