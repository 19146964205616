import React from 'react';
import classnames from 'classnames';
import PageTitle from '../../common/PageTitle/PageTitle';
import s from './InformationPage.module.scss';

export default function InformationPage() {
  return (
    <>
      <PageTitle withBackBtn={false} title="Информация" />

      <div className={s.contentBlock}>
        <h3>
          Техническая поддержка системы управления проектной деятельности
          (СУПД), предоставление аналитической информации о реализации:
        </h3>
        <div className={s.list}>
          <span className={s.text}>Заместитель начальника отдела</span>
          <span className={s.text}>Веденков Денис Витальевич</span>
          <a className="external" href={'mailto:vedenkovdv@apkpro.ru'}>
            vedenkovdv@apkpro.ru
          </a>
          <span className={s.text}>
            <a className="external" href={'tel:+79151682423'}>
              +7 (915) 168-24-23
            </a>
          </span>
        </div>
        <div className={s.list}>
          <span className={s.text}>Начальник отдела:</span>
          <span className={s.text}>Савчук Юрий Сергеевич</span>
          <span className={s.text}>
            <a className="external" href={'mailto:savchukus@apkpro.ru'}>
              savchukus@apkpro.ru
            </a>
          </span>
          <span className={s.text}>
            <a className="external" href={'tel:+79032631168'}>
              +7 (903) 263-11-68
            </a>
          </span>
        </div>
      </div>

      <div className={s.contentBlock}>
        <h3>
          Организационно-методическая поддержка в части подготовки отчётности в
          ГИИС "Электронный бюджет":
        </h3>
        <div className={s.list}>
          <span className={s.text}>Ялунин Владимир Сергеевич</span>
          <a className="external" href={'mailto:yaluninvs@apkpro.ru'}>
            yaluninvs@apkpro.ru
          </a>
          <span className={s.text}>
            <a className="external" href={'tel:+79679099802'}>
              +7 (967) 909 98 02
            </a>
          </span>
        </div>
      </div>

      <div className={s.contentBlock}>
        <h3>Координаторы мероприятий в модуле "Дорожные карты":</h3>
        <table className={classnames(s.table, s.table__bordered)}>
          <tr>
            <th>Наименование сущности</th>
            <th>Координаторы проекта</th>
          </tr>
          <tr>
            <td>"Точка роста"</td>
            <td>
              <div>Антоненко Игорь Павлович</div>
              <a className="external" href="tel:+79601212121">
                +7 (960) 121-21-21
              </a>
              ,
              <a className="external" href="mailto:antonenkoip@apkpro.ru">
                antonenkoip@apkpro.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>"Кванториум"</td>
            <td>
              <div>Крылова Наталья Александровна</div>
              <a className="external" href="tel:+79161133275">
                +7 (916) 113-32-75
              </a>
              ,
              <a className="external" href="mailto:krylovana@apkpro.ru">
                krylovana@apkpro.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>ЦНППМПР</td>
            <td>
              <div>Уральская Евгения Владимировна</div>
              <a className="external" href="tel:+79825125690">
                +7 (982) 512-56-90
              </a>
              ,
              <a className="external" href="mailto:uralskayaev@apkpro.ru">
                uralskayaev@apkpro.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>IT-куб</td>
            <td>
              <div>Кузнецова Ольга Левоновна</div>
              <a className="external" href="tel:+79682745002">
                +7 (968) 274-50-02
              </a>
              ,
              <a className="external" href="mailto:kuznecovaol@apkpro.ru">
                kuznecovaol@apkpro.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>
              ЦОС <br />
              ЦОС (эксперимент)
            </td>
            <td>
              <div>Морозов Роман Николаевич</div>
              <a className="external" href="tel:+79376113315">
                +7 (937) 611-33-15
              </a>
              ,
              <a className="external" href="mailto:kuznecovaol@apkpro.ru">
                morozovrn@apkpro.ru
              </a>
              <div>Сибагатуллина Лейсан Дамировна</div>
              <a className="external" href="tel:+79179138386">
                +7 (917) 913-83-86
              </a>
              ,
              <a className="external" href="mailto:sibagatullinald@apkpro.ru">
                sibagatullinald@apkpro.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>Центр для одарённых детей и молодежи</td>
            <td>
              <div>Платонычев Сергей Владимирович</div>
              <a className="external" href="tel:+79384948424">
                +7 (938) 494-84-24
              </a>
              <div>Сереброва Елена Владимировна</div>
              <a className="external" href="tel:+79856106527">
                +7 (985) 610-65-26
              </a>{' '}
              (от Минпросвещения России)
            </td>
          </tr>
          <tr>
            <td>Целевая модель ДОД (РМЦ)</td>
            <td>
              <div>Львова Лариса Семеновна</div>
              <a className="external" href="tel:+79067278931">
                +7 (906) 727-89-31
              </a>
              ,
              <a className="external" href="mailto:Larisa-lvova@mail.ru">
                Larisa-lvova@mail.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>Новые места ДОД</td>
            <td>
              <div>Менников Владимир Евгеньевич</div>
              <a className="external" href="tel:+74956033015">
                +7 (495) 603-30-15
              </a>
              ,
              <a className="external" href="mailto:novmesta@ecobiocentre.ru">
                novmesta@ecobiocentre.ru
              </a>
              <div>Агапкина Наталья Ивановна</div>
              <a className="external" href="tel:+79161309358">
                +7 (916) 130-93-58
              </a>
              ,
              <a className="external" href="mailto:novmesta@ecobiocentre.ru">
                novmesta@ecobiocentre.ru
              </a>
            </td>
          </tr>

          <tr>
            <td>Дети с ОВЗ</td>
            <td>
              <div>Сухова Екатерина Михайловна</div>
              <a className="external" href="tel:+79779632971">
                +7 (977) 963-29-71
              </a>
              ,
              <a className="external" href="mailto:suhova-em@edu.gov.ru">
                suhova-em@edu.gov.ru
              </a>
            </td>
          </tr>
        </table>
      </div>

      <div className={s.contentBlock}>
        <h3>Кураторы региональных ведомственных проектных офисов:</h3>
        <table className={s.table}>
          <tr>
            <td colSpan={2}>
              <div>Сулима Лариса Олеговна, начальник отдела</div>
              <a className="external" href="tel:+79274962013">
                +7 (927) 496-20-13
              </a>
              ,
              <a className="external" href="mailto:sulimalo@apkpro.ru">
                sulimalo@apkpro.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <b>СКФО, ДФО</b>
              <div>Антоненко Игорь Павлович</div>
              <a className="external" href="tel:+79601212121">
                +7 (960) 121-21-21
              </a>
              ,
              <a className="external" href="mailto:antonenkoip@apkpro.ru">
                antonenkoip@apkpro.ru
              </a>
              <div>Уральская Евгения Владимировна</div>
              <a className="external" href="tel:+79825125690">
                +7 (982) 512-56-90
              </a>
              ,
              <a className="external" href="mailto:uralskayaev@apkpro.ru">
                uralskayaev@apkpro.ru
              </a>
            </td>
            <td>
              <b>СЗФО, ЦФО</b>
              <div>Крылова Наталья Александровна</div>
              <a className="external" href="tel:+79161133275">
                +7 (916) 113-32-75
              </a>
              ,
              <a className="external" href="mailto:krylovana@apkpro.ru">
                krylovana@apkpro.ru
              </a>
              <div>Кузнецова Ольга Левоновна</div>
              <a className="external" href="tel:+79682745002">
                +7 (968) 274-50-02
              </a>
              ,
              <a className="external" href="mailto:kuznecovaol@apkpro.ru">
                kuznecovaol@apkpro.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <b>УФО, ПФО</b>
              <div>Сибагатуллина Лейсан Дамировна</div>
              <a className="external" href="tel:+79179138386">
                +7 (917) 913-83-86
              </a>
              ,
              <a className="external" href="mailto:sibagatullinald@apkpro.ru">
                sibagatullinald@apkpro.ru
              </a>
              <div>Морозов Роман Николаевич</div>
              <a className="external" href="tel:+79376113315">
                +7 (937) 611-33-15
              </a>
              ,
              <a className="external" href="mailto:kuznecovaol@apkpro.ru">
                morozovrn@apkpro.ru
              </a>
            </td>
            <td>
              <b>СФО, ЮФО</b>
              <div>Белышева Ольга Сергеевна</div>
              <a className="external" href="tel:+79035536920">
                +7 (903) 553-69-20
              </a>
              ,
              <a className="external" href="mailto:belyshevaos@apkpro.ru">
                belyshevaos@apkpro.ru
              </a>
              <div>Кропотина Мария Сергеевна</div>
              <a className="external" href="tel:+79274988892">
                +7 (927) 498-88-92
              </a>
              ,
              <a className="external" href="mailto:kropotinams@apkpro.ru">
                kropotinams@apkpro.ru
              </a>
            </td>
          </tr>
        </table>
      </div>

      <div className={s.contentBlock}>
        <h3>
          Поддержка в части создания имущественного комплекса Нацпроекта
          "Образование", инфраструктурные листы
        </h3>
        <table className={s.table}>
          <tr>
            <td>
              <b>
                Управление модулями «Закупки» и <br />
                «Управление инфраструктурными листами» в СУПД
              </b>
              <div>Семенюк Георгий Эдуардович</div>
              <a className="external" href="tel:+79646363355">
                +7 (964) 636-33-55
              </a>
              ,
              <a className="external" href="mailto:semenukge@apkpro.ru">
                semenukge@apkpro.ru
              </a>
            </td>
            <td>
              <b>Документооборот по инфраструктурным листам</b>
              <div>Милова Ирина Сергеевна</div>
              <a className="external" href="tel:+79160900267">
                +7 (916) 090-02-67
              </a>
              ,
              <a className="external" href="mailto:milovais@apkpro.ru">
                milovais@apkpro.ru
              </a>
            </td>
          </tr>
        </table>
      </div>

      <div className={s.contentBlock}>
        <h3>Рассмотрение инфраструктурных листов:</h3>

        <table className={s.table}>
          <tr>
            <td>
              <b>"Точка роста", Школьный "Кванториум"</b>
              <div>Иванов Алексей Александрович</div>
              <a className="external" href="tel:+79161435841">
                +7 (916) 143-58-41
              </a>
              ,
              <a className="external" href="mailto:ivanovaa@apkpro.ru">
                ivanovaa@apkpro.ru
              </a>
              <div>Милова Ирина Сергеевна</div>
              <a className="external" href="tel:+79160900267">
                +7 (916) 090-02-67
              </a>
              ,
              <a className="external" href="mailto:milovais@apkpro.ru">
                milovais@apkpro.ru
              </a>
            </td>
            <td>
              <b>ЦОС, ЦОС эксперимент, ИТ-куб</b>
              <div>Семенюк Георгий Эдуардович</div>
              <a className="external" href="tel:+79646363355">
                +7 (964) 636-33-55
              </a>
              ,
              <a className="external" href="mailto:semenukge@apkpro.ru">
                semenukge@apkpro.ru
              </a>
              <div>Расулов Александр Эдуардович</div>
              <a className="external" href="tel:+79096635403">
                +7 (909) 663-54-03
              </a>
              ,
              <a className="external" href="mailto:rasulovae@apkpro.ru">
                rasulovae@apkpro.ru
              </a>
            </td>
          </tr>
        </table>
      </div>

      <ul>
        <li>
          <a
            className="external"
            href="/Инструкция по работе в подсистеме сбора и консолидации данных.pdf"
          >
            Инструкция по работе в подсистеме сбора и консолидации данных
          </a>
        </li>
        <li>
          <a
            className="external"
            href="/Инструкция_по_фотомониторингу_для_РВПО.pdf"
          >
            Инструкция по фотомониторингу для РВПО
          </a>
        </li>
        <li>
          <a className="external" href="/Инструкция_Состав_закупки.pdf">
            Инструкция "Состав закупки"
          </a>
        </li>
        <li>
          <a
            className="external"
            href="/Инструкция_Состав_закупки_Приложение_1.xlsx"
          >
            Инструкция "Состав закупки". Приложение 1
          </a>
        </li>
        <li>
          <a
            className="external"
            href="/РВПО_Инструкция_по_работе_в_подсистеме_по_инфралистам.pdf"
          >
            РВПО Инструкция по работе в подсистеме по инфралистам
          </a>
        </li>
      </ul>
    </>
  );
}
