import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_CLASSES_SCHEDULES_PAGE } from '../../graphql/queries/classesSchedules';

const classesSchedulesPageQueryOptions = {
  query: QUERY_CLASSES_SCHEDULES_PAGE,
  mapData: (result) => result.classesSchedulesPage,
  skipQuery: skipIfNull(null)
};

export function useClassesSchedulesPage(filter) {
  return useRetriableGraphqlVars({
    queryOptions: classesSchedulesPageQueryOptions,
    variableCreator: [
      () =>
        filter
          ? {
              input: {
                filter: filter
              }
            }
          : null,
      [filter]
    ]
  });
}
