import React from 'react';
import { FastField, FieldArray } from 'formik';
import InputGroup from '../../../common/Inputs/InputGroup';
import { TextInput } from '../../../common/Inputs/TextInput';
import { ContestDocsIndicatorEditable } from './ContestDocsIndicator/ContestDocsIndicatorEditable';

export const ContestDocsMonitoringFormEditable = ({
  entity,
  values,
  touched,
  errors
}) => {
  const indicators = entity.roadmap_indicators;
  const hasIndicators = !!indicators && indicators.length > 0;

  return (
    <>
      {hasIndicators && (
        <FieldArray name={`indicators`}>
          {() =>
            indicators?.map((ind, iInd) => (
              <ContestDocsIndicatorEditable
                key={iInd}
                index={iInd}
                indicator={ind}
                values={values}
                touched={touched}
                errors={errors}
              />
            ))
          }
        </FieldArray>
      )}

      <InputGroup>
        <FastField
          name="analytical_information"
          label="Аналитическая информация"
          fullWidth
          component={TextInput}
          multiline
          errorMessage={
            touched.analytical_information && errors.analytical_information
          }
        />
      </InputGroup>
    </>
  );
};
