/**
 * Объединяет попарно массивы
 * При разной длине массивов дополняет результат заглушками
 *
 * @param arr1
 * @param arr2
 * @param placeholder - заглушка
 */
export function zipArrays(arr1, arr2, placeholder) {
  const result = [];
  for (let i = 0; i < Math.max(arr1.length, arr2.length); i++) {
    result.push([arr1[i] ?? placeholder, arr2[i]] ?? placeholder);
  }
  return result;
}
