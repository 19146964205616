import React, { useCallback, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useContextStore } from '@proscom/prostore-react';
import Button from '@material-ui/core/Button';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { getName } from '../../../utils/data/label';
import { useSubsidiaryEntity } from '../../../store/subsidiaryEntity/useSubsidiaryEntity';
import SubTable from '../../../common/SubTable/SubTable';
import { queryLoaderWithExistenceCheck } from '../../../common/QueryLoader';
import { PageActionsBar } from '../../../common/PageActionsBar/PageActionsBar';
import { EditButton } from '../../../common/Form/EditButton/EditButton';
import { Individual } from '../../../store/Individual';
import { SubsidiaryEntity } from '../../../store/subsidiaryEntity/SubsidiaryEntity';
import { DeleteButtonWithConfirmation } from '../../../common/Form/DeleteButtonWithConfirmation';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../graphql/graphqlErrors';
import { SUBSIDIARY_ENTITY_STORE } from '../../../store/names';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import { useConfirmRow } from '../../../common/Table/useConfirmRow';
import { useRevokeConfirmationRow } from '../../../common/Table/useRevokeConfirmationRow';
import { SubsidiaryEntityEditValidationSchema } from '../../../utils/data/validation';
import { SubsidiaryEntityValidationDialog } from '../SubsidiaryEntityValidationDialog';
import { SubsidiaryEntityBreadCrumbs } from './SubsidiaryEntityBreadCrumbs';

export default function SubsidiaryEntityPage({ match, history }) {
  const [isValidationError, setIsValidationError] = useState(false);

  const subsidiaryEntityId = match.params.subsidiaryEntityId;

  const subsidiaryEntityStore = useContextStore(SUBSIDIARY_ENTITY_STORE);

  const subsidiaryEntityQuery = useSubsidiaryEntity(subsidiaryEntityId, true);
  const subsidiaryEntity = subsidiaryEntityQuery.state.data;

  const address = SubsidiaryEntity.combineAddress(subsidiaryEntity);

  const getEmails = (emails) => {
    if (!emails) return null;
    return emails.map((mail, index) => (
      <a key={index} className="external" href={`mailto:${mail}`}>
        {mail}
      </a>
    ));
  };

  const [isDeleting, setDeleting] = useState(false);
  const deleteSubsidiaryEntity = useCallback(() => {
    setDeleting(true);
    subsidiaryEntityStore
      .deleteSubsidiaryEntity(subsidiaryEntityId)
      .then((result) => {
        setTimeout(() => {
          history.push('/subsidiaryEntities');
        }, 0);
      })
      .catch((error) => {
        showGraphqlErrorToast(error, mutationErrors.deleteObject);
      })
      .finally(() => setDeleting(false));
  }, [subsidiaryEntityId, history, subsidiaryEntityStore]);

  const [
    isConfirming,
    confirmDialog,
    openConfirmDialog
  ] = useConfirmRow(
    subsidiaryEntityStore.approveSubsidiaryEntity,
    'субсидиарную сущность',
    () => subsidiaryEntityQuery.load()
  );

  const [
    isRevokeConfirming,
    revokeConfirmationDialog,
    openRevokeConfirmationDialog
  ] = useRevokeConfirmationRow(
    subsidiaryEntityStore.revokeSubsidiaryEntityApprove,
    'субсидиарной сущности  ',
    () => subsidiaryEntityQuery.load()
  );

  const user = useCurrentUser();
  const canEdit = SubsidiaryEntity.canEdit(user, subsidiaryEntity);
  const canDelete = SubsidiaryEntity.canDelete(user, subsidiaryEntity);
  const canConfirm = SubsidiaryEntity.canConfirm(user, subsidiaryEntity);
  const canRevokeConfirmation = SubsidiaryEntity.canRevokeConfirmation(
    user,
    subsidiaryEntity
  );

  let headerActionButton = null;
  if (subsidiaryEntity) {
    if (!subsidiaryEntity.is_approved && canConfirm && canEdit) {
      headerActionButton = (
        <Button
          onClick={() => {
            SubsidiaryEntityEditValidationSchema.validate({
              ...subsidiaryEntity,
              address,
              se_type_id: subsidiaryEntity?.se_type?.id
            })
              .then(() => {
                openConfirmDialog(subsidiaryEntity.id);
              })
              .catch((err) => {
                console.log(err);
                setIsValidationError(true);
              });
          }}
          loading={isConfirming}
        >
          Утвердить
        </Button>
      );
    } else if (
      subsidiaryEntity.is_approved &&
      canRevokeConfirmation &&
      canEdit
    ) {
      headerActionButton = (
        <Button
          onClick={() => openRevokeConfirmationDialog(subsidiaryEntity.id)}
        >
          Отменить утверждение
        </Button>
      );
    }
  }

  return (
    <>
      <HeadTitle
        type="Субсидиарная сущность"
        entity={getName(subsidiaryEntity)}
      />
      <SubsidiaryEntityBreadCrumbs />
      <PageTitle title={getName(subsidiaryEntity)} withBackBtn />
      <SubsidiaryEntityValidationDialog
        isOpen={isValidationError}
        onCancel={() => setIsValidationError(false)}
      />
      {queryLoaderWithExistenceCheck(subsidiaryEntityQuery) || (
        <>
          {!(subsidiaryEntity.is_approved && !canRevokeConfirmation) && (
            <PageActionsBar
              actions={headerActionButton}
              editButton={
                canEdit &&
                !subsidiaryEntity.is_approved && (
                  <EditButton editLink={`${match.url}/edit`} />
                )
              }
              deleteButton={
                canDelete &&
                !subsidiaryEntity.is_approved && (
                  <DeleteButtonWithConfirmation
                    onDelete={deleteSubsidiaryEntity}
                    isDeleting={isDeleting}
                    entityName={'субсидиарную сущность'}
                  />
                )
              }
            />
          )}
          {confirmDialog}
          {revokeConfirmationDialog}
          <SubTable title="Общие сведения">
            <TableRow>
              <TableCell>Название субсидиарной сущности</TableCell>
              <TableCell>{getName(subsidiaryEntity)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Тип</TableCell>
              <TableCell>{getName(subsidiaryEntity.se_type)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Субъект Российской Федерации</TableCell>
              <TableCell>{subsidiaryEntity.region?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Малокомплектная школа</TableCell>
              <TableCell>
                {subsidiaryEntity?.is_small_school ? 'Да' : 'Нет'}
              </TableCell>
            </TableRow>
          </SubTable>
          <SubTable title="Организация">
            <TableRow>
              <TableCell>Название образовательной организации</TableCell>
              <TableCell>
                {SubsidiaryEntity.getBaseOrganizationName(
                  subsidiaryEntity,
                  true
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Адрес</TableCell>
              <TableCell>{address}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ИНН</TableCell>
              <TableCell>{subsidiaryEntity.inn}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>КПП</TableCell>
              <TableCell>{subsidiaryEntity.kpp}</TableCell>
            </TableRow>
          </SubTable>
          <SubTable title="Руководитель">
            <TableRow>
              <TableCell>ФИО</TableCell>
              <TableCell>
                {Individual.combineName(subsidiaryEntity.supervisor)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Мобильный телефон</TableCell>
              <TableCell>{subsidiaryEntity.supervisor?.phones}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>E-mail</TableCell>
              <TableCell>
                {getEmails(subsidiaryEntity.supervisor?.emails)}
              </TableCell>
            </TableRow>
          </SubTable>
          <SubTable>
            <TableRow>
              <TableCell>Год создания</TableCell>
              <TableCell>{subsidiaryEntity.year_of_creation}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Версия</TableCell>
              <TableCell>{subsidiaryEntity.version}</TableCell>
            </TableRow>
          </SubTable>
        </>
      )}
    </>
  );
}
