import { useField } from 'formik';
import { useRef } from 'react';

export function useFieldFast(props) {
  const [field, meta, helpers] = useField(props);

  const latestRef = useRef({});

  // On every render save newest helpers to latestRef
  latestRef.current.setValue = helpers.setValue;
  latestRef.current.setTouched = helpers.setTouched;
  latestRef.current.setError = helpers.setError;

  // On the first render create new function which will never change
  // but call newest helper function
  if (!latestRef.current.helpers) {
    latestRef.current.helpers = {
      setValue: (...args) => latestRef.current.setValue(...args),
      setTouched: (...args) => latestRef.current.setTouched(...args),
      setError: (...args) => latestRef.current.setError(...args)
    };
  }

  return [field, meta, latestRef.current.helpers];
}
