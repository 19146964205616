import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import s from './Dropdown.module.scss';

export function Dropdown({
  top,
  children,
  childrenClassName,
  childrenOpenedClassName,
  defaultOpened,
  onChange,
  className
}) {
  const [opened, setOpened] = useState(defaultOpened || false);

  useEffect(() => {
    onChange && onChange(opened);
  }, [opened, onChange]);

  return (
    <div className={className}>
      {top({ opened, setOpened })}
      <div
        className={classNames(childrenClassName, s.Dropdown, {
          [s._active]: opened,
          [childrenOpenedClassName || s._active]: opened
        })}
      >
        {typeof children === 'function'
          ? children({ opened, setOpened })
          : children}
      </div>
    </div>
  );
}
