import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useContextStore } from '@proscom/prostore-react';
import { apiUrl } from '../../../config';
import { getAxiosErrorMessage } from '../../../graphql/graphqlErrors';
import { splitFileName } from '../../../utils/data/file';
import SubTable from '../../../common/SubTable/SubTable';
import { SubTableHeading } from '../../../common/SubTable/SubTableHeading';
import { FileInputButton } from '../../../common/Form/FileInputButton';
import { RegionalEvent } from '../../../store/roadmap/RegionalEvent';
import { useFieldFast } from '../../../common/InputsNew/useFieldFast';
import { STORE_FILE_TOKEN } from '../../../store/names';
import { EditDocumentsWithoutSlots } from './EditDocumentsWithoutSlots';
import { DocumentsWithSlots } from './EventDocuments';
import s from './EventPage.module.scss';

let globalDocumentId = 1;

export const SLOT_GUIDELINE_NAME = 'staff_schedule_guideline_deviation';

const uploadFile = async (
  file,
  currentDocumentId,
  changeDocument,
  tokenStore
) => {
  const formData = new FormData();
  formData.append('file', file, file.name);

  const cancelToken = axios.CancelToken.source();
  try {
    const accessToken = await tokenStore.createUploadOneTimeToken();
    const response = await axios.request({
      method: 'post',
      url: apiUrl + '/upload/regionalEventDocument',
      cancelToken: cancelToken.token,
      data: formData,
      params: {
        token: accessToken
      },
      onUploadProgress: (p) => {
        changeDocument(currentDocumentId, (doc) => ({
          ...doc,
          uploadingFile: {
            ...doc.uploadingFile,
            progress: Math.round((p.loaded * 100) / p.total),
            cancel: cancelToken
          }
        }));
      }
    });
    changeDocument(currentDocumentId, (doc) => ({
      ...doc,
      connected_file: {
        ...doc.connected_file,
        id: response.data.id
      },
      uploadingFile: {
        uploading: false,
        uploaded: true,
        progress: 100,
        error: false
      }
    }));
  } catch (error) {
    changeDocument(currentDocumentId, (doc) => ({
      ...doc,
      uploadingFile: {
        ...doc.uploadingFile,
        uploading: false,
        uploaded: false,
        error: error,
        errorMessage: getAxiosErrorMessage(error)
      }
    }));
  }
};

function createNewDocument(file, slot, type, documentProps) {
  const newDocumentId = globalDocumentId++ + '';
  let aborted = false;

  const [name, file_extension] = splitFileName(file.name);

  const newDocument = {
    id: newDocumentId,
    isNew: true,
    name: file.name,
    document_slot_id: slot ? slot.id : undefined,
    document_slot_codeName: slot ? slot.code_name : undefined,
    type,
    connected_file: {
      name,
      file_extension,
      file_size: file.size
    },
    uploadingFile: {
      uploading: true,
      uploaded: false,
      progress: 0,
      error: false
    },
    ...documentProps
  };

  if (
    (newDocument.photo_category_id ||
      newDocument.type === RegionalEvent.DocType.PHOTO) &&
    file.size > 5242880
  ) {
    newDocument.uploadingFile = {
      uploading: false,
      uploaded: false,
      progress: 20,
      error: true,
      errorMessage: 'Размер файла превышает допустимые 5Мб'
    };
    aborted = true;
  }
  return { newDocumentId, aborted, newDocument };
}

export function useDocumentsField({ name }) {
  const [, meta, { setValue }] = useFieldFast(name);
  const documents = meta.value || [];
  const tokenStore = useContextStore(STORE_FILE_TOKEN);

  const setDocuments = setValue;
  const documentsRef = useRef(documents);
  documentsRef.current = documents;

  const changeDocument = useCallback(
    (id, callback) => {
      const documents = documentsRef.current;
      setDocuments(
        documents.map((doc) => {
          if (doc.id === id) {
            return callback(doc);
          }
          return doc;
        })
      );
    },
    [setDocuments]
  );

  const handleFileChange = useCallback(
    (event, type, slot = undefined, documentProps = undefined) => {
      const files = Object.values(event.target.files).map((file) => {
        let { newDocumentId, aborted, newDocument } = createNewDocument(
          file,
          slot,
          type,
          documentProps
        );

        return {
          file,
          documentId: newDocumentId,
          aborted,
          fileOptions: newDocument
        };
      });

      const documents = documentsRef.current;
      const newDocument = files.map((file) => file.fileOptions);
      setDocuments([...documents, ...newDocument]);

      files.forEach((file) => {
        if (!file.aborted) {
          uploadFile(file.file, file.documentId, changeDocument, tokenStore);
        }
      });

      // Очищаем инпут, чтобы можно было выбрать тот же самый файл еще раз
      // Иначе onChange не вызовется
      event.target.value = null;
    },
    [changeDocument, setDocuments, tokenStore]
  );

  const handleAddLink = useCallback(
    (type = undefined, slot = undefined) => {
      const newDocumentId = globalDocumentId++ + '';

      const newDocument = {
        id: newDocumentId,
        isNew: true,
        content: '',
        document_slot_id: slot ? slot.id : undefined,
        type
      };

      const documents = documentsRef.current;
      setDocuments([...documents, newDocument]);
    },
    [setDocuments]
  );

  const handleDocumentChange = useCallback(
    (id, changes) => {
      changeDocument(id, (doc) => ({
        ...doc,
        ...changes
      }));
    },
    [changeDocument]
  );

  const handleDocumentDelete = useCallback(
    (id) => {
      const documents = documentsRef.current;
      setDocuments(documents.filter((doc) => doc.id !== id));
    },
    [setDocuments]
  );

  const handleCancelUpload = useCallback(
    (id) => {
      const documents = documentsRef.current;
      const document = documents.find((doc) => doc.id === id);
      document.uploadingFile.cancel.cancel('Загрузка отменена');
      handleDocumentDelete(id);
    },
    [handleDocumentDelete]
  );

  return {
    documents,
    documentsRef,
    changeDocument,
    handleFileChange,
    handleAddLink,
    handleDocumentChange,
    handleDocumentDelete,
    handleCancelUpload
  };
}

export const DocumentsEditField = ({
  name,
  slots,
  canUploadArbitrary,
  showSlots,
  showOtherDocs = true,
  canEditStatus,
  canUploadDocuments,
  eventStatus,
  showDocumentStatus = true,
  title,
  entities,
  staffError,
  setHaveReason,
  haveReason
}) => {
  const {
    documents,
    handleFileChange,
    handleAddLink,
    handleDocumentChange,
    handleDocumentDelete,
    handleCancelUpload
  } = useDocumentsField({ name });

  useEffect(() => {
    const staffSlot = slots.find(
      (slot) => slot.code_name === SLOT_GUIDELINE_NAME
    );
    if (!staffSlot) {
      return;
    }
    const guideLineDocument = documents.some((doc) => {
      // Если файл загружен, то форму можно сохранить.
      return doc.document_slot_id === staffSlot.id;
    });
    if (guideLineDocument) {
      setHaveReason(true);
    } else {
      setHaveReason(false);
    }
  }, [documents, setHaveReason, slots]);

  const withoutSlots = useMemo(() => {
    return (documents || []).filter(
      (document) => !slots?.find((s) => s.id === document.document_slot_id)
    );
  }, [documents, slots]);

  return (
    <SubTable title={title} editVariant>
      {showSlots && slots && (
        <DocumentsWithSlots
          slots={slots}
          documents={documents}
          editable={true}
          uploadMultiple={true}
          onDelete={handleDocumentDelete}
          onSelectFile={handleFileChange}
          onCancelUpload={handleCancelUpload}
          onLinkAdd={handleAddLink}
          onDocumentChange={handleDocumentChange}
          canEditStatus={canEditStatus}
          canUploadDocuments={canUploadDocuments}
          eventStatus={eventStatus}
          showDocumentStatus={showDocumentStatus}
          entities={entities}
          staffError={staffError}
          haveReason={haveReason}
        />
      )}
      {showOtherDocs && (
        <>
          {showSlots && slots && (
            <SubTableHeading>Дополнительные документы</SubTableHeading>
          )}
          <EditDocumentsWithoutSlots
            documents={withoutSlots}
            onDocumentChange={handleDocumentChange}
            onCancelUpload={handleCancelUpload}
            onDelete={handleDocumentDelete}
            canEditStatus={canEditStatus}
            canUploadDocuments={canUploadDocuments}
            eventStatus={eventStatus}
            showDocumentStatus={showDocumentStatus}
            entities={entities}
          />
          {canUploadArbitrary && canUploadDocuments && (
            <TableRow>
              <TableCell colSpan={2}>
                <div className={s.wrapper}>
                  <FileInputButton
                    onSelectFile={(e) =>
                      handleFileChange(e, RegionalEvent.DocType.SIMPLE)
                    }
                  />
                </div>
              </TableCell>
            </TableRow>
          )}
        </>
      )}
    </SubTable>
  );
};
