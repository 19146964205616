import { useCallback } from 'react';

export function useUrlValue(
  query,
  changeQuery,
  name,
  defaultValue = null,
  replace = false
) {
  const setValue = useCallback(
    (newValue) => {
      changeQuery(
        {
          [name]: newValue
        },
        replace
      );
    },
    [changeQuery, replace, name]
  );

  const value = query[name] !== undefined ? query[name] : defaultValue;

  return [value, setValue];
}
