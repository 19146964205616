import MaterialMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ComponentWithTooltip } from '../ComponentWithTooltip/ComponentWithTooltip';
import s from './ContextMenuArea.module.scss';

export const ContextMenuArea = ({ children, options, className }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <div onClick={handleClick} className={className}>
        {children}
      </div>
      <MaterialMenu
        anchorEl={anchorEl}
        // `getContentAnchorEl` make correct vertical position
        // https://stackoverflow.com/questions/48157863/how-to-make-a-dropdown-menu-open-below-the-appbar-using-material-ui
        getContentAnchorEl={null}
        keepMounted
        open={open}
        onClose={handleClose}
        PopoverClasses={{
          root: s.container
        }}
        PaperProps={{
          className: s.menuPaper
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        className={s.menu}
        elevation={0}
      >
        {options.map(
          (
            { title, onClick, disabled, isTooltipExists, tooltipTitle },
            index
          ) => (
            <div key={index}>
              <ComponentWithTooltip
                title={tooltipTitle}
                placement={'left'}
                isTooltipExists={isTooltipExists}
              >
                <MenuItem
                  disabled={disabled}
                  onClick={() => {
                    handleClose();
                    onClick && onClick();
                  }}
                  className={s.menuItem}
                >
                  {title}
                </MenuItem>
              </ComponentWithTooltip>
            </div>
          )
        )}
      </MaterialMenu>
    </>
  );
};

ContextMenuArea.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string
};
