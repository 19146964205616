import React from 'react';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import classNames from 'classnames';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';

import s from './Sidebar.module.scss';

const LinkComponent = React.forwardRef((itemProps, ref) =>
  itemProps.to ? (
    <NavLink exact {...itemProps} activeClassName={s.ListItemActive} />
  ) : (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a target="_blank" rel="noopener noreferrer" {...itemProps} />
  )
);

export function ListItemLink({ primary, className, ...props }) {
  return (
    <li>
      <ListItem
        button
        component={LinkComponent}
        className={classNames(s.ListItem, className)}
        {...props}
      >
        <ListItemText
          primary={primary}
          classes={{
            root: s.ListItemTextRoot,
            primary: s.ListItemTextPrimary
          }}
        />
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  primary: PropTypes.string.isRequired,
  isActive: PropTypes.func,
  to: PropTypes.string,
  href: PropTypes.string
};
