import React from 'react';
import { TableInputView } from '../../../common/Table/TableInputView';
import { purchaseInfralistColumns } from './edit/PurchaseInfralistEditField';

export const PurchaseInfralistsComponent = ({ title, purchaseInfralist }) => {
  const columns = purchaseInfralistColumns();

  return (
    <TableInputView title={title} columns={columns} rows={purchaseInfralist} />
  );
};
