import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useStore } from '@proscom/prostore-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { COMMENT_TYPES } from '../../../../store/infralist/commentEntities';
import { useEquipmentComments } from '../../../../store/infralist/comments/equipment/useEquipmentComments';
import { useFetchEquipmentFPOComments } from '../../../../store/infralist/comments/equipment/useFetchEquipmentFPOComments';
import { useFetchEquipmentGeneralComments } from '../../../../store/infralist/comments/equipment/useFetchEquipmentGeneralComments';
import { useFetchEquipmentRVPOComments } from '../../../../store/infralist/comments/equipment/useFetchEquipmentRVPOComments';
import { useCommentReadMutations } from '../../../../store/infralist/comments/useCommentReadMutations';
import { useMakeDecisionEquipmentCommentaries } from '../../../../store/infralist/comments/useMakeDecisionEquipmentCommentaries';
import { useEquipmentItem } from '../../../../store/infralist/useEquipmentItem';
import {
  transformEquipmentNameListToMap,
  useEquipmentNameList
} from '../../../../store/infralist/useEquipmentNameList';
import { STORE_AUTH } from '../../../../store/names';
import { useMapToIdList } from '../../../../utils/hooks/useMapToIdList';
import { useExportInfralistComments } from '../../useExportInfralistComments';
import { CommentPage } from '../components/CommentPage/CommentPage';
import { EquipmentInfo } from './EquipmentInfo/EquipmentInfo';

export default function EquipmentCommentsPage() {
  const {
    infralistId,
    equipmentId,
    sectionId,
    directionId,
    type
  } = useParams();
  const location = useLocation();
  const client = useContextApolloClient();

  const [auth] = useStore(STORE_AUTH);
  const {
    createCommentary,
    updateCommentary,
    deleteCommentary,
    infralist
  } = useEquipmentComments({ infralistId, equipmentId, type });
  const fpoComments = useFetchEquipmentFPOComments(equipmentId);
  const rvpoComments = useFetchEquipmentRVPOComments(equipmentId);
  const generalComments = useFetchEquipmentGeneralComments(equipmentId);
  const infralistStatus = infralist.state.data?.infralist_status?.name;
  const equipment = useEquipmentItem(equipmentId);
  const equipmentNameList = useEquipmentNameList(sectionId);
  const equipmentNameMap = transformEquipmentNameListToMap(equipmentNameList);
  const onCreate = ({ content }) => {
    return createCommentary({
      content,
      rvpo_equipment_id: equipmentId,
      type,
      user_id: auth?.authData?.user?.id
    });
  };
  const exportCommentaries = useExportInfralistComments('equipment', {
    infralistId,
    directionId,
    sectionId,
    equipmentId
  });

  // обновляем данные при переходе с уведомления, чтобы отобразить актуальные комментарии
  useEffect(() => {
    if (location.state?.notificationId) {
      client.reFetchObservableQueries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, location.state?.notificationId]);

  // отправка событий что комментарии были прочитаны
  const { markEquipmentCommentsAsRead } = useCommentReadMutations({
    infralistId
  });

  const fpoCommentsIds = useMapToIdList(fpoComments);
  useEffect(() => {
    if (fpoCommentsIds.length > 0 && type === COMMENT_TYPES.FPO) {
      markEquipmentCommentsAsRead({ commentIds: fpoCommentsIds });
    }
  }, [fpoCommentsIds, markEquipmentCommentsAsRead, type]);

  const rvpoCommentsIds = useMapToIdList(rvpoComments);
  useEffect(() => {
    if (rvpoCommentsIds.length > 0 && type === COMMENT_TYPES.RVPO) {
      markEquipmentCommentsAsRead({ commentIds: rvpoCommentsIds });
    }
  }, [rvpoCommentsIds, markEquipmentCommentsAsRead, type]);

  const generalCommentsIds = useMapToIdList(generalComments);
  useEffect(() => {
    if (generalCommentsIds.length > 0 && type === COMMENT_TYPES.GENERAL) {
      markEquipmentCommentsAsRead({ commentIds: generalCommentsIds });
    }
  }, [generalCommentsIds, markEquipmentCommentsAsRead, type]);

  const {
    acceptComment,
    rejectComment
  } = useMakeDecisionEquipmentCommentaries();

  const onAccept = ({ content, ids }) => {
    return acceptComment({ content, ids, equipmentId });
  };

  const onReject = ({ content, ids }) => {
    return rejectComment({ content, equipmentId, ids });
  };

  return (
    <CommentPage
      backUrl={`/infralist/${infralistId}/directions/${directionId}/sections/${sectionId}`}
      backUrlTitle={'Назад к списку оборудования'}
      baseUrl={`/infralist-comments/${infralistId}/directions/${directionId}/sections/${sectionId}/equipment/${equipmentId}`}
      fpoComments={fpoComments}
      rvpoComments={rvpoComments}
      generalComments={generalComments}
      infralistStatus={infralistStatus}
      createComment={onCreate}
      updateComment={updateCommentary}
      deleteComment={deleteCommentary}
      title={'Комментарии'}
      commentType={type}
      rejectComment={onReject}
      acceptComment={onAccept}
      exportCommentaries={exportCommentaries}
      isEditEnabledByEventStatus={infralist.state.data?.editable}
      renderInfoContent={
        <EquipmentInfo
          data={equipment}
          equipmentNameMap={equipmentNameMap}
          equipmentNames={equipmentNameList}
          backUrl={`/infralist/${infralistId}/directions/${directionId}/sections/${sectionId}`}
          infralistStatus={infralistStatus}
          isEditEnabledByEventStatus={infralist.state.data?.editable}
        />
      }
    />
  );
}
