import { useGraphqlQueryVars } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { QUERY_GET_GRBSES } from '../../graphql/queries/grbses';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';

const grbsesQueryOptions = {
  query: QUERY_GET_GRBSES,
  mapData: (result) => result.grbses.list,
  skipQuery: skipIfNull([])
};

export function useGrbses(regionId) {
  return useGraphqlQueryVars({
    queryOptions: grbsesQueryOptions,
    variableCreator: [
      () =>
        regionId
          ? {
              input: {
                filter: {
                  region_ids: regionId
                }
              }
            }
          : null,
      [regionId]
    ]
  });
}

export function useRetriableGrbses(filter) {
  return useRetriableGraphqlVars({
    queryOptions: grbsesQueryOptions,
    variableCreator: [
      () =>
        filter
          ? {
              input: {
                filter
              }
            }
          : null,
      [filter]
    ]
  });
}
