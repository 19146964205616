import gql from 'graphql-tag';

export const QUERY_GET_REGIONS = gql`
  query getRegions {
    regions {
      id
      name
      federal_district_id
      code
      code_nalog
      code_okato
    }
  }
`;

export const QUERY_GET_SIMPLE_REGIONS = gql`
  query getSimpleRegions {
    simpleRegions {
      id
      name
    }
  }
`;
