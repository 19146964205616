import React, { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { InfralistTable } from '../../../../../common/infralist/InfralistTable/InfralistTable';
import { infralistRoutes } from '../../../../../store/infralist/routes/infralistRoutes';
import { useInfralistVersionData } from '../../../../../store/infralist/versions/useInfralistVersionData';
import { HeadTitle } from '../../../../../common/HeadTitle/HeadTitle';
import { useInfralist } from '../../../../../store/infralist/useInfralist';
import { useInfralistSectionUserAccesses } from '../../../../../store/infralist/useInfralistUserAccesses';
import { useInfralistVersion } from '../../../../../store/infralist/versions/useInfralistVersion';
import { useVersionDirection } from '../../../../../store/infralist/versions/useVersionDirection';
import { useVersionEquipmentList } from '../../../../../store/infralist/versions/useVersionEquipmentList';
import { useVersionSection } from '../../../../../store/infralist/versions/useVersionSection';
import { InfralistSectionBreadCrumbs } from '../../../direction/section/components/InfralistSectionBreadCrumbs';
import { useInfralistSectionTableColumns } from '../../../direction/section/hooks/useInfralistSectionTableColumns';
import { InfralistCheckStatus } from '../../../InfralistCheckStatus';
import { InfralistStatus } from '../../../InfralistStatus';
import { InfralistSumFilter } from '../../../InfralistSumFilter';
import { PageTitlePanel } from '../../../PageTitlePanel';
import styles from './VersionSection.module.scss';

export default function VersionSection() {
  const { infralistId, directionId, sectionId } = useParams();
  const { versionId } = useInfralistVersion();
  const history = useHistory();

  // section
  const sectionQuery = useVersionSection({ sectionId, versionId });
  const section = {
    spinner: sectionQuery.check.spinner,
    data: sectionQuery.state.data
  };
  const infralistSectionStatus = section.data?.status_by_version?.name;
  const infralistSectionCheckStatus =
    section.data?.check_status_by_version?.name;
  const infralistSectionApproveStatus =
    section.data?.approvement_status_by_version?.name;

  // direction
  const directionQuery = useVersionDirection({
    directionId,
    versionId
  });
  const direction = directionQuery.state?.data;

  // infralist
  const infralistQuery = useInfralist(infralistId);
  const infralistVersionQuery = useInfralistVersionData(versionId);
  const infralistStatus =
    infralistVersionQuery.state.data?.infralist_status?.name;

  // accesses
  const { showCheckStatusInHeader } = useInfralistSectionUserAccesses({
    sectionStatus: infralistSectionStatus,
    infralistSectionCheckStatus,
    infralistStatus,
    infralistSectionApproveStatus
  });

  // equipment list
  const {
    data: equipmentList,
    spinner: infralistEquipmentSpinner,
    loadMore,
    setEquipmentData
  } = useVersionEquipmentList({
    versionId,
    sectionId
  });

  useEffect(() => {
    history.listen(() => {
      setEquipmentData((prev) => ({
        ...prev,
        list: []
      }));
    });
  }, [history, setEquipmentData]);

  const mockSuccessPromise = new Promise((resolve) => resolve(true));

  // table columns
  const [isSpecificationsHidden, setIsSpecificationsHidden] = useState(false);
  const columns = useInfralistSectionTableColumns({
    isSpecificationsHidden,
    sectionId,
    submitSignEquipment: mockSuccessPromise,
    submitCheckEquipment: mockSuccessPromise,
    submitApproveEquipment: mockSuccessPromise,
    sectionStatus: infralistSectionStatus,
    infralistSectionCheckStatus,
    checkEditEquipments: () => false,
    infralistStatus,
    infralistSectionApproveStatus
  });

  return (
    <>
      <HeadTitle title={section.data?.name} />
      <InfralistSectionBreadCrumbs
        roadmapName={infralistQuery.state.data?.roadmap?.name}
        infralistId={infralistId}
        directionId={directionId}
        directionName={direction?.name}
      />
      <PageTitlePanel
        title={section.data?.name}
        backUrl={generatePath(infralistRoutes.DIRECTION_VERSION, {
          infralistId,
          directionId,
          versionId
        })}
        description={
          <>
            {showCheckStatusInHeader && infralistSectionCheckStatus ? (
              <InfralistCheckStatus
                isLoading={section.spinner}
                status={infralistSectionCheckStatus}
              />
            ) : (
              <InfralistStatus
                isLoading={section.spinner}
                status={infralistSectionStatus}
              />
            )}
          </>
        }
      />

      <InfralistSumFilter
        sum={sectionQuery.state.data?.section_sum_by_version}
      />

      <div className={styles.actionLine}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isSpecificationsHidden}
              onChange={() =>
                setIsSpecificationsHidden(!isSpecificationsHidden)
              }
              name="isSpecificationsHidden"
              color="primary"
              size="small"
            />
          }
          classes={{
            label: styles.hideCharacteristicsCheckbox
          }}
          label="Скрыть характеристики"
        />
      </div>
      <InfralistTable
        columns={columns}
        data={equipmentList}
        isLoading={infralistEquipmentSpinner}
        emptyMessage={<>Оборудование не найдено</>}
        minWidth={1200}
        onLoadMore={loadMore}
        readonly={true}
      />
    </>
  );
}
