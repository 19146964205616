import React, { Fragment, useMemo } from 'react';
import classNames from 'classnames';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { groupBy } from 'lodash-es';

import { useSubsidiaryEntitiesForFilter } from '../../../../store/subsidiaryEntity/useSubsidiaryEntities';
import { SubTableHeading } from '../../../../common/SubTable/SubTableHeading';
import { SubsidiaryEntity } from '../../../../store/subsidiaryEntity/SubsidiaryEntity';
import SubTable from '../../../../common/SubTable/SubTable';

import s from './StaffingTableField.module.scss';

const getStaffingTableBody = (staffSchedules, entities) => {
  if (!entities || !staffSchedules) {
    return null;
  }

  const grouped = groupBy(
    staffSchedules
      .slice()
      .sort(
        (a, b) =>
          +a.stuff_schedule_guideline.number -
          +b.stuff_schedule_guideline.number
      ),
    (staffSchedule) => staffSchedule.subsidiary_entity_id
  );

  const staffingTableBody = [];
  for (const key of Object.keys(grouped)) {
    const entity = entities.find((entity) => entity.id === key);
    if (!entity) {
      continue;
    }
    staffingTableBody.push(
      <Fragment key={key}>
        <SubTableHeading cols={6}>
          <p>{SubsidiaryEntity.getCompleteName(entity)}</p>
          <p style={{ fontWeight: 400 }}>Тип: {entity?.se_type?.name_full}</p>
        </SubTableHeading>
        {grouped[key].map((item, iItem) => {
          return (
            <TableRow key={iItem}>
              <TableCell className={s.call}>
                {item.stuff_schedule_guideline.staff_category}
              </TableCell>
              <TableCell className={s.call}>
                {item.stuff_schedule_guideline.occupation}
              </TableCell>
              <TableCell className={s.call}>
                {item.stuff_schedule_guideline.amount_of_staff}
              </TableCell>
              <TableCell className={s.call}>
                {item.stuff_schedule_guideline.comment}
              </TableCell>
              <TableCell className={s.call}>{item.occupation}</TableCell>
              <TableCell className={s.call}>{item.amount_of_staff}</TableCell>
            </TableRow>
          );
        })}
      </Fragment>
    );
  }

  return staffingTableBody;
};

export const StaffingTable = ({
  staffSchedules,
  roadmapId,
  year,
  regionId
}) => {
  const entitiesQuery = useSubsidiaryEntitiesForFilter({
    region_ids: regionId,
    owner_roadmap_ids: roadmapId,
    year: year + ''
  });
  const entities = entitiesQuery.state.data?.list;
  const body = useMemo(() => getStaffingTableBody(staffSchedules, entities), [
    staffSchedules,
    entities
  ]);

  return (
    <SubTable cols={6} title="Штатное расписание">
      <TableRow>
        <TableCell className={classNames(s.call, s.callCaption)} colSpan={4}>
          Методические рекомендации
        </TableCell>
        <TableCell className={classNames(s.call, s.callCaption)} colSpan={2}>
          Факт
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classNames(s.call, s.callCaption)}>
          Категория персонала
        </TableCell>
        <TableCell className={classNames(s.call, s.callCaption)}>
          Должность
        </TableCell>
        <TableCell className={classNames(s.call, s.callCaption)}>
          Количество штатных единиц
        </TableCell>
        <TableCell className={classNames(s.call, s.callCaption)}>
          Комментарий
        </TableCell>
        <TableCell className={classNames(s.call, s.callCaption)}>
          Должность из штатного расписания
        </TableCell>
        <TableCell className={classNames(s.call, s.callCaption)}>
          Количество штатных единиц
        </TableCell>
      </TableRow>
      {body}
    </SubTable>
  );
};
