import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useState } from 'react';
import { MUTATION_SIGN_DIRECTION_EQUIPMENTS } from '../../../graphql/mutations/equipment';
import { clearApolloQueryCache } from '../../../utils/apollo';
import { INFRALIST_QUERIES } from '../infralistQueries';

export const useSignAllDirectionEquipment = () => {
  const client = useContextApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  const signAllDirectionEquipments = ({ infralistId, directionId }) => {
    setIsLoading(true);
    return client
      .mutate({
        mutation: MUTATION_SIGN_DIRECTION_EQUIPMENTS,
        variables: {
          infralist_id: infralistId,
          direction_id: directionId
        },
        update: (store) => {
          clearApolloQueryCache(store, [
            INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT
          ]);
        }
      })
      .finally(() => setIsLoading(false));
  };

  return {
    isLoadingSignDirectionEquipments: isLoading,
    signAllDirectionEquipments
  };
};
