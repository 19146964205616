import React, { useEffect } from 'react';
import { TextInput } from '../../../common/Inputs/TextInput';
import { useFieldFast } from '../../../common/InputsNew/useFieldFast';
import { EMDASH } from '../../../utils/constants';

export const IndicatorFactValueField = ({
  name,
  value: propValue,
  calculated,
  ...props
}) => {
  const [field, { touched, error }, { setValue }] = useFieldFast(name);

  useEffect(() => {
    if (calculated) {
      setValue(propValue);
    }
  }, [propValue, setValue, calculated]);

  return !calculated ? (
    <TextInput {...props} field={field} errorMessage={touched && error} />
  ) : (
    propValue || EMDASH
  );
};
