import React from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { ParentalAssistanceGuideline } from '../../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import { removeLastPathItem } from '../../../../utils/url';
import { useOtherEditor } from '../../../../common/Form/useOtherEditor';
import { useParentalAssistanceGuidelineRegional } from '../../../../store/parentalAssistanceGuideline/useParentalAssistanceGuidelineRegional';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import { handleFormSubmission } from '../../../../utils/data/form';
import { STORE_PARENTAL_ASSISTANCE_GUIDELINE } from '../../../../store/names';
import { ParentalAssistanceGuidelinesRegionalValidationSchema } from '../../../../utils/data/validation';
import { ParentalAssistanceGuidelinesRegionalEditForm } from './ParentalAssistanceGuidelinesRegionalEditForm';

export default function ParentalAssistanceGuidelinesRegionalEditPage({
  history,
  match
}) {
  const parentalAssistanceGuideline = useContextStore(
    STORE_PARENTAL_ASSISTANCE_GUIDELINE
  );
  const backLink = removeLastPathItem(match.url);
  const { guidelineId } = match.params;

  const editor = useOtherEditor(
    'parentalAssistanceGuidelinesRegional',
    guidelineId
  );

  const parentalAssistanceGuidelineRegionalQuery = useParentalAssistanceGuidelineRegional(
    guidelineId
  );
  const parentalAssistanceGuidelineRegional =
    parentalAssistanceGuidelineRegionalQuery.state.data;

  const region = parentalAssistanceGuidelineRegional?.region;

  return (
    <>
      <HeadTitle
        type="Перечень вопросов по апробации методических рекомендаций"
        pageType={HeadTitle.PageType.edit}
      />
      <h2>
        Редактирование перечня вопросов по апробации методических рекомендаций
      </h2>
      {queryLoaderWithExistenceCheck(
        parentalAssistanceGuidelineRegionalQuery
      ) || (
        <Formik
          validationSchema={
            ParentalAssistanceGuidelinesRegionalValidationSchema
          }
          initialValues={ParentalAssistanceGuideline.toRegionalForm(
            parentalAssistanceGuidelineRegional
          )}
          onSubmit={(values, actions) => {
            const form = ParentalAssistanceGuideline.fromRegionalForm(values);
            handleFormSubmission(
              parentalAssistanceGuideline.updateParentalAssistanceGuidelinesRegional(
                form
              ),
              actions,
              (result) =>
                history.goBack(
                  ParentalAssistanceGuideline.getRegionalUrl(result.data.result)
                )
            );
          }}
          render={(formikProps) => (
            <ParentalAssistanceGuidelinesRegionalEditForm
              {...formikProps}
              onBackClick={() => history.goBack(backLink)}
              editor={editor}
              region={region}
            />
          )}
        />
      )}
    </>
  );
}
