import gql from 'graphql-tag';

export const QUERY_GET_GRBSES = gql`
  query grbses($input: GrbsPageInput) {
    grbses(input: $input) {
      list {
        id
        name_full
        code
        code_g
      }
    }
  }
`;
