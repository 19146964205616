import { clearApolloQueryCache } from '../../utils/apollo';
import {
  MUTATION_APPROVE_CONTEST_DOCS_ENTITY,
  MUTATION_DELETE_CONTEST_DOCS_ENTITY,
  MUTATION_UPDATE_CONTEST_DOCS_ENTITY,
  MUTATION_REQUEST_EDIT_CONTEST_DOCS_ENTITY,
  MUTATION_ALLOW_EDIT_CONTEST_DOCS_ENTITY,
  MUTATION_REJECT_EDIT_CONTEST_DOCS_ENTITY
} from '../../graphql/mutations/contestDocsMonitoring';

export class ContestDocsMonitoringStore {
  constructor({ client }) {
    this.client = client;
  }

  queriesToClear = [
    'monthlyMonitoringTenderDocumentationsPage',
    'monthlyMonitoringTenderDocumentation'
    // 'regions',
    // 'federalProjectRoadmaps'
  ];

  queriesToRefetch = [
    'monthlyMonitoringTenderDocumentationsPage',
    'monthlyMonitoringTenderDocumentation'
  ];

  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  update = (input) => {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_CONTEST_DOCS_ENTITY,
      variables: {
        input
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };

  delete = (id) => {
    return this.client.mutate({
      mutation: MUTATION_DELETE_CONTEST_DOCS_ENTITY,
      variables: {
        id
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };

  approve = (id) => {
    return this.client.mutate({
      mutation: MUTATION_APPROVE_CONTEST_DOCS_ENTITY,
      variables: {
        id
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };

  requestEdit = (id) => {
    return this.client.mutate({
      mutation: MUTATION_REQUEST_EDIT_CONTEST_DOCS_ENTITY,
      variables: {
        id
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };

  allowEdit = (id) => {
    return this.client.mutate({
      mutation: MUTATION_ALLOW_EDIT_CONTEST_DOCS_ENTITY,
      variables: {
        id
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };

  rejectEdit = (id) => {
    return this.client.mutate({
      mutation: MUTATION_REJECT_EDIT_CONTEST_DOCS_ENTITY,
      variables: {
        id
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };
}
