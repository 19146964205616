export const getSubsidiaryLabel = (subsidiary) => {
  if (!subsidiary) {
    return '';
  }
  return `${subsidiary.name_full} ${
    subsidiary.base_organization?.name_full
      ? `- ${subsidiary.base_organization?.name_full}`
      : ''
  } ${
    (subsidiary.inn || subsidiary.kpp) &&
    `(${subsidiary.inn && `ИНН ${subsidiary.inn}`} ${
      subsidiary.inn && subsidiary.kpp && ' ,'
    } ${subsidiary.kpp && `КПП ${subsidiary.kpp}`})`
  }`;
};
