import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { NewAutocompleteInput } from '../../../common/InputsNew/NewAutocompleteInput';
import { ParentalAssistanceGuideline } from '../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import s from '../ParentalAssistanceGuidelinesRegional/ParentalAssistanceGuidelinesRegionalPage.module.scss';

export const PossibleChoiceFields = ({ startOfNumerate }) => {
  return (
    <>
      <TableRow>
        <TableCell className={s.question}>
          {startOfNumerate}. Предусмотрена ли возможность для получателя услуги
          выбора:
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="possible_specialist_choice"
            label="специалиста"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            hasCopyBtn={false}
            fullWidth
          />
          <NewAutocompleteInput
            name="possible_time_choice"
            label="времени оказания услуги"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            hasCopyBtn={false}
            fullWidth
          />
          <NewAutocompleteInput
            name="possible_format_choice"
            label="формата предоставления услуги"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            hasCopyBtn={false}
            fullWidth
          />
          <NewAutocompleteInput
            name="possible_consultation_topic_choice"
            label="тематики консультации заранее"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            hasCopyBtn={false}
            fullWidth
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          {startOfNumerate + 1}. Предусмотрена ли возможность предоставления
          услуг:
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="possible_nwh_services_provisioning"
            label="в нерабочее время"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            hasCopyBtn={false}
            fullWidth
          />
          <NewAutocompleteInput
            name="possible_weekend_services_provisioning"
            label="в выходные дни"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            hasCopyBtn={false}
            fullWidth
          />
        </TableCell>
      </TableRow>
    </>
  );
};
