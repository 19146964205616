import gql from 'graphql-tag';

export const QUERY_GET_RESULT_FEDERALS = gql`
  query searchResults($input: FederalResultsPageInput!) {
    resultFederals(input: $input) {
      list {
        id
        name
        name_full
      }
    }
  }
`;
