import { pick } from 'lodash-es';

const roleFields = ['name', 'permissions', 'base_role_id'];
const permissionsFields = ['base_permission_id', 'object_id', 'object_type'];

export class Role {
  static filterUpdateFields(data) {
    return pick(data, ['id', ...roleFields]);
  }

  static filterCreateFields(data) {
    return pick(data, roleFields);
  }

  static fromForm({ permissions, ...role }) {
    return {
      ...role,
      permissions: permissions.map((permission) =>
        pick(permission, [...permissionsFields])
      )
    };
  }

  static getUrl(role) {
    return `/roles/${role.id}`;
  }
}
