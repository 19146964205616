import { useMemo } from 'react';
import * as Yup from 'yup';
import { removeEmptyRowsFromString } from '../../utils/data/string';
import { validationMessages } from '../../utils/data/validation';

const urlValidation = Yup.object().shape({
  data: Yup.array().of(
    Yup.string()
      .required(validationMessages.requiredMessage)
      .url(validationMessages.urlMessage)
  )
});

export const useValidationMultipleLinks = () => {
  return useMemo(
    () =>
      Yup.string()
        .test({
          name: 'multiple-links',
          message: validationMessages.urlMessage,
          test: (value) => {
            return urlValidation.isValidSync({
              data: removeEmptyRowsFromString(value)
            });
          }
        })
        .required(validationMessages.requiredMessage),
    []
  );
};
