import React, { useEffect } from 'react';
import { useCurrentUser } from '../../store/useCurrentUser';
import Loader from '../../common/Loader/Loader';
import { useRpImplementationForecast } from '../../store/rpImplementationForecast/useRpImplementationForecast';

export default function RpImplementationForecastsPage({ history }) {
  const user = useCurrentUser();

  const rpImplementationForecastQuery = useRpImplementationForecast(
    {
      region_id: user.region?.id
    },
    true
  );

  const rpImplementationForecast = rpImplementationForecastQuery.state.data;

  useEffect(() => {
    !rpImplementationForecastQuery.check.spinner &&
      rpImplementationForecast &&
      history.replace(
        `/rpImplementationForecast/${rpImplementationForecast.id}`
      );
  }, [
    history,
    rpImplementationForecast,
    rpImplementationForecastQuery.check.spinner
  ]);

  return <Loader />;
}
