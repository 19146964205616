import { QUERY_GET_REGIONAL_PROJECTS_LIST } from '../../graphql/queries/regionalProjects';
import { QUERY_SUBSIDIARY_ENTITIES_FOR_FILTER } from '../../graphql/queries/subsidiaryEntities';
import { QUERY_GET_EXPENSES_DIRECTIONS } from '../../graphql/queries/expensesDirections';
import { QUERY_GET_ORGANIZATIONS } from '../../graphql/queries/organizations';
import { QUERY_GET_GRBSES } from '../../graphql/queries/grbses';
import { QUERY_GET_RESULT_FEDERALS } from '../../graphql/queries/results';
import { defaultPaginationParams } from '../../common/usePagination';
import { QUERY_GET_FEDERAL_PROJECTS } from '../../graphql/queries/federalProjects';
import { QUERY_GET_FEDERAL_DISTRICTS } from '../../graphql/queries/federalDistricts';
import { QUERY_GET_REGIONS } from '../../graphql/queries/regions';
import {
  QUERY_GET_FEDERAL_PROJECT_ROADMAPS,
  QUERY_GET_ROADMAP_EVENTS
} from '../../graphql/queries/roadmapEvents';
import { QUERY_GET_SUPPLIER_DETERMINATIONS } from '../../graphql/queries/supplierDeterminations';
import { QUERY_ROADMAP_INDICATORS } from '../../graphql/queries/indicators';
import { QUERY_GET_TASK_FPS } from '../../graphql/queries/taskFps';
import { getName } from '../../utils/data/label';
import { QUERY_GET_FORMS } from '../../graphql/queries/forms';
import { QUERY_GET_USERS } from '../../graphql/queries/users';
import { EMDASH } from '../../utils/constants';
import { QUERY_GET_NON_COMMERCIAL_ORGANIZATIONS } from '../../graphql/queries/parentalAssistanceGuidelines/nonCommercialOrganizations';
import { Form } from './Form';

export const mapEntityOption = (entity) => ({
  label: getName(entity),
  value: entity.id
});

export const mapEntityOptions = (entities) => entities?.map(mapEntityOption);

export const syncEntities = [
  Form.EntityClass.FederalProject,
  Form.EntityClass.FederalDistrict,
  Form.EntityClass.Region,
  Form.EntityClass.Roadmap,
  Form.EntityClass.SupplierDetermination,
  Form.EntityClass.RoadmapIndicator,
  Form.EntityClass.RoadmapEvent,
  Form.EntityClass.TaskFp
];

export function getSyncEntityQueryOptions(entity) {
  if (entity === Form.EntityClass.FederalProject) {
    return {
      query: QUERY_GET_FEDERAL_PROJECTS,
      mapData: (result) => result.federalProjects
    };
  } else if (entity === Form.EntityClass.FederalDistrict) {
    return {
      query: QUERY_GET_FEDERAL_DISTRICTS,
      mapData: (result) => result.federalDistricts
    };
  } else if (entity === Form.EntityClass.Region) {
    return {
      query: QUERY_GET_REGIONS,
      mapData: (result) => result.regions
    };
  } else if (entity === Form.EntityClass.Roadmap) {
    return {
      query: QUERY_GET_FEDERAL_PROJECT_ROADMAPS,
      mapData: (result) => result.federalProjectRoadmaps
    };
  } else if (entity === Form.EntityClass.SupplierDetermination) {
    return {
      query: QUERY_GET_SUPPLIER_DETERMINATIONS,
      mapData: (result) => result.supplierDeterminations
    };
  } else if (entity === Form.EntityClass.RoadmapIndicator) {
    return {
      query: QUERY_ROADMAP_INDICATORS,
      mapData: (result) => result.roadmapIndicators
    };
  } else if (entity === Form.EntityClass.RoadmapEvent) {
    return {
      query: QUERY_GET_ROADMAP_EVENTS,
      mapData: (result) => result.roadmapEventsPage.list
    };
  } else if (entity === Form.EntityClass.TaskFp) {
    return {
      query: QUERY_GET_TASK_FPS,
      mapData: (result) => result.taskFps
    };
  } else {
    throw new Error(`Неизвестный тип сущности '${entity}'`);
  }
}

const loadQueryBase = (query, getList, client, filter) =>
  client
    .query({
      query,
      variables: {
        input: {
          filter,
          pagination: defaultPaginationParams
        }
      }
    })
    .then((result) => getList(result.data));

const loadQueryOptions = ({ query, getList, client, variables, filter }) => (
  search
) =>
  loadQueryBase(query, getList, client, { search, ...variables }).then(
    filter || mapEntityOptions
  );

const loadQueryOption = ({ query, getList, client }) => (id) =>
  loadQueryBase(query, getList, client, { ids: id })
    .then((list) => list[0])
    .then(mapEntityOption);

const loadQuery = (args) => [loadQueryOptions(args), loadQueryOption(args)];

export function getAsyncEntityLoader({ entity, client, variables, filter }) {
  const creator = (query, getList) =>
    loadQuery({
      query,
      getList,
      client,
      variables,
      filter
    });

  if (entity === Form.EntityClass.RegionalProject) {
    return creator(
      QUERY_GET_REGIONAL_PROJECTS_LIST,
      (result) => result.regionalProjects.list
    );
  } else if (entity === Form.EntityClass.SubsidiaryEntity) {
    return creator(
      QUERY_SUBSIDIARY_ENTITIES_FOR_FILTER,
      (result) => result.subsidiaryEntitiesPage.list
    );
  } else if (entity === Form.EntityClass.ExpensesDirection) {
    return creator(
      QUERY_GET_EXPENSES_DIRECTIONS,
      (result) => result.expensesDirections.list
    );
  } else if (entity === Form.EntityClass.Organization) {
    return creator(
      QUERY_GET_ORGANIZATIONS,
      (result) => result.organizations.list
    );
  } else if (entity === Form.EntityClass.Grbs) {
    return creator(QUERY_GET_GRBSES, (result) => result.grbses.list);
  } else if (entity === Form.EntityClass.ResultFp) {
    return creator(
      QUERY_GET_RESULT_FEDERALS,
      (result) => result.resultFederals.list
    );
  } else if (entity === Form.EntityClass.Form) {
    return creator(QUERY_GET_FORMS, (result) => result.forms.list);
  } else if (entity === Form.EntityClass.Users) {
    return creator(QUERY_GET_USERS, (result) =>
      result.users.list.map((entity) => ({
        ...entity,
        name: entity.name + ` (${entity.email || entity.login || EMDASH})`
      }))
    );
  } else if (entity === Form.EntityClass.NonCommercialOrganizations) {
    return creator(
      QUERY_GET_NON_COMMERCIAL_ORGANIZATIONS,
      (result) => result.nonCommercialOrganizations.list
    );
  } else {
    return [undefined, undefined];
  }
}
