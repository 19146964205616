import React, { useCallback, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Button } from '@material-ui/core';
import { useContextStore } from '@proscom/prostore-react';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { queryLoaderWithExistenceCheck } from '../../../common/QueryLoader';
import SubTable from '../../../common/SubTable/SubTable';
import { useRegions } from '../../../store/subsidiaryEntity/useSubsidiaryEntity';
import AutocompleteBaseInput from '../../../common/Inputs/Selects/AutocompleteBaseInput';
import { useMemoSuggestions } from '../../../utils/suggestions';
import { PageActionsBar } from '../../../common/PageActionsBar/PageActionsBar';
import { downloadRestFile } from '../../../utils/AxiosHelpers';
import { STORE_FILE_TOKEN } from '../../../store/names';
import s from '../MentoringCoverage.module.scss';

const title = 'Отчет охвата наставничеством';
export default function ReportMentoringCoverage({ match, history }) {
  const user = useCurrentUser();
  const tokenStore = useContextStore(STORE_FILE_TOKEN);
  const regionsQuery = useRegions();
  const regionSuggestion = useMemoSuggestions(regionsQuery.state.data);
  const [regions, setRegions] = useState([]);

  const handleSubmit = useCallback(async () => {
    const accessToken = await tokenStore.createMentoringCoverageReportOneTimeToken();
    const now = new Date().toISOString();
    downloadRestFile({
      filters: { region_ids: regions.map((item) => item.value) },
      responseUrl: '/reference/mentoring_coverage_report',
      method: 'post',
      accessToken
    });
  }, [regions, tokenStore]);

  return (
    <>
      <HeadTitle title={title} />
      <PageTitle title={title} withBackBtn />
      <PageActionsBar />
      {queryLoaderWithExistenceCheck(regionsQuery) || (
        <>
          <SubTable>
            <TableRow>
              <TableCell>Выбор регионов</TableCell>
              <TableCell>
                <AutocompleteBaseInput
                  onChange={setRegions}
                  label="Регионы"
                  name="regions"
                  value={regions}
                  suggestions={regionSuggestion}
                  hasCopyBtn={false}
                  fullWidth
                  isMulti
                  useObject
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={s.button} colSpan={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Сформировать отчет
                </Button>
              </TableCell>
            </TableRow>
          </SubTable>
        </>
      )}
    </>
  );
}
