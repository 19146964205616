import React, { useCallback } from 'react';
import ReactNumberFormat from 'react-number-format';
import { TextField } from '../TextField/TextField';

export const NumberFormat = ({
  onChange,
  onValueChange,
  error,
  ...restProps
}) => {
  const handleValueChange = useCallback(
    (values) => {
      onValueChange(values.floatValue ?? '');
    },
    [onValueChange]
  );
  return (
    <ReactNumberFormat
      thousandSeparator=" "
      decimalSeparator=","
      onValueChange={handleValueChange}
      customInput={TextField}
      error={error}
      {...restProps}
    />
  );
};
