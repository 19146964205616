import React from 'react';
import Button from '@material-ui/core/Button';
import MaterialDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ProgressButton } from './ProgressButton';

const Dialog = ({
  handleAccept,
  open,
  handleClose,
  title,
  children,
  isLoading,
  titleAccept = 'Да',
  titleRefuse = 'Нет',
  additionalButtons,
  showAcceptButton = true,
  showCancelButton = true
}) => {
  return (
    <MaterialDialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {additionalButtons && additionalButtons}
        {showAcceptButton && (
          <ProgressButton
            isLoading={isLoading}
            onClick={handleAccept}
            color="primary"
          >
            {titleAccept}
          </ProgressButton>
        )}
        {showCancelButton && (
          <Button onClick={handleClose} color="primary" autoFocus>
            {titleRefuse}
          </Button>
        )}
      </DialogActions>
    </MaterialDialog>
  );
};

export default Dialog;
