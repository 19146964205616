import { Field } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import { NumberFormat } from '../FormFields/NumberFormat/NumberFormat';

export const FormikNumber = ({ name, ...restProps }) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => (
        <NumberFormat
          {...field}
          {...restProps}
          name={name}
          error={meta.touched && meta.error ? meta.error : ''}
          onValueChange={(nextValue) => form.setFieldValue(name, nextValue)}
        />
      )}
    </Field>
  );
};

FormikNumber.propTypes = {
  name: PropTypes.string.isRequired
};
