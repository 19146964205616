import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_SUBSIDIARY_MONITORING_PAGE } from '../../graphql/queries/subsidiaryMonitoring';

const subsidiaryMonitoringPageQueryOptions = {
  query: QUERY_SUBSIDIARY_MONITORING_PAGE,
  mapData: (data) => data.subsidiaryEntityMonitoringPage,
  skipQuery: skipIfNull(null)
};

export function useSubsidiaryMonitoringPage(filter, paginationParams) {
  return useRetriableGraphqlVars({
    queryOptions: subsidiaryMonitoringPageQueryOptions,
    variableCreator: [
      () =>
        filter
          ? {
              input: {
                filter: filter,
                pagination: paginationParams
              }
            }
          : null,
      [filter, paginationParams]
    ],
    options: {
      apolloOptions: {
        fetchPolicy: 'network-only'
      }
    }
  });
}
