import React, { useState } from 'react';
import { ChangeLogSidebar } from './sidebar/ChangeLogSidebar';
import { ChangeLogDiff } from './diff/ChangeLogDiff';
import s from './InfralistChangeLogs.module.scss';

export const InfralistChangeLogs = () => {
  const [currentChangeLogId, setCurrentChangeLogId] = useState();

  return (
    <div className={s.root}>
      <ChangeLogSidebar
        currentChangeLogId={currentChangeLogId}
        setCurrentChangeLogId={setCurrentChangeLogId}
      />
      {currentChangeLogId && (
        <ChangeLogDiff currentChangeLogId={currentChangeLogId} />
      )}
    </div>
  );
};
