import gql from 'graphql-tag';
import { FRAGMENT_FORM, FRAGMENT_FORM_DOCUMENT } from '../queries/forms';

export const MUTATION_UPDATE_FORM = gql`
  mutation updateForm($input: CustomFormInput!) {
    result: updateForm(input: $input) {
      ...form
    }
  }
  ${FRAGMENT_FORM}
`;

export const MUTATION_CREATE_FORM = gql`
  mutation createForm($input: CustomFormInput!) {
    result: createForm(input: $input) {
      ...form
    }
  }
  ${FRAGMENT_FORM}
`;

export const MUTATION_DELETE_FORM = gql`
  mutation deleteForm($id: ID!) {
    result: deleteForm(id: $id)
  }
`;

export const MUTATION_UPDATE_FORM_DOCUMENT = gql`
  mutation updateFormDocument($input: CustomFormDocumentInput!) {
    result: updateFormDocument(input: $input) {
      ...formDocument
    }
  }
  ${FRAGMENT_FORM_DOCUMENT}
`;

export const MUTATION_CREATE_FORM_DOCUMENT = gql`
  mutation createFormDocument($input: CustomFormDocumentInput!) {
    result: createFormDocument(input: $input) {
      ...formDocument
    }
  }
  ${FRAGMENT_FORM_DOCUMENT}
`;

export const MUTATION_DELETE_FORM_DOCUMENT = gql`
  mutation deleteFormDocument($id: ID!) {
    result: deleteFormDocument(id: $id)
  }
`;
