import React from 'react';
import { FieldArray } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SubTable from '../../../common/SubTable/SubTable';
import { EMDASH } from '../../../utils/constants';
import { ContestDocsIndicatorPreview } from './ContestDocsIndicator/ContestDocsIndicatorPreview';

export const ContestDocsMonitoringFormPreview = ({ entity, values }) => {
  const indicators = entity.roadmap_indicators;
  const hasIndicators = !!indicators && indicators.length > 0;

  return (
    <>
      {hasIndicators && (
        <FieldArray name={`indicators`}>
          {() =>
            indicators?.map((ind, iInd) => (
              <ContestDocsIndicatorPreview
                key={iInd}
                index={iInd}
                indicator={ind}
                values={values}
              />
            ))
          }
        </FieldArray>
      )}

      <SubTable>
        <TableRow>
          <TableCell>Аналитическая информация</TableCell>
          <TableCell>{entity.analytical_information || EMDASH}</TableCell>
        </TableRow>
      </SubTable>
    </>
  );
};
