import { useCallback } from 'react';
import { useParams, generatePath } from 'react-router-dom';
import { versionRouteRelates } from './useVersionRouteRelates';

export const useInfralistVersion = () => {
  const { versionId } = useParams();

  const generatePathBasedOnVersion = useCallback(
    (path, params) => {
      const currentRoutes = versionRouteRelates.find(
        (currentRouteRelates) =>
          currentRouteRelates.baseRoute === path ||
          currentRouteRelates.versionRote === path
      );
      if (!currentRoutes) {
        return generatePath(path, params);
      }
      const targetPath = !!versionId
        ? currentRoutes.versionRote
        : currentRoutes.baseRoute;
      return generatePath(targetPath, {
        ...params,
        versionId
      });
    },
    [versionId]
  );

  return {
    isVersionMode: !!versionId,
    versionId,
    generatePathBasedOnVersion
  };
};
