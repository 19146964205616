import gql from 'graphql-tag';
import { FRAGMENT_PAGINATION_HEADER } from './common';
import { FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT } from './subsidiaryEntity';

export const QUERY_CLASSES_SCHEDULES_PAGE = gql`
  query classesSchedulesPage($input: ClassesSchedulesPageInput) {
    classesSchedulesPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        id
        date
        fact_date
        number
        confirmation_date
        comment_fpo
        subsidiary_entity_id
        year
        subsidiary_entity {
          ...subsidiaryEntityForSelect
          region {
            id
            name
          }
        }
      }
    }
  }

  ${FRAGMENT_PAGINATION_HEADER}
  ${FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT}
`;

export const FRAGMENT_CLASSES_SCHEDULE = gql`
  fragment classesSchedule on ClassesScheduleType {
    id
    comment_fpo
    confirmation_date
    date
    fact_date
    number
    status
    subsidiary_entity {
      ...subsidiaryEntityForSelect
      owner_roadmap {
        id
        name_full
      }
      region {
        id
        name
      }
      regional_project {
        id
        name
      }
    }
    subsidiary_entity_id
    version
    year
  }

  ${FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT}
`;

export const QUERY_CLASSES_SCHEDULE = gql`
  query classesSchedule($id: ID!) {
    classesSchedule(id: $id) {
      ...classesSchedule
    }
  }

  ${FRAGMENT_CLASSES_SCHEDULE}
`;
