import React, { useMemo } from 'react';
import classnames from 'classnames';
import isEmpty from 'lodash-es/isEmpty';
import MaterialTable from 'material-table';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Signals } from '../Inputs/Signals';
import { icons, localization } from './constants';
import s from './Table.module.scss';

export function isTableFieldRequired(rowData, columnDef) {
  const { required } = columnDef;
  if (typeof required === 'function') {
    return required(rowData);
  } else {
    return required;
  }
}

function checkRequiredFields(newData, columns) {
  let res = true;
  for (const columnDef of columns) {
    const { required } = columnDef;
    const isRequire = isTableFieldRequired(newData, columnDef);

    if (isRequire) {
      if (!newData[columnDef.field] && newData[columnDef.field] !== 0) {
        res = false;
      }
      if (typeof required === 'function') {
        res = false;
      }
    }
  }
  return res;
}

const TableInput = ({
  columns,
  form,
  field,
  tableName,
  signals,
  renderTotalRow,
  actionsColumnIndex = 7,
  canAdd = true,
  canDelete = true,
  canUpdate = true,
  customIcons,
  errorMessage = null
}) => {
  const { value, name } = field;

  const tableOptions = {
    draggable: false,
    grouping: false,
    paging: false,
    search: false,
    sorting: false
  };

  const materialIcons = useMemo(() => {
    return {
      ...icons,
      ...(customIcons || {})
    };
  }, [customIcons]);

  const onRowAdd = (newData) =>
    new Promise((resolve, reject) => {
      if (!checkRequiredFields(newData, columns)) {
        return reject();
      }
      resolve();
      form.setFieldValue(name, [...value, newData]);
    });

  const onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      if (!checkRequiredFields(newData, columns)) {
        return reject();
      }
      resolve();
      const data = [...value];
      data[data.indexOf(oldData)] = newData;
      form.setFieldValue(name, data);
    });

  const onRowDelete = (oldData) =>
    new Promise((resolve) => {
      resolve();
      const data = [...value];
      data.splice(data.indexOf(oldData), 1);
      form.setFieldValue(name, data);
    });

  const editable = {};
  if (canAdd) editable.onRowAdd = onRowAdd;
  if (canUpdate) editable.onRowUpdate = onRowUpdate;
  if (canDelete) editable.onRowDelete = onRowDelete;

  const isEditable = !isEmpty(editable);

  return (
    <div
      className={classnames(s.EditableTable, {
        [s.EditableTable_editable]: isEditable,
        [s.EditableTable_error]: !!errorMessage
      })}
    >
      <MaterialTable
        columns={columns}
        data={value}
        editable={editable}
        icons={materialIcons}
        localization={localization}
        options={{
          ...tableOptions,
          actionsColumnIndex
        }}
        title={tableName}
      />
      {renderTotalRow && renderTotalRow(value)}
      <Signals signals={signals && signals[name]} />
      {errorMessage && (
        <FormHelperText
          className={s.EditableTable__error}
          error={!!errorMessage}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </div>
  );
};

export default React.memo(TableInput);
