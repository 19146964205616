import React, { useCallback, useMemo, useState } from 'react';
import { Modal, TableCell } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import AutocompleteBaseInput from '../../../../common/Inputs/Selects/AutocompleteBaseInput';
import { useArchiveDownloadingFile } from '../../downloadArchiveFile';
import s from '../EventPage.module.scss';

const defaultAllSuggestion = {
  label: 'Все субсидиарные сущности',
  value: null
};

export function DownloadArchiveFilePopup({ documents, regionalEventId }) {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState(defaultAllSuggestion.value);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const SESuggestion = useMemo(() => {
    const documentSE = documents
      ? documents
          .map((item) => {
            if (!item.subsidiary_entity) {
              return null;
            }
            return {
              label: item.subsidiary_entity.name_full,
              value: item.subsidiary_entity.id
            };
          })
          .filter((i) => i)
      : [];

    return [defaultAllSuggestion, ...documentSE];
  }, [documents]);

  const {
    handleArchiveDownload: downloadArchiveFile,
    isDownloading
  } = useArchiveDownloadingFile(regionalEventId, null, {}, handleClose);

  const handleArchiveDownload = () => {
    const SEID = {};
    if (filter) {
      SEID.subsidiaryEntityIds = [filter];
    }
    downloadArchiveFile(SEID);
  };

  return (
    <>
      <TableRow>
        <TableCell className={s.cellStyle}>
          Скачивание документов мероприятия
        </TableCell>
        <TableCell>
          <div>
            <Button
              variant="contained"
              color="primary"
              component="span"
              onClick={handleOpen}
            >
              Скачать архивом
            </Button>
          </div>
        </TableCell>
      </TableRow>
      {isOpen && (
        <Modal
          open={isOpen}
          onClose={handleClose}
          className={s.downloadButtonModal}
        >
          <div className={s.downloadButtonModal__content}>
            <AutocompleteBaseInput
              label={'Выбор субсидиарной сущности'}
              onChange={setFilter}
              hasCopyBtn={false}
              isClearable={false}
              value={filter}
              suggestions={SESuggestion}
            />
            <Button
              variant="contained"
              color="primary"
              component="span"
              onClick={handleArchiveDownload}
              disabled={isDownloading}
            >
              {isDownloading ? 'Скачивание' : 'Скачать архивом'}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}
