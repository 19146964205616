import React, { useMemo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { format } from 'date-fns';
import { EMDASH } from '../../../../utils/constants';
import { SubsidiaryMonitoringEntity } from '../../../../store/subsidiaryMonitoring/SubsidiaryMonitoringEntity';
import SubTable from '../../../../common/SubTable/SubTable';
import {
  parseApiDate,
  removeTimezoneFromDate
} from '../../../../utils/data/date';
import { DATE_FORMAT_RUSSIAN } from '../../../../utils/date';

export const SubsidiaryMonitoringInfoItemPreview = ({ field }) => {
  const fieldObj = field.additional_field;
  const valueName = SubsidiaryMonitoringEntity.getAddFieldValueName(fieldObj);
  const type = fieldObj.data_type;
  const value = field[valueName]?.toString();

  const valueStr = useMemo(() => {
    if (!value) return null;

    const isDate =
      type === SubsidiaryMonitoringEntity.AditionalFieldDataType.DATE;
    if (isDate) {
      const d = removeTimezoneFromDate(parseApiDate(value));
      return d ? format(d, DATE_FORMAT_RUSSIAN) : null;
    }

    return value;
  }, [value, type]);

  return (
    <SubTable title={fieldObj?.name_full || EMDASH}>
      <TableRow>
        <TableCell
          style={{
            whiteSpace: 'pre-wrap'
          }}
        >
          {valueStr || EMDASH}
        </TableCell>
        <TableCell>{field.note || EMDASH}</TableCell>
      </TableRow>
    </SubTable>
  );
};
