import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import s from './FixedContainer.module.scss';

const paddingSize = 10;

export const FixedContainer = (props) => {
  const [fixed, setFixed] = useState(false);
  const containerPosition = useRef(null);
  const container = useRef(null);

  const handleScroll = useCallback(() => {
    if (container.current && window) {
      const currentScroll = window.pageYOffset;
      const elementRect = container.current.getBoundingClientRect();
      if (!containerPosition.current) {
        containerPosition.current = elementRect.top + currentScroll;
      }

      if (currentScroll >= containerPosition.current - paddingSize) {
        if (fixed !== true) {
          setFixed(true);
          window.scrollTo(0, currentScroll - elementRect.height);
        }
      } else if (
        currentScroll <
        containerPosition.current - elementRect.height + paddingSize
      ) {
        if (fixed !== false) {
          setFixed(false);
        }
      }
    }
  }, [fixed]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <div
      ref={container}
      className={classNames(s.fixedContainer, props.classWrapper, {
        [s.fixed]: fixed
      })}
    >
      {props.children}
    </div>
  );
};

FixedContainer.propTypes = {
  classContainer: PropTypes.string,
  classWrapper: PropTypes.string,
  children: PropTypes.node
};
