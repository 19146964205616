import gql from 'graphql-tag';

export const QUERY_GET_TASK_FPS = gql`
  query getTaskFps {
    taskFps {
      id
      code
      description
    }
  }
`;
