import React, { useMemo, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { generatePath } from 'react-router-dom';
import { Autocomplete } from '../../../../common/infralist/FormFields/Autocomplete/Autocomplete';
import { MultiSelectBlock } from '../../../../common/MultiSelectBlock/MultiSelectBlock';
import { infralistRoutes } from '../../../../store/infralist/routes/infralistRoutes';
import { useSearchOptions } from './useSearchOptions';
import { useChangeLogDatesOptions } from './useChangeLogDatesOptions';
import s from './SearchBlock.module.scss';

const optionToValue = (option) => option.value;

export const SearchBlock = ({ params, setFilter }) => {
  const { infralistId } = useParams();
  const history = useHistory();

  const {
    changeLogDateOptions,
    isLoadingChangeLogDateOptions
  } = useChangeLogDatesOptions(infralistId);

  // автовыбор первой даты при загрузке
  useEffect(() => {
    if (changeLogDateOptions.length > 0 && !params.filter.date) {
      setFilter({
        date: changeLogDateOptions[0].value
      });
    }
  }, [changeLogDateOptions, params.filter.date, setFilter]);

  const {
    directionsOptions,
    isLoadingDirections,
    sectionsOptions,
    isLoadingSections,
    equipmentOptions,
    isLoadingEquipment
  } = useSearchOptions(infralistId, params);

  const selectedDirections = useMemo(() => {
    return directionsOptions.filter((optionItem) =>
      params.filter.direction_ids.includes(optionItem.value)
    );
  }, [directionsOptions, params.filter.direction_ids]);

  const selectedSections = useMemo(() => {
    return sectionsOptions.filter((optionItem) =>
      params.filter.section_ids.includes(optionItem.value)
    );
  }, [params.filter.section_ids, sectionsOptions]);

  const selectedEquipments = useMemo(() => {
    return equipmentOptions.filter((optionItem) =>
      params.filter.fpo_equipment_ids.includes(optionItem.value)
    );
  }, [equipmentOptions, params.filter.fpo_equipment_ids]);

  const onDateChange = (nextDate) => {
    setFilter({
      date: nextDate,
      direction_ids: [],
      section_ids: [],
      fpo_equipment_ids: []
    });
  };

  const onDirectionsChange = (directions) => {
    setFilter({
      direction_ids: directions?.map(optionToValue) ?? [],
      section_ids: [],
      fpo_equipment_ids: []
    });
  };

  const onSectionsChange = (sections) => {
    setFilter({
      section_ids: sections?.map(optionToValue) ?? [],
      fpo_equipment_ids: []
    });
  };

  const onEquipmentsChange = (equipments) => {
    setFilter({
      fpo_equipment_ids: equipments?.map(optionToValue) ?? []
    });
  };

  const back = () =>
    history.push(
      generatePath(infralistRoutes.INFRALIST, {
        infralistId
      })
    );

  return (
    <Paper variant="outlined" square className={s.root}>
      <div className={s.backContainer}>
        <IconButton size="small" onClick={back}>
          <ArrowBackIcon />
        </IconButton>
        <p className={s.backTitle}>Назад к режиму просмотра</p>
      </div>
      <p className={s.searchTitle}>Журнал изменений</p>

      <Autocomplete
        name="date"
        value={params.filter.date}
        onChange={onDateChange}
        options={changeLogDateOptions}
        placeholder="Дата"
        movePlaceholder={true}
        disabled={isLoadingChangeLogDateOptions}
        className={s.select}
      />
      {params.filter.date && (
        <MultiSelectBlock
          value={selectedDirections}
          onChange={onDirectionsChange}
          options={directionsOptions}
          placeholder={isLoadingDirections ? 'Загрузка...' : 'Направление'}
          disabled={isLoadingDirections}
          className={s.select}
        />
      )}
      {selectedDirections.length > 0 && (
        <MultiSelectBlock
          value={selectedSections}
          onChange={onSectionsChange}
          options={sectionsOptions}
          placeholder={isLoadingSections ? 'Загрузка...' : 'Раздел'}
          disabled={isLoadingSections}
          className={s.select}
        />
      )}
      {selectedSections.length > 0 && (
        <MultiSelectBlock
          value={selectedEquipments}
          onChange={onEquipmentsChange}
          options={equipmentOptions}
          placeholder={
            isLoadingEquipment
              ? 'Загрузка...'
              : 'Наименование оборудования (ФПО)'
          }
          disabled={isLoadingEquipment}
          className={s.select}
        />
      )}
    </Paper>
  );
};
