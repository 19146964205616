import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SubTable from '../../../../../common/SubTable/SubTable';
import { DateCell } from '../../../../../common/TableCells';

export const UtilsInfo = React.memo(function UtilsInfo({ regionalEvent }) {
  return (
    <SubTable editVariant>
      <TableRow>
        <TableCell>Дата заполнения</TableCell>
        <DateCell value={regionalEvent.created_at} />
      </TableRow>
      <TableRow>
        <TableCell>Дата обновления</TableCell>
        <DateCell value={regionalEvent.updated_at} />
      </TableRow>
      <TableRow>
        <TableCell>Версия</TableCell>
        <TableCell>{regionalEvent.version || ''}</TableCell>
      </TableRow>
    </SubTable>
  );
});
