import React from 'react';
import { useField } from 'formik';
import { NewTextField } from './NewTextField';

export const NewTextInput = function NewTextInput(props) {
  const [field, meta, helpers] = useField(props);

  return (
    <NewTextField
      {...field}
      value={field.value || ''}
      errorMessage={meta.touched && meta.error}
      {...props}
    />
  );
};
