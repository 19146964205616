import React, { useState } from 'react';
import classnames from 'classnames';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TableRow from '@material-ui/core/TableRow';
import FormHelperText from '@material-ui/core/FormHelperText';
import { TableCell } from '@material-ui/core';
import SubTable from '../SubTable/SubTable';
import s from './Spoiler.module.scss';

export const Spoiler = ({
  className,
  title,
  head,
  children,
  errorMessage = null
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={classnames(s.Spoiler, className)}>
      <div className={s.Spoiler__head} onClick={() => setOpen(!open)}>
        <SubTable
          className={classnames(s.Spoiler__headTable, {
            [s.Spoiler__headTable_error]: !!errorMessage
          })}
          fixedLayout={false}
        >
          <TableRow>
            <TableCell>
              <div className={s.Spoiler__headIcon}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </div>
            </TableCell>
            <TableCell className={s.Spoiler__headTable_content}>
              <SubTable
                className={s.Spoiler__headContent}
                title={title}
                cols={1}
              >
                {head}
              </SubTable>
            </TableCell>
          </TableRow>
          {errorMessage && (
            <TableRow>
              <TableCell colSpan={2}>
                <FormHelperText
                  className={s.Spoiler__error}
                  error={!!errorMessage}
                >
                  {errorMessage}
                </FormHelperText>
              </TableCell>
            </TableRow>
          )}
        </SubTable>
      </div>

      {open && <div className={s.Spoiler__content}>{children}</div>}
    </div>
  );
};
