import { EnumHelpers } from '../../utils/EnumHelpers';

export class Infralist {
  static Status = {
    AGREED: 'AGREED',
    NOT_AGREED: 'NOT_AGREED',
    NOT_FORMED: 'NOT_FORMED',
    NOT_SIGNED: 'NOT_SIGNED',
    ON_APPROVAL: 'ON_APPROVAL',
    ON_CHECK: 'ON_CHECK',
    ON_SIGNING: 'ON_SIGNING',
    AGREED_WITH_REMARKS: 'AGREED_WITH_REMARKS'
  };

  static status = new EnumHelpers(this.Status, {
    AGREED: 'Согласовано',
    NOT_AGREED: 'Не согласовано',
    NOT_FORMED: 'Не сформировано',
    NOT_SIGNED: 'Не подписано',
    ON_APPROVAL: 'На согласовании',
    ON_CHECK: 'На проверке',
    ON_SIGNING: 'На подписании',
    AGREED_WITH_REMARKS: 'Согласовано с замечаниями'
  });

  static Type = {
    INFRALIST: 'INFRALIST',
    INFRALIST_SS: 'INFRALIST_SS'
  };
}
