import React, { useCallback, useEffect, useMemo } from 'react';
import { getCurrentYear, yearsSuggestions } from '../../utils/data/date';
import { useRetriableRegionalProjects } from '../../store/regionalProject/useRegionalProjects';
import { useMemoSuggestions } from '../../utils/suggestions';
import AutocompleteBaseInput from '../../common/Inputs/Selects/AutocompleteBaseInput';
import { User } from '../../store/role/User';
import { useRegions } from '../../store/subsidiaryEntity/useSubsidiaryEntity';
import { queryLoader } from '../../common/QueryLoader';
import { ContestDocsMonitoringEntity } from '../../store/contestDocsMonitoring/ContestDocsMonitoringEntity';
import { useUserRole } from '../../store/role/useUserRole';

export const useContestDocsMonitoringFilter = (query, changeQuery, user) => {
  const { isRegionAdmin } = useUserRole(user);

  const regionsQuery = useRegions();
  const regions = regionsQuery.state.data;
  const availableRegions = regions && User.getRegions(user, regions);
  const regionsSuggestions = useMemoSuggestions(availableRegions);

  // const userRegionId =
  //   availableRegions?.length === 1 ? availableRegions[0].id : user?.region?.id;
  const regionId = isRegionAdmin ? user?.region?.id : query.region_id;
  const year = query.year || getCurrentYear().toString();

  const filter = {
    region_id: regionId || undefined,
    regional_project_id: query.regional_project_id || undefined,
    status: query.status,
    year: +year
  };

  const setFilterValue = useCallback(
    (value, name) => {
      const queryObj = {
        [name]: value ?? undefined,
        page: 0
      };
      return changeQuery(queryObj);
    },
    [changeQuery]
  );

  useEffect(() => {
    setFilterValue(year, 'year');
  }, [year, setFilterValue]);

  useEffect(() => {
    if (isRegionAdmin && regionId) {
      setFilterValue(regionId, 'region_id');
    }
  }, [isRegionAdmin, regionId, setFilterValue]);

  const regionalProjectsFilter = useMemo(() => {
    return {
      implementation_years: [filter.year],
      region_ids: filter.region_id ? [filter.region_id] : undefined
    };
  }, [filter.region_id, filter.year]);
  const regionalProjectsQuery = useRetriableRegionalProjects(
    regionalProjectsFilter
  );
  const regionalProjects = regionalProjectsQuery.state.data?.data;
  const regionalProjectsSuggestions = useMemoSuggestions(regionalProjects);

  const filterComponent = queryLoader(regionsQuery) || (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Год"
        name="year"
        suggestions={yearsSuggestions}
        value={year}
        variant="standard"
        isClearable={false}
      />
      {regionsSuggestions?.length > 1 && (
        <AutocompleteBaseInput
          onChange={setFilterValue}
          hasCopyBtn={false}
          label="Регион"
          name="region_id"
          suggestions={regionsSuggestions}
          suggestionsLoading={regionsQuery.check.spinner}
          value={filter.region_id}
          variant="standard"
          disabled={isRegionAdmin}
        />
      )}
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Региональный проект"
        name="regional_project_id"
        suggestions={regionalProjectsSuggestions}
        suggestionsLoading={regionalProjectsQuery.check.spinner}
        value={filter.regional_project_id}
        resetIfNotExists={true}
        variant="standard"
      />
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Статус"
        name="status"
        suggestions={ContestDocsMonitoringEntity.statusSuggestions}
        value={filter.status}
        variant="standard"
      />
    </div>
  );
  return [filter, filterComponent];
};
