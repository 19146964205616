import { Close } from '@material-ui/icons';
import React from 'react';
import { Dialog } from '@material-ui/core';
import s from './Popup.module.scss';

export const Popup = ({ open, onClose, title, children, description }) => (
  <Dialog
    open={open}
    onClose={onClose}
    classes={{
      paper: s.Popup
    }}
  >
    <div className={s.Header}>
      <Close onClick={onClose} fontSize={'small'} className={s.Close} />
    </div>
    <div className={s.Content}>
      <p className={s.Title}>{title}</p>
      {description && <p className={s.Description}>{description}</p>}
      {children}
    </div>
  </Dialog>
);
