import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { components as defaultComponents } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/FileCopy';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Loader from '../../Loader/Loader';
import s from '../Inputs.module.scss';

export const noOptionsMessage = () => 'Ничего не найдено';
export const loadingMessage = () => 'Загрузка...';

const InputComponent = ({ inputRef, isLoading, ...props }) => {
  return isLoading ? (
    <Loader centered={true} size={20} />
  ) : (
    <div ref={inputRef} {...props} />
  );
};

InputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired
    })
  ])
};

const IndicatorsContainer = ({ children, size = 20, ...props }) => {
  return (
    <div>
      <defaultComponents.IndicatorsContainer {...props}>
        {props.selectProps.hasCopyBtn && props.selectProps.value && (
          <defaultComponents.DropdownIndicator
            {...props}
            innerProps={{
              onMouseDown: (e) => e.stopPropagation()
            }}
          >
            <CopyToClipboard text={props.selectProps.value.label}>
              <Tooltip title="Скопировать текст">
                <EditIcon style={{ width: size, height: size }} />
              </Tooltip>
            </CopyToClipboard>
          </defaultComponents.DropdownIndicator>
        )}
        {children}
      </defaultComponents.IndicatorsContainer>
    </div>
  );
};

const Control = (props) => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { TextFieldProps, isInitialLoading, isSuggestionLoading }
  } = props;

  return (
    <TextField
      variant="outlined"
      multiline
      onMouseDown={innerProps.onMouseDown}
      InputProps={{
        inputComponent: InputComponent,
        inputProps: {
          className: s.Input,
          ref: innerRef,
          children,
          isLoading: isInitialLoading || isSuggestionLoading,
          ...innerProps
        }
      }}
      {...TextFieldProps}
    />
  );
};

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired
    })
  ]).isRequired,
  selectProps: PropTypes.object.isRequired
};

const NoOptionsMessage = (props) => {
  return (
    <Typography
      component="div"
      color="textSecondary"
      className={s.NoOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired
};

const LoadingMessage = (props) => {
  return (
    <Typography
      component="div"
      color="textSecondary"
      className={s.NoOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

LoadingMessage.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired
};

const optionHeight = 48;

const MenuList = React.memo((props) => {
  const { options, children, maxHeight = 0, getValue, selectProps } = props;
  const [value] = getValue();

  const listRef = useRef();
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(options.indexOf(value));
    }
  }, [value, options]);

  return children.length ? (
    <List
      ref={listRef}
      style={{ maxHeight: '300px', height: 'auto' }}
      height={maxHeight}
      itemCount={children.length}
      itemSize={optionHeight}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  ) : (
    <NoOptionsMessage selectProps={selectProps}>{children}</NoOptionsMessage>
  );
});

const Option = ({
  isFocused,
  isSelected,
  innerRef,
  value,
  children,
  innerProps: { onMouseMove, onMouseOver, ...newInnerProps }
}) => {
  return (
    <MenuItem
      selected={isFocused}
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
      ref={innerRef}
      value={value}
      title={children}
      {...newInnerProps}
    >
      <p className={s.Option}>{children}</p>
    </MenuItem>
  );
};

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    key: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired
    })
  ]),
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired
};

function Menu(props) {
  return (
    <Paper square className={s.Paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.element.isRequired,
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired
};

const ValueContainer = (props) => {
  return <div className={s.AutocompleteValueContainer}>{props.children}</div>;
};

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired
};

export const commonSelectComponents = {
  Control,
  Menu,
  MenuList,
  Option,
  LoadingMessage,
  NoOptionsMessage,
  IndicatorsContainer,
  IndicatorSeparator: null
};
