import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import React from 'react';
import s from './SubTable.module.scss';

export function SubTableHeading({ cols = 2, children }) {
  return (
    <TableRow>
      <TableCell colSpan={cols} classes={{ root: s.SubTableHeader }}>
        {children}
      </TableCell>
    </TableRow>
  );
}
