import React from 'react';
import { Field } from 'formik';
import { TextField } from '../../FormFields/TextField/TextField';
import { removeXMLInvalidChars } from '../../../../utils/string';

export const FormikTextField = ({
  name,
  placeholder,
  multiline,
  disabled,
  isValueTrimmed,
  maxLength,
  className
}) => {
  return (
    <Field name={name}>
      {({ field, meta, form }) => (
        <TextField
          name={name}
          placeholder={placeholder}
          multiline={multiline}
          disabled={disabled}
          error={meta.touched && meta.error ? meta.error : ''}
          maxLength={maxLength}
          className={className}
          {...field}
          onChange={(e) =>
            isValueTrimmed
              ? form.setFieldValue(
                  name,
                  removeXMLInvalidChars(
                    e.target.value.replaceAll(' ', '').replaceAll('%20', '')
                  )
                )
              : form.setFieldValue(name, removeXMLInvalidChars(e.target.value))
          }
        />
      )}
    </Field>
  );
};
