import gql from 'graphql-tag';

export const QUERY_INFRALIST_RVPO_EQUIPMENT_STATUSES = gql`
  query infralistRvpoEquipmentStatuses {
    infralistRvpoEquipmentStatuses {
      id
      name
    }
  }
`;

export const QUERY_INFRALIST_RVPO_EQUIPMENT_CHECK_STATUSES = gql`
  query infralistRvpoEquipmentCheckStatuses {
    infralistRvpoEquipmentCheckStatuses {
      id
      name
    }
  }
`;
