import React from 'react';
import diff from 'fast-diff';
import { zipArrays } from '../../../../utils/array';
import s from './DiffRow.module.scss';

const DiffCellOld = ({ diffResult }) => {
  return (
    <p>
      {diffResult.map(([type, str], i) => {
        if (type === diff.DELETE) {
          return (
            <span className={s.diffOld} key={i}>
              {str}
            </span>
          );
        }
        if (type === diff.EQUAL) {
          return <span key={i}>{str}</span>;
        }
        return null;
      })}
    </p>
  );
};

const DiffCellNew = ({ diffResult }) => {
  return (
    <p>
      {diffResult.map(([type, str], i) => {
        if (type === diff.INSERT) {
          return (
            <span className={s.diffNew} key={i}>
              {str}
            </span>
          );
        }
        if (type === diff.EQUAL) {
          return <span key={i}>{str}</span>;
        }
        return null;
      })}
    </p>
  );
};

const getDiffs = (version, actual) => {
  const diffResult = diff(String(version ?? ''), String(actual ?? ''));
  return {
    version: version ? (
      <DiffCellOld diffResult={diffResult} />
    ) : (
      <p className={s.noValue}>Нет предыдущего значения</p>
    ),
    actual: <DiffCellNew diffResult={diffResult} />
  };
};

export const DiffRow = ({
  title,
  subTitleVersion,
  subTitleActual,
  version,
  actual,
  render
}) => {
  const diffs = getDiffs(version, actual);
  const subTitleDiffs = getDiffs(subTitleVersion, subTitleActual);
  return (
    <>
      <div className={s.gridCell}>
        <p className={s.rowTitle}>{title}</p>

        {(subTitleVersion || subTitleActual) && (
          <div className={s.rowSubTitle}>{subTitleDiffs.version}</div>
        )}

        {typeof render === 'function' && version
          ? render(version, diffs.version)
          : diffs.version}
      </div>

      <div className={s.gridCell}>
        <p className={s.rowTitle}>{title}</p>

        {(subTitleVersion || subTitleActual) && (
          <div className={s.rowSubTitle}>{subTitleDiffs.actual}</div>
        )}

        {typeof render === 'function'
          ? render(actual, diffs.actual)
          : diffs.actual}
      </div>
    </>
  );
};

export const DiffRowArray = ({ title, version, actual, render }) => {
  const diffs = zipArrays(
    version ?? [],
    actual ?? [],
    ''
  ).map(([versionItem, actualItem]) => getDiffs(versionItem, actualItem));

  return (
    <>
      <div className={s.gridCell}>
        <p className={s.rowTitle}>{title}</p>

        {typeof render === 'function' && version
          ? diffs.map(({ version: versionItem }, i) =>
              render(version[i], versionItem)
            )
          : diffs.map(({ version }) => version)}
      </div>

      <div className={s.gridCell}>
        <p className={s.rowTitle}>{title}</p>

        {typeof render === 'function'
          ? diffs.map(({ actual: actualItem }, i) =>
              render(actual[i], actualItem)
            )
          : diffs.map(({ actual }) => actual)}
      </div>
    </>
  );
};
