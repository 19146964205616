import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { Button } from '@material-ui/core';
import s from './ErrorPage.module.scss';

export default function ErrorPage({ text, description, children }) {
  const history = useHistory();

  return (
    <div className={classNames(s.ErrorPage)}>
      <div className={s.ErrorPage__wrapper}>
        {text && <div className={s.ErrorPage__text}>{text}</div>}
        {description && <div>{description}</div>}
        {children && <div>{children}</div>}
        <Button
          className={s.ErrorPage__backLink}
          variant="contained"
          color="primary"
          onClick={() => history.goBack('/')}
        >
          Вернуться назад
        </Button>
      </div>
    </div>
  );
}
