import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_RESULT_FEDERALS } from '../../graphql/queries/results';

const resultFederalsQueryOptions = {
  query: QUERY_GET_RESULT_FEDERALS,
  mapData: (result) => result.resultFederals.list,
  skipQuery: skipIfNull(null)
};

export function useResultFederals(filter) {
  return useRetriableGraphqlVars({
    queryOptions: resultFederalsQueryOptions,
    variableCreator: [
      () =>
        filter
          ? {
              input: {
                filter
              }
            }
          : null,
      [filter]
    ]
  });
}
