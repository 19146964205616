import gql from 'graphql-tag';

export const QUERY_GET_FEDERAL_PROJECTS = gql`
  query federalProjects {
    federalProjects {
      id
      code
      name
      name_full
      project_code
    }
  }
`;

export const QUERY_GET_FEDERAL_PROJECTS_AVAILABLE_FOR_CONTEST_DOCS_MONITORING = gql`
  query federalProjectsAvailableForTenderDocumentationForSS($year: Int!) {
    federalProjectsAvailableForTenderDocumentationForSS(year: $year) {
      id
      code
      name
      name_full
      project_code
    }
  }
`;
