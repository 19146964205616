import gql from 'graphql-tag';
import { FRAGMENT_SUBSIDIARY_ENTITY } from '../queries/subsidiaryEntity';

export const MUTATION_UPDATE_SUBSIDIARY_ENTITY = gql`
  mutation updateSubsidiaryEntity($input: SubsidiaryEntityInput!) {
    result: updateSubsidiaryEntity(input: $input) {
      ...subsidiaryEntity
    }
  }
  ${FRAGMENT_SUBSIDIARY_ENTITY}
`;

export const MUTATION_CREATE_SUBSIDIARY_ENTITY = gql`
  mutation createSubsidiaryEntity($input: SubsidiaryEntityInput!) {
    result: createSubsidiaryEntity(input: $input) {
      ...subsidiaryEntity
    }
  }
  ${FRAGMENT_SUBSIDIARY_ENTITY}
`;

export const MUTATION_DELETE_SUBSIDIARY_ENTITY = gql`
  mutation deleteSubsidiaryEntity($id: String!) {
    deleteSubsidiaryEntity(id: $id)
  }
`;

export const MUTATION_BIND_SUBSIDIARY_ENTITY = gql`
  mutation bindSubsidiaryTowardInfralist(
    $subsidiary_id: ID!
    $infralist_id: ID!
  ) {
    bindSubsidiaryTowardInfralist(
      subsidiary_id: $subsidiary_id
      infralist_id: $infralist_id
    ) {
      id
    }
  }
`;

export const MUTATION_APPROVE_SUBSIDIARY_ENTITY = gql`
  mutation approveSubsidiaryEntity($id: String!) {
    approveSubsidiaryEntity(subsidiary_entity_id: $id)
  }
`;

export const MUTATION_REVOKE_SUBSIDIARY_ENTITY_APPROVAL = gql`
  mutation revokeSubsidiaryEntityApproval($id: String!) {
    revokeSubsidiaryEntityApproval(subsidiary_entity_id: $id)
  }
`;
