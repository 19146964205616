import React, { useCallback } from 'react';
import { format } from 'date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import AutocompleteBaseInput from '../../common/Inputs/Selects/AutocompleteBaseInput';
import { SearchForm } from '../../common/SearchForm/SearchForm';
import nomRuLocale from '../../utils/fnsNomRuLocale';
import s from './Notifications.module.scss';

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 160,
    marginRight: 20
  }
}));
const filterSuggestions = [
  { value: 'read', label: 'Прочитанные' },
  { value: 'not_read', label: 'Непрочитанные' }
];

const convertValue = (yearMonth) => {
  if (!yearMonth) return null;
  return new Date(yearMonth);
};

export function useNotificationFilter(query, changeQuery) {
  const classes = useStyles();
  const filter = {
    yearMonth: query.yearMonth || undefined,
    read: query.read,
    search: query.search || ''
  };

  const setFilterValue = useCallback(
    (value, name) =>
      changeQuery({
        [name]: value,
        page: 0
      }),
    [changeQuery]
  );

  const filterComponent = (
    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nomRuLocale}>
        <DatePicker
          clearable
          inputVariant="standard"
          onChange={(event) => {
            if (event) {
              const date = format(event, 'yyyy-MM');
              changeQuery({
                yearMonth: date
              });
            } else {
              changeQuery({
                yearMonth: ''
              });
            }
          }}
          TextFieldComponent={(props) => {
            return (
              <TextField
                label="Год/месяц уведомления"
                className={classNames(classes.input)}
                {...props}
              />
            );
          }}
          value={convertValue(filter.yearMonth)}
          views={['year', 'month']}
          className={s.datePicker}
        />
      </MuiPickersUtilsProvider>
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Фильтр"
        name="read"
        suggestions={filterSuggestions}
        value={filter.read}
        variant="standard"
      />
      <SearchForm
        label="Поиск уведомлений"
        style={{ margin: '0 28px 1.5rem 0' }}
        changeQuery={changeQuery}
        initialInput={filter.search}
      />
    </div>
  );

  return [filterComponent, filter];
}
