import { uniqueId } from 'lodash-es';
import Button from '@material-ui/core/Button';
import React, { useCallback, useRef, useState } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

const ButtonMemo = React.memo(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  input: {
    display: 'none'
  }
}));

export function FileInputButton({
  onSelectFile,
  children = 'Загрузить',
  buttonProps,
  inputProps
}) {
  const classes = useStyles();
  const inputId = uniqueId('input');

  return (
    <div className={classes.root}>
      <input
        id={inputId}
        accept={'*'}
        className={classes.input}
        type="file"
        onChange={onSelectFile}
        {...inputProps}
      />
      <label htmlFor={inputId}>
        <ButtonMemo
          variant="contained"
          color="primary"
          component="span"
          {...buttonProps}
        >
          {children}
        </ButtonMemo>
      </label>
    </div>
  );
}

export function FileActionButton({
  onSelectFile,
  children,
  buttonProps,
  inputProps,
  ...props
}) {
  const [isLoading, setLoading] = useState(false);
  const inputRef = useRef();

  const handleSelectFile = useCallback(
    (e) => {
      const files = e.target.files;
      Promise.resolve()
        .then(() => setLoading(true))
        .then(() => onSelectFile(files))
        .catch(() => {})
        .then(() => {
          setLoading(false);
          if (inputRef.current) {
            inputRef.current.value = '';
          }
        });
    },
    [onSelectFile]
  );

  return (
    <FileInputButton
      onSelectFile={handleSelectFile}
      buttonProps={{ ...buttonProps, disabled: isLoading }}
      inputProps={{ ...inputProps, ref: inputRef }}
      {...props}
    >
      {isLoading ? <CircularProgress size={24} color="secondary" /> : children}
    </FileInputButton>
  );
}
