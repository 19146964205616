import gql from 'graphql-tag';
import { FRAGMENT_USER } from '../queries/users';

export const MUTATION_UPDATE_USER = gql`
  mutation updateUser($input: UserUpdateInput!) {
    result: updateUser(input: $input) {
      ...user
    }
  }
  ${FRAGMENT_USER}
`;

export const MUTATION_CREATE_USER = gql`
  mutation createUser($input: UserInput!) {
    result: createUser(input: $input) {
      ...user
    }
  }
  ${FRAGMENT_USER}
`;

export const MUTATION_DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    result: deleteUser(id: $id)
  }
`;
