import React, { useMemo } from 'react';
import Table from '../../common/Table/Table';
import PageTitle from '../../common/PageTitle/PageTitle';
import { useRegionalProjects } from '../../store/regionalProject/useRegionalProjects';
import {
  QueryPagination,
  useQueryPagination,
  useQueryPaginationCount
} from '../../common/usePagination';
import { useUrlQuery } from '../../common/useUrlQuery';
import { queryLoaderWithExistenceCheck } from '../../common/QueryLoader';
import { useCurrentUser } from '../../store/useCurrentUser';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';
import { useProjectsPageFilter } from './useProjectsPageFilter';

const projectsColumns = [
  { label: '№', key: 'order', isThin: true },
  { label: 'Наименование', key: 'name', isWide: true, isBold: true },
  { label: 'Количество закупок', key: 'count_purchases', isThin: true }
];

const projectsMapper = ({ page, onePage }, year) => (
  { __typename, ...rest },
  index
) => ({
  order: page * onePage + index + 1,
  queries: { year },
  ...rest
});

const title = 'Региональные проекты';

export default function ProjectsPage({ history, location }) {
  const user = useCurrentUser();
  const regionId = user?.region?.id;

  const [query, changeQuery] = useUrlQuery(location, history);

  const pagination = useQueryPagination(query, changeQuery, 100);

  const [filterComponent, filter] = useProjectsPageFilter(
    query,
    changeQuery,
    regionId
  );

  const projectsQuery = useRegionalProjects(filter, pagination.params);

  useQueryPaginationCount(pagination, projectsQuery);

  const projects = useMemo(() => {
    if (projectsQuery.state.data) {
      const { data, header } = projectsQuery.state.data;
      return data.map(projectsMapper(header, query.year));
    }
    return [];
  }, [projectsQuery.state.data, query.year]);

  return (
    <>
      <HeadTitle title={title} />
      <PageTitle withBackBtn={false} title={title} />
      {queryLoaderWithExistenceCheck(projectsQuery) || (
        <>
          <Table
            columns={projectsColumns}
            data={projects}
            rowRedirectRoute="/projects/"
            className="projects-page"
            filter={filterComponent}
            pagination={<QueryPagination pagination={pagination} />}
          />
        </>
      )}
    </>
  );
}
