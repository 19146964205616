import React, { useCallback, useMemo } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useContextStore } from '@proscom/prostore-react';
import { useRegionalProject } from '../../../../../store/regionalProject/useRegionalProject';
import { useExpensesDirection } from '../../../../../store/expensesDirection/useExpensesDirection';
import PageTitle from '../../../../../common/PageTitle/PageTitle';
import { usePurchases } from '../../../../../store/purchase/usePurchases';
import Menu from '../../../../../common/Menu/Menu';
import { useDeleteConfirmation } from '../../../../../common/Form/useDeleteConfirmation';
import Table from '../../../../../common/Table/Table';
import { STORE_PURCHASE } from '../../../../../store/names';
import {
  QueryPagination,
  useQueryPagination,
  useQueryPaginationCount
} from '../../../../../common/usePagination';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../../graphql/graphqlErrors';
import NotFoundPage from '../../../../notFound/NotFoundPage';
import { ActionButton } from '../../../../../common/Form/ActionButton';
import { useLatestPurchaseConfirmation } from '../../../../../store/purchase/useLatestPurchaseConfirmation';
import { reformatDate } from '../../../../../utils/date';
import { Purchase } from '../../../../../store/purchase/Purchase';
import { useCurrentUser } from '../../../../../store/useCurrentUser';
import { useUrlQuery } from '../../../../../common/useUrlQuery';
import { queryLoaderWithExistenceCheck } from '../../../../../common/QueryLoader';
import { HeadTitle } from '../../../../../common/HeadTitle/HeadTitle';
import { getName } from '../../../../../utils/data/label';
import { useSorting } from '../../../../../common/Table/Sorting/useSorting';
import { Sorting } from '../../../../../common/Table/Sorting/Sorting';
import { ExpenseGroupBreadCrumbs } from './ExpenseGroupBreadCrumbs';
import { useExpenseGroupFilter } from './useExpenseGroupFilter';
import s from './ExpenseGroupPage.module.scss';

const getPurchasesColumn = (sortableColumns) => [
  { label: '№', key: 'order', isThin: true },
  { label: 'Код', key: 'number', isThin: true },
  { label: 'Объект закупки', key: 'item', isWide: true, isBold: true },
  {
    label: 'Заказчик',
    key: (row) => Purchase.getCustomerNameDisplay(row),
    isWide: true,
    isBreak: true
  },
  { label: 'ГРБС', key: 'grbs_name', isWide: true, isBreak: true },
  {
    label: 'Статус',
    key: (row) => Purchase.status.getName(row.status),
    isWide: true,
    isBreak: true
  },
  // {
  //   label: 'Плановая дата',
  //   isThin: true,
  //   key: (row) => {
  //     if (!(row.status === Purchase.Status.CONTRACTED)) {
  //       return Purchase.getNearestFridayDate();
  //     }
  //     return '';
  //   }
  // },
  ...sortableColumns
];

const ExpenseGroupPage = ({ match, location, history }) => {
  const { projectId, expenseGroupId: id } = match.params;
  const purchaseStore = useContextStore(STORE_PURCHASE);
  const [query, changeQuery] = useUrlQuery(location, history);

  const projectQuery = useRegionalProject(projectId);
  const expensesDirectionQuery = useExpensesDirection(id);

  const project = projectQuery.state.data;
  const expensesDirection = expensesDirectionQuery.state.data;
  const implementationYears = expensesDirection?.implementation_years;

  const [sortableColumns, sorting] = useSorting([
    {
      field: Sorting.SortFields.status_changed_at,
      key: (row) => reformatDate(row.status_changed_at)
    }
  ]);

  const [filterComponent, filter] = useExpenseGroupFilter(
    query,
    changeQuery,
    projectId,
    id,
    implementationYears
  );

  const pagination = useQueryPagination(query, changeQuery, 20);

  const purchasesQuery = usePurchases(filter, pagination.params, sorting);

  useQueryPaginationCount(pagination, purchasesQuery);

  const deletePurchase = useCallback(
    (id) => {
      purchaseStore.deletePurchase(id).catch((error) => {
        showGraphqlErrorToast(error, mutationErrors.deleteObject);
      });
    },
    [purchaseStore]
  );

  const [dialog, openDialog] = useDeleteConfirmation(deletePurchase, {
    entityName: 'закупку'
  });

  const purchases = purchasesQuery.state.data?.data;

  const latestPurchaseConfirmationQuery = useLatestPurchaseConfirmation({
    regional_project_id: projectId,
    expenses_direction_id: id
  });
  const latestPurchaseConfirmation = latestPurchaseConfirmationQuery.state.data;

  const user = useCurrentUser();
  const canConfirmForDate = Purchase.canConfirmForDate(user);
  const canCrud = Purchase.canCrud(user);

  const columns = useMemo(() => getPurchasesColumn(sortableColumns), [
    sortableColumns
  ]);

  if (expensesDirectionQuery.check.component && !expensesDirection) {
    return <NotFoundPage />;
  }

  return (
    <>
      <HeadTitle
        type="Направление расхода"
        entity={getName(expensesDirection)}
      />
      <ExpenseGroupBreadCrumbs project={project} projectId={projectId} />
      <PageTitle
        title={
          expensesDirection
            ? `${expensesDirection.code}. ${expensesDirection.name_full}`
            : '...'
        }
        withBackBtn
      />
      {queryLoaderWithExistenceCheck(purchasesQuery) || (
        <>
          <div className={s.buttonBox}>
            {canConfirmForDate && (
              <ActionButton
                onClick={() => {
                  return purchaseStore.createPurchaseConfirmation({
                    regional_project_id: projectId,
                    expenses_direction_id: id
                  });
                }}
                title="Подтвердить состояние на сегодня"
              />
            )}
            <p className={s.confirmationDate}>
              Дата последнего подтверждения:
              <br />
              {(latestPurchaseConfirmation &&
                reformatDate(latestPurchaseConfirmation.confirmation_date)) ||
                'Не подтверждено'}
            </p>
          </div>
          <Table
            addButtonText="Добавить закупку"
            addFieldRoute={`${match.url}/purchase/create`}
            columns={columns}
            data={purchases}
            hasAddField={canCrud}
            renderNoData={() => (
              <h3>Нет закупок по выбранному направлению расходов</h3>
            )}
            rowRedirectRoute={`${match.url}/purchases/`}
            filter={filterComponent}
            renderMenu={
              canCrud
                ? (row) => (
                    <Menu
                      iconColor="default"
                      icon={MoreHorizIcon}
                      options={[
                        {
                          title: 'Редактировать',
                          onClick: () =>
                            history.push(
                              `${match.url}/purchases/${row.id}/edit`
                            )
                        },
                        {
                          title: 'Удалить',
                          onClick: () => openDialog(row.id)
                        }
                      ]}
                    />
                  )
                : undefined
            }
            pagination={<QueryPagination pagination={pagination} />}
          />
          {dialog}
        </>
      )}
    </>
  );
};

export default ExpenseGroupPage;
