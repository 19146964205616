import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import React from 'react';

export function wrapTableCell(children) {
  if (!children) return children;
  return (
    <TableRow>
      <TableCell>{children}</TableCell>
    </TableRow>
  );
}
