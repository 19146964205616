import url from 'url';
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import MaterialTable from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import { get } from 'lodash-es';
import Loader from '../Loader/Loader';
import { queryLoader } from '../QueryLoader';
import { createPreservedLinkBase } from '../../utils/links';
import s from './Table.module.scss';

const TableTopActions = ({
  actions,
  filter = null,
  redirect,
  buttonText,
  disabled
}) => {
  return (
    <>
      <div className={s.AddFieldContainer}>
        {filter}
        {redirect && (
          <Button
            className={s.Button}
            component={Link}
            to={redirect}
            color="primary"
            variant="contained"
            disabled={disabled}
          >
            <AddIcon className={s.ButtonIcon} />
            {buttonText}
          </Button>
        )}
      </div>
      <div className={s.actions}>{actions}</div>
    </>
  );
};

const Table = ({
  addButtonText,
  addFieldRoute,
  addButtonDisabled,
  className,
  columns,
  data,
  filter,
  hasAddField,
  isLoading,
  query,
  pagination,
  renderNoData,
  renderMenu,
  rowRedirectRoute,
  menuComponent,
  actions
}) => {
  const MenuComponent = menuComponent;
  return (
    <>
      {(hasAddField || filter) && (
        <TableTopActions
          actions={actions}
          filter={filter}
          redirect={hasAddField && addFieldRoute}
          buttonText={addButtonText}
          disabled={addButtonDisabled}
        />
      )}
      {(isLoading && <Loader />) ||
        (query && queryLoader(query)) ||
        false ||
        (data?.length >= 0 ? (
          <>
            <MaterialTable
              classes={{
                root: classnames(s.Table, {
                  [s.ProjectsPage]: className === 'projects-page',
                  [s.ProjectPage]: className === 'project-page'
                })
              }}
              component="div"
            >
              <TableHead component="div">
                <TableRow component="div">
                  {columns.map(({ label, isThin }, index) => (
                    <TableCell
                      component="div"
                      key={index}
                      classes={{
                        root: classnames(s.TableHeaderCell, {
                          [s.TableHeaderCell_thin]: isThin
                        })
                      }}
                    >
                      {label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody component="div">
                {data.map((row, iRow) => {
                  const baseLink = createPreservedLinkBase(
                    rowRedirectRoute,
                    row.queries
                  );
                  baseLink.pathname += row.id;
                  const link = url.format(baseLink);

                  return (
                    <TableRow
                      hover
                      component={Link}
                      to={link}
                      key={row.id}
                      classes={{ root: s.TableBodyRow }}
                    >
                      {columns.map((columnDef, index) => {
                        const {
                          key,
                          type,
                          isThin,
                          isBold,
                          isBreak,
                          isWide,
                          renderCell
                        } = columnDef;

                        const value =
                          typeof key === 'function' ? key(row) : get(row, key);
                        const content =
                          typeof renderCell === 'function'
                            ? renderCell(value, row, columnDef, index)
                            : value;

                        return (
                          <TableCell
                            key={`${row.id}-${index}`}
                            classes={{
                              root: classnames(s.TableBodyCell, {
                                [s.TableBodyCell_thin]: isThin,
                                [s.TableBodyCell_bold]: isBold,
                                [s.TableBodyCell_break]: isBreak,
                                [s.TableBodyCell__wide]: isWide
                              })
                            }}
                          >
                            {content}
                          </TableCell>
                        );
                      })}
                      {(renderMenu || menuComponent) && (
                        <TableCell
                          onClick={(e) => e.preventDefault()}
                          component="div"
                          align="center"
                          classes={{
                            root: classnames(
                              s.TableBodyCell,
                              s.TableBodyActionsCell
                            )
                          }}
                        >
                          {renderMenu ? (
                            renderMenu(row, iRow)
                          ) : (
                            <MenuComponent row={row} iRow={iRow} />
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </MaterialTable>
          </>
        ) : renderNoData ? (
          renderNoData()
        ) : (
          <h3>Ничего не найдено</h3>
        ))}
      {pagination}
    </>
  );
};

Table.defaultProps = {
  addButtonText: '',
  columns: [],
  data: [],
  handleRowClick: () => {},
  hasAddField: false
};

Table.propTypes = {
  addFieldRoute: PropTypes.string,
  addButtonText: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  handleRowClick: PropTypes.func,
  hasAddField: PropTypes.bool,
  renderNoData: PropTypes.func,
  rowRedirectRoute: PropTypes.string
};

export default Table;
