import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { useSubsidiaryEntityRoadmapIndicator } from '../../../../../../store/subsidiaryEntity/useSubsidiaryEntityRoadmapIndicator';
import { queryLoaderWithExistenceCheck } from '../../../../../../common/QueryLoader';
import { Indicator } from '../../../../../../store/indicator/Indicator';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../../../graphql/graphqlErrors';
import { removeLastPathItem } from '../../../../../../utils/url';
import PageTitle from '../../../../../../common/PageTitle/PageTitle';
import { useRegionalProject } from '../../../../../../store/regionalProject/useRegionalProject';
import { INDICATOR_STORE } from '../../../../../../store/names';
import { indicatorValidationSchema } from '../../../../../../utils/data/validation';
import { useCurrentUser } from '../../../../../../store/useCurrentUser';
import { IndicatorBreadCrumbs } from '../IndicatorBreadCrumbs';
import { FORM_STATUS_SUBMITTED } from '../../../../../../utils/data/form';
import { useOtherEditor } from '../../../../../../common/Form/useOtherEditor';
import { getName } from '../../../../../../utils/data/label';
import { HeadTitle } from '../../../../../../common/HeadTitle/HeadTitle';
import { IndicatorEditForm } from './IndicatorEditForm';

export default function IndicatorEditPage({ match, history }) {
  const indicatorStore = useContextStore(INDICATOR_STORE);
  const backLink = removeLastPathItem(match.url);

  const { projectId, roadmapId, indicatorId } = match.params;

  const editor = useOtherEditor('indicator', indicatorId);

  const indicatorQuery = useSubsidiaryEntityRoadmapIndicator(indicatorId, true);
  const indicator = indicatorQuery.state.data;

  const projectQuery = useRegionalProject(projectId);
  const project = projectQuery.state.data;

  const user = useCurrentUser();
  const validationSchema = useMemo(
    () => indicatorValidationSchema(indicator, user),
    [indicator, user]
  );

  return (
    <>
      <HeadTitle
        type="Индикатор"
        entity={getName(indicator)}
        pageType={HeadTitle.PageType.edit}
      />
      <IndicatorBreadCrumbs
        projectId={projectId}
        project={project}
        roadmapId={roadmapId}
      />
      {queryLoaderWithExistenceCheck(indicatorQuery) || (
        <>
          <PageTitle title={getName(indicator)} />
          <Formik
            validationSchema={validationSchema}
            initialValues={Indicator.toForm(indicator)}
            onSubmit={(values, actions) => {
              const indicator = Indicator.fromForm(values);
              indicatorStore
                .updateIndicator(indicator)
                .then((result) => {
                  actions.setStatus(FORM_STATUS_SUBMITTED);
                  setTimeout(() => {
                    history.goBack(
                      Indicator.getUrl(result.data.result, projectId, roadmapId)
                    );
                  }, 0);
                })
                .catch((error) => {
                  showGraphqlErrorToast(error, mutationErrors.updateObject);
                })
                .then(() => {
                  actions.setSubmitting(false);
                });
            }}
          >
            {(formikProps) => (
              <IndicatorEditForm
                {...formikProps}
                onBackClick={() => history.goBack(backLink)}
                project={project}
                indicator={indicator}
                match={match}
                editor={editor}
              />
            )}
          </Formik>
        </>
      )}
    </>
  );
}
