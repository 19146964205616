import React from 'react';
import ErrorPage from '../../common/ErrorBoundary/ErrorPage';

export default function HasNoAccessPage(props) {
  return (
    <ErrorPage
      text="У вас нет доступа к этой странице"
      description="Ошибка доступа"
    />
  );
}
