import { useEffect, useRef } from 'react';
import { SingleTimeoutManager } from './SingleTimeoutManager';
import { SingleIntervalManager } from './SingleIntervalManager';

const useTimeRef = (Manager) => {
  /** @type {React.MutableRefObject<SingleTimeoutManager>} */
  const ref = useRef(null);

  if (!ref.current) {
    ref.current = new Manager();
  }

  useEffect(() => {
    return () => {
      if (ref.current) ref.current.clear();
    };
  }, []);

  return ref.current;
};

export const useTimeoutRef = () => {
  return useTimeRef(SingleTimeoutManager);
};

export const useIntervalRef = () => {
  return useTimeRef(SingleIntervalManager);
};
