import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { removeLastPathItem } from '../../../../utils/url';
import PageTitle from '../../../../common/PageTitle/PageTitle';
import { STORE_ROADMAP_EVENT } from '../../../../store/names';
import { useOtherEditor } from '../../../../common/Form/useOtherEditor';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../graphql/graphqlErrors';
import { RegionalEventBreadCrumbs } from '../RegionalEventBreadCrumbs';
import { useCurrentUser } from '../../../../store/useCurrentUser';
import { RegionalEvent } from '../../../../store/roadmap/RegionalEvent';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { getName } from '../../../../utils/data/label';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { RegionalEventEditForm } from './RegionalEventEditForm';

const RegionalEventEditPage = ({
  event,
  regionalEvent,
  currentUrl,
  history,
  projectId,
  roadmapId,
  eventId
}) => {
  const roadmapEventStore = useContextStore(STORE_ROADMAP_EVENT);
  const user = useCurrentUser();
  const backLink = removeLastPathItem(currentUrl);
  const editor = useOtherEditor('regionalEvent', regionalEvent?.id);

  const project = regionalEvent.regional_project;
  const roadmap = event.roadmap;
  const region = regionalEvent.region;

  const formValues = useMemo(() => {
    return RegionalEvent.toForm(regionalEvent, event);
  }, [regionalEvent, event]);

  const handleBackClick = useCallback(() => history.goBack(backLink), [
    history,
    backLink
  ]);

  return (
    <>
      <HeadTitle
        type="Региональное мероприятие"
        entity={`${getName(event)} (${getName(region)})`}
        pageType={HeadTitle.PageType.edit}
      />
      <RegionalEventBreadCrumbs
        project={project}
        event={event}
        projectId={projectId}
        federalEventId={eventId}
        roadmapId={roadmapId}
      />
      <PageTitle title={getName(event)} />
      <Formik
        initialValues={formValues}
        onSubmit={(values, actions) => {
          const eventData = RegionalEvent.fromForm(
            values,
            {
              eventId: event.id,
              regionId: region.id,
              regionalId: regionalEvent.id
            },
            user
          );
          roadmapEventStore
            .updateRegionalEvent(eventData)
            .then((result) => {
              actions.setStatus(FORM_STATUS_SUBMITTED);
              history.goBack(
                RegionalEvent.getUrl(
                  result.data.result,
                  projectId && project.id
                )
              );
            })
            .catch((error) => {
              showGraphqlErrorToast(error, mutationErrors.updateObject);
            })
            .then(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {(formikProps) => (
          <RegionalEventEditForm
            {...formikProps}
            onBackClick={handleBackClick}
            editor={editor}
            event={event}
            regionalEvent={regionalEvent}
            user={user}
          />
        )}
      </Formik>
    </>
  );
};

export default RegionalEventEditPage;
