import { groupBy } from 'lodash-es';
import React, { Fragment } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { SubTableHeading } from '../../../common/SubTable/SubTableHeading';
import { ParentalAssistanceGuideline } from '../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';

export const OrganizationsData = ({ organizations }) => {
  const grouped = groupBy(organizations, (organization) => organization.kind);
  return Object.keys(grouped).map((organizationType, iOrganizationType) => (
    <Fragment key={iOrganizationType}>
      <SubTableHeading cols={3}>
        {ParentalAssistanceGuideline.intelligenceType.getName(
          organizationType.toUpperCase()
        )}
        :
      </SubTableHeading>
      {grouped[organizationType].map((organization, iOrganization) => (
        <TableRow key={iOrganization}>
          <TableCell>{organization.organization_name}</TableCell>
          <TableCell>
            {ParentalAssistanceGuideline.getYesOrNo(organization.free_services)}
          </TableCell>
          <TableCell>
            {ParentalAssistanceGuideline.getYesOrNo(organization.paid_services)}
          </TableCell>
        </TableRow>
      ))}
    </Fragment>
  ));
};
