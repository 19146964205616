import { FormHelperText } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';
import { reactSelectHelpers } from '../../../../utils/form/reactSelectHelpers';
import { commonSelectComponents } from '../../../Inputs/Selects/common';
import s from './Autocomplete.module.scss';

export const Autocomplete = ({
  value,
  onChange,
  options,
  name,
  searchable,
  error,
  placeholder = 'Выбрать из списка...',
  disabled,
  className,
  isLoading,
  movePlaceholder,
  hasCopyBtn
}) => {
  const selectRef = useRef();

  const val = options.find((item) => item.value === value) ?? null;
  return (
    <FormControl fullWidth={true} error={!!error}>
      {value?.length > 0 && movePlaceholder && (
        <span className={s.placeholder}>{placeholder}</span>
      )}
      <Select
        // для разработки: можно держать меню всегда открытым
        // menuIsOpen={true}
        ref={selectRef}
        value={val}
        options={options}
        name={name}
        onChange={(v) => onChange(v.value)}
        className={classNames(s.Select, className)}
        isSearchable={searchable}
        menuPortalTarget={document.body}
        placeholder={placeholder}
        isLoading={isLoading}
        hasCopyBtn={hasCopyBtn}
        // filterOption исправляет ошибку с поиском по значению
        // https://stackoverflow.com/questions/51938587/react-select-type-to-search-access-to-filteroption-resulting-array
        filterOption={reactSelectHelpers.filterOption}
        // исправление ошибки при выборе первого значения по клику на пробел
        // https://stackoverflow.com/questions/53396697/react-select-v2-on-space-press-selects-first-value
        onKeyDown={(e) => {
          if (e.keyCode === 32 && !selectRef.current.state.inputValue) {
            e.preventDefault();
          }
        }}
        styles={{
          placeholder: (provided) => ({
            ...provided,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '100%',
            paddingRight: 4
          }),
          container: (provided, state) => ({
            ...provided,
            border: `1px solid #${!!error ? 'F44336' : 'D1D0D8'}`,
            borderRadius: state.selectProps.menuIsOpen ? '6px 6px 0 0' : 6
          }),
          control: (provided, state) => ({
            ...provided,
            height: 34,
            minHeight: 34,
            outline: 'none',
            fontSize: 12,
            border: 'none',
            borderRadius: state.selectProps.menuIsOpen ? '6px 6px 0 0' : 6,
            boxShadow: 'none',
            '&:hover': {
              outline: 'none'
            },
            ...(state.selectProps.menuIsOpen
              ? {
                  borderBottom: '1px solid #F5F6F7'
                }
              : {})
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            padding: '8px 6px'
          }),
          indicatorSeparator: () => ({
            display: 'none'
          }),
          menu: (provided) => ({
            ...provided,
            margin: 0,
            borderRadius: '0 0 6px 6px'
          }),
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 10
          }),
          option: (provided, state) => ({
            ...provided,
            minHeight: 36,
            padding: '5px 16px',
            fontSize: 12,
            lineHeight: '16px',
            display: 'flex',
            alignItems: 'center',
            background: state.isFocused ? '#F5F6F7' : '#fff',
            color: '#3F4752',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          })
        }}
        isDisabled={disabled}
        closeMenuOnScroll={true}
        components={{
          IndicatorsContainer: (props) =>
            commonSelectComponents.IndicatorsContainer({
              size: 16,
              ...props
            })
        }}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

Autocomplete.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array
};
