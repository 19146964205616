import React from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { removeLastPathItem } from '../../../../utils/url';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import { STORE_MENTORING_COVERAGE } from '../../../../store/names';
import { handleFormSubmission } from '../../../../utils/data/form';
import {
  MentoringCoverageSchema,
  ParentalAssistanceGuidelinesOrganizationalValidationSchema
} from '../../../../utils/data/validation';
import { useMentoringCoverage } from '../../../../store/mentoringCoverage/useMentoringCoverage';
import { MentoringCoverage } from '../../../../store/mentoringCoverage/MentoringCoverage';
import RegionMentoringCoverageEditForm from './RegionMentoringCoverageEditForm';

const title = 'Охват наставничеством';

export default function RegionMentoringCoverageEdit({ history, match }) {
  const mentoringCoverageStore = useContextStore(STORE_MENTORING_COVERAGE);
  const backLink = removeLastPathItem(match.url);
  const { mentoringCoverageId } = match.params;

  const mentoringCoverageQuery = useMentoringCoverage(mentoringCoverageId);
  const mentoringCoverage = mentoringCoverageQuery.state.data;

  return (
    <>
      <HeadTitle type={title} pageType={HeadTitle.PageType.edit} />
      <h2>Редактирование охвата наставничеством</h2>
      {queryLoaderWithExistenceCheck(mentoringCoverageQuery) || (
        <Formik
          validationSchema={MentoringCoverageSchema}
          initialValues={MentoringCoverage.toMentoringCoverageForm(
            mentoringCoverage
          )}
          onSubmit={(values, actions) => {
            const form = MentoringCoverage.fromMentoringCoverageForm(values);
            handleFormSubmission(
              mentoringCoverageStore.updateMentoringCoverage(form),
              actions,
              (result) =>
                history.goBack(
                  MentoringCoverage.getMentoringCoverageUrl(result)
                )
            );
          }}
          render={(formikProps) => (
            <RegionMentoringCoverageEditForm
              {...formikProps}
              onBackClick={() => history.goBack(backLink)}
              region={mentoringCoverage.region}
            />
          )}
        />
      )}
    </>
  );
}
