import {
  MUTATION_CREATE_PURCHASE,
  MUTATION_CREATE_PURCHASE_CONFIRMATION,
  MUTATION_DELETE_PURCHASE,
  MUTATION_UPDATE_PURCHASE
} from '../../graphql/mutations/purchases';
import { clearApolloQueryCache } from '../../utils/apollo';
import { QUERY_GET_INFRALISTS_EQUIPMENTS } from '../../graphql/queries/purchases';
import { Purchase } from './Purchase';

export class PurchaseStore {
  constructor({ client }) {
    this.client = client;
  }

  // поскольку аполло кеширует данные по запросам в виде {ХЭШ_АПОЛЛО}ROOT_QUERY.query_name
  // и мы не знаем значение хэша, то необходимо искать нужный запрос по окончанию строки

  purchasesMatcher = /\$ROOT_QUERY\.purchases?/;

  queriesToClear = [
    'purchases',
    'expensesDirections',
    'regionalProjects',
    'latestPurchaseConfirmation'
  ];
  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  clearCacheWithList = (id) => (store) => {
    const storeData = store.data;
    // при optimisticRender Apollo хранит данные по кешу в store.data.parent, иначе в store.data.data
    const isOptimisticRemoving = 'parent' in storeData;
    if (!isOptimisticRemoving) {
      // если не optimisticRendering - просто вызываем функцию очистки
      this.clearCache(store);
    } else {
      const data = storeData.parent.data;
      Object.keys(data).forEach((key) => {
        // ищем нужный закешированный запрос
        if (key.match(this.purchasesMatcher)) {
          // берем список из данных запроса и удаляем элемент с нужным id
          this.removeItemFromList(data[key].list, id);
        }
      });
    }
  };

  removeItemFromList = (list = [], id) => {
    const index = list.findIndex(
      ({ typename, id: elemId }) => elemId === `${typename}:${id}`
    );
    list.splice(index, 1);
  };

  deletePurchase(id) {
    return this.client.mutate({
      mutation: MUTATION_DELETE_PURCHASE,
      variables: {
        id
      },
      update: this.clearCacheWithList(id),
      optimisticResponse: {
        deletePurchase: 1
      },
      refetchQueries: ['purchases']
    });
  }

  createPurchase(data) {
    return this.client.mutate({
      mutation: MUTATION_CREATE_PURCHASE,
      variables: {
        input: Purchase.filterCreateFields(data)
      },
      refetchQueries: ['purchase'],
      update: this.clearCache
    });
  }

  updatePurchase(data) {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_PURCHASE,
      variables: {
        input: Purchase.filterUpdateFields(data)
      },
      refetchQueries: ['purchase'],
      update: this.clearCache
    });
  }

  createPurchaseConfirmation(input) {
    return this.client.mutate({
      mutation: MUTATION_CREATE_PURCHASE_CONFIRMATION,
      variables: {
        input: input
      },
      refetchQueries: ['latestPurchaseConfirmation'],
      update: this.clearCache
    });
  }

  getInfralistsSuggestion(input) {
    return this.client.query({
      fetchPolicy: 'network-only',
      query: QUERY_GET_INFRALISTS_EQUIPMENTS,
      variables: {
        input: input
      }
    });
  }
}
