import React, { useMemo, useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useContextStore } from '@proscom/prostore-react';
import { CircularProgress } from '@material-ui/core';
import PageTitle from '../../common/PageTitle/PageTitle';
import { useCurrentUser } from '../../store/useCurrentUser';
import {
  QueryPagination,
  useQueryPagination,
  useQueryPaginationCount
} from '../../common/usePagination';
import { useUrlQuery } from '../../common/useUrlQuery';
import Table from '../../common/Table/Table';
import { EMDASH } from '../../utils/constants';
import Menu from '../../common/Menu/Menu';
import { useSubsidiaryEntities } from '../../store/subsidiaryEntity/useSubsidiaryEntities';
import { SUBSIDIARY_ENTITY_STORE } from '../../store/names';
import { Individual } from '../../store/Individual';
import { SubsidiaryEntity } from '../../store/subsidiaryEntity/SubsidiaryEntity';
import { useDeleteRow } from '../../common/Table/useDeleteRow';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';
import { useConfirmRow } from '../../common/Table/useConfirmRow';
import { useRevokeConfirmationRow } from '../../common/Table/useRevokeConfirmationRow';
import { SubsidiaryEntityEditValidationSchema } from '../../utils/data/validation';
import { useSubsidiaryEntitiesFilter } from './useSubsidiaryEntitiesFilter';
import { SubsidiaryEntityNameCell } from './SubsidiaryEntityNameCell';
import { SubsidiaryEntityValidationDialog } from './SubsidiaryEntityValidationDialog';

const subsidiaryEntitiesColumns = [
  { label: '№', key: 'order', isThin: true },
  { label: 'Код', key: 'code', isThin: true },
  {
    label: 'Наименование',
    key: (entity) => <SubsidiaryEntityNameCell entity={entity} />,
    isWide: true,
    isBold: true
  },
  {
    label: 'Федеральный проект',
    key: 'owner_roadmap.result.owner_task.federal_project.name',
    isWide: true,
    isBreak: true
  },
  { label: 'Регион', key: 'region.name', isWide: true, isBreak: true },
  {
    label: 'Дорожная карта',
    key: 'owner_roadmap.name_full',
    isWide: true,
    isBreak: true
  },
  {
    label: 'ФИО Руководителя',
    key: (row) => Individual.combineName(row.supervisor) || EMDASH,
    isBreak: true
  },
  {
    label: 'Утверждено',
    key: (entity) => (entity.is_approved ? '✓' : '✕'),
    isBreak: true
  }
];

const title = 'Реестр субсидиарных сущностей';

export default function SubsidiaryEntitiesPage({ history, location, match }) {
  const user = useCurrentUser();
  const subsidiaryEntityStore = useContextStore(SUBSIDIARY_ENTITY_STORE);
  const [isValidationError, setIsValidationError] = useState(false);

  const [query, changeQuery] = useUrlQuery(location, history);
  const [filter, filterComponent] = useSubsidiaryEntitiesFilter(
    query,
    changeQuery,
    user
  );
  const pagination = useQueryPagination(query, changeQuery, 50);

  const entitiesQuery = useSubsidiaryEntities(filter, pagination.params);

  useQueryPaginationCount(pagination, entitiesQuery);
  const entitiesQueryData = entitiesQuery.state.data;
  const data = useMemo(() => {
    if (!entitiesQueryData) {
      return null;
    }
    const { list, header } = entitiesQueryData;
    return list.map((entity, iEntity) => ({
      ...entity,
      order: 1 + iEntity + header.page * header.onePage
    }));
  }, [entitiesQueryData]);

  const canCreate = SubsidiaryEntity.canCreate(user);

  const menuComponent = useMemo(
    () =>
      function MenuComponent({ row }) {
        const [isDeleting, dialog, openDialog] = useDeleteRow(
          subsidiaryEntityStore.deleteSubsidiaryEntity,
          'субсидиарную сущность'
        );

        const [isConfirming, confirmDialog, openConfirmDialog] = useConfirmRow(
          subsidiaryEntityStore.approveSubsidiaryEntity,
          'субсидиарную сущность'
        );

        const [
          isRevokeConfirming,
          revokeConfirmationDialog,
          openRevokeConfirmationDialog
        ] = useRevokeConfirmationRow(
          subsidiaryEntityStore.revokeSubsidiaryEntityApprove,
          'субсидиарной сущности'
        );

        const menu = [];

        const canEdit = SubsidiaryEntity.canEdit(user, row);
        const canDelete = SubsidiaryEntity.canDelete(user, row);
        const canConfirm = SubsidiaryEntity.canConfirm(user, row);
        const canRevokeConfirmation = SubsidiaryEntity.canRevokeConfirmation(
          user,
          row
        );

        if (!row.is_approved && canConfirm && canEdit) {
          menu.push({
            title: 'Утвердить',
            onClick: () => {
              SubsidiaryEntityEditValidationSchema.validate(row)
                .then(() => {
                  openConfirmDialog(row.id);
                })
                .catch((err) => {
                  setIsValidationError(true);
                });
            }
          });
        }

        if (row.is_approved && canRevokeConfirmation && canEdit) {
          menu.push({
            title: 'Отозвать утверждение',
            onClick: () => openRevokeConfirmationDialog(row.id)
          });
        }

        if (!row.is_approved && canEdit) {
          menu.push({
            title: 'Редактировать',
            onClick: () => history.push(`${match.url}/${row.id}/edit`)
          });
        }

        if (!row.is_approved && canDelete) {
          menu.push({
            title: 'Удалить',
            onClick: () => openDialog(row.id)
          });
        }

        if (menu.length === 0) {
          return false;
        }

        return (
          <>
            {isDeleting || isConfirming || isRevokeConfirming ? (
              <CircularProgress size={18} />
            ) : (
              <Menu iconColor="default" icon={MoreHorizIcon} options={menu} />
            )}
            {dialog}
            {confirmDialog}
            {revokeConfirmationDialog}
          </>
        );
      },
    [history, match, subsidiaryEntityStore, user]
  );

  return (
    <>
      <HeadTitle title={title} />
      <PageTitle withBackBtn={false} title={title} />
      <SubsidiaryEntityValidationDialog
        isOpen={isValidationError}
        onCancel={() => setIsValidationError(false)}
      />
      <Table
        addButtonText="Добавить"
        addFieldRoute={`/subsidiaryEntities/create`}
        columns={subsidiaryEntitiesColumns}
        isLoading={entitiesQuery.check.spinner}
        data={data}
        filter={filterComponent}
        hasAddField={canCreate}
        renderNoData={() => (
          <h3>Нет субсидиарных сущностей по выбранным фильтрам</h3>
        )}
        rowRedirectRoute={`${match.url}/`}
        menuComponent={menuComponent}
        pagination={<QueryPagination pagination={pagination} />}
      />
    </>
  );
}
