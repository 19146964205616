export const DocumentStatus = {
  status: {
    APPROVAL: 'APPROVAL',
    APPROVED: 'APPROVED',
    NOT_APPROVED: 'NOT_APPROVED'
  },
  getStatusName(status) {
    switch (status) {
      case DocumentStatus.status.APPROVAL:
        return 'На утверждении';
      case DocumentStatus.status.APPROVED:
        return 'Утверждено';
      default:
        return 'Не утверждено';
    }
  }
};
