import React from 'react';
import { Prompt } from 'react-router-dom';
import { Form } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import InputGroup from '../../../../common/Inputs/InputGroup';
import { FormHeader } from '../../../../common/Form/Form';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { getName } from '../../../../utils/data/label';
import SubTable from '../../../../common/SubTable/SubTable';
import { RpImplementationForecast } from '../../../../store/rpImplementationForecast/RpImplementationForecast';
import { NewAutocompleteInput } from '../../../../common/InputsNew/NewAutocompleteInput';
import { ResultForecastsFields } from './parts/ResultForecastsFields';
import { RoadmapForecastsFields } from './parts/RoadmapForecastsFields';
import { PassportForecastsFields } from './parts/PassportForecastsFields';
import { IndicatorProjectsFields } from './parts/IndicatorProjectsFields';
import { AgreementForecastsFields } from './parts/AgreementForecastsFields';
import { SubsidyForecastsFields } from './parts/SubsidyForecastsFields';

export const RpImplementationForecastEditForm = ({
  onBackClick,
  isSubmitting,
  editor,
  status,
  values,
  projects,
  mbtFinances,
  resultFederals
}) => {
  const federalProjectsOptions = RpImplementationForecast.getFederalProjectsOptions(
    projects
  );

  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <Form noValidate>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting}
          editor={editor}
          isSubmitting={isSubmitting}
        />
        <SubTable title="Общие сведения" editVariant>
          <TableRow>
            <TableCell>Регион</TableCell>
            <TableCell>{getName(values.region)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Статус</TableCell>
            <TableCell>
              {RpImplementationForecast.status.getName(values.status)}
            </TableCell>
          </TableRow>
        </SubTable>
        <InputGroup>
          <NewAutocompleteInput
            name="status"
            label="Статус"
            suggestions={RpImplementationForecast.status.getOptions()}
            fullWidth
            required
          />
        </InputGroup>
        <IndicatorProjectsFields />
        <ResultForecastsFields
          federalProjectsOptions={federalProjectsOptions}
          resultFederals={resultFederals}
        />
        <SubsidyForecastsFields mbtFinances={mbtFinances} />
        <AgreementForecastsFields
          federalProjectsOptions={federalProjectsOptions}
        />
        <PassportForecastsFields />
        <RoadmapForecastsFields />
      </Form>
    </>
  );
};
