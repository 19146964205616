import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ContextMenuArea } from '../../../ContextMenuArea/ContextMenuArea';
import s from './TableContextMenu.module.scss';

export const TableContextMenu = ({ options, tooltipTitle }) => {
  const activeOptions = useMemo(() => {
    return options.filter(
      (optionItem) =>
        typeof optionItem.isActive === 'undefined' || !!optionItem.isActive
    );
  }, [options]);

  if (!activeOptions.length) {
    return null;
  }

  return (
    <ContextMenuArea
      options={activeOptions}
      className={s.buttonArea}
      tooltipTitle={tooltipTitle}
    >
      <IconButton className={s.tableMenuButton}>
        <MoreVertIcon className={s.tableMenuIcon} />
      </IconButton>
    </ContextMenuArea>
  );
};

TableContextMenu.propTypes = {
  options: PropTypes.array
};
