import React, { useMemo } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useContextStore } from '@proscom/prostore-react';
import { CircularProgress } from '@material-ui/core';
import PageTitle from '../../common/PageTitle/PageTitle';
import Table from '../../common/Table/Table';
import Menu from '../../common/Menu/Menu';
import { User } from '../../store/role/User';
import {
  QueryPagination,
  useQueryPagination,
  useQueryPaginationCount
} from '../../common/usePagination';
import { useUrlQuery } from '../../common/useUrlQuery';
import { useCurrentUser } from '../../store/useCurrentUser';
import { STORE_FORM } from '../../store/names';
import { useForms } from '../../store/form/formQueries';
import { useDeleteRow } from '../../common/Table/useDeleteRow';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';

const columns = [
  {
    label: '№',
    key: 'order',
    isThin: true
  },
  {
    label: 'Имя',
    key: 'name',
    isWide: true,
    isBold: true
  }
];

const title = 'Управление формами';

export default function FormsPage({ match, location, history }) {
  const itemStore = useContextStore(STORE_FORM);
  const user = useCurrentUser();
  const [query, changeQuery] = useUrlQuery(location, history);
  const pagination = useQueryPagination(query, changeQuery, 50);

  const itemsQuery = useForms(
    {
      creator_user_id: !User.isAdmin(user) ? user?.id : undefined,
      canEdit: true
    },
    pagination.params
  );

  useQueryPaginationCount(pagination, itemsQuery);
  const itemsData = itemsQuery.state.data;
  const data = useMemo(() => {
    if (!itemsData) {
      return null;
    }
    const { list, header } = itemsData;
    return list.map((user, iEntity) => ({
      ...user,
      order: 1 + iEntity + header.page * header.onePage
    }));
  }, [itemsData]);

  const menuComponent = useMemo(
    () =>
      function MenuComponent({ row }) {
        const [isDeleting, dialog, openDialog] = useDeleteRow(
          itemStore.deleteForm,
          'формы'
        );

        return (
          <>
            {isDeleting ? (
              <CircularProgress size={18} />
            ) : (
              <Menu
                iconColor="default"
                icon={MoreHorizIcon}
                options={[
                  {
                    title: 'Редактировать',
                    onClick: () => history.push(`${match.url}/${row.id}/edit`)
                  },
                  {
                    title: 'Удалить',
                    onClick: () => openDialog(row.id)
                  }
                ]}
              />
            )}
            {dialog}
          </>
        );
      },
    [history, match, itemStore]
  );

  return (
    <>
      <HeadTitle title={title} />
      <PageTitle withBackBtn={false} title={title} />
      <Table
        addButtonText="Добавить"
        addFieldRoute={`${match.url}/create`}
        hasAddField={true}
        columns={columns}
        data={data}
        isLoading={itemsQuery.check.spinner}
        renderNoData={() => <h3>Нет доступных форм</h3>}
        rowRedirectRoute={`${match.url}/`}
        menuComponent={menuComponent}
        pagination={<QueryPagination pagination={pagination} />}
      />
    </>
  );
}
