import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import InputGroup from '../../../../../common/Inputs/InputGroup';
import { NewAutocompleteInput } from '../../../../../common/InputsNew/NewAutocompleteInput';
import { RegionalEvent } from '../../../../../store/roadmap/RegionalEvent';
import { NewTextInput } from '../../../../../common/InputsNew/NewTextInput';
import SubTable from '../../../../../common/SubTable/SubTable';

export const StatusInfo = React.memo(function StatusInfo({
  canEditStatus,
  user,
  regionalEvent
}) {
  return canEditStatus ? (
    <InputGroup>
      <NewAutocompleteInput
        name="status"
        label="Статус выполнения мероприятия"
        suggestions={RegionalEvent.getStatusOptions(user)}
        fullWidth
        required
        hasCopyBtn={false}
        isClearable={false}
      />
      <NewTextInput
        name="comment_fpo"
        multiline
        label="Комментарий ФПО"
        fullWidth
      />
    </InputGroup>
  ) : (
    <SubTable title="Статус выполнения мероприятия" editVariant>
      <TableRow>
        <TableCell>Статус</TableCell>
        <TableCell>
          {RegionalEvent.status.getName(regionalEvent.status)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Комментарий ФПО</TableCell>
        <TableCell>{regionalEvent.comment_fpo}</TableCell>
      </TableRow>
    </SubTable>
  );
});
