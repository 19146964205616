import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_LATEST_PURCHASE_CONFIRMATION } from '../../graphql/queries/purchases';

const latestPurchaseConfirmationQueryOptions = {
  query: QUERY_GET_LATEST_PURCHASE_CONFIRMATION,
  mapData: (result) => result.latestPurchaseConfirmation,
  skipQuery: skipIfNull(null)
};

export function useLatestPurchaseConfirmation(filter) {
  return useRetriableGraphqlVars({
    queryOptions: latestPurchaseConfirmationQueryOptions,
    variableCreator: [
      () =>
        filter
          ? {
              filter
            }
          : null,
      [filter]
    ]
  });
}
