import { clearApolloQueryCache } from '../../utils/apollo';
import {
  MUTATION_CREATE_ROLE,
  MUTATION_DELETE_ROLE,
  MUTATION_UPDATE_ROLE
} from '../../graphql/mutations/roles';
import { Role } from './Role';

export class RoleStore {
  constructor({ client }) {
    this.client = client;
  }

  queriesToClear = ['roles', 'role'];

  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  updateRole(data) {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_ROLE,
      variables: {
        input: Role.filterUpdateFields(data)
      },
      refetchQueries: ['roles'],
      update: this.clearCache
    });
  }

  createRole(data) {
    return this.client.mutate({
      mutation: MUTATION_CREATE_ROLE,
      variables: {
        input: Role.filterCreateFields(data)
      },
      refetchQueries: ['roles'],
      update: this.clearCache
    });
  }

  deleteRole = (data) => {
    return this.client.mutate({
      mutation: MUTATION_DELETE_ROLE,
      variables: {
        id: data
      },
      refetchQueries: ['roles'],
      update: this.clearCache
    });
  };
}
