import React from 'react';
import { CustomFastField } from '../../../../../common/Inputs/CustomFastField';
import TableInput from '../../../../../common/Table/TableInput';
import InputGroup from '../../../../../common/Inputs/InputGroup';
import { RpImplementationForecast } from '../../../../../store/rpImplementationForecast/RpImplementationForecast';
import { TableTextInput } from '../../../../../common/Inputs/TextInput';
import { TableSelectWrapper } from '../../../../../common/Inputs/TableSelectWrapper';

const getAgreementForecastsColumns = (federalProjectsOptions) => {
  return [
    {
      editComponent: TableSelectWrapper(federalProjectsOptions, {
        width: '200px'
      }),
      field: 'federal_project_id',
      title: 'Региональный проект',
      headerStyle: RpImplementationForecast.editTableStyles.tableCellMedium,
      render: ({ federal_project_id }) => {
        const federalProject = federalProjectsOptions.filter(
          (item) => item.value === federal_project_id
        );
        return federalProject[0] ? federalProject[0].label : '—';
      }
    },
    {
      editComponent: TableTextInput,
      field: 'agreement_number',
      title: 'Номер соглашения',
      headerStyle: RpImplementationForecast.editTableStyles.tableCellMedium
    },
    {
      editComponent: TableTextInput,
      field: 'suggestions',
      title: 'Предложения по внесению изменений',
      headerStyle: RpImplementationForecast.editTableStyles.tableCellLarge
    }
  ];
};

export const AgreementForecastsFields = ({ federalProjectsOptions }) => {
  return (
    <InputGroup>
      <CustomFastField
        name="agreement_forecasts"
        columns={getAgreementForecastsColumns(federalProjectsOptions)}
        component={TableInput}
        tableName="Изменения в соглашение"
      />
    </InputGroup>
  );
};
