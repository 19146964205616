import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { useRegionalProject } from '../../../../store/regionalProject/useRegionalProject';
import { Pof } from '../../../../store/pof/Pof';
import { PofAuth } from '../../../../store/pof/PofAuth';
import { PofEditForm } from '../edit/PofEditForm';
import { removeLastPathItem } from '../../../../utils/url';
import { getMonth } from '../../../../utils/data/date';
import { STORE_POF } from '../../../../store/names';
import { useUrlQuery } from '../../../../common/useUrlQuery';
import { handleFormSubmission } from '../../../../utils/data/form';
import { PofBreadCrumbs } from '../PofBreadCrumbs';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';

const PofCreatePage = ({ match, location, history }) => {
  const backLink = removeLastPathItem(match.url);
  const projectId = match.params.projectId;
  const [query] = useUrlQuery(location, history);

  const { year, month, regionalProjectId } = query;

  const projectQuery = useRegionalProject(projectId || regionalProjectId);
  const project = projectQuery.state.data;

  const regionId = project?.region?.id;

  const pofStore = useContextStore(STORE_POF);

  const today = new Date();

  const formHeaderData = {
    regionName: project?.region?.name,
    regionId: project?.region?.id,
    projectId,
    year: query.year || today.getFullYear(),
    monthName: getMonth(query.month - 1) || getMonth(today.getMonth())
  };

  const initialValues = useMemo(
    () => ({
      expenses_direction_id: '',
      values: Pof.monthInitialValues,
      year: Number(year) || today.getFullYear(),
      month: month ? String(month) : today.getMonth(),
      region_id: regionId
    }),
    [today, regionId, year, month]
  );

  const validationSchema = useMemo(() => PofAuth.validationSchemaCreate(), []);

  return (
    <>
      <HeadTitle type="ПОФ" pageType={HeadTitle.PageType.create} />
      <PofBreadCrumbs projectId={projectId} project={project} />
      <h2>Создание ПОФ</h2>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          const pof = Pof.fromForm(values, regionId);
          handleFormSubmission(pofStore.createPof(pof), actions, (result) =>
            history.push(Pof.getUrl(result.data.result, projectId))
          );
        }}
        render={(formikProps) => (
          <PofEditForm
            {...formikProps}
            onBackClick={() => history.goBack(backLink)}
            formHeaderData={formHeaderData}
            createForm={true}
          />
        )}
      />
    </>
  );
};

export default PofCreatePage;
