import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_NON_COMMERCIAL_ORGANIZATION } from '../../graphql/queries/parentalAssistanceGuidelines/nonCommercialOrganizations';

const nonCommercialOrganizationsQueryOptions = {
  query: QUERY_GET_NON_COMMERCIAL_ORGANIZATION,
  mapData: (result) => result.nonCommercialOrganization,
  skipQuery: skipIfNull(null)
};

export function useNonCommercialOrganization(id) {
  return useRetriableGraphql({
    queryOptions: nonCommercialOrganizationsQueryOptions,
    options: {
      apolloOptions: {
        fetchPolicy: 'network-only'
      }
    },
    variables: id
      ? {
          id
        }
      : null
  });
}
