import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Pof } from '../store/pof/Pof';

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 160,
    marginRight: 20
  }
}));

export const PofStatusSelect = React.memo(function PofStatusSelect({
  status,
  onChange,
  label
}) {
  const classes = useStyles();
  const handleChange = (event) => {
    onChange({ status: event.target.value }, true);
  };

  return (
    <TextField
      select
      value={status}
      onChange={handleChange}
      className={classes.input}
      variant="outlined"
      label={label}
    >
      <MenuItem value="all">Все</MenuItem>
      {Object.values(Pof.Status).map((status, index) => (
        <MenuItem key={index} value={status}>
          {Pof.status.getName(status)}
        </MenuItem>
      ))}
    </TextField>
  );
});
