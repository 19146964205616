import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import {
  MUTATION_ACCEPT_DIRECTION_COMMENTARY,
  MUTATION_REJECT_DIRECTION_COMMENTARY
} from '../../../graphql/mutations/comment';
import { INFRALIST_QUERIES } from '../infralistQueries';

export const useMakeDecisionDirectionCommentaries = () => {
  const client = useContextApolloClient();

  const acceptComment = ({ content, directionId, infralistId, ids }) => {
    return client.mutate({
      mutation: MUTATION_ACCEPT_DIRECTION_COMMENTARY,
      variables: {
        content,
        comment_ids: ids,
        direction_id: directionId,
        infralist_id: infralistId
      },
      refetchQueries: [INFRALIST_QUERIES.DIRECTION_GENERAL_COMMENTARIES]
    });
  };

  const rejectComment = ({ content, directionId, infralistId, ids }) => {
    return client.mutate({
      mutation: MUTATION_REJECT_DIRECTION_COMMENTARY,
      variables: {
        content,
        comment_ids: ids,
        direction_id: directionId,
        infralist_id: infralistId
      },
      refetchQueries: [INFRALIST_QUERIES.DIRECTION_GENERAL_COMMENTARIES]
    });
  };

  return { acceptComment, rejectComment };
};
