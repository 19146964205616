import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_STAFF_SCHEDULE_GUIDELINES } from '../../graphql/queries/staffScheduleGuidelines';

const staffScheduleGuidelinesQueryOptions = {
  query: QUERY_GET_STAFF_SCHEDULE_GUIDELINES,
  mapData: (result) =>
    result.staffScheduleGuidelines?.sort((a, b) => +a.number - +b.number),
  skipQuery: skipIfNull(null)
};

export function useStaffScheduleGuidelines(filter) {
  return useRetriableGraphqlVars({
    queryOptions: staffScheduleGuidelinesQueryOptions,
    variableCreator: [
      () =>
        filter
          ? {
              filter
            }
          : null,
      [filter]
    ]
  });
}
