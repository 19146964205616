import { pick } from 'lodash-es';
import { formatCurrency } from '../../utils/number';
import { monthNames } from '../../utils/data/date';
import { EnumHelpers } from '../../utils/EnumHelpers';
import colors from '../../colors.scss';

export class Pof {
  static baseFields = [
    'id',
    'author_id',
    'date',
    'editor_id',
    'expenses_direction_id',
    'month',
    'month',
    'status',
    'year',
    'region_id'
  ];

  static pofFields = [
    // Поля модели
    ...this.baseFields,
    'comment_fpo',

    // Поля инпута
    'values'
  ];

  static monthInitialValues = monthNames.map(() => ({ value: 0, comment: '' }));

  static Status = {
    DRAFT: 'DRAFT',
    APPROVAL: 'APPROVAL',
    RETURNED: 'RETURNED',
    APPROVED: 'APPROVED'
  };

  static status = new EnumHelpers(
    this.Status,
    {
      DRAFT: 'Черновик',
      APPROVAL: 'На утверждении',
      RETURNED: 'Возвращено на доработку',
      APPROVED: 'Утверждено'
    },
    {
      DRAFT: colors.graytable,
      APPROVAL: colors.yellow,
      RETURNED: colors.error,
      APPROVED: colors.green
    }
  );

  static getQuarters(values = []) {
    const quarters = [];
    let j = 0;
    for (let i = 0; i < values.length; i++) {
      if (i % 3 === 0) {
        j = quarters.length;
        quarters.push([]);
      }
      quarters[j].push(values[i]);
    }
    return quarters;
  }

  static transformValues(values, year) {
    return values.map((value, index) => ({
      year,
      value: Number.parseFloat(value.value),
      month: index + 1,
      comment: value.comment
    }));
  }

  static filterUpdateFields(data) {
    return pick(data, ['id', ...this.pofFields]);
  }

  static fromForm({ values, year, ...fields }, regionId) {
    return {
      ...fields,
      year,
      region_id: regionId,
      values: values && Pof.transformValues(values, year)
    };
  }

  static toForm(event) {
    const values = event.values.map((value) => ({
      value: value.value,
      comment: value.comment
    }));
    return {
      ...event,
      values
    };
  }

  static getUrl(pof, regionalProjectId) {
    if (regionalProjectId)
      return `/projects/${regionalProjectId}/pofs/${pof.id}`;
    return `/pofs/${pof.id}`;
  }

  static formatData(haveData, dataValue) {
    if (haveData) {
      return formatCurrency(dataValue || 0);
    }
    return formatCurrency(null);
  }
}
