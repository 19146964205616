import React from 'react';
import PropTypes from 'prop-types';
import { FormikNumber } from './FormikNumber';

export const FormikCurrency = ({ name, placeholder }) => (
  <FormikNumber
    decimalScale={2}
    fixedDecimalScale={true}
    name={name}
    placeholder={placeholder}
  />
);

FormikCurrency.propTypes = {
  name: PropTypes.string.isRequired
};
