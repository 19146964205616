import React, { useMemo } from 'react';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';
import PageTitle from '../../common/PageTitle/PageTitle';
import Table from '../../common/Table/Table';
import { formatDateIso, tryParseIso } from '../../utils/date';
import { ReactComponent as MailLogo } from '../../assets/icons/mail.svg';
import { ReactComponent as MailLogoOpened } from '../../assets/icons/mail-opened.svg';
import { useUrlQuery } from '../../common/useUrlQuery';
import { useIncomingNotification } from '../../store/notification/useNotifications';
import { Notifications } from '../../store/notification/Notification';
import {
  QueryPagination,
  useQueryPagination,
  useQueryPaginationCount
} from '../../common/usePagination';
import { useNotificationFilter } from './useNotificationFilter';
import s from './Notifications.module.scss';

const notifyColumn = [
  {
    label: 'Прочтено',
    key: 'read',
    isThin: true,
    renderCell: (value) => {
      return (
        <div className={s.mailLogo}>
          {value ? <MailLogoOpened /> : <MailLogo />}
        </div>
      );
    }
  },
  {
    label: 'Заголовок',
    key: 'subject',
    isWide: true,
    isBold: true
  },
  {
    label: 'Раздел',
    key: 'section',
    isWide: true,
    renderCell: (value) => {
      return <div>{Notifications.sections.getName(value)}</div>;
    }
  },
  {
    label: 'Дата',
    key: 'sent_at',
    isWide: true,
    renderCell: (value) => formatDateIso(tryParseIso(value))
  },
  {
    label: 'Автор',
    key: 'sender.login',
    isWide: true
  }
];

const title = 'Уведомления';

function NotificationsPage({ match, location, history }) {
  const [query, changeQuery] = useUrlQuery(location, history);
  const [filterComponent, filter] = useNotificationFilter(query, changeQuery);

  const pagination = useQueryPagination(query, changeQuery, 25);
  const incomingNotificationQuery = useIncomingNotification(
    filter,
    pagination.params
  );
  const incomingNotification = useMemo(() => {
    if (!incomingNotificationQuery.state.data) return [];
    return incomingNotificationQuery.state.data.list;
  }, [incomingNotificationQuery.state.data]);
  useQueryPaginationCount(pagination, incomingNotificationQuery);

  return (
    <>
      <HeadTitle title={title} />
      <PageTitle withBackBtn={false} title={title} />
      <Table
        addButtonText="Отправить"
        addFieldRoute={`${match.url}/create`}
        hasAddField={true}
        columns={notifyColumn}
        data={incomingNotification}
        isLoading={incomingNotificationQuery.check.spinner}
        renderNoData={() => <h3>Нет уведомлений</h3>}
        rowRedirectRoute={`${match.url}/`}
        filter={filterComponent}
        pagination={<QueryPagination pagination={pagination} />}
      />
    </>
  );
}

export default NotificationsPage;
