import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Form } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { useRegions } from '../../../store/subsidiaryEntity/useSubsidiaryEntity';
import { User } from '../../../store/role/User';
import { useMemoSuggestions } from '../../../utils/suggestions';
import { FormHeader } from '../../../common/Form/Form';
import InputGroup from '../../../common/Inputs/InputGroup';
import SubTable from '../../../common/SubTable/SubTable';
import { yearsSuggestions } from '../../../utils/data/date';
import { EMDASH } from '../../../utils/constants';
import AutocompleteBaseInput from '../../../common/Inputs/Selects/AutocompleteBaseInput';
import Loader from '../../../common/Loader/Loader';
import { ContestDocsMonitoringEntity } from '../../../store/contestDocsMonitoring/ContestDocsMonitoringEntity';
import { useUserRole } from '../../../store/role/useUserRole';
import { useAvailableRegionalProjectsQueryOptions } from '../../../store/contestDocsMonitoring/useMonthlyMonitoringRegionalProjects';
import { useGeneralConfirmation } from '../../../common/Form/useGeneralConfirmation';
import { ContestDocsMonitoringFormEditable } from './ContestDocsMonitoringFormEditable';
import { ContestDocsMonitoringFormPreview } from './ContestDocsMonitoringFormPreview';
import { ContestDocsEntityValidationDialog } from './ContestDocsEntityValidationDialog';
import s from './ContestDocsMonitoringForm.module.scss';

export const ContestDocsMonitoringForm = ({
  onBackClick,
  isSubmitting,
  editor,
  dirty,
  isValid,
  touched,
  errors,
  values,
  submitForm,
  resetForm,
  setFieldValue,
  filter,
  entity,
  entityLoading,
  entityDeleting,
  exportLoading,
  onFilterChange,
  onDelete,
  onRequestEdit,
  onAllowEdit,
  onRejectEdit,
  onExport,
  onExportHistoryOpen
}) => {
  const user = useCurrentUser();
  const { isRegionAdmin } = useUserRole(user);
  const [isValidationError, setIsValidationError] = useState(false);

  const regionsQuery = useRegions();
  const regions = regionsQuery.state.data;
  const regionsLoading = regionsQuery.check.spinner;
  const availableRegions = useMemo(() => User.getRegions(user, regions), [
    user,
    regions
  ]);
  const regionsSuggestions = useMemoSuggestions(availableRegions);

  const regionalProjectsFilter = useMemo(() => {
    return {
      region_id: filter.region_id || undefined,
      year: +filter.year
    };
  }, [filter]);
  const regionalProjectsQuery = useAvailableRegionalProjectsQueryOptions(
    regionalProjectsFilter
  );
  const regionalProjects = regionalProjectsQuery.state.data;
  const regionalProjectsLoading = regionalProjectsQuery.check.spinner;
  const regionalProjectsSuggestions = useMemoSuggestions(regionalProjects);

  const entityStatus = entity?.status;
  const isApproved =
    entityStatus === ContestDocsMonitoringEntity.Status.APPROVED;
  const isDraft = entityStatus === ContestDocsMonitoringEntity.Status.DRAFT;
  const isReturned =
    entityStatus === ContestDocsMonitoringEntity.Status.RETURNED;
  const isEditRequested =
    entityStatus === ContestDocsMonitoringEntity.Status.EDIT_REQUESTED;

  const canEdit = ContestDocsMonitoringEntity.canEdit(user, entity);
  const canRevoke = ContestDocsMonitoringEntity.canRevoke(user);

  const editable = canEdit && (isDraft || isReturned);

  const handleApprove = useCallback(
    (status) => {
      if (dirty) {
        resetForm();
      }
      setFieldValue('submitStatus', status, false);
      setTimeout(submitForm, 0);
    },
    [dirty, submitForm, resetForm, setFieldValue]
  );

  const handleSubmit = useCallback(() => {
    setFieldValue(
      'submitStatus',
      ContestDocsMonitoringEntity.formSubmitStatus.Submit,
      false
    );
  }, [setFieldValue]);

  const [approveDialog, openApproveDialog] = useGeneralConfirmation(
    handleApprove,
    {
      title: 'Вы уверены, что хотите утвердить форму?',
      children:
        dirty && 'При данном действии все несохраненные данные будут утеряны!'
    }
  );

  const isApprove =
    values['submitStatus'] ===
    ContestDocsMonitoringEntity.formSubmitStatus.Approve;

  useEffect(() => {
    if (isApprove && !isValid) {
      setIsValidationError(true);
    }
  }, [isApprove, isValid]);

  return (
    <>
      <Prompt
        // when={entity && editable && status !== FORM_STATUS_SUBMITTED}
        when={!!entity && editable && !entityDeleting}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />

      <ContestDocsEntityValidationDialog
        isOpen={isValidationError}
        onCancel={() => setIsValidationError(false)}
      >
        {isApprove &&
          'Вы не можете утвердить форму, пока не заполнены все обязательные поля'}
      </ContestDocsEntityValidationDialog>

      <Form noValidate>
        <FormHeader
          backButtonText={'К списку'}
          onBackClick={onBackClick}
          disabled={isSubmitting}
          editor={editor}
          editable={editable}
          isSubmitting={isSubmitting}
          onSubmitClick={handleSubmit}
          actions={
            entity && (
              <>
                {(isApproved || isEditRequested) && (
                  <Button
                    color="default"
                    variant="outlined"
                    onClick={() => onExport(entity.id)}
                  >
                    {exportLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Экспортировать'
                    )}
                  </Button>
                )}

                <Button
                  color="default"
                  variant="outlined"
                  onClick={onExportHistoryOpen}
                >
                  История экспорта
                </Button>

                {canEdit && (isDraft || isReturned) && (
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => onDelete(entity.id)}
                  >
                    Удалить
                  </Button>
                )}

                {canEdit && (isDraft || isReturned) && (
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() =>
                      openApproveDialog(
                        ContestDocsMonitoringEntity.formSubmitStatus.Approve
                      )
                    }
                  >
                    Утвердить
                  </Button>
                )}

                {canEdit && isApproved && (
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => onRequestEdit(entity.id)}
                  >
                    Запросить редактирование
                  </Button>
                )}

                {canRevoke && isEditRequested && (
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => onRejectEdit(entity.id)}
                  >
                    Запретить редактирование
                  </Button>
                )}

                {canRevoke && isEditRequested && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => onAllowEdit(entity.id)}
                  >
                    Разрешить редактирование
                  </Button>
                )}
              </>
            )
          }
        />

        <SubTable title="Общие сведения">
          <TableRow>
            <TableCell>Статус</TableCell>
            <TableCell>
              {ContestDocsMonitoringEntity.statusLabel[values.status] || EMDASH}
            </TableCell>
          </TableRow>
        </SubTable>

        <InputGroup>
          <AutocompleteBaseInput
            label="Год"
            suggestions={yearsSuggestions}
            value={filter.year}
            hasCopyBtn={false}
            fullWidth
            required
            isClearable={false}
            onChange={(value) => onFilterChange('year', value)}
          />
          <AutocompleteBaseInput
            label="Регион"
            suggestions={regionsSuggestions}
            suggestionsLoading={regionsLoading}
            value={filter.region_id}
            hasCopyBtn={false}
            fullWidth
            required
            disabled={isRegionAdmin}
            onChange={(value) => onFilterChange('region_id', value)}
          />
          <AutocompleteBaseInput
            label="Региональный проект"
            suggestions={regionalProjectsSuggestions}
            suggestionsLoading={regionalProjectsLoading}
            value={filter.regional_project_id}
            hasCopyBtn={false}
            fullWidth
            required
            disabled={!filter.region_id}
            onChange={(value) => onFilterChange('regional_project_id', value)}
          />
        </InputGroup>

        {(entityLoading && (
          <Loader className={s.ContestDocsMonitoringForm__loader} />
        )) ||
          (entity ? (
            editable ? (
              <ContestDocsMonitoringFormEditable
                entity={entity}
                values={values}
                touched={touched}
                errors={errors}
              />
            ) : (
              <ContestDocsMonitoringFormPreview entity={entity} />
            )
          ) : (
            <h3>Нет информации по выбранным фильтрам</h3>
          ))}
      </Form>

      {approveDialog}
    </>
  );
};
