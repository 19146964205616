import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useState } from 'react';
import { MUTATION_SIGN_SECTION_EQUIPMENTS } from '../../../graphql/mutations/equipment';
import { clearApolloQueryCache } from '../../../utils/apollo';
import { INFRALIST_QUERIES } from '../infralistQueries';

export const useSignEquipmentSection = () => {
  const client = useContextApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  const signSectionEquipments = ({ infralistId, sectionId }) => {
    setIsLoading(true);
    return client
      .mutate({
        mutation: MUTATION_SIGN_SECTION_EQUIPMENTS,
        variables: {
          infralist_id: infralistId,
          section_id: sectionId
        },
        update: (store) => {
          clearApolloQueryCache(store, [
            INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT
          ]);
        },
        refetchQueries: [INFRALIST_QUERIES.SECTION]
      })
      .finally(() => setIsLoading(false));
  };

  return {
    isLoadingSignEquipmentSection: isLoading,
    signSectionEquipments
  };
};
