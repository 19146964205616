import React from 'react';
import { groupBy, isBoolean, isNull, pick } from 'lodash-es';
import { EnumHelpers } from '../../utils/EnumHelpers';

const nonCommercialOrganizationsInfoFields = [
  'free_services',
  'kind',
  'organization_name',
  'paid_services'
];
const nonCommercialOrganizationFields = [
  'fact_addresses',
  'name_short',
  'region_id'
];

export const resultPartGuideLine = [
  'amount_of_contract_specialists_2019',
  'amount_of_contract_specialists_2020',
  'amount_of_lended_another_format_services_2019',
  'amount_of_lended_another_format_services_2020',
  'amount_of_lended_f2f_services_2019',
  'amount_of_lended_f2f_services_2020',
  'amount_of_lended_field_services_2019',
  'amount_of_lended_field_services_2020',
  'amount_of_lended_free_services_2019',
  'amount_of_lended_free_services_2020',
  'amount_of_lended_paid_services_2019',
  'amount_of_lended_paid_services_2020',
  'amount_of_lended_remote_services_2019',
  'amount_of_lended_remote_services_2020',
  'amount_of_multipletimes_service_recipients_2019',
  'amount_of_multipletimes_service_recipients_2020',
  'amount_of_onetime_service_recipients_2019',
  'amount_of_onetime_service_recipients_2020',
  'amount_of_prepared_specialists_2019',
  'amount_of_prepared_specialists_2020',
  'amount_of_service_rejections_2019',
  'amount_of_service_rejections_2020',
  'amount_of_specialists_in_state_2019',
  'amount_of_specialists_in_state_2020'
];

const numberCommonPart = [...resultPartGuideLine, 'maximum_waiting_time'];

const organizationalNumberFields = [
  'average_duration_of_services_receivement',
  'average_duration_of_services_provisioning'
];
const organizationalFields = [
  'non_commercial_organization_id',
  'babysitting_in_service_time',
  'babysitting_in_service_time_description',
  'comfortable_zone_for_disabled_children',
  'comfortable_zone_for_disabled_children_description',
  'free_service_recipient_groups',
  'has_approved_mediaplan',
  'information_distribution_description',
  'information_distribution_links',
  'maximum_waiting_time_exists',
  'official_site_has_info',
  'official_site_has_info_description',
  'official_site_has_specialists_info',
  'official_site_has_specialists_info_description',
  'other_registration_methods',
  'other_service_delivery_formats',
  'paid_consultations_for_parents',
  'paid_consultations_for_parents_description',
  'possible_consultation_topic_choice',
  'possible_format_choice',
  'possible_nwh_services_provisioning',
  'possible_specialist_choice',
  'possible_time_choice',
  'possible_weekend_services_provisioning',
  'problems_in_new_service_introduction_description',
  'problems_in_new_service_introduction_exist',
  'registration_methods',
  'service_delivery_formats',
  'status',
  'services_quality_control_methodology',
  'services_quality_is_being_controlled',
  'services_quality_is_being_controlled_on_portal',
  'special_categories_of_service_recievers',
  'special_categories_of_service_recievers_description',
  'typical_consultations_description',
  'waiting_zone_description',
  'waiting_zone_exists',
  'status',
  'approved_mediaplan',
  'official_site_info_files',
  'information_distribution_protocols',
  'list_of_local_service_regulations',
  'list_of_regional_service_regulations',
  'service_rejections_description_2019',
  'service_rejections_description_2020',
  'list_of_local_service_regulations_description',
  'list_of_regional_service_regulations_description',
  ...numberCommonPart,
  ...organizationalNumberFields
];

const regionalFields = [
  'region_id',
  'non_commercial_organizations_info',
  'date_of_service_start',
  'date_of_service_start_description',
  'free_service_recipient_groups',
  'information_distribution_description',
  'information_is_being_distributed',
  'maximum_waiting_time_exists',
  'other_service_delivery_formats',
  'other_sources_of_financing',
  'paid_service_delivery_description',
  'list_of_service_regulations',
  'information_distribution_protocols',
  'approved_roadmap',
  'paid_service_possibility',
  'possible_consultation_topic_choice',
  'possible_format_choice',
  'possible_nwh_services_provisioning',
  'possible_specialist_choice',
  'possible_time_choice',
  'possible_weekend_services_provisioning',
  'problems_in_new_service_introduction_description',
  'problems_in_new_service_introduction_exist',
  'problems_in_new_service_introduction_suggestions',
  'service_delivery_formats',
  'service_delivery_mechanism',
  'services_quality_control_methodology',
  'services_quality_is_being_controlled',
  'sources_of_financing',
  'special_categories_of_legal_representatives',
  'special_categories_of_legal_representatives_description',
  'types_of_free_consultations',
  'service_rejections_description_2019',
  'service_rejections_description_2020',
  'new_service_implementation_roadmap_exist',
  'best_practices_of_services_provision',
  'status',
  ...numberCommonPart
];

const mapDataToMultiValues = (dataEnum) => (item) => ({
  label: dataEnum.getName(item.toUpperCase()),
  value: item.toUpperCase()
});

const mapMultiValuesToEnumArray = (item) => item.value;
const mapFileStatusToProperty = (item) => ({
  id: item.id,
  description: item.description || ''
});
const mapFileToProperty = (item) => ({
  id: item.id
});

const mapFilePropertyToField = (item) => ({
  id: item.id,
  name: item.file_name,
  description: item.description || '',
  connected_file: {
    id: item.id,
    name: item.file_name
  }
});

const mapNonCommercialOrganizationsInfo = (type) => ({
  free_services,
  paid_services,
  ...item
}) =>
  pick(
    {
      ...item,
      kind: type,
      free_services: isBoolean(free_services)
        ? free_services
        : free_services === 'true',
      paid_services: isBoolean(paid_services)
        ? paid_services
        : paid_services === 'true'
    },
    nonCommercialOrganizationsInfoFields
  );

export class ParentalAssistanceGuideline {
  static Status = {
    DRAFT: 'DRAFT',
    APPROVED: 'APPROVED'
  };
  static status = new EnumHelpers(this.Status, {
    DRAFT: 'Черновик',
    APPROVED: 'Утверждено'
  });

  static SourcesOfFinancing = {
    STATE_ASSIGNMENT: 'STATE_ASSIGNMENT',
    SUBSIDIES: 'SUBSIDIES',
    SUBVENTIONS_TO_LOCAL_GOVERNMENTS: 'SUBVENTIONS_TO_LOCAL_GOVERNMENTS'
  };
  static sourcesOfFinancing = new EnumHelpers(this.SourcesOfFinancing, {
    STATE_ASSIGNMENT: 'Государственное задание',
    SUBSIDIES: 'Субсидии',
    SUBVENTIONS_TO_LOCAL_GOVERNMENTS:
      'Субвенции органам местного самоуправления'
  });

  static TypesOfFreeConsultations = {
    PSYCHOLOGICAL_AND_PEDAGOGICAL: 'PSYCHOLOGICAL_AND_PEDAGOGICAL',
    METHODICAL: 'METHODICAL',
    DIAGNOSTIC: 'DIAGNOSTIC',
    CONSULTING: 'CONSULTING'
  };
  static typesOfFreeConsultations = new EnumHelpers(
    this.TypesOfFreeConsultations,
    {
      PSYCHOLOGICAL_AND_PEDAGOGICAL: 'Психолого-педагогическая',
      METHODICAL: 'Методическая',
      DIAGNOSTIC: 'Диагностическая',
      CONSULTING: 'Консультационная'
    }
  );

  static ServiceDeliveryFormats = {
    FACE_TO_FACE_CONSULTATIONS: 'FACE_TO_FACE_CONSULTATIONS',
    REMOTE_CONSULTATIONS: 'REMOTE_CONSULTATIONS',
    FIELD_CONSULTATIONS: 'FIELD_CONSULTATIONS'
  };
  static serviceDeliveryFormats = new EnumHelpers(this.ServiceDeliveryFormats, {
    FACE_TO_FACE_CONSULTATIONS: 'Очные консультации',
    REMOTE_CONSULTATIONS: 'Дистанционные консультации',
    FIELD_CONSULTATIONS: 'Выездные консультации'
  });

  static IntelligenceType = {
    PRESCHOOL: 'PRESCHOOL',
    GENERAL: 'GENERAL',
    OTHER: 'OTHER'
  };
  static intelligenceType = new EnumHelpers(this.IntelligenceType, {
    PRESCHOOL: 'Дошкольная образовательная организация',
    GENERAL: 'Общеобразовательная организация',
    OTHER: 'Иная организация'
  });

  static RegistrationMethods = {
    WEB: 'WEB',
    PHONE: 'PHONE',
    FACE_TO_FACE: 'FACE_TO_FACE'
  };
  static registrationMethods = new EnumHelpers(this.RegistrationMethods, {
    WEB: 'Электронная запись',
    PHONE: 'По телефону',
    FACE_TO_FACE: 'Очно (лично)'
  });

  static getYesOrNo = (value) => {
    if (isNull(value)) return '';
    if (value) {
      return 'Да';
    } else {
      return 'Нет';
    }
  };

  static yesNoSuggestions = [
    { label: 'Да', value: true },
    { label: 'Нет', value: false }
  ];

  static toRegionalForm = ({
    sources_of_financing,
    types_of_free_consultations,
    service_delivery_formats,
    non_commercial_organizations_info,
    information_distribution_protocols,
    approved_roadmap,
    list_of_service_regulations,
    ...data
  }) => {
    const grouped = groupBy(
      non_commercial_organizations_info,
      (item) => item.kind
    );

    return {
      ...data,
      sources_of_financing: sources_of_financing?.map(
        mapDataToMultiValues(this.sourcesOfFinancing)
      ),
      types_of_free_consultations: types_of_free_consultations?.map(
        mapDataToMultiValues(this.typesOfFreeConsultations)
      ),
      service_delivery_formats: service_delivery_formats?.map(
        mapDataToMultiValues(this.serviceDeliveryFormats)
      ),
      preschool_non_commercial_organizations_info: grouped.PRESCHOOL || [],
      general_non_commercial_organizations_info: grouped.GENERAL || [],
      other_non_commercial_organizations_info: grouped.OTHER || [],
      information_distribution_protocols:
        information_distribution_protocols?.map(mapFilePropertyToField) || [],
      approved_roadmap: approved_roadmap
        ? [mapFilePropertyToField(approved_roadmap)]
        : [],
      list_of_service_regulations:
        list_of_service_regulations?.map(mapFilePropertyToField) || []
    };
  };

  static fromRegionalForm = ({
    sources_of_financing,
    types_of_free_consultations,
    service_delivery_formats,
    preschool_non_commercial_organizations_info,
    general_non_commercial_organizations_info,
    other_non_commercial_organizations_info,
    information_distribution_protocols,
    approved_roadmap,
    list_of_service_regulations,
    approved_mediaplan,
    ...data
  }) => {
    const fields = {
      sources_of_financing: sources_of_financing?.map(
        mapMultiValuesToEnumArray
      ),
      types_of_free_consultations: types_of_free_consultations?.map(
        mapMultiValuesToEnumArray
      ),
      service_delivery_formats: service_delivery_formats?.map(
        mapMultiValuesToEnumArray
      ),
      non_commercial_organizations_info: [
        ...preschool_non_commercial_organizations_info.map(
          mapNonCommercialOrganizationsInfo(this.IntelligenceType.PRESCHOOL)
        ),
        ...general_non_commercial_organizations_info.map(
          mapNonCommercialOrganizationsInfo(this.IntelligenceType.GENERAL)
        ),
        ...other_non_commercial_organizations_info.map(
          mapNonCommercialOrganizationsInfo(this.IntelligenceType.OTHER)
        )
      ],
      information_distribution_protocols: information_distribution_protocols.map(
        mapFileToProperty
      ),
      approved_roadmap: approved_roadmap.map(mapFileToProperty)[0],
      list_of_service_regulations: list_of_service_regulations.map(
        mapFileStatusToProperty
      )
    };

    for (let key of Object.keys(data)) {
      if (numberCommonPart.some((field) => field === key)) {
        const value = parseInt(data[key]);
        fields[key] = Number.isNaN(value) ? undefined : value;
      } else {
        fields[key] = data[key];
      }
    }

    return fields;
  };

  static filterCreateRegionalFields(data) {
    return pick(data, regionalFields);
  }

  static filterUpdateRegionalFields(data) {
    return pick(data, ['id', ...regionalFields]);
  }

  static getRegionalUrl(data) {
    return '/parentalAssistanceGuidelines/' + data.id;
  }

  static toOrganizationalForm = ({
    approved_mediaplan,
    registration_methods,
    official_site_info_files,
    service_delivery_formats,
    list_of_local_service_regulations,
    information_distribution_protocols,
    list_of_regional_service_regulations,
    ...data
  }) => {
    return {
      ...data,
      service_delivery_formats: service_delivery_formats?.map(
        mapDataToMultiValues(this.serviceDeliveryFormats)
      ),
      registration_methods: registration_methods?.map(
        mapDataToMultiValues(this.registrationMethods)
      ),
      list_of_local_service_regulations:
        list_of_local_service_regulations?.map(mapFilePropertyToField) || [],
      list_of_regional_service_regulations:
        list_of_regional_service_regulations?.map(mapFilePropertyToField) || [],
      approved_mediaplan: approved_mediaplan
        ? [mapFilePropertyToField(approved_mediaplan)]
        : [],
      information_distribution_protocols:
        information_distribution_protocols?.map(mapFilePropertyToField) || [],
      official_site_info_files:
        official_site_info_files?.map(mapFileToProperty) || []
    };
  };

  static fromOrganizationalForm = ({
    approved_mediaplan,
    registration_methods,
    official_site_info_files,
    service_delivery_formats,
    list_of_local_service_regulations,
    information_distribution_protocols,
    list_of_regional_service_regulations,
    ...data
  }) => {
    const fields = {
      service_delivery_formats: service_delivery_formats?.map(
        mapMultiValuesToEnumArray
      ),
      registration_methods: registration_methods?.map(
        mapMultiValuesToEnumArray
      ),
      list_of_local_service_regulations: list_of_local_service_regulations.map(
        mapFileStatusToProperty
      ),
      list_of_regional_service_regulations: list_of_regional_service_regulations.map(
        mapFileStatusToProperty
      ),
      approved_mediaplan: approved_mediaplan.map(mapFileToProperty)[0],
      information_distribution_protocols: information_distribution_protocols?.map(
        mapFileToProperty
      ),
      official_site_info_files: official_site_info_files.map(mapFileToProperty)
    };

    for (let key of Object.keys(data)) {
      if (
        numberCommonPart.some((field) => field === key) ||
        organizationalNumberFields.some((field) => field === key)
      ) {
        const value = parseInt(data[key]);
        fields[key] = Number.isNaN(value) ? undefined : value;
      } else {
        fields[key] = data[key];
      }
    }
    return fields;
  };

  static filterCreateOrganizationalFields(data) {
    return pick(data, organizationalFields);
  }

  static filterUpdateOrganizationalFields(data) {
    return pick(data, ['id', ...organizationalFields]);
  }

  static getOrganizationalUrl(data) {
    return '/nkoGuidelines/' + data.id;
  }

  static fromNonCommercialOrganizationForm = (data) => {
    return {
      ...data,
      fact_addresses: [
        {
          address: data.address
        }
      ]
    };
  };

  static filterCreateNonCommercialOrganizationFields(data) {
    return pick(data, nonCommercialOrganizationFields);
  }
}
