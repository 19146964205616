import gql from 'graphql-tag';
import { FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT } from './subsidiaryEntity';

export const QUERY_SUBSIDIARY_ENTITIES = gql`
  query subsidiaryEntities($input: SubsidiaryEntitiesPageInput) {
    subsidiaryEntitiesPage(input: $input) {
      header {
        hasNext
        onePage
        page
        totalCount
      }
      list {
        ...subsidiaryEntityForSelect
        owner_roadmap_id
        base_organization_id
        base_organization_name
        year_of_creation
        name
        index
        region_string
        locality
        street
        house
        building
        inn
        kpp
        form_of_incorporation
        name
        code
        version
        is_approved
        supervisor {
          id
          surname
          first_name
          patronymic
          birthday
          emails
          phones
        }
        region {
          id
          name
        }
        owner_roadmap {
          id
          name
          name_full
          roadmap_years {
            year
            result {
              id
              owner_task {
                id
                federal_project {
                  id
                  code
                  name
                  name_full
                }
              }
            }
          }
        }
        se_type_id
        address
        region_id
      }
    }
  }

  ${FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT}
`;

export const QUERY_SUBSIDIARY_ENTITIES_FOR_FILTER = gql`
  query subsidiaryEntitiesForFilter($input: SubsidiaryEntitiesPageInput) {
    subsidiaryEntitiesPage(input: $input) {
      list {
        ...subsidiaryEntityForSelect
        inn
        kpp
        base_organization_name
        se_type {
          id
          name_full
        }
      }
    }
  }

  ${FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT}
`;

export const QUERY_UNFILLED_CLASSES_SCHEDULE_SUBSIDIARY_ENTITIES = gql`
  query unfilledClassesScheduleSubsidiaryEntities(
    $filter: UnfilledClassesScheduleSubsidiaryEntities!
  ) {
    unfilledClassesScheduleSubsidiaryEntities(filter: $filter) {
      ...subsidiaryEntityForSelect
      base_organization {
        bs_address
      }
    }
  }

  ${FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT}
`;

export const QUERY_GET_SUBSIDIARY_ENTITY_TYPES = gql`
  query getSubsidiaryEntityTypes($filter: SeTypeFilterInput) {
    seTypes(filter: $filter) {
      id
      code
      name
      name_full
      photo_categories {
        id
        name
        name_full
        code
        roadmap_events {
          id
        }
      }
    }
  }
`;

export const QUERY_SUBSIDIARY_ENTITIES_FOR_MONITORING = gql`
  query subsidiaryEntitiesForSubsidiaryEntityMonitoring(
    $input: SubsidiaryEntityMonitoringSubsidiaryEntitiesSearch!
  ) {
    subsidiaryEntitiesForSubsidiaryEntityMonitoring(args: $input) {
      ...subsidiaryEntityForSelect
      base_organization_name
      inn
      kpp
      year_of_creation
    }
  }

  ${FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT}
`;

export const QUERY_SUBSIDIARY_ENTITIES_AVAILABLE_FOR_CONTEST_DOCS_MONITORING = gql`
  query subsidiaryEntitiesAvailableForTenderDocumentationForSS(
    $input: TenderDocumentationForSSSubsidiaryEntitiesSearch!
  ) {
    subsidiaryEntitiesAvailableForTenderDocumentationForSS(args: $input) {
      ...subsidiaryEntityForSelect
      base_organization_name
      inn
      kpp
      year_of_creation
      owner_roadmap {
        id
        name
        name_full
      }
    }
  }

  ${FRAGMENT_SUBSIDIARY_ENTITY_FOR_SELECT}
`;
