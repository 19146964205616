import { EMDASH, QUARTERSP } from './constants';

export function formatNumber(number, decimals = 2, hideTrailingZeroes = false) {
  if (number === null || number === undefined) return EMDASH;
  const withTrailingZeroes = Number(number).toFixed(decimals);
  const baseNumber = hideTrailingZeroes
    ? String(parseFloat(withTrailingZeroes))
    : withTrailingZeroes;
  const formattedNum = baseNumber
    .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1' + QUARTERSP)
    .replace('.', ',');
  if (formattedNum === 'NaN') {
    return EMDASH;
  }
  if (formattedNum === 'Infinity') {
    return EMDASH;
  }
  return formattedNum;
}

export function getIndexDecimals(index, value) {
  if (typeof index.decimals === 'function') {
    return index.decimals(value);
  }
  return index.decimals;
}

export function formatIndexValue(value, index) {
  return formatNumber(value, getIndexDecimals(index, value));
}

export function formatValueUnit(value, unit, decimals = 2) {
  return formatNumber(value, decimals) + declineUnit(value, unit);
}

export function declineUnit(number, unit) {
  if (Array.isArray(unit)) {
    return declinedTitle(number, unit);
  }
  return unit;
}

export function declinedTitle(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
}

export const formatPercent = (first, second = 1) => {
  return formatValueUnit((first / second) * 100, '%', 1);
};

const byteUnits = [
  ' B',
  ' KB',
  ' MB',
  ' GB',
  ' TB',
  ' PB',
  ' EB',
  ' ZB',
  ' YB'
];

export function formatFileSize(fileSizeInBytes) {
  let i = 0;
  while (fileSizeInBytes > 1024 && i < byteUnits.length - 1) {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  }
  return formatValueUnit(Math.max(fileSizeInBytes, 0.1), byteUnits[i], 1);
}
