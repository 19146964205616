import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { EMDASH } from '../../../../utils/constants';
import Loader from '../../../Loader/Loader';
import s from './TableRow.module.scss';

const ChangeWatcher = ({ property, onChange }) => {
  useEffect(() => {
    onChange && onChange(property);
  }, [onChange, property]);
  return null;
};

export const TableRow = ({
  dataItem,
  onSubmit,
  isEditMode,
  gridTemplateColumns,
  onLineClick,
  onCancelEditRow,
  onDoubleClick,
  onChangeDirty,
  validationSchema,
  columns,
  getActions,
  dataItemKey,
  checkItemEditAccess,
  readonly
}) => {
  const doubleClickHandler = useCallback(() => {
    onDoubleClick &&
      !readonly &&
      (!checkItemEditAccess || checkItemEditAccess(dataItem)) &&
      onDoubleClick(dataItem.id);
  }, [checkItemEditAccess, dataItem, onDoubleClick, readonly]);
  const isClickable = !!onLineClick;
  const submitHandler = async (row) => {
    // желательно чтобы вернулся промис,
    // но если функция синхронная, то ее тоже обработаем
    const promise = onSubmit(row);
    if (promise?.then) {
      return promise.then(() => {
        onChangeDirty(false);
        onCancelEditRow();
      });
    } else {
      onCancelEditRow();
      return promise;
    }
  };

  return isEditMode ? (
    <Formik
      initialValues={dataItem}
      onSubmit={submitHandler}
      validationSchema={validationSchema}
    >
      {({ dirty, isSubmitting, values }) => {
        return (
          <>
            <Form className={s.tableForm}>
              <ChangeWatcher property={dirty} onChange={onChangeDirty} />
              <div
                className={classNames(s.tableRow, '_no-border')}
                style={{
                  gridTemplateColumns
                }}
              >
                {columns &&
                  columns.map((columnItem, columnItemKey) => {
                    return (
                      <div
                        className={s.tableCell}
                        key={`column-${columnItemKey}-${dataItem.id}`}
                      >
                        {/* render in edit mode call renderEditCell */}
                        {columnItem.renderEditCell &&
                          (columnItem.renderEditCell(
                            dataItem,
                            dataItemKey,
                            values
                          ) ??
                            EMDASH)}
                        {/* render without edit mode */}
                        {!columnItem.renderEditCell &&
                          columnItem.renderCell &&
                          (columnItem.renderCell(
                            dataItem,
                            dataItemKey,
                            values
                          ) ??
                            EMDASH)}
                      </div>
                    );
                  })}
                {!!getActions && <div className={s.tableActionCell} />}
              </div>

              <div className={s.tableFormActions}>
                <Button
                  variant={'text'}
                  type={'submit'}
                  color={'secondary'}
                  className={s.tableFormButton}
                  disabled={isSubmitting}
                >
                  Сохранить
                </Button>
                <Button
                  variant={'text'}
                  type={'button'}
                  onClick={onCancelEditRow}
                  color={'default'}
                  className={s.tableFormButton}
                >
                  Отменить
                </Button>
              </div>
            </Form>
            {isSubmitting && (
              <Loader
                centered
                style={{ position: 'absolute', top: '50%', left: '50%' }}
              />
            )}
          </>
        );
      }}
    </Formik>
  ) : (
    <div
      onClick={() => isClickable && onLineClick(dataItem)}
      className={classNames(s.tableRow, {
        [s.isClickable]: isClickable
      })}
      onDoubleClick={doubleClickHandler}
      style={{
        gridTemplateColumns
      }}
    >
      {columns &&
        columns.map((columnItem, columnItemKey) => {
          return (
            <div
              className={s.tableCell}
              key={`column-${columnItemKey}-${dataItem.id}`}
            >
              {/* render without edit mode */}
              {columnItem.renderCell &&
                (columnItem.renderCell(dataItem, dataItemKey) ?? EMDASH)}
            </div>
          );
        })}

      {!!getActions && (
        <div className={s.tableActionCell}>
          {!readonly && getActions(dataItem)}
        </div>
      )}
    </div>
  );
};

TableRow.propTypes = {
  dataItem: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
  gridTemplateColumns: PropTypes.string,
  onLineClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  // Устанавливает есть ли измененные данные в форме редактирования
  onChangeDirty: PropTypes.func,
  onSubmit: PropTypes.func,
  // Отмена редактирования
  onCancelEditRow: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      renderCell: PropTypes.func,
      renderEditCell: PropTypes.func,
      // ширина колонки записывается в `grid-template-columns`
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  checkItemEditAccess: PropTypes.func,
  readonly: PropTypes.bool
};
