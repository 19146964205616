import gql from 'graphql-tag';
import { FRAGMENT_PAGINATION_HEADER } from './common';

export const FRAGMENT_REGIONAL_PROJECT = gql`
  fragment RegionalProject on RegionalProjectType {
    id
    name
    code
    federal_project_id
    region_id
    implementation_years
  }
`;

export const QUERY_GET_REGIONAL_PROJECTS = gql`
  query regionalProjectsWithCounts(
    $input: RegionalProjectPageInput!
    $delivery_years: [Int!]
  ) {
    regionalProjects(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        ...RegionalProject
        count_purchases(delivery_years: $delivery_years)
      }
    }
  }
  ${FRAGMENT_REGIONAL_PROJECT}
  ${FRAGMENT_PAGINATION_HEADER}
`;

export const QUERY_GET_REGIONAL_PROJECTS_LIST = gql`
  query regionalProjectsList($input: RegionalProjectPageInput!) {
    regionalProjects(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        ...RegionalProject
        federal_project {
          id
          name_full
        }
      }
    }
  }
  ${FRAGMENT_REGIONAL_PROJECT}
  ${FRAGMENT_PAGINATION_HEADER}
`;

export const QUERY_GET_REGIONAL_PROJECT = gql`
  query regionalProject($id: ID!) {
    regionalProject(id: $id) {
      ...RegionalProject
      count_purchases
      region {
        id
        name
      }
    }
  }
  ${FRAGMENT_REGIONAL_PROJECT}
`;
