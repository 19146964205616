import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { infralistRoutes } from '../routes/infralistRoutes';

export const versionRouteRelates = [
  {
    versionRote: infralistRoutes.INFRALIST_VERSION,
    baseRoute: infralistRoutes.INFRALIST
  },
  {
    versionRote: infralistRoutes.DIRECTION_VERSION,
    baseRoute: infralistRoutes.DIRECTION
  },
  {
    versionRote: infralistRoutes.SECTION_VERSION,
    baseRoute: infralistRoutes.SECTION
  }
];

// поиск пути к странице с версией и без
export const useVersionRouteRelates = () => {
  const currentPageUrl = window.location.pathname;

  const currentVersionRouteRelates = useMemo(() => {
    let currentRoutes;
    versionRouteRelates.forEach((currentRouteRelates) => {
      const matchVersionRoute = matchPath(currentPageUrl, {
        path: currentRouteRelates.versionRote,
        exact: true
      });
      const matchBaseRoute = matchPath(currentPageUrl, {
        path: currentRouteRelates.baseRoute,
        exact: true
      });

      if (matchVersionRoute || matchBaseRoute) {
        currentRoutes = {
          versionRote: currentRouteRelates.versionRote,
          baseRoute: currentRouteRelates.baseRoute,
          params: matchVersionRoute?.params ?? matchBaseRoute?.params,
          isBaseRouteMatches: !!matchBaseRoute,
          isVersionRouteMatches: !!matchVersionRoute
        };
      }
    });

    return currentRoutes;
  }, [currentPageUrl]);

  return {
    versionRote: currentVersionRouteRelates?.versionRote,
    baseRoute: currentVersionRouteRelates?.baseRoute,
    params: currentVersionRouteRelates?.params,
    isBaseRouteMatches: currentVersionRouteRelates?.isBaseRouteMatches,
    isVersionRouteMatches: currentVersionRouteRelates?.isVersionRouteMatches
  };
};
