import React, { useMemo } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PageTitle from '../../common/PageTitle/PageTitle';
import Table from '../../common/Table/Table';
import { useIndicatorForecasts } from '../../store/indicatorForecasts/useIndicatorForecasts';
import { useCurrentUser } from '../../store/useCurrentUser';
import Menu from '../../common/Menu/Menu';
import { IndicatorForecast } from '../../store/indicatorForecasts/IndicatorForecast';

const indicatorForecastsColumns = [
  { label: '№', key: 'order', isThin: true },
  {
    label: IndicatorForecast.parts.federal_project.name,
    key: 'federal_project.name_full',
    isWide: true,
    isBold: true
  },
  {
    label: IndicatorForecast.parts.indicator.name,
    key: 'indicator.name_full',
    isWide: true
  },
  {
    label: IndicatorForecast.parts.region.name,
    key: 'region.name',
    isWide: true
  },
  {
    label: IndicatorForecast.parts.status.name,
    key: ({ status }) => IndicatorForecast.status.getName(status),
    isThin: true
  }
];

export default function IndicatorForecastsPage({ match, history, location }) {
  const user = useCurrentUser();

  const indicatorForecastsQuery = useIndicatorForecasts({
    region_ids: user?.region?.id
  });
  const indicatorForecasts = useMemo(
    () =>
      indicatorForecastsQuery.state.data?.map(
        (indicatorForecast, iIndicatorForecast) => ({
          ...indicatorForecast,
          order: iIndicatorForecast + 1
        })
      ),
    [indicatorForecastsQuery.state.data]
  );

  const menuComponent = useMemo(
    () =>
      function MenuComponent({ row }) {
        const menu = [];

        const canEdit = IndicatorForecast.canEdit(user, row);

        if (canEdit) {
          menu.push({
            title: 'Редактировать',
            onClick: () => history.push(`${match.url}/${row.id}/edit`)
          });
        }

        if (menu.length === 0) {
          return false;
        }

        return <Menu iconColor="default" icon={MoreHorizIcon} options={menu} />;
      },
    [history, match, user]
  );

  return (
    <>
      <PageTitle withBackBtn={false} title={IndicatorForecast.title.plural} />
      <Table
        columns={indicatorForecastsColumns}
        isLoading={indicatorForecastsQuery.check.spinner}
        data={indicatorForecasts}
        renderNoData={() => <h3>Нет прогнозов по выбранным фильтрам</h3>}
        rowRedirectRoute={`${match.url}/`}
        menuComponent={menuComponent}
      />
    </>
  );
}
