import React from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { removeLastPathItem } from '../../../../utils/url';
import { useOtherEditor } from '../../../../common/Form/useOtherEditor';
import {
  useRetriableExpensesDirectionsRich,
  useRPIndicatorGoals
} from '../../../../store/expensesDirection/useExpensesDirections';
import { useRetriableRegionalProjects } from '../../../../store/regionalProject/useRegionalProjects';
import { RpImplementationForecast } from '../../../../store/rpImplementationForecast/RpImplementationForecast';
import { useRoadmaps } from '../../../../store/roadmap/useRoadmaps';
import { useRpImplementationForecast } from '../../../../store/rpImplementationForecast/useRpImplementationForecast';
import { RP_IMPLEMENTATION_FORECAST_STORE } from '../../../../store/names';
import { useResultFederals } from '../../../../store/rpImplementationForecast/useResultFederals';
import { createFormSubmitter } from '../../../../common/Form/createFormSubmitter';
import { multiQueryLoader } from '../../../../common/QueryLoader';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { RpImplementationForecastEditForm } from './RpImplementationForecastEditForm';

export default function RpImplementationForecastEditPage({ match, history }) {
  const rpImplementationForecastStore = useContextStore(
    RP_IMPLEMENTATION_FORECAST_STORE
  );
  const backLink = removeLastPathItem(match.url);
  const { rpImplementationForecastId } = match.params;

  const editor = useOtherEditor(
    'rpImplementationForecast',
    rpImplementationForecastId
  );

  const rpImplementationForecastQuery = useRpImplementationForecast(
    rpImplementationForecastId,
    true
  );
  const rpImplementationForecast = rpImplementationForecastQuery.state.data;

  const region_id = rpImplementationForecast?.region?.id;

  const projectsQuery = useRetriableRegionalProjects({
    region_ids: region_id
  });
  const projects = projectsQuery.state.data?.data;

  const roadmapsQuery = useRoadmaps({
    region_id: region_id
  });
  const roadmaps = roadmapsQuery.state.data?.data;

  const expensesDirectionsQuery = useRetriableExpensesDirectionsRich({
    region_ids: region_id,
    exists_in_mbt_by_subjects: true
  });
  const expensesDirections = expensesDirectionsQuery.state.data;

  const mbtFinancesGroupedQuery = RpImplementationForecast.useMbtFinancesGrouped(
    { region_id }
  );
  const mbtFinances = mbtFinancesGroupedQuery.state.data;

  const indicatorsQuery = useRPIndicatorGoals(region_id, 2020);
  const indicators = indicatorsQuery.state.data;

  const resultFederalsQuery = useResultFederals({});
  const resultFederals = resultFederalsQuery.state.data;

  return (
    <>
      <HeadTitle
        type="Прогноз Реализации РП"
        pageType={HeadTitle.PageType.edit}
      />
      <h2>Редактирование прогноза реализации РП</h2>
      {multiQueryLoader([
        rpImplementationForecastQuery,
        projectsQuery,
        roadmapsQuery,
        indicatorsQuery,
        mbtFinancesGroupedQuery,
        expensesDirectionsQuery,
        resultFederalsQuery
      ]) || (
        <Formik
          initialValues={RpImplementationForecast.toForm(
            rpImplementationForecast,
            projects,
            roadmaps,
            expensesDirections,
            indicators
          )}
          onSubmit={createFormSubmitter({
            history,
            dataType: RpImplementationForecast,
            update: rpImplementationForecastStore.updateRpImplementationForecast
          })}
          render={(formikProps) => (
            <RpImplementationForecastEditForm
              {...formikProps}
              onBackClick={() => history.goBack(backLink)}
              editor={editor}
              projects={projects}
              mbtFinances={mbtFinances}
              resultFederals={resultFederals}
            />
          )}
        />
      )}
    </>
  );
}
