import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_REGIONAL_ROADMAP_EVENT } from '../../graphql/queries/roadmapEvents';

const regionalRoadmapEventQueryOptions = {
  query: QUERY_GET_REGIONAL_ROADMAP_EVENT,
  mapData: (data) => {
    const regionalRoadmapEvent = data.regionalRoadmapEvent;
    const roadmapEvent = regionalRoadmapEvent.roadmap_event;
    const roadmap = roadmapEvent.roadmap;

    const federalResult = roadmapEvent.roadmap.roadmap_years
      .filter((x) => x.year === roadmapEvent.year)
      .shift()?.result;

    return {
      ...regionalRoadmapEvent,
      roadmap_event: {
        ...roadmapEvent,
        roadmap: {
          ...roadmap,
          result: federalResult
        }
      }
    };
  },
  skipQuery: skipIfNull(null)
};

export function useRegionalRoadmapEvent(id, networkOnly = false) {
  return useRetriableGraphqlVars({
    queryOptions: regionalRoadmapEventQueryOptions,
    variableCreator: [
      () =>
        id
          ? {
              id
            }
          : null,
      [id]
    ],
    options: {
      apolloOptions: {
        fetchPolicy: networkOnly ? 'network-only' : 'cache-first'
      }
    }
  });
}
