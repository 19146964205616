import gql from 'graphql-tag';

export const MUTATION_UPDATE_OR_CREATE_PARENTAL_ASSISTANCE_GUIDELINES_ORGANIZATIONAL = gql`
  mutation updateOrCreateParentalAssistanceGuidelinesOrganizational(
    $input: ParentalAssistanceGuidelinesOrganizationalInput!
  ) {
    result: updateOrCreateParentalAssistanceGuidelinesOrganizational(
      input: $input
    ) {
      id
    }
  }
`;

export const MUTATION_CREATE_GUIDELINE_REPORT_FILE_TOKEN = gql`
  mutation createParentalAssistanceGuidelinesRegionalReportOneTimeToken {
    createParentalAssistanceGuidelinesRegionalReportOneTimeToken
  }
`;
