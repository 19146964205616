import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_USER, QUERY_GET_USERS } from '../../graphql/queries/users';

const usersQueryOptions = {
  query: QUERY_GET_USERS,
  mapData: (result) => result.users,
  skipQuery: skipIfNull(null)
};

const userQueryOptions = {
  query: QUERY_GET_USER,
  mapData: (result) => result.user,
  skipQuery: skipIfNull(null)
};

export function useUsers(filter, paginationParams) {
  return useRetriableGraphqlVars({
    queryOptions: usersQueryOptions,
    variableCreator: [
      () =>
        filter
          ? {
              input: {
                filter: filter,
                pagination: paginationParams
              }
            }
          : null,
      [filter, paginationParams]
    ]
  });
}

export function useUser(id, networkOnly = false) {
  return useRetriableGraphqlVars({
    queryOptions: userQueryOptions,
    variableCreator: [
      () =>
        id
          ? {
              id
            }
          : null,
      [id]
    ],
    options: {
      apolloOptions: {
        fetchPolicy: networkOnly ? 'network-only' : 'cache-first'
      }
    }
  });
}
