import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../../common/useRetriableGraphqlVars';
import { QUERY_VERSION_DIRECTION_SECTIONS } from '../../../graphql/queries/infralist';

const infralistDirectionsQueryOptions = {
  query: QUERY_VERSION_DIRECTION_SECTIONS,
  mapData: (result) =>
    result.infralistVersionDirectionSections.map((section) => ({
      ...section,
      status: section.status_by_version,
      checkStatus: section.check_status_by_version,
      sectionSum: section.section_sum_by_version,
      checkedUsers: section.checked_users_by_version,
      statusChangeTime: section.status_change_time_by_version,
      approvement_status: section.approvement_status_by_version
    })),
  skipQuery: skipIfNull(null)
};

export function useVersionDirectionSections({ versionId, directionId }) {
  const response = useRetriableGraphql({
    queryOptions: infralistDirectionsQueryOptions,
    variables: {
      version_id: versionId,
      direction_id: directionId
    }
  });

  const data = response.state.data;

  return {
    sections: data,
    spinner: response.check.spinner
  };
}
