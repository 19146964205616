import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import { Signal } from '../../../common/Inputs/Signals';
import { DeleteButton } from '../../../common/DeleteButton';
import { CancelButton } from '../../../common/CancelButton';
import { DocumentFile } from './DocumentFile';
import s from './EventPage.module.scss';

export const DocumentUploadFileStatus = ({
  documentId,
  document,
  onDelete,
  onCancelUpload,
  canUploadDocuments,
  disabled,
  ...props
}) => {
  const errorMessage = document?.uploadingFile?.errorMessage;

  return (
    <div>
      {(document.uploadingFile &&
        ((document.uploadingFile.error && (
          <>
            <Signal>{errorMessage}</Signal>
            <DeleteButton onClick={onDelete} />
          </>
        )) ||
          (document.uploadingFile.uploading && (
            <div className={s.wrapper}>
              <LinearProgress
                className={s.progressBar}
                variant="determinate"
                value={document.uploadingFile.progress}
              />
              <CancelButton onClick={onCancelUpload} />
            </div>
          )))) || (
        <div className={s.wrapper}>
          <DocumentFile
            documentId={documentId}
            document={document}
            disabled={disabled}
            {...props}
          />
          {canUploadDocuments && (
            <DeleteButton disabled={disabled} onClick={onDelete} />
          )}
        </div>
      )}
    </div>
  );
};
