import React from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { ParentalAssistanceGuideline } from '../../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import { removeLastPathItem } from '../../../../utils/url';
import { ParentalAssistanceGuidelinesRegionalEditForm } from '../edit/ParentalAssistanceGuidelinesRegionalEditForm';
import { handleFormSubmission } from '../../../../utils/data/form';
import { STORE_PARENTAL_ASSISTANCE_GUIDELINE } from '../../../../store/names';
import { useRegions } from '../../../../store/subsidiaryEntity/useSubsidiaryEntity';
import { ParentalAssistanceGuidelinesRegionalValidationSchema } from '../../../../utils/data/validation';
import { useCurrentUser } from '../../../../store/useCurrentUser';

export default function ParentalAssistanceGuidelinesRegionalCreatePage({
  history,
  match
}) {
  const user = useCurrentUser();
  const regionId = user?.region?.id;
  const backLink = removeLastPathItem(match.url);
  const parentalAssistanceGuideline = useContextStore(
    STORE_PARENTAL_ASSISTANCE_GUIDELINE
  );

  const regionQuery = useRegions(regionId);
  const region = regionQuery.state.data?.[0];

  const initialValues = {
    region_id: regionId,
    non_commercial_organizations_info: [],
    amount_of_contract_specialists_2019: '',
    amount_of_contract_specialists_2020: '',
    amount_of_lended_another_format_services_2019: '',
    amount_of_lended_another_format_services_2020: '',
    amount_of_lended_f2f_services_2019: '',
    amount_of_lended_f2f_services_2020: '',
    amount_of_lended_field_services_2019: '',
    amount_of_lended_field_services_2020: '',
    amount_of_lended_free_services_2019: '',
    amount_of_lended_free_services_2020: '',
    amount_of_lended_paid_services_2019: '',
    amount_of_lended_paid_services_2020: '',
    amount_of_lended_remote_services_2019: '',
    amount_of_lended_remote_services_2020: '',
    amount_of_multipletimes_service_recipients_2019: '',
    amount_of_multipletimes_service_recipients_2020: '',
    amount_of_onetime_service_recipients_2019: '',
    amount_of_onetime_service_recipients_2020: '',
    amount_of_prepared_specialists_2019: '',
    amount_of_prepared_specialists_2020: '',
    amount_of_service_rejections_2019: '',
    amount_of_service_rejections_2020: '',
    amount_of_specialists_in_state_2019: '',
    amount_of_specialists_in_state_2020: '',
    date_of_service_start: null,
    free_service_recipient_groups: '',
    information_distribution_description: '',
    information_is_being_distributed: undefined,
    maximum_waiting_time: '',
    maximum_waiting_time_exists: undefined,
    other_service_delivery_formats: '',
    other_sources_of_financing: '',
    paid_service_delivery_description: '',
    paid_service_possibility: undefined,
    possible_consultation_topic_choice: undefined,
    possible_format_choice: undefined,
    possible_nwh_services_provisioning: undefined,
    possible_specialist_choice: undefined,
    possible_time_choice: undefined,
    possible_weekend_services_provisioning: undefined,
    problems_in_new_service_introduction_description: '',
    problems_in_new_service_introduction_exist: undefined,
    problems_in_new_service_introduction_suggestions: '',
    service_delivery_formats: [],
    service_delivery_mechanism: '',
    service_rejections_description_2019: '',
    service_rejections_description_2020: '',
    services_quality_control_methodology: '',
    services_quality_is_being_controlled: undefined,
    sources_of_financing: [],
    best_practices_of_services_provision: '',
    new_service_implementation_roadmap_exist: undefined,
    special_categories_of_legal_representatives: '',
    special_categories_of_legal_representatives_description: '',
    types_of_free_consultations: [],
    status: 'DRAFT'
  };

  return (
    <>
      <HeadTitle
        type="Перечень вопросов по апробации методических рекомендаций"
        pageType={HeadTitle.PageType.edit}
      />
      <h2>
        Редактирование перечня вопросов по апробации методических рекомендаций
      </h2>
      <Formik
        validationSchema={ParentalAssistanceGuidelinesRegionalValidationSchema}
        initialValues={ParentalAssistanceGuideline.toRegionalForm(
          initialValues
        )}
        onSubmit={(values, actions) => {
          const form = ParentalAssistanceGuideline.fromRegionalForm(values);
          handleFormSubmission(
            parentalAssistanceGuideline.createParentalAssistanceGuidelinesRegional(
              form
            ),
            actions,
            (result) =>
              history.goBack(
                ParentalAssistanceGuideline.getRegionalUrl(result.data.result)
              )
          );
        }}
        render={(formikProps) => (
          <ParentalAssistanceGuidelinesRegionalEditForm
            {...formikProps}
            onBackClick={() => history.goBack(backLink)}
            region={region}
          />
        )}
      />
    </>
  );
}
