import React from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { removeLastPathItem } from '../../../../utils/url';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { ParentalAssistanceGuideline } from '../../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import { useParentalAssistanceGuidelineOrganizational } from '../../../../store/parentalAssistanceGuideline/useParentalAssistanceGuidelineOrganizational';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import { STORE_PARENTAL_ASSISTANCE_GUIDELINE } from '../../../../store/names';
import { handleFormSubmission } from '../../../../utils/data/form';
import { ParentalAssistanceGuidelinesOrganizationalValidationSchema } from '../../../../utils/data/validation';
import ParentalAssistanceGuidelinesOrganizationalEditForm from './ParentalAssistanceGuidelinesOrganizationalEditForm';

export default function ParentalAssistanceGuidelinesOrganizationalEditPage({
  history,
  match
}) {
  const parentalAssistanceGuideline = useContextStore(
    STORE_PARENTAL_ASSISTANCE_GUIDELINE
  );
  const backLink = removeLastPathItem(match.url);
  const { guidelineId } = match.params;

  const parentalAssistanceGuidelineOrganizationalQuery = useParentalAssistanceGuidelineOrganizational(
    guidelineId
  );
  const parentalAssistanceGuidelineOrganizational =
    parentalAssistanceGuidelineOrganizationalQuery.state.data;

  const nonCommercialOrganization =
    parentalAssistanceGuidelineOrganizational?.non_commercial_organization;
  return (
    <>
      <HeadTitle
        type="Перечень вопросов по апробации методических рекомендаций"
        pageType={HeadTitle.PageType.edit}
      />
      <h2>
        Редактирование перечня вопросов по апробации методических рекомендаций
      </h2>
      {queryLoaderWithExistenceCheck(
        parentalAssistanceGuidelineOrganizationalQuery
      ) || (
        <Formik
          validationSchema={
            ParentalAssistanceGuidelinesOrganizationalValidationSchema
          }
          initialValues={ParentalAssistanceGuideline.toOrganizationalForm(
            parentalAssistanceGuidelineOrganizational
          )}
          onSubmit={(values, actions) => {
            const form = ParentalAssistanceGuideline.fromOrganizationalForm(
              values
            );
            handleFormSubmission(
              parentalAssistanceGuideline.updateParentalAssistanceGuidelinesOrganizational(
                form
              ),
              actions,
              (result) =>
                history.goBack(
                  ParentalAssistanceGuideline.getOrganizationalUrl(
                    result.data.result
                  )
                )
            );
          }}
          render={(formikProps) => (
            <ParentalAssistanceGuidelinesOrganizationalEditForm
              {...formikProps}
              onBackClick={() => history.goBack(backLink)}
              nonCommercialOrganization={nonCommercialOrganization}
            />
          )}
        />
      )}
    </>
  );
}
