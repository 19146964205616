import { clearApolloQueryCache } from '../../utils/apollo';
import {
  MUTATION_CREATE_USER,
  MUTATION_DELETE_USER,
  MUTATION_UPDATE_USER
} from '../../graphql/mutations/users';
import { User } from './User';

export class UserStore {
  constructor({ client }) {
    this.client = client;
  }

  queriesToClear = ['users', 'user', 'roles'];

  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  updateUser(data) {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_USER,
      variables: {
        input: User.filterUpdateFields(data)
      },
      refetchQueries: ['users'],
      update: this.clearCache
    });
  }

  createUser(data) {
    return this.client.mutate({
      mutation: MUTATION_CREATE_USER,
      variables: {
        input: User.filterCreateFields(data)
      },
      refetchQueries: ['users'],
      update: this.clearCache
    });
  }

  deleteUser = (data) => {
    return this.client.mutate({
      mutation: MUTATION_DELETE_USER,
      variables: {
        id: data
      },
      refetchQueries: ['users'],
      update: this.clearCache
    });
  };
}
