import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MaterialLink from '@material-ui/core/Link';
import { Link } from 'react-router-dom';

import SubTable from '../../../../common/SubTable/SubTable';
import { EditButton } from '../../../../common/Form/EditButton/EditButton';
import { PageActionsBar } from '../../../../common/PageActionsBar/PageActionsBar';
import { Individual } from '../../../../store/Individual';
import { EventDocuments } from '../../../regionalRoadmapEvents/regionalEvent/EventDocuments';
import {
  EVENT_TYPE_PURCHASES,
  EVENT_TYPE_SUBSIDIARY_ENTITIES
} from '../../../../store/roadmap/roadmapEvents';
import { createPreservedUrl } from '../../../../utils/links';
import { DateCell } from '../../../../common/TableCells';

export const Event = ({ match, event }) => {
  const canSeeSSLink = event.event_type === EVENT_TYPE_SUBSIDIARY_ENTITIES;
  const canSeePurchaseLink = event.event_type === EVENT_TYPE_PURCHASES;
  const roadmap = event.roadmap;

  const federalSlots = event?.roadmap_event_document_slots?.filter(
    (slot) => slot.federal
  );

  return (
    <>
      <PageActionsBar
        editButton={
          event.permissions?.edit && (
            <EditButton editLink={`${match.url}/edit`} />
          )
        }
      />
      <SubTable title="Общие сведения">
        <TableRow>
          <TableCell>Название мероприятия</TableCell>
          <TableCell>{event.name_full}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Результат</TableCell>
          <TableCell>{event.roadmap?.result?.name_full}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Год</TableCell>
          <TableCell>{event.year}</TableCell>
        </TableRow>
        {canSeeSSLink && (
          <TableRow>
            <TableCell colSpan={2}>
              <MaterialLink
                className="external"
                component={Link}
                to={createPreservedUrl(`/subsidiaryEntities`, {
                  owner_roadmap_id: roadmap.id,
                  year: event.year
                })}
              >
                Перейти к реестру субсидиарных сущностей
              </MaterialLink>
            </TableCell>
          </TableRow>
        )}
        {canSeePurchaseLink && (
          <TableRow>
            <TableCell colSpan={2}>
              <MaterialLink
                className="external"
                component={Link}
                to={createPreservedUrl(`/purchases`, {
                  expenses_direction_id: roadmap.result.expenses_direction.id,
                  year: event.year
                })}
              >
                Перейти к закупкам
              </MaterialLink>
            </TableCell>
          </TableRow>
        )}
      </SubTable>
      <SubTable>
        <TableRow>
          <TableCell>Плановый срок</TableCell>
          {event.plan_term ? (
            <DateCell value={event.plan_term} />
          ) : (
            <TableCell>{event.plan_roadmap?.periodicity_string}</TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Результат мероприятия</TableCell>
          <TableCell>{event.event_result}</TableCell>
        </TableRow>
      </SubTable>
      {event.responsibility_level_vo && (
        <SubTable
          title={
            'Ответственный за реализацию. Ведомственный проектный офис проекта "Образование"'
          }
        >
          <TableRow>
            <TableCell>ФИО</TableCell>
            <TableCell>
              {Individual.combineName(event.responsible_person)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>
              {
                <a
                  className="external"
                  href={'mailto:' + event.responsible_person?.emails?.[0]}
                >
                  {event.responsible_person?.emails?.[0]}
                </a>
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Номер мобильного телефона</TableCell>
            <TableCell>{event.responsible_person?.phones?.[0]}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Должность</TableCell>
            <TableCell>
              {event.responsible_person?.individual_data?.occupation}
            </TableCell>
          </TableRow>
        </SubTable>
      )}
      <EventDocuments
        slots={federalSlots}
        documents={event?.roadmap_event_documents}
        showSlots={event.display_document_slots}
        showDocumentStatus={false}
        title="Документы ведомственного проектного офиса"
      />
      <SubTable>
        <TableRow>
          <TableCell>Версия</TableCell>
          <TableCell>{event.version}</TableCell>
        </TableRow>
      </SubTable>
    </>
  );
};
