import gql from 'graphql-tag';

export const MUTATION_SET_EDITING_ITEM = gql`
  mutation setEditingItem($entity: String!) {
    setEditingItem(entity: $entity) {
      id
      name
      login
      email
    }
  }
`;
