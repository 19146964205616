import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { COMMENT_TYPES } from '../../../../store/infralist/commentEntities';
import { useDirectionComments } from '../../../../store/infralist/comments/direction/useDirectionComments';
import { useFetchDirectionFPOComments } from '../../../../store/infralist/comments/direction/useFetchDirectionFPOComments';
import { useFetchDirectionGeneralComments } from '../../../../store/infralist/comments/direction/useFetchDirectionGeneralComments';
import { useFetchDirectionRVPOComments } from '../../../../store/infralist/comments/direction/useFetchDirectionRVPOComments';
import { useCommentReadMutations } from '../../../../store/infralist/comments/useCommentReadMutations';
import { useMakeDecisionDirectionCommentaries } from '../../../../store/infralist/comments/useMakeDecisionDirectionCommentaries';
import { useDirection } from '../../../../store/infralist/useDirection';
import { useInfralist } from '../../../../store/infralist/useInfralist';
import { useMapToIdList } from '../../../../utils/hooks/useMapToIdList';
import { useExportInfralistComments } from '../../useExportInfralistComments';
import { CommentPage } from '../components/CommentPage/CommentPage';
import { DirectionInfo } from './DirectionInfo';

export const DirectionCommentsPage = () => {
  const { infralistId, directionId, type } = useParams();
  const location = useLocation();
  const client = useContextApolloClient();

  const infralist = useInfralist(infralistId);
  const infralistStatus = infralist.state.data?.infralist_status?.name;
  const fpoComments = useFetchDirectionFPOComments({
    directionId,
    infralistId
  });
  const rvpoComments = useFetchDirectionRVPOComments({
    directionId,
    infralistId
  });
  const generalComments = useFetchDirectionGeneralComments({
    directionId,
    infralistId
  });
  const direction = useDirection(directionId, infralistId);
  const {
    commentType,
    updateCommentary,
    createCommentary,
    deleteCommentary
  } = useDirectionComments({ infralistId, directionId, type });

  const {
    acceptComment,
    rejectComment
  } = useMakeDecisionDirectionCommentaries();

  const onAccept = ({ content, ids }) => {
    return acceptComment({ content, directionId, infralistId, ids });
  };

  const onReject = ({ content, ids }) => {
    return rejectComment({ content, ids, infralistId, directionId });
  };

  const exportCommentaries = useExportInfralistComments('direction', {
    infralistId,
    directionId
  });

  // обновляем данные при переходе с уведомления, чтобы отобразить актуальные комментарии
  useEffect(() => {
    if (location.state?.notificationId) {
      client.reFetchObservableQueries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, location.state?.notificationId]);

  // отправка событий что комментарии были прочитаны
  const { markDirectionCommentsAsRead } = useCommentReadMutations({
    infralistId
  });

  const fpoCommentsIds = useMapToIdList(fpoComments);
  useEffect(() => {
    if (fpoCommentsIds.length > 0 && type === COMMENT_TYPES.FPO) {
      markDirectionCommentsAsRead({ commentIds: fpoCommentsIds });
    }
  }, [fpoCommentsIds, markDirectionCommentsAsRead, type]);

  const rvpoCommentsIds = useMapToIdList(rvpoComments);
  useEffect(() => {
    if (rvpoCommentsIds.length > 0 && type === COMMENT_TYPES.RVPO) {
      markDirectionCommentsAsRead({ commentIds: rvpoCommentsIds });
    }
  }, [rvpoCommentsIds, markDirectionCommentsAsRead, type]);

  const generalCommentsIds = useMapToIdList(generalComments);
  useEffect(() => {
    if (generalCommentsIds.length > 0 && type === COMMENT_TYPES.GENERAL) {
      markDirectionCommentsAsRead({ commentIds: generalCommentsIds });
    }
  }, [generalCommentsIds, markDirectionCommentsAsRead, type]);

  return (
    <CommentPage
      generalComments={generalComments}
      fpoComments={fpoComments}
      rvpoComments={rvpoComments}
      infralistStatus={infralistStatus}
      backUrl={`/infralist/${infralistId}`}
      baseUrl={`/infralist-comments/${infralistId}/direction/${directionId}`}
      backUrlTitle={'Назад к списку направлений'}
      isEditEnabledByEventStatus={infralist.state.data?.editable}
      renderInfoContent={
        <DirectionInfo
          data={direction.state.data}
          infralistStatus={infralistStatus}
        />
      }
      createComment={createCommentary}
      deleteComment={deleteCommentary}
      updateComment={updateCommentary}
      commentType={commentType}
      rejectComment={onReject}
      acceptComment={onAccept}
      exportCommentaries={exportCommentaries}
    />
  );
};
