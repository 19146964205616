import React, { useCallback, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { stringifyUrl } from 'query-string';
import { useContextStore } from '@proscom/prostore-react';
import { toast } from 'react-toastify';
import { useCurrentUser } from '../../store/useCurrentUser';
import { useOtherEditor } from '../../common/Form/useOtherEditor';
import { useContestDocsMonitoringEntity } from '../../store/contestDocsMonitoring/useContestDocsMonitoringEntity';
import { useUrlQuery } from '../../common/useUrlQuery';
import { getCurrentYear } from '../../utils/data/date';
import { ContestDocsMonitoringEntity } from '../../store/contestDocsMonitoring/ContestDocsMonitoringEntity';
import {
  CONTEST_DOCS_MONITORING_STORE,
  STORE_FILE_TOKEN
} from '../../store/names';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../graphql/graphqlErrors';
import { useDeleteRow } from '../../common/Table/useDeleteRow';
import {
  ContestDocsMonitoringEntityApprovalValidationSchema,
  ContestDocsMonitoringEntityDraftValidationSchema,
  lazyValidation
} from '../../utils/data/validation';
import { useUserRole } from '../../store/role/useUserRole';
import { useGeneralConfirmationRow } from '../../common/Table/useGeneralConfirmationRow';
import { downloadRestFile } from '../../utils/AxiosHelpers';
import { SubsidiaryMonitoringEntity } from '../../store/subsidiaryMonitoring/SubsidiaryMonitoringEntity';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';
import PageTitle from '../../common/PageTitle/PageTitle';
import { ExportHistoryPopup } from '../../common/ExportHistoryPopup/ExportHistoryPopup';
import { QUERY_CONTEST_DOCS_MONITORING_EXPORT_HISTORY } from '../../graphql/queries/contestDocsMonitoring';
import { ContestDocsMonitoringForm } from './ContestDocsMonitoringForm/ContestDocsMonitoringForm';
import { useContestDocsExportDialog } from './ContestDocsExport/useContestDocsExportDialog';
import { contestDocsMonitoringPageTitle } from './ContestDocsMonitoringPage';

export default function ContestDocsMonitoringFormPage({
  match,
  history,
  location
}) {
  const { entityId } = match.params;
  const [query, changeQuery] = useUrlQuery(location, history);
  // const backLink = removeLastPathItem(match.url);
  const user = useCurrentUser();
  const { isRegionAdmin } = useUserRole(user);
  const [exportHistoryOpen, setExportHistoryOpen] = useState(false);

  const entityStore = useContextStore(CONTEST_DOCS_MONITORING_STORE);
  const tokenStore = useContextStore(STORE_FILE_TOKEN);

  const [isDeleting, deleteDialog, openDeleteDialog] = useDeleteRow(
    entityStore.delete,
    'форму',
    () =>
      changeQuery({
        region_id: undefined,
        regional_project_id: undefined
      })
  );
  const [
    isRequestEditLoading,
    requestEditDialog,
    openRequestEditDialog
  ] = useGeneralConfirmationRow(
    entityStore.requestEdit,
    'Вы уверены, что хотите запросить редактирование формы?',
    'Возникла ошибка при запросе редактирования формы. Попробуйте еще раз'
  );
  const [
    isAllowEditLoading,
    allowEditDialog,
    openAllowEditDialog
  ] = useGeneralConfirmationRow(
    entityStore.allowEdit,
    'Вы уверены, что хотите разрешить редактирование формы?',
    'Возникла ошибка при разрешении редактирования формы. Попробуйте еще раз'
  );
  const [
    isRejectEditLoading,
    rejectEditDialog,
    openRejectEditDialog
  ] = useGeneralConfirmationRow(
    entityStore.rejectEdit,
    'Вы уверены, что хотите запретить редактирование формы?',
    'Возникла ошибка при запрете редактирования формы. Попробуйте еще раз'
  );
  const [
    exportLoading,
    exportDialog,
    openExportDialog
  ] = useContestDocsExportDialog(
    async (id, data) => {
      const accessToken = await tokenStore.createTenderDocumentationMonthlyMonitoringReportOneTimeToken();
      const url = stringifyUrl({
        url: `/monthly_monitoring/tender_documentation/${id}`,
        query: data
      });
      await downloadRestFile({
        method: 'get',
        responseUrl: url,
        accessToken
      });
    },
    'Для экспорта формы укажите должность и ФИО лица, подписывающего документ',
    'Возникла ошибка при экспорте формы. Попробуйте еще раз'
  );

  const year = query.year || getCurrentYear().toString();
  const region_id = isRegionAdmin ? user?.region?.id : query.region_id;
  const regional_project_id = query.regional_project_id;

  const filter = {
    year,
    region_id,
    regional_project_id
  };

  const input = useMemo(() => {
    return !isDeleting && regional_project_id && year
      ? {
          regional_project_id: regional_project_id,
          year: +year
        }
      : null;
  }, [isDeleting, regional_project_id, year]);
  const entityQuery = useContestDocsMonitoringEntity(input);
  const entityQueryLoading = entityQuery.check.spinner;
  const entityQueryData = entityQuery.state.data;
  const entityQueryDataId = entityQueryData?.id;

  const handleChangeFilter = useCallback(
    (name, value) => {
      const obj = {
        [name]: value
      };

      if (name === 'region_id' || name === 'year') {
        obj.regional_project_id = undefined;
      }

      changeQuery(obj);
    },
    [changeQuery]
  );

  const handleSubmit = useCallback(
    (data, actions) => {
      const input = ContestDocsMonitoringEntity.fromForm(data);

      const isSubmit =
        data['submitStatus'] ===
        ContestDocsMonitoringEntity.formSubmitStatus.Submit;
      const isApprove =
        data['submitStatus'] ===
        ContestDocsMonitoringEntity.formSubmitStatus.Approve;

      if (isSubmit) {
        entityStore
          .update(input)
          .then((result) => {
            // actions.setStatus(FORM_STATUS_SUBMITTED);
            // setTimeout(() => {
            //   history.replace('/contestDocsMonitoring');
            // }, 0);
            toast.success('Форма успешно сохранена');
          })
          .catch((error) => {
            showGraphqlErrorToast(error, mutationErrors.updateObject);
          })
          .then(() => {
            actions.setSubmitting(false);
            // actions.resetForm();
          });
      }
      if (isApprove) {
        entityStore
          .approve(entityQueryDataId)
          .catch((error) => {
            showGraphqlErrorToast(error, mutationErrors.approveObject);
          })
          .then(() => {
            actions.setSubmitting(false);
            // actions.resetForm();
          });
      }
    },
    [entityStore, entityQueryDataId]
  );

  const handleGoBack = useCallback(() => {
    history.replace('/contestDocsMonitoring');
  }, [history]);

  const initialValues = useMemo(() => {
    return ContestDocsMonitoringEntity.toForm(entityQueryData);
  }, [entityQueryData]);

  const editor = useOtherEditor(
    'monthlyMonitoringTenderDocumentation',
    entityId || entityQueryDataId
  );

  const loading =
    isDeleting ||
    isRequestEditLoading ||
    isAllowEditLoading ||
    isRejectEditLoading;

  return (
    <>
      <HeadTitle title={contestDocsMonitoringPageTitle} />
      <PageTitle withBackBtn={false} title={contestDocsMonitoringPageTitle} />
      <Formik
        validationSchema={lazyValidation((values) => {
          const isApproval =
            values['submitStatus'] ===
            SubsidiaryMonitoringEntity.formSubmitStatus.Approve;
          return isApproval
            ? ContestDocsMonitoringEntityApprovalValidationSchema
            : ContestDocsMonitoringEntityDraftValidationSchema;
        })}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        render={(formikProps) => (
          <ContestDocsMonitoringForm
            {...formikProps}
            entity={entityQueryData}
            entityLoading={entityQueryLoading || loading}
            entityDeleting={isDeleting}
            exportLoading={exportLoading}
            editor={editor}
            filter={filter}
            onFilterChange={handleChangeFilter}
            onBackClick={handleGoBack}
            onDelete={openDeleteDialog}
            onRequestEdit={openRequestEditDialog}
            onAllowEdit={openAllowEditDialog}
            onRejectEdit={openRejectEditDialog}
            onExport={openExportDialog}
            onExportHistoryOpen={() => setExportHistoryOpen(true)}
          />
        )}
      />
      <ExportHistoryPopup
        id={entityId || entityQueryDataId}
        query={QUERY_CONTEST_DOCS_MONITORING_EXPORT_HISTORY}
        open={exportHistoryOpen}
        onClose={() => setExportHistoryOpen(false)}
      />
      {deleteDialog}
      {requestEditDialog}
      {allowEditDialog}
      {rejectEditDialog}
      {exportDialog}
    </>
  );
}
