import React, { useMemo } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useContextStore } from '@proscom/prostore-react';
import { CircularProgress } from '@material-ui/core';
import PageTitle from '../../common/PageTitle/PageTitle';
import Table from '../../common/Table/Table';
import Menu from '../../common/Menu/Menu';
import { useRoles } from '../../store/role/useRoles';
import { useCurrentUser } from '../../store/useCurrentUser';
import { ROLE_STORE } from '../../store/names';
import { User } from '../../store/role/User';
import { useDeleteRow } from '../../common/Table/useDeleteRow';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';

const title = 'Роли';

const rolesColumn = [
  {
    label: '№',
    key: 'order',
    isThin: true
  },
  {
    label: 'Название роли',
    key: 'name',
    isWide: true,
    isBold: true
  }
];

export default function RolesPage({ match, history }) {
  const roleStore = useContextStore(ROLE_STORE);
  const user = useCurrentUser();
  const filter = {
    creator_user_id: user?.role !== User.Role.ADMIN ? user?.id : undefined
  };
  const rolesQuery = useRoles(filter);
  const roles = rolesQuery.state.data?.list;

  const data = useMemo(
    () =>
      roles?.map((role, iRole) => ({
        ...role,
        order: iRole + 1
      })),
    [roles]
  );

  const menuComponent = useMemo(
    () =>
      function MenuComponent({ row }) {
        const [isDeleting, dialog, openDialog] = useDeleteRow(
          roleStore.deleteRole,
          'роль'
        );

        return (
          <>
            {isDeleting ? (
              <CircularProgress size={18} />
            ) : (
              <Menu
                iconColor="default"
                icon={MoreHorizIcon}
                options={[
                  {
                    title: 'Редактировать',
                    onClick: () => history.push(`${match.url}/${row.id}/edit`)
                  },
                  {
                    title: 'Удалить',
                    onClick: () => openDialog(row.id)
                  }
                ]}
              />
            )}
            {dialog}
          </>
        );
      },
    [history, match, roleStore]
  );

  return (
    <>
      <HeadTitle title={title} />
      <PageTitle withBackBtn={false} title={title} />
      <Table
        addButtonText="Добавить"
        addFieldRoute={`${match.url}/create`}
        hasAddField={true}
        columns={rolesColumn}
        data={data}
        isLoading={rolesQuery.check.spinner}
        renderNoData={() => <h3>Нет ролей</h3>}
        rowRedirectRoute={`${match.url}/`}
        menuComponent={menuComponent}
      />
    </>
  );
}
