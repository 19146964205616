import { pick } from 'lodash-es';
import * as Yup from 'yup';
import { User } from '../role/User';
import { validationMessages } from '../../utils/data/validation';
import { Pof } from './Pof';

export class PofAuth {
  static canCreate(user) {
    return User.hasPermission(user, 'pof.crud');
  }

  static canEdit(pof, user) {
    return (
      user &&
      pof &&
      ((User.hasPermission(user, 'pof.approve') &&
        (pof.status === Pof.Status.APPROVAL ||
          pof.status === Pof.Status.APPROVED)) ||
        (User.hasPermission(user, 'pof.crud') &&
          (pof.status === Pof.Status.RETURNED ||
            pof.status === Pof.Status.DRAFT)))
    );
  }

  static canDelete(pof, user) {
    return (
      user &&
      pof &&
      User.hasPermission(user, 'pof.crud') &&
      (pof.status === Pof.Status.DRAFT || pof.status === Pof.Status.RETURNED)
    );
  }

  static canEditValues(pof, user) {
    return (
      user &&
      User.hasPermission(user, 'pof.crud') &&
      (!pof ||
        pof.status === Pof.Status.DRAFT ||
        pof.status === Pof.Status.RETURNED)
    );
  }

  static canSendForApproval(pof, user) {
    return (
      pof &&
      User.hasPermission(user, 'pof.send_for_approval') &&
      (pof.status === Pof.Status.RETURNED || pof.status === Pof.Status.DRAFT)
    );
  }

  static canApprove(pof, user) {
    return (
      pof &&
      User.hasPermission(user, 'pof.approve') &&
      (pof.status === Pof.Status.APPROVAL || pof.status === Pof.Status.APPROVED)
    );
  }

  static canSeeTotalFinances(pof, user) {
    return User.hasPermission(user, 'pof.view_total_finances');
  }

  static filterAccessibleFields(data, user, originalDocument) {
    const fields = ['id', ...Pof.baseFields];

    if (PofAuth.canApprove(originalDocument, user)) {
      fields.push('comment_fpo');
    }
    if (PofAuth.canEditValues(originalDocument, user)) {
      fields.push('values');
    }

    return pick(data, fields);
  }

  static getAvailableStatuses(pof, user) {
    return (
      (PofAuth.canApprove(pof, user) &&
        (pof.status === Pof.Status.APPROVAL ||
          pof.status === Pof.Status.APPROVED) && [
          Pof.Status.APPROVAL,
          Pof.Status.APPROVED,
          Pof.Status.RETURNED
        ]) ||
      []
    );
  }

  static validationSchemaCreate = () =>
    Yup.object().shape({
      expenses_direction_id: Yup.string()
        .required(validationMessages.requiredMessage)
        .nullable()
    });

  static validationSchemaEdit = (pof, user) => {
    if (this.canApprove(pof, user)) {
      return Yup.object().shape(
        {
          status: Yup.string()
            .required(validationMessages.requiredMessage)
            .nullable(),
          comment_fpo: Yup.string()
            .nullable()
            .when('status', {
              is: (id) => id === Pof.Status.RETURNED,
              then: Yup.string().required(validationMessages.requiredMessage),
              otherwise: Yup.string()
            })
        },
        [['status']]
      );
    }

    return Yup.object().shape({});
  };
}
