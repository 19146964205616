import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import {
  MUTATION_CREATE_DIRECTION_COMMENT,
  MUTATION_DELETE_DIRECTION_COMMENTARY,
  MUTATION_UPDATE_DIRECTION_COMMENT
} from '../../graphql/mutations/comment';
import { clearApolloQueryCache } from '../../utils/apollo';
import { INFRALIST_QUERIES } from './infralistQueries';

export const useModifyDirectionComments = (type) => {
  const client = useContextApolloClient();

  const refetchQueries =
    type === 'rvpo'
      ? [INFRALIST_QUERIES.DIRECTION_RVPO_COMMENTARIES]
      : type === 'fpo'
      ? [INFRALIST_QUERIES.DIRECTION_FPO_COMMENTARIES]
      : type === 'general'
      ? [INFRALIST_QUERIES.DIRECTION_GENERAL_COMMENTARIES]
      : [INFRALIST_QUERIES.DIRECTION_RVPO_COMMENTARIES];

  const createCommentary = (data) => {
    return client.mutate({
      mutation: MUTATION_CREATE_DIRECTION_COMMENT,
      variables: {
        input: data
      },
      refetchQueries
    });
  };

  const updateCommentary = (data) => {
    return client.mutate({
      mutation: MUTATION_UPDATE_DIRECTION_COMMENT,
      variables: {
        input: data
      },
      refetchQueries,
      update: (store) => clearApolloQueryCache(store, refetchQueries)
    });
  };

  const deleteCommentary = (id) => {
    return client.mutate({
      mutation: MUTATION_DELETE_DIRECTION_COMMENTARY,
      variables: {
        id
      },
      refetchQueries,
      update: (store) => clearApolloQueryCache(store, refetchQueries)
    });
  };

  return {
    createCommentary,
    deleteCommentary,
    updateCommentary
  };
};
