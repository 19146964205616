import React, { useMemo } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PageTitle from '../../common/PageTitle/PageTitle';
import { useRegionalRoadmapEvents } from '../../store/roadmap/useRegionalRoadmapEvents';
import Table from '../../common/Table/Table';
import { useUrlQuery } from '../../common/useUrlQuery';
import {
  QueryPagination,
  useQueryPagination,
  useQueryPaginationCount
} from '../../common/usePagination';
import { RegionalEvent } from '../../store/roadmap/RegionalEvent';
import Menu from '../../common/Menu/Menu';
import { useCurrentUser } from '../../store/useCurrentUser';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';
import { reformatDate } from '../../utils/date';
import { useSorting } from '../../common/Table/Sorting/useSorting';
import { Sorting } from '../../common/Table/Sorting/Sorting';
import { useRegionalRoadmapEventsFilter } from './useRegionalRoadmapEventsFilter';

const title = 'Реестр региональных мероприятий';

const getRegionalRoadmapEventsColumn = (sortableColumns) => [
  { label: '№', key: 'order', isThin: true },
  { label: 'Код', key: 'code', isThin: true },
  {
    label: 'Наименование',
    key: 'roadmap_event.name_full',
    isWide: true,
    isBold: true
  },
  {
    label: 'Дорожная карта',
    key: 'roadmap_event.roadmap.name_full',
    isWide: true
  },
  { label: 'Регион', key: 'region.name', isWide: true, isBreak: true },
  { label: 'Статус', key: 'statusName', isThin: true },
  {
    label: 'Плановая дата предоставления',
    key: (row) => reformatDate(row.roadmap_event?.plan_term),
    isThin: true
  },
  ...sortableColumns
];

export default function RegionalRoadmapEventsPage({
  match,
  location,
  history
}) {
  const [query, changeQuery] = useUrlQuery(location, history);

  const [sortableColumns, sorting] = useSorting([
    {
      field: Sorting.SortFields.status_changed_at,
      key: (row) => reformatDate(row.status_changed_at)
    }
  ]);

  const [filter, filterComponent] = useRegionalRoadmapEventsFilter(
    query,
    changeQuery
  );
  const pagination = useQueryPagination(query, changeQuery, 50);

  const regionalRoadmapEventsQuery = useRegionalRoadmapEvents(
    filter,
    pagination.params,
    sorting
  );
  const regionalRoadmapEvents = regionalRoadmapEventsQuery.state.data;
  const data = useMemo(() => {
    if (!regionalRoadmapEvents) {
      return null;
    }
    const { list, header } = regionalRoadmapEvents;
    return list.map((regionalRoadmapEvent, iregionalRoadmapEvent) => ({
      ...regionalRoadmapEvent,
      order: header.page * header.onePage + iregionalRoadmapEvent + 1,
      statusName: RegionalEvent.status.getName(regionalRoadmapEvent.status)
    }));
  }, [regionalRoadmapEvents]);

  useQueryPaginationCount(pagination, regionalRoadmapEventsQuery);

  const user = useCurrentUser();

  const columns = useMemo(
    () => getRegionalRoadmapEventsColumn(sortableColumns),
    [sortableColumns]
  );

  return (
    <>
      <HeadTitle title={title} />
      <PageTitle title={title} />
      <Table
        columns={columns}
        isLoading={regionalRoadmapEventsQuery.check.spinner}
        data={data}
        filter={filterComponent}
        renderNoData={() => (
          <h3>Нет региональных мероприятий по выбранным фильтрам</h3>
        )}
        rowRedirectRoute={`${match.url}/`}
        renderMenu={(row) => {
          const canEdit = RegionalEvent.canEditEvent(user, row);
          if (!canEdit) {
            return null;
          }
          return (
            <Menu
              iconColor="default"
              icon={MoreHorizIcon}
              options={[
                {
                  title: 'Редактировать',
                  onClick: () => history.push(`${match.url}/${row.id}/edit`)
                }
              ]}
            />
          );
        }}
        pagination={<QueryPagination pagination={pagination} />}
      />
    </>
  );
}
