import { useEffect } from 'react';
import ym from 'react-yandex-metrika';
import { useCurrentUser } from './store/useCurrentUser';

export function UserTracker() {
  const user = useCurrentUser();

  useEffect(() => {
    if (ym) {
      if (user) {
        ym('userParams', {
          UserID: user.id,
          regionId: user.region?.id,
          organizationId: user.organization?.id,
          regionCode: user.region_code_nalog,
          role: user.role,
          roles: user.roles?.map((r) => r?.id)
        });
      }
    }
  }, [user]);

  return null;
}
