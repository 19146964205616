import { FORM_STATUS_SUBMITTED } from '../../utils/data/form';
import { delayPromise } from '../../utils/helpers';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../graphql/graphqlErrors';

export function createFormSubmitter({
  dataType,
  history,
  prepare,
  update,
  getUrl
}) {
  return async (values, actions) => {
    try {
      const prepareFn = prepare || dataType.fromForm;
      const getUrlFn = getUrl || dataType.getUrl;

      const prepared = await prepareFn?.(values);
      const result = await update?.(prepared);
      actions.setStatus(FORM_STATUS_SUBMITTED);
      await delayPromise();
      const url = await getUrlFn?.(result.data.result, prepared);
      history.goBack(url);
    } catch (error) {
      showGraphqlErrorToast(error, mutationErrors.updateObject);
    } finally {
      actions.setSubmitting(false);
    }
  };
}
