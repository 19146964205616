import gql from 'graphql-tag';

export const QUERY_GET_PARENTAL_ASSISTANCE_GUIDELINE_ORGANIZATIONAL = gql`
  query getParentalAssistanceGuidelineOrganizational($id: ID!) {
    parentalAssistanceGuidelineOrganizational(id: $id) {
      id
      non_commercial_organization {
        id
        name_short
      }
      status
      amount_of_contract_specialists_2019
      amount_of_contract_specialists_2020
      amount_of_lended_another_format_services_2019
      amount_of_lended_another_format_services_2020
      amount_of_lended_f2f_services_2019
      amount_of_lended_f2f_services_2020
      amount_of_lended_field_services_2019
      amount_of_lended_field_services_2020
      amount_of_lended_free_services_2019
      amount_of_lended_free_services_2020
      amount_of_lended_paid_services_2019
      amount_of_lended_paid_services_2020
      amount_of_lended_remote_services_2019
      amount_of_lended_remote_services_2020
      amount_of_multipletimes_service_recipients_2019
      amount_of_multipletimes_service_recipients_2020
      amount_of_onetime_service_recipients_2019
      amount_of_onetime_service_recipients_2020
      amount_of_prepared_specialists_2019
      amount_of_prepared_specialists_2020
      amount_of_service_rejections_2019
      amount_of_service_rejections_2020
      amount_of_specialists_in_state_2019
      amount_of_specialists_in_state_2020
      average_duration_of_services_provisioning
      average_duration_of_services_receivement
      babysitting_in_service_time
      babysitting_in_service_time_description
      comfortable_zone_for_disabled_children
      comfortable_zone_for_disabled_children_description
      free_service_recipient_groups
      has_approved_mediaplan
      information_distribution_description
      information_distribution_links
      maximum_waiting_time
      maximum_waiting_time_exists
      non_commercial_organization_id
      official_site_has_info
      official_site_has_info_description
      official_site_has_specialists_info
      official_site_has_specialists_info_description
      other_registration_methods
      other_service_delivery_formats
      paid_consultations_for_parents
      paid_consultations_for_parents_description
      possible_consultation_topic_choice
      possible_format_choice
      possible_nwh_services_provisioning
      possible_specialist_choice
      possible_time_choice
      possible_weekend_services_provisioning
      problems_in_new_service_introduction_description
      problems_in_new_service_introduction_exist
      registration_methods
      service_delivery_formats
      service_rejections_description_2019
      service_rejections_description_2020
      services_quality_control_methodology
      services_quality_is_being_controlled
      services_quality_is_being_controlled_on_portal
      special_categories_of_service_recievers
      special_categories_of_service_recievers_description
      status
      typical_consultations_description
      waiting_zone_description
      waiting_zone_exists
      list_of_local_service_regulations_description
      list_of_regional_service_regulations_description
      list_of_local_service_regulations {
        id
        file_name
        description
      }
      list_of_regional_service_regulations {
        id
        file_name
        description
      }
      approved_mediaplan {
        id
        file_name
      }
      information_distribution_protocols {
        id
        file_name
      }
      official_site_info_files {
        id
        file_name
      }
    }
  }
`;
