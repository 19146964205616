import isNil from 'lodash-es/isNil';
import { User } from '../role/User';
import { monthNames } from '../../utils/data/date';
import { toFixed } from '../../utils/number';
import { tryNumberLike } from '../../utils/helpers';

export class SubsidiaryMonitoringEntity {
  static Status = {
    DRAFT: 'DRAFT',
    RETURNED: 'RETURNED',
    APPROVED: 'APPROVED',
    ON_APPROVAL: 'ON_APPROVAL',
    EDIT_REQUESTED: 'EDIT_REQUESTED'
  };

  static statusLabel = {
    [SubsidiaryMonitoringEntity.Status.DRAFT]: 'Черновик',
    [SubsidiaryMonitoringEntity.Status.RETURNED]: 'На доработке',
    [SubsidiaryMonitoringEntity.Status.ON_APPROVAL]: 'На утверждении',
    [SubsidiaryMonitoringEntity.Status.APPROVED]: 'Утвержден',
    [SubsidiaryMonitoringEntity.Status.EDIT_REQUESTED]:
      'Запрошено редактирование'
  };

  static statusSuggestions = [
    { value: SubsidiaryMonitoringEntity.Status.DRAFT, label: 'Черновик' },
    {
      value: SubsidiaryMonitoringEntity.Status.RETURNED,
      label: 'На доработке'
    },
    {
      value: SubsidiaryMonitoringEntity.Status.ON_APPROVAL,
      label: 'На утверждении'
    },
    { value: SubsidiaryMonitoringEntity.Status.APPROVED, label: 'Утвержден' },
    {
      value: SubsidiaryMonitoringEntity.Status.EDIT_REQUESTED,
      label: 'Запрошено редактирование'
    }
  ];

  static IndicatorFillType = {
    CALCULATED: 'CALCULATED',
    MANUAL: 'MANUAL'
  };

  static Month = {
    JANUARY: 'JANUARY',
    FEBRUARY: 'FEBRUARY',
    MARCH: 'MARCH',
    APRIL: 'APRIL',
    MAY: 'MAY',
    JUNE: 'JUNE',
    JULY: 'JULY',
    AUGUST: 'AUGUST',
    SEPTEMBER: 'SEPTEMBER',
    OCTOBER: 'OCTOBER',
    NOVEMBER: 'NOVEMBER',
    DECEMBER: 'DECEMBER'
  };

  static monthArray = Object.keys(SubsidiaryMonitoringEntity.Month);

  static monthSuggestions = Object.keys(SubsidiaryMonitoringEntity.Month).map(
    (key, i) => ({
      value: key,
      label: monthNames[i]
    })
  );

  static AditionalFieldDataType = {
    DATE: 'DATE',
    NUMBER: 'NUMBER',
    STRING: 'STRING'
  };

  static formSubmitStatus = {
    Submit: 'Submit',
    Approve: 'Approve',
    SendForApproval: 'SendForApproval'
  };

  static fromForm(data) {
    return {
      id: data.id,
      analytical_information: data.analytical_information || '',
      indicators: data.indicators?.map((ind) => ({
        roadmap_indicator_id: ind.id,
        value: tryNumberLike(ind.value, null, true),
        note: ind.note || '',
        components:
          ind.components && ind.components.length > 0
            ? ind.components.map((comp) => ({
                component_id: comp.id,
                value: tryNumberLike(comp.value, null, true)
              }))
            : null
      })),
      trainings:
        data.trainings && data.trainings.length > 0
          ? data.trainings.map((tr) => ({
              amount: tryNumberLike(tr.amount, null, true),
              hours: tryNumberLike(tr.hours, null, true),
              date: tr.date || null,
              name: tr.name || '',
              note: tr.note || '',
              organization_name: tr.organization_name || ''
            }))
          : null,
      additional_fields:
        data.additional_fields && data.additional_fields.length > 0
          ? data.additional_fields.map((field) => ({
              additional_field_id: field.id,
              note: field.note || '',
              value_date: field.value_date || null,
              value_number: tryNumberLike(field.value_number, null, true),
              value_string: field.value_string || ''
            }))
          : null,
      location_note: data.location_note || '',
      organization_head_email: data.organization_head_email || '',
      organization_head_name: data.organization_head_name || '',
      organization_head_note: data.organization_head_note || '',
      organization_head_patronymic: data.organization_head_patronymic || '',
      organization_head_phone: data.organization_head_phone || '',
      subsidiary_entity_head_note: data.subsidiary_entity_head_note || '',
      organization_head_surname: data.organization_head_surname || '',
      comment_subsidiary_entity_address:
        data.comment_subsidiary_entity_address || '',
      comment_subsidiary_entity_name: data.comment_subsidiary_entity_name || '',
      comment_subsidiary_entity_head_surname:
        data.comment_subsidiary_entity_head_surname || '',
      comment_subsidiary_entity_head_name:
        data.comment_subsidiary_entity_head_name || '',
      comment_subsidiary_entity_head_patronymic:
        data.comment_subsidiary_entity_head_patronymic || '',
      comment_subsidiary_entity_head_phone:
        data.comment_subsidiary_entity_head_phone || '',
      comment_subsidiary_entity_head_email:
        data.comment_subsidiary_entity_head_email || '',
      comment_organization_head_surname:
        data.comment_organization_head_surname || '',
      comment_organization_head_name: data.comment_organization_head_name || '',
      comment_organization_head_patronymic:
        data.comment_organization_head_patronymic || '',
      comment_organization_head_phone:
        data.comment_organization_head_phone || '',
      comment_organization_head_email:
        data.comment_organization_head_email || ''
    };
  }

  static toForm(data) {
    const indicators = data?.indicators?.map((ind) => {
      const indValue = !isNil(ind.value) ? ind.value.toString() : '';
      const indPrecision = ind.roadmap_indicator?.precision ?? undefined;

      return {
        id: ind.roadmap_indicator?.id || '',
        name: ind.roadmap_indicator?.name || '',
        value: toFixed(indValue, indPrecision) ?? '',
        plan_value: !isNil(ind.plan_value) ? ind.plan_value.toString() : '',
        precision: indPrecision,
        fill_type: ind.roadmap_indicator?.fill_type ?? undefined,
        note: ind.note || '',
        components: ind.components?.map((comp) => {
          const compValue = !isNil(comp.value) ? comp.value.toString() : '';
          const compPrecision = comp.component?.precision ?? undefined;

          return {
            id: comp.component?.id || '',
            name: comp.component?.name || '',
            name_full: comp.component?.name_full || '',
            code: comp.component?.code || '',
            unit: comp.component?.unit?.name_local || '',
            precision: compPrecision,
            fill_type: comp.component?.fill_type ?? undefined,
            value: toFixed(compValue, compPrecision) ?? ''
          };
        })
      };
    });

    const addFields = data?.additional_fields?.map((field) => ({
      id: field.additional_field?.id || '',
      data_type: field.additional_field?.data_type || '',
      is_link: field.additional_field?.is_link,
      name: field.additional_field?.name || '',
      name_full: field.additional_field?.name_full || '',
      note: field.note || '',
      value_date: !isNil(field.value_date) ? field.value_date.toString() : '',
      value_number: !isNil(field.value_number)
        ? field.value_number.toString()
        : '',
      value_string: !isNil(field.value_string)
        ? field.value_string.toString()
        : '',
      suggestion: field.additional_field?.suggestion
    }));

    return {
      id: data?.id,
      analytical_information: data?.analytical_information || '',
      status: data?.status || SubsidiaryMonitoringEntity.Status.DRAFT,
      indicators: indicators || [],
      trainings: data?.trainings || [],
      additional_fields: addFields || [],
      location_note: data?.location_note || '',
      organization_head_email: data?.organization_head_email || '',
      organization_head_name: data?.organization_head_name || '',
      organization_head_note: data?.organization_head_note || '',
      organization_head_patronymic: data?.organization_head_patronymic || '',
      organization_head_phone: data?.organization_head_phone || '',
      subsidiary_entity_head_note: data?.subsidiary_entity_head_note || '',
      organization_head_surname: data?.organization_head_surname || '',
      comment_subsidiary_entity_address:
        data?.comment_subsidiary_entity_address || '',
      comment_subsidiary_entity_name:
        data?.comment_subsidiary_entity_name || '',
      comment_subsidiary_entity_head_surname:
        data?.comment_subsidiary_entity_head_surname || '',
      comment_subsidiary_entity_head_name:
        data?.comment_subsidiary_entity_head_name || '',
      comment_subsidiary_entity_head_patronymic:
        data?.comment_subsidiary_entity_head_patronymic || '',
      comment_subsidiary_entity_head_phone:
        data?.comment_subsidiary_entity_head_phone || '',
      comment_subsidiary_entity_head_email:
        data?.comment_subsidiary_entity_head_email || '',
      comment_organization_head_surname:
        data?.comment_organization_head_surname || '',
      comment_organization_head_name:
        data?.comment_organization_head_name || '',
      comment_organization_head_patronymic:
        data?.comment_organization_head_patronymic || '',
      comment_organization_head_phone:
        data?.comment_organization_head_phone || '',
      comment_organization_head_email:
        data?.comment_organization_head_email || '',
      //custom
      submitStatus: SubsidiaryMonitoringEntity.formSubmitStatus.Submit
    };
  }

  static getUrl(entity) {
    return `/subsidiaryMonitoring/edit/${entity.id}`;
  }

  static getAddFieldValueName(field) {
    switch (field.data_type) {
      case SubsidiaryMonitoringEntity.AditionalFieldDataType.DATE:
        return 'value_date';
      case SubsidiaryMonitoringEntity.AditionalFieldDataType.NUMBER:
        return 'value_number';
      case SubsidiaryMonitoringEntity.AditionalFieldDataType.STRING:
        return 'value_string';
      default:
        return '';
    }
  }

  static canView(user) {
    return (
      User.hasPermission(user, 'monthly_monitoring.subsidiary_entity.view') ||
      SubsidiaryMonitoringEntity.canViewOwn(user)
    );
  }

  static canViewOwn(user) {
    return (
      User.hasPermission(
        user,
        'monthly_monitoring.subsidiary_entity.view_own'
      ) && !!user.organization_id
    );
  }

  static canEdit(user, item) {
    const isCrud = User.hasPermission(
      user,
      'monthly_monitoring.subsidiary_entity.crud'
    );
    const isCrudOwner = User.hasPermission(
      user,
      'monthly_monitoring.subsidiary_entity.crud_own'
    );

    let isCrudPermitted = isCrud;
    let isCrudOwnPermitted = isCrudOwner;

    if (item) {
      const { isRegionAdmin } = User.getRole(user);

      if (isRegionAdmin) {
        const userRegionId = user?.region?.id;
        const itemRegionId = item?.subsidiary_entity?.region?.id;
        isCrudPermitted = userRegionId === itemRegionId;
      }
      if (isCrudOwner) {
        const userOrgId = user?.organization?.id;
        const itemOrgId = item?.subsidiary_entity?.base_organization?.id;
        isCrudOwnPermitted = userOrgId === itemOrgId;
      }
    }

    return isCrudPermitted || isCrudOwnPermitted;
  }

  static canApprove(user) {
    return User.hasPermission(
      user,
      'monthly_monitoring.subsidiary_entity.approve'
    );
  }

  static canRevoke(user) {
    return User.hasPermission(
      user,
      'monthly_monitoring.subsidiary_entity.revoke_approval'
    );
  }
}
