import React, { useMemo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Field } from 'formik';
import { EMDASH } from '../../../../utils/constants';
import TableInput from '../../../../common/Table/TableInput';
import SubTable from '../../../../common/SubTable/SubTable';
import { isNumberLike } from '../../../../utils/helpers';
import { toFixed } from '../../../../utils/number';

export const ContestDocsIndicatorPreview = ({ index, indicator, values }) => {
  const indicatorUnit = indicator?.roadmap_indicator?.unit?.name_local;
  const value = indicator?.value;
  const previous_value = indicator?.previous_value;
  const precision = indicator?.roadmap_indicator?.precision;

  const valueWithPrecision = useMemo(() => {
    return toFixed(value, precision);
  }, [value, precision]);

  const indicatorValuesDiff = useMemo(() => {
    if (!isNumberLike(value)) return null;
    return toFixed(value - (previous_value || 0), precision);
  }, [value, previous_value, precision]);

  const components = indicator?.components;
  const hasComponents = !!components && components.length > 0;

  const showPreviousValue = !!values.showPreviousValue;
  const showValueDiff = !!values.showValueDiff;

  const componentsColumns = useMemo(() => {
    if (!hasComponents) return [];

    const componentNameColWidth = 30;
    const filtersArray = [showPreviousValue, showValueDiff];
    const filtersDisabledAmount = filtersArray.filter((f) => !f).length;

    const cols = [
      {
        field: 'name_full',
        width: '50%',
        title: 'Компонент'
      },
      {
        field: 'unit',
        title: 'Единица измерения'
      },
      {
        field: 'previous_value',
        title:
          'Фактическое значение предыдущего периода компонента минимального показателя'
      },
      {
        field: 'value',
        title: 'Фактическое значение компонента минимального показателя',
        render: ({ value, precision }) => {
          return toFixed(value, precision) ?? EMDASH;
        }
      },
      {
        field: 'values_diff',
        title:
          'Отклонение фактического значения от значения предыдущего периода',
        render: ({ value, previous_value, precision }) => {
          return toFixed(value - (previous_value || 0), precision) ?? EMDASH;
        }
      }
    ].filter((col) => {
      if (col.field === 'previous_value' && !showPreviousValue) {
        return false;
      }
      if (col.field === 'values_diff' && !showValueDiff) {
        return false;
      }
      return true;
    });

    const componentNameCol = cols.find((col) => col.field === 'name_full');

    componentNameCol.width =
      Math.max(
        componentNameColWidth + 10 * filtersDisabledAmount,
        componentNameColWidth
      ) + '%';

    return cols;
  }, [hasComponents, showPreviousValue, showValueDiff]);

  return (
    <SubTable
      supTitle={'Минимальный показатель:'}
      title={indicator.roadmap_indicator.name_full}
    >
      <TableRow>
        <TableCell>Единица измерения</TableCell>
        <TableCell>{indicatorUnit || EMDASH}</TableCell>
      </TableRow>

      {showPreviousValue && (
        <TableRow>
          <TableCell>Фактическое значение предыдущего периода</TableCell>
          <TableCell>{previous_value ?? EMDASH}</TableCell>
        </TableRow>
      )}

      <TableRow>
        <TableCell>Фактическое значение</TableCell>
        <TableCell>{valueWithPrecision ?? EMDASH}</TableCell>
      </TableRow>

      {showValueDiff && (
        <TableRow>
          <TableCell>Отклонение фактического значения</TableCell>
          <TableCell>{indicatorValuesDiff ?? EMDASH}</TableCell>
        </TableRow>
      )}

      {hasComponents && (
        <TableRow>
          <TableCell colSpan={2}>
            <Field
              name={`indicators[${index}].components`}
              tableName="Компоненты расчета индикатора"
              columns={componentsColumns}
              component={TableInput}
              canAdd={false}
              canUpdate={false}
              canDelete={false}
            />
          </TableCell>
        </TableRow>
      )}

      <TableRow>
        <TableCell>Примечание</TableCell>
        <TableCell>{indicator?.roadmap_indicator?.note || EMDASH}</TableCell>
      </TableRow>
    </SubTable>
  );
};
