import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useStore } from '@proscom/prostore-react';
import { useUserRoleByPermissions } from '../../../../utils/hooks/useUserRoleByPermissions';
import { STORE_AUTH } from '../../../names';
import { COMMENT_TYPES } from '../../commentEntities';
import { useInfralist } from '../../useInfralist';
import { useModifyEquipmentComments } from '../../useModifyEquipmentsComments';

export const useEquipmentComments = ({ infralistId, equipmentId, type }) => {
  const infralist = useInfralist(infralistId);
  const [auth] = useStore(STORE_AUTH);
  const user = auth.authData.user;
  const { getRole } = useUserRoleByPermissions();
  const role = getRole(user);
  const commentType =
    type && [COMMENT_TYPES.GENERAL, role?.type].includes(type)
      ? type
      : role?.type;
  const client = useContextApolloClient();
  const {
    createCommentary,
    updateCommentary,
    deleteCommentary,
    comments
  } = useModifyEquipmentComments(client, equipmentId, commentType);

  const onCreate = (data) => {
    return createCommentary({
      content: data.content,
      rvpo_equipment_id: equipmentId,
      type: commentType,
      user_id: user?.id
    });
  };

  const onUpdate = (data) => {
    return updateCommentary({
      ...data,
      rvpo_equipment_id: equipmentId,
      type: commentType
    });
  };

  return {
    infralist,
    comments,
    commentType,
    createCommentary: onCreate,
    updateCommentary: onUpdate,
    deleteCommentary
  };
};
