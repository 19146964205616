import { clearApolloQueryCache } from '../../utils/apollo';
import {
  MUTATION_APPROVE_SUBSIDIARY_MONITORING_ENTITY,
  MUTATION_DELETE_SUBSIDIARY_MONITORING_ENTITY,
  MUTATION_REJECT_EDIT_SUBSIDIARY_MONITORING_ENTITY,
  MUTATION_REQUEST_EDIT_SUBSIDIARY_MONITORING_ENTITY,
  MUTATION_RETURN_SUBSIDIARY_MONITORING_ENTITY,
  MUTATION_SEND_SUBSIDIARY_MONITORING_ENTITY_FOR_APPROVAL,
  MUTATION_UPDATE_SUBSIDIARY_MONITORING_ENTITY
} from '../../graphql/mutations/subsidiaryMonitoring';

export class SubsidiaryMonitoringStore {
  constructor({ client }) {
    this.client = client;
  }

  queriesToClear = [
    'subsidiaryEntityMonitoringPage',
    'subsidiaryEntityMonitoring'
  ];

  queriesToRefetch = [
    'subsidiaryEntityMonitoringPage',
    'subsidiaryEntityMonitoring'
  ];

  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  update = (input) => {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_SUBSIDIARY_MONITORING_ENTITY,
      variables: {
        input
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };

  delete = (id) => {
    return this.client.mutate({
      mutation: MUTATION_DELETE_SUBSIDIARY_MONITORING_ENTITY,
      variables: {
        id
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };

  approve = (id) => {
    return this.client.mutate({
      mutation: MUTATION_APPROVE_SUBSIDIARY_MONITORING_ENTITY,
      variables: {
        id
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };

  sendForApproval = (id) => {
    return this.client.mutate({
      mutation: MUTATION_SEND_SUBSIDIARY_MONITORING_ENTITY_FOR_APPROVAL,
      variables: {
        id
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };

  requestEdit = (id) => {
    return this.client.mutate({
      mutation: MUTATION_REQUEST_EDIT_SUBSIDIARY_MONITORING_ENTITY,
      variables: {
        id
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };

  rejectEdit = (id) => {
    return this.client.mutate({
      mutation: MUTATION_REJECT_EDIT_SUBSIDIARY_MONITORING_ENTITY,
      variables: {
        id
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };

  return = (id) => {
    return this.client.mutate({
      mutation: MUTATION_RETURN_SUBSIDIARY_MONITORING_ENTITY,
      variables: {
        id
      },
      refetchQueries: this.queriesToRefetch,
      update: this.clearCache
    });
  };
}
