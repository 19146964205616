import { BehaviorStore } from '@proscom/prostore';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { tryParseJson } from '../utils/helpers';

export const LOCAL_STORAGE_AUTH = 'auth';

export class LocalStorageStore extends BehaviorStore {
  items = [LOCAL_STORAGE_AUTH];

  localStorage;

  constructor(localStorage) {
    super({});

    this.localStorage = localStorage;
    this.readStorage();
  }

  registerListener() {
    window.addEventListener('storage', this.handleStorageChange);
  }

  unregisterListener() {
    window.removeEventListener('storage', this.handleStorageChange);
  }

  readStorage() {
    const items = {};
    this.items.forEach((item) => {
      items[item] = tryParseJson(this.localStorage.getItem(item));
    });
    this.setState(items);
  }

  setItem(item, value) {
    this.localStorage.setItem(item, JSON.stringify(value));
    this.setState({ [item]: value });
  }

  get$(item) {
    return this.state$.pipe(
      map((state) => state[item]),
      distinctUntilChanged()
    );
  }

  handleStorageChange = (e) => {
    if (this.items.indexOf(e.key) === -1) {
      return;
    }
    if (e.newValue === e.oldValue) {
      return;
    }

    let value;
    let success = false;
    try {
      value = JSON.parse(e.newValue);
      success = true;
    } catch (e) {
      console.error(e);
    }

    if (success) {
      this.setState({
        [e.key]: value
      });
    }
  };
}
