import React from 'react';
import { useHistory } from 'react-router';
import { generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ButtonLine } from '../../../../../common/ButtonLine/ButtonLine';
import { ComponentWithTooltip } from '../../../../../common/ComponentWithTooltip/ComponentWithTooltip';
import Dialog from '../../../../../common/Dialog';
import { ProgressButton } from '../../../../../common/ProgressButton';
import {
  getGraphQLErrorInfo,
  showGraphqlErrorToast
} from '../../../../../graphql/graphqlErrors';
import { useCheckEquipmentSection } from '../../../../../store/infralist/hooks/useCheckEquipmentSection';
import { useCompleteApprovement } from '../../../../../store/infralist/hooks/useCompleteApprovement';
import { useCompleteChecking } from '../../../../../store/infralist/hooks/useCompleteChecking';
import { useCompleteSigning } from '../../../../../store/infralist/hooks/useCompleteSigning';
import { useFormInfralistMutation } from '../../../../../store/infralist/hooks/useFormInfralistMutation';
import { useSignEquipmentSection } from '../../../../../store/infralist/hooks/useSignEquipmentSection';
import { infralistRoutes } from '../../../../../store/infralist/routes/infralistRoutes';
import { useApprovement } from '../../../../../store/infralist/useApprovement';
import {
  useInfralistSectionUserAccesses,
  useInfralistUserAccesses
} from '../../../../../store/infralist/useInfralistUserAccesses';
import { useDialog } from '../../../../../utils/hooks/useDialog';

export const InfralistSectionActions = ({
  equipmentsListNotEmpty,
  sectionStatus,
  infralistId,
  sectionId,
  onCallReset,
  directionId,
  infralistSectionCheckStatus,
  isEquipmentEditing,
  infralistStatus,
  infralistSectionApproveStatus,
  isEditingBlocked,
  isEditEnabledByEventStatus
}) => {
  const {
    showFormActions,
    canForm,
    showSignActions,
    canSign,
    showCheckActions,
    canCheck,
    canApprove,
    showApproveActions
  } = useInfralistSectionUserAccesses({
    sectionStatus,
    infralistSectionCheckStatus,
    infralistStatus,
    infralistSectionApproveStatus,
    isEditEnabledByEventStatus
  });
  // формирование
  const { formInfralist, isLoadingFormInfralist } = useFormInfralistMutation();

  // подписание раздела
  const {
    signSectionEquipments,
    isLoadingSignEquipmentSection
  } = useSignEquipmentSection({ infralistId, sectionId });
  // завершить подписание
  const {
    completeSigning,
    isLoadingCompleteSigning,
    isLoadCompleteSigningWithoutEquipment
  } = useCompleteSigning();

  // проверка раздела
  const {
    checkEquipmentSection,
    isCheckingEquipmentSection
  } = useCheckEquipmentSection();
  // завершить проверку
  const { completeChecking, isLoadingCompleteChecking } = useCompleteChecking();

  // согласовать раздел
  const { approveAllEquipments } = useApprovement();
  const {
    completeApprovement,
    isLoadingCompleteApprovement,
    isLoadingCompleteApprovementWithoutEquipment
  } = useCompleteApprovement();

  const {
    isOpen: isFormPopupVisible,
    open: openFormPopup,
    close: closeFormPopup
  } = useDialog();

  const {
    isOpen: isSignPopupVisible,
    open: openSignPopup,
    close: closeSignPopup
  } = useDialog();

  const {
    isOpen: isCompleteSigningVisibleWithoutEquipment,
    open: openSignPopupWithoutEquipment,
    close: closeSignPopupWithoutEquipment
  } = useDialog();

  const {
    isOpen: isCheckPopupVisible,
    open: openCheckPopup,
    close: closeCheckPopup
  } = useDialog();

  const {
    isOpen: isApprovePopupVisible,
    open: openApprovePopup,
    close: closeApprovePopup
  } = useDialog();

  const {
    isOpen: isApprovePopupWithoutEquipmentVisible,
    open: openApprovePopupWithoutEquipment,
    close: closeApprovePopupWithoutEquipment
  } = useDialog();

  const { isFPO2 } = useInfralistUserAccesses();

  const isEquipmentListReady = !isEquipmentEditing;

  // отлов ошибок на завершение
  const catchCompleteErrors = (graphqlError) => {
    const errorInfo = getGraphQLErrorInfo(graphqlError);
    const completeError =
      'До завершения необходимо проставить статус по всем позициям оборудования';
    // Привязываемся к тексту ошибки (больше не к чему)
    const catchErrors = [
      'Not all equipments signing',
      'Not all rvpo equipment is checked in section',
      'Not all equipments approved'
    ];
    if (catchErrors.includes(errorInfo?.message)) {
      toast.error(completeError);
    } else {
      showGraphqlErrorToast(graphqlError);
    }
  };

  const history = useHistory();
  // переходим в направление после завершения
  const goToDirection = () => {
    history.push(
      generatePath(infralistRoutes.DIRECTION, {
        infralistId,
        directionId
      })
    );
  };

  const tooltipEditingBlocked =
    'Одна из позиций оборудования находится в режиме редактирования';

  return (
    <ButtonLine>
      {/* Формирование инфралиста */}
      {showFormActions && (
        <>
          <ComponentWithTooltip
            placement={'left'}
            isTooltipExists={isEditingBlocked}
            width={300}
            title={tooltipEditingBlocked}
          >
            <ProgressButton
              color={'secondary'}
              variant="contained"
              onClick={openFormPopup}
              disabled={!isEquipmentListReady || !canForm || isEditingBlocked}
            >
              Завершить формирование
            </ProgressButton>
          </ComponentWithTooltip>
          <Dialog
            title={
              'Вы действительно хотите завершить формирование перечня оборудования?'
            }
            open={isFormPopupVisible}
            handleAccept={() => {
              formInfralist(infralistId, sectionId)
                .then(onCallReset)
                .then(goToDirection)
                .catch(showGraphqlErrorToast);
            }}
            handleClose={closeFormPopup}
            isLoading={isLoadingFormInfralist}
          >
            После подтверждения редактирование будет невозможно.
          </Dialog>
        </>
      )}

      {/* Подписание инфралиста */}
      {showSignActions && (
        <>
          <ComponentWithTooltip
            placement={'bottom'}
            isTooltipExists={isEquipmentListReady && canSign}
            width={300}
            title={
              'После нажатия на кнопку все неподписанные позиции оборудования приобретут статус "Подписано"'
            }
          >
            <ProgressButton
              color={'secondary'}
              variant="outlined"
              disabled={!isEquipmentListReady || !canSign}
              onClick={() =>
                signSectionEquipments({
                  sectionId,
                  infralistId
                }).then(onCallReset)
              }
              isLoading={isLoadingSignEquipmentSection}
            >
              Подписать всё
            </ProgressButton>
          </ComponentWithTooltip>
          <ComponentWithTooltip
            placement={'left'}
            isTooltipExists={isEditingBlocked}
            width={300}
            title={tooltipEditingBlocked}
          >
            <ProgressButton
              color={'secondary'}
              variant="contained"
              disabled={!isEquipmentListReady || !canSign || isEditingBlocked}
              onClick={
                equipmentsListNotEmpty
                  ? openSignPopup
                  : openSignPopupWithoutEquipment
              }
            >
              Завершить подписание
            </ProgressButton>
          </ComponentWithTooltip>
          <Dialog
            title={
              'Вы действительно хотите завершить подписание перечня оборудования?'
            }
            open={isSignPopupVisible}
            handleAccept={() => {
              completeSigning({ infralistId, sectionId })
                .then(onCallReset)
                .then(goToDirection)
                .catch(catchCompleteErrors);
            }}
            handleClose={closeSignPopup}
            isLoading={isLoadingCompleteSigning}
          >
            После подтверждения редактирование будет невозможно.
          </Dialog>
          <Dialog
            title={
              'Вы действительно хотите завершить подписание перечня оборудования?'
            }
            open={isCompleteSigningVisibleWithoutEquipment}
            handleAccept={() => {
              completeSigning({
                infralistId,
                sectionId,
                isEmptySigning: true
              })
                .then(onCallReset)
                .then(goToDirection)
                .catch(catchCompleteErrors);
            }}
            handleClose={closeSignPopupWithoutEquipment}
            isLoading={isLoadingCompleteSigning}
            titleRefuse={'Отмена'}
            titleAccept={'Подписать раздел'}
            additionalButtons={
              <ProgressButton
                color="primary"
                isLoading={isLoadCompleteSigningWithoutEquipment}
                onClick={() => {
                  completeSigning({
                    infralistId,
                    sectionId,
                    isEmptySigning: false
                  })
                    .then(onCallReset)
                    .then(goToDirection)
                    .catch(catchCompleteErrors);
                }}
              >
                Не подписать раздел
              </ProgressButton>
            }
          >
            После подтверждения редактирование будет невозможно.
          </Dialog>
        </>
      )}

      {/* Проверка инфралиста */}
      {showCheckActions && (
        <>
          <ComponentWithTooltip
            isTooltipExists={isEquipmentListReady && canCheck}
            placement={'bottom'}
            width={300}
            title={
              'После нажатия на кнопку все непроверенные позиции оборудования приобретут статус "Замечаний нет"'
            }
          >
            <ProgressButton
              color={'secondary'}
              variant="outlined"
              disabled={!isEquipmentListReady || !canCheck}
              onClick={() =>
                checkEquipmentSection({
                  sectionId,
                  infralistId
                }).then(onCallReset)
              }
              isLoading={isCheckingEquipmentSection}
            >
              Замечаний нет
            </ProgressButton>
          </ComponentWithTooltip>
          <ComponentWithTooltip
            placement={'left'}
            isTooltipExists={isEditingBlocked}
            width={300}
            title={tooltipEditingBlocked}
          >
            <ProgressButton
              color={'secondary'}
              variant="contained"
              disabled={!isEquipmentListReady || !canCheck || isEditingBlocked}
              onClick={openCheckPopup}
            >
              Завершить проверку
            </ProgressButton>
          </ComponentWithTooltip>
          <Dialog
            title={
              'Вы действительно хотите завершить проверку перечня оборудования?'
            }
            open={isCheckPopupVisible}
            handleAccept={() => {
              completeChecking({ infralistId, sectionId })
                .then(onCallReset)
                .then(goToDirection)
                .catch(catchCompleteErrors);
            }}
            handleClose={closeCheckPopup}
            isLoading={isLoadingCompleteChecking}
          >
            После подтверждения редактирование будет невозможно.
          </Dialog>
        </>
      )}

      {/* согласование оборудования */}
      {showApproveActions && (
        <>
          <ComponentWithTooltip
            placement={'bottom'}
            isTooltipExists={isEquipmentListReady && canApprove}
            width={300}
            title={
              'После нажатия на кнопку все несогласованные позиции оборудования приобретут статус "Согласовано"'
            }
          >
            <ProgressButton
              color={'secondary'}
              variant="outlined"
              disabled={!isEquipmentListReady || !canApprove}
              onClick={() =>
                approveAllEquipments({ sectionId, infralistId }).then(
                  onCallReset
                )
              }
              isLoading={isCheckingEquipmentSection}
            >
              Согласовать все
            </ProgressButton>
          </ComponentWithTooltip>
          <ComponentWithTooltip
            placement={'left'}
            isTooltipExists={isEditingBlocked}
            width={300}
            title={tooltipEditingBlocked}
          >
            <ProgressButton
              color={'secondary'}
              variant="contained"
              disabled={
                !isEquipmentListReady || !canApprove || isEditingBlocked
              }
              onClick={
                isFPO2 && !equipmentsListNotEmpty
                  ? openApprovePopupWithoutEquipment
                  : openApprovePopup
              }
            >
              Завершить согласование
            </ProgressButton>
          </ComponentWithTooltip>
          <Dialog
            title={
              'Вы действительно хотите завершить согласование перечня оборудования?'
            }
            open={isApprovePopupVisible}
            handleAccept={() => {
              completeApprovement({ infralistId, sectionId })
                .then(onCallReset)
                .then(goToDirection)
                .catch(catchCompleteErrors);
            }}
            handleClose={closeApprovePopup}
            isLoading={isLoadingCompleteApprovement}
          >
            После подтверждения редактирование будет невозможно.
          </Dialog>
          <Dialog
            title={
              'Вы действительно хотите завершить согласование перечня оборудования?'
            }
            open={isApprovePopupWithoutEquipmentVisible}
            handleAccept={() => {
              completeApprovement({ infralistId, sectionId, isApprove: true })
                .then(onCallReset)
                .then(goToDirection)
                .catch(catchCompleteErrors);
            }}
            handleClose={closeApprovePopupWithoutEquipment}
            isLoading={isLoadingCompleteApprovement}
            titleRefuse={'Отмена'}
            titleAccept={'Согласовать раздел'}
            additionalButtons={
              <ProgressButton
                color="primary"
                isLoading={isLoadingCompleteApprovementWithoutEquipment}
                onClick={() => {
                  completeApprovement({
                    infralistId,
                    sectionId,
                    isApprove: false
                  })
                    .then(onCallReset)
                    .then(goToDirection)
                    .catch(catchCompleteErrors);
                }}
              >
                Не согласовать раздел
              </ProgressButton>
            }
          >
            После подтверждения редактирование будет невозможно.
          </Dialog>
        </>
      )}
    </ButtonLine>
  );
};
