import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import { ProgressButton } from '../../../common/ProgressButton';
import { InfralistTable } from '../../../common/infralist/InfralistTable/InfralistTable';
import { useResetInfralistMutation } from '../../../store/infralist/hooks/useResetInfralistMutation';
import { infralistRoutes } from '../../../store/infralist/routes/infralistRoutes';
import { useInfralist } from '../../../store/infralist/useInfralist';
import { useInfralistVersion } from '../../../store/infralist/versions/useInfralistVersion';
import { useInfralistVersionData } from '../../../store/infralist/versions/useInfralistVersionData';
import { useInfralistVersionDirections } from '../../../store/infralist/versions/useInfralistVersionDirections';
import { useInfralistPageColumns } from '../hooks/useInfralistPageColumns';
import { InfralistPageBreadCrumbs } from '../InfralistPageBreadCrumbs';
import { InfralistStatus } from '../InfralistStatus';
import { PageTitlePanel } from '../PageTitlePanel';
import { InfralistSumFilter } from '../InfralistSumFilter';
import { isInfralistTestModeEnable } from '../../../config';

const title = 'Формирование и согласование перечня оборудования';

export default function InfralistVersionPage() {
  const { infralistId } = useParams();
  const {
    generatePathBasedOnVersion,
    isVersionMode,
    versionId
  } = useInfralistVersion();

  const history = useHistory();
  const infralistQuery = useInfralist(infralistId);
  const infralistVersionQuery = useInfralistVersionData(versionId);
  const infralistStatus =
    infralistVersionQuery.state.data?.infralist_status?.name;

  const {
    data: directionsData,
    isLoading: isLoadingDirections
  } = useInfralistVersionDirections({ infralistId, versionId });

  const columns = useInfralistPageColumns({
    infralistStatus,
    versionPage: true
  });

  const sum = useMemo(
    () =>
      directionsData?.reduce(
        (total, current) => total + +current.direction_sum_by_version,
        0
      ),
    [directionsData]
  );
  const {
    isLoadingResetInfralist,
    resetInfralist
  } = useResetInfralistMutation();

  return (
    <>
      <HeadTitle title={title} />
      <InfralistPageBreadCrumbs
        roadmapName={infralistQuery.state.data?.roadmap?.name}
      />
      <PageTitlePanel
        title={title}
        description={
          <InfralistStatus
            isLoading={infralistVersionQuery.check.spinner}
            status={infralistStatus}
          />
        }
        withBackBtn={false}
        actions={
          isInfralistTestModeEnable && (
            <ProgressButton
              variant="contained"
              color={'primary'}
              onClick={() => resetInfralist(infralistId)}
              isLoading={isLoadingResetInfralist}
            >
              Сбросить данные инфралиста
            </ProgressButton>
          )
        }
      />

      <InfralistSumFilter sum={sum} />

      <InfralistTable
        onLineClick={(dataItem) => {
          history.push(
            generatePathBasedOnVersion(infralistRoutes.DIRECTION, {
              infralistId,
              directionId: dataItem.id
            })
          );
        }}
        columns={columns}
        data={directionsData}
        isLoading={isLoadingDirections}
        readonly={isVersionMode}
        minWidth={850}
      />
    </>
  );
}
