export const COMMENT_TYPES = {
  FPO: 'fpo',
  RVPO: 'rvpo',
  GENERAL: 'general'
};

export const COMMENT_STATUSES = {
  ACCEPTED_BY_RVPO: 'accepted_by_rvpo',
  NOT_ACCEPTED_BY_RVPO: 'not_accepted_by_rvpo',
  ACCEPTED_BY_FPO: 'accepted_by_fpo',
  NOT_ACCEPTED_BY_FPO: 'not_accepted_by_fpo',
  EMPTY: ''
};
