import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_FEDERAL_PROJECTS_AVAILABLE_FOR_CONTEST_DOCS_MONITORING } from '../../graphql/queries/federalProjects';

const federalProjectsAvailableForContestDocsMonitoringQueryOptions = {
  query: QUERY_GET_FEDERAL_PROJECTS_AVAILABLE_FOR_CONTEST_DOCS_MONITORING,
  mapData: (result) =>
    result.federalProjectsAvailableForTenderDocumentationForSS,
  skipQuery: skipIfNull(null)
};

export function useFederalProjectsAvailableForContestDocsMonitoring(filter) {
  return useRetriableGraphql({
    queryOptions: federalProjectsAvailableForContestDocsMonitoringQueryOptions,
    variables: filter
      ? {
          ...filter
        }
      : null
  });
}
