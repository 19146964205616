// функция поиска введенного значения в label, по умолчанию ищет по всем полям
// https://stackoverflow.com/questions/51938587/react-select-type-to-search-access-to-filteroption-resulting-array
const filterOption = ({ label }, input) => {
  if (typeof label !== 'string') {
    return true;
  }
  // поиск в нижнем регистре
  return label.toLocaleLowerCase().includes(input.toLocaleLowerCase());
};

export const reactSelectHelpers = {
  filterOption
};
