import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { useContextStore } from '@proscom/prostore-react';
import {
  DateCell,
  DateTimeCell,
  LinkCell,
  PriceCell
} from '../../../common/TableCells';
import { useRegionalProject } from '../../../store/regionalProject/useRegionalProject';
import { useExpensesDirection } from '../../../store/expensesDirection/useExpensesDirection';
import { usePurchaseWithSignals } from '../../../store/purchase/usePurchase';
import { Purchase } from '../../../store/purchase/Purchase';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { getFullLabel } from '../../../utils/data/label';
import SubTable from '../../../common/SubTable/SubTable';
import { EMDASH } from '../../../utils/constants';
import { STORE_PURCHASE } from '../../../store/names';
import { mergeSignals, parseSignals } from '../../../utils/data/signals';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../graphql/graphqlErrors';
import NotFoundPage from '../../notFound/NotFoundPage';
import { DeleteButtonWithConfirmation } from '../../../common/Form/DeleteButtonWithConfirmation';
import { queryLoaderWithExistenceCheck } from '../../../common/QueryLoader';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import { PurchaseComponents } from './PurchaseComponents';
import { PurchaseChanges } from './PurchaseChanges';
import { PurchasePaymentSchedules } from './PurchasePaymentSchedules';
import { PurchaseBreadCrumbs } from './PurchaseBreadCrumbs';
import { PurchaseInfralistsComponent } from './PurchaseInfralistsComponent';
import s from './PurchasePage.module.scss';

const PurchasePage = ({ match, history }) => {
  const { projectId, expenseGroupId, id } = match.params;
  const [redirectAfterDeletionUrl] = match.url.split('/purchases');
  const [isDeleting, setDeleting] = useState(false);
  const purchaseStore = useContextStore(STORE_PURCHASE);

  const deletePurchase = useCallback(() => {
    setDeleting(true);
    purchaseStore
      .deletePurchase(id)
      .then((result) => {
        history.push(redirectAfterDeletionUrl || '/purchases');
      })
      .catch((error) => {
        showGraphqlErrorToast(error, mutationErrors.deleteObject);
      })
      .finally(() => setDeleting(false));
  }, [id, history, redirectAfterDeletionUrl, purchaseStore]);

  const projectQuery = useRegionalProject(projectId);
  const project = projectQuery.state.data;

  const expensesDirectionQuery = useExpensesDirection(expenseGroupId);
  const expensesDirection = expensesDirectionQuery.state.data;

  const purchaseQuery = usePurchaseWithSignals(id, true);
  const queryData = purchaseQuery.state.data;
  const purchaseDefault = queryData?.purchase;
  const purchase = useMemo(
    () => (purchaseDefault ? Purchase.toForm(purchaseDefault) : {}),
    [purchaseDefault]
  );

  const serverSignals = queryData && parseSignals(queryData.signals);
  const clientSignals = Purchase.checkSignals(purchase);
  const { fields: signals } = mergeSignals(serverSignals, clientSignals);

  const regional_project = purchase.regional_project;
  const region = regional_project && regional_project.region;

  const cancelStatus = purchase.cancel_status;
  const statusPartiallyCanceled =
    cancelStatus === Purchase.CancelStatus.PARTIALLY_CANCELED;

  if (purchaseQuery.check.component && !purchaseDefault) {
    return <NotFoundPage />;
  }

  return (
    <>
      <HeadTitle type="Закупка" entity={purchase?.item} />
      <PurchaseBreadCrumbs
        project={project}
        projectId={projectId}
        expensesDirection={expensesDirection}
        expenseGroupId={expenseGroupId}
      />
      <PageTitle
        title={
          purchase.item
            ? `${purchase.number}. ${purchase.item}`
            : purchase.number
            ? `Закупка №${purchase.number}`
            : '...'
        }
        withBackBtn
      />
      <div className={s.AddFieldContainer}>
        <Tooltip title="Редактировать">
          <Link to={`${match.url}/edit`}>
            <IconButton color="primary" classes={{ root: s.IconButton }}>
              <EditIcon />
            </IconButton>
          </Link>
        </Tooltip>
        <DeleteButtonWithConfirmation
          onDelete={deletePurchase}
          isDeleting={isDeleting}
          entityName="закупку"
        />
      </div>
      {queryLoaderWithExistenceCheck(purchaseQuery) || (
        <div>
          <SubTable title="Общие сведения">
            <TableRow>
              <TableCell>Год</TableCell>
              <TableCell>{(purchase && purchase.year) || EMDASH}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Регион</TableCell>
              <TableCell>{region && region.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Региональный проект</TableCell>
              <TableCell>{regional_project && regional_project.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Направление расходов</TableCell>
              <TableCell>
                {purchase.expenses_direction &&
                  getFullLabel(purchase.expenses_direction)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ГРБС</TableCell>
              <TableCell>{Purchase.getGrbsName(purchase)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Заказчик</TableCell>
              <TableCell>
                {Purchase.getCustomerNameDisplay(purchaseDefault)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Объект закупки</TableCell>
              <TableCell>{purchase.item}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Статус</TableCell>
              <TableCell>{Purchase.status.getName(purchase.status)}</TableCell>
            </TableRow>
            {false && (
              /* Скрыто по требованию заказчика */ <TableRow>
                <TableCell>Оборудование и ПО</TableCell>
                <TableCell>
                  {Purchase.getEquipmentAndSoftware(
                    purchase.equipment_and_software
                  )}
                </TableCell>
              </TableRow>
            )}
          </SubTable>

          {statusPartiallyCanceled && (
            <SubTable title="Сумма исполненных обязательств по контракту">
              <TableRow>
                <TableCell>Всего</TableCell>
                <PriceCell value={purchase.fulfilled_obligations} />
              </TableRow>
              <TableRow>
                <TableCell>Федеральный бюджет</TableCell>
                <PriceCell value={purchase.fulfilled_obligations_fb} />
              </TableRow>
              <TableRow>
                <TableCell>Региональный бюджет</TableCell>
                <PriceCell value={purchase.fulfilled_obligations_rb} />
              </TableRow>
              <TableRow>
                <TableCell>Муниципальный бюджет</TableCell>
                <PriceCell value={purchase.fulfilled_obligations_mb} />
              </TableRow>
              <TableRow>
                <TableCell>Внебюджетные средства</TableCell>
                <PriceCell value={purchase.fulfilled_obligations_vb} />
              </TableRow>
            </SubTable>
          )}

          <SubTable>
            <TableRow>
              <TableCell>Способ определения поставщика</TableCell>
              <TableCell>{purchase.supplier_determination_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Закон</TableCell>
              <TableCell>{purchase.supplier_determination_law_name}</TableCell>
            </TableRow>
          </SubTable>
          <SubTable title="Начальная (максимальная) цена контракта">
            <TableRow>
              <TableCell>Всего</TableCell>
              <PriceCell value={purchase.financing_total} />
            </TableRow>
            <TableRow>
              <TableCell>Федеральный бюджет</TableCell>
              <PriceCell
                value={purchase.financing_sfb}
                signals={signals.financing_sfb}
              />
            </TableRow>
            <TableRow>
              <TableCell>Региональный бюджет</TableCell>
              <PriceCell value={purchase.financing_skbsrf} />
            </TableRow>
            <TableRow>
              <TableCell>Муниципальный бюджет</TableCell>
              <PriceCell value={purchase.financing_mb} />
            </TableRow>
            <TableRow>
              <TableCell>Внебюджетные средства</TableCell>
              <PriceCell value={purchase.financing_vb} />
            </TableRow>
          </SubTable>
          <SubTable title="Срок размещения закупки в ЕИС">
            <TableRow>
              <TableCell>Плановый. Начало</TableCell>
              <DateCell value={purchase.eis_publication_planned_start} />
            </TableRow>
            <TableRow>
              <TableCell>Плановый. Конец</TableCell>
              <DateCell value={purchase.eis_publication_planned_end} />
            </TableRow>
            <TableRow>
              <TableCell>Фактический</TableCell>
              <DateCell
                value={purchase.eis_publication_fact}
                signals={signals.eis_publication_fact}
              />
            </TableRow>
          </SubTable>
          <PurchaseChanges
            title="Перенос срока размещения закупки в ЕИС"
            items={purchase.eis_publication_changes}
          />
          <SubTable>
            <TableRow>
              <TableCell>
                Ссылка на закупку на портале zakupki.gov.ru, прочие площадки
              </TableCell>
              <LinkCell value={purchase.purchase_link} />
            </TableRow>
            <TableRow>
              <TableCell>Номер закупки</TableCell>
              <TableCell>{purchase.eis_number}</TableCell>
            </TableRow>
          </SubTable>
          <SubTable title="Срок заключения контракта/договора">
            <TableRow>
              <TableCell>Плановый</TableCell>
              <DateCell value={purchase.contract_sign_planned_end} />
            </TableRow>
            <TableRow>
              <TableCell>Фактический</TableCell>
              <DateCell
                value={purchase.contract_sign_fact}
                signals={signals.contract_sign_fact}
              />
            </TableRow>
          </SubTable>
          <PurchaseChanges
            title="Перенос срока заключения контракта/договора"
            items={purchase.contract_sign_changes}
            oneDateOnly={true}
          />
          <SubTable title="Цена контракта/договора">
            <TableRow>
              <TableCell>Всего</TableCell>
              <PriceCell value={purchase.contract_summ_total} />
            </TableRow>
            <TableRow>
              <TableCell>в т.ч. отечественного производства</TableCell>
              <PriceCell value={purchase.contract_summ_domestic} />
            </TableRow>
            <TableRow>
              <TableCell>Федеральный бюджет</TableCell>
              <PriceCell
                value={purchase.contract_summ_sfb}
                signals={signals.contract_summ_sfb}
              />
            </TableRow>
            <TableRow>
              <TableCell>Региональный бюджет</TableCell>
              <PriceCell value={purchase.contract_summ_skbsrf} />
            </TableRow>
            <TableRow>
              <TableCell>Муниципальный бюджет</TableCell>
              <PriceCell value={purchase.contract_summ_mb} />
            </TableRow>
            <TableRow>
              <TableCell>Внебюджетные средства</TableCell>
              <PriceCell value={purchase.contract_summ_vb} />
            </TableRow>
          </SubTable>
          <SubTable title="Отклонение цены контракта/договора от начальной (максимальной) цены контракта/договора">
            <TableRow>
              <TableCell>Всего</TableCell>
              <PriceCell
                value={purchase.financing_deviation_total}
                signals={signals.financing_deviation_total}
              />
            </TableRow>
            <TableRow>
              <TableCell>Федеральный бюджет</TableCell>
              <PriceCell
                value={purchase.financing_deviation_sfb}
                signals={signals.financing_deviation_sfb}
              />
            </TableRow>
            <TableRow>
              <TableCell>Региональный бюджет</TableCell>
              <PriceCell
                value={purchase.financing_deviation_skbsrf}
                signals={signals.financing_deviation_skbsrf}
              />
            </TableRow>
            <TableRow>
              <TableCell>Муниципальный бюджет</TableCell>
              <PriceCell
                value={purchase.financing_deviation_mb}
                signals={signals.financing_deviation_mb}
              />
            </TableRow>
            <TableRow>
              <TableCell>Внебюджетные средства</TableCell>
              <PriceCell
                value={purchase.financing_deviation_vb}
                signals={signals.financing_deviation_vb}
              />
            </TableRow>
            <TableRow>
              <TableCell>Причина (обоснование)</TableCell>
              <TableCell>{purchase.deviation_reason}</TableCell>
            </TableRow>
          </SubTable>
          <SubTable title="Поставщик">
            <TableRow>
              <TableCell>ИНН</TableCell>
              <TableCell>{purchase.supplier_inn}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>КПП</TableCell>
              <TableCell>{purchase.supplier_kpp}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Наименование</TableCell>
              <TableCell>{purchase.supplier_name}</TableCell>
            </TableRow>
          </SubTable>
          <SubTable>
            <TableRow>
              <TableCell>
                Планируемый срок поставки (подписание акта), дата
              </TableCell>
              <DateCell value={purchase.delivery_planned} />
            </TableRow>
          </SubTable>
          <PurchasePaymentSchedules
            title="График платежей по федеральному бюджету"
            items={purchase.payment_schedules}
            signals={signals.payment_schedules}
          />
          <PurchaseComponents
            title="Состав закупки"
            purchaseComponents={purchase.purchase_components}
          />
          <SubTable>
            <TableRow>
              <TableCell>Примечание (причины, обоснование)</TableCell>
              <TableCell>{purchase.description}</TableCell>
            </TableRow>
          </SubTable>
          <PurchaseInfralistsComponent
            title={'Позиции оборудования инфраструктурного листа'}
            purchaseInfralist={purchase.rvpo_equipments}
          />
          <SubTable>
            <TableRow>
              <TableCell>Дата создания</TableCell>
              <DateTimeCell value={purchase.created_at} />
            </TableRow>
            <TableRow>
              <TableCell>Дата обновления</TableCell>
              <DateTimeCell value={purchase.updated_at} />
            </TableRow>
            <TableRow>
              <TableCell>Версия</TableCell>
              <TableCell>{purchase.version}</TableCell>
            </TableRow>
          </SubTable>
        </div>
      )}
    </>
  );
};

PurchasePage.propTypes = {};

export default PurchasePage;
