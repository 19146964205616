import { skipIfNull } from '@proscom/prostore';
import {
  QUERY_GET_FORM_DOCUMENT,
  QUERY_GET_FORM_DOCUMENTS
} from '../../graphql/queries/forms';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { tryParseJson } from '../../utils/helpers';

const formDocumentsQueryOptions = {
  query: QUERY_GET_FORM_DOCUMENTS,
  mapData: (r) => r.formDocuments,
  skipQuery: skipIfNull(null)
};

const formDocumentQueryOptions = {
  query: QUERY_GET_FORM_DOCUMENT,
  mapData: (r) => ({
    ...r.formDocument,
    data: tryParseJson(r.formDocument.data)
  }),
  skipQuery: skipIfNull(null)
};

export function useFormDocuments(filter, pagination) {
  return useRetriableGraphql({
    queryOptions: formDocumentsQueryOptions,
    variables: filter
      ? {
          input: {
            filter,
            pagination
          }
        }
      : null
  });
}

export function useFormDocument(id) {
  return useRetriableGraphql({
    queryOptions: formDocumentQueryOptions,
    variables: id
      ? {
          id
        }
      : null
  });
}
