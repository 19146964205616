import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../../../common/useRetriableGraphqlVars';
import { QUERY_GET_DIRECTION_RVPO_COMMENTS } from '../../../../graphql/queries/infralistComment';

const options = {
  query: QUERY_GET_DIRECTION_RVPO_COMMENTS,
  mapData: (result) => result.infralistDirectionRvpoCommentaries,
  skipQuery: skipIfNull(null)
};

export function useFetchDirectionRVPOComments({ infralistId, directionId }) {
  const response = useRetriableGraphql({
    queryOptions: options,
    variables: {
      infralist_id: infralistId,
      direction_id: directionId
    },
    options: {
      apolloOptions: {
        fetchPolicy: 'network-only'
      }
    }
  });
  const data = response.state.data;

  return data ?? [];
}
