import React from 'react';
import { Form } from '../../../../store/form/Form';
import { formatCurrency } from '../../../../utils/number';
import { formatNumber } from '../../../../utils/formatNumber';
import { FormDocumentSelectField } from './FormDocumentSelectField';

export function FormDocumentDisplayField({ value, field }) {
  if (field.input_type === Form.InputType.CURRENCY) {
    return formatCurrency(value);
  } else if (field.input_type === Form.InputType.INTEGER) {
    return formatNumber(value, 0);
  } else if (field.input_type === Form.InputType.FLOAT) {
    return formatNumber(value);
  } else if (field.input_type === Form.InputType.SELECT) {
    return (
      <FormDocumentSelectField entity={field.entity_class} value={value} />
    );
  }

  return value || '';
}
