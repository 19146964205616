import { useState } from 'react';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../graphql/graphqlErrors';
import { useApproveConfirmation } from '../Form/useApproveConfirmation.js';

export function useConfirmRow(confirmFn, entityName, onApply = undefined) {
  const [isConfirming, setIsConfirming] = useState(false);

  const confirmForm = (input) => {
    setIsConfirming(true);

    return confirmFn(input)
      .catch((error) => {
        showGraphqlErrorToast(error, mutationErrors.confirmObject);
      })
      .finally(() => {
        setIsConfirming(false);

        if (onApply) {
          onApply();
        }
      });
  };

  const [dialog, openDialog] = useApproveConfirmation(confirmForm, {
    entityName: entityName
  });

  return [isConfirming, dialog, openDialog];
}
