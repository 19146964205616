import React from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { removeLastPathItem } from '../../../../utils/url';
import { useUrlQuery } from '../../../../common/useUrlQuery';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { ParentalAssistanceGuideline } from '../../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import ParentalAssistanceGuidelinesOrganizationalEditForm from '../edit/ParentalAssistanceGuidelinesOrganizationalEditForm';
import { useNonCommercialOrganization } from '../../../../store/parentalAssistanceGuideline/useNonCommercialOrganization';
import { STORE_PARENTAL_ASSISTANCE_GUIDELINE } from '../../../../store/names';
import { handleFormSubmission } from '../../../../utils/data/form';
import { ParentalAssistanceGuidelinesOrganizationalValidationSchema } from '../../../../utils/data/validation';
import s from '../../ParentalAssistanceGuidelines.module.scss';

export default function ParentalAssistanceGuidelinesOrganizationalCreatePage({
  location,
  history,
  match
}) {
  const [query] = useUrlQuery(location, history);
  const backLink = removeLastPathItem(match.url);
  const { organizationId } = query;

  const parentalAssistanceGuideline = useContextStore(
    STORE_PARENTAL_ASSISTANCE_GUIDELINE
  );

  const nonCommercialOrganizationQuery = useNonCommercialOrganization(
    organizationId
  );
  const nonCommercialOrganization = nonCommercialOrganizationQuery.state.data;

  const initialValues = {
    non_commercial_organization_id: organizationId,
    status: 'DRAFT',
    list_of_local_service_regulations: [],
    list_of_regional_service_regulations: [],

    free_service_recipient_groups: '',
    service_delivery_formats: [],
    other_service_delivery_formats: '',
    special_categories_of_service_recievers: '',
    special_categories_of_service_recievers_description: '',
    babysitting_in_service_time: undefined,
    babysitting_in_service_time_description: '',
    waiting_zone_exists: undefined,
    waiting_zone_description: '',
    comfortable_zone_for_disabled_children: undefined,
    comfortable_zone_for_disabled_children_description: '',
    paid_consultations_for_parents: undefined,
    paid_consultations_for_parents_description: '',
    registration_methods: [],
    other_registration_methods: '',
    possible_specialist_choice: undefined,
    possible_time_choice: undefined,
    possible_format_choice: undefined,
    possible_consultation_topic_choice: undefined,
    possible_nwh_services_provisioning: undefined,
    possible_weekend_services_provisioning: undefined,
    maximum_waiting_time_exists: undefined,
    maximum_waiting_time: '',
    average_duration_of_services_receivement: '',
    average_duration_of_services_provisioning: '',
    official_site_has_info: undefined,
    official_site_has_info_description: '',
    official_site_has_specialists_info: undefined,
    official_site_has_specialists_info_description: '',
    has_approved_mediaplan: undefined,
    information_distribution_description: '',
    information_distribution_links: '',
    typical_consultations_description: '',
    services_quality_is_being_controlled: undefined,
    services_quality_is_being_controlled_on_portal: undefined,
    services_quality_control_methodology: '',
    problems_in_new_service_introduction_exist: undefined,
    problems_in_new_service_introduction_description: '',

    amount_of_lended_f2f_services_2019: '',
    amount_of_lended_f2f_services_2020: '',
    amount_of_lended_remote_services_2019: '',
    amount_of_lended_remote_services_2020: '',
    amount_of_lended_field_services_2019: '',
    amount_of_lended_field_services_2020: '',
    amount_of_lended_another_format_services_2019: '',
    amount_of_lended_another_format_services_2020: '',
    amount_of_lended_free_services_2019: '',
    amount_of_lended_free_services_2020: '',
    amount_of_lended_paid_services_2019: '',
    amount_of_lended_paid_services_2020: '',
    amount_of_onetime_service_recipients_2019: '',
    amount_of_onetime_service_recipients_2020: '',
    amount_of_multipletimes_service_recipients_2019: '',
    amount_of_multipletimes_service_recipients_2020: '',
    amount_of_specialists_in_state_2019: '',
    amount_of_specialists_in_state_2020: '',
    amount_of_contract_specialists_2019: '',
    amount_of_contract_specialists_2020: '',
    amount_of_prepared_specialists_2019: '',
    amount_of_prepared_specialists_2020: '',
    amount_of_service_rejections_2019: '',
    amount_of_service_rejections_2020: '',
    service_rejections_description_2019: '',
    service_rejections_description_2020: ''
  };

  return (
    <div className={s.wrapper}>
      <HeadTitle
        type="Перечень вопросов по апробации методических рекомендаций"
        pageType={HeadTitle.PageType.edit}
      />
      <h2>
        Редактирование перечня вопросов по апробации методических рекомендаций
      </h2>
      <Formik
        validationSchema={
          ParentalAssistanceGuidelinesOrganizationalValidationSchema
        }
        initialValues={ParentalAssistanceGuideline.toOrganizationalForm(
          initialValues
        )}
        onSubmit={(values, actions) => {
          const form = ParentalAssistanceGuideline.fromOrganizationalForm(
            values
          );
          handleFormSubmission(
            parentalAssistanceGuideline.createParentalAssistanceGuidelinesOrganizational(
              form
            ),
            actions,
            (result) =>
              history.goBack(
                ParentalAssistanceGuideline.getOrganizationalUrl(
                  result.data.result
                )
              )
          );
        }}
        render={(formikProps) => (
          <ParentalAssistanceGuidelinesOrganizationalEditForm
            {...formikProps}
            nonCommercialOrganization={nonCommercialOrganization}
            onBackClick={() => history.goBack(backLink)}
          />
        )}
      />
    </div>
  );
}
