import React, { useCallback } from 'react';
import { useContextStore } from '@proscom/prostore-react';
import queryString from 'query-string';
import { apiUrl } from '../../../config';
import { formatFileSize } from '../../../utils/formatNumber';
import { number } from '../../../utils/number';
import { DocumentStatus } from '../../../store/roadmap/documentStatus';
import { Signal } from '../../../common/Inputs/Signals';
import { reformatDate } from '../../../utils/date';
import { getFullFileName } from '../../../utils/data/file';
import { SubsidiaryEntity } from '../../../store/subsidiaryEntity/SubsidiaryEntity';
import { STORE_FILE_TOKEN } from '../../../store/names';
import { showGraphqlErrorToast } from '../../../graphql/graphqlErrors';
import s from './EventPage.module.scss';

export function SubsidiaryEntityInfo({ subsidiaryEntity }) {
  if (!subsidiaryEntity) return null;
  const completeName = SubsidiaryEntity.getCompleteName(subsidiaryEntity);
  return (
    <div>
      <span className={s.filePropCaptionStyle}>Субсидиарная сущность: </span>
      <span>{completeName}</span>
    </div>
  );
}

function getRoadmapDownloadLink(id, auth) {
  return queryString.stringifyUrl({
    url: `${apiUrl}/files/roadmapDocument/${id}`,
    query: { token: auth }
  });
}

const useAuthFileToken = () => {
  const tokenStore = useContextStore(STORE_FILE_TOKEN);

  return useCallback(
    (id) => {
      tokenStore
        .createRoadmapDocumentToken(id)
        .then((auth) => {
          window.open(getRoadmapDownloadLink(id, auth));
        })
        .catch((error) => {
          showGraphqlErrorToast(error);
        });
    },
    [tokenStore]
  );
};

export const SimpleFile = ({ document, showDocumentStatus = true }) => {
  const subsidiaryEntity = document.subsidiary_entity;
  const file = document.connected_file;

  const handleDownload = useAuthFileToken();

  if (!file) {
    return (
      <div>
        <Signal>Ошибка. Файл недоступен</Signal>
      </div>
    );
  }
  const fileName = getFullFileName(file);

  return (
    <>
      <div className={s.gutterBottomStyle}>
        <div className={'external'} onClick={() => handleDownload(document.id)}>
          {fileName}
        </div>
      </div>
      <SubsidiaryEntityInfo subsidiaryEntity={subsidiaryEntity} />
      <div>
        <span className={s.filePropCaptionStyle}>Размер: </span>
        <span>{formatFileSize(number(file.file_size))}</span>
      </div>
      {showDocumentStatus && (
        <div>
          <span className={s.filePropCaptionStyle}>Статус: </span>
          <span>{DocumentStatus.getStatusName(document.status)}</span>
        </div>
      )}
      {document.comment_fpo && (
        <div>
          <span className={s.filePropCaptionStyle}>Комментарий ФПО: </span>
          <span>{document.comment_fpo}</span>
        </div>
      )}
    </>
  );
};

export const FileWithNumberAndApprovalDate = ({
  document,
  showDocumentStatus = true
}) => {
  const subsidiaryEntity = document.subsidiary_entity;
  const file = document.connected_file;

  const handleDownload = useAuthFileToken();

  if (!file) {
    return (
      <div>
        <Signal>Ошибка. Файл недоступен</Signal>
      </div>
    );
  }
  const fileName = getFullFileName(file);

  return (
    <>
      <div className={s.gutterBottomStyle}>
        <div className="external" onClick={() => handleDownload(document.id)}>
          {fileName}
        </div>
      </div>
      <SubsidiaryEntityInfo subsidiaryEntity={subsidiaryEntity} />
      <div>
        <span className={s.filePropCaptionStyle}>Размер: </span>
        <span>{formatFileSize(number(file.file_size))}</span>
      </div>
      <div>
        <span className={s.filePropCaptionStyle}>Номер документа: </span>
        <span>{document.number}</span>
      </div>
      <div>
        <span className={s.filePropCaptionStyle}>Дата утверждения: </span>
        <span>{reformatDate(document.date_approval)}</span>
      </div>
      {showDocumentStatus && (
        <div>
          <span className={s.filePropCaptionStyle}>Статус: </span>
          <span>{DocumentStatus.getStatusName(document.status)}</span>
        </div>
      )}
      {document.comment_fpo && (
        <div>
          <span className={s.filePropCaptionStyle}>Комментарий ФПО: </span>
          <span>{document.comment_fpo}</span>
        </div>
      )}
    </>
  );
};

export const FileWithLicense = ({ document, showDocumentStatus = true }) => {
  const subsidiaryEntity = document.subsidiary_entity;
  const file = document.connected_file;

  const handleDownload = useAuthFileToken();

  if (!file) {
    return (
      <div>
        <Signal>Ошибка. Файл недоступен</Signal>
      </div>
    );
  }
  const fileName = getFullFileName(file);

  return (
    <>
      <div className={s.gutterBottomStyle}>
        <div className="external" onClick={() => handleDownload(document.id)}>
          {fileName}
        </div>
      </div>
      <SubsidiaryEntityInfo subsidiaryEntity={subsidiaryEntity} />
      <div>
        <span className={s.filePropCaptionStyle}>Размер: </span>
        <span>{formatFileSize(number(file.file_size))}</span>
      </div>
      <div>
        <span className={s.filePropCaptionStyle}>Номер документа: </span>
        <span>{document.number}</span>
      </div>
      <div>
        <span className={s.filePropCaptionStyle}>Срок окончания: </span>
        <span>{reformatDate(document.license_expiration_date)}</span>
      </div>
      <div>
        <span className={s.filePropCaptionStyle}>Дата утверждения: </span>
        <span>{reformatDate(document.date_approval)}</span>
      </div>
      {showDocumentStatus && (
        <div>
          <span className={s.filePropCaptionStyle}>Статус: </span>
          <span>{DocumentStatus.getStatusName(document.status)}</span>
        </div>
      )}
      {document.comment_fpo && (
        <div>
          <span className={s.filePropCaptionStyle}>Комментарий ФПО: </span>
          <span>{document.comment_fpo}</span>
        </div>
      )}
    </>
  );
};

export const DocumentLink = ({ document, showDocumentStatus = true }) => {
  const subsidiaryEntity = document.subsidiary_entity;
  const linkStyle = {
    display: 'inline-block',
    maxWidth: '350px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  };

  return (
    <>
      <div>
        <a
          href={document.content}
          className="external"
          style={linkStyle}
          target="_blank"
          rel="noopener noreferrer"
          title={document.content}
        >
          {document.content}
        </a>
      </div>
      <SubsidiaryEntityInfo subsidiaryEntity={subsidiaryEntity} />
      {showDocumentStatus && (
        <div>
          <span className={s.filePropCaptionStyle}>Статус: </span>
          <span>{DocumentStatus.getStatusName(document.status)}</span>
        </div>
      )}
      {document.comment_fpo && (
        <div>
          <span className={s.filePropCaptionStyle}>Комментарий ФПО: </span>
          <span>{document.comment_fpo}</span>
        </div>
      )}
    </>
  );
};
