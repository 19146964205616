import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { ComponentWithTooltip } from '../../../../../common/ComponentWithTooltip/ComponentWithTooltip';
import Dialog from '../../../../../common/Dialog';
import { Popup } from '../../../../../common/Popup/Popup';
import { ProgressButton } from '../../../../../common/ProgressButton';
import { showGraphqlErrorToast } from '../../../../../graphql/graphqlErrors';
import { PreventChangePage } from '../../../../../PreventChangePage';
import { useSubscribeSingleSectionOrEquipmentLock } from '../../../../../store/infralist/subscription/useSubscribeSingleSectionOrEquipmentLock';
import { useEquipmentMutations } from '../../../../../store/infralist/useEquipmentMutations';
import { useInfralistSectionCheckers } from '../../../../../store/infralist/useInfralistUserAccesses';
import { useSection } from '../../../../../store/infralist/useSection';
import { useCreateEquipmentPageValidationSchema } from '../../../../createEquipment/useCreateEquipmentPageValidationSchema';
import { EquipmentInfoForm } from './EquipmentInfoForm';
import { EquipmentInfoStatic } from './EquipmentInfoStatic';
import s from './EquipmentInfo.module.scss';

export function EquipmentInfo({
  data,
  equipmentNameMap,
  equipmentNames,
  infralistStatus,
  isEditEnabledByEventStatus
}) {
  const [edit, setEdit] = useState(false);
  const [popup, setPopup] = useState(false);
  const { editEquipment } = useEquipmentMutations();
  const [blockEditingPopup, setBlockEditingPopup] = useState(false);
  const { infralistId, sectionId, equipmentId } = useParams();
  const sectionQuery = useSection(sectionId, infralistId);
  const sectionStatus = sectionQuery.state.data?.status?.name;
  const infralistSectionApproveStatus =
    sectionQuery.state.data?.approvement_status?.name;

  const infralistSectionCheckStatus =
    sectionQuery.state.data?.check_status?.name;
  const { checkEditEquipments } = useInfralistSectionCheckers({
    sectionStatus,
    infralistSectionCheckStatus,
    infralistStatus,
    isEditEnabledByEventStatus
  });
  const {
    blocked,
    lockEditing,
    unlockEditing,
    closeRefreshPagePopup,
    isRefreshPagePopupVisible
  } = useSubscribeSingleSectionOrEquipmentLock({
    objectId: equipmentId,
    objectType: 'Equipment'
  });

  const canEdit = checkEditEquipments(data);

  const makeEditable = () => {
    setEdit(true);
    lockEditing();
  };

  const onCancel = (dirty) => {
    if (dirty) {
      setPopup(true);
    } else {
      setEdit(false);
      unlockEditing();
    }
  };

  const onSubmit = (values) => {
    return editEquipment(values)
      .then(() => unlockEditing())
      .finally(() => setEdit(false))
      .catch(showGraphqlErrorToast);
  };
  const validationSchema = useCreateEquipmentPageValidationSchema();

  // сброс блокировки на анмаунт компонента
  useEffect(() => {
    return () => {
      unlockEditing();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (blocked && edit) {
      setBlockEditingPopup(true);
      unlockEditing();
      setEdit(false);
    }
  }, [blocked, edit, unlockEditing]);

  const closeBlockedPopup = () => {
    setBlockEditingPopup(false);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={data}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {(form) => (
        <>
          <PreventChangePage when={form.dirty} />
          <div className={s.topLine}>
            <div className={s.title}>Оборудование</div>
            {edit ? (
              <div className={s.buttons}>
                <ProgressButton
                  color="secondary"
                  className={s.edit}
                  onClick={() => {
                    if (form.dirty) {
                      form.submitForm();
                    }
                  }}
                  isLoading={form.isSubmitting}
                >
                  Сохранить
                </ProgressButton>
                <Button className={s.edit} onClick={() => onCancel(form.dirty)}>
                  Отменить
                </Button>
              </div>
            ) : (
              <div className={s.buttons}>
                <ComponentWithTooltip
                  placement={'right'}
                  isTooltipExists={blocked}
                  title={
                    'Данная позиция оборудования уже редактируется, попробуйте позже'
                  }
                >
                  <Button
                    color={'primary'}
                    className={s.edit}
                    onClick={makeEditable}
                    disabled={!canEdit || blocked}
                  >
                    Редактировать
                  </Button>
                </ComponentWithTooltip>
              </div>
            )}
          </div>
          <Popup
            open={blockEditingPopup}
            onClose={closeBlockedPopup}
            title={'Оборудование уже редактируется'}
          >
            Данная позиция оборудования уже редактируется, попробуйте позже
          </Popup>
          {edit ? (
            <EquipmentInfoForm
              data={form.values}
              equipmentMap={equipmentNameMap}
              equipments={equipmentNames}
              infralistId={infralistId}
              infralistSectionCheckStatus={infralistSectionCheckStatus}
              sectionStatus={sectionStatus}
              equipmentId={form.values?.id}
              infralistStatus={infralistStatus}
              infralistSectionApproveStatus={infralistSectionApproveStatus}
              isEditEnabledByEventStatus={isEditEnabledByEventStatus}
            />
          ) : (
            <EquipmentInfoStatic
              data={form.values}
              equipmentMap={equipmentNameMap}
              infralistId={infralistId}
              infralistSectionCheckStatus={infralistSectionCheckStatus}
              sectionStatus={sectionStatus}
              equipmentId={form.values?.id}
              canEdit={canEdit}
              infralistStatus={infralistStatus}
              infralistSectionApproveStatus={infralistSectionApproveStatus}
              isEditEnabledByEventStatus={isEditEnabledByEventStatus}
            />
          )}
          <Dialog
            title={
              'Вы уверены, что хотите закрыть форму редактирования? Имеются несохраненные данные.'
            }
            open={popup}
            handleAccept={() => {
              setPopup(false);
              setEdit(false);
              form.setValues(data);
              unlockEditing();
            }}
            handleClose={() => {
              setPopup(false);
              setEdit(true);
            }}
          />
          <Popup
            open={isRefreshPagePopupVisible}
            onClose={closeRefreshPagePopup}
            title={
              'Оборудование было на редактировании, перезагрузите страницу.'
            }
          />
        </>
      )}
    </Formik>
  );
}

EquipmentInfo.propTypes = {
  data: PropTypes.object,
  equipmentNameMap: PropTypes.object,
  statuses: PropTypes.array,
  equipmentNames: PropTypes.array,
  backUrl: PropTypes.string
};
