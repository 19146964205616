import React from 'react';
import classNames from 'classnames';
import { Sorting } from './Sorting';
import s from './SortingLabel.module.scss';

export const SortLabel = ({ field, label, state, setState }) => {
  return (
    <div
      className={s.SortLabel}
      onClick={() => {
        if (state[field] === Sorting.SortDirection.ASC) {
          setState({
            ...state,
            [field]: Sorting.SortDirection.DESC,
            isActiveFields: [field]
          });
        } else {
          setState({
            ...state,
            [field]: Sorting.SortDirection.ASC,
            isActiveFields: [field]
          });
        }
      }}
    >
      <div>{label}</div>
      <div
        className={classNames(s.SortLabel__arrows, {
          [s._active]: state.isActiveFields.includes(field)
        })}
      >
        {state[field] === Sorting.SortDirection.ASC ? (
          <div>&#9650;</div>
        ) : (
          <div> &#9660;</div>
        )}
      </div>
    </div>
  );
};
