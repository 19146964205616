import React from 'react';
import InputGroup from '../../../../../common/Inputs/InputGroup';
import { NewTextInput } from '../../../../../common/InputsNew/NewTextInput';

export const AnnotationInfo = React.memo(function AnnotationInfo({
  canEditRegionalField
}) {
  return (
    <InputGroup>
      <NewTextInput
        name="annotation"
        multiline
        label="Примечание (причины, обоснование)"
        fullWidth
        disabled={!canEditRegionalField}
      />
    </InputGroup>
  );
});
