import React, { useMemo } from 'react';
import { useContextStore } from '@proscom/prostore-react';
import { Formik } from 'formik';
import { ADDITIONAL_DATA_STORE } from '../../../../../../store/names';
import { removeLastPathItem } from '../../../../../../utils/url';
import { AdditionalDataBreadCrumbs } from '../AdditionalDataBreadCrumbs';
import { AdditionalData } from '../../../../../../store/additionalData/AdditionalData';
import { AdditionalDataEditForm } from '../edit/AdditionalDataEditForm';
import { useRegionalProject } from '../../../../../../store/regionalProject/useRegionalProject';
import { useRoadmap } from '../../../../../../store/roadmap/useRoadmap';
import { handleFormSubmission } from '../../../../../../utils/data/form';
import { useUrlQuery } from '../../../../../../common/useUrlQuery';
import { useCurrentUser } from '../../../../../../store/useCurrentUser';
import { classesScheduleValidationSchema } from '../../../../../../utils/data/validation';
import { HeadTitle } from '../../../../../../common/HeadTitle/HeadTitle';

export default function AdditionalDataCreatePage({ match, history, location }) {
  const additionalDataStore = useContextStore(ADDITIONAL_DATA_STORE);
  const backLink = removeLastPathItem(match.url);
  const [query] = useUrlQuery(location, history);

  const { projectId, roadmapId } = match.params;
  const { year } = query;

  const projectQuery = useRegionalProject(projectId);
  const project = useMemo(() => {
    if (!projectQuery.state.data) return {};
    return projectQuery.state.data;
  }, [projectQuery.state.data]);

  const regionId = project?.region?.id;

  const roadmapQuery = useRoadmap(roadmapId);
  const roadmap = useMemo(() => {
    if (!roadmapQuery.state.data) return {};
    return roadmapQuery.state.data;
  }, [roadmapQuery.state.data]);

  const initialValue = {
    comment_fpo: '',
    fact_date: '',
    status: 'DRAFT',
    subsidiary_entity_id: '',
    year: +year
  };

  const user = useCurrentUser();
  const validationSchema = useMemo(
    () => classesScheduleValidationSchema(initialValue, user),
    [initialValue, user]
  );

  return (
    <>
      <HeadTitle
        pageType={HeadTitle.PageType.create}
        type="График начала занятий"
      />
      <AdditionalDataBreadCrumbs
        project={project}
        projectId={projectId}
        roadmapId={roadmapId}
        roadmap={roadmap}
      />
      <h2>Добавить график начала занятий по субсидиарной сущности</h2>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValue}
        onSubmit={(values, actions) => {
          const additionalData = AdditionalData.fromForm(values);
          handleFormSubmission(
            additionalDataStore.updateOrCreateClassesSchedule(additionalData),
            actions,
            (result) =>
              history.push(
                AdditionalData.getUrl(result.data.result, projectId, roadmapId)
              )
          );
        }}
      >
        {(formikProps) => (
          <AdditionalDataEditForm
            {...formikProps}
            onBackClick={() => history.goBack(backLink)}
            roadmapId={roadmapId}
            regionId={regionId}
            year={year}
          />
        )}
      </Formik>
    </>
  );
}
