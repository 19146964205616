import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { ParentalAssistanceGuideline } from '../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import { SubTableHeading } from '../../../common/SubTable/SubTableHeading';

export const PossibleChoice = ({ data, startOfList }) => {
  return (
    <>
      <SubTableHeading>
        {startOfList}. Предусмотрена ли возможность для получателя услуги
        выбора:
      </SubTableHeading>
      <TableRow>
        <TableCell>- специалиста</TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(
            data.possible_specialist_choice
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>- времени оказания услуги</TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(data.possible_time_choice)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>- формата предоставления услуги</TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(data.possible_format_choice)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>- тематики консультации заранее</TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(
            data.possible_consultation_topic_choice
          )}
        </TableCell>
      </TableRow>
      <SubTableHeading>
        {startOfList + 1}. Предусмотрена ли возможность предоставления услуг:
      </SubTableHeading>
      <TableRow>
        <TableCell>- в нерабочее время?</TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(
            data.possible_nwh_services_provisioning
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>- в выходные дни?</TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(
            data.possible_weekend_services_provisioning
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
