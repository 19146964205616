import React, { useCallback, useEffect, useMemo } from 'react';
import {
  getCurrentMonth,
  getCurrentYear,
  yearsSuggestions
} from '../../utils/data/date';
import { useMemoSuggestions } from '../../utils/suggestions';
import { useRoadmapsForMonitoring } from '../../store/roadmap/useRoadmaps';
import AutocompleteBaseInput from '../../common/Inputs/Selects/AutocompleteBaseInput';
import { User } from '../../store/role/User';
import { useRegions } from '../../store/subsidiaryEntity/useSubsidiaryEntity';
import { queryLoader } from '../../common/QueryLoader';
import { SubsidiaryMonitoringEntity } from '../../store/subsidiaryMonitoring/SubsidiaryMonitoringEntity';
import { useUserRole } from '../../store/role/useUserRole';
import { useSubsidiaryEntitiesForMonitoring } from '../../store/subsidiaryEntity/useSubsidiaryEntities';
import { SubsidiaryEntity } from '../../store/subsidiaryEntity/SubsidiaryEntity';

export const useSubsidiaryMonitoringFilter = (query, changeQuery, user) => {
  const { isRegionAdmin } = useUserRole(user);
  const canViewOwn = SubsidiaryMonitoringEntity.canViewOwn(user);

  const regionsQuery = useRegions();
  const regions = regionsQuery.state.data;
  const availableRegions = regions && User.getRegions(user, regions);
  const regionsSuggestions = useMemoSuggestions(availableRegions);

  const regionId = isRegionAdmin ? user?.region?.id : query.region_id;
  const year = query.year || getCurrentYear().toString();
  const month =
    query.month || SubsidiaryMonitoringEntity.monthArray[getCurrentMonth() - 1];

  const filter = canViewOwn
    ? {
        year: +year,
        month,
        subsidiary_entity_id: query.subsidiary_entity_id,
        status: query.status
      }
    : {
        year: +year,
        month,
        region_id: regionId,
        roadmap_id: query.roadmap_id,
        status: query.status
      };

  const setFilterValue = useCallback(
    (value, name) => {
      const queryObj = {
        [name]: value ?? undefined,
        page: 0
      };

      if (['year', 'month', 'region_id'].includes(name)) {
        queryObj.roadmap_id = undefined;
        queryObj.subsidiary_entity_id = undefined;
      }

      return changeQuery(queryObj);
    },
    [changeQuery]
  );

  useEffect(() => {
    changeQuery({
      year,
      month,
      page: 0
    });
  }, [year, month, changeQuery]);

  useEffect(() => {
    if (isRegionAdmin && regionId) {
      setFilterValue(regionId, 'region_id');
    }
  }, [isRegionAdmin, regionId, setFilterValue]);

  const roadmapFilter = useMemo(() => {
    return !canViewOwn && filter.year
      ? {
          form_year: filter.year,
          region_id: filter.region_id
        }
      : null;
  }, [filter.region_id, filter.year, canViewOwn]);
  const roadmapsQuery = useRoadmapsForMonitoring(roadmapFilter);
  const roadmaps = roadmapsQuery.state.data;
  const roadmapsSuggestions = useMemoSuggestions(roadmaps);

  const subsidiaryEntitiesFilter = useMemo(() => {
    return canViewOwn && year
      ? {
          form_year: +year
        }
      : null;
  }, [year, canViewOwn]);
  const subsidiaryEntitiesQuery = useSubsidiaryEntitiesForMonitoring(
    subsidiaryEntitiesFilter
  );
  const querySubsidiaryEntities = subsidiaryEntitiesQuery.state.data;
  const subsidiaryEntities = useMemo(() => {
    return querySubsidiaryEntities?.map((entity) => ({
      ...entity,
      name_full: SubsidiaryEntity.getCompleteName(entity)
    }));
  }, [querySubsidiaryEntities]);
  const subsidiaryEntitiesSuggestions = useMemoSuggestions(subsidiaryEntities);

  const filterComponent = queryLoader(regionsQuery) || (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Год"
        name="year"
        suggestions={yearsSuggestions}
        value={year}
        variant="standard"
        isClearable={false}
      />
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Месяц"
        name="month"
        suggestions={SubsidiaryMonitoringEntity.monthSuggestions}
        value={month}
        variant="standard"
        isClearable={false}
      />
      {canViewOwn ? (
        <AutocompleteBaseInput
          onChange={setFilterValue}
          hasCopyBtn={false}
          label="Субсидиарная сущность"
          name="subsidiary_entity_id"
          suggestions={subsidiaryEntitiesSuggestions}
          suggestionsLoading={subsidiaryEntitiesQuery.check.spinner}
          value={filter.subsidiary_entity_id}
          variant="standard"
          resetIfNotExists={true}
          disabled={!subsidiaryEntitiesFilter}
          placeholder={!subsidiaryEntitiesFilter && 'Сначала укажите год'}
        />
      ) : (
        <>
          {regionsSuggestions?.length > 1 && (
            <AutocompleteBaseInput
              onChange={setFilterValue}
              hasCopyBtn={false}
              label="Регион"
              name="region_id"
              suggestions={regionsSuggestions}
              suggestionsLoading={regionsQuery.check.spinner}
              value={filter.region_id}
              variant="standard"
              disabled={isRegionAdmin}
            />
          )}
          <AutocompleteBaseInput
            onChange={setFilterValue}
            hasCopyBtn={false}
            label="Дорожная карта"
            name="roadmap_id"
            suggestions={roadmapsSuggestions}
            suggestionsLoading={roadmapsQuery.check.spinner}
            value={filter.roadmap_id}
            variant="standard"
            resetIfNotExists={true}
            disabled={!roadmapFilter}
            placeholder={!roadmapFilter && 'Сначала укажите год'}
          />
        </>
      )}
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Статус"
        name="status"
        suggestions={SubsidiaryMonitoringEntity.statusSuggestions}
        value={filter.status}
        variant="standard"
      />
    </div>
  );
  return [filter, filterComponent];
};
