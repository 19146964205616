import React from 'react';
import classNames from 'classnames';
import { ReactComponent as Arrow } from '../../assets/icons/Arrow.svg';
import s from './Dropdown.module.scss';

export function DropdownTitle({
  children,
  opened,
  setOpened,
  className,
  iconClassName
}) {
  return (
    <div
      className={classNames(s.DropdownTitle, className)}
      onClick={() => setOpened(!opened)}
    >
      <div>{children}</div>
      <div
        className={classNames(s.DropdownTitle__icon, iconClassName, {
          [s._active]: opened
        })}
      >
        <Arrow />
      </div>
    </div>
  );
}

DropdownTitle.propTypes = {};
