import React, { useMemo } from 'react';
import BreadCrumbs from '../../common/BreadCrumbs/BreadCrumbs';

export function getInfralistPageBreadCrumbs({ roadmapName }) {
  return [
    {
      label: roadmapName
    }
  ];
}

export const InfralistPageBreadCrumbs = ({ roadmapName }) => {
  const breadCrumbsLinks = useMemo(() => {
    return getInfralistPageBreadCrumbs({
      roadmapName
    });
  }, [roadmapName]);

  return <BreadCrumbs links={breadCrumbsLinks} />;
};
