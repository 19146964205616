import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export const RadioButtonGroup = ({ value, id, label, children, ...rest }) => {
  return (
    <FormControl style={{ marginBottom: '1.5rem' }}>
      {label && <h4>{label}</h4>}
      <RadioGroup name={id} value={value} row {...rest}>
        {children}
      </RadioGroup>
    </FormControl>
  );
};

export const RadioButton = ({ value, label, ...rest }) => (
  <FormControlLabel
    value={value}
    control={<Radio color="primary" {...rest} />}
    label={label}
    labelPlacement="bottom"
  />
);
