import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_PURCHASE_COMPONENT_CATEGORIES } from '../../graphql/queries/purchaseComponentCategories';

const purchaseComponentCategoriesQueryOptions = {
  query: QUERY_GET_PURCHASE_COMPONENT_CATEGORIES,
  mapData: (result) => result.purchaseComponentCategories,
  skipQuery: skipIfNull(null)
};

export function usePurchaseComponentCategories() {
  return useRetriableGraphql({
    queryOptions: purchaseComponentCategoriesQueryOptions,
    variables: {}
  });
}
