import React, { useEffect, useMemo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useContextStore } from '@proscom/prostore-react';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import BreadCrumbs from '../../../common/BreadCrumbs/BreadCrumbs';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { Notifications } from '../../../store/notification/Notification';
import SubTable from '../../../common/SubTable/SubTable';
import { queryLoaderWithExistenceCheck } from '../../../common/QueryLoader';
import { formatDateIso, tryParseIso } from '../../../utils/date';
import { getNotificationBreadCrumbs } from '../getNotificationBreadCrumbs';
import { useNotification } from '../../../store/notification/useNotifications';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { STORE_NOTIFICATION_COUNT_STORE } from '../../../store/names';
import s from '../Notifications.module.scss';

function NotificationPage({ match }) {
  const notificationCountStore = useContextStore(
    STORE_NOTIFICATION_COUNT_STORE
  );
  const { id } = match.params;
  const user = useCurrentUser();
  const breadCrumbs = useMemo(() => getNotificationBreadCrumbs(), []);
  const notificationQuery = useNotification(+id);
  const notification = notificationQuery.state.data;

  useEffect(() => {
    if (notification) {
      notificationCountStore.updateNotificationCount();
    }
  }, [notificationCountStore, notification]);

  return (
    <>
      <HeadTitle type="Уведомление" />
      <BreadCrumbs links={breadCrumbs} />
      {queryLoaderWithExistenceCheck(notificationQuery) || (
        <>
          <PageTitle title={'Уведомление'} withBackBtn />
          <SubTable title="Общие сведения">
            <TableRow>
              <TableCell>Тема</TableCell>
              <TableCell>{notification.subject}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Раздел</TableCell>
              <TableCell>
                {Notifications.sections.getName(notification.section)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Кому</TableCell>
              <TableCell>{user.login}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>От кого</TableCell>
              <TableCell>
                {notification.sender
                  ? notification.sender.login
                  : 'Системное сообщение'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Дата</TableCell>
              <TableCell>
                {formatDateIso(tryParseIso(notification.sent_at))}
              </TableCell>
            </TableRow>
          </SubTable>
          <SubTable cols={2}>
            <TableRow className={s.Notification__content}>
              <div className={s.Notification__contentTitle}>
                Текст уведомления
              </div>
              {/* todo: Обработать возможную XSS */}
              <div
                className={s.Notification__contentText}
                dangerouslySetInnerHTML={{ __html: notification.content }}
              ></div>
            </TableRow>
          </SubTable>
        </>
      )}
    </>
  );
}

export default NotificationPage;
