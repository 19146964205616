import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';

import { QUERY_GET_SIGNALS } from '../../../../graphql/queries/purchases';

const signalsOptions = {
  query: QUERY_GET_SIGNALS,
  mapData: (r) => r.signals,
  skipQuery: skipIfNull(null)
};

export const useServerSignal = (formValues, skipQuery) => {
  return useGraphqlQuery({
    queryOptions: signalsOptions,
    variables: !skipQuery
      ? {
          purchase: formValues
        }
      : null
  });
};
