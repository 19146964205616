import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';
import { HeadTitle } from '../../../../../common/HeadTitle/HeadTitle';
import { COMMENT_TYPES } from '../../../../../store/infralist/commentEntities';
import { CommentList } from '../CommentList/CommentList';
import { MessageHistory } from '../MessageHistory/MessageHistory';
import s from './CommentPage.module.scss';

export const CommentPage = ({
  title,
  backUrl,
  baseUrl,
  backUrlTitle,
  renderInfoContent,
  fpoComments,
  rvpoComments,
  generalComments,
  createComment,
  updateComment,
  deleteComment,
  infralistStatus,
  commentType,
  rejectComment,
  acceptComment,
  exportCommentaries,
  isEditEnabledByEventStatus
}) => {
  const history = useHistory();

  const back = () => history.push(backUrl);

  const commentariesMap = {
    fpo: fpoComments,
    rvpo: rvpoComments,
    general: generalComments
  };

  const isDownloadCommentariesDisabled = () => {
    const comments = commentariesMap[commentType];
    if (!comments) {
      return true;
    }
    return comments.length === 0;
  };

  return (
    <>
      <HeadTitle title={title} />
      <div className={s.container}>
        <div className={s.info}>
          <div className={s.back}>
            <IconButton size="small" onClick={back}>
              <ArrowBackIcon className={s.backIcon} />
            </IconButton>
            <p className={s.backTitle}>{backUrlTitle}</p>
          </div>
          {renderInfoContent}
        </div>
        {commentType === COMMENT_TYPES.GENERAL && (
          <CommentList
            data={generalComments}
            createComment={createComment}
            commentType={commentType}
            deleteComment={deleteComment}
            updateComment={updateComment}
            status={infralistStatus}
            acceptComment={acceptComment}
            rejectComment={rejectComment}
            isEditEnabledByEventStatus={isEditEnabledByEventStatus}
          />
        )}
        {commentType === COMMENT_TYPES.FPO && (
          <CommentList
            data={fpoComments}
            createComment={createComment}
            commentType={commentType}
            deleteComment={deleteComment}
            updateComment={updateComment}
            status={infralistStatus}
            acceptComment={acceptComment}
            rejectComment={rejectComment}
            isEditEnabledByEventStatus={isEditEnabledByEventStatus}
          />
        )}
        {commentType === COMMENT_TYPES.RVPO && (
          <CommentList
            data={rvpoComments}
            createComment={createComment}
            commentType={commentType}
            deleteComment={deleteComment}
            updateComment={updateComment}
            status={infralistStatus}
            acceptComment={acceptComment}
            rejectComment={rejectComment}
            isEditEnabledByEventStatus={isEditEnabledByEventStatus}
          />
        )}
        <MessageHistory
          commentType={commentType}
          status={infralistStatus}
          baseUrl={baseUrl}
          downloadDisabled={isDownloadCommentariesDisabled()}
          exportCommentaries={exportCommentaries}
        />
      </div>
    </>
  );
};
