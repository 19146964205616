import React from 'react';
import { FieldArray } from 'formik';
import { Spoiler } from '../../../../common/Spoiler/Spoiler';
import { ContestDocsIndicatorEditable } from '../ContestDocsIndicator/ContestDocsIndicatorEditable';

export const ContestDocsRoadmapEditable = ({
  index,
  values,
  roadmap,
  touched,
  errors
}) => {
  const indicators = roadmap.roadmap_indicators;
  const hasIndicators = !!indicators && indicators.length > 0;

  const roadmapTouched = touched.roadmaps?.[index];
  const roadmapErrors = errors.roadmaps?.[index];
  const roadmapError =
    roadmapTouched?.indicators?.length > 0 &&
    roadmapErrors?.indicators?.length > 0;

  return hasIndicators ? (
    <Spoiler
      title={`${roadmap.roadmap?.name}:`}
      errorMessage={roadmapError && 'Заполнены не все индикаторы'}
    >
      <FieldArray name={`roadmaps[${index}].indicators`}>
        {() =>
          indicators?.map((ind, iInd) => (
            <ContestDocsIndicatorEditable
              key={iInd}
              index={iInd}
              rmIndex={index}
              indicator={ind}
              values={values}
              touched={touched}
              errors={errors}
            />
          ))
        }
      </FieldArray>
    </Spoiler>
  ) : null;
};
