import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { infralistStatusService } from '../../store/infralist/infralistStatusService';
import styles from './InfralistStatus.module.scss';

export const InfralistStatus = ({
  status = infralistStatusService.defaultStatus,
  isLoading
}) => {
  return (
    <div
      className={classNames(styles.root)}
      style={{
        color: infralistStatusService.getColor(status)
      }}
    >
      <span className={styles.statusPoint} />
      <span className={styles.statusText}>{isLoading ? '...' : status}</span>
    </div>
  );
};

InfralistStatus.propTypes = {
  status: PropTypes.string
};
