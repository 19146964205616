import { StatusService } from '../../utils/StatusService';
import { EQUIPMENT_STATUSES } from './equipmentStatusService';
import colors from '../../colors.scss';

// статусы инфралиста, разделов и направлений
export const INFRALIST_STATUSES = {
  NOT_FORMED: 'Не сформировано',
  // только для разделов и направлений
  FORMED: 'Сформировано',

  ON_SIGNING: 'На подписании',
  // только для разделов и направлений
  SIGNED: 'Подписано',
  NOT_SIGNED: 'Не подписано',

  ON_CHECKING: 'На проверке',

  ON_APPROVAL: 'На согласовании',
  APPROVED: 'Согласовано',
  APPROVED_WITH_COMMENTS: 'Согласовано с замечаниями',
  // для разделов
  NOT_APPROVED: 'Не согласовано'
};

export const infralistStatusDescriptions = {
  [INFRALIST_STATUSES.NOT_FORMED]:
    'Инфралист находится на формировании у РВПО1',
  [INFRALIST_STATUSES.ON_SIGNING]: 'Инфралист находится на подписании у РВПО2',
  [INFRALIST_STATUSES.NOT_SIGNED]: 'Инфралист находится на доработке у РВПО1',
  [INFRALIST_STATUSES.ON_CHECKING]: 'Инфралист находится на проверке у ФПО1',
  [INFRALIST_STATUSES.ON_APPROVAL]:
    'Инфралист находится на согласовании у ФПО2',
  [INFRALIST_STATUSES.APPROVED]: 'Инфралист согласован ФПО2',
  [INFRALIST_STATUSES.APPROVED_WITH_COMMENTS]:
    'Инфралист согласован ФПО2 с замечаниями',
  [INFRALIST_STATUSES.NOT_APPROVED]: 'Инфралист находится на доработке у РВПО2'
};

export const infralistStatusService = new StatusService({
  colors: {
    [INFRALIST_STATUSES.NOT_FORMED]: colors.graytable,
    [INFRALIST_STATUSES.FORMED]: colors.greenBright,
    [INFRALIST_STATUSES.ON_SIGNING]: colors.blue,
    [INFRALIST_STATUSES.SIGNED]: colors.greenBright,
    [INFRALIST_STATUSES.NOT_SIGNED]: colors.red,
    [INFRALIST_STATUSES.ON_CHECKING]: colors.blue,
    [INFRALIST_STATUSES.ON_APPROVAL]: colors.blue,
    [INFRALIST_STATUSES.APPROVED]: colors.greenBright,
    [INFRALIST_STATUSES.NOT_APPROVED]: colors.red,
    [INFRALIST_STATUSES.APPROVED_WITH_COMMENTS]: colors.yellowLight
  },
  defaultStatus: EQUIPMENT_STATUSES.NOT_FORMED
});
