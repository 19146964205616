import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import classNames from 'classnames';
import SubTable from '../../../../common/SubTable/SubTable';
import { getName } from '../../../../utils/data/label';
import s from '../RpImplementationForecastPage.module.scss';

export const PassportForecasts = ({ passportForecasts }) => {
  return (
    <SubTable title="Изменения в паспорт РП">
      <TableRow>
        <TableCell className={classNames(s.tableCell, s.tableCellMedium)}>
          Региональный проект
        </TableCell>
        <TableCell className={classNames(s.tableCell, s.tableCellLarge)}>
          Предложения
        </TableCell>
      </TableRow>
      {passportForecasts?.map((passportForecast, iPassportForecast) => (
        <TableRow key={iPassportForecast}>
          <TableCell className={classNames(s.tableCell, s.tableCellMedium)}>
            {getName(passportForecast.federal_project)}
          </TableCell>
          <TableCell className={classNames(s.tableCell, s.tableCellLarge)}>
            {passportForecast.suggestions}
          </TableCell>
        </TableRow>
      ))}
    </SubTable>
  );
};
