import React, { useMemo } from 'react';
import { stringify } from 'query-string';
import PageTitle from '../../common/PageTitle/PageTitle';
import { useCurrentUser } from '../../store/useCurrentUser';
import { useUrlQuery } from '../../common/useUrlQuery';
import { usePofRegionalDocuments } from '../../store/pof/pofQueries';
import { queryLoaderWithExistenceCheck } from '../../common/QueryLoader';
import { Signal } from '../../common/Inputs/Signals';
import { getMonth } from '../../utils/data/date';
import { getName } from '../../utils/data/label';
import { reformatDate } from '../../utils/date';
import { PofsListTable } from './PofsListTable';
import { PofStatus } from './pof/PofStatus';
import { usePofsFilter } from './usePofsFilter';

const POFsColumns = [
  { label: '№', key: 'order', isThin: true },
  {
    label: 'Месяц',
    key: 'month',
    renderCell: (value) => getMonth(value - 1),
    isThin: true
  },
  { label: 'Год', key: 'year', isThin: true },
  {
    label: 'Региональный проект',
    key: 'regional_project',
    isWide: true,
    isBreak: true,
    renderCell: (value) => getName(value)
  },
  {
    label: 'Код направления расходов',
    key: 'expenses_direction.code',
    isThin: true
  },
  {
    label: 'Наименование направления расходов',
    key: 'expenses_direction.name_full',
    isWide: true,
    isBold: true
  },
  {
    label: 'Статус',
    key: 'status',
    isThin: true,
    renderCell: (value) => <PofStatus status={value} />
  },
  {
    label: 'Дата статуса',
    key: 'status_changed_at',
    isThin: true,
    renderCell: (value) => reformatDate(value)
  }
];

export default function Pofs({ match, history, location }) {
  const user = useCurrentUser();

  const [query, changeQuery] = useUrlQuery(location, history);
  const [filter, filterComponent, maxMonth] = usePofsFilter(
    query,
    changeQuery,
    user
  );

  const pofsQuery = usePofRegionalDocuments(filter);
  const pofs = useMemo(() => {
    return pofsQuery.state.data?.map((pof, iPof) => ({
      ...pof,
      order: iPof + 1
    }));
  }, [pofsQuery.state.data]);

  return (
    <>
      <PageTitle withBackBtn={false} title="Реестр ПОФ" />
      {user?.region ? (
        <>
          <Signal warning>
            Для просмотра уже созданных ПОФ можно воспользоваться фильтрами ниже
          </Signal>
          {!filter.regional_project_ids && (
            <Signal warning>
              Для создания нового ПОФ следует сначала выбрать региональный
              проект
            </Signal>
          )}
        </>
      ) : (
        <Signal warning>
          Для просмотра необходимых ПОФ можно воспользоваться фильтрами ниже
        </Signal>
      )}
      <Signal warning>
        Документ ПОФ субъекту следует предоставлять до 25 числа каждого месяца
      </Signal>
      {queryLoaderWithExistenceCheck(pofsQuery) || (
        <PofsListTable
          match={match}
          history={history}
          data={pofs}
          canCreate={filter.regional_project_ids && filter.month <= maxMonth}
          POFsColumns={POFsColumns}
          addFieldRoute={
            '/pofs/create?' +
            stringify({
              year: filter.year,
              month: filter.month,
              regionalProjectId: filter.regional_project_ids
            })
          }
          rowRedirectRoute={`${match.url}/`}
          filter={filterComponent}
        />
      )}
    </>
  );
}
