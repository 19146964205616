import React, { useMemo, useState } from 'react';
import Async from 'react-select/async/dist/react-select.esm';
import debounce from 'debounce-promise';
import classNames from 'classnames';
import { defaultPaginationParams } from '../../usePagination';
import { showGraphqlErrorToast } from '../../../graphql/graphqlErrors';
import {
  commonSelectComponents,
  loadingMessage,
  noOptionsMessage
} from './common';
import s from '../Inputs.module.scss';

export default ({
  defaultValue,
  errorMessage = null,
  filterParams,
  getItems,
  fullWidth,
  name,
  hasCopyBtn = true,
  label,
  onChange,
  onBlur,
  required = false,
  mapData,
  useObject = false,
  variant = 'outlined',
  value: inputValue,
  ...rest
}) => {
  const [transformedDefaultValue, setDefaultValue] = useState(
    defaultValue
      ? { label: defaultValue.name_full, value: defaultValue.id }
      : null
  );
  const loadOptions = useMemo(() => debounce(getItems, 500), [getItems]);

  const value =
    typeof inputValue === 'string' ? transformedDefaultValue : inputValue;

  return (
    <Async
      defaultOptions={true}
      loadOptions={(inputValue) =>
        loadOptions({
          filter: { ...filterParams, search: inputValue },
          pagination: defaultPaginationParams
        })
          .then(({ data }) => {
            return mapData(data);
          })
          .catch((e) => {
            showGraphqlErrorToast(e);
          })
      }
      className={s.Field}
      components={commonSelectComponents}
      hasCopyBtn={hasCopyBtn}
      // inputId={name}
      isClearable={true}
      name={name}
      loadingMessage={loadingMessage}
      noOptionsMessage={noOptionsMessage}
      openOnFocus={true}
      onChange={(item) => {
        setDefaultValue(item);
        onChange(!item || useObject ? item : item.value);
      }}
      onBlur={onBlur}
      placeholder={false}
      TextFieldProps={{
        label,
        error: !!errorMessage,
        helperText: errorMessage,
        InputLabelProps: {
          htmlFor: name,
          shrink: true,
          required
        },
        fullWidth,
        classes: {
          root: classNames(s.InputString, 'autocompleteInput')
        },
        variant
      }}
      value={value}
      menuPortalTarget={document.body}
      {...rest}
    />
  );
};
