import { get } from 'lodash-es';
import { FastField } from 'formik';
import React from 'react';

const customShouldUpdate = (props, oldProps) => {
  const dependencyChanged =
    props.dependentFields &&
    props.dependentFields.some(
      (field) => oldProps.formik.values[field] !== props.formik.values[field]
    );
  return !!(
    get(oldProps.formik.values, oldProps.name) !==
      get(props.formik.values, oldProps.name) ||
    get(oldProps.formik.errors, oldProps.name) !==
      get(props.formik.errors, oldProps.name) ||
    get(oldProps.formik.touched, oldProps.name) !==
      get(props.formik.touched, oldProps.name) ||
    Object.keys(oldProps).length !== Object.keys(props).length ||
    get(oldProps.signals, oldProps.name) !== get(props.signals, props.name) ||
    oldProps.formik.isSubmitting !== props.formik.isSubmitting ||
    dependencyChanged
  );
};

export const CustomFastField = (props) => {
  return (
    <FastField shouldUpdate={customShouldUpdate} name={props.name} {...props} />
  );
};
