import { useGraphqlQueryVars } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { QUERY_GET_EXPENSES_DIRECTION } from '../../graphql/queries/expensesDirections';

const expensesDirectionQueryOptions = {
  query: QUERY_GET_EXPENSES_DIRECTION,
  mapData: (result) => result.expensesDirection,
  skipQuery: skipIfNull(null)
};

export function useExpensesDirection(id) {
  return useGraphqlQueryVars({
    queryOptions: expensesDirectionQueryOptions,
    variableCreator: [
      () =>
        id
          ? {
              id
            }
          : null,
      [id]
    ]
  });
}
