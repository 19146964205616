import React, { useCallback } from 'react';
import { useContextStore } from '@proscom/prostore-react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { STORE_PURCHASE } from '../../store/names';
import { usePurchases } from '../../store/purchase/usePurchases';
import PageTitle from '../../common/PageTitle/PageTitle';
import Table from '../../common/Table/Table';
import Menu from '../../common/Menu/Menu';
import { useDeleteConfirmation } from '../../common/Form/useDeleteConfirmation';
import {
  QueryPagination,
  useQueryPagination,
  useQueryPaginationCount
} from '../../common/usePagination';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../graphql/graphqlErrors';
import { useUrlQuery } from '../../common/useUrlQuery';
import { useCurrentUser } from '../../store/useCurrentUser';
import { queryLoaderWithExistenceCheck } from '../../common/QueryLoader';
import { Purchase } from '../../store/purchase/Purchase';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';
import { Signal } from '../../common/Inputs/Signals';
import { usePurchasesFilter } from './usePurchasesFilter';

const purchaseColumns = [
  { label: '№', key: 'order', isThin: true },
  { label: 'Код', key: 'number', isThin: true },
  { label: 'Объект закупки', key: 'item', isWide: true, isBold: true },
  {
    label: 'Заказчик',
    key: 'customer_name_display',
    isWide: true,
    isBreak: true
  },
  { label: 'ГРБС', key: 'grbs_name', isWide: true, isBreak: true },
  {
    label: 'Статус',
    key: (row) => Purchase.status.getName(row.status),
    isWide: true,
    isBreak: true
  }
  // {
  //   label: 'Плановая дата',
  //   isThin: true,
  //   key: (row) => {
  //     if (!(row.status === Purchase.Status.CONTRACTED)) {
  //       return Purchase.getNearestFridayDate();
  //     }
  //     return '';
  //   }
  // }
];

const title = 'Все закупки';

function PurchasesPage({ match, location, history }) {
  const user = useCurrentUser();
  const regionId = user?.region?.id;

  const [query, changeQuery] = useUrlQuery(location, history);
  const pagination = useQueryPagination(query, changeQuery, 20);

  const [filterComponent, filter] = usePurchasesFilter(
    query,
    changeQuery,
    regionId
  );
  const purchasesQuery = usePurchases(filter, pagination.params);
  useQueryPaginationCount(pagination, purchasesQuery);

  const purchaseStore = useContextStore(STORE_PURCHASE);
  const deletePurchase = useCallback(
    (id) => {
      purchaseStore.deletePurchase(id).catch((error) => {
        showGraphqlErrorToast(error, mutationErrors.deleteObject);
      });
    },
    [purchaseStore]
  );
  const [dialog, openDialog] = useDeleteConfirmation(deletePurchase, {
    entityName: 'закупку'
  });
  const data = purchasesQuery.state.data?.data;

  return (
    <>
      <HeadTitle title={title} />
      <PageTitle withBackBtn={false} title={title} />
      {queryLoaderWithExistenceCheck(purchasesQuery) || (
        <>
          <Signal warning style={{ marginBottom: 32 }}>
            Необходимо подтверждать актуальность заполненных данных по закупкам
            по направлению расходов еженедельно каждую пятницу до 16:00 по
            московскому времени
          </Signal>
          <Table
            addButtonText="Добавить закупку"
            addFieldRoute={`/purchase/create`}
            columns={purchaseColumns}
            isLoading={purchasesQuery.check.spinner}
            data={data}
            filter={filterComponent}
            hasAddField={true}
            renderNoData={() => <h3>Нет закупок по выбранным фильтрам</h3>}
            rowRedirectRoute={`${match.url}/`}
            renderMenu={(row) => (
              <Menu
                iconColor="default"
                icon={MoreHorizIcon}
                options={[
                  {
                    title: 'Редактировать',
                    onClick: () => history.push(`${match.url}/${row.id}/edit`)
                  },
                  {
                    title: 'Удалить',
                    onClick: () => openDialog(row.id)
                  }
                ]}
              />
            )}
            pagination={<QueryPagination pagination={pagination} />}
          />
        </>
      )}
      {dialog}
    </>
  );
}

export default PurchasesPage;
