import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { COMMENT_TYPES } from '../../../../store/infralist/commentEntities';
import { useFetchSectionFPOComments } from '../../../../store/infralist/comments/section/useFetchSectionFPOComments';
import { useFetchSectionGeneralComments } from '../../../../store/infralist/comments/section/useFetchSectionGeneralComments';
import { useFetchSectionRVPOComments } from '../../../../store/infralist/comments/section/useFetchSectionRVPOComments';
import { useSectionComments } from '../../../../store/infralist/comments/section/useSectionComments';
import { useCommentReadMutations } from '../../../../store/infralist/comments/useCommentReadMutations';
import { useInfralist } from '../../../../store/infralist/useInfralist';
import { useSection } from '../../../../store/infralist/useSection';
import { useMapToIdList } from '../../../../utils/hooks/useMapToIdList';
import { useMakeDecisionSectionCommentaries } from '../../../../store/infralist/comments/useMakeDecisionSectionCommentaries';
import { useExportInfralistComments } from '../../useExportInfralistComments';
import { CommentPage } from '../components/CommentPage/CommentPage';
import { SectionInfo } from './SectionInfo';

export const SectionCommentsPage = () => {
  const { infralistId, sectionId, directionId, type } = useParams();
  const location = useLocation();
  const client = useContextApolloClient();

  const fpoComments = useFetchSectionFPOComments({ infralistId, sectionId });
  const rvpoComments = useFetchSectionRVPOComments({ infralistId, sectionId });
  const generalComments = useFetchSectionGeneralComments({
    infralistId,
    sectionId
  });
  const section = useSection(sectionId, infralistId);
  const infralist = useInfralist(infralistId);
  const infralistStatus = infralist.state.data?.infralist_status?.name;
  const {
    commentType,
    deleteCommentary,
    updateCommentary,
    createCommentary
  } = useSectionComments({ infralistId, sectionId, type });
  const { acceptComment, rejectComment } = useMakeDecisionSectionCommentaries();

  const onAccept = ({ content, ids }) => {
    return acceptComment({ content, sectionId, infralistId, ids });
  };

  const onReject = ({ content, ids }) => {
    return rejectComment({ content, ids, infralistId, sectionId });
  };

  const exportCommentaries = useExportInfralistComments('section', {
    infralistId,
    directionId,
    sectionId
  });

  // обновляем данные при переходе с уведомления, чтобы отобразить актуальные комментарии
  useEffect(() => {
    if (location.state?.notificationId) {
      client.reFetchObservableQueries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, location.state?.notificationId]);

  // отправка событий что комментарии были прочитаны
  const { markSectionCommentsAsRead } = useCommentReadMutations({
    infralistId
  });

  const fpoCommentsIds = useMapToIdList(fpoComments);
  useEffect(() => {
    if (fpoCommentsIds.length > 0 && type === COMMENT_TYPES.FPO) {
      markSectionCommentsAsRead({ commentIds: fpoCommentsIds });
    }
  }, [fpoCommentsIds, markSectionCommentsAsRead, type]);

  const rvpoCommentsIds = useMapToIdList(rvpoComments);
  useEffect(() => {
    if (rvpoCommentsIds.length > 0 && type === COMMENT_TYPES.RVPO) {
      markSectionCommentsAsRead({ commentIds: rvpoCommentsIds });
    }
  }, [rvpoCommentsIds, markSectionCommentsAsRead, type]);

  const generalCommentsIds = useMapToIdList(generalComments);
  useEffect(() => {
    if (generalCommentsIds.length > 0 && type === COMMENT_TYPES.GENERAL) {
      markSectionCommentsAsRead({ commentIds: generalCommentsIds });
    }
  }, [generalCommentsIds, markSectionCommentsAsRead, type]);

  return (
    <CommentPage
      commentType={commentType}
      updateComment={updateCommentary}
      deleteComment={deleteCommentary}
      createComment={createCommentary}
      fpoComments={fpoComments}
      rvpoComments={rvpoComments}
      generalComments={generalComments}
      baseUrl={`/infralist-comments/${infralistId}/directions/${directionId}/section/${sectionId}`}
      backUrl={`/infralist/${infralistId}/directions/${directionId}`}
      backUrlTitle={'Назад к списку разделов'}
      infralistStatus={infralistStatus}
      acceptComment={onAccept}
      rejectComment={onReject}
      exportCommentaries={exportCommentaries}
      isEditEnabledByEventStatus={infralist.state.data?.editable}
      renderInfoContent={
        <SectionInfo
          data={section.state.data}
          infralistStatus={infralistStatus}
        />
      }
    />
  );
};
