import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_INDICATOR_FORECAST } from '../../graphql/queries/indicatorForecasts';

const indicatorForecastQueryOptions = {
  query: QUERY_GET_INDICATOR_FORECAST,
  mapData: (result) => result.indicatorForecast,
  skipQuery: skipIfNull(null)
};

export function useIndicatorForecast(id, networkOnly = false) {
  return useRetriableGraphqlVars({
    queryOptions: indicatorForecastQueryOptions,
    variableCreator: [
      () =>
        id
          ? {
              id
            }
          : null,
      [id]
    ],
    options: {
      apolloOptions: {
        fetchPolicy: networkOnly ? 'network-only' : 'cache-first'
      }
    }
  });
}
