import React, { useEffect, useMemo, useState } from 'react';
import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import {
  getAsyncEntityLoader,
  syncEntities
} from '../../../../store/form/entities';
import { useEntityList } from '../../../../store/form/useEntityList';
import { queryLoaderInline } from '../../../../common/QueryLoader';
import { showGraphqlErrorToast } from '../../../../graphql/graphqlErrors';
import { getName } from '../../../../utils/data/label';

function FormDocumentSelectSyncField({ value, entity }) {
  const query = useEntityList(entity);
  const data = query.state.data;

  return (
    (value &&
      (queryLoaderInline(query) ||
        getName(data?.find((d) => d.id === value)))) ||
    false
  );
}

function FormDocumentSelectAsyncField({ value, entity }) {
  const client = useContextApolloClient();
  const [, loadOption] = useMemo(
    () => getAsyncEntityLoader({ entity, client }),
    [entity, client]
  );
  const [name, setName] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    if (!value) {
      setName('');
      setLoading(false);
    } else {
      loadOption(value)
        .then((result) => {
          if (mounted) {
            if (result) {
              setName(result.label);
            } else {
              setName(null);
            }
          }
        })
        .catch((error) => showGraphqlErrorToast(error))
        .then(() => setLoading(false));
    }

    return () => {
      mounted = false;
    };
  }, [value, loadOption]);

  return (loading && '...') || name || '';
}

export function FormDocumentSelectField({ value, entity }) {
  if (syncEntities.includes(entity)) {
    return <FormDocumentSelectSyncField value={value} entity={entity} />;
  }
  return <FormDocumentSelectAsyncField value={value} entity={entity} />;
}
