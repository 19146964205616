import { useState } from 'react';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../graphql/graphqlErrors';
import { useRevokeApprovalConfirmation } from '../Form/useRevokeApprovalConfirmation';

export function useRevokeConfirmationRow(
  removeConfirmationFn,
  entityName,
  onApply = undefined
) {
  const [isRevokeConfirmation, setIsRevokeConfirmation] = useState(false);

  const revokeConfirmationForm = (id) => {
    setIsRevokeConfirmation(true);
    return removeConfirmationFn(id)
      .catch((error) => {
        showGraphqlErrorToast(error, mutationErrors.revokeConfirmationObject);
      })
      .finally(() => {
        setIsRevokeConfirmation(false);
        if (onApply) {
          onApply();
        }
      });
  };

  const [dialog, openDialog] = useRevokeApprovalConfirmation(
    revokeConfirmationForm,
    {
      entityName: entityName
    }
  );

  return [isRevokeConfirmation, dialog, openDialog];
}
