import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { SimpleFile } from './TypeComponents';
import s from './EventPage.module.scss';

export const EventOtherDocument = ({ document, ...props }) => {
  return (
    <TableRow>
      <TableCell className={s.documentNameCell}>{document.name}</TableCell>
      <TableCell>
        <SimpleFile document={document} {...props} />
      </TableCell>
    </TableRow>
  );
};
