import gql from 'graphql-tag';
import { FRAGMENT_POF_REGIONAL_DOCUMENT } from '../queries/pofs';

export const MUTATION_UPDATE_POF_REGIONAL_DOCUMENT = gql`
  mutation updatePofRegionalDocument($input: PofRegionalDocumentInput!) {
    result: updatePofRegionalDocument(input: $input) {
      ...pofRegionalDocument
    }
  }
  ${FRAGMENT_POF_REGIONAL_DOCUMENT}
`;

export const MUTATION_CREATE_POF_REGIONAL_DOCUMENT = gql`
  mutation createPofRegionalDocument($input: PofRegionalDocumentInput!) {
    result: createPofRegionalDocument(input: $input) {
      ...pofRegionalDocument
    }
  }
  ${FRAGMENT_POF_REGIONAL_DOCUMENT}
`;

export const MUTATION_DELETE_POF_REGIONAL_DOCUMENT = gql`
  mutation deletePofRegionalDocument($id: String!) {
    result: deletePofRegionalDocument(id: $id)
  }
`;
