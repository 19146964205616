import React, { useCallback, useState } from 'react';
import Dialog from '../Dialog';

export function useRevokeApprovalConfirmation(onConfirm, dialogProps = {}) {
  const [data, setData] = useState(null);
  const open = useCallback((data) => {
    setData(data);
  }, []);
  const handleClose = useCallback(() => setData(null), []);
  const { entityName = 'объект', ...otherDialogProps } = dialogProps;

  const dialog = (
    <Dialog
      title={`Вы уверены, что хотите отозвать утверждение ${entityName}?`}
      handleAccept={() => {
        handleClose();
        onConfirm(data);
      }}
      open={!!data}
      handleClose={handleClose}
      {...otherDialogProps}
    />
  );

  return [dialog, open];
}
