import Button from '@material-ui/core/Button';
import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ExportModal } from '../../../common/ExportModal/ExportModal';
import { Autocomplete } from '../../../common/infralist/FormFields/Autocomplete/Autocomplete';
import { useInfralistVersion } from '../../../store/infralist/versions/useInfralistVersion';
import { useInfralistVersionList } from '../../../store/infralist/versions/useInfralistVersionList';
import { useVersionRouteRelates } from '../../../store/infralist/versions/useVersionRouteRelates';
import { useExportInfralist } from '../useExportInfralist';
import { useDialog } from '../../../utils/hooks/useDialog';
import { showGraphqlErrorToast } from '../../../graphql/graphqlErrors';
import s from './VersionsPanel.module.scss';

export const VersionsPanel = () => {
  const { infralistId } = useParams();
  const { versionListOptions } = useInfralistVersionList({ infralistId });
  const { versionId } = useInfralistVersion();
  const history = useHistory();

  const {
    versionRote,
    params,
    isBaseRouteMatches,
    isVersionRouteMatches
  } = useVersionRouteRelates();
  const { exportInfralist } = useExportInfralist({
    infralistId,
    versionId
  });

  const {
    open: openExportModal,
    isOpen: isExportModalOpen,
    close: closeExportModal
  } = useDialog();

  const handleExport = (documentType) => {
    return exportInfralist(documentType)
      .catch(showGraphqlErrorToast)
      .finally(closeExportModal);
  };

  const handleChangeVersion = (nextVersion) => {
    if (isBaseRouteMatches || isVersionRouteMatches) {
      history.push(
        generatePath(versionRote, {
          ...params,
          versionId: nextVersion
        })
      );
    }
  };

  return (
    <div className={s.root}>
      <div className={s.title}>Версии инфралиста</div>
      <Autocomplete
        options={versionListOptions}
        value={versionId}
        onChange={handleChangeVersion}
        className={s.versionsSelect}
        placeholder={'Выберите дату'}
        name={'versions'}
      />

      <Button
        color={'secondary'}
        variant={'contained'}
        disabled={!versionId}
        onClick={openExportModal}
      >
        Скачать версию
      </Button>

      <ExportModal
        open={isExportModalOpen}
        onClose={closeExportModal}
        onExport={handleExport}
      />
    </div>
  );
};
