import React, { useMemo } from 'react';
import { groupBy } from 'lodash-es';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { FileInputButton } from '../../../../common/Form/FileInputButton';
import { RegionalEvent } from '../../../../store/roadmap/RegionalEvent';
import { getName } from '../../../../utils/data/label';
import { enableArchiveDownload } from '../../../../config';
import { useArchiveDownloadingFile } from '../../downloadArchiveFile';
import { Photo } from './Photo';
import { PhotoEdit } from './PhotoEdit';
import s from './EventPhotos.module.scss';

function RenderCategory({
  iCategory,
  title,
  categoryId,
  isEventCategory,
  entityId,
  editProps,
  disabled,
  regionEventId,
  categoriesDocuments
}) {
  const { handleArchiveDownload, isDownloading } = useArchiveDownloadingFile(
    regionEventId,
    null,
    {
      subsidiaryEntityIds: [entityId],
      photoCategoryIds: [categoryId]
    }
  );

  const documents = categoriesDocuments[categoryId];
  if (!(documents?.length > 0 || isEventCategory)) {
    return null;
  }
  if (disabled && !(documents?.length > 0)) {
    return null;
  }

  return (
    <div key={iCategory} className={s.Category}>
      <div className={s.Category__title}>{title}</div>
      <div>
        <div
          className={classNames(s.Category__photos, {
            [s._editMode]: editProps
          })}
        >
          {documents?.map((document, iDocument) => {
            if (editProps) {
              return (
                <PhotoEdit
                  key={iDocument}
                  document={document}
                  editProps={editProps}
                  disabled={disabled}
                />
              );
            } else {
              return <Photo key={iDocument} document={document} />;
            }
          })}
          {editProps?.canUploadDocuments && isEventCategory && !disabled && (
            <FileInputButton
              inputProps={{ accept: 'image/*', multiple: true }}
              onSelectFile={(e) =>
                editProps.handleFileChange(
                  e,
                  RegionalEvent.DocType.PHOTO,
                  undefined,
                  {
                    photo_category_id: categoryId,
                    subsidiary_entity_id: entityId,
                    event_id: regionEventId
                  },
                  true
                )
              }
            />
          )}
        </div>
      </div>
      {enableArchiveDownload && !editProps && documents?.length > 0 && (
        <div
          className={classNames(
            s.downloadLinkButton,
            s.downloadLinkButton__photoCategory
          )}
        >
          <Button
            variant="outlined"
            component="span"
            className={s.downloadLinkButton}
            onClick={handleArchiveDownload}
            disabled={isDownloading}
          >
            {isDownloading ? 'Скачивание' : 'Скачать архивом'}
          </Button>
        </div>
      )}
    </div>
  );
}

export function SubsidiaryEntityPhotosCategories({
  entityType,
  documents,
  eventId,
  entityId,
  editProps,
  disabled,
  regionEventId
}) {
  const categories = entityType?.photo_categories || [];

  const categoriesDocuments = useMemo(
    () => groupBy(documents || [], (doc) => doc.photo_category_id || null),
    [documents]
  );

  return (
    <div>
      {categories?.map((category, iCategory) => {
        const isEventCategory = category.roadmap_events?.find(
          (roadmap_event) => roadmap_event.id === eventId
        );
        return (
          <RenderCategory
            iCategory={iCategory}
            title={getName(category)}
            categoryId={category.id}
            isEventCategory={isEventCategory}
            entityId={entityId}
            editProps={editProps}
            disabled={disabled}
            regionEventId={regionEventId}
            categoriesDocuments={categoriesDocuments}
          />
        );
      })}
      {categoriesDocuments[null] && (
        <RenderCategory
          title={'Без категории'}
          entityId={entityId}
          editProps={editProps}
          disabled={disabled}
          regionEventId={regionEventId}
          categoriesDocuments={categoriesDocuments}
        />
      )}
    </div>
  );
}
