export const LAQUO = '\u00AB';
export const RAQUO = '\u00BB';
export const EMDASH = '–';
export const THINSP = '\u2009';
export const QUARTERSP = '\u2005';
export const THIRDSP = '\u2004';
export const ENSP = '\u2002';
export const EMSP = '\u2001';
export const NBSP = '\u00A0';
export const HELLIP = '\u2026';
export const LSAQUO = '\u2039';
export const RSAQUO = '\u203A';
export const RUBLE = '\u20BD';
export const LEQ = '\u2264';
export const NEQ = '\u2260';

export const unitRuble = `${NBSP}руб.`;

export const approveSuggestions = [
  { value: true, label: 'Только утвержденные' },
  { value: false, label: 'Только неутвержденные' }
];

export const PAGES_LIST = [
  { permissions: ['regional_project.access_page'], route: '/projects' },
  { permissions: ['purchase.access_page'], route: '/purchases' },
  { permissions: ['roadmap_event.access_page'], route: '/roadmaps' },
  {
    permissions: ['regional_event.access_page'],
    route: '/regionalRoadmapEvents'
  },
  {
    permissions: ['subsidiary_entity.access_page'],
    route: '/subsidiaryEntities'
  },
  { permissions: ['pof.access_page'], route: '/pofs' },
  { permissions: ['organization.access_monitoring'], route: '/monitoring' },
  { permissions: ['user.crud', 'user.crud_all'], route: '/users' },
  { permissions: ['role.crud', 'role.crud_all'], route: '/roles' },
  { permissions: ['form.access_fill_page'], route: '/fillForms' },
  { permissions: ['form.crud'], route: '/forms' },
  { permissions: ['information.access'], route: '/information' }
];
