import React, { useEffect } from 'react';
import { useCurrentUser } from '../../store/useCurrentUser';
import { useParentalAssistanceGuidelinesRegional } from '../../store/parentalAssistanceGuideline/useParentalAssistanceGuidelinesRegional';
import Loader from '../../common/Loader/Loader';

const title = 'Реестр перечня вопросов по апробации методических рекомендаций';

export default function ParentalAssistanceGuidelines({ history }) {
  const user = useCurrentUser();
  const regionId = user?.region?.id;
  const query = useParentalAssistanceGuidelinesRegional({
    region_ids: regionId
  });
  const guidelines = query.state.data;

  useEffect(() => {
    if (query.check.component) {
      if (guidelines && guidelines.length > 0) {
        history.replace(`/parentalAssistanceGuidelines/${guidelines[0].id}`);
      } else {
        history.replace(`/parentalAssistanceGuidelines/create`);
      }
    }
  }, [history, guidelines, query.check.component, regionId]);

  return <Loader />;
}
