import React, { useMemo, useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useContextStore } from '@proscom/prostore-react';
import { CircularProgress } from '@material-ui/core';
import PageTitle from '../../common/PageTitle/PageTitle';
import { useCurrentUser } from '../../store/useCurrentUser';
import {
  QueryPagination,
  useQueryPagination,
  useQueryPaginationCount
} from '../../common/usePagination';
import { useUrlQuery } from '../../common/useUrlQuery';
import Table from '../../common/Table/Table';
import Menu from '../../common/Menu/Menu';
import { CONTEST_DOCS_MONITORING_STORE } from '../../store/names';
import { ContestDocsMonitoringEntityApprovalValidationSchema } from '../../utils/data/validation';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';
import { useConfirmRow } from '../../common/Table/useConfirmRow';
import { useContestDocsMonitoringPage } from '../../store/contestDocsMonitoring/useContestDocsMonitoringPage';
import { ContestDocsMonitoringEntity } from '../../store/contestDocsMonitoring/ContestDocsMonitoringEntity';
import { createPreservedUrl } from '../../utils/links';
import { useDeleteRow } from '../../common/Table/useDeleteRow';
import { useGeneralConfirmationRow } from '../../common/Table/useGeneralConfirmationRow';
import { useContestDocsMonitoringFilter } from './useContestDocsMonitoringFilter';
import { ContestDocsEntityValidationDialog } from './ContestDocsMonitoringForm/ContestDocsEntityValidationDialog';

const contestDocsEntitiesColumns = [
  { label: '№', key: 'order', isThin: true },
  { label: 'Регион', key: 'region.name', isWide: true, isBreak: true },
  {
    label: 'Региональный проект',
    key: 'regional_project.federal_project.name',
    isWide: true,
    isBreak: true
  },
  {
    label: 'Статус',
    key: (entity) => ContestDocsMonitoringEntity.statusLabel[entity.status],
    isBreak: true
  }
];

export const contestDocsMonitoringPageTitle =
  'Информация в рамках конкурсной документации';

export default function ContestDocsMonitoringPage({
  history,
  location,
  match
}) {
  const user = useCurrentUser();
  const entityStore = useContextStore(CONTEST_DOCS_MONITORING_STORE);
  const [isValidationError, setIsValidationError] = useState(false);

  const [query, changeQuery] = useUrlQuery(location, history);
  const [filter, filterComponent] = useContestDocsMonitoringFilter(
    query,
    changeQuery,
    user
  );
  const pagination = useQueryPagination(query, changeQuery, 50);

  const entitiesQuery = useContestDocsMonitoringPage(filter, pagination.params);

  useQueryPaginationCount(pagination, entitiesQuery);
  const entitiesQueryData = entitiesQuery.state.data;
  const data = useMemo(() => {
    if (!entitiesQueryData) {
      return null;
    }
    const { list, header } = entitiesQueryData;
    return list.map((entity, iEntity) => ({
      ...entity,
      order: 1 + iEntity + header.page * header.onePage,
      queries: {
        year: entity.year,
        region_id: entity.region.id,
        regional_project_id: entity.regional_project.id
      }
    }));
  }, [entitiesQueryData]);

  const canCreate = ContestDocsMonitoringEntity.canEdit(user);

  const creationLink = useMemo(() => {
    return createPreservedUrl(`${match.url}/edit/`, {
      year: filter.year,
      region_id: filter.region_id,
      regional_project_id: filter.regional_project_id
    });
  }, [match, filter]);

  const menuComponent = useMemo(
    () =>
      function MenuComponent({ row }) {
        const [isDeleting, deleteDialog, openDeleteDialog] = useDeleteRow(
          entityStore.delete,
          'форму'
        );

        const [isApproving, approveDialog, openApproveDialog] = useConfirmRow(
          entityStore.approve,
          'форму'
        );

        const [
          isRequestEditLoading,
          requestEditDialog,
          openRequestEditDialog
        ] = useGeneralConfirmationRow(
          entityStore.requestEdit,
          'Вы уверены, что хотите запросить редактирование формы?',
          'Возникла ошибка при запросе редактирования формы. Попробуйте еще раз'
        );

        const [
          isAllowEditLoading,
          allowEditDialog,
          openAllowEditDialog
        ] = useGeneralConfirmationRow(
          entityStore.allowEdit,
          'Вы уверены, что хотите разрешить редактирование формы?',
          'Возникла ошибка при разрешении редактирования формы. Попробуйте еще раз'
        );

        const [
          isRejectEditLoading,
          rejectEditDialog,
          openRejectEditDialog
        ] = useGeneralConfirmationRow(
          entityStore.rejectEdit,
          'Вы уверены, что хотите запретить редактирование формы?',
          'Возникла ошибка при запрете редактирования формы. Попробуйте еще раз'
        );

        const menu = [];
        const status = row.status;
        const isApproved =
          status === ContestDocsMonitoringEntity.Status.APPROVED;
        const isDraft = status === ContestDocsMonitoringEntity.Status.DRAFT;
        const isReturned =
          status === ContestDocsMonitoringEntity.Status.RETURNED;
        const isEditRequested =
          status === ContestDocsMonitoringEntity.Status.EDIT_REQUESTED;

        const canEdit = ContestDocsMonitoringEntity.canEdit(user, row);
        const canRevoke = ContestDocsMonitoringEntity.canRevoke(user);

        if (canEdit && (isDraft || isReturned)) {
          menu.push({
            title: 'Утвердить',
            onClick: () => {
              ContestDocsMonitoringEntityApprovalValidationSchema.validate(
                ContestDocsMonitoringEntity.toForm(row)
              )
                .then(() => {
                  openApproveDialog(row.id);
                })
                .catch((err) => {
                  console.log(err);
                  setIsValidationError(true);
                });
            }
          });
        }

        if (canEdit && isApproved) {
          menu.push({
            title: 'Запросить редактирование',
            onClick: () => openRequestEditDialog(row.id)
          });
        }

        if (canRevoke && isEditRequested) {
          menu.push({
            title: 'Разрешить редактирование',
            onClick: () => openAllowEditDialog(row.id)
          });
        }

        if (canRevoke && isEditRequested) {
          menu.push({
            title: 'Запретить редактирование',
            onClick: () => openRejectEditDialog(row.id)
          });
        }

        if (canEdit && (isDraft || isReturned)) {
          menu.push({
            title: 'Удалить',
            onClick: () => openDeleteDialog(row.id)
          });
        }

        if (menu.length === 0) {
          return false;
        }

        const loading =
          isDeleting ||
          isApproving ||
          isRequestEditLoading ||
          isAllowEditLoading ||
          isRejectEditLoading;

        return (
          <>
            {loading ? (
              <CircularProgress size={18} />
            ) : (
              <Menu iconColor="default" icon={MoreHorizIcon} options={menu} />
            )}
            {deleteDialog}
            {approveDialog}
            {requestEditDialog}
            {allowEditDialog}
            {rejectEditDialog}
          </>
        );
      },
    [entityStore, user]
  );

  return (
    <>
      <HeadTitle title={contestDocsMonitoringPageTitle} />
      <PageTitle withBackBtn={false} title={contestDocsMonitoringPageTitle} />
      <ContestDocsEntityValidationDialog
        isOpen={isValidationError}
        onCancel={() => setIsValidationError(false)}
      >
        Вы не можете утвердить форму, пока не заполнены все обязательные поля
      </ContestDocsEntityValidationDialog>
      <Table
        columns={contestDocsEntitiesColumns}
        isLoading={entitiesQuery.check.spinner}
        data={data}
        filter={filterComponent}
        hasAddField={canCreate}
        addButtonText="Создать"
        addFieldRoute={creationLink}
        renderNoData={() => <h3>Нет информации по выбранным фильтрам</h3>}
        rowRedirectRoute={`${match.url}/edit/`}
        menuComponent={menuComponent}
        pagination={<QueryPagination pagination={pagination} />}
      />
    </>
  );
}
