import React from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { useRegionalProject } from '../../../../store/regionalProject/useRegionalProject';
import { useExpensesDirection } from '../../../../store/expensesDirection/useExpensesDirection';
import { PurchaseEditForm } from '../edit/PurchaseEditForm';
import { Purchase } from '../../../../store/purchase/Purchase';
import { STORE_PURCHASE } from '../../../../store/names';
import { purchaseValidationSchema } from '../../../../utils/data/validation';
import { removeLastPathItem } from '../../../../utils/url';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../graphql/graphqlErrors';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { PurchaseBreadCrumbs } from '../PurchaseBreadCrumbs';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { getCurrentYear } from '../../../../utils/data/date';

function PurchaseCreatePage({ match, history }) {
  const { projectId, expenseGroupId } = match.params;

  const purchaseStore = useContextStore(STORE_PURCHASE);
  const projectQuery = useRegionalProject(projectId);
  const expensesDirectionQuery = useExpensesDirection(expenseGroupId);

  const project = projectQuery.state.data;
  const expensesDirection = expensesDirectionQuery.state.data;

  // todo придумать, что делать с + 's'
  let backLink = removeLastPathItem(match.url) + 's';

  return (
    <>
      <HeadTitle type="Закупка" pageType={HeadTitle.PageType.create} />
      <PurchaseBreadCrumbs
        project={project}
        expensesDirection={expensesDirection}
        projectId={projectId}
        expenseGroupId={expenseGroupId}
      />
      <h2>Создание закупки</h2>
      <Formik
        initialValues={{
          regional_project_id: projectId,
          expenses_direction_id: expenseGroupId,
          grbs_id: null,
          grbs_string: '',
          equipment_and_software: null,
          customer_string: '',
          item: '',
          supplier_determination_id: null,
          financing_sfb: null,
          financing_skbsrf: null,
          financing_mb: null,
          application_date_changes: [],
          eis_publication_changes: [],
          contract_sign_changes: [],
          payment_schedules: [],
          purchase_components: [],
          purchase_stages: [],
          rvpo_equipments: [],
          eis_number: '',
          year: getCurrentYear().toString()
        }}
        validationSchema={purchaseValidationSchema}
        onSubmit={(values, actions) => {
          const purchase = Purchase.fromForm(values);
          purchaseStore
            .createPurchase(purchase)
            .then((result) => {
              actions.setStatus(FORM_STATUS_SUBMITTED);
              history.push(
                projectId || expenseGroupId
                  ? Purchase.getUrl(result.data.createPurchase)
                  : Purchase.getShortUrl(result.data.createPurchase)
              );
            })
            .catch((error) => {
              showGraphqlErrorToast(error, mutationErrors.createObject);
            })
            .then(() => actions.setSubmitting(false));
        }}
        render={(formikProps) => (
          <PurchaseEditForm
            {...formikProps}
            onBackClick={() => history.goBack(backLink)}
          />
        )}
      />
    </>
  );
}

export default PurchaseCreatePage;
