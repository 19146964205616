import { pick } from 'lodash-es';
import { User } from '../role/User';
import { EnumHelpers } from '../../utils/EnumHelpers';

export class IndicatorForecast {
  static indicatorForecastFields = [
    'comment',
    'indicator_forecast_value',
    'orgs_demo',
    'orgs_total',
    'people_demo',
    'people_total',
    'status'
  ];

  static Status = {
    APPROVED_BY_REGION: 'APPROVED_BY_REGION',
    DRAFT: 'DRAFT'
  };

  static status = new EnumHelpers(this.Status, {
    APPROVED_BY_REGION: 'Утверждено регионом',
    DRAFT: 'Черновик'
  });

  static FormVariant = {
    ORGS: 'ORGS',
    PEOPLE: 'PEOPLE'
  };

  static canEdit = (user, indicatorForecast) => {
    return (
      (indicatorForecast?.status === IndicatorForecast.Status.DRAFT &&
        User.hasPermission(user, 'rp_forecast.crud')) ||
      User.isAdmin(user)
    );
  };

  static canApprove(user, indicatorForecast) {
    return (
      indicatorForecast?.status === IndicatorForecast.Status.DRAFT &&
      User.hasPermission(user, 'rp_forecast.crud')
    );
  }

  static filterUpdateFields = (data) => {
    return pick(data, ['id', ...this.indicatorForecastFields]);
  };

  static getUrl = (indicatorForecast) => {
    return `/indicatorForecasts/${indicatorForecast.id}`;
  };

  static toForm = (data) => {
    return data;
  };

  static fromForm = ({
    indicator_forecast_value,
    orgs_demo,
    orgs_total,
    people_demo,
    people_total,
    ...data
  }) => {
    return {
      ...data,
      indicator_forecast_value: +indicator_forecast_value,
      orgs_demo: +orgs_demo,
      orgs_total: +orgs_total,
      people_demo: +people_demo,
      people_total: +people_total
    };
  };

  static title = {
    singular: 'Прогноз показателя проекта "Молодые профессионалы"',
    plural: 'Прогнозы показателей проекта "Молодые профессионалы"',
    edit: 'Редактирование прогноза показателя проекта "Молодые профессионалы"'
  };

  static parts = {
    status: {
      name: 'Статус'
    },
    federal_project: {
      name: 'Наименование проекта'
    },
    indicator: {
      name: 'Показатель'
    },
    region: {
      name: 'Субъект Российской Федерации'
    },
    indicator_plan_value: {
      name: 'Показатель на 2020 год (%)'
    },
    indicator_forecast_value: {
      name: 'Прогнозные данные по показателю на 2020 год (%)'
    },
    people_demo: {
      name:
        'Число обучающихся, завершающих обучение в организациях, ' +
        'осуществляющих образовательную деятельность по ' +
        'образовательным программам среднего профессионального ' +
        'образования, прошедших и планирующих пройти аттестацию с ' +
        'использованием механизма демонстрационного экзамена, чел.'
    },
    people_total: {
      name:
        'Общее количество обучающихся, завершающих обучение в ' +
        'организациях, осуществляющих образовательную деятельность ' +
        'по образовательным программам среднего профессионального ' +
        'образования, чел.'
    },
    orgs_demo: {
      name:
        'Число организаций, осуществляющих образовательную ' +
        'деятельность по образовательным программам среднего ' +
        'профессионального образования, итоговая аттестация в которых ' +
        'проходит в форме демонстрационного экзамена'
    },
    orgs_total: {
      name:
        'Общее количество организаций, осуществляющих образовательную ' +
        'деятельность по образовательным программам среднего ' +
        'профессионального образования'
    },
    comment: {
      name: 'Примечание'
    }
  };
}
