import { skipIfNull } from '@proscom/prostore';
import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useCallback, useEffect, useState } from 'react';
import { QUERY_RVPO_EQUIPMENTS_LIST } from '../../graphql/queries/infralistEquipment';
import { useSortPager } from '../../utils/hooks/useSortPager';
import { useInfralistEquipmentFilter } from './useInfralistEquipmentFilter';

const infralistEquimentListQueryOptions = {
  query: QUERY_RVPO_EQUIPMENTS_LIST,
  mapData: (result) => result.infralistSectionRvpoEquipments,
  skipQuery: skipIfNull(null)
};

const EQUIPMENT_PER_PAGE = 10;
const defaultSort = {
  field: 'createdAt',
  direction: 'DESC'
};

/**
 *
 * @param sectionId
 * @param infralistId
 * @returns {{onSetCheckStatusesFilter: (function(*=): void), checkStatusesFilter: unknown, data: *, loadMore: loadMoreWatchQuery, updateAllEquipmentsInList: (function(*=): void), statusesFilter: unknown, onSetStatusesFilter: (function(*=): void), updateEquipmentInList: (function(*=): void), resetEquipmentList: reset, setSort: (function(*): void), approvementStatusesFilter: unknown, deleteEquipmentFromList: (function(*=): void), onSetApprovementStatusesFilter: (function(*=): void), sorting: *, spinner: boolean}}
 */

export function useInfralistEquipmentList({ sectionId, infralistId }) {
  const client = useContextApolloClient();

  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(false);
  const [equipmentQueryResult, setEquipmentQueryResult] = useState();
  const {
    pagination,
    sorting,
    loadMore,
    setSort,
    reset,
    setPage
  } = useSortPager({
    itemsPerPage: EQUIPMENT_PER_PAGE
  });
  const {
    statusesFilter,
    checkStatusesFilter,
    approvementStatusesFilter,
    onSetApprovementStatusesFilter,
    onSetCheckStatusesFilter,
    onSetStatusesFilter
  } = useInfralistEquipmentFilter(setPage);

  useEffect(() => {
    setIsLoading(true);
    client
      .query({
        ...infralistEquimentListQueryOptions,
        variables: {
          input: {
            filter: {
              section_id: sectionId,
              infralist_id: infralistId,
              statuses: statusesFilter?.map((s) => s.value),
              check_statuses: checkStatusesFilter?.map((s) => s.value),
              approvement_statuses: approvementStatusesFilter?.map(
                (s) => s.value
              )
            },
            pagination,
            sorting: sorting ? [sorting, defaultSort] : defaultSort
          }
        }
      })
      .then((response) => {
        /**
         * @type {{header: {hasNext: boolean, page: number, totalCount: number}, list: []}}
         */
        const responseData = response.data.infralistSectionRvpoEquipments;
        setEquipmentQueryResult((prev) => ({
          header: responseData.header,
          list:
            responseData.header.page === 0
              ? responseData.list
              : [...(prev.list ?? []), ...responseData.list]
        }));
      })
      .finally(() => {
        setLoadMoreEnabled(true);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    client,
    infralistId,
    pagination,
    sectionId,
    sorting,
    statusesFilter,
    checkStatusesFilter,
    approvementStatusesFilter
  ]);

  const updateItem = useCallback((nextItem) => {
    setEquipmentQueryResult((prevState) => ({
      ...prevState,
      list: prevState.list.map((currentItem) =>
        currentItem.id === nextItem.id
          ? { ...currentItem, ...nextItem }
          : currentItem
      )
    }));
  }, []);

  const deleteItem = useCallback((itemId) => {
    setEquipmentQueryResult((prevState) => ({
      ...prevState,
      list: prevState.list.filter((currentItem) => currentItem.id !== itemId)
    }));
  }, []);

  const updateAll = useCallback((updatedProps) => {
    setEquipmentQueryResult((prevState) => ({
      ...prevState,
      list: prevState.list.map((currentItem) => ({
        ...currentItem,
        ...updatedProps
      }))
    }));
  }, []);

  const loadMoreWatchQuery = () => {
    if (equipmentQueryResult?.header?.hasNext && !isLoading) {
      if (loadMoreEnabled) {
        loadMore();
      }
      setLoadMoreEnabled(false);
    }
  };

  return {
    data: equipmentQueryResult?.list,
    spinner: isLoading,
    sorting,
    loadMore: loadMoreWatchQuery,
    setSort,
    updateAllEquipmentsInList: updateAll,
    updateEquipmentInList: updateItem,
    resetEquipmentList: reset,
    deleteEquipmentFromList: deleteItem,
    statusesFilter,
    checkStatusesFilter,
    approvementStatusesFilter,
    onSetApprovementStatusesFilter,
    onSetCheckStatusesFilter,
    onSetStatusesFilter
  };
}
