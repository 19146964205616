import React, { useCallback, useMemo } from 'react';
import { getCurrentMonth, getCurrentYear } from '../../utils/data/date';
import { useRetriableRegionalProjects } from '../../store/regionalProject/useRegionalProjects';
import {
  useMemoSuggestions,
  useMemoSuggestionsFull
} from '../../utils/suggestions';
import AutocompleteBaseInput from '../../common/Inputs/Selects/AutocompleteBaseInput';
import { User } from '../../store/role/User';
import { useRegions } from '../../store/subsidiaryEntity/useSubsidiaryEntity';
import { queryLoader } from '../../common/QueryLoader';
import { useRetriableExpensesDirectionsFilter } from '../../store/expensesDirection/useExpensesDirections';
import { YearSelect } from '../../common/YearSelect';
import { MonthSelect } from '../../common/MonthSelect';
import { compareSimple, makeComparator } from '../../utils/sorting';
import { getId, getName, makeOptionator } from '../../utils/data/label';
import { Pof } from '../../store/pof/Pof';
import { useSetMonth } from './useSetMonth';
import s from './PofsPage.module.scss';

const pofOptions = Pof.status.getOptions();

export const usePofsFilter = (query, changeQuery, user) => {
  const regionsQuery = useRegions();
  const regions = regionsQuery.state.data;
  const [regionsSuggestions, availableRegions] = useMemo(() => {
    const availableRegions = regions && User.getRegions(user, regions);
    const result = availableRegions?.map(makeOptionator(getName, getId));
    if (result) {
      result.sort(makeComparator(compareSimple, (r) => r.label));
    }
    return [result, availableRegions];
  }, [regions, user]);

  const userRegionId =
    availableRegions?.length === 1 ? availableRegions[0].id : null;
  const regionId = user?.region?.id || userRegionId;
  const filter = {
    region_ids: query.regionId || regionId,
    regional_project_ids: query.regional_project_id,
    expenses_direction_ids: query.expenses_direction_id,
    month: query.month || String(getCurrentMonth()),
    year: query.year || String(getCurrentYear()),
    status: query.status
  };

  const setFilterValue = useCallback(
    (value, name) =>
      changeQuery({
        [name]: value ?? undefined,
        page: 0
      }),
    [changeQuery]
  );

  const regionalProjectsQuery = useRetriableRegionalProjects(
    regions
      ? { region_ids: filter.region_ids, roadmap_ids: filter.owner_roadmap_ids }
      : { roadmap_ids: filter.owner_roadmap_ids }
  );
  const regionalProjects = regionalProjectsQuery.state.data?.data;
  const regionalProjectsSuggestions = useMemoSuggestions(regionalProjects);

  const expensesDirectionsFilter = useMemo(() => {
    if (!availableRegions)
      return {
        exists_in_mbt_by_subjects: true,
        exists_in_pof_links: true
      };

    return {
      exists_in_mbt_by_subjects: true,
      regional_project_ids: filter.regional_project_ids,
      region_ids: filter.region_ids,
      year: +filter.year || undefined
    };
  }, [
    availableRegions,
    filter.regional_project_ids,
    filter.region_ids,
    filter.year
  ]);
  const expensesDirectionsQuery = useRetriableExpensesDirectionsFilter(
    expensesDirectionsFilter
  );
  const expensesDirections = useMemoSuggestionsFull(
    expensesDirectionsQuery.state.data
  );

  const [year, month, currentYear, maxMonth] = useSetMonth(query, changeQuery);
  const filterComponent = queryLoader(regionsQuery) || (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {regionsSuggestions?.length > 1 && (
        <AutocompleteBaseInput
          onChange={setFilterValue}
          hasCopyBtn={false}
          isClearable={!regionId}
          label="Регион"
          name="regionId"
          suggestions={regionsSuggestions}
          suggestionsLoading={regionsQuery.check.spinner}
          value={filter.region_ids}
          variant="standard"
        />
      )}
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Региональный проект"
        name="regional_project_id"
        suggestions={regionalProjectsSuggestions}
        suggestionsLoading={regionalProjectsQuery.check.spinner}
        value={filter.regional_project_ids}
        variant="standard"
      />
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Направление расходов"
        name="expenses_direction_id"
        suggestions={expensesDirections}
        suggestionsLoading={expensesDirectionsQuery.check.spinner}
        value={filter.expenses_direction_ids}
        variant="standard"
      />
      <YearSelect
        label="Год"
        year={year}
        onChange={changeQuery}
        maxYear={currentYear}
        variant="standard"
        className={s.selector}
      />
      <MonthSelect
        label="Месяц"
        month={month}
        onChange={changeQuery}
        maxMonth={maxMonth}
        variant="standard"
        className={s.selector}
      />
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Статус"
        name="status"
        suggestions={pofOptions}
        value={filter.status}
        variant="standard"
      />
    </div>
  );
  return [filter, filterComponent, maxMonth];
};
