import gql from 'graphql-tag';
import { FRAGMENT_ROLE } from '../queries/roles';

export const MUTATION_UPDATE_ROLE = gql`
  mutation updateRole($input: CustomRoleUpdateInput!) {
    result: updateRole(input: $input) {
      ...role
    }
  }
  ${FRAGMENT_ROLE}
`;

export const MUTATION_CREATE_ROLE = gql`
  mutation createRole($input: CustomRoleInput!) {
    result: createRole(input: $input) {
      ...role
    }
  }
  ${FRAGMENT_ROLE}
`;

export const MUTATION_DELETE_ROLE = gql`
  mutation deleteRole($id: ID!) {
    result: deleteRole(id: $id)
  }
`;
