import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import {
  QUERY_GET_MENTORING_COVERAGE,
  QUERY_GET_MENTORING_COVERAGE_BY_REGION
} from '../../graphql/queries/mentoringCoverage';

const mentoringCoverageOptions = {
  query: QUERY_GET_MENTORING_COVERAGE,
  mapData: (result) => result.mentoringCoverage,
  skipQuery: skipIfNull(null)
};

const mentoringCoverageByRegionIdOptions = {
  query: QUERY_GET_MENTORING_COVERAGE_BY_REGION,
  mapData: (result) => result.mentoringCoverageByRegionId,
  skipQuery: skipIfNull(null)
};

/** @typedef {Object} MentoringCoverageData
 * @property {String} comment
 * @property {String} id
 * @property {String} percentage
 * @property {Object} region
 * @property {String} region_id
 * @property {String} status
 */

/**
 *
 * @param {String} id
 * @returns query
 * @returns {MentoringCoverageData} query.state.data
 */
export function useMentoringCoverage(id) {
  return useRetriableGraphqlVars({
    queryOptions: mentoringCoverageOptions,
    variableCreator: [
      () =>
        id
          ? {
              id
            }
          : null,
      [id]
    ],
    options: {
      apolloOptions: {
        fetchPolicy: 'network-only'
      }
    }
  });
}

/**
 *
 * @param {String} regionId
 * @returns query
 * @returns {MentoringCoverageData} query.state.data
 */
export function useMentoringCoverageByRegionId(regionId) {
  return useRetriableGraphqlVars({
    queryOptions: mentoringCoverageByRegionIdOptions,
    variableCreator: [
      () =>
        regionId
          ? {
              regionId
            }
          : null,
      [regionId]
    ],
    options: {
      apolloOptions: {
        fetchPolicy: 'network-only'
      }
    }
  });
}
