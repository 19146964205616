import { tryParseJson } from '../helpers';

export function setFieldSignal(signals = {}, fieldName, value) {
  if (!signals.fields) signals.fields = {};
  if (!signals.fields[fieldName]) signals.fields[fieldName] = [];
  signals.fields[fieldName].push(value);
}

export function mergeSignalsFields(...fields) {
  const result = {};
  // eslint-disable-next-line no-unused-vars
  for (const item of fields) {
    // eslint-disable-next-line no-unused-vars
    for (const [key, signals] of Object.entries(item)) {
      if (result[key]) {
        result[key].push(...signals);
      } else {
        result[key] = [...signals];
      }
    }
  }
  return result;
}

export function mergeSignals(...signals) {
  return {
    fields: mergeSignalsFields(
      ...signals
        .filter((f) => f)
        .map((f) => f.fields)
        .filter((f) => f)
    )
  };
}

export function parseSignals(signals) {
  return {
    fields: tryParseJson(signals.fields, {})
  };
}
