import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_ROADMAP_INDICATORS } from '../../graphql/queries/indicators';

const roadmapIndicatorsQueryOptions = {
  query: QUERY_ROADMAP_INDICATORS,
  mapData: (result) => ({
    data: result.roadmapIndicators
  }),
  skipQuery: skipIfNull(null)
};

export function useRoadmapIndicators(filter) {
  return useRetriableGraphqlVars({
    queryOptions: roadmapIndicatorsQueryOptions,
    variableCreator: [
      () =>
        filter
          ? {
              filter
            }
          : null,
      [filter]
    ]
  });
}
