import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SubTable from '../SubTable/SubTable';

export const TableInputView = ({ columns, rows, ...props }) => {
  return (
    <SubTable cols={columns.length} {...props}>
      <TableRow>
        {columns.map((column, iColumn) => {
          return <TableCell key={iColumn}>{column.title}</TableCell>;
        })}
      </TableRow>
      {rows?.map((row, iRow) => (
        <TableRow key={iRow}>
          {columns.map((column, iColumn) => {
            return (
              <TableCell key={iColumn}>
                {column.render ? column.render(row) : row[column.field]}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </SubTable>
  );
};
