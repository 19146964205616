import React from 'react';
import { sumBy } from 'lodash-es';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SubTable from '../../../common/SubTable/SubTable';
import { CellSignals, DateCell, PriceCell } from '../../../common/TableCells';
import { DATE_FORMAT_MONTH } from '../../../utils/date';
import nomRuLocale from '../../../utils/fnsNomRuLocale';
import { formatCurrency } from '../../../utils/number';
import { typeOptions } from './edit/PurchaseEditForm';
import s from './PurchasePage.module.scss';

export function PurchasePaymentSchedules({ title, items, signals }) {
  const totalPlan = sumBy(items, (item) => item.plan);
  const totalFact = sumBy(items, (item) => item.fact);
  const totalDiffFactPlan = totalPlan - totalFact;

  return (
    <SubTable title={title} cols={7}>
      <TableRow>
        <TableCell>№</TableCell>
        <TableCell>Тип платежа</TableCell>
        <TableCell>Срок исполнения платежа</TableCell>
        <TableCell>Плановая сумма, руб.</TableCell>
        <TableCell>Фактическая сумма, руб.</TableCell>
        <TableCell>Отклонение фактической от плановой суммы, руб.</TableCell>
        <TableCell>Причина (обоснование)</TableCell>
      </TableRow>
      {items &&
        items.map((payment, index) => {
          const type = typeOptions.filter(
            (item) => item.value === payment.type_payment
          );
          return (
            <TableRow key={index}>
              <TableCell>{payment.payment_schedule_id}</TableCell>
              <TableCell>{type[0].label}</TableCell>
              <DateCell
                value={payment.date_execution_payment}
                format={DATE_FORMAT_MONTH}
                locale={nomRuLocale}
              />
              <PriceCell value={payment.plan} unit="" />
              <PriceCell value={payment.fact} unit="" />
              <PriceCell value={payment.plan - payment.fact} unit="" />
              <TableCell>{payment.reason}</TableCell>
            </TableRow>
          );
        })}
      <TableRow>
        <TableCell colSpan={3} className={s.Total}>
          Итог:
        </TableCell>
        <TableCell>{formatCurrency(totalPlan)}</TableCell>
        <TableCell>{formatCurrency(totalFact)}</TableCell>
        <TableCell>{formatCurrency(totalDiffFactPlan)}</TableCell>
      </TableRow>
      {signals && (
        <TableRow>
          <TableCell colSpan={6}>
            <CellSignals signals={signals} />
          </TableCell>
        </TableRow>
      )}
    </SubTable>
  );
}
