import React from 'react';
import BreadCrumbs from '../../../common/BreadCrumbs/BreadCrumbs';
import { IndicatorForecast } from '../../../store/indicatorForecasts/IndicatorForecast';

export function getIndicatorForecastBreadCrumbs() {
  return [
    {
      label: IndicatorForecast.title.plural,
      to: '/indicatorForecasts'
    }
  ];
}

export const IndicatorForecastBreadCrumbs = () => {
  const breadCrumbsLinks = getIndicatorForecastBreadCrumbs();

  return <BreadCrumbs links={breadCrumbsLinks} />;
};
