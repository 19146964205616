import React from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { useRole } from '../../../../store/role/useRole';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import { RoleBreadCrumbs } from '../RoleBreadCrumbs';
import { removeLastPathItem } from '../../../../utils/url';
import { ROLE_STORE } from '../../../../store/names';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../graphql/graphqlErrors';
import { Role } from '../../../../store/role/Role';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { useOtherEditor } from '../../../../common/Form/useOtherEditor';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { getName } from '../../../../utils/data/label';
import { RoleEditForm } from './RoleEditForm';

export default function RoleEditPage({ match, history }) {
  const roleStore = useContextStore(ROLE_STORE);
  const backLink = removeLastPathItem(match.url);
  const { roleId } = match.params;

  const editor = useOtherEditor('role', roleId);

  const roleQuery = useRole(roleId, true);
  const role = roleQuery.state.data;

  return (
    <>
      <HeadTitle
        type="Роль"
        entity={getName(role)}
        pageType={HeadTitle.PageType.edit}
      />
      <RoleBreadCrumbs />
      <h2>Редактирование роли</h2>
      {queryLoaderWithExistenceCheck(roleQuery) || (
        <Formik
          initialValues={role}
          onSubmit={(values, actions) => {
            const role = Role.fromForm(values);
            roleStore
              .updateRole(role)
              .then((result) => {
                actions.setStatus(FORM_STATUS_SUBMITTED);
                setTimeout(() => {
                  history.goBack(Role.getUrl(result.data.result));
                }, 0);
              })
              .catch((error) => {
                showGraphqlErrorToast(error, mutationErrors.updateObject);
              })
              .then(() => {
                actions.setSubmitting(false);
              });
          }}
          render={(formikProps) => (
            <RoleEditForm
              {...formikProps}
              onBackClick={() => history.goBack(backLink)}
              editor={editor}
            />
          )}
        />
      )}
    </>
  );
}
