import React from 'react';
import { MultiSelectInline } from '../../common/MultiSelectInline/MultiSelectInline';
import {
  infralistStatusService,
  INFRALIST_STATUSES
} from '../../store/infralist/infralistStatusService';
import {
  infralistCheckStatusService,
  INFRALIST_CHECK_STATUSES
} from '../../store/infralist/statuses/infralistCheckStatusService';
import { useInfralistUserAccesses } from '../../store/infralist/useInfralistUserAccesses';
import { formatNumber } from '../../utils/formatNumber';
import s from './FiltersDirectionsSections.module.scss';

const optionsStatusColumn = [
  {
    label: INFRALIST_STATUSES.NOT_FORMED,
    value: 'NOT_FORMED',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.NOT_FORMED)
  },
  {
    label: INFRALIST_STATUSES.FORMED,
    value: 'FORMED',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.FORMED)
  },
  {
    label: INFRALIST_STATUSES.ON_SIGNING,
    value: 'ON_SIGNING',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.ON_SIGNING)
  },
  {
    label: INFRALIST_STATUSES.SIGNED,
    value: 'SIGNED',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.SIGNED)
  },
  {
    label: INFRALIST_STATUSES.NOT_SIGNED,
    value: 'NOT_SIGNED',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.NOT_SIGNED)
  }
];

const optionsStatusSignColumn = [
  {
    label: INFRALIST_STATUSES.ON_SIGNING,
    value: 'ON_SIGNING',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.ON_SIGNING)
  },
  {
    label: INFRALIST_STATUSES.SIGNED,
    value: 'SIGNED',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.SIGNED)
  },
  {
    label: INFRALIST_STATUSES.NOT_SIGNED,
    value: 'NOT_SIGNED',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.NOT_SIGNED)
  }
];

const optionsCheckColumn = [
  {
    label: INFRALIST_CHECK_STATUSES.NOT_CHECKED,
    value: 'NOT_CHECKED',
    color: infralistCheckStatusService.getColor(
      INFRALIST_CHECK_STATUSES.NOT_CHECKED
    )
  },
  {
    label: INFRALIST_CHECK_STATUSES.CHECKED_WITH_COMMENTS,
    value: 'THERE_ARE_COMMENTS',
    color: infralistCheckStatusService.getColor(
      INFRALIST_CHECK_STATUSES.CHECKED_WITH_COMMENTS
    )
  },
  {
    label: INFRALIST_CHECK_STATUSES.CHECKED_SUCCESS,
    value: 'NO_COMMENTS',
    color: infralistCheckStatusService.getColor(
      INFRALIST_CHECK_STATUSES.CHECKED_SUCCESS
    )
  }
];

const optionsApproveColumn = [
  {
    label: INFRALIST_STATUSES.ON_APPROVAL,
    value: 'ON_APPROVAL',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.ON_APPROVAL)
  },
  {
    label: INFRALIST_STATUSES.APPROVED,
    value: 'AGREED',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.APPROVED)
  },
  {
    label: INFRALIST_STATUSES.NOT_APPROVED,
    value: 'NOT_AGREED',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.NOT_APPROVED)
  },
  {
    label: INFRALIST_STATUSES.APPROVED_WITH_COMMENTS,
    value: 'AGREED_WITH_REMARKS',
    color: infralistStatusService.getColor(
      INFRALIST_STATUSES.APPROVED_WITH_COMMENTS
    )
  }
];

const optionsApproveColumnsWithoutApproval = optionsApproveColumn.filter(
  (option) => option.label !== INFRALIST_STATUSES.ON_APPROVAL
);

export const FiltersDirectionsSections = ({
  sum,
  statuses,
  checkStatuses,
  approvementStatuses,
  setFilter,
  infralistStatus
}) => {
  const { isFPO1, isFPO2, isRVPO1, isRVPO2 } = useInfralistUserAccesses();

  const rvpo2StatusOptions =
    infralistStatus !== INFRALIST_STATUSES.NOT_APPROVED
      ? optionsStatusSignColumn
      : optionsStatusSignColumn.concat(optionsApproveColumnsWithoutApproval);

  return (
    <div className={s.root}>
      <p className={s.sum}>Итого: {formatNumber(sum)} руб.</p>

      <div className={s.filters}>
        {isRVPO1 && (
          <MultiSelectInline
            placeholder="Статус"
            value={optionsStatusColumn.filter((option) =>
              statuses?.includes(option.value)
            )}
            onChange={(options) =>
              setFilter({ statuses: options?.map((option) => option.value) })
            }
            options={optionsStatusColumn}
            className={s.select}
          />
        )}

        {isRVPO2 && (
          <MultiSelectInline
            placeholder="Статус"
            value={rvpo2StatusOptions.filter((option) =>
              statuses?.includes(option.value)
            )}
            onChange={(options) =>
              setFilter({ statuses: options?.map((option) => option.value) })
            }
            options={rvpo2StatusOptions}
            className={s.select}
          />
        )}

        {(isFPO1 || isFPO2) && (
          <MultiSelectInline
            placeholder="Результат проверки"
            value={optionsCheckColumn.filter((option) =>
              checkStatuses?.includes(option.value)
            )}
            onChange={(options) =>
              setFilter({
                check_statuses: options?.map((option) => option.value)
              })
            }
            options={optionsCheckColumn}
            className={s.select}
          />
        )}

        {(isFPO1 || isFPO2) && (
          <MultiSelectInline
            placeholder="Статус согласования"
            value={optionsApproveColumn.filter((option) =>
              approvementStatuses?.includes(option.value)
            )}
            onChange={(options) =>
              setFilter(
                setFilter({
                  approvement_statuses: options?.map((option) => option.value)
                })
              )
            }
            options={optionsApproveColumn}
            className={s.select}
          />
        )}
      </div>
    </div>
  );
};
