import { clearApolloQueryCache } from '../../utils/apollo';
import { MUTATION_UPDATE_CREATE_OR_UPDATE_RPIMPLEMENTATION_FORECAST } from '../../graphql/mutations/rpImplementationForecast';
import { RpImplementationForecast } from './RpImplementationForecast';

export class RpImplementationForecastStore {
  constructor({ client }) {
    this.client = client;
  }

  queriesToClear = [
    'rpImplementationForecast',
    'regionalProjects',
    'federalProjectRoadmaps',
    'expensesDirections',
    'mbtFinancesGrouped',
    'indicators'
  ];

  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  updateRpImplementationForecast = (data) => {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_CREATE_OR_UPDATE_RPIMPLEMENTATION_FORECAST,
      variables: {
        input: RpImplementationForecast.filterUpdateFields(data)
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  };

  approve(id) {
    return this.updateRpImplementationForecast({
      id,
      status: RpImplementationForecast.Status.APPROVED_BY_REGION
    });
  }
}
