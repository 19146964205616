import React, { useMemo } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { queryLoader } from '../../../../common/QueryLoader';
import Table from '../../../../common/Table/Table';
import { RegionalEvent } from '../../../../store/roadmap/RegionalEvent';
import Menu from '../../../../common/Menu/Menu';
import { useCurrentUser } from '../../../../store/useCurrentUser';
import { useRegionalProject } from '../../../../store/regionalProject/useRegionalProject';
import { useUrlQuery } from '../../../../common/useUrlQuery';
import { reformatDateWithoutTimezone } from '../../../../utils/date';
import { getName } from '../../../../utils/data/label';
import { useRegionalRoadmapEvents } from '../../../../store/roadmap/useRegionalRoadmapEvents';
import { useSorting } from '../../../../common/Table/Sorting/useSorting';
import { Sorting } from '../../../../common/Table/Sorting/Sorting';
import { useRegionalEventFilter } from './useRegionalEventFilter';

const getRegionalEventsColumn = (regionId, sortableColumns) => {
  const baseColumns = [
    { label: '№', key: 'order', isThin: true },
    { label: 'Код', key: 'code', isThin: true },
    {
      label: 'Название мероприятия',
      key: 'name',
      isBold: true,
      isWide: true
    },
    sortableColumns[0]
  ];

  if (!regionId) return baseColumns;
  return [
    ...baseColumns,
    {
      label: 'Статус',
      key: (row) => RegionalEvent.status.getName(row.regionalEvent?.status),
      isThin: true
    },
    sortableColumns[1]
  ];
};

export const RegionalEvents = ({
  match,
  history,
  location,
  roadmap,
  roadmapYear
}) => {
  const user = useCurrentUser();
  const { projectId, roadmapId } = match.params;
  const regionalProjectQuery = useRegionalProject(projectId);
  const regionId = regionalProjectQuery.state.data?.region.id;

  const [query, changeQuery] = useUrlQuery(location, history);

  const [sortableColumns, sorting] = useSorting([
    {
      field: Sorting.SortFields.plan_term,
      key: (row) => reformatDateWithoutTimezone(row.plan_term),
      isThin: true
    },
    {
      field: Sorting.SortFields.status_changed_at,
      key: (row) =>
        reformatDateWithoutTimezone(row.regionalEvent?.status_changed_at)
    }
  ]);

  const [filterComponent, filter] = useRegionalEventFilter(
    query,
    changeQuery,
    roadmap,
    roadmapYear,
    regionId,
    user
  );

  const roadmapFederalEventsQuery = useRegionalRoadmapEvents(
    filter,
    { onePage: 200 },
    sorting
  );

  const events = useMemo(() => {
    if (!roadmapFederalEventsQuery.state.data) return [];
    return roadmapFederalEventsQuery.state.data.list
      .filter(({ roadmap_event }) => roadmap_event)
      .map(({ roadmap_event, ...regionalEvent }, index) => {
        return {
          ...roadmap_event,
          regionalEvent,
          order: index + 1,
          name: getName(roadmap_event)
        };
      });
  }, [roadmapFederalEventsQuery.state.data]);

  const columns = useMemo(
    () => getRegionalEventsColumn(regionId, sortableColumns),
    [regionId, sortableColumns]
  );

  return (
    <>
      {queryLoader(roadmapFederalEventsQuery) || (
        <Table
          columns={columns}
          data={events}
          rowRedirectRoute={`/projects/${projectId}/roadmaps/${roadmapId}/events/`}
          renderMenu={(row) => {
            const canEdit = RegionalEvent.canEditEvent(user, row);
            if (!canEdit) return null;
            return (
              <Menu
                iconColor="default"
                icon={MoreHorizIcon}
                options={[
                  {
                    title: 'Редактировать',
                    onClick: () =>
                      history.push(`${match.url}/events/${row.id}/edit`)
                  }
                ]}
              />
            );
          }}
          filter={filterComponent}
        />
      )}
    </>
  );
};
