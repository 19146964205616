import React, { useMemo } from 'react';
import BreadCrumbs from '../../../../../common/BreadCrumbs/BreadCrumbs';
import { useRoadmap } from '../../../../../store/roadmap/useRoadmap';

export function getIndicatorBreadCrubs({
  projectId,
  projectName,
  roadmapId,
  roadmapName
}) {
  if (projectId) {
    return [
      {
        label: 'Региональные проекты',
        to: '/projects'
      },
      {
        label: projectName || '...',
        to: `/projects/${projectId}`
      },
      {
        label: 'Дорожные карты',
        to: `/projects/${projectId}?tab=roadmaps`
      },
      {
        label: roadmapName || '...',
        to: `/projects/${projectId}/roadmaps/${roadmapId}?tab=indicators`
      }
    ];
  }

  return [
    {
      label: 'Реестр дорожных карт',
      to: '/roadmaps'
    },
    {
      label: roadmapName || '...',
      to: `/roadmaps/${roadmapId}?tab=indicators`
    }
  ];
}

export const IndicatorBreadCrumbs = ({ project, roadmapId, projectId }) => {
  const roadmapQuery = useRoadmap(roadmapId);
  const roadmap = useMemo(() => {
    if (!roadmapQuery.state.data) return {};
    return roadmapQuery.state.data;
  }, [roadmapQuery.state.data]);

  const breadCrumbsLinks = useMemo(
    () =>
      getIndicatorBreadCrubs({
        projectId,
        projectName: project?.name,
        roadmapId,
        roadmapName: roadmap?.name_full
      }),
    [project, projectId, roadmap, roadmapId]
  );

  return <BreadCrumbs links={breadCrumbsLinks} />;
};
