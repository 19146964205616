import React from 'react';
import { Prompt } from 'react-router-dom';
import { FastField, Form } from 'formik';
import { FormHeader } from '../../../../common/Form/Form';
import { TextInput } from '../../../../common/Inputs/TextInput';
import InputGroup from '../../../../common/Inputs/InputGroup';
import { PermissionsEditField } from '../../../../common/Form/Permissions/PermissionsEditField';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';

export const RoleEditForm = ({
  onBackClick,
  status,
  isSubmitting,
  editor,
  touched,
  errors,
  values,
  setFieldValue,
  handleBlur
}) => {
  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <Form noValidate>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting}
          editor={editor}
          isSubmitting={isSubmitting}
        />
        <InputGroup>
          <FastField
            name="name"
            label="Название роли"
            component={TextInput}
            fullWidth
          />
        </InputGroup>
        <PermissionsEditField
          name="permissions"
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
        />
      </Form>
    </>
  );
};
