import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useCallback } from 'react';
import {
  GET_INCOMING_NOTIFICATIONS,
  GET_NOTIFICATION
} from '../../graphql/queries/notifications';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';

const incomingNotificationsOptions = {
  query: GET_INCOMING_NOTIFICATIONS,
  mapData: (data) => data.incomingNotifications
};

const notificationQuery = {
  query: GET_NOTIFICATION,
  mapData: (data) => data.notification
};

export function useIncomingNotification(queryFilter, pagination) {
  const filter = {
    ...queryFilter,
    read:
      queryFilter.read === 'read'
        ? true
        : queryFilter.read === 'not_read'
        ? false
        : undefined
  };
  return useRetriableGraphql({
    queryOptions: incomingNotificationsOptions,
    variables: { input: { filter, pagination } },
    options: {
      apolloOptions: {
        fetchPolicy: 'network-only'
      }
    }
  });
}

export function useNotification(id) {
  return useRetriableGraphql({
    queryOptions: notificationQuery,
    variables: { id }
  });
}

export const useNotificationOnClick = (id) => {
  const client = useContextApolloClient();

  const fetch = useCallback(() => {
    return client.query({
      query: GET_NOTIFICATION,
      variables: {
        id: Number(id)
      }
    });
  }, [client, id]);

  return { getNotification: fetch };
};
