import React, { useMemo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SubTable from '../../../../common/SubTable/SubTable';
import { EMDASH } from '../../../../utils/constants';
import { Individual } from '../../../../store/Individual';
import { joinNonEmpty } from '../../../../utils/data/label';
import { SubsidiaryEntity } from '../../../../store/subsidiaryEntity/SubsidiaryEntity';

const getEmail = (email, key) => {
  if (!email) return null;
  return (
    <a className="external" href={`mailto:${email}`} key={key}>
      {email}
    </a>
  );
};
const getEmails = (emails) => {
  if (!emails) return null;
  return emails.map((item, key) => getEmail(item, key));
};

export const SubsidiaryMonitoringOrganizationPreview = ({ entity }) => {
  const address = SubsidiaryEntity.combineAddress(entity.subsidiary_entity);
  const supervisor = entity.subsidiary_entity?.supervisor;

  const headFIO = useMemo(() => {
    return joinNonEmpty(
      [
        entity.organization_head_surname,
        entity.organization_head_name,
        entity.organization_head_patronymic
      ],
      ' '
    );
  }, [entity]);

  return (
    <>
      <SubTable title={'Полное наименование субсидиарной сущности'}>
        <TableRow>
          <TableCell>Комментарий</TableCell>
          <TableCell>
            {entity.comment_subsidiary_entity_name || EMDASH}
          </TableCell>
        </TableRow>
      </SubTable>

      <SubTable
        title={'Адрес фактического местонахождения субсидиарной сущности'}
      >
        <TableRow>
          <TableCell>Адрес</TableCell>
          <TableCell>{address || EMDASH}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Комментарий</TableCell>
          <TableCell>
            {entity.comment_subsidiary_entity_address || EMDASH}
          </TableCell>
        </TableRow>
      </SubTable>

      <SubTable title={'Данные руководителя организации'}>
        <TableRow>
          <TableCell>Фамилия</TableCell>
          <TableCell>{entity.organization_head_surname || EMDASH}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Комментарий (Фамилия)</TableCell>
          <TableCell>
            {entity.comment_organization_head_surname || EMDASH}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Имя</TableCell>
          <TableCell>{entity.organization_head_name || EMDASH}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Комментарий (Имя)</TableCell>
          <TableCell>
            {entity.comment_organization_head_name || EMDASH}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Отчество</TableCell>
          <TableCell>{entity.organization_head_patronymic || EMDASH}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Комментарий (Отчество)</TableCell>
          <TableCell>
            {entity.comment_organization_head_patronymic || EMDASH}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Действующий контактный телефон</TableCell>
          <TableCell>{entity.organization_head_phone || EMDASH}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Комментарий (Действующий контактный телефон)</TableCell>
          <TableCell>
            {entity.comment_organization_head_phone || EMDASH}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Электронная почта</TableCell>
          <TableCell>
            {getEmail(entity.organization_head_email) || EMDASH}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Комментарий (Электронная почта)</TableCell>
          <TableCell>
            {entity.comment_organization_head_email || EMDASH}
          </TableCell>
        </TableRow>
      </SubTable>

      <SubTable title={'Данные руководителя субсидиарной сущности'}>
        <TableRow>
          <TableCell>Фамилия</TableCell>
          <TableCell>{supervisor?.surname || EMDASH}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Комментарий (Фамилия)</TableCell>
          <TableCell>
            {entity.comment_subsidiary_entity_head_surname || EMDASH}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Имя</TableCell>
          <TableCell>{supervisor?.first_name || EMDASH}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Комментарий (Имя)</TableCell>
          <TableCell>
            {entity.comment_subsidiary_entity_head_name || EMDASH}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Отчество</TableCell>
          <TableCell>{supervisor?.patronymic || EMDASH}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Комментарий (Отчество)</TableCell>
          <TableCell>
            {entity.comment_subsidiary_entity_head_patronymic || EMDASH}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Действующий контактный телефон</TableCell>
          <TableCell>{supervisor?.phones || EMDASH}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Комментарий (Действующий контактный телефон)</TableCell>
          <TableCell>
            {entity.comment_subsidiary_entity_head_phone || EMDASH}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Электронная почта</TableCell>
          <TableCell>{getEmails(supervisor?.emails) || EMDASH}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Комментарий (Электронная почта)</TableCell>
          <TableCell>
            {entity.comment_subsidiary_entity_head_email || EMDASH}
          </TableCell>
        </TableRow>
      </SubTable>
    </>
  );
};
