import React from 'react';
import { CustomFastField } from '../../../../../common/Inputs/CustomFastField';
import TableInput from '../../../../../common/Table/TableInput';
import InputGroup from '../../../../../common/Inputs/InputGroup';
import { RpImplementationForecast } from '../../../../../store/rpImplementationForecast/RpImplementationForecast';
import {
  TableCurrencyInput,
  TableTextInput
} from '../../../../../common/Inputs/TextInput';
import { getName } from '../../../../../utils/data/label';

const indicatorProjectsOptions = [
  {
    editComponent: ({ rowData }) => {
      return <span>{getName(rowData.federal_project)}</span>;
    },
    title: 'Проект',
    field: 'federal_project',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellMedium,
    render: ({ federal_project }) => <span>{getName(federal_project)}</span>
  },
  {
    editComponent: ({ rowData }) => {
      return <span>{getName(rowData)}</span>;
    },
    title: 'Показатель',
    field: 'indicator',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellLarge,
    render: (item) => {
      return <span>{getName(item)}</span>;
    }
  },
  {
    editComponent: ({ rowData }) => {
      return <span>{getName(rowData.unit)}</span>;
    },
    title: 'Ед.изм.',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellSmall,
    render: (item) => {
      return <span>{getName(item.unit)}</span>;
    }
  },
  {
    editComponent: ({ rowData }) => {
      return <span>{rowData.value}</span>;
    },
    title: 'Плановое значение показателя на 2020 год',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellSmall,
    render: (item) => {
      return <span>{item.value}</span>;
    }
  },
  {
    editComponent: TableCurrencyInput,
    title: 'Прогнозное значение показателя на 2020 год',
    field: 'forecast_value',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellSmall
  },
  {
    editComponent: TableTextInput,
    title: 'Обоснование изменений',
    field: 'changes_justification',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellLarge
  },
  {
    editComponent: TableTextInput,
    title: 'Примечание',
    field: 'annotation',
    headerStyle: RpImplementationForecast.editTableStyles.tableCellLarge
  }
];

export const IndicatorProjectsFields = () => {
  return (
    <InputGroup>
      <CustomFastField
        name="indicator_forecasts"
        columns={indicatorProjectsOptions}
        component={TableInput}
        canAdd={false}
        canDelete={false}
        tableName="Информация по показателям"
      />
    </InputGroup>
  );
};
