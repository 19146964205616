import Tooltip from '@material-ui/core/Tooltip';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import s from './ComponentWithTooltip.module.scss';

export const ComponentWithTooltip = ({
  isTooltipExists,
  children,
  placement,
  title,
  open,
  className,
  width
}) => {
  const [visible, setVisible] = useState(false);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  // добавляем ширину к подсказке
  const styledTitle = title ? <div style={{ width }}>{title}</div> : '';

  return (
    <Tooltip
      title={styledTitle}
      placement={placement}
      arrow
      open={!!((visible && isTooltipExists) || open)}
      classes={{
        tooltip: s.Tooltip,
        arrow: s.Arrow
      }}
    >
      <div onMouseOver={show} onMouseLeave={hide} className={className}>
        {children}
      </div>
    </Tooltip>
  );
};

ComponentWithTooltip.propTypes = {
  title: PropTypes.any
};
