import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import {
  QUERY_GET_FEDERAL_PROJECT_ROADMAPS,
  QUERY_GET_ROADMAP_EVENTS_FOR_MONITORING,
  QUERY_GET_ROADMAPS_AVAILABLE_FOR_CONTEST_DOCS_MONITORING
} from '../../graphql/queries/roadmapEvents';

const roadmapsQueryOptions = {
  query: QUERY_GET_FEDERAL_PROJECT_ROADMAPS,
  mapData: (result) => ({
    data: result.federalProjectRoadmaps
  }),
  skipQuery: skipIfNull(null)
};

const roadmapsForMonitoringQueryOptions = {
  query: QUERY_GET_ROADMAP_EVENTS_FOR_MONITORING,
  mapData: (result) => result.roadmapsForSubsidiaryEntityMonitoring,
  skipQuery: skipIfNull(null)
};

const roadmapsAvailableForContestDocsMonitoringQueryOptions = {
  query: QUERY_GET_ROADMAPS_AVAILABLE_FOR_CONTEST_DOCS_MONITORING,
  mapData: (result) => result.roadmapsAvailableForTenderDocumentationForSS,
  skipQuery: skipIfNull(null)
};

export function useRoadmaps(filter) {
  return useRetriableGraphql({
    queryOptions: roadmapsQueryOptions,
    variables: filter
      ? {
          filter
        }
      : null
  });
}

export function useRoadmapsForMonitoring(input) {
  return useRetriableGraphql({
    queryOptions: roadmapsForMonitoringQueryOptions,
    variables: input ? { input } : null
  });
}

export function useRoadmapsAvailableForContestDocsMonitoring(input) {
  return useRetriableGraphql({
    queryOptions: roadmapsAvailableForContestDocsMonitoringQueryOptions,
    variables: input ? { input } : null
  });
}
