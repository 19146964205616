import { QUERY_GET_ORGANIZATIONS } from '../../graphql/queries/organizations';

export class OrganizationsStore {
  constructor({ client }) {
    this.client = client;
  }

  searchOrganizations = (variables) => {
    return this.client.query({
      fetchPolicy: 'network-only',
      query: QUERY_GET_ORGANIZATIONS,
      variables: {
        input: variables
      }
    });
  };

  getSuggestions(data) {
    return data.organizations.list.map(({ id, name_full }) => ({
      value: id,
      label: name_full
    }));
  }
}
