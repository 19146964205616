import React from 'react';
import Loader from './Loader/Loader';
import ErrorMessage from './ErrorMessage/ErrorMessage';

export function QueryLoader({ query, render }) {
  return query.check.spinner ? (
    <Loader />
  ) : query.check.error ? (
    <ErrorMessage error={query.state.error} load={query.load} />
  ) : (
    render()
  );
}

export function multiQueryLoader(queries) {
  const spinner = queries.some((q) => q.check.spinner);
  const errorQuery = queries.find((q) => q.check.error);
  return spinner ? (
    <Loader />
  ) : errorQuery ? (
    <ErrorMessage error={errorQuery.state.error} load={errorQuery.load} />
  ) : null;
}

export function queryLoader(query) {
  return query.check.spinner ? (
    <Loader />
  ) : query.check.error ? (
    <ErrorMessage error={query.state.error} load={query.load} />
  ) : null;
}

export function queryLoaderInline(query) {
  return query.check.spinner ? (
    '...'
  ) : query.check.error ? (
    <ErrorMessage error={query.state.error} load={query.load} />
  ) : null;
}

export function queryLoaderWithExistenceCheck(
  query,
  renderNotFound = () => 'Не найдено'
) {
  return (
    queryLoader(query) || (!query.state.data ? renderNotFound() : undefined)
  );
}
