import React from 'react';
import { Signals } from '../Inputs/Signals';
import { NumberFormatField } from './NumberFormatField';
import { NewTextField } from './NewTextField';

export const NewNumberField = React.memo(function NewCurrencyField({
  unit,
  signals,
  decimalScale,
  fixedDecimalScale,
  ...props
}) {
  const inputComponent = React.useCallback(
    (props) => {
      return (
        <NumberFormatField
          {...props}
          unit={unit}
          decimalScale={decimalScale}
          fixedDecimalScale={fixedDecimalScale}
        />
      );
    },
    [unit, decimalScale, fixedDecimalScale]
  );

  return (
    <>
      <NewTextField
        {...props}
        InputProps={{
          inputComponent,
          autoComplete: 'off'
        }}
      />
      <Signals signals={signals?.[props.name]} />
    </>
  );
});
