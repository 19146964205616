import React, { useMemo } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useContextStore } from '@proscom/prostore-react';
import { CircularProgress } from '@material-ui/core';
import { STORE_POF } from '../../store/names';
import Menu from '../../common/Menu/Menu';
import Table from '../../common/Table/Table';
import { useCurrentUser } from '../../store/useCurrentUser';
import { PofAuth } from '../../store/pof/PofAuth';
import { useDeleteRow } from '../../common/Table/useDeleteRow';

export const PofsListTable = ({
  data,
  match,
  history,
  canCreate: canCreateProp,
  POFsColumns,
  filter,
  addFieldRoute,
  rowRedirectRoute
}) => {
  const pofStore = useContextStore(STORE_POF);

  const user = useCurrentUser();
  const canCreate = canCreateProp && PofAuth.canCreate(user);

  const menuComponent = useMemo(
    () =>
      function MenuComponent({ row }) {
        const [isDeleting, dialog, openDialog] = useDeleteRow(
          pofStore.deletePof,
          'ПОФ'
        );

        const actions = [];

        if (PofAuth.canEdit(row, user)) {
          actions.push({
            title: 'Редактировать',
            onClick: () => {
              if (match.params.projectId)
                return history.push(`${match.url}/pofs/${row.id}/edit`);
              return history.push(`/pofs/${row.id}/edit`);
            }
          });
        }

        if (PofAuth.canDelete(row, user)) {
          actions.push({
            title: 'Удалить',
            onClick: () => openDialog(row.id)
          });
        }

        if (actions.length === 0) {
          return false;
        }

        return (
          <>
            {isDeleting ? (
              <CircularProgress size={18} />
            ) : (
              <Menu
                iconColor="default"
                icon={MoreHorizIcon}
                options={actions}
              />
            )}
            {dialog}
          </>
        );
      },
    [history, match, pofStore, user]
  );

  return (
    <>
      <Table
        addButtonText="Создать"
        addFieldRoute={addFieldRoute}
        hasAddField={canCreate}
        columns={POFsColumns}
        data={data}
        filter={filter}
        rowRedirectRoute={rowRedirectRoute}
        menuComponent={menuComponent}
      />
    </>
  );
};
