import React from 'react';
import { Notifications } from '../../../store/notification/Notification';
import { NewAutocompleteInput } from '../../InputsNew/NewAutocompleteInput';

export const NotificationSelector = (props) => {
  return (
    <NewAutocompleteInput
      {...props}
      value={props.value || 'all'}
      suggestions={Notifications.type.getOptions()}
      isClearable={false}
      hasCopyBtn={false}
      fullWidth
    />
  );
};
