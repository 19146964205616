import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import className from 'classnames';
import SubTable from '../../../common/SubTable/SubTable';
import s from './InformationFields.module.scss';

export function InformationRegionField() {
  return (
    <SubTable>
      <TableRow>
        <TableCell className={s.description} colSpan={2}>
          <p>
            В рамках реализации федерального проекта «Поддержка семей, имеющих
            детей» национального проекта «Образование» предусмотрено проведение
            в 2020 году апробации использования методических рекомендаций по
            обеспечению информационно-просветительской поддержки родителей.
          </p>
          <p>
            В рамках проведения апробации просим пройти данный опрос и
            представить информацию об использовании методических рекомендаций по
            оказанию методической, психолого-педагогической, диагностической и
            консультативной помощи родителям (законным представителям)
            несовершеннолетних обучающихся, обеспечивающим получение детьми
            дошкольного образования в форме семейного образования, утвержденных
            31.05.2019 № МР-78/02вн.
          </p>
          <p>
            <a href="/Методические_рекомендации_по_оказанию_методической,_психолого-педагогической,_диагностической_и_консультативной_помощи.pdf">
              Методические рекомендации по оказанию методической,
              психолого-педагогической, диагностической и консультативной помощи
              родителям (законным представителям) несовершеннолетних
              обучающихся, обеспечивающим получение детьми дошкольного
              образования в форме семейного образования
            </a>
          </p>
        </TableCell>
      </TableRow>
    </SubTable>
  );
}

export function InformationOrganizationFiled() {
  return (
    <div className={s.description}>
      <p>
        В рамках реализации федерального проекта «Поддержка семей, имеющих
        детей» национального проекта «Образование» (далее – федеральный проект)
        предусмотрено проведение в 2020 году апробации использования
        методических рекомендаций по обеспечению информационно-просветительской
        поддержки родителей.
      </p>
      <p>
        В рамках проведения апробации просим пройти данный опрос и представить
        информацию об использовании методических рекомендаций по организации
        процесса оказания психолого-педагогической, методической и
        консультативной помощи родителям (законным представителям) детей, а
        также гражданам, желающим принять на воспитание в свои семьи детей,
        оставшихся без попечения родителей, утвержденных распоряжением
        Министерства просвещения Российской Федерации от 01.03.2019 № Р-26
      </p>
      <p>
        <a href="/Методические_рекомендации_по_организации_процесса_оказания_психолого-педагогической_помощи_родителям.pdf">
          Методические рекомендации по организации процесса оказания
          психолого-педагогической, методической и консультативной помощи
          родителям (законным представителям).pdf
        </a>
      </p>
    </div>
  );
}

export function AttentionInformation() {
  return (
    <SubTable>
      <TableRow>
        <TableCell
          className={className(s.description, s.attention)}
          colSpan={2}
        >
          <p>
            Внимание! Работа в форме (заполнение, сохранение, утверждение
            данных) должна производиться в одной открытой вкладке браузера одним
            пользователем. Одновременная работа нескольких сессий может привести
            к перезаписи данных, которые были заполнены в другой вкладке или
            сохранены другим пользователем
          </p>
        </TableCell>
      </TableRow>
    </SubTable>
  );
}
