import { skipIfNull } from '@proscom/prostore';
import { QUERY_GET_SUBSIDIARY_ENTITY_TYPES } from '../../graphql/queries/subsidiaryEntities';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';

const seTypesQueryOptions = {
  query: QUERY_GET_SUBSIDIARY_ENTITY_TYPES,
  mapData: (result) => result.seTypes,
  skipQuery: skipIfNull(null)
};

export function useSeTypes(filter) {
  return useRetriableGraphql({
    queryOptions: seTypesQueryOptions,
    variables: filter ? { filter } : null
  });
}
