import React from 'react';
import s from './CommentInfoLine.module.scss';

export const CommentInfoLine = ({ label, title, description }) => (
  <div className={s.row}>
    <div className={s.smallGrayTitle}>{label}</div>
    <div className={s.smallBlackTitle}>{title}</div>
    {description && <div className={s.text}>{description}</div>}
  </div>
);
