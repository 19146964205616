import { pick } from 'lodash-es';
import { EnumHelpers } from '../../utils/EnumHelpers';
import { User } from '../role/User';

const classesScheduleFields = [
  // Поля мщдели
  'id',
  'comment_fpo',
  'status',
  'year',

  // Поля инпута
  'fact_date',
  'subsidiary_entity_id'
];

export class AdditionalData {
  static Status = {
    APPROVED: 'APPROVED',
    DRAFT: 'DRAFT',
    FOR_APPROVAL: 'FOR_APPROVAL',
    RETURNED: 'RETURNED'
  };

  static status = new EnumHelpers(this.Status, {
    DRAFT: 'Черновик',
    FOR_APPROVAL: 'На утверждении',
    RETURNED: 'Возвращено на доработку',
    APPROVED: 'Утверждено'
  });

  static toForm = (data) => {
    return data;
  };

  static fromForm = ({ subsidiary_entity_id, ...data }) => {
    return {
      subsidiary_entity_id: subsidiary_entity_id.id,
      ...data
    };
  };

  static filterUpdateFields = (data) => {
    return {
      ...pick(data, classesScheduleFields)
    };
  };

  static getUrl(classesSchedule, projectId, roadmapId) {
    if (projectId) {
      return `/projects/${projectId}/roadmaps/${roadmapId}/additionalData/${classesSchedule[0].id}`;
    }
    return `/roadmaps/${roadmapId}/additionalData/${classesSchedule[0].id}`;
  }

  static canCreateClassesSchedule(user) {
    return User.hasPermission(user, 'class_schedule.crud');
  }

  static canEditClassesSchedule(user, classesSchedule) {
    return (
      ((classesSchedule?.status === AdditionalData.Status.DRAFT ||
        classesSchedule?.status === AdditionalData.Status.RETURNED) &&
        User.hasPermission(user, 'class_schedule.crud')) ||
      (classesSchedule?.status === AdditionalData.Status.FOR_APPROVAL &&
        User.hasPermission(user, 'class_schedule.approve')) ||
      User.isAdmin(user)
    );
  }

  static canDeleteClassesSchedule(user, classesSchedule) {
    return (
      ((classesSchedule?.status === AdditionalData.Status.DRAFT ||
        classesSchedule?.status === AdditionalData.Status.RETURNED) &&
        User.hasPermission(user, 'class_schedule.crud')) ||
      User.isAdmin(user)
    );
  }

  static canSendForApproval(user, classesSchedule) {
    return (
      (classesSchedule?.status === AdditionalData.Status.DRAFT ||
        classesSchedule?.status === AdditionalData.Status.RETURNED) &&
      User.hasPermission(user, 'class_schedule.send_for_approval')
    );
  }

  static canApprove(user, classesSchedule) {
    return (
      (classesSchedule?.status === AdditionalData.Status.FOR_APPROVAL &&
        User.hasPermission(user, 'class_schedule.approve')) ||
      User.isAdmin(user)
    );
  }
}
