import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Button, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Delete, Edit } from '@material-ui/icons';
import { useStore } from '@proscom/prostore-react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormikTextField } from '../../../../../common/infralist/FormikFormFields/FormikTextField/FormikTextField';
import { ProgressButton } from '../../../../../common/ProgressButton';
import { useInfralistSingleCommentAccesses } from '../../../../../store/infralist/comments/useInfralistSingleCommentAccesses';
import { STORE_AUTH } from '../../../../../store/names';
import { validationMessages } from '../../../../../utils/data/validation';
import { useUserRoleByPermissions } from '../../../../../utils/hooks/useUserRoleByPermissions';
import { useCommentDate } from './useCommentDate';
import { useCommentStatus } from './useCommentStatus';
import s from './Comment.module.scss';

const validationSchema = yup.object().shape({
  content: yup
    .string()
    .required(validationMessages.requiredMessage)
    .trim(validationMessages.requiredMessage)
});

export const Comment = ({
  data,
  onEdit,
  onDelete,
  canDeleted,
  isEditEnabledByEventStatus
}) => {
  const [auth] = useStore(STORE_AUTH);
  const user = auth.authData.user;
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const { canEdit } = useInfralistSingleCommentAccesses(
    data?.commentary_user,
    isEditEnabledByEventStatus
  );
  const { getRole } = useUserRoleByPermissions();
  const commentRole = getRole(data?.commentary_user);
  const date = useCommentDate(data?.created_at);
  const status = data?.accept_status;
  const commentStatus = useCommentStatus(status);
  const commentRef = useRef(null);

  const onSubmit = (values, actions) => {
    setLoading(true);
    if (onEdit) {
      onEdit({
        id: data.id,
        content: values.content
      })
        .then(() => {
          actions.resetForm();
          setEdit(false);
        })
        .finally(() => setLoading(false));
    }
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    }
  };

  const onEnableEdit = () => {
    setEdit(true);
  };

  /**
   * когда нажимаем на редактирование и комментов много и есть скролл
   * прокручивем чтобы кнопки "сохранить", "отменить" были видны пользователю
   */
  useEffect(() => {
    if (edit && commentRef.current?.scrollIntoViewIfNeeded) {
      commentRef.current.scrollIntoViewIfNeeded();
    }
  }, [edit]);

  const Actions = () => (
    <div className={s.CommentIcons}>
      <IconButton onClick={onEnableEdit} size={'small'}>
        <Edit fontSize={'small'} />
      </IconButton>
      {canDeleted && (
        <IconButton onClick={handleDelete} size={'small'}>
          <Delete fontSize={'small'} />
        </IconButton>
      )}
    </div>
  );
  return (
    <div ref={commentRef}>
      <div className={s.Comment}>
        <div className={s.CommentHeader}>
          <div className={classNames(s.CommentName, s.Text)}>
            {commentRole?.type === 'fpo' ? 'ФПО: ' : 'РВПО: '}{' '}
            {data.user_id === user.id ? 'Вы ' : data.commentary_user?.name}
          </div>
          <div className={s.CommentDate}>{date}</div>
          {canEdit && !status && <Actions />}
        </div>
        {edit ? (
          <Formik
            initialValues={{
              content: data.content
            }}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <Form>
              <FormikTextField
                multiline
                name={'content'}
                placeholder={'Комментарий...'}
              />
              <ProgressButton
                color={'secondary'}
                type={'submit'}
                isLoading={loading}
              >
                Сохранить
              </ProgressButton>
              <Button color={'default'} onClick={() => setEdit(false)}>
                Отменить
              </Button>
            </Form>
          </Formik>
        ) : (
          <div className={classNames(s.Text, s.CommentText)}>
            {data.content}
          </div>
        )}
      </div>
      {status && commentStatus}
    </div>
  );
};

Comment.propTypes = {
  data: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};
