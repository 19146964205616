import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useCallback } from 'react';
import {
  MUTATION_APPROVE_EQUIPMENT,
  MUTATION_CHECK_EQUIPMENT,
  MUTATION_CREATE_EQUIPMENT,
  MUTATION_DELETE_EQUIPMENT,
  MUTATION_SIGN_EQUIPMENT,
  MUTATION_UPDATE_EQUIPMENT
} from '../../graphql/mutations/equipment';
import { QUERY_INFRALIST_SECTION } from '../../graphql/queries/infralist';
import { clearApolloQueryCache } from '../../utils/apollo';
import { removeEmptyRowsFromString } from '../../utils/data/string';
import { INFRALIST_QUERIES } from './infralistQueries';

export function useEquipmentMutations() {
  const client = useContextApolloClient();

  const create = ({ data, sectionId, infralistId }) => {
    return client.mutate({
      mutation: MUTATION_CREATE_EQUIPMENT,
      variables: {
        input: data
      },
      refetchQueries: [
        {
          query: QUERY_INFRALIST_SECTION,
          variables: {
            section_id: sectionId,
            infralist_id: infralistId
          }
        },
        INFRALIST_QUERIES.DIRECTION
      ],
      update: (store) => {
        clearApolloQueryCache(store, [
          INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT,
          INFRALIST_QUERIES.DIRECTION_SECTIONS,
          INFRALIST_QUERIES.DIRECTION,
          INFRALIST_QUERIES.DIRECTIONS_LIST,
          INFRALIST_QUERIES.SECTION
        ]);
      }
    });
  };

  const edit = async (data) => {
    return await client.mutate({
      mutation: MUTATION_UPDATE_EQUIPMENT,
      variables: {
        input: {
          id: data.id,
          fpo_equipment_id: data.fpo_equipment_id,
          section_id: data.section_id,
          infralist_id: data.infralist_id,
          unit: data.unit,
          price: data.price,
          name_rvpo: data.name_rvpo,
          description_rvpo: data.description_rvpo,
          model_rvpo: data.model_rvpo,
          quantity: data.quantity,
          equipment_external_url: removeEmptyRowsFromString(
            data.equipment_external_url
          ).join('\n'),
          equipment_status_id: data.equipment_status_id,
          equipment_approvement_status_id: data.equipment_approvement_status_id,
          check_status_id: data.check_status_id
        }
      },
      refetchQueries: [
        INFRALIST_QUERIES.RVPO_EQUIPMENT,
        INFRALIST_QUERIES.SECTION,
        INFRALIST_QUERIES.DIRECTION
      ],
      update: (store) =>
        clearApolloQueryCache(store, [
          INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT,
          INFRALIST_QUERIES.DIRECTION_SECTIONS,
          INFRALIST_QUERIES.DIRECTION,
          INFRALIST_QUERIES.DIRECTIONS_LIST,
          INFRALIST_QUERIES.RVPO_EQUIPMENT
        ])
    });
  };

  const deleteEquipment = async (id) => {
    return await client.mutate({
      mutation: MUTATION_DELETE_EQUIPMENT,
      variables: {
        id
      },
      refetchQueries: [INFRALIST_QUERIES.SECTION, INFRALIST_QUERIES.DIRECTION],
      update: (store) =>
        clearApolloQueryCache(store, [
          INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT,
          INFRALIST_QUERIES.DIRECTION_SECTIONS,
          INFRALIST_QUERIES.DIRECTION,
          INFRALIST_QUERIES.DIRECTIONS_LIST,
          INFRALIST_QUERIES.RVPO_EQUIPMENT
        ])
    });
  };

  const sign = useCallback(
    ({ statusId, equipmentId, infralistId }) => {
      return client.mutate({
        mutation: MUTATION_SIGN_EQUIPMENT,
        variables: {
          infralist_id: infralistId,
          status_id: statusId,
          rvpo_equipment_id: equipmentId
        },
        update: (store) =>
          clearApolloQueryCache(store, [
            INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT,
            INFRALIST_QUERIES.RVPO_EQUIPMENT
          ]),
        refetchQueries: [INFRALIST_QUERIES.RVPO_EQUIPMENT]
      });
    },
    [client]
  );

  const check = useCallback(
    ({ statusId, equipmentId }) => {
      return client.mutate({
        mutation: MUTATION_CHECK_EQUIPMENT,
        variables: {
          status_id: statusId,
          rvpo_equipment_id: equipmentId
        },
        update: (store) =>
          clearApolloQueryCache(store, [
            INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT,
            INFRALIST_QUERIES.RVPO_EQUIPMENT
          ]),
        refetchQueries: [INFRALIST_QUERIES.RVPO_EQUIPMENT]
      });
    },
    [client]
  );

  const approve = useCallback(
    ({ statusId, equipmentId }) => {
      return client.mutate({
        mutation: MUTATION_APPROVE_EQUIPMENT,
        variables: {
          approve_status_id: statusId,
          rvpo_equipment_id: equipmentId
        },
        update: (store) =>
          clearApolloQueryCache(store, [
            INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT,
            INFRALIST_QUERIES.RVPO_EQUIPMENT
          ]),
        refetchQueries: [INFRALIST_QUERIES.RVPO_EQUIPMENT]
      });
    },
    [client]
  );

  return {
    createEquipment: create,
    editEquipment: edit,
    deleteEquipment,
    signEquipment: sign,
    checkEquipment: check,
    approveEquipment: approve
  };
}
