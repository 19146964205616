import React from 'react';
import { Prompt } from 'react-router-dom';
import { Form } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { getName } from '../../../../utils/data/label';
import { FormHeader } from '../../../../common/Form/Form';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import SubTable from '../../../../common/SubTable/SubTable';
import { ParentalAssistanceGuideline } from '../../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import { ProcedureRegionalPart } from '../../parts/ProcedureRegionalPart';
import { ResultPart } from '../../parts/ResultPart';
import { FilesRegionalPart } from '../../parts/FilesRegionalPart';
import { NewAutocompleteInput } from '../../../../common/InputsNew/NewAutocompleteInput';
import { OrganizationsDataFields } from '../../parts/OrganizationsDataFields';
import {
  AttentionInformation,
  InformationRegionField
} from '../../common/InformationFields';

export const ParentalAssistanceGuidelinesRegionalEditForm = ({
  onBackClick,
  isSubmitting,
  editor,
  status,
  values,
  setValue,
  region,
  touched,
  errors
}) => {
  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <Form noValidate>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting}
          editor={editor}
          isSubmitting={isSubmitting}
        />
        <AttentionInformation />
        <InformationRegionField />
        <SubTable title="Общие сведения" editVariant>
          <TableRow>
            <TableCell>Регион</TableCell>
            <TableCell>{getName(region)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Статус</TableCell>
            <TableCell>
              <NewAutocompleteInput
                name="status"
                suggestions={ParentalAssistanceGuideline.status.getOptions()}
                label={'Статус'}
                hasCopyBtn={false}
                fullWidth
              />
            </TableCell>
          </TableRow>
        </SubTable>
        <FilesRegionalPart values={values} touched={touched} errors={errors} />
        <ProcedureRegionalPart
          values={values}
          setValue={setValue}
          touched={touched}
          errors={errors}
        />
        <ResultPart values={values} touched={touched} errors={errors} />
        <OrganizationsDataFields />
      </Form>
    </>
  );
};
