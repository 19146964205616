import { useGraphqlQueryVars } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { QUERY_GET_REGIONAL_PROJECT } from '../../graphql/queries/regionalProjects';

const projectQueryOptions = {
  query: QUERY_GET_REGIONAL_PROJECT,
  mapData: (result) => result.regionalProject,
  skipQuery: skipIfNull(null)
};

export function useRegionalProject(id) {
  return useGraphqlQueryVars({
    queryOptions: projectQueryOptions,
    variableCreator: [
      () =>
        id
          ? {
              id
            }
          : null,
      [id]
    ]
  });
}
