import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import Loader from '../../../../common/Loader/Loader';
import { equipmentStatusService } from '../../../../store/infralist/equipmentStatusService';
import { infralistCheckStatusService } from '../../../../store/infralist/statuses/infralistCheckStatusService';
import { useChangeLog } from '../sidebar/useChangeLog';
import { getSubsidiaryLabel } from '../sidebar/changeLogUtils';
import { localeNumberFormat } from '../../../../utils/localeNumberFormat';
import { clearApolloQueryCache } from '../../../../utils/apollo';
import { DiffRow, DiffRowArray } from './DiffRow';
import s from './ChangeLogDiff.module.scss';

const titles = ['Выбранная версия', 'Актуальная версия'];

export const ChangeLogDiff = ({ currentChangeLogId }) => {
  const { changeLog, isLoadingChangeLog } = useChangeLog(currentChangeLogId);
  const client = useContextApolloClient();

  useEffect(() => {
    clearApolloQueryCache(client.store.getCache(), ['infralistChangeLog']);
  }, [client.store, currentChangeLogId]);

  if (isLoadingChangeLog || !changeLog) {
    return (
      <div className={s.loader}>
        <Loader />
      </div>
    );
  }

  const { actual, version } = changeLog;

  return (
    <div className={s.root}>
      <div className={s.grid}>
        {titles.map((title, i) => (
          <p className={classNames(s.gridCell, s.title)} key={i}>
            {title}
          </p>
        ))}

        <DiffRow
          title="Субсидиарная сущность"
          version={getSubsidiaryLabel(version?.subsidiary_entity_data)}
          actual={getSubsidiaryLabel(actual?.subsidiary_entity_data)}
        />

        <DiffRow
          title="Наименование оборудование (ФПО)"
          subTitleVersion={version?.fpo_equipment?.name}
          subTitleActual={actual?.fpo_equipment?.name}
          version={version?.fpo_equipment?.fpo_description}
          actual={actual?.fpo_equipment?.fpo_description}
        />

        <DiffRow
          title="Наименование оборудования (РВПО)"
          subTitleVersion={version?.name_rvpo}
          subTitleActual={actual?.name_rvpo}
          version={version?.description_rvpo}
          actual={actual?.description_rvpo}
        />

        <DiffRow
          title="Примерная модель"
          version={version?.model_rvpo}
          actual={actual?.model_rvpo}
        />

        <DiffRow
          title="Количество"
          version={
            version?.quantity || version?.unit
              ? `${version?.quantity ?? ''} ${version?.unit ?? ''}`
              : ''
          }
          actual={
            actual?.quantity || actual?.unit
              ? `${actual?.quantity ?? ''} ${actual?.unit ?? ''}`
              : ''
          }
        />

        <DiffRow
          title="Цена, руб"
          version={
            version?.price
              ? localeNumberFormat({
                  number: version?.price,
                  minLength: 2,
                  maxLength: 2
                })
              : undefined
          }
          actual={localeNumberFormat({
            number: actual?.price,
            minLength: 2,
            maxLength: 2
          })}
        />

        <DiffRow
          title="Стоимость, руб"
          version={
            version?.full_price
              ? localeNumberFormat({
                  number: version.full_price,
                  minLength: 2,
                  maxLength: 2
                })
              : undefined
          }
          actual={localeNumberFormat({
            number: actual?.full_price,
            minLength: 2,
            maxLength: 2
          })}
        />

        <DiffRowArray
          title="Ссылка на оборудование"
          version={version?.equipment_external_url.split('\n')}
          actual={actual?.equipment_external_url.split('\n')}
          render={(value, diff) => (
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              className={s.link}
            >
              {diff}
            </a>
          )}
        />

        <DiffRow
          title="Результат проверки"
          version={version?.check_status?.name}
          actual={actual?.check_status?.name}
          render={(value) => infralistCheckStatusService.getColoredName(value)}
        />

        <DiffRow
          title="Статус согласования"
          version={version?.equipment_approvement_status?.name}
          actual={actual?.equipment_approvement_status?.name}
          render={(value) => equipmentStatusService.getColoredName(value)}
        />
      </div>
    </div>
  );
};
