import React, { useState } from 'react';
import { Form } from 'formik';
import { Prompt } from 'react-router';
import { FormHeader } from '../../../../common/Form/Form';
import { RegionalEvent } from '../../../../store/roadmap/RegionalEvent';
import useScrollToError from '../../../../common/Form/useScrollToError';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { isInfralist } from '../../../../store/roadmap/roadmapEvents';
import { CommonInfo } from './parts/CommonInfo';
import { StatusInfo } from './parts/StatusInfo';
import { ResponsiblePersonInfo } from './parts/ResponsiblePersonInfo';
import { ResultInfo } from './parts/ResultInfo';
import { DocumentsInfo } from './parts/DocumentsInfo';
import { AnnotationInfo } from './parts/AnnotationInfo';
import { TermsInfo } from './parts/TermsInfo';
import { UtilsInfo } from './parts/UtilsInfo';
import { StaffScheduleInfo } from './parts/StaffScheduleInfo';
import { InfralistsEditInfo } from './parts/InfralistsEditInfo';

export const RegionalEventEditForm = ({
  onBackClick,
  editor,
  isSubmitting,
  isValid,
  errors,
  status,
  values,
  event,
  regionalEvent,
  user
}) => {
  useScrollToError({ errors, isSubmitting, isValid });

  const [staffError, setStaffError] = useState(false);
  const [haveReason, setHaveReason] = useState(false);

  const canEditRegionalField = RegionalEvent.canEditRegionalField(user);
  const canEditStatus = RegionalEvent.canEditStatus(user);
  const canUploadDocuments = RegionalEvent.canUploadDocuments(user);
  const canEditFactTerm = RegionalEvent.canEditFactTerm(user);

  const project = regionalEvent.regional_project;
  const roadmap = event.roadmap;
  const region = regionalEvent.region;

  const disabled = staffError ? !haveReason : false;
  const isInfralistEvent = isInfralist(event.event_type);

  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <Form>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting || disabled}
          editor={editor}
          isSubmitting={isSubmitting}
          entityName={`Мероприятие`}
        />
        <CommonInfo
          event={event}
          region={region}
          project={project}
          roadmap={roadmap}
        />
        <StatusInfo
          canEditStatus={canEditStatus}
          user={user}
          regionalEvent={regionalEvent}
        />
        <ResponsiblePersonInfo
          event={event}
          canEditRegionalField={canEditRegionalField}
        />
        <ResultInfo event={event} />
        <DocumentsInfo
          event={event}
          regionalEvent={regionalEvent}
          region={region}
          roadmap={roadmap}
          canEditStatus={canEditStatus}
          canUploadDocuments={canUploadDocuments}
          status={values.status}
          staffError={staffError}
          setHaveReason={setHaveReason}
          haveReason={haveReason}
        />
        {isInfralistEvent && (
          <InfralistsEditInfo
            regionalEvent={regionalEvent}
            event={event}
            year={event.year}
            eventType={event.event_type}
            regionId={region.id}
            roadmapId={roadmap.id}
          />
        )}
        <StaffScheduleInfo
          name="staff_schedules"
          eventType={event.event_type}
          eventYear={event.year}
          region={region}
          roadmap={roadmap}
          setStaffError={setStaffError}
          values={values}
        />
        <AnnotationInfo canEditRegionalField={canEditRegionalField} />
        <TermsInfo event={event} canEditFactTerm={canEditFactTerm} />
        <UtilsInfo regionalEvent={regionalEvent} />
      </Form>
    </>
  );
};
