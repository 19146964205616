import { skipIfNull } from '@proscom/prostore';
import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import React, { useEffect, useMemo, useState } from 'react';
import { QUERY_INFRALIST_RVPO_EQUIPMENT_CHECK_STATUSES } from '../../graphql/queries/infralistStatuses';
import { infralistCheckStatusService } from './statuses/infralistCheckStatusService';

const options = {
  query: QUERY_INFRALIST_RVPO_EQUIPMENT_CHECK_STATUSES,
  mapData: (result) => result.infralistRvpoEquipmentCheckStatuses,
  skipQuery: skipIfNull(null)
};

export const useEquipmentCheckStatuses = (hasAccess) => {
  const [statusList, setStatusList] = useState([]);
  const client = useContextApolloClient();

  useEffect(() => {
    if (hasAccess) {
      client
        .query({
          ...options,
          variables: {}
        })
        .then((response) => {
          setStatusList(response.data.infralistRvpoEquipmentCheckStatuses);
        });
    }
  }, [client, hasAccess]);

  const statusListOptions = useMemo(() => {
    return statusList.map((item) => ({
      value: item.id,
      label: infralistCheckStatusService.getColoredName(item.name)
    }));
  }, [statusList]);

  return {
    checkStatusList: statusList,
    checkStatusListOptions: statusListOptions
  };
};
