import React from 'react';
import { Redirect } from 'react-router';
import { useStore } from '@proscom/prostore-react';
import { STORE_AUTH } from '../../store/names';
import { User } from '../../store/role/User';
import { PAGES_LIST } from '../../utils/constants';

export default function IndexPage(props) {
  const [auth] = useStore(STORE_AUTH);
  if (!auth.loaded || !auth.authData || !auth.authData.user) return null;
  const user = auth.authData.user;
  if (user && user.role === User.Role.ORGANIZATION_ADMIN) {
    return <Redirect to="/monitoring" />;
  }

  for (const page of PAGES_LIST) {
    if (User.hasPermission(user, ...page.permissions)) {
      return <Redirect to={page.route} />;
    }
  }

  return <Redirect to="/projects" />;
  // return (
  //   <Typography paragraph>
  //     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
  //     tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus
  //     non enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
  //     imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
  //     Convallis convallis tellus id interdum velit laoreet id donec ultrices.
  //     Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
  //     adipiscing bibendum est ultricies integer quis. Cursus euismod quis
  //     viverra nibh cras. Metus vulputate eu scelerisque felis imperdiet proin
  //     fermentum leo. Mauris commodo quis imperdiet massa tincidunt. Cras
  //     tincidunt lobortis feugiat vivamus at augue. At augue eget arcu dictum
  //     varius duis at consectetur lorem. Velit sed ullamcorper morbi tincidunt.
  //     Lorem donec massa sapien faucibus et molestie ac.
  //   </Typography>
  // );
}
