import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { QUERY_INFRALIST_SECTION } from '../../graphql/queries/infralist';
import { infralistStatusService } from './infralistStatusService';

const infralistDirectionsQueryOptions = {
  query: QUERY_INFRALIST_SECTION,
  mapData: (result) => {
    return {
      ...result.infralistSection,
      status: result.infralistSection?.status ?? {
        name: infralistStatusService.defaultStatus
      }
    };
  },
  skipQuery: skipIfNull(null)
};

export function useSection(sectionId, infralistId) {
  return useRetriableGraphql({
    queryOptions: infralistDirectionsQueryOptions,
    variables: {
      section_id: sectionId,
      infralist_id: infralistId
    }
  });
}
