import React from 'react';

import { useRegionalProject } from '../../../store/regionalProject/useRegionalProject';
import { useRoadmapEvent } from '../../../store/roadmap/useRoadmapEvent';

import {
  queryLoader,
  queryLoaderWithExistenceCheck
} from '../../../common/QueryLoader';

import RegionalEventPage from './RegionalEventPage';

export default function ProjectRegionalEventPage({ match }) {
  const { projectId, roadmapId, eventId } = match.params;

  const projectQuery = useRegionalProject(projectId);
  const project = projectQuery.state.data;

  const regionId = project?.region_id;

  const roadmapEventQuery = useRoadmapEvent(eventId, regionId, true);
  const event = roadmapEventQuery.state.data;
  const regionalEvent = event?.regional_roadmap_events?.[0];

  return (
    queryLoader(projectQuery) ||
    queryLoaderWithExistenceCheck(roadmapEventQuery) || (
      <RegionalEventPage
        event={event}
        regionalEvent={regionalEvent}
        currentUrl={match.url}
        projectId={projectId}
        roadmapId={roadmapId}
        eventId={eventId}
      />
    )
  );
}
