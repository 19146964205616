import React from 'react';
import PropTypes from 'prop-types';
import { LabelField } from '../../../../../common/infralist/FormFields/LabelField/LabelField';
import { TextField } from '../../../../../common/infralist/FormFields/TextField/TextField';
import { FormikAutocomplete } from '../../../../../common/infralist/FormikFormFields/FormikAutocomplete/FormikAutocomplete';
import { FormikCurrency } from '../../../../../common/infralist/FormikFormFields/FormikCurrency';
import { FormikNumber } from '../../../../../common/infralist/FormikFormFields/FormikNumber';
import { FormikTextField } from '../../../../../common/infralist/FormikFormFields/FormikTextField/FormikTextField';
import { equipmentStatusService } from '../../../../../store/infralist/equipmentStatusService';
import {
  INFRALIST_STATUSES,
  infralistStatusService
} from '../../../../../store/infralist/infralistStatusService';
import { infralistCheckStatusService } from '../../../../../store/infralist/statuses/infralistCheckStatusService';
import { useEquipmentCheckStatuses } from '../../../../../store/infralist/useEquipmentCheckStatuses';
import { useEquipmentStatuses } from '../../../../../store/infralist/useEquipmentStatuses';
import {
  useInfralistSectionUserAccesses,
  useInfralistUserAccesses
} from '../../../../../store/infralist/useInfralistUserAccesses';
import { localeNumberFormat } from '../../../../../utils/localeNumberFormat';
import s from './EquipmentInfo.module.scss';

export const EquipmentInfoForm = ({
  data,
  equipments,
  equipmentMap,
  infralistId,
  infralistSectionCheckStatus,
  sectionStatus,
  equipmentId,
  infralistStatus,
  infralistSectionApproveStatus,
  isEditEnabledByEventStatus
}) => {
  const {
    canFetchEquipmentStatuses,
    canFetchEquipmentCheckStatuses
  } = useInfralistUserAccesses();
  const {
    showStatusColumn,
    showStatusSignColumn,
    canSign,
    showApproveColumn,
    showApproveActions,
    canApprove,
    showCheckColumn,
    showCheckActions,
    canCheck
  } = useInfralistSectionUserAccesses({
    sectionStatus,
    infralistSectionCheckStatus,
    infralistStatus,
    infralistSectionApproveStatus,
    isEditEnabledByEventStatus
  });
  const { statusListOptions } = useEquipmentStatuses(canFetchEquipmentStatuses);

  const { checkStatusListOptions } = useEquipmentCheckStatuses(
    canFetchEquipmentCheckStatuses
  );

  // если раздел На согласовании и у оборудования нет статуса, то показываем статус раздела
  // иначе показываем статус согласования оборудования
  const approveStatusText =
    sectionStatus === INFRALIST_STATUSES.ON_APPROVAL &&
    equipmentStatusService.checkIsEmpty(
      data?.equipment_approvement_status?.name
    )
      ? infralistStatusService.getColoredName(sectionStatus)
      : equipmentStatusService.getColoredName(
          data?.equipmentApprovementequipment_approvement_statusStatus?.name
        );

  return (
    <div className={s.form}>
      <LabelField label={'Наименование оборудования (ФПО)'}>
        <FormikAutocomplete
          name={'fpo_equipment_id'}
          options={equipments}
          hasCopyBtn={true}
        />
      </LabelField>
      <LabelField label={'Краткие примерные технические характеристики (ФПО)'}>
        <TextField
          disabled
          multiline
          value={equipmentMap.get(data.fpo_equipment_id)?.description}
        />
      </LabelField>
      <LabelField label={'Наименование оборудования (РВПО)'}>
        <FormikTextField name={'name_rvpo'} />
      </LabelField>
      <LabelField label={'Краткие примерные технические характеристики (РВПО)'}>
        <FormikTextField name={'description_rvpo'} multiline />
      </LabelField>
      <LabelField label={'Примерная модель (РВПО)'}>
        <FormikTextField name={'model_rvpo'} multiline />
      </LabelField>
      <LabelField label={'Количество'}>
        <FormikNumber name={'quantity'} />
      </LabelField>
      <LabelField label={'Единица измерения'}>
        <FormikTextField name={'unit'} />
      </LabelField>
      <LabelField label={'Цена, руб.'}>
        <FormikCurrency name={'price'} />
      </LabelField>
      <LabelField label={'Стоимость, руб.'}>
        {localeNumberFormat({
          number: data.price * data.quantity,
          maxLength: 2,
          minLength: 2
        })}
      </LabelField>
      <LabelField label={'Ссылка на оборудование'}>
        <FormikTextField
          name={'equipment_external_url'}
          isValueTrimmed
          multiline
          className={s.links}
        />
      </LabelField>

      {/* Статус */}
      {showStatusColumn && (
        <LabelField label={'Статус'}>
          {equipmentStatusService.getColoredName(data?.equipment_status?.name)}
        </LabelField>
      )}

      {/* Статус подписания */}
      {showStatusSignColumn && (
        <LabelField label={'Статус'}>
          <FormikAutocomplete
            name={'equipment_status_id'}
            options={statusListOptions}
            placeholder={'Выбрать статус'}
            disabled={!canSign}
            searchable={false}
          />
        </LabelField>
      )}

      {/* Статус проверки */}
      {showCheckColumn && (
        <LabelField label={'Результат проверки'}>
          {!showCheckActions &&
            infralistCheckStatusService.getColoredName(
              data?.check_status?.name
            )}

          {showCheckActions && (
            <FormikAutocomplete
              name={'check_status_id'}
              options={checkStatusListOptions}
              placeholder={'Выбрать статус'}
              disabled={!canCheck}
              searchable={false}
            />
          )}
        </LabelField>
      )}

      {/* Статус согласования */}
      {showApproveColumn && (
        <LabelField label={'Статус согласования'}>
          {!showApproveActions && approveStatusText}

          {showApproveActions && (
            <FormikAutocomplete
              name={'equipment_approvement_status_id'}
              options={statusListOptions}
              placeholder={'Выбрать статус'}
              disabled={!canApprove}
              searchable={false}
            />
          )}
        </LabelField>
      )}
    </div>
  );
};

EquipmentInfoForm.propTypes = {
  data: PropTypes.object
};
