import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import s from './Inputs.module.scss';

const TextBaseInput = ({
  field: { value = '', name, onChange, onBlur },
  fullWidth,
  children,
  errorMessage,
  label,
  ...rest
}) => {
  return (
    <TextField
      id={name}
      name={name}
      label={label || ''}
      value={value || ''}
      fullWidth={fullWidth}
      margin="normal"
      error={!!errorMessage}
      helperText={errorMessage}
      InputProps={
        !fullWidth
          ? {
              classes: {
                root: s.TextInput
              },
              required: false
            }
          : {}
      }
      classes={{
        root: s.Field
      }}
      onChange={onChange}
      onBlur={onBlur}
      {...rest}
    >
      {children}
    </TextField>
  );
};

TextBaseInput.defaultProps = {
  errorMessage: null,
  variant: 'outlined',
  fullWidth: false,
  disabled: false
};

TextBaseInput.propTypes = {
  field: PropTypes.shape(),
  fullWidth: PropTypes.bool,
  form: PropTypes.shape(),
  InputProps: PropTypes.shape(),
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  unit: PropTypes.string
};

export default TextBaseInput;
