import React from 'react';
import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { QUERY_SUBSIDIARY_ENTITIES_FOR_FILTER } from '../../graphql/queries/subsidiaryEntities';
import { SubsidiaryEntity } from '../subsidiaryEntity/SubsidiaryEntity';

export const SUBSIDIARY_ENTITIES = {
  INFRALIST_SS: 'INFRALIST_SS',
  INFRALIST: 'INFRALIST'
};

const queryOptions = {
  query: QUERY_SUBSIDIARY_ENTITIES_FOR_FILTER,
  mapData: (result) =>
    result.subsidiaryEntitiesPage?.list?.map((item) => {
      const label = SubsidiaryEntity.getCompleteName(item);
      return { label, value: item.id };
    }),
  skipQuery: skipIfNull(null)
};

export const useSubsidiaryEntityList = (filter) => {
  const response = useRetriableGraphql({
    queryOptions,
    variables: filter
      ? {
          input: {
            filter
          }
        }
      : null
  });
  return response;
};
