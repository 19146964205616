import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import React from 'react';
import {
  FormName,
  RegionalProjectName,
  RegionName,
  RoadmapName
} from '../../../common/Form/Permissions/PermissionObjectNames';
import { Notifications } from '../../../store/notification/Notification';

export function RolePermissions({ permissions, allPermissions }) {
  if (!permissions) return false;
  return permissions.map((rolePermission, iPermission) => {
    const permission = allPermissions?.find(
      (permission) => permission.id === rolePermission.base_permission_id
    );

    if (permission?.type === Notifications.Type.notification.toUpperCase()) {
      return (
        <TableRow key={iPermission}>
          <TableCell>{permission?.name}</TableCell>
          {rolePermission.object_type === 'all' ? (
            <TableCell>Все</TableCell>
          ) : rolePermission.object_id ? (
            <TableCell>
              {Notifications.type.getName(rolePermission.object_id)}
            </TableCell>
          ) : (
            <TableCell />
          )}
        </TableRow>
      );
    }

    return (
      <TableRow key={iPermission}>
        <TableCell>{permission?.name}</TableCell>
        {rolePermission.object_type === 'all' ? (
          <TableCell>Все</TableCell>
        ) : rolePermission.object_id ? (
          rolePermission.base_permission_id === 'roadmap.access' ||
          rolePermission.base_permission_id === 'roadmap.edit' ? (
            <RoadmapName id={rolePermission.object_id} />
          ) : rolePermission.base_permission_id === 'region.access' ? (
            <RegionName id={rolePermission.object_id} />
          ) : rolePermission.base_permission_id ===
            'regional_project.access' ? (
            <RegionalProjectName id={rolePermission.object_id} />
          ) : rolePermission.base_permission_id === 'form.fill' ? (
            <FormName id={rolePermission.object_id} />
          ) : (
            <TableCell />
          )
        ) : (
          <TableCell />
        )}
      </TableRow>
    );
  });
}
