import React, { useMemo } from 'react';
import BreadCrumbs from '../../../common/BreadCrumbs/BreadCrumbs';
import { getRoadmapBreadCrumbs } from '../../roadmaps/roadmap/RoadmapBreadCrumbs';
import { getFederalEventBreadCrumbs } from '../../roadmaps/roadmap/federalEvent/getFederalEventBreadCrumbs';

export function getRegionalEventBreadCrumbs({
  projectId,
  projectName,
  roadmapId,
  roadmapName,
  eventId,
  eventName
}) {
  if (projectId) {
    return [
      ...getRoadmapBreadCrumbs({
        projectId,
        projectName
      }),
      {
        label: roadmapName || '...',
        to: `/projects/${projectId}/roadmaps/${roadmapId}`
      }
    ];
  }

  if (roadmapId) {
    return [
      ...getFederalEventBreadCrumbs({
        roadmapId,
        roadmapName
      }),
      {
        label: eventName || '...',
        to: `/roadmaps/${roadmapId}/events/${eventId}`
      },
      {
        label: 'Регионы',
        to: `/roadmaps/${roadmapId}/events/${eventId}?tab=regions`
      }
    ];
  }

  return [
    {
      label: 'Реестр региональных мероприятий',
      to: '/regionalRoadmapEvents'
    }
  ];
}

export const RegionalEventBreadCrumbs = ({
  project,
  event,
  projectId,
  roadmapId,
  federalEventId
}) => {
  const breadCrumbsLinks = useMemo(() => {
    return getRegionalEventBreadCrumbs({
      projectId,
      projectName: project?.name,
      roadmapId,
      roadmapName: event?.roadmap?.name_full,
      eventId: federalEventId,
      eventName: event?.name_full
    });
  }, [projectId, event, roadmapId, federalEventId, project]);

  return <BreadCrumbs links={breadCrumbsLinks} />;
};
