import React, { useMemo } from 'react';
import Table from '../../common/Table/Table';
import { useRoadmaps } from '../../store/roadmap/useRoadmaps';
import { queryLoader } from '../../common/QueryLoader';
import { compareSimple, makeComparator } from '../../utils/sorting';
import { createPreservedUrl } from '../../utils/links';

const roadmapsColumns = [
  { label: '№', key: 'order', isThin: true },
  { label: 'Код', key: 'code', isThin: true },
  { label: 'Название ДК', key: 'name_full', isBold: true, isWide: true }
];

export const RoadmapsTable = ({
  filter = {},
  filterComponent,
  projectId,
  year
}) => {
  const roadmapsQuery = useRoadmaps(filter);
  const roadmapsData = roadmapsQuery.state.data;

  const roadmaps = useMemo(() => {
    if (!roadmapsData) return [];
    return roadmapsData.data
      .slice()
      .sort(makeComparator(compareSimple, (r) => r.code))
      .map(({ ...rest }, index) => ({
        order: index + 1,
        ...rest
      }));
  }, [roadmapsData]);

  const rowRedirectRoute = projectId
    ? `/projects/${projectId}/roadmaps/`
    : `/roadmaps/`;

  const rowRedirectLink = createPreservedUrl(rowRedirectRoute, { year });

  return (
    queryLoader(roadmapsQuery) || (
      <Table
        columns={roadmapsColumns}
        data={roadmaps}
        rowRedirectRoute={rowRedirectLink}
        filter={filterComponent}
        renderNoData={() => <h3>Нет закупок по выбранным фильтрам</h3>}
      />
    )
  );
};
