import axios from 'axios';
import { apiUrl } from '../config';
import { showGraphqlErrorToast } from '../graphql/graphqlErrors';

/**
 * Функция для загрузки файла, через post запрос, через blob тип
 * @param {String} fileName
 * @param {String | undefined} fileExtension
 * @param {String | undefined} accessToken
 * @param {String} responseUrl
 * @param {Object} params
 * @param {any} filters
 * @param {String | undefined} method
 * @returns {Promise<void>}
 */
export async function downloadRestFile({
  fileName,
  fileExtension,
  accessToken,
  responseUrl,
  params = {},
  filters,
  method
}) {
  try {
    const cancelToken = axios.CancelToken.source();
    const config = {
      method: method || 'post',
      url: apiUrl + responseUrl,
      cancelToken: cancelToken.token,
      responseType: 'blob',
      data: filters,
      params: { ...params }
    };
    if (accessToken) {
      config.params.token = accessToken;
    }
    const response = await axios.request(config);

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank reefer';
    if (!fileName) {
      const urlFileName = decodeURIComponent(
        response.headers['content-disposition']
      ).split('filename=')[1];
      link.setAttribute('download', urlFileName);
    } else {
      link.setAttribute(
        'download',
        `${fileName}${fileExtension ? '.' + fileExtension : ''}`
      );
    }
    console.log(link);
    link.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    showGraphqlErrorToast(error);
  }
}
