import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../../../common/useRetriableGraphqlVars';
import { QUERY_INFRALIST_CHANGE_LOG } from '../../../../graphql/queries/infralist';

const infralistChangeLogQueryOptions = {
  query: QUERY_INFRALIST_CHANGE_LOG,
  mapData: (result) => {
    return {
      id: result.infralistChangeLog.id,
      actual: {
        ...result.infralistChangeLog.rvpo_equipment,
        subsidiary_entity_data:
          result.infralistChangeLog.infralist.subsidiary_entity
      },
      version: result.infralistChangeLog.rvpo_equipment_version_data
    };
  },
  skipQuery: skipIfNull(null)
};

export const useChangeLog = (changeLogId) => {
  const query = useRetriableGraphql({
    queryOptions: infralistChangeLogQueryOptions,
    variables: { id: changeLogId }
  });

  return {
    changeLog: {
      id: query.state.data?.id,
      actual: query.state.data?.actual,
      version: query.state.data?.version
    },
    isLoadingChangeLog: query.state.loading
  };
};
