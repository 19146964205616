import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

export const IndicatorCheckboxItem = ({
  currentId,
  selected,
  currentStatus,
  setSelected,
  value
}) => {
  return (
    <Checkbox
      checked={value}
      onChange={(e) => {
        if (value) {
          setSelected(
            selected.filter((indicator) => indicator.id !== currentId)
          );
        } else {
          setSelected([
            ...selected,
            {
              id: currentId,
              status: currentStatus
            }
          ]);
        }
      }}
      color="primary"
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  );
};
