import gql from 'graphql-tag';

export const MUTATION_CREATE_NON_COMMERCIAL_ORGANIZATION = gql`
  mutation createNonCommercialOrganization(
    $input: NonCommercialOrganizationInput!
  ) {
    result: createNonCommercialOrganization(input: $input) {
      id
      name_short
      region_id
    }
  }
`;
