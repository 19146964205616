export const getFullLabel = (item) =>
  `${(item.code + '').trim()}. ${item.name_full}`;

export const makeOptionator = (labelFn, valueFn = (x) => x) => (value) => ({
  value: valueFn(value),
  label: labelFn(value)
});

export function getName(entity) {
  return (
    (entity &&
      (entity.name_short ||
        entity.name_full ||
        entity.name ||
        entity.description)) ||
    null
  );
}

export function getId(entity) {
  return entity?.id || null;
}

export function joinNonEmpty(array, glue) {
  return array.filter((x) => x).join(glue);
}
