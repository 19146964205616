import gql from 'graphql-tag';

export const MUTATION_UPDATE_OR_CREATE_PARENTAL_ASSISTANCE_GUIDELINES_REGIONAL = gql`
  mutation updateOrCreateParentalAssistanceGuidelinesRegional(
    $input: ParentalAssistanceGuidelinesRegionalInput!
  ) {
    result: updateOrCreateParentalAssistanceGuidelinesRegional(input: $input) {
      id
    }
  }
`;
