import React, { useMemo } from 'react';
import { Prompt } from 'react-router-dom';
import { FastField, Form } from 'formik';
import { Notifications } from '../../../../store/notification/Notification';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { FormHeader } from '../../../../common/Form/Form';
import InputGroup from '../../../../common/Inputs/InputGroup';
import { TextInput } from '../../../../common/Inputs/TextInput';
import { NewAutocompleteInput } from '../../../../common/InputsNew/NewAutocompleteInput';
import { User } from '../../../../store/role/User';
import { Form as FormStore } from '../../../../store/form/Form';
import { FormSelectInput } from '../../../fillForms/form/FormSelectInput';

export function NotificationEditForm({
  onBackClick,
  status,
  isSubmitting,
  editor,
  touched,
  errors,
  values
}) {
  const variables = useMemo(
    () => ({
      role: values.role || undefined
    }),
    [values.role]
  );

  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <Form noValidate>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting}
          editor={editor}
          isSubmitting={isSubmitting}
          saveButtonName={'Отправить'}
        />
        <InputGroup title="Общие сведения">
          <FastField
            name="subject"
            label="Тема"
            component={TextInput}
            errorMessage={touched.subject && errors.subject}
            fullWidth
          />
          <NewAutocompleteInput
            name="section"
            label="Раздел"
            suggestions={Notifications.sections.getOptions()}
            fullWidth
            isClearable={false}
            hasCopyBtn={false}
          />
        </InputGroup>
        <InputGroup title="Получатель">
          <NewAutocompleteInput
            name="role"
            label="Базовая роль"
            suggestions={User.role.getOptions()}
            fullWidth
            hasCopyBtn={false}
          />
          <FormSelectInput
            name="recipients"
            label="Кому"
            isMulti
            entity={FormStore.EntityClass.Users}
            fullWidth
            variables={variables}
            isClearable={false}
            hasCopyBtn={false}
          />
        </InputGroup>
        <InputGroup title="Текст сообщения">
          <FastField
            name="content"
            label="Текст уведомления"
            component={TextInput}
            multiline
            errorMessage={values.subject && errors.subject}
            fullWidth
          />
        </InputGroup>
      </Form>
    </>
  );
}
