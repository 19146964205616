import React from 'react';
import { format } from 'date-fns';
import { FastField, FieldArray } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SubTable from '../../../common/SubTable/SubTable';
import { EMDASH } from '../../../utils/constants';
import InputGroup from '../../../common/Inputs/InputGroup';
import TableInput from '../../../common/Table/TableInput';
import { parseApiDate, removeTimezoneFromDate } from '../../../utils/data/date';
import { DATE_FORMAT_RUSSIAN } from '../../../utils/date';
import { tryNumberLike } from '../../../utils/helpers';
import { SubsidiaryMonitoringIndicatorPreview } from './SubsidiaryMonitoringIndicator/SubsidiaryMonitoringIndicatorPreview';
import { SubsidiaryMonitoringInfoItemPreview } from './SubsidiaryMonitoringInfo/SubsidiaryMonitoringInfoItemPreview';
import { SubsidiaryMonitoringOrganizationPreview } from './SubsidiaryMonitoringOrganization/SubsidiaryMonitoringOrganizationPreview';

const trainingsColumns = [
  {
    field: 'index',
    title: '№',
    width: '5%',
    render: ({ tableData }) => {
      return tryNumberLike(tableData.id + 1, EMDASH);
    }
  },
  {
    field: 'name',
    title: 'Название программы повышения квалификации, количество часов'
  },
  {
    field: 'date',
    title: 'Дата выдачи удостоверения о повышении квалификации',
    type: 'date',
    render: ({ date }) => {
      const d = removeTimezoneFromDate(parseApiDate(date));
      return d && format(d, DATE_FORMAT_RUSSIAN);
    }
  },
  // {
  //   field: 'hours',
  //   title: 'Количество часов'
  // },
  {
    field: 'organization_name',
    title: 'Название организации, реализующей программу'
  },
  {
    field: 'amount',
    title: 'Численность слушателей программы из ОО'
  },
  {
    field: 'note',
    title: 'Примечание'
  }
];

export const SubsidiaryMonitoringFormPreview = ({ entity }) => {
  const indicators = entity.indicators;
  const addFields = entity.additional_fields;
  const hasIndicators = !!indicators && indicators.length > 0;
  const hasAddFields = !!addFields && addFields.length > 0;

  return (
    <>
      <SubsidiaryMonitoringOrganizationPreview entity={entity} />

      {hasAddFields && (
        <>
          <SubTable title={'Дополнительная информация:'} cols={1} />
          {addFields?.map((field, iField) => (
            <SubsidiaryMonitoringInfoItemPreview key={iField} field={field} />
          ))}
        </>
      )}

      {entity?.subsidiary_entity?.owner_roadmap
        ?.participating_in_staff_training && (
        <InputGroup>
          <FastField
            name="trainings"
            tableName="Данные о повышении квалификации сотрудников"
            columns={trainingsColumns}
            component={TableInput}
            canAdd={false}
            canUpdate={false}
            canDelete={false}
          />
        </InputGroup>
      )}

      {hasIndicators && (
        <>
          <SubTable title={'Данные по индикаторам:'} cols={1} />
          <FieldArray name={'indicators'}>
            {() =>
              indicators?.map((ind, iInd) => (
                <SubsidiaryMonitoringIndicatorPreview
                  key={iInd}
                  index={iInd}
                  indicator={ind}
                />
              ))
            }
          </FieldArray>
        </>
      )}

      <SubTable>
        <TableRow>
          <TableCell>Аналитическая информация</TableCell>
          <TableCell>{entity.analytical_information || EMDASH}</TableCell>
        </TableRow>
      </SubTable>
    </>
  );
};
