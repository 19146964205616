import React from 'react';
import { Organization } from '../../store/organization/Organization';
import s from './NameCell.module.scss';

export const SubsidiaryEntityNameCell = ({ entity }) => {
  if (!entity) {
    return null;
  }
  return (
    <>
      <span>{entity.name_full}</span>
      <span className={s.organizationName}>
        {Organization.getCompleteName(entity.base_organization) ||
          Organization.getCompleteName({
            inn: entity.inn,
            kpp: entity.kpp,
            name_short: entity.base_organization_name
          }) ||
          entity.base_organization_name}
      </span>
    </>
  );
};
