import { skipIfNull } from '@proscom/prostore';
import {
  useRetriableGraphql,
  useRetriableGraphqlVars
} from '../../common/useRetriableGraphqlVars';
import {
  QUERY_SUBSIDIARY_ENTITIES,
  QUERY_SUBSIDIARY_ENTITIES_AVAILABLE_FOR_CONTEST_DOCS_MONITORING,
  QUERY_SUBSIDIARY_ENTITIES_FOR_FILTER,
  QUERY_SUBSIDIARY_ENTITIES_FOR_MONITORING,
  QUERY_UNFILLED_CLASSES_SCHEDULE_SUBSIDIARY_ENTITIES
} from '../../graphql/queries/subsidiaryEntities';

const subsidiaryEntitiesQueryOptions = {
  query: QUERY_SUBSIDIARY_ENTITIES,
  mapData: (result) => {
    const data = result.subsidiaryEntitiesPage;

    const list = data.list.map((x) => {
      const federalResult = x.owner_roadmap.roadmap_years
        .filter((y) => y.year === x.year_of_creation)
        .shift()?.result;
      return {
        ...x,
        owner_roadmap: {
          ...x.owner_roadmap,
          result: federalResult
        }
      };
    });

    return { ...data, list };
  },
  skipQuery: skipIfNull(null)
};

const subsidiaryEntitiesForFilterQueryOptions = {
  query: QUERY_SUBSIDIARY_ENTITIES_FOR_FILTER,
  mapData: (result) => result.subsidiaryEntitiesPage,
  skipQuery: skipIfNull(null)
};

const unfilledClassesScheduleSubsidiaryEntitiesQueryOptions = {
  query: QUERY_UNFILLED_CLASSES_SCHEDULE_SUBSIDIARY_ENTITIES,
  mapData: (result) => result.unfilledClassesScheduleSubsidiaryEntities,
  skipQuery: skipIfNull(null)
};

const subsidiaryEntitiesForMonitoringQueryOptions = {
  query: QUERY_SUBSIDIARY_ENTITIES_FOR_MONITORING,
  mapData: (result) => result.subsidiaryEntitiesForSubsidiaryEntityMonitoring,
  skipQuery: skipIfNull(null)
};

const subsidiaryEntitiesAvailableForContestDocsMonitoringQueryOptions = {
  query: QUERY_SUBSIDIARY_ENTITIES_AVAILABLE_FOR_CONTEST_DOCS_MONITORING,
  mapData: (result) =>
    result.subsidiaryEntitiesAvailableForTenderDocumentationForSS,
  skipQuery: skipIfNull(null)
};

export function useSubsidiaryEntities(filter, paginationParams) {
  return useRetriableGraphqlVars({
    queryOptions: subsidiaryEntitiesQueryOptions,
    variableCreator: [
      () =>
        filter
          ? {
              input: {
                filter: filter,
                pagination: paginationParams
              }
            }
          : null,
      [filter, paginationParams]
    ]
  });
}

export function useSubsidiaryEntitiesForFilter(filter) {
  return useRetriableGraphql({
    queryOptions: subsidiaryEntitiesForFilterQueryOptions,
    variables: filter
      ? {
          input: {
            filter
          }
        }
      : null
  });
}

export function useUnfilledClassesScheduleSubsidiaryEntities(filter) {
  return useRetriableGraphql({
    queryOptions: unfilledClassesScheduleSubsidiaryEntitiesQueryOptions,
    variables: filter
      ? {
          filter
        }
      : null
  });
}

export function useSubsidiaryEntitiesForMonitoring(input) {
  return useRetriableGraphql({
    queryOptions: subsidiaryEntitiesForMonitoringQueryOptions,
    variables: input ? { input } : null
  });
}

export function useSubsidiaryEntitiesAvailableForContestDocsMonitoring(input) {
  return useRetriableGraphql({
    queryOptions: subsidiaryEntitiesAvailableForContestDocsMonitoringQueryOptions,
    variables: input ? { input } : null
  });
}
