import React, { useMemo } from 'react';
import { useContextStore } from '@proscom/prostore-react';
import { STORE_ORGANIZATIONS } from '../../../../store/names';
import AutocompleteBaseInput from '../../../../common/Inputs/Selects/AutocompleteBaseInput';
import AutocompleteAsyncInput from '../../../../common/Inputs/Selects/AutocompleteAsyncInput';

const mapSuggestion = (s) => ({
  label: s.name_full,
  value: s.id
});

const CustomerIdType = {
  Organization: 'Organization',
  Grbs: 'Grbs'
};

export function CustomerIdInput({
  field: { value: inputValue, name, onBlur },
  form: { setFieldValue: handleChange },
  errorMessage,
  regionId,
  customer,
  customer_grbs,
  customer_id_type,
  grbses,
  grbsesLoading
}) {
  const organizationsStore = useContextStore(STORE_ORGANIZATIONS);

  const organizationFilters = useMemo(
    () =>
      regionId
        ? {
            region_ids: regionId
          }
        : null,
    [regionId]
  );

  const customerGrbsSuggestion = useMemo(
    () => customer_grbs && mapSuggestion(customer_grbs),
    [customer_grbs]
  );

  return (
    <>
      <AutocompleteBaseInput
        defaultValue={customerGrbsSuggestion}
        errorMessage={errorMessage}
        fullWidth
        suggestions={grbses}
        onBlur={onBlur}
        onChange={(item) => {
          handleChange(name, item);
          handleChange('customer_id_type', CustomerIdType.Grbs);
        }}
        label="Выбор из списка ГРБС"
        name="customer_id"
        value={customer_id_type === CustomerIdType.Grbs ? inputValue : ''}
        isDisabled={inputValue && customer_id_type !== CustomerIdType.Grbs}
      />
      <AutocompleteAsyncInput
        defaultValue={customer}
        errorMessage={errorMessage}
        fullWidth
        onBlur={onBlur}
        onChange={(item) => {
          handleChange(name, item);
          handleChange('customer_id_type', CustomerIdType.Organization);
        }}
        isAsync={true}
        getItems={organizationsStore.searchOrganizations}
        filterParams={organizationFilters}
        label="Выбор из списка организаций"
        mapData={organizationsStore.getSuggestions}
        name="customer_id"
        value={
          customer_id_type === CustomerIdType.Organization ? inputValue : ''
        }
        isDisabled={
          inputValue && customer_id_type !== CustomerIdType.Organization
        }
        isLoading={!organizationFilters}
      />
    </>
  );
}
