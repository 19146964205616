import { isNil } from 'lodash-es';

export function applyDefaults(object, defaults) {
  const result = { ...object };
  // eslint-disable-next-line
  for (const [key, defaultValue] of Object.entries(defaults)) {
    const value = object[key];
    if (isNil(value)) {
      result[key] = defaultValue;
    }
  }
  return result;
}
