import { Button } from '@material-ui/core';
import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { FormikTextField } from '../../../../../common/infralist/FormikFormFields/FormikTextField/FormikTextField';
import { Popup } from '../../../../../common/Popup/Popup';
import s from './CommentPopup.module.scss';

export const CommentPopup = ({
  open,
  title,
  description,
  onCancel,
  onSubmit,
  placeholder,
  useValidation
}) => {
  const validationSchema = Yup.object().shape({
    content: useValidation
      ? Yup.string().required('Обязательно для заполнения')
      : Yup.string().notRequired()
  });

  return (
    <Popup
      open={open}
      description={description}
      title={title}
      onClose={onCancel}
    >
      <Formik
        initialValues={{ content: '' }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <FormikTextField
            name={'content'}
            placeholder={placeholder}
            multiline
          />
          <div className={s.Actions}>
            <Button
              color={'secondary'}
              variant={'contained'}
              type={'submit'}
              className={s.Button}
            >
              Сохранить
            </Button>
            <Button color={'default'} onClick={onCancel} className={s.Button}>
              Отменить
            </Button>
          </div>
        </Form>
      </Formik>
    </Popup>
  );
};
