import React, { useCallback, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useContextStore } from '@proscom/prostore-react';
import { useRegionalProject } from '../../../../../store/regionalProject/useRegionalProject';
import PageTitle from '../../../../../common/PageTitle/PageTitle';
import { PageActionsBar } from '../../../../../common/PageActionsBar/PageActionsBar';
import { EditButton } from '../../../../../common/Form/EditButton/EditButton';
import SubTable from '../../../../../common/SubTable/SubTable';
import { DateCell } from '../../../../../common/TableCells';
import { useSubsidiaryEntityRoadmapIndicator } from '../../../../../store/subsidiaryEntity/useSubsidiaryEntityRoadmapIndicator';
import { queryLoaderWithExistenceCheck } from '../../../../../common/QueryLoader';
import {
  Indicator as IndicatorsData,
  Indicator
} from '../../../../../store/indicator/Indicator';
import { formatNumber } from '../../../../../utils/formatNumber';
import { useCurrentUser } from '../../../../../store/useCurrentUser';
import { User } from '../../../../../store/role/User';
import { ActionButton } from '../../../../../common/Form/ActionButton';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../../graphql/graphqlErrors';
import { INDICATOR_STORE } from '../../../../../store/names';
import { DeleteButtonWithConfirmation } from '../../../../../common/Form/DeleteButtonWithConfirmation';
import { SubsidiaryEntityOrganizationInfo } from '../../../../subsidiaryEntities/SubsidiaryEntityOrganizationInfo';
import { getName } from '../../../../../utils/data/label';
import { HeadTitle } from '../../../../../common/HeadTitle/HeadTitle';
import { IndicatorBreadCrumbs } from './IndicatorBreadCrumbs';

export default function IndicatorPage({ match, history }) {
  const indicatorStore = useContextStore(INDICATOR_STORE);

  const { projectId, roadmapId, indicatorId } = match.params;

  const projectQuery = useRegionalProject(projectId);
  const project = projectQuery.state.data;

  const indicatorQuery = useSubsidiaryEntityRoadmapIndicator(indicatorId, true);
  const indicator = indicatorQuery.state.data;

  const user = useCurrentUser();
  const canEditIndicator = Indicator.canEditIndicator(user, indicator);
  const canSendForApproval = Indicator.canSendForApproval(user, indicator);
  const canDeleteIndicator = Indicator.canDeleteIndicator(user, indicator);

  const roadmapIndicator = indicator?.owner_roadmap_indicator;

  const [isDeleting, setDeleting] = useState(false);
  const [redirectAfterDeletionUrl] = match.url.split('/indicators');

  const deleteIndicator = useCallback(() => {
    setDeleting(true);
    indicatorStore
      .deleteIndicator(indicatorId)
      .then((result) => {
        setTimeout(() => {
          history.push(redirectAfterDeletionUrl + '?tab=indicators');
        }, 0);
      })
      .catch((error) => {
        showGraphqlErrorToast(error, mutationErrors.deleteObject);
      })
      .finally(() => setDeleting(false));
  }, [indicatorId, history, redirectAfterDeletionUrl, indicatorStore]);

  return (
    <>
      <HeadTitle type="Индикатор" entity={getName(indicator)} />
      <IndicatorBreadCrumbs
        projectId={projectId}
        project={project}
        roadmapId={roadmapId}
      />
      {queryLoaderWithExistenceCheck(indicatorQuery) || (
        <>
          <PageTitle title={getName(indicator)} withBackBtn />
          <PageActionsBar
            editButton={
              canEditIndicator && <EditButton editLink={`${match.url}/edit`} />
            }
            deleteButton={
              canDeleteIndicator && (
                <DeleteButtonWithConfirmation
                  onDelete={deleteIndicator}
                  isDeleting={isDeleting}
                  entityName={'индикатор'}
                />
              )
            }
            actions={
              canSendForApproval && (
                <ActionButton
                  onClick={() =>
                    indicatorStore
                      .sendForApproval(indicator.id)
                      .catch((error) => {
                        showGraphqlErrorToast(
                          error,
                          mutationErrors.updateObject
                        );
                      })
                  }
                  title="Отправить на утверждение"
                />
              )
            }
          />
          <SubTable title="Общие сведения">
            <TableRow>
              <TableCell>Регион</TableCell>
              <TableCell>{indicator.region?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Год</TableCell>
              <TableCell>{indicator.year}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Месяц</TableCell>
              <TableCell>{indicator.month}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Субсидиарная сущность</TableCell>
              <TableCell>{indicator.subsidiary_entity?.name_full}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Организация</TableCell>
              <TableCell>
                <SubsidiaryEntityOrganizationInfo
                  organization={indicator.subsidiary_entity?.base_organization}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Статус</TableCell>
              <TableCell>{Indicator.getStatus(indicator.status)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Комментарий ФПО</TableCell>
              <TableCell>{indicator.comment_fpo}</TableCell>
            </TableRow>
          </SubTable>
          <SubTable title="Данные индикатора ДК">
            <TableRow>
              <TableCell>Единица измерения</TableCell>
              <TableCell>
                {indicator.owner_roadmap_indicator?.unit?.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Минимальное значение в год</TableCell>
              <TableCell>
                {IndicatorsData.getMinimumText(roadmapIndicator)}
              </TableCell>
            </TableRow>
            {indicator.owner_roadmap_indicator?.plan_roadmap_indicator
              ?.explanation && (
              <TableRow>
                <TableCell>Пояснение к минимальному значению в год</TableCell>
                <TableCell>
                  {
                    indicator.owner_roadmap_indicator?.plan_roadmap_indicator
                      ?.explanation
                  }
                </TableCell>
              </TableRow>
            )}
          </SubTable>
          <SubTable title="Данные индикатора ДК по субсидиарной сущности">
            <TableRow>
              <TableCell>Значение из конкурсной документации</TableCell>
              <TableCell>
                {formatNumber(indicator.value_from_tender_documentation)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Значение факт</TableCell>
              <TableCell>{formatNumber(indicator.value_fact)}</TableCell>
            </TableRow>
          </SubTable>
          <SubTable>
            <TableRow>
              <TableCell>Автор</TableCell>
              <TableCell>{User.getUserName(indicator.author_user)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Дата создания</TableCell>
              <DateCell value={indicator.created_at} />
            </TableRow>
            <TableRow>
              <TableCell>Дата редактирования</TableCell>
              <DateCell value={indicator.updated_at} />
            </TableRow>
            <TableRow>
              <TableCell>Версия</TableCell>
              <TableCell>{indicator.version}</TableCell>
            </TableRow>
          </SubTable>
        </>
      )}
    </>
  );
}
