import React, { useCallback, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useContextStore } from '@proscom/prostore-react';
import { AdditionalData } from '../../../../../store/additionalData/AdditionalData';
import { EditButton } from '../../../../../common/Form/EditButton/EditButton';
import { DeleteButtonWithConfirmation } from '../../../../../common/Form/DeleteButtonWithConfirmation';
import { ActionButton } from '../../../../../common/Form/ActionButton';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../../graphql/graphqlErrors';
import { PageActionsBar } from '../../../../../common/PageActionsBar/PageActionsBar';
import { reformatDate } from '../../../../../utils/date';
import { queryLoaderWithExistenceCheck } from '../../../../../common/QueryLoader';
import { useClassesSchedule } from '../../../../../store/additionalData/useClassesSchedule';
import SubTable from '../../../../../common/SubTable/SubTable';
import PageTitle from '../../../../../common/PageTitle/PageTitle';
import { ADDITIONAL_DATA_STORE } from '../../../../../store/names';
import { useCurrentUser } from '../../../../../store/useCurrentUser';
import { HeadTitle } from '../../../../../common/HeadTitle/HeadTitle';
import { SubsidiaryEntity } from '../../../../../store/subsidiaryEntity/SubsidiaryEntity';
import { EMDASH } from '../../../../../utils/constants';
import { AdditionalDataBreadCrumbs } from './AdditionalDataBreadCrumbs';

export default function AdditionalDataPage({ match, history }) {
  const additionalDataStore = useContextStore(ADDITIONAL_DATA_STORE);
  const { projectId, roadmapId, additionalDataId } = match.params;

  const classesScheduleQuery = useClassesSchedule(additionalDataId);
  const classesSchedule = classesScheduleQuery.state.data;

  const [isDeleting, setDeleting] = useState(false);
  const [redirectAfterDeletionUrl] = match.url.split('/additionalData');
  const deleteClassesSchedules = useCallback(() => {
    setDeleting(true);
    additionalDataStore
      .deleteClassesSchedule(additionalDataId)
      .then((result) => {
        setTimeout(() => {
          history.push(redirectAfterDeletionUrl + '?tab=additionalData');
        }, 0);
      })
      .catch((error) => {
        showGraphqlErrorToast(error, mutationErrors.deleteObject);
      })
      .finally(() => setDeleting(false));
  }, [
    additionalDataId,
    history,
    redirectAfterDeletionUrl,
    additionalDataStore
  ]);

  const user = useCurrentUser();
  const canEditClassesSchedule = AdditionalData.canEditClassesSchedule(
    user,
    classesSchedule
  );
  const canDeleteClassesSchedule = AdditionalData.canDeleteClassesSchedule(
    user,
    classesSchedule
  );
  const canSendForApproval = AdditionalData.canSendForApproval(
    user,
    classesSchedule
  );

  const entityName = SubsidiaryEntity.getCompleteName(
    classesSchedule?.subsidiary_entity
  );

  return (
    <>
      <HeadTitle entity={entityName} type="График начала занятий" />
      <AdditionalDataBreadCrumbs
        project={classesSchedule?.subsidiary_entity?.regional_project}
        projectId={projectId}
        roadmapId={roadmapId}
        roadmap={classesSchedule?.subsidiary_entity?.owner_roadmap}
      />
      {queryLoaderWithExistenceCheck(classesScheduleQuery) || (
        <>
          <PageTitle
            title={`График начала занятий ${EMDASH} ${entityName}`}
            withBackBtn
          />
          <PageActionsBar
            editButton={
              canEditClassesSchedule && (
                <EditButton editLink={`${match.url}/edit`} />
              )
            }
            deleteButton={
              canDeleteClassesSchedule && (
                <DeleteButtonWithConfirmation
                  onDelete={deleteClassesSchedules}
                  isDeleting={isDeleting}
                  entityName={'график начала занятий'}
                />
              )
            }
            actions={
              canSendForApproval && (
                <ActionButton
                  onClick={() =>
                    additionalDataStore
                      .sendForApproval(classesSchedule)
                      .catch((error) => {
                        showGraphqlErrorToast(
                          error,
                          mutationErrors.updateObject
                        );
                      })
                  }
                  title="Отправить на утверждение"
                />
              )
            }
          />
          <SubTable title="Общие сведения">
            <TableRow>
              <TableCell>Субсидиарная сущность</TableCell>
              <TableCell>
                {SubsidiaryEntity.getCompleteName(
                  classesSchedule.subsidiary_entity
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Статус</TableCell>
              <TableCell>
                {AdditionalData.status.getName(classesSchedule.status)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Комментарий ФПО</TableCell>
              <TableCell>{classesSchedule.comment_fpo}</TableCell>
            </TableRow>
          </SubTable>
          <SubTable>
            <TableRow>
              <TableCell>Предварительная дата</TableCell>
              <TableCell>01.09.{classesSchedule.year}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Фактическая дата</TableCell>
              <TableCell>{reformatDate(classesSchedule.fact_date)}</TableCell>
            </TableRow>
          </SubTable>
          <SubTable>
            <TableRow>
              <TableCell>Версия</TableCell>
              <TableCell>{classesSchedule.version}</TableCell>
            </TableRow>
          </SubTable>
        </>
      )}
    </>
  );
}
