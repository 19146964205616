import React, { useMemo } from 'react';
import { makeOptionator } from './data/label';

export class EnumHelpers {
  constructor(values, names = {}, colors = {}) {
    this.values = values;
    this.names = names;
    this.colors = colors;
  }

  getExisting = (value) => {
    return Object.values(this.values).find((v) => v === value);
  };

  getName = (value) => {
    return this.names[value] || value;
  };

  getFromName = (name) => {
    for (const key of Object.keys(this.names)) {
      if (this.names[key] === name) {
        return key;
      }
    }
    return null;
  };

  getColor = (value) => {
    return this.colors[value];
  };

  getOptions = (valuesArray = Object.keys(this.values)) => {
    return valuesArray.map(makeOptionator(this.getName));
  };

  useOptions(arrayValues) {
    return useMemo(() => this.getOptions(arrayValues), [arrayValues]);
  }
}

export function EnumStatus(helper) {
  return function PofStatus({ status }) {
    return (
      <span
        style={{
          color: helper.getColor(status)
        }}
      >
        {helper.getName(status)}
      </span>
    );
  };
}
