import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../../common/useRetriableGraphqlVars';
import { QUERY_INFRALIST_VERSION_SECTION } from '../../../graphql/queries/infralist';
import { infralistStatusService } from '../infralistStatusService';

const infralistDirectionsQueryOptions = {
  query: QUERY_INFRALIST_VERSION_SECTION,
  mapData: (result) => {
    return {
      ...result.infralistSection,
      statusByVersion: result.infralistSection?.status_by_version ?? {
        name: infralistStatusService.defaultStatus
      }
    };
  },
  skipQuery: skipIfNull(null)
};

export function useVersionSection({ sectionId, versionId }) {
  return useRetriableGraphql({
    queryOptions: infralistDirectionsQueryOptions,
    variables: {
      section_id: sectionId,
      version_id: versionId
    }
  });
}
