import React from 'react';
import { FastField, FieldArray } from 'formik';
import InputGroup from '../../../common/Inputs/InputGroup';
import { TextInput } from '../../../common/Inputs/TextInput';
import { ContestDocsRoadmapEditable } from './ContestDocsRoadmap/ContestDocsRoadmapEditable';

export const ContestDocsMonitoringFormEditable = ({
  entity,
  values,
  touched,
  errors
}) => {
  const roadmaps = entity.roadmaps;
  const hasRoadmaps = !!roadmaps && roadmaps.length > 0;

  return (
    <>
      <FieldArray name={'roadmaps'}>
        {() => {
          return hasRoadmaps
            ? entity.roadmaps.map((rm, iRm) => (
                <ContestDocsRoadmapEditable
                  key={iRm}
                  index={iRm}
                  roadmap={rm}
                  values={values}
                  touched={touched}
                  errors={errors}
                />
              ))
            : null;
        }}
      </FieldArray>

      <InputGroup>
        <FastField
          name="analytical_information"
          label="Аналитическая информация"
          fullWidth
          component={TextInput}
          multiline
          errorMessage={
            touched.analytical_information && errors.analytical_information
          }
        />
      </InputGroup>
    </>
  );
};
