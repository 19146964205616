import React from 'react';
import { NewAutocompleteAsyncField } from '../Inputs/Selects/NewAutocompleteAsyncField';
import { useFieldFast } from './useFieldFast';

export function NewAutocompleteAsyncInput(props) {
  const [field, meta, helpers] = useFieldFast(props);

  return (
    <NewAutocompleteAsyncField
      {...field}
      onChange={helpers.setValue}
      errorMessage={meta.touched && meta.error}
      {...props}
    />
  );
}
