import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { EQUIPMENT_STATUSES } from '../../store/infralist/equipmentStatusService';
import { useDirection } from '../../store/infralist/useDirection';
import { useEquipmentMutations } from '../../store/infralist/useEquipmentMutations';
import {
  transformEquipmentNameListToMap,
  useEquipmentNameList
} from '../../store/infralist/useEquipmentNameList';
import { useInfralist } from '../../store/infralist/useInfralist';
import { useSection } from '../../store/infralist/useSection';
import { removeEmptyRowsFromString } from '../../utils/data/string';

export const useCreateEquipmentPage = (infralistId, directionId, sectionId) => {
  const directionQuery = useDirection(directionId, infralistId);
  const sectionQuery = useSection(sectionId, infralistId);
  const infralist = useInfralist(infralistId);
  const history = useHistory();
  const { createEquipment } = useEquipmentMutations();

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: infralist.state.data?.roadmap?.name
      },
      {
        label: 'Формирование и согласование перечня оборудования',
        to: `/infralist/${infralistId}`
      },
      {
        label: directionQuery.state.data?.name,
        to: `/infralist/${infralistId}/directions/${directionId}`
      },
      {
        label: sectionQuery.state.data?.name,
        to: `/infralist/${infralistId}/directions/${directionId}/sections/${sectionId}`
      }
    ];
  }, [
    sectionQuery.state.data,
    directionId,
    directionQuery.state.data,
    infralistId,
    sectionId,
    infralist
  ]);

  const backUrl = useMemo(
    () =>
      `/infralist/${infralistId}/directions/${directionId}/sections/${sectionId}`,
    [infralistId, sectionId, directionId]
  );

  const isFormationComplete =
    sectionQuery.check.spinner ||
    sectionQuery.state.data?.status?.name === EQUIPMENT_STATUSES.FORMED;

  const equipmentList = useEquipmentNameList(sectionId);
  const equipmentMap = useMemo(
    () => transformEquipmentNameListToMap(equipmentList),
    [equipmentList]
  );

  const back = () => history.push(backUrl);

  const submitCreateEquipment = (data, formikHelpers) => {
    return createEquipment({
      data: {
        ...data,
        section_id: sectionId,
        infralist_id: infralistId,
        equipment_external_url: removeEmptyRowsFromString(
          data.equipment_external_url
        ).join('\n')
      },
      sectionId,
      infralistId
    })
      .then(() => formikHelpers.resetForm())
      .then(back);
  };

  return {
    breadcrumbs,
    backUrl,
    isFormationComplete,
    equipmentList,
    equipmentMap,
    submitCreateEquipment,
    back
  };
};
