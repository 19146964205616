import React, { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { EditButton } from '../../../common/Form/EditButton/EditButton';
import { PageActionsBar } from '../../../common/PageActionsBar/PageActionsBar';
import { useParentalAssistanceGuidelineOrganizational } from '../../../store/parentalAssistanceGuideline/useParentalAssistanceGuidelineOrganizational';
import { queryLoaderWithExistenceCheck } from '../../../common/QueryLoader';
import { ParentalAssistanceGuideline } from '../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import SubTable from '../../../common/SubTable/SubTable';
import { getName } from '../../../utils/data/label';
import { ProcedureOrganizationalSection } from '../sections/ProcedureOrganizationalSection';
import { ResultSection } from '../sections/ResultSection';
import { FilesOrganizationalSection } from '../sections/FilesOrganizationalSection';
import { downloadRestFile } from '../../../utils/AxiosHelpers';
import { DownloadButton } from '../../../common/Form/DownloadButton/DownloadButton';
import s from './ParentalAssistanceGuidelinesOrganizationalPage.module.scss';

const title = 'Перечень вопросов по апробации методических рекомендаций';

export default function ParentalAssistanceGuidelinesOrganizationalPage({
  match
}) {
  const [isDownload, setIsDownload] = useState(false);
  const { guidelineId } = match.params;

  const parentalAssistanceGuidelineOrganizationalQuery = useParentalAssistanceGuidelineOrganizational(
    guidelineId
  );

  const parentalAssistanceGuidelineOrganizational =
    parentalAssistanceGuidelineOrganizationalQuery.state.data;

  const handleDownload = async ({ type }) => {
    setIsDownload(true);
    await downloadRestFile({
      filters: {
        non_commercial_organization_id:
          parentalAssistanceGuidelineOrganizational?.non_commercial_organization
            ?.id,
        extension: type
      },
      responseUrl: '/reference/parental_assistance_guideline_organizational',
      method: 'post'
    });
    setIsDownload(false);
  };
  const canEdit =
    parentalAssistanceGuidelineOrganizational?.status ===
    ParentalAssistanceGuideline.Status.DRAFT;

  return (
    <div className={s.wrapper}>
      <HeadTitle title={title} />
      <PageTitle title={title} withBackBtn />
      <PageActionsBar
        actions={
          parentalAssistanceGuidelineOrganizational?.non_commercial_organization && (
            <DownloadButton
              files={[
                {
                  name: 'Формат xls',
                  type: 'xls'
                },
                {
                  name: 'Формат pdf',
                  type: 'pdf'
                }
              ]}
              loader={isDownload}
              onClick={handleDownload}
            >
              Загрузить
            </DownloadButton>
          )
        }
        editButton={canEdit && <EditButton editLink={`${match.url}/edit`} />}
      />
      {queryLoaderWithExistenceCheck(
        parentalAssistanceGuidelineOrganizationalQuery
      ) || (
        <>
          <SubTable title="Общие сведения">
            <TableRow>
              <TableCell>Организация</TableCell>
              <TableCell>
                {getName(
                  parentalAssistanceGuidelineOrganizational.non_commercial_organization
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Статус</TableCell>
              <TableCell>
                {ParentalAssistanceGuideline.status.getName(
                  parentalAssistanceGuidelineOrganizational.status
                )}
              </TableCell>
            </TableRow>
          </SubTable>
          <FilesOrganizationalSection
            data={parentalAssistanceGuidelineOrganizational}
          />
          <ProcedureOrganizationalSection
            data={parentalAssistanceGuidelineOrganizational}
          />
          <ResultSection data={parentalAssistanceGuidelineOrganizational} />
        </>
      )}
    </div>
  );
}
