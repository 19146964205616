import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { removeLastPathItem } from '../../../../../../utils/url';
import { useOtherEditor } from '../../../../../../common/Form/useOtherEditor';
import { AdditionalData } from '../../../../../../store/additionalData/AdditionalData';
import { ADDITIONAL_DATA_STORE } from '../../../../../../store/names';
import PageTitle from '../../../../../../common/PageTitle/PageTitle';
import { queryLoaderWithExistenceCheck } from '../../../../../../common/QueryLoader';
import { useClassesSchedule } from '../../../../../../store/additionalData/useClassesSchedule';
import { AdditionalDataBreadCrumbs } from '../AdditionalDataBreadCrumbs';
import { handleFormSubmission } from '../../../../../../utils/data/form';
import { classesScheduleValidationSchema } from '../../../../../../utils/data/validation';
import { useCurrentUser } from '../../../../../../store/useCurrentUser';
import { HeadTitle } from '../../../../../../common/HeadTitle/HeadTitle';
import { AdditionalDataEditForm } from './AdditionalDataEditForm';

export default function AdditionalDataEditPage({ match, history }) {
  const additionalDataStore = useContextStore(ADDITIONAL_DATA_STORE);
  const backLink = removeLastPathItem(match.url);

  const { projectId, roadmapId, additionalDataId } = match.params;
  const editor = useOtherEditor('additionalData', additionalDataId);

  const classesScheduleQuery = useClassesSchedule(additionalDataId);
  const classesSchedule = classesScheduleQuery.state.data;

  const user = useCurrentUser();
  const validationSchema = useMemo(
    () => classesScheduleValidationSchema(classesSchedule, user),
    [classesSchedule, user]
  );

  return (
    <>
      <HeadTitle
        pageType={HeadTitle.PageType.edit}
        type="График начала занятий"
      />
      <AdditionalDataBreadCrumbs
        project={classesSchedule?.subsidiary_entity?.regional_project}
        projectId={projectId}
        roadmapId={roadmapId}
        roadmap={classesSchedule?.subsidiary_entity?.owner_roadmap}
      />
      {queryLoaderWithExistenceCheck(classesScheduleQuery) || (
        <>
          <PageTitle
            title={
              'График начала занятий - ' +
              classesSchedule.subsidiary_entity.name_full
            }
          />
          <Formik
            validationSchema={validationSchema}
            initialValues={AdditionalData.toForm(classesSchedule)}
            onSubmit={(values, actions) => {
              const additionalData = AdditionalData.fromForm(values);
              handleFormSubmission(
                additionalDataStore.updateOrCreateClassesSchedule(
                  additionalData
                ),
                actions,
                (result) =>
                  history.goBack(
                    AdditionalData.getUrl(
                      result.data.result,
                      projectId,
                      roadmapId
                    )
                  )
              );
            }}
          >
            {(formikProps) => (
              <AdditionalDataEditForm
                {...formikProps}
                onBackClick={() => history.goBack(backLink)}
                editor={editor}
                roadmapId={roadmapId}
                regionId={classesSchedule.subsidiary_entity.region.id}
                classesSchedule={classesSchedule}
              />
            )}
          </Formik>
        </>
      )}
    </>
  );
}
