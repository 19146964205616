import { useCallback, useState } from 'react';

export const SORT_ORDER_MAP = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const useTableManager = () => {
  const [editRowId, setEditRow] = useState();
  const [deleteItemId, setDeleteItemId] = useState();

  const clearEditRow = useCallback(() => setEditRow(undefined), []);

  return {
    setEditRow,
    editRowId,
    clearEditRow,
    deleteItemId,
    setDeleteItemId
  };
};
