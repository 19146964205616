import React, { useEffect, useRef } from 'react';
import MaterialLink from '@material-ui/core/Link';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import {
  EVENT_TYPE_INFRALIST,
  EVENT_TYPE_INFRALIST_SS,
  isInfralist
} from '../../../../../store/roadmap/roadmapEvents';
import InputGroup from '../../../../../common/Inputs/InputGroup';
import {
  useEventInfralists,
  useEventInfralistsStatus
} from '../../../../../store/infralists/useEventInfralists';
import { queryLoaderWithExistenceCheck } from '../../../../../common/QueryLoader';
import SubTable from '../../../../../common/SubTable/SubTable';
import { Infralist } from '../../../../../store/infralists/Infralist';
import { SubsidiaryEntity } from '../../../../../store/subsidiaryEntity/SubsidiaryEntity';
import { DeleteButton } from '../../../../../common/DeleteButton';
import s from '../EventEditPage.module.scss';

export const InfralistItem = ({ data, handleInfralistDelete }) => {
  return (
    <div className={s.infralist}>
      <MaterialLink
        className={classNames(s.InfralistInfo__link, 'external')}
        component={React.a}
        href={`/infralist/${data.id}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <SubTable>
          <TableRow>
            <TableCell>
              {data.description || 'Документ со списком перечня оборудования'}
            </TableCell>
            <TableCell>
              {Infralist.status.getName(data.infralist_status_id)}
            </TableCell>
          </TableRow>
        </SubTable>
      </MaterialLink>
      {handleInfralistDelete && (
        <DeleteButton onClick={() => handleInfralistDelete(data.id)} />
      )}
    </div>
  );
};

export const InfralistSsItem = ({ data, handleInfralistDelete }) => {
  return (
    <div className={s.infralist}>
      <MaterialLink
        className={classNames(s.InfralistInfo__link, 'external')}
        component={React.a}
        href={`/infralist/${data.id}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div>{SubsidiaryEntity.getCompleteName(data.subsidiary_entity)}</div>
        <SubTable>
          <TableRow>
            <TableCell>
              {data.description || 'Документ со списком перечня оборудования'}
            </TableCell>
            <TableCell>
              {Infralist.status.getName(data.infralist_status_id)}
            </TableCell>
          </TableRow>
        </SubTable>
      </MaterialLink>
      {handleInfralistDelete && (
        <DeleteButton onClick={() => handleInfralistDelete(data.id)} />
      )}
    </div>
  );
};

export const useInfralistsDataSubscriber = ({ eventId }) => {
  const loadRef = useRef({});
  const eventInfralistsQuery = useEventInfralists(eventId);
  const eventInfralistsStatusQuery = useEventInfralistsStatus(eventId);

  loadRef.current.loadInfralists = eventInfralistsQuery.load;
  loadRef.current.loadInfralistStatuses = eventInfralistsStatusQuery.load;

  const eventInfralistsData = eventInfralistsQuery.state.data;
  const infralistsStatus = eventInfralistsStatusQuery.state.data;

  useEffect(() => {
    if (
      eventInfralistsQuery.state.loading ||
      eventInfralistsStatusQuery.state.loading
    ) {
      return;
    }

    let shouldRefetch = false;

    const infralists = eventInfralistsData || [];
    const infralistsStatuses = infralistsStatus || [];
    if (infralists.length !== infralistsStatuses.length) {
      shouldRefetch = true;
    }
    if (!shouldRefetch) {
      for (let i = 0; i < infralists.length; i++) {
        const infralist = infralists[i];
        const status = infralistsStatuses.find((i) => i.id === infralist.id)
          ?.infralist_status_id;
        if (status && status !== infralist.infralist_status_id) {
          shouldRefetch = true;
          break;
        }
      }
    }

    if (shouldRefetch) {
      loadRef.current.loadInfralists().then(() => {
        toast.info('Инфраструктурные листы автоматически обновлены');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventInfralistsData, infralistsStatus]);

  return [eventInfralistsQuery, loadRef];
};

export const InfralistsInfo = ({ event, eventType }) => {
  const isInfralistEvent = isInfralist(eventType);

  const [eventInfralistsQuery, loadRef] = useInfralistsDataSubscriber({
    eventId: event.id
  });

  const eventInfralistsData = eventInfralistsQuery.state.data;

  useEffect(() => {
    const onFocus = () => {
      if (loadRef.current.loadInfralistStatuses) {
        loadRef.current.loadInfralistStatuses();
      }
    };
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [loadRef]);

  if (!isInfralistEvent) return null;

  return (
    <InputGroup
      title={
        eventType === EVENT_TYPE_INFRALIST
          ? 'Инфраструктурный лист'
          : 'Инфраструктурные листы'
      }
    >
      {queryLoaderWithExistenceCheck(eventInfralistsQuery) ||
        eventInfralistsData.map((infralist, iIfralist) => {
          if (eventType === EVENT_TYPE_INFRALIST_SS) {
            return <InfralistSsItem key={iIfralist} data={infralist} />;
          }
          return <InfralistItem key={iIfralist} data={infralist} />;
        })}
    </InputGroup>
  );
};
