import gql from 'graphql-tag';
import { FRAGMENT_PURCHASE } from '../queries/purchases';

export const MUTATION_UPDATE_PURCHASE = gql`
  mutation updatePurchase($input: PurchaseUpdateInput!) {
    updatePurchase(input: $input) {
      ...Purchase
    }
  }

  ${FRAGMENT_PURCHASE}
`;

export const MUTATION_CREATE_PURCHASE = gql`
  mutation createPurchase($input: PurchaseInput!) {
    createPurchase(input: $input) {
      ...Purchase
    }
  }

  ${FRAGMENT_PURCHASE}
`;

export const MUTATION_DELETE_PURCHASE = gql`
  mutation deletePurchase($id: ID!) {
    deletePurchase(id: $id)
  }
`;

export const MUTATION_CREATE_PURCHASE_CONFIRMATION = gql`
  mutation createPurchaseConfirmation(
    $input: PurchaseConfirmationInputFields!
  ) {
    createPurchaseConfirmation(input: $input) {
      confirmation_date
    }
  }
`;
