import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { Purchase } from '../../../../store/purchase/Purchase';
import { usePurchase } from '../../../../store/purchase/usePurchase';
import { useExpensesDirection } from '../../../../store/expensesDirection/useExpensesDirection';
import { useRegionalProject } from '../../../../store/regionalProject/useRegionalProject';
import { STORE_PURCHASE } from '../../../../store/names';
import { purchaseValidationSchema } from '../../../../utils/data/validation';
import { removeLastPathItem } from '../../../../utils/url';
import { useOtherEditor } from '../../../../common/Form/useOtherEditor';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { PurchaseBreadCrumbs } from '../PurchaseBreadCrumbs';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { PurchaseEditForm } from './PurchaseEditForm';

function PurchaseEditPage({ match, history }) {
  const purchaseStore = useContextStore(STORE_PURCHASE);
  const backLink = removeLastPathItem(match.url);

  const { projectId, expenseGroupId, id } = match.params;

  const editor = useOtherEditor('purchase', id);

  const projectQuery = useRegionalProject(projectId);
  const project = projectQuery.state.data;

  const expensesDirectionQuery = useExpensesDirection(expenseGroupId);
  const expensesDirection = expensesDirectionQuery.state.data;

  const purchaseQuery = usePurchase(id, true);
  const purchase = useMemo(() => {
    const data = purchaseQuery.state.data;
    if (!data) return {};
    return Purchase.toForm(data);
  }, [purchaseQuery.state.data]);

  return (
    <>
      <HeadTitle
        type="Закупка"
        entity={purchase?.item}
        pageType={HeadTitle.PageType.edit}
      />
      <PurchaseBreadCrumbs
        expenseGroupId={expenseGroupId}
        projectId={projectId}
        expensesDirection={expensesDirection}
        project={project}
      />
      <h2>Редактирование закупки</h2>
      {queryLoaderWithExistenceCheck(purchaseQuery) || (
        <Formik
          validationSchema={purchaseValidationSchema}
          initialValues={purchase}
          onSubmit={(values, actions) => {
            const purchase = Purchase.fromForm(values);
            purchaseStore
              .updatePurchase(purchase)
              .then((result) => {
                actions.setStatus(FORM_STATUS_SUBMITTED);
                history.goBack(
                  projectId && expenseGroupId
                    ? Purchase.getUrl(result.data.updatePurchase)
                    : Purchase.getShortUrl(result.data.updatePurchase)
                );
              })
              .then(() => {
                actions.setSubmitting(false);
              });
          }}
          render={(formikProps) => (
            <PurchaseEditForm
              {...formikProps}
              onBackClick={() => history.goBack(backLink)}
              editor={editor}
              isEditMode={true}
            />
          )}
        />
      )}
    </>
  );
}

PurchaseEditPage.propTypes = {};

export default PurchaseEditPage;
