import { clearApolloQueryCache } from '../../utils/apollo';
import {
  MUTATION_CREATE_OR_UPDATE_CLASSES_SCHEDULES,
  MUTATION_DELETE_CLASSES_SCHEDULES
} from '../../graphql/mutations/classesSchedules';
import { Indicator } from '../indicator/Indicator';
import { AdditionalData } from './AdditionalData';

export class AdditionalDataStore {
  constructor({ client }) {
    this.client = client;
  }

  queriesToClear = [
    'classesSchedulesPage',
    'classesSchedule',
    'subsidiaryEntitiesPage',
    'unfilledClassesScheduleSubsidiaryEntities'
  ];

  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  updateOrCreateClassesSchedule(data) {
    return this.client.mutate({
      mutation: MUTATION_CREATE_OR_UPDATE_CLASSES_SCHEDULES,
      variables: {
        schedules: [AdditionalData.filterUpdateFields(data)]
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  }

  deleteClassesSchedule = (data) => {
    return this.client.mutate({
      mutation: MUTATION_DELETE_CLASSES_SCHEDULES,
      variables: {
        ids: [data]
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  };

  sendForApproval({ id, year }) {
    return this.updateOrCreateClassesSchedule({
      id,
      year,
      status: AdditionalData.Status.FOR_APPROVAL
    });
  }
}
