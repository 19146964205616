import { useStore } from '@proscom/prostore-react';
import { useMemo } from 'react';
import { useUserRoleByPermissions } from '../../../utils/hooks/useUserRoleByPermissions';
import { STORE_AUTH } from '../../names';
import { User } from '../../role/User';
import { useInfralistUserAccesses } from '../useInfralistUserAccesses';

/**
 *
 * @param user
 * @param isEditEnabledByEventStatus
 * @returns {{canEdit, type}}
 */
export const useInfralistSingleCommentAccesses = (
  user,
  isEditEnabledByEventStatus
) => {
  const { isRVPO1, isRVPO2, isFPO1, isFPO2 } = useInfralistUserAccesses();
  const [auth] = useStore(STORE_AUTH);
  const loggedUser = User.getUser(auth);
  const { getRole } = useUserRoleByPermissions();
  const role = getRole(user);
  const accesses = useMemo(() => {
    const canEditOwn =
      (role?.name === 'rvpo1' || role?.name === 'fpo1') &&
      (isFPO1 || isRVPO1) &&
      user.id === loggedUser.id;
    return {
      canEdit: (canEditOwn || isRVPO2 || isFPO2) && isEditEnabledByEventStatus,
      type: role?.type
    };
  }, [
    role,
    isFPO1,
    isRVPO1,
    user.id,
    loggedUser.id,
    isEditEnabledByEventStatus,
    isRVPO2,
    isFPO2
  ]);

  return accesses;
};
