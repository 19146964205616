import { useCallback, useMemo } from 'react';
import { useHasPermission } from '../../utils/hooks/useHasPermission';
import { EQUIPMENT_STATUSES } from './equipmentStatusService';
import { INFRALIST_STATUSES } from './infralistStatusService';
import { INFRALIST_CHECK_STATUSES } from './statuses/infralistCheckStatusService';
import { useInfralistVersion } from './versions/useInfralistVersion';

export const INFRALIST_PERMISSIONS = {
  CREATE: 'infralist.create',
  SIGN: 'infralist.sign',
  APPROVE: 'infralist.approve',
  POSITION_FILLING: 'infralist.positions_filling',
  DIRECTION_CHECK: 'infralist.direction_check',
  VIEW_REGIONAL: 'infralist.view_regional',
  VIEW_FEDERAL: 'infralist.view_federal'
};

/**
 * Возвращает infralist роль по пермишенам
 * @return {{isRandomFpo: boolean, isRVPO2: boolean, isRVPO1: boolean, isFPO1: boolean, isFPO2: boolean, canFetchEquipmentCheckStatuses, canFetchEquipmentStatuses, isRandomRVPO: boolean}}
 */
export const useInfralistUserAccesses = () => {
  const { checkPermissions } = useHasPermission();

  const accesses = useMemo(() => {
    // Пользователю может быть назначена только 1 из ролей
    // роли расположены в порядке убывания
    let isFPO2 = false;
    let isFPO1 = false;
    let isRVPO2 = false;
    let isRVPO1 = false;
    let isRandomFpo = false;
    let isRandomRVPO = false;

    if (checkPermissions([INFRALIST_PERMISSIONS.APPROVE])) {
      isFPO2 = true;
    } else if (checkPermissions([INFRALIST_PERMISSIONS.DIRECTION_CHECK])) {
      isFPO1 = true;
    } else if (checkPermissions([INFRALIST_PERMISSIONS.SIGN])) {
      isRVPO2 = true;
    } else if (checkPermissions([INFRALIST_PERMISSIONS.POSITION_FILLING])) {
      isRVPO1 = true;
    } else if (checkPermissions([INFRALIST_PERMISSIONS.VIEW_FEDERAL])) {
      isRandomFpo = true;
    } else if (checkPermissions([INFRALIST_PERMISSIONS.VIEW_REGIONAL])) {
      isRandomRVPO = true;
    }

    return {
      isRVPO1,
      isRVPO2,
      isFPO1,
      isFPO2,
      isRandomFpo,
      isRandomRVPO,
      canFetchEquipmentStatuses: isRVPO2 || isFPO2,
      canFetchEquipmentCheckStatuses: isFPO2 || isFPO1,
      showNotApproveAllActions: isFPO2,
      showSigningActions: isRVPO2,
      showCheckActions: isFPO1
    };
  }, [checkPermissions]);

  return accesses;
};

/**
 * Доступы к интерфейсу раздела инфралиста на основе роли и статусов
 * @param {string} sectionStatus
 * @param {string} infralistSectionCheckStatus
 * @param {string} infralistStatus
 * @param {string} infralistSectionApproveStatus
 * @param isEditEnabledByEventStatus
 * @return {{showSignActions: boolean, canEdit, showCommentLinkAlways, showCheckActions: boolean, canSign, canForm, showFormActions, canCheck, showCheckStatusInHeader, showApproveActions, canEditApprovedEquipments, canEditSignedEquipments}}
 */
export const useInfralistSectionUserAccesses = ({
  sectionStatus,
  infralistSectionCheckStatus,
  infralistStatus,
  infralistSectionApproveStatus,
  isEditEnabledByEventStatus
}) => {
  const {
    isRVPO1,
    isRVPO2,
    isFPO1,
    isFPO2,
    isRandomRVPO,
    isRandomFpo
  } = useInfralistUserAccesses();
  const { isVersionMode } = useInfralistVersion();

  const accesses = useMemo(() => {
    // когда раздел "не сформирован" или "Не подписан"
    const isRVPO1canEdit =
      sectionStatus === INFRALIST_STATUSES.NOT_FORMED ||
      (sectionStatus === INFRALIST_STATUSES.NOT_SIGNED &&
        [INFRALIST_STATUSES.NOT_SIGNED, INFRALIST_STATUSES.NOT_FORMED].includes(
          infralistStatus
        ));
    // когда раздел "на подписании" или "Не согласован"
    const isRVPO2canEdit =
      sectionStatus === INFRALIST_STATUSES.ON_SIGNING ||
      (sectionStatus === INFRALIST_STATUSES.NOT_APPROVED &&
        [
          INFRALIST_STATUSES.NOT_APPROVED,
          INFRALIST_STATUSES.ON_SIGNING
        ].includes(infralistStatus));

    const isFPO1canEdit =
      sectionStatus === INFRALIST_STATUSES.ON_CHECKING &&
      ![
        INFRALIST_CHECK_STATUSES.CHECKED_SUCCESS,
        INFRALIST_CHECK_STATUSES.CHECKED_WITH_COMMENTS
      ].includes(infralistSectionCheckStatus);

    const isFPO2canEdit = sectionStatus === INFRALIST_STATUSES.ON_APPROVAL;

    return {
      // действия по ролям
      showFormActions: isRVPO1,
      canForm:
        !isVersionMode &&
        isRVPO1 &&
        isRVPO1canEdit &&
        isEditEnabledByEventStatus,

      showSignActions: isRVPO2,
      canSign:
        !isVersionMode &&
        isRVPO2 &&
        isRVPO2canEdit &&
        isEditEnabledByEventStatus,

      showCheckActions: isFPO1,
      showCheckStatusInHeader: isFPO1,
      canCheck:
        !isVersionMode && isFPO1 && isFPO1canEdit && isEditEnabledByEventStatus,

      showApproveActions: isFPO2,
      canApprove:
        !isVersionMode &&
        isFPO2 &&
        isEditEnabledByEventStatus &&
        [
          INFRALIST_STATUSES.ON_APPROVAL,
          INFRALIST_STATUSES.APPROVED,
          INFRALIST_STATUSES.APPROVED_WITH_COMMENTS
        ].includes(sectionStatus),

      // может редактировать оборудование
      canEdit:
        !isVersionMode &&
        ((isRVPO1 && isRVPO1canEdit) ||
          (isRVPO2 && isRVPO2canEdit) ||
          (isFPO1 && isFPO1canEdit) ||
          (isFPO2 && isFPO2canEdit)) &&
        isEditEnabledByEventStatus,

      // настройка таблицы: колонки и действия
      showStatusColumn: isRVPO1,
      showStatusSignColumn: isRVPO2 || isRandomRVPO,
      showCheckColumn: isFPO1 || isFPO2 || isRandomFpo,
      showApproveColumn:
        isFPO1 ||
        isFPO2 ||
        isRandomFpo ||
        ((isRVPO2 || isRandomRVPO) && !!infralistSectionApproveStatus),

      // показывать ссылку на комментарии
      showCommentLinkAlways:
        (isRVPO2 && sectionStatus === INFRALIST_STATUSES.ON_SIGNING) ||
        (isFPO1 &&
          [
            INFRALIST_STATUSES.ON_CHECKING,
            INFRALIST_STATUSES.ON_APPROVAL
          ].includes(sectionStatus)) ||
        isFPO2,

      // редактирование 1 оборудования
      canEditSignedEquipments:
        !isVersionMode &&
        (isRVPO2 || isFPO1 || isFPO2) &&
        isEditEnabledByEventStatus,
      canEditApprovedEquipments:
        isFPO2 &&
        [
          INFRALIST_STATUSES.ON_APPROVAL,
          INFRALIST_STATUSES.APPROVED,
          INFRALIST_STATUSES.APPROVED_WITH_COMMENTS
        ].includes(infralistStatus) &&
        isEditEnabledByEventStatus
    };
  }, [
    isEditEnabledByEventStatus,
    infralistSectionApproveStatus,
    infralistSectionCheckStatus,
    infralistStatus,
    isFPO1,
    isFPO2,
    isRVPO1,
    isRVPO2,
    isRandomFpo,
    isRandomRVPO,
    isVersionMode,
    sectionStatus
  ]);

  return accesses;
};

export const useInfralistSectionCheckers = ({
  sectionStatus,
  infralistSectionCheckStatus,
  infralistStatus,
  isEditEnabledByEventStatus
}) => {
  const {
    canEdit,
    canEditApprovedEquipments,
    canEditSignedEquipments
  } = useInfralistSectionUserAccesses({
    sectionStatus,
    infralistSectionCheckStatus,
    infralistStatus,
    isEditEnabledByEventStatus
  });
  const checkEditEquipments = useCallback(
    (equipment) => {
      if (!equipment) {
        return false;
      }

      // проверка на возможность редактирования согласованного оборудования
      const isApproved = [
        EQUIPMENT_STATUSES.APPROVED,
        INFRALIST_STATUSES.APPROVED_WITH_COMMENTS
      ].includes(equipment.equipment_approvement_status?.name);
      const canEditIfApproved =
        !isApproved || (isApproved && canEditApprovedEquipments);

      // проверка на возможность редактирования подписанного оборудования
      const isSigned =
        equipment.equipment_status?.name === EQUIPMENT_STATUSES.SIGNED;
      const canEditIfSigned =
        !isSigned || (isSigned && canEditSignedEquipments);

      return canEdit && canEditIfApproved && canEditIfSigned;
    },
    [canEdit, canEditApprovedEquipments, canEditSignedEquipments]
  );

  return {
    checkEditEquipments
  };
};

export const useInfralistStatusUserAccesses = (infralistStatus) => {
  const {
    isRVPO1,
    isRVPO2,
    isFPO1,
    isFPO2,
    isRandomFpo,
    isRandomRVPO
  } = useInfralistUserAccesses();

  const accesses = useMemo(() => {
    const isRVPO1OwnStage =
      isRVPO1 &&
      [INFRALIST_STATUSES.NOT_FORMED, INFRALIST_STATUSES.NOT_SIGNED].includes(
        infralistStatus
      );

    const isRVPO2OwnStage =
      isRVPO2 &&
      [INFRALIST_STATUSES.ON_SIGNING, INFRALIST_STATUSES.NOT_APPROVED].includes(
        infralistStatus
      );

    const isFPO1OwnStage =
      isFPO1 && infralistStatus === INFRALIST_STATUSES.ON_CHECKING;

    const isFPO2OwnStage =
      isFPO2 && infralistStatus === INFRALIST_STATUSES.ON_APPROVAL;

    return {
      isRVPO1OwnStage,
      isRVPO2OwnStage,
      isFPO1OwnStage,
      isFPO2OwnStage,
      showRVPOColumns: isRVPO1 || isRVPO2 || isRandomRVPO,
      showFPOColumns: isFPO1 || isFPO2 || isRandomFpo
    };
  }, [
    infralistStatus,
    isFPO1,
    isFPO2,
    isRVPO1,
    isRVPO2,
    isRandomFpo,
    isRandomRVPO
  ]);
  return accesses;
};

export const useInfralistDirectionAccesses = ({
  directionStatus,
  directionCheckStatus,
  isEditEnabledByEventStatus
}) => {
  const { isRVPO1, isRVPO2, isFPO1, isFPO2 } = useInfralistUserAccesses();

  const accesses = useMemo(() => {
    return {
      showCheckStatus:
        isFPO1 &&
        directionStatus === INFRALIST_STATUSES.ON_CHECKING &&
        directionCheckStatus,
      showSignAllInDirection: isRVPO2,
      canSignAllInDirection:
        [
          INFRALIST_STATUSES.ON_SIGNING,
          INFRALIST_STATUSES.NOT_APPROVED
        ].includes(directionStatus) && isEditEnabledByEventStatus
    };
  }, [
    directionCheckStatus,
    directionStatus,
    isEditEnabledByEventStatus,
    isFPO1,
    isRVPO2
  ]);

  return accesses;
};
