import React from 'react';
import { useParams } from 'react-router';
import Paper from '@material-ui/core/Paper';
import Loader from '../../../../common/Loader/Loader';
import { BlockWatchScroll } from '../../../../common/BlockWatchScroll/BlockWatchScroll';
import { SearchBlock } from './SearchBlock';
import { ResultBlock } from './ResultBlock';
import { useChangeLogs } from './useChangeLogs';
import s from './ChangeLogSidebar.module.scss';

export const ChangeLogSidebar = ({
  currentChangeLogId,
  setCurrentChangeLogId
}) => {
  const { infralistId } = useParams();

  const {
    changeLogs,
    isLoadingChangeLogs,
    params,
    loadMoreChangeLogs,
    setFilter
  } = useChangeLogs(infralistId);

  return (
    <BlockWatchScroll
      className={s.root}
      onScrollEndY={loadMoreChangeLogs}
      onScroll={(e) => {
        // имитирую скрол body, чтобы react-select среагировал
        if (e.target === e.currentTarget) {
          document.body.dispatchEvent(new CustomEvent('scroll'));
        }
      }}
    >
      <Paper variant="outlined" square className={s.paper}>
        <SearchBlock params={params} setFilter={setFilter} />

        {changeLogs.map((changeLog) => (
          <ResultBlock
            active={currentChangeLogId === changeLog.id}
            changeLog={changeLog}
            setCurrentChangeLogId={setCurrentChangeLogId}
            key={changeLog.id}
          />
        ))}

        {isLoadingChangeLogs && (
          <div className={s.loading}>
            <Loader />
          </div>
        )}
      </Paper>
    </BlockWatchScroll>
  );
};
