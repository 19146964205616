import React, { useMemo } from 'react';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { useContextStore } from '@proscom/prostore-react';

import { CircularProgress } from '@material-ui/core';

import PageTitle from '../../common/PageTitle/PageTitle';
import Table from '../../common/Table/Table';
import Menu from '../../common/Menu/Menu';
import { User } from '../../store/role/User';
import { useUsers } from '../../store/role/useUsers';
import {
  QueryPagination,
  useQueryPagination,
  useQueryPaginationCount
} from '../../common/usePagination';
import { useUrlQuery } from '../../common/useUrlQuery';
import { useCurrentUser } from '../../store/useCurrentUser';

import { USER_STORE } from '../../store/names';

import { useDeleteRow } from '../../common/Table/useDeleteRow';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';
import { useUsersPageFilter } from './useUsersPageFilter';

const usersColumn = [
  {
    label: '№',
    key: 'order',
    isThin: true
  },
  {
    label: 'Имя',
    key: 'name',
    isWide: true,
    isBold: true
  },
  {
    label: 'Почта',
    key: 'email',
    isWide: true
  },
  {
    label: 'Логин',
    key: 'login',
    isWide: true
  },
  {
    label: 'Роль',
    key: 'roleName',
    isWide: true
  }
];

const title = 'Пользователи';

export default function UsersPage({ match, location, history }) {
  const userStore = useContextStore(USER_STORE);
  const [query, changeQuery] = useUrlQuery(location, history);
  const pagination = useQueryPagination(query, changeQuery, 50);

  const [filterComponent, filter] = useUsersPageFilter(query, changeQuery);

  const usersQuery = useUsers(filter, pagination.params);

  useQueryPaginationCount(pagination, usersQuery);
  const usersQueryData = usersQuery.state.data;
  const data = useMemo(() => {
    if (!usersQueryData) {
      return null;
    }
    const { list, header } = usersQueryData;
    return list.map((user, iEntity) => ({
      ...user,
      order: 1 + iEntity + header.page * header.onePage,
      roleName: user?.roles?.[0]?.name
    }));
  }, [usersQueryData]);

  const menuComponent = useMemo(
    () =>
      function MenuComponent({ row }) {
        const [isDeleting, dialog, openDialog] = useDeleteRow(
          userStore.deleteUser,
          'пользователя'
        );

        return (
          <>
            {isDeleting ? (
              <CircularProgress size={18} />
            ) : (
              <Menu
                iconColor="default"
                icon={MoreHorizIcon}
                options={[
                  {
                    title: 'Редактировать',
                    onClick: () => history.push(`${match.url}/${row.id}/edit`)
                  },
                  {
                    title: 'Удалить',
                    onClick: () => openDialog(row.id)
                  }
                ]}
              />
            )}
            {dialog}
          </>
        );
      },
    [history, match, userStore]
  );

  return (
    <>
      <HeadTitle title={title} />
      <PageTitle withBackBtn={false} title={title} />
      <Table
        addButtonText="Добавить"
        addFieldRoute={`${match.url}/create`}
        hasAddField={true}
        columns={usersColumn}
        data={data}
        isLoading={usersQuery.check.spinner}
        renderNoData={() => <h3>Нет пользователей</h3>}
        rowRedirectRoute={`${match.url}/`}
        menuComponent={menuComponent}
        filter={filterComponent}
        pagination={<QueryPagination pagination={pagination} />}
      />
    </>
  );
}
