import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_INDICATOR_FORECASTS } from '../../graphql/queries/indicatorForecasts';

const indicatorForecastsQueryOptions = {
  query: QUERY_GET_INDICATOR_FORECASTS,
  mapData: (result) => result.indicatorForecasts,
  skipQuery: skipIfNull(null)
};

export function useIndicatorForecasts(filter) {
  return useRetriableGraphqlVars({
    queryOptions: indicatorForecastsQueryOptions,
    variableCreator: [
      () =>
        filter
          ? {
              filter
            }
          : null,
      [filter]
    ]
  });
}
