import axios from 'axios';
import { apiUrl } from '../../config';

export const INFRALIST_EXPORT_TYPES = {
  DOCX: 'docx',
  PDF: 'pdf',
  XLSX: 'xlsx',
  DOCX_WITHOUT_PRICE: 'docx_without_price'
};

export const useExportInfralist = ({ infralistId, versionId }) => {
  const fetch = (documentType, ext) => {
    return axios
      .get(`${apiUrl}/files/infralist/${infralistId}`, {
        responseType: 'blob',
        params: {
          type:
            documentType === INFRALIST_EXPORT_TYPES.DOCX_WITHOUT_PRICE
              ? INFRALIST_EXPORT_TYPES.DOCX
              : documentType,
          version_id: versionId,
          without_price:
            documentType === INFRALIST_EXPORT_TYPES.DOCX_WITHOUT_PRICE
              ? true
              : undefined
        }
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `infralist.${ext ? ext : documentType}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  return { exportInfralist: fetch };
};
