export const createEquipmentInitialValues = {
  fpo_equipment_id: '',
  name_rvpo: '',
  description_rvpo: '',
  model_rvpo: '',
  unit: '',
  quantity: '',
  price: '',
  equipment_external_url: ''
};
