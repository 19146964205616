import {
  MUTATION_DOCUMENT_ARCHIVE_TOKEN,
  MUTATION_ROADMAP_DOCUMENT_TOKEN,
  MUTATION_ROADMAP_FILE_TOKEN,
  MUTATION_UPLOAD_DOCUMENT_TOKEN,
  MUTATION_UPLOAD_UNIVERSAL_DOCUMENT_TOKEN
} from '../graphql/mutations/roadmapEvent';
import { GET_DISTRIBUTION_TOKEN } from '../graphql/mutations/internetSpeed';
import { MUTATION_CREATE_MENTORING_COVERAGE_TOKEN } from '../graphql/mutations/mentoringCoverage';
import { MUTATION_CREATE_GUIDELINE_REPORT_FILE_TOKEN } from '../graphql/mutations/parentalAssistanceGuidelines/parentalAssistanceGuidelinesOrganizational';
import { MUTATION_CREATE_CONTEST_DOCS_REPORT_FILE_TOKEN } from '../graphql/mutations/contestDocsMonitoring';
import { MUTATION_CREATE_SUBSIDIARY_MONITORING_REPORT_FILE_TOKEN } from '../graphql/mutations/subsidiaryMonitoring';
import { MUTATION_CREATE_CONTEST_DOCS_FOR_SS_REPORT_FILE_TOKEN } from '../graphql/mutations/contestDocsForSSMonitoring';

export class FileTokenStore {
  constructor({ client }) {
    this.client = client;
  }

  async createRoadmapDocumentFileToken(id) {
    const query = await this.client.mutate({
      mutation: MUTATION_ROADMAP_FILE_TOKEN,
      variables: { id }
    });
    return query.data.createRoadmapDocumentFileOneTimeToken;
  }

  async createRoadmapDocumentToken(id) {
    const query = await this.client.mutate({
      mutation: MUTATION_ROADMAP_DOCUMENT_TOKEN,
      variables: { id }
    });
    return query.data.createRoadmapDocumentOneTimeToken;
  }

  async createUploadOneTimeToken() {
    const query = await this.client.mutate({
      mutation: MUTATION_UPLOAD_DOCUMENT_TOKEN
    });
    return query.data.createUploadOneTimeToken;
  }

  async createUploadUniversalDocumentOneTimeToken() {
    const query = await this.client.mutate({
      mutation: MUTATION_UPLOAD_UNIVERSAL_DOCUMENT_TOKEN
    });
    return query.data.createUploadUniversalDocumentFileOneTimeToken;
  }

  async createDistributionOneTimeToken(organizationId) {
    const query = await this.client.mutate({
      mutation: GET_DISTRIBUTION_TOKEN,
      variables: { organizationId }
    });
    return query.data.createDistributionOneTimeToken;
  }

  async createRoadmapDocumentsArchiveOneTimeToken(id) {
    const query = await this.client.mutate({
      mutation: MUTATION_DOCUMENT_ARCHIVE_TOKEN,
      variables: { id }
    });
    return query.data.createRoadmapDocumentsArchiveOneTimeToken;
  }

  async createMentoringCoverageReportOneTimeToken() {
    const query = await this.client.mutate({
      mutation: MUTATION_CREATE_MENTORING_COVERAGE_TOKEN
    });
    return query.data.createMentoringCoverageReportOneTimeToken;
  }

  async createParentalAssistanceGuidelinesRegionalReportOneTimeToken() {
    const query = await this.client.mutate({
      mutation: MUTATION_CREATE_GUIDELINE_REPORT_FILE_TOKEN
    });
    return query.data
      .createParentalAssistanceGuidelinesRegionalReportOneTimeToken;
  }

  async createTenderDocumentationMonthlyMonitoringReportOneTimeToken() {
    const query = await this.client.mutate({
      mutation: MUTATION_CREATE_CONTEST_DOCS_REPORT_FILE_TOKEN
    });
    return query.data
      .createTenderDocumentationMonthlyMonitoringReportOneTimeToken;
  }

  async createTenderDocumentationForSSReportOneTimeToken() {
    const query = await this.client.mutate({
      mutation: MUTATION_CREATE_CONTEST_DOCS_FOR_SS_REPORT_FILE_TOKEN
    });
    return query.data.createTenderDocumentationForSSReportOneTimeToken;
  }

  async createSubsidiaryMonitoringReportOneTimeToken(id) {
    const query = await this.client.mutate({
      mutation: MUTATION_CREATE_SUBSIDIARY_MONITORING_REPORT_FILE_TOKEN,
      variables: {
        id
      }
    });
    return query.data.createSubsidiaryEntityMonitoringReportOneTimeToken;
  }
}
