import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useContextStore } from '@proscom/prostore-react';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import {
  EVENT_TYPE_INFRALIST,
  EVENT_TYPE_INFRALIST_SS,
  isInfralist
} from '../../../../../store/roadmap/roadmapEvents';
import InputGroup from '../../../../../common/Inputs/InputGroup';
import {
  useEventInfralists,
  useEventInfralistsStatus
} from '../../../../../store/infralists/useEventInfralists';
import { queryLoaderWithExistenceCheck } from '../../../../../common/QueryLoader';
import { Infralist } from '../../../../../store/infralists/Infralist';
import { STORE_INFRALIST } from '../../../../../store/names';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../../graphql/graphqlErrors';
import AutocompleteBaseInput from '../../../../../common/Inputs/Selects/AutocompleteBaseInput';
import { useSubsidiaryEntitiesForFilter } from '../../../../../store/subsidiaryEntity/useSubsidiaryEntities';
import { SubsidiaryEntity } from '../../../../../store/subsidiaryEntity/SubsidiaryEntity';
import { useMemoSuggestions } from '../../../../../utils/suggestions';
import Loader from '../../../../../common/Loader/Loader';
import { useFieldFast } from '../../../../../common/InputsNew/useFieldFast';
import { InfralistItem, InfralistSsItem } from './InfralistsInfo';
import s from '../EventEditPage.module.scss';

const useInfralistsField = ({
  eventInfralistsData,
  eventInfralistsStatusQuery,
  infralists,
  deletedInfralists,
  setInfralist,
  setDeletedInfralist,
  updateInfralistsRef
}) => {
  const infralistsStatus = eventInfralistsStatusQuery.state.data;

  const filterInfralistCompare = useCallback(
    (item) =>
      !deletedInfralists?.some((dInfralist) => dInfralist.id === item.id),
    [deletedInfralists]
  );

  useEffect(() => {
    // При каждом изменении получаемого массива данных, обновляем локальный стор
    const infralistsData = eventInfralistsData?.filter(filterInfralistCompare);
    setInfralist(infralistsData ? [...infralistsData] : undefined);
  }, [eventInfralistsData, filterInfralistCompare, setInfralist]);

  useEffect(() => {
    const infralistsData = infralists || [];
    let isUpdated = false;
    // Проверяем, изменились ли статусы инфралистов
    const updatedStatus = infralistsData
      .map((infralist) => {
        const status = infralistsStatus.find((i) => i.id === infralist.id)
          ?.infralist_status_id;
        if (status && status !== infralist.infralist_status_id) {
          isUpdated = true;
          return {
            ...infralist,
            infralist_status_id: status
          };
        }
        return infralist;
      })
      .filter(filterInfralistCompare);
    // Если изменились, то обновляем
    if (isUpdated) {
      setInfralist(updatedStatus);
      toast.info('Статусы инфраструктурных листов автоматически обновлены');
    }
    // Если изменилось количество, то обновляем основной запрос
    if (
      infralistsStatus &&
      eventInfralistsData &&
      infralistsStatus.length !== eventInfralistsData.length
    ) {
      updateInfralistsRef.current.loadInfralist();
      toast.info('Инфраструктурные листы автоматически обновлены');
    }
    // Функция зависит только от запроса, зависимость от infralistField создает рекурсию
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    infralistsStatus,
    eventInfralistsData,
    filterInfralistCompare,
    setInfralist
  ]);

  // Функция удаление инфралиста
  return useCallback(
    async (id) => {
      let infralistsData = infralists || [];
      infralistsData = infralistsData.find((i) => i.id === id);

      // Добавляем удаленный инфралист в локальный стор, чтобы отфильтровать его
      // в основном пуле
      const deletedInfralistsData = deletedInfralists
        ? [...deletedInfralists, infralistsData]
        : [infralistsData];

      setDeletedInfralist(deletedInfralistsData);
      toast.warn(
        'Удаление инфраструктурного листа. ' +
          'Для сохранения изменений, сохраните мероприятие'
      );
    },
    [infralists, deletedInfralists, setDeletedInfralist]
  );
};

export const InfralistsEditInfo = ({
  regionalEvent,
  eventType,
  regionId,
  roadmapId,
  year
}) => {
  const loadRef = useRef({});
  const [infralistsField, metaInfralists, helpersInfralists] = useFieldFast(
    'infralists'
  );
  const [
    deletedInfralistsField,
    deletedInfralistsMeta,
    deletedInfralistsHelpers
  ] = useFieldFast('deletedInfralists');

  const isInfralistEvent = isInfralist(eventType);
  const infralistStore = useContextStore(STORE_INFRALIST);

  const [isSubmitting, setSubmitting] = useState(false);
  const [ssId, setSsId] = useState('');

  const eventInfralistsQuery = useEventInfralists(regionalEvent.id);
  const eventInfralistsStatusQuery = useEventInfralistsStatus(regionalEvent.id);

  loadRef.current.loadInfralist = eventInfralistsQuery.load;
  loadRef.current.loadInfralistStatuses = eventInfralistsStatusQuery.load;

  const eventInfralistsData = eventInfralistsQuery.state.data;

  const eventInfralists = infralistsField.value || undefined;

  const infralistOptions = useMemo(
    () => ({
      eventInfralistsData,
      eventInfralistsStatusQuery,
      infralists: infralistsField.value,
      deletedInfralists: deletedInfralistsField.value,
      setInfralist: helpersInfralists.setValue,
      setDeletedInfralist: deletedInfralistsHelpers.setValue,
      updateInfralistsRef: loadRef
    }),
    [
      deletedInfralistsHelpers.setValue,
      deletedInfralistsField.value,
      eventInfralistsData,
      eventInfralistsStatusQuery,
      infralistsField.value,
      helpersInfralists.setValue
    ]
  );

  const handleInfralistDelete = useInfralistsField(infralistOptions);

  useEffect(() => {
    const onFocus = () => {
      loadRef.current.loadInfralistStatuses();
    };
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  const subsidiaryEntitiesFilter = useMemo(
    () => ({
      region_ids: regionId,
      owner_roadmap_ids: roadmapId,
      year: year + ''
    }),
    [regionId, roadmapId, year]
  );
  const entitiesQuery = useSubsidiaryEntitiesForFilter(
    subsidiaryEntitiesFilter
  );
  const entities = entitiesQuery.state.data?.list.map((entity) => ({
    ...entity,
    name_full: SubsidiaryEntity.getCompleteName(entity)
  }));
  const entitiesSuggestions = useMemoSuggestions(entities);

  const handleInfralistCreate = (e) => {
    infralistStore
      .createInfralist({
        infralist_status_id: Infralist.Status.NOT_FORMED,
        regional_roadmap_event_id: regionalEvent.id,
        subsidiary_entity_id: ssId,
        type: eventType.toUpperCase()
      })
      .then((result) => {
        setSsId('');
        eventInfralistsQuery.load();
        toast.success('Инфраструктурный лист создан и привязан');
      })
      .catch((error) => {
        showGraphqlErrorToast(error, mutationErrors.updateObject);
        setSubmitting(false);
      });
  };

  if (!isInfralistEvent) return null;

  return (
    <InputGroup
      title={
        eventType === EVENT_TYPE_INFRALIST
          ? 'Инфраструктурный лист'
          : 'Инфраструктурные листы'
      }
    >
      {queryLoaderWithExistenceCheck(eventInfralistsQuery) ||
        eventInfralists?.map((infralist, iIfralist) => {
          if (eventType === EVENT_TYPE_INFRALIST_SS) {
            return (
              <InfralistSsItem
                key={iIfralist}
                data={infralist}
                handleInfralistDelete={
                  infralist.is_deletable && handleInfralistDelete
                }
              />
            );
          }
          return (
            <InfralistItem
              key={iIfralist}
              data={infralist}
              handleInfralistDelete={
                infralist.is_deletable && handleInfralistDelete
              }
            />
          );
        })}
      <>
        {eventType === EVENT_TYPE_INFRALIST_SS && (
          <AutocompleteBaseInput
            name="subsidiary_entity_id"
            label="Субсидиарная сущность"
            variant="standard"
            value={ssId}
            onChange={(e) => {
              setSsId(e);
            }}
            hasCopyBtn={false}
            suggestions={entitiesSuggestions}
            suggestionsLoading={entitiesQuery.check.spinner}
            fullWidth
          />
        )}
        <Button
          className={s.Button}
          color="primary"
          variant="contained"
          onClick={handleInfralistCreate}
        >
          {isSubmitting ? (
            <Loader />
          ) : (
            <>
              <AddIcon className={s.ButtonIcon} />
              Создать инфралист
            </>
          )}
        </Button>
      </>
    </InputGroup>
  );
};
