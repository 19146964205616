import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { removeLastPathItem } from '../../../../utils/url';
import { getMonth } from '../../../../utils/data/date';
import { Pof } from '../../../../store/pof/Pof';
import { useRegionalProject } from '../../../../store/regionalProject/useRegionalProject';
import { STORE_POF } from '../../../../store/names';
import { useOtherEditor } from '../../../../common/Form/useOtherEditor';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import { useCurrentUser } from '../../../../store/useCurrentUser';
import { handleFormSubmission } from '../../../../utils/data/form';
import { PofAuth } from '../../../../store/pof/PofAuth';
import { usePofRegionalDocument } from '../../../../store/pof/pofQueries';
import { PofBreadCrumbs } from '../PofBreadCrumbs';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { getName } from '../../../../utils/data/label';
import { PofEditForm } from './PofEditForm';

const PofEditPage = ({ match, history }) => {
  const backLink = removeLastPathItem(match.url);
  const { projectId, pofId } = match.params;

  const pofStore = useContextStore(STORE_POF);
  const editor = useOtherEditor('pof', pofId);

  const pofRegionalDocumentQuery = usePofRegionalDocument(pofId);
  const pofRegionalDocument = pofRegionalDocumentQuery.state.data;

  const projectQuery = useRegionalProject(projectId);
  const project = projectQuery.state.data;

  const formHeaderData = pofRegionalDocument && {
    regionName: pofRegionalDocument.region?.name,
    regionId: pofRegionalDocument.region?.id,
    projectId,
    year: pofRegionalDocument.year,
    monthName: getMonth(pofRegionalDocument.month - 1),
    result: pofRegionalDocument.expenses_direction?.result?.name_full,
    status: pofRegionalDocument.status,
    fpoComment: pofRegionalDocument.comment_fpo,
    author: pofRegionalDocument.author?.name,
    federalProject: pofRegionalDocument.federal_project
  };

  const user = useCurrentUser();
  const validationSchema = useMemo(
    () => PofAuth.validationSchemaEdit(pofRegionalDocument, user),
    [pofRegionalDocument, user]
  );

  return (
    <>
      <HeadTitle
        type="ПОФ"
        entity={getName(pofRegionalDocument?.expenses_direction)}
        pageType={HeadTitle.PageType.edit}
      />
      <PofBreadCrumbs projectId={projectId} project={project} />
      <h2>Редактирование ПОФ</h2>
      {queryLoaderWithExistenceCheck(pofRegionalDocumentQuery) || (
        <Formik
          validationSchema={validationSchema}
          initialValues={Pof.toForm(pofRegionalDocument)}
          onSubmit={(values, actions) => {
            const filtered = PofAuth.filterAccessibleFields(
              values,
              user,
              pofRegionalDocument
            );
            const pof = Pof.fromForm(filtered);
            handleFormSubmission(pofStore.updatePof(pof), actions, (result) =>
              history.goBack(Pof.getUrl(result.data.result, projectId))
            );
          }}
          render={(formikProps) => (
            <PofEditForm
              {...formikProps}
              onBackClick={() => history.goBack(backLink)}
              formHeaderData={formHeaderData}
              editor={editor}
              createForm={false}
              pofRegionalDocument={pofRegionalDocument}
            />
          )}
        />
      )}
    </>
  );
};

export default PofEditPage;
