import { pick } from 'lodash-es';
import { User } from '../role/User';
import { formatNumber } from '../../utils/formatNumber';
import { makeOptionator } from '../../utils/data/label';

const indicatorFields = [
  'name_full',
  'owner_roadmap_indicator_id',
  'region_id',
  'subsidiary_entity_id',
  'year',
  'month',

  'status',
  'comment_fpo',

  'value_fact',
  'value_from_tender_documentation'
];

export class Indicator {
  static Status = {
    NOT_TRANSFERRED: 'NOT_TRANSFERRED',
    ON_CONFIRMATION: 'ON_CONFIRMATION',
    CONFIRMED: 'CONFIRMED',
    RETURNED: 'RETURNED'
  };

  static getStatus(status) {
    switch (status) {
      case Indicator.Status.ON_CONFIRMATION:
        return 'На утверждении';
      case Indicator.Status.CONFIRMED:
        return 'Утверждено';
      case Indicator.Status.RETURNED:
        return 'Возвращено на доработку';
      default:
        return 'Не передано';
    }
  }

  static editStatusOptions = [
    Indicator.Status.ON_CONFIRMATION,
    Indicator.Status.CONFIRMED,
    Indicator.Status.RETURNED
  ].map(makeOptionator(Indicator.getStatus));

  static filterStatusOptions = [
    Indicator.Status.ON_CONFIRMATION,
    Indicator.Status.CONFIRMED,
    Indicator.Status.RETURNED,
    Indicator.Status.NOT_TRANSFERRED
  ].map(makeOptionator(Indicator.getStatus));

  static filterUpdateFields(data) {
    return pick(data, ['id', ...indicatorFields]);
  }

  static filterCreateFields(data) {
    return pick(data, indicatorFields);
  }

  static fromForm({
    value_from_tender_documentation,
    value_fact,
    year,
    month,
    ...data
  }) {
    return {
      ...data,
      value_from_tender_documentation: +value_from_tender_documentation,
      value_fact: +value_fact,
      year: +year,
      month: +month
    };
  }

  static toForm(data) {
    return data;
  }

  static getUrl(indicator, projectId, roadmapId) {
    return `/projects/${projectId}/roadmaps/${roadmapId}/indicators/${indicator.id}`;
  }

  static getMinimumText(roadmapIndicator) {
    if (!roadmapIndicator) return null;
    const planData = roadmapIndicator.plan_roadmap_indicator;
    const minValue = planData?.min_numeric_value_per_year;
    const minValueFormula = planData?.min_numeric_value_per_year_formula;
    return minValueFormula || formatNumber(minValue);
  }

  static canCreateIndicator(user) {
    return User.hasPermission(user, 'indicator.crud');
  }

  static canEditIndicator(user, indicator) {
    return (
      ((indicator?.status === Indicator.Status.NOT_TRANSFERRED ||
        indicator?.status === Indicator.Status.RETURNED) &&
        User.hasPermission(user, 'indicator.crud')) ||
      (indicator?.status === Indicator.Status.ON_CONFIRMATION &&
        User.hasPermission(user, 'indicator.approve')) ||
      User.isAdmin(user)
    );
  }

  static canDeleteIndicator(user, indicator) {
    return (
      ((indicator?.status === Indicator.Status.NOT_TRANSFERRED ||
        indicator?.status === Indicator.Status.RETURNED) &&
        User.hasPermission(user, 'indicator.crud')) ||
      User.isAdmin(user)
    );
  }

  static canSendForApproval(user, indicator) {
    return (
      (indicator?.status === Indicator.Status.NOT_TRANSFERRED ||
        indicator?.status === Indicator.Status.RETURNED) &&
      User.hasPermission(user, 'indicator.send_for_approval')
    );
  }

  static canApprove(user, indicator) {
    return (
      (indicator &&
        indicator.status === Indicator.Status.ON_CONFIRMATION &&
        User.hasPermission(user, 'indicator.approve')) ||
      User.isAdmin(user)
    );
  }

  static canApproveMultipleIndicators(user, indicators) {
    if (!indicators) return false;

    const haveData = indicators.find(
      (indicator) => indicator.status === Indicator.Status.ON_CONFIRMATION
    );

    return (
      haveData &&
      (User.hasPermission(user, 'indicator.approve') || User.isAdmin(user))
    );
  }
}
