import gql from 'graphql-tag';
import { FRAGMENT_EVENT_INFRALISTS } from '../queries/eventInfralists';

export const MUTATION_INFRALIST_FORMATION_COMPLETE = gql`
  mutation infralistFormationComplete($infralist_id: ID!, $section_id: ID!) {
    infralistFormationComplete(
      infralist_id: $infralist_id
      section_id: $section_id
    ) {
      id
      infralist_status {
        id
        name
      }
    }
  }
`;

export const MUTATION_INFRALIST_RESET = gql`
  mutation infralistFormationReset($infralist_id: ID!) {
    infralistFormationReset(infralist_id: $infralist_id) {
      id
      infralist_status {
        id
        name
      }
    }
  }
`;

export const MUTATION_CREATE_INFRALIST = gql`
  mutation createInfralist($input: InfralistInputType!) {
    result: createInfralist(input: $input) {
      ...eventInfralist
    }
  }
  ${FRAGMENT_EVENT_INFRALISTS}
`;

export const MUTATION_INFRALIST_NOT_APPROVE_ALL = gql`
  mutation notApproveAll($infralist_id: ID!) {
    notApproveAll(infralist_id: $infralist_id) {
      id
      infralist_status {
        id
        name
      }
    }
  }
`;

export const MUTATION_INFRALIST_COMPLETE_NOT_APPROVE = gql`
  mutation completeNotApprove($infralist_id: ID!, $is_empty_agreed: Boolean) {
    completeNotApprove(
      infralist_id: $infralist_id
      is_empty_agreed: $is_empty_agreed
    ) {
      id
      infralist_status {
        id
        name
      }
    }
  }
`;

export const MUTATION_INFRALIST_COMPLETE_SIGNING = gql`
  mutation completeInfralistSigning(
    $infralist_id: ID!
    $is_empty_signing: Boolean
  ) {
    completeInfralistSigning(
      infralist_id: $infralist_id
      is_empty_signing: $is_empty_signing
    ) {
      id
    }
  }
`;

export const MUTATION_INFRALIST_COMPLETE_CHECKING = gql`
  mutation completeInfralistCheck(
    $infralist_id: ID!
    $is_empty_check: Boolean
  ) {
    completeInfralistCheck(
      infralist_id: $infralist_id
      is_empty_check: $is_empty_check
    ) {
      id
    }
  }
`;

export const MUTATION_INFRALIST_NOT_SIGN_ALL = gql`
  mutation notSignAll($infralist_id: ID!) {
    notSignAll(infralist_id: $infralist_id) {
      id
    }
  }
`;

export const MUTATION_INFRALIST_CHECK_ALL = gql`
  mutation checkAll($infralist_id: ID!) {
    checkAll(infralist_id: $infralist_id) {
      id
    }
  }
`;
