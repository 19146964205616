import { useState } from 'react';
import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { toast } from 'react-toastify';
import { MUTATION_INFRALIST_COMPLETE_SIGNING } from '../../../graphql/mutations/infralist';
import { INFRALIST_QUERIES } from '../infralistQueries';
import { useDialog } from '../../../utils/hooks/useDialog';
import {
  getGraphQLErrorInfo,
  showGraphqlErrorToast
} from '../../../graphql/graphqlErrors';
import { clearApolloQueryCache } from '../../../utils/apollo';

export const useSignInfralist = (infralistId) => {
  const [loading, setLoading] = useState(false);
  const [loadingWithoutEquipment, setLoadingWithoutEquipment] = useState(false);
  const client = useContextApolloClient();
  const {
    isOpen: isSigningInfralistPopup,
    open: openSigningInfralistPopup,
    close: closeSigningInfralistPopup
  } = useDialog();

  const {
    isOpen: isSigningInfralistPopupWithoutEquipment,
    open: openSigningInfralistPopupWithoutEquipment,
    close: closeSigningInfralistPopupWithoutEquipment
  } = useDialog();

  const catchCompleteSignInfralistErrors = (graphqlError) => {
    const errorInfo = getGraphQLErrorInfo(graphqlError);
    const completeError =
      'Для завершения подписания необходимо проставить статусы всем позициям оборудования';
    // Привязываемся к тексту ошибки
    const catchErrors = [
      'Not all infralists items are approve or disapprove',
      'Not all equipments signing'
    ];
    if (catchErrors.includes(errorInfo?.message)) {
      toast.error(completeError);
    } else {
      showGraphqlErrorToast(graphqlError);
    }
  };

  const completeInfralistSigning = (isEmptySigning) => {
    if (isEmptySigning || typeof isEmptySigning === 'undefined') {
      setLoading(true);
    } else {
      setLoadingWithoutEquipment(true);
    }
    clearApolloQueryCache(client.store.getCache(), [
      INFRALIST_QUERIES.INFRALIST,
      INFRALIST_QUERIES.DIRECTIONS_LIST,
      INFRALIST_QUERIES.DIRECTION,
      INFRALIST_QUERIES.DIRECTION_SECTIONS,
      INFRALIST_QUERIES.SECTION,
      INFRALIST_QUERIES.EQUIPMENT_LIST
    ]);
    return client
      .mutate({
        mutation: MUTATION_INFRALIST_COMPLETE_SIGNING,
        variables: {
          infralist_id: infralistId,
          is_empty_signing: isEmptySigning
        },
        refetchQueries: [
          INFRALIST_QUERIES.INFRALIST,
          INFRALIST_QUERIES.DIRECTIONS_LIST,
          INFRALIST_QUERIES.DIRECTION,
          INFRALIST_QUERIES.DIRECTION_SECTIONS,
          INFRALIST_QUERIES.SECTION,
          INFRALIST_QUERIES.EQUIPMENT_LIST
        ]
      })
      .finally(() => {
        if (isEmptySigning || typeof isEmptySigning === 'undefined') {
          setLoading(false);
        } else {
          setLoadingWithoutEquipment(false);
        }
        closeSigningInfralistPopup();
        closeSigningInfralistPopupWithoutEquipment();
      })
      .catch(catchCompleteSignInfralistErrors);
  };

  return {
    completeInfralistSigning,
    completeSigningLoading: loading,
    completeSigningLoadingWithoutEquipment: loadingWithoutEquipment,
    openSigningInfralistPopupWithoutEquipment,
    openSigningInfralistPopup,
    closeSigningInfralistPopupWithoutEquipment,
    closeSigningInfralistPopup,
    isSigningInfralistPopupWithoutEquipment,
    isSigningInfralistPopup
  };
};
