import React, { useState } from 'react';
import classNames from 'classnames';
import { CircularProgress } from '@material-ui/core';
import { useContextStore } from '@proscom/prostore-react';
import queryString from 'query-string';
import { apiUrl } from '../../../../config';
import { STORE_FILE_TOKEN } from '../../../../store/names';
import { showGraphqlErrorToast } from '../../../../graphql/graphqlErrors';
import s from './EventPhotos.module.scss';

export function Photo({ document }) {
  const [previewUrl, setPreviewUrl] = useState(null);
  const tokenStore = useContextStore(STORE_FILE_TOKEN);

  const handleClick = () => {
    tokenStore
      .createRoadmapDocumentFileToken(document.connected_file.id)
      .then((token) => {
        const link = queryString.stringifyUrl({
          url: `${apiUrl}/files/roadmapDocumentFile/${document.connected_file.id}`,
          query: { token: token }
        });
        setPreviewUrl(link);
        if (previewUrl) {
          window.open(link);
        }
      })
      .catch((error) => {
        showGraphqlErrorToast(error);
      });
  };

  if (!document.connected_file || !document.connected_file.id) {
    return (
      <div className={s.Photo}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classNames('external', s.Photo)} onClick={handleClick}>
      {previewUrl ? (
        <img src={previewUrl} alt="" />
      ) : (
        <div>Нажмите для просмотра фотографии</div>
      )}
    </div>
  );
}
