import gql from 'graphql-tag';

export const FRAGMENT_ORGANIZATION_FOR_LIST = gql`
  fragment organizationForList on OrganizationType {
    id
    inn
    kpp
    name_short
    name_full
    bs_address
  }
`;

export const QUERY_GET_ORGANIZATIONS = gql`
  query organizations($input: OrganizationPageInput!) {
    organizations(input: $input) {
      list {
        ...organizationForList
      }
    }
  }

  ${FRAGMENT_ORGANIZATION_FOR_LIST}
`;
