import React from 'react';
import { Prompt } from 'react-router-dom';
import { Form as FormikForm } from 'formik';
import { useCurrentUser } from '../../../../store/useCurrentUser';
import { FormHeader } from '../../../../common/Form/Form';
import InputGroup from '../../../../common/Inputs/InputGroup';
import { NewTextInput } from '../../../../common/InputsNew/NewTextInput';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { FormFieldsInput } from './FormFieldsInput';

export const FormEditForm = ({
  onBackClick,
  status,
  isSubmitting,
  editor,
  values
}) => {
  const user = useCurrentUser();

  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <FormikForm noValidate>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting}
          editor={editor}
          isSubmitting={isSubmitting}
        />
        <InputGroup>
          <NewTextInput name="name" label="Название формы" fullWidth />
        </InputGroup>
        <FormFieldsInput name={'fields'} />
      </FormikForm>
    </>
  );
};
