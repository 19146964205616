import React, { useCallback, useMemo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Clear as ClearIcon } from '@material-ui/icons';
import { useRoadmapIndicators } from '../../../../store/roadmap/useRoadmapIndicators';
import { useMemoSuggestions } from '../../../../utils/suggestions';
import { useSubsidiaryEntitiesForFilter } from '../../../../store/subsidiaryEntity/useSubsidiaryEntities';
import { SubsidiaryEntity } from '../../../../store/subsidiaryEntity/SubsidiaryEntity';
import AutocompleteBaseInput from '../../../../common/Inputs/Selects/AutocompleteBaseInput';
import { Indicator as IndicatorsData } from '../../../../store/indicator/Indicator';
import {
  dateToBackendString,
  yearsSuggestions
} from '../../../../utils/data/date';
import { DatePickerRanger } from '../../../../common/DatePickerRanger/DatePickerRanger';
import { YearSelect } from '../../../../common/YearSelect';
import { MonthSelect } from '../../../../common/MonthSelect';
import s from '../Indicators.module.scss';

const convertValue = (year, month) => {
  return new Date(year, month, 1);
};

const getYearOptions = (start, end) => {
  const options = [];
  for (let i = start; i < end; i++) {
    options.push(i);
  }
  return options;
};

export const useIndicatorsFilter = (
  query,
  changeQuery,
  projectId,
  roadmapId,
  defaultYear,
  defaultMonth,
  regionId
) => {
  const filter = {
    subsidiary_entity_id: query.subsidiary_entity_id,
    roadmap_indicator_id: query.roadmap_indicator_id,
    regional_project_id: projectId,
    roadmap_id: roadmapId,
    year: +(query.year || defaultYear),
    month: query.month ? +query.month : null,
    status: query.status,
    se_year: +query.se_year,
    status_changed_at:
      query.statusChangedAtStartDate && query.statusChangedAtEndDate
        ? {
            start: query.statusChangedAtStartDate,
            end: query.statusChangedAtEndDate
          }
        : undefined
  };

  const setFilterValue = useCallback(
    (value, name) =>
      changeQuery({
        [name]: value,
        page: 0
      }),
    [changeQuery]
  );

  const roadmapIndicatorsFilter = useMemo(
    () => ({
      regional_project_id: projectId,
      roadmap_id: roadmapId
    }),
    [projectId, roadmapId]
  );
  const roadmapIndicatorsQuery = useRoadmapIndicators(roadmapIndicatorsFilter);
  const roadmapIndicators = roadmapIndicatorsQuery.state.data?.data;
  const roadmapIndicatorsSuggestions = useMemoSuggestions(roadmapIndicators);

  const subsidiaryEntitiesFilter = useMemo(
    () => ({
      region_ids: regionId,
      owner_roadmap_ids: roadmapId
    }),
    [regionId, roadmapId]
  );
  const entitiesQuery = useSubsidiaryEntitiesForFilter(
    subsidiaryEntitiesFilter
  );
  const entities = entitiesQuery.state.data?.list.map((entity) => ({
    ...entity,
    name_full: SubsidiaryEntity.getCompleteName(entity)
  }));
  const entitiesSuggestions = useMemoSuggestions(entities);

  const minYear = filter.se_year ? +filter.se_year : defaultYear - 1;
  const maxYear = filter.se_year ? 2024 : defaultYear + 1;

  const yearsOptions = getYearOptions(minYear, maxYear);

  const handleChange = (field) => (value) => {
    changeQuery({ [field]: value ? dateToBackendString(value) : '' });
  };

  const filterComponent = (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <AutocompleteBaseInput
        name="roadmap_indicator_id"
        label="Индикатор ДК"
        variant="standard"
        value={filter.roadmap_indicator_id}
        onChange={setFilterValue}
        hasCopyBtn={false}
        suggestions={roadmapIndicatorsSuggestions}
        suggestionsLoading={roadmapIndicatorsQuery.check.spinner}
      />
      <AutocompleteBaseInput
        name="subsidiary_entity_id"
        label="Субсидиарная сущность"
        variant="standard"
        value={filter.subsidiary_entity_id}
        onChange={setFilterValue}
        hasCopyBtn={false}
        suggestions={entitiesSuggestions}
        suggestionsLoading={entitiesQuery.check.spinner}
      />
      <AutocompleteBaseInput
        name="se_year"
        label="Год субсидиарной сущности"
        variant="standard"
        value={filter.se_year + ''}
        onChange={(val, name) => {
          if (val > filter.year) {
            changeQuery({
              year: val,
              se_year: val
            });
          } else if (!val && filter.year > defaultYear + 1) {
            changeQuery({
              year: defaultYear + 1,
              se_year: val
            });
          } else {
            setFilterValue(val, name);
          }
        }}
        hasCopyBtn={false}
        suggestions={yearsSuggestions}
      />
      <AutocompleteBaseInput
        name="status"
        label="Статус"
        variant="standard"
        value={filter.status}
        onChange={setFilterValue}
        hasCopyBtn={false}
        suggestions={IndicatorsData.filterStatusOptions}
      />
      <YearSelect
        label="Год"
        year={filter.year}
        onChange={changeQuery}
        years={yearsOptions}
        maxYear={maxYear}
        variant="standard"
        className={s.selector}
      />
      <MonthSelect
        label="Месяц"
        month={filter.month}
        onChange={changeQuery}
        variant="standard"
        className={s.selector}
        InputProps={{
          endAdornment: !!filter.month && (
            <IconButton
              onClick={(event) => {
                changeQuery({
                  month: undefined,
                  page: 0
                });
                event.stopPropagation();
              }}
            >
              <ClearIcon />
            </IconButton>
          )
        }}
      />
      <DatePickerRanger
        sinceTitle="От"
        tillTitle="До"
        label="Дата изменения статуса"
        startDate={query.statusChangedAtStartDate}
        endDate={query.statusChangedAtEndDate}
        onStartChange={handleChange('statusChangedAtStartDate')}
        onEndChange={handleChange('statusChangedAtEndDate')}
      />
    </div>
  );
  return [filter, filterComponent];
};
