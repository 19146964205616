import React, { useMemo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { FastField } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { TextInput } from '../../../../common/Inputs/TextInput';
import { EMDASH } from '../../../../utils/constants';
import { SubsidiaryMonitoringEntity } from '../../../../store/subsidiaryMonitoring/SubsidiaryMonitoringEntity';
import { FormDateInput } from '../../../../common/Inputs/DateInput';
import SubTable from '../../../../common/SubTable/SubTable';

const ValueField = ({ field, index, touched, errors }) => {
  const type = field.data_type;
  const isLink = field.is_link;
  const isString =
    type === SubsidiaryMonitoringEntity.AditionalFieldDataType.STRING;

  const valueName = SubsidiaryMonitoringEntity.getAddFieldValueName(field);

  const InputComp = useMemo(() => {
    switch (type) {
      case SubsidiaryMonitoringEntity.AditionalFieldDataType.DATE:
        return FormDateInput;
      case SubsidiaryMonitoringEntity.AditionalFieldDataType.NUMBER:
      case SubsidiaryMonitoringEntity.AditionalFieldDataType.STRING:
      default:
        return TextInput;
    }
  }, [type]);

  const valueUnit = useMemo(() => {
    if (isLink) {
      return 'ссылка';
    }

    switch (type) {
      case SubsidiaryMonitoringEntity.AditionalFieldDataType.DATE:
        return 'дата';
      case SubsidiaryMonitoringEntity.AditionalFieldDataType.NUMBER:
        return 'число';
      case SubsidiaryMonitoringEntity.AditionalFieldDataType.STRING:
      default:
        return 'текст';
    }
  }, [type, isLink]);

  const errorMessage = useMemo(() => {
    const isTouched = touched.additional_fields?.[index]?.[valueName];
    const error = errors.additional_fields?.[index]?.[valueName];
    return isTouched && error;
  }, [touched, errors, index, valueName]);

  return (
    <TableRow>
      <TableCell>
        <Tooltip title={field?.suggestion}>
          <FastField
            name={`additional_fields[${index}].${valueName}`}
            label={`Значение (${valueUnit})`}
            fullWidth
            multiline={isString}
            component={InputComp}
            errorMessage={errorMessage}
          />
        </Tooltip>
      </TableCell>
      <TableCell>
        <FastField
          name={`additional_fields[${index}].note`}
          label={`Комментарий`}
          fullWidth
          multiline={isString}
          component={InputComp}
          errorMessage={errorMessage}
        />
      </TableCell>
    </TableRow>
  );
};

export const SubsidiaryMonitoringInfoItemEditable = ({
  index,
  field,
  touched,
  errors
}) => {
  return (
    <SubTable title={field.name_full || EMDASH}>
      <ValueField
        field={field}
        index={index}
        touched={touched}
        errors={errors}
      />
    </SubTable>
  );
};
