import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import { useContextStore, useStore } from '@proscom/prostore-react';
import queryString from 'query-string';
import PageTitle from '../../common/PageTitle/PageTitle';
import { apiUrl, filesUrl } from '../../config';
import { STORE_AUTH, STORE_FILE_TOKEN } from '../../store/names';
import { useInternetSpeedInNodes } from '../../store/organization/useInternetSpeedInNodes';
import { reformatDate } from '../../utils/date';
import Loader from '../../common/Loader/Loader';
import { formatValueUnit } from '../../utils/formatNumber';
import { NBSP } from '../../utils/constants';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';
import { showGraphqlErrorToast } from '../../graphql/graphqlErrors';
import s from './MonitoringPage.module.scss';

const title = 'Мониторинг скорости интернета';

const MonitoringPage = () => {
  const [auth] = useStore(STORE_AUTH);
  const user = auth.authData && auth.authData.user;
  const tokenStore = useContextStore(STORE_FILE_TOKEN);

  const organizationId =
    (user && user.organization && user.organization.id) || undefined;

  const filter = {
    onlyLast: true,
    organization_ids: organizationId
  };
  const internetSpeedQuery = useInternetSpeedInNodes(filter);

  const internetSpeed = useMemo(() => {
    if (!internetSpeedQuery.state.data) return [];
    return internetSpeedQuery.state.data;
  }, [internetSpeedQuery.state.data]);

  const pc1Data =
    internetSpeed && internetSpeed.find((item) => item.computer_number === 1);
  const pc2Data =
    internetSpeed && internetSpeed.find((item) => item.computer_number === 2);
  const pc3Data =
    internetSpeed && internetSpeed.find((item) => item.computer_number === 3);

  const computersSpeedData = internetSpeed && [
    {
      name: 'Компьютер учащегося, расположенный в классе информатики',
      active: !!pc1Data,
      distributiveLink: filesUrl + '/distro/5507036109_550701001_1.zip',
      ...pc1Data
    },
    {
      name: 'Компьютер преподавателя в любом классе',
      active: !!pc2Data,
      distributiveLink: filesUrl + '/distro/5507036109_550701001_2.zip',
      ...pc2Data
    },
    {
      name: 'Другой компьютер',
      active: !!pc3Data,
      distributiveLink: filesUrl + '/distro/5507036109_550701001_3.zip',
      ...pc3Data
    }
  ];

  const handleDistributionDownload = (url) => {
    if (!organizationId) {
      toast.error('Только организации могут загружать данный дистрибутив');
      return;
    }
    tokenStore
      .createDistributionOneTimeToken(organizationId)
      .then((auth) => {
        const link = queryString.stringifyUrl({
          url: url,
          query: { token: auth }
        });
        window.open(link);
      })
      .catch((error) => {
        showGraphqlErrorToast(error);
      });
  };

  const renderComputers = () => {
    return computersSpeedData.map((item, index) => (
      <div className={s.PcWrapper} key={index}>
        <h2 className={s.Caption}>{item.name}</h2>
        {internetSpeedQuery.check.spinner ? (
          <Loader style={{ display: 'block' }} />
        ) : !item.active ? (
          <>
            <p>Дистрибутив не активен</p>
          </>
        ) : (
          <>
            <p>
              Скорость загрузки:{' '}
              {formatValueUnit(item.download_speed, ' мбит/с')}
            </p>
            <p>
              Скорость отдачи: {formatValueUnit(item.upload_speed, ' мбит/с')}
            </p>
            <p className={s.lastTestDate}>
              Максимальная скорость за {reformatDate(item.date)} (UTC)
            </p>
          </>
        )}
        <h3 className={s.DownloadTitle}>Скачать дистрибутив:</h3>
        <Button
          className={s.DownloadButton}
          color="primary"
          variant="contained"
          onClick={() =>
            handleDistributionDownload(
              `${apiUrl}/files/distribution?organization_id=${organizationId}&node=${
                index + 1
              }`
            )
          }
        >
          x64
        </Button>
        {NBSP}
        <Button
          className={s.DownloadButton}
          color="primary"
          variant="contained"
          onClick={() =>
            handleDistributionDownload(
              `${apiUrl}/files/distribution?organization_id=${organizationId}&node=${
                index + 1
              }&x32=true`
            )
          }
        >
          x32
        </Button>
      </div>
    ));
  };

  return (
    <>
      <HeadTitle title={title} />
      <PageTitle withBackBtn={false} title={title} />
      <div className={s.Description}>
        Данный раздел предназначен для администраторов образовательных
        организаций и позволяет скачать дистрибутивы программы, которые после
        установки на 3 персональных компьютера, находящихся в контуре
        организации, позволят Министерству просвещения Российской Федерации
        осуществлять в фоновом режиме мониторинг скорости интернет-соединения.
      </div>
      {renderComputers()}
    </>
  );
};

export default MonitoringPage;
