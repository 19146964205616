import React, { Component } from 'react';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

export class ErrorBoundary extends Component {
  state = {
    error: null
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.children !== prevProps.children) {
      this.setState({ error: null });
    }
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const { error } = this.state;
    const { children, component: ErrorComponent, renderError } = this.props;

    if (error) {
      const props = {
        text: 'Произошла ошибка',
        description: error.toString()
      };

      if (ErrorComponent) {
        return <ErrorComponent {...props} />;
      } else if (renderError) {
        return renderError(props);
      } else {
        return <ErrorMessage error={error} />;
      }
    }

    return children;
  }
}
