import React from 'react';
import { FastField } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SubTable from '../../../../common/SubTable/SubTable';
import { TextInput } from '../../../../common/Inputs/TextInput';
import { EMDASH } from '../../../../utils/constants';
import { Individual } from '../../../../store/Individual';
import { SubsidiaryEntity } from '../../../../store/subsidiaryEntity/SubsidiaryEntity';

const getEmails = (emails) => {
  if (!emails) return null;
  return emails.map((mail, index) => (
    <a key={index} className="external" href={`mailto:${mail}`}>
      {mail}
    </a>
  ));
};

export const SubsidiaryMonitoringOrganizationEditable = ({
  entity,
  touched,
  errors
}) => {
  const address = SubsidiaryEntity.combineAddress(entity.subsidiary_entity);
  const supervisor = entity.subsidiary_entity?.supervisor;

  return (
    <>
      <SubTable title={'Полное наименование субсидиарной сущности'}>
        <TableRow>
          <TableCell colSpan={2}>
            <FastField
              name={'comment_subsidiary_entity_name'}
              label="Комментарий"
              fullWidth
              component={TextInput}
              errorMessage={
                touched?.comment_subsidiary_entity_name &&
                errors?.comment_subsidiary_entity_name
              }
            />
          </TableCell>
        </TableRow>
      </SubTable>

      <SubTable
        title={'Адрес фактического местонахождения субсидиарной сущности'}
      >
        <TableRow>
          <TableCell colSpan={2}>{address || EMDASH}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>
            <FastField
              name={'comment_subsidiary_entity_address'}
              label="Комментарий"
              fullWidth
              component={TextInput}
              errorMessage={
                touched?.comment_subsidiary_entity_address &&
                errors?.comment_subsidiary_entity_address
              }
            />
          </TableCell>
        </TableRow>
      </SubTable>

      <SubTable title={'Данные руководителя организации'}>
        <TableRow>
          <TableCell>
            <FastField
              name={'organization_head_surname'}
              label="Фамилия"
              fullWidth
              component={TextInput}
              errorMessage={
                touched?.organization_head_surname &&
                errors?.organization_head_surname
              }
            />
          </TableCell>
          <TableCell>
            <FastField
              name={'comment_organization_head_surname'}
              label="Комментарий"
              fullWidth
              component={TextInput}
              errorMessage={
                touched?.comment_organization_head_surname &&
                errors?.comment_organization_head_surname
              }
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FastField
              name={'organization_head_name'}
              label="Имя"
              fullWidth
              component={TextInput}
              errorMessage={
                touched?.organization_head_name &&
                errors?.organization_head_name
              }
            />
          </TableCell>
          <TableCell>
            <FastField
              name={'comment_organization_head_name'}
              label="Комментарий"
              fullWidth
              component={TextInput}
              errorMessage={
                touched?.comment_organization_head_name &&
                errors?.comment_organization_head_name
              }
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FastField
              name={'organization_head_patronymic'}
              label="Отчество"
              fullWidth
              component={TextInput}
              errorMessage={
                touched?.organization_head_patronymic &&
                errors?.organization_head_patronymic
              }
            />
          </TableCell>
          <TableCell>
            <FastField
              name={'comment_organization_head_patronymic'}
              label="Комментарий"
              fullWidth
              component={TextInput}
              errorMessage={
                touched?.comment_organization_head_patronymic &&
                errors?.comment_organization_head_patronymic
              }
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FastField
              name={'organization_head_phone'}
              label="Действующий контактный телефон"
              fullWidth
              component={TextInput}
              inputProps={{ maxLength: 20 }}
              errorMessage={
                touched?.organization_head_phone &&
                errors?.organization_head_phone
              }
            />
          </TableCell>
          <TableCell>
            <FastField
              name={'comment_organization_head_phone'}
              label="Комментарий"
              fullWidth
              component={TextInput}
              errorMessage={
                touched?.comment_organization_head_phone &&
                errors?.comment_organization_head_phone
              }
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FastField
              name={'organization_head_email'}
              label="Электронная почта"
              fullWidth
              component={TextInput}
              errorMessage={
                touched?.organization_head_email &&
                errors?.organization_head_email
              }
            />
          </TableCell>
          <TableCell>
            <FastField
              name={'comment_organization_head_email'}
              label="Комментарий"
              fullWidth
              component={TextInput}
              errorMessage={
                touched?.comment_organization_head_email &&
                errors?.comment_organization_head_email
              }
            />
          </TableCell>
        </TableRow>
      </SubTable>

      <SubTable title={'Данные руководителя субсидиарной сущности'}>
        <TableRow>
          <TableCell>Фамилия</TableCell>
          <TableCell>{supervisor?.surname || EMDASH}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Комментарий (Фамилия)</TableCell>
          <TableCell>
            <FastField
              name={'comment_subsidiary_entity_head_surname'}
              label="Комментарий"
              fullWidth
              component={TextInput}
              errorMessage={
                touched?.comment_subsidiary_entity_head_surname &&
                errors?.comment_subsidiary_entity_head_surname
              }
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Имя</TableCell>
          <TableCell>{supervisor?.first_name || EMDASH}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Комментарий (Имя)</TableCell>
          <TableCell>
            <FastField
              name={'comment_subsidiary_entity_head_name'}
              label="Комментарий"
              fullWidth
              component={TextInput}
              errorMessage={
                touched?.comment_subsidiary_entity_head_name &&
                errors?.comment_subsidiary_entity_head_name
              }
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Отчество</TableCell>
          <TableCell>{supervisor?.patronymic || EMDASH}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Комментарий (Отчество)</TableCell>
          <TableCell>
            <FastField
              name={'comment_subsidiary_entity_head_patronymic'}
              label="Комментарий"
              fullWidth
              component={TextInput}
              errorMessage={
                touched?.comment_subsidiary_entity_head_patronymic &&
                errors?.comment_subsidiary_entity_head_patronymic
              }
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Действующий контактный телефон</TableCell>
          <TableCell>{supervisor?.phones || EMDASH}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Комментарий (Действующий контактный телефон)</TableCell>
          <TableCell>
            <FastField
              name={'comment_subsidiary_entity_head_phone'}
              label="Комментарий"
              fullWidth
              component={TextInput}
              errorMessage={
                touched?.comment_subsidiary_entity_head_phone &&
                errors?.comment_subsidiary_entity_head_phone
              }
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Электронная почта</TableCell>
          <TableCell>{getEmails(supervisor?.emails) || EMDASH}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Комментарий (Электронная почта)</TableCell>
          <TableCell>
            <FastField
              name={'comment_subsidiary_entity_head_email'}
              label="Комментарий"
              fullWidth
              component={TextInput}
              errorMessage={
                touched?.comment_subsidiary_entity_head_email &&
                errors?.comment_subsidiary_entity_head_email
              }
            />
          </TableCell>
        </TableRow>
      </SubTable>
    </>
  );
};
