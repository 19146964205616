import gql from 'graphql-tag';

export const QUERY_GET_SUPPLIER_DETERMINATIONS = gql`
  query supplierDeterminations {
    supplierDeterminations {
      id
      code
      name
      law_id
      law {
        id
        code
        name
      }
    }
  }
`;
