import gql from 'graphql-tag';
import { FRAGMENT_ROLE_PERMISSION } from '../queries/roles';

export const FRAGMENT_AUTH_RESPONSE = gql`
  fragment AuthResponse on AuthResponseType {
    accessToken
    refreshToken {
      token
      expires_at
    }
    user {
      id
      login
      name
      email
      role
      region_code_nalog
      region {
        id
        name
        code_nalog
        federal_district_id
        federal_district {
          id
          code
          name
        }
      }
      organization_id
      organization {
        id
        name_full
        name_short
      }
      permissions {
        ...permission
      }
      roles {
        id
        name
        code
        creator_user_id
        permissions {
          ...permission
        }
      }
    }
  }

  ${FRAGMENT_ROLE_PERMISSION}
`;

export const MUTATION_USE_REFRESH_TOKEN = gql`
  mutation useRefreshToken($token: String!) {
    authData: useRefreshToken(token: $token) {
      ...AuthResponse
    }
  }

  ${FRAGMENT_AUTH_RESPONSE}
`;

export const MUTATION_LOGIN_WITH_EMAIL = gql`
  mutation loginWithEmail($email: String!, $password: String!) {
    authData: loginWithEmail(emailOrLogin: $email, password: $password) {
      ...AuthResponse
    }
  }

  ${FRAGMENT_AUTH_RESPONSE}
`;

export const MUTATION_LOGOUT = gql`
  mutation logout($token: String!) {
    logout(token: $token)
  }
`;
