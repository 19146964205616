import React, { useCallback, useEffect, useMemo } from 'react';
import {
  getCurrentYear,
  subsidiaryEntitiesYearsSuggestions
} from '../../utils/data/date';
import { useRetriableRegionalProjects } from '../../store/regionalProject/useRegionalProjects';
import { useMemoSuggestions } from '../../utils/suggestions';
import { useRoadmaps } from '../../store/roadmap/useRoadmaps';
import AutocompleteBaseInput from '../../common/Inputs/Selects/AutocompleteBaseInput';
import { User } from '../../store/role/User';
import { useRegions } from '../../store/subsidiaryEntity/useSubsidiaryEntity';
import { queryLoader } from '../../common/QueryLoader';
import { approveSuggestions } from '../../utils/constants';

export const useSubsidiaryEntitiesFilter = (query, changeQuery, user) => {
  const regionsQuery = useRegions();
  const regions = regionsQuery.state.data;
  const availableRegions = regions && User.getRegions(user, regions);
  const regionsSuggestions = useMemoSuggestions(availableRegions);

  const userRegionId =
    availableRegions?.length === 1 ? availableRegions[0].id : null;
  const regionId = user?.region?.id || userRegionId;
  const year = query.year || getCurrentYear().toString();

  const filter = {
    region_ids: regionId || query.regionId || undefined,
    regional_project_ids: query.regional_project_id || undefined,
    owner_roadmap_ids: query.owner_roadmap_id || undefined,
    year,
    roadmap_implementation_years: [+year],
    is_approved:
      query.is_approved === 'true'
        ? true
        : query.is_approved === 'false'
        ? false
        : undefined
  };

  const setFilterValue = useCallback(
    (value, name) => {
      const queryObj = {
        [name]: value ?? undefined,
        page: 0
      };
      return changeQuery(queryObj);
    },
    [changeQuery]
  );

  useEffect(() => {
    setFilterValue(year, 'year');
  }, [year, setFilterValue]);

  const regionalProjectsFilter = useMemo(() => {
    return {
      implementation_years: filter.roadmap_implementation_years,
      region_ids: regions ? filter.region_ids : undefined
    };
  }, [regions, filter.region_ids, filter.roadmap_implementation_years]);
  const regionalProjectsQuery = useRetriableRegionalProjects(
    regionalProjectsFilter
  );
  const regionalProjects = regionalProjectsQuery.state.data?.data;
  const regionalProjectsSuggestions = useMemoSuggestions(regionalProjects);

  const roadmapFilter = useMemo(() => {
    return regions
      ? {
          region_id: filter.region_ids,
          regional_project_ids: filter.regional_project_ids,
          implementation_years: filter.roadmap_implementation_years
        }
      : null;
  }, [
    regions,
    filter.region_ids,
    filter.regional_project_ids,
    filter.roadmap_implementation_years
  ]);
  const roadmapsQuery = useRoadmaps(roadmapFilter);
  const roadmaps = roadmapsQuery.state.data && roadmapsQuery.state.data.data;
  const roadmapsSuggestions = useMemoSuggestions(roadmaps);

  const filterComponent = queryLoader(regionsQuery) || (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Год"
        name="year"
        suggestions={subsidiaryEntitiesYearsSuggestions}
        value={year}
        variant="standard"
        isClearable={false}
      />
      {regionsSuggestions?.length > 1 && (
        <AutocompleteBaseInput
          onChange={setFilterValue}
          hasCopyBtn={false}
          label="Регион"
          name="regionId"
          suggestions={regionsSuggestions}
          suggestionsLoading={regionsQuery.check.spinner}
          value={filter.region_ids}
          variant="standard"
        />
      )}
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Региональный проект"
        name="regional_project_id"
        suggestions={regionalProjectsSuggestions}
        suggestionsLoading={regionalProjectsQuery.check.spinner}
        value={filter.regional_project_ids}
        resetIfNotExists={true}
        variant="standard"
      />
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Дорожная карта"
        name="owner_roadmap_id"
        suggestions={roadmapsSuggestions}
        suggestionsLoading={roadmapsQuery.check.spinner}
        value={filter.owner_roadmap_ids}
        resetIfNotExists={true}
        variant="standard"
      />
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Статус утверждения"
        name="is_approved"
        suggestions={approveSuggestions}
        value={filter.is_approved}
        variant="standard"
      />
    </div>
  );
  return [filter, filterComponent];
};
