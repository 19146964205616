import React from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ButtonLine } from '../../../common/ButtonLine/ButtonLine';
import { ComponentWithTooltip } from '../../../common/ComponentWithTooltip/ComponentWithTooltip';
import Dialog from '../../../common/Dialog';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import { Popup } from '../../../common/Popup/Popup';
import { ProgressButton } from '../../../common/ProgressButton';
import {
  getGraphQLErrorInfo,
  showGraphqlErrorToast
} from '../../../graphql/graphqlErrors';
import { useCompleteSigningDirection } from '../../../store/infralist/hooks/useCompleteSigningDirection';
import { useSignAllDirectionEquipment } from '../../../store/infralist/hooks/useSignEquipmentDirection';
import { infralistRoutes } from '../../../store/infralist/routes/infralistRoutes';
import { useDirection } from '../../../store/infralist/useDirection';
import { useDirectionSections } from '../../../store/infralist/useDirectionSections';
import { useInfralist } from '../../../store/infralist/useInfralist';
import { useInfralistDirectionAccesses } from '../../../store/infralist/useInfralistUserAccesses';
import { useVisibilityCommentLink } from '../../../store/infralist/useVisibilityCommentLink';
import { useInfralistVersion } from '../../../store/infralist/versions/useInfralistVersion';
import { useDialog } from '../../../utils/hooks/useDialog';
import { CommentLink } from '../CommentLink';
import { useInfralistDirectionColumns } from '../hooks/useInfralistDirectionColumns';
import { InfralistCheckStatus } from '../InfralistCheckStatus';
import { PageTitlePanel } from '../PageTitlePanel';
import { InfralistStatus } from '../InfralistStatus';
import { InfralistTable } from '../../../common/infralist/InfralistTable/InfralistTable';
import { useSortPager } from '../../../utils/hooks/useSortPager';
import { FiltersDirectionsSections } from '../FiltersDirectionsSections';
import { InfralistDirectionBreadCrumbs } from './InfralistDirectionBreadCrumbs';

export default function InfralistDirection() {
  const { infralistId, directionId } = useParams();
  const history = useHistory();

  const directionQuery = useDirection(directionId, infralistId);
  const directionStatus = directionQuery.state.data?.status?.name;
  const directionCheckStatus = directionQuery.state.data?.check_status?.name;

  const infralistQuery = useInfralist(infralistId);
  const infralist = useInfralist(infralistId);
  const infralistStatus = infralist.state.data?.infralist_status?.name;

  const {
    showCheckStatus,
    showSignAllInDirection,
    canSignAllInDirection
  } = useInfralistDirectionAccesses({
    directionStatus,
    directionCheckStatus,
    isEditEnabledByEventStatus: infralistQuery.state.data?.editable
  });

  const { params, setSort, sorting, setFilter } = useSortPager({
    initialParams: {
      pagination: {
        page: 0,
        // выводим все сразу, постраничка не должна работать
        onePage: 100
      },
      filter: {
        direction_id: directionId,
        infralist_id: infralistId
      }
    }
  });
  const { sections, spinner: isSectionsLoading } = useDirectionSections(
    params,
    infralistId
  );

  const { canSeeCommentsIfHasComments } = useVisibilityCommentLink(
    infralistStatus
  );

  const columns = useInfralistDirectionColumns({ infralistStatus });

  const {
    signAllDirectionEquipments,
    isLoadingSignDirectionEquipments
  } = useSignAllDirectionEquipment();

  const {
    completeSigningDirection,
    isLoadingCompleteSigningDirection
  } = useCompleteSigningDirection();

  const { generatePathBasedOnVersion, isVersionMode } = useInfralistVersion();

  const catchCompleteErrors = (graphqlError) => {
    const errorInfo = getGraphQLErrorInfo(graphqlError);
    const completeError =
      'До завершения необходимо проставить статус по всем позициям оборудования';
    // Привязываемся к тексту ошибки
    const catchErrors = ['Not all equipments signing'];
    if (catchErrors.includes(errorInfo?.message)) {
      toast.error(completeError);
    } else {
      showGraphqlErrorToast(graphqlError);
    }
  };

  const {
    isOpen: isSignPopupVisible,
    open: openSignPopup,
    close: closeSignPopup
  } = useDialog();

  const {
    isOpen: isSignAllSuccessVisible,
    open: openSignAllSuccessPopup,
    close: closeSignAllSuccessPopup
  } = useDialog();

  const onCompleteSigningComplete = () => {
    history.push(generatePath(infralistRoutes.INFRALIST, { infralistId }));
  };

  return (
    <>
      <HeadTitle title={directionQuery.state.data?.name} />
      <InfralistDirectionBreadCrumbs
        roadmapName={infralistQuery.state.data?.roadmap?.name}
        infralistId={infralistId}
      />
      <PageTitlePanel
        title={directionQuery.state.data?.name}
        backUrl={`/infralist/${infralistId}`}
        description={
          showCheckStatus ? (
            <InfralistCheckStatus
              isLoading={directionQuery.check.spinner}
              status={directionCheckStatus}
            />
          ) : (
            <InfralistStatus
              isLoading={directionQuery.check.spinner}
              status={directionQuery.state.data?.status?.name}
            />
          )
        }
        actions={
          <>
            {showSignAllInDirection && (
              <ButtonLine>
                <ComponentWithTooltip
                  placement={'bottom'}
                  isTooltipExists={canSignAllInDirection}
                  width={300}
                  title={
                    'После нажатия на кнопку все неподписанные позиции оборудования приобретут статус "Подписано"'
                  }
                >
                  <ProgressButton
                    color={'secondary'}
                    variant="outlined"
                    disabled={!canSignAllInDirection}
                    onClick={() => {
                      signAllDirectionEquipments({
                        infralistId,
                        directionId
                      })
                        .then(openSignAllSuccessPopup)
                        .catch(showGraphqlErrorToast);
                    }}
                    isLoading={isLoadingSignDirectionEquipments}
                  >
                    Подписать всё
                  </ProgressButton>
                </ComponentWithTooltip>
                <ProgressButton
                  color={'secondary'}
                  variant="contained"
                  onClick={openSignPopup}
                  disabled={!canSignAllInDirection}
                >
                  Завершить подписание
                </ProgressButton>
              </ButtonLine>
            )}
          </>
        }
      />

      <Dialog
        title={
          'Вы действительно хотите завершить подписание перечня оборудования?'
        }
        open={isSignPopupVisible}
        handleAccept={() => {
          completeSigningDirection({
            infralistId,
            directionId
          })
            .then(onCompleteSigningComplete)
            .catch(catchCompleteErrors);
        }}
        handleClose={closeSignPopup}
        isLoading={isLoadingCompleteSigningDirection}
      >
        После подтверждения редактирование будет невозможно.
      </Dialog>

      <Popup
        open={isSignAllSuccessVisible}
        onClose={closeSignAllSuccessPopup}
        title={'Оборудование подписано успешно'}
      >
        Для присваивания разделам статуса "Подписано" нажмите на кнопку
        "Завершить подписание"
      </Popup>

      <FiltersDirectionsSections
        sum={directionQuery.state.data?.direction_sum}
        statuses={params?.filter?.statuses}
        checkStatuses={params?.filter?.check_statuses}
        approvementStatuses={params?.filter?.approvement_statuses}
        setFilter={setFilter}
        infralistStatus={infralistStatus}
      />

      <InfralistTable
        onLineClick={(dataItem) => {
          history.push(
            generatePathBasedOnVersion(infralistRoutes.SECTION, {
              infralistId,
              directionId,
              sectionId: dataItem.id
            })
          );
        }}
        columns={columns}
        data={sections?.list}
        isLoading={isSectionsLoading}
        getActions={(dataItem) =>
          canSeeCommentsIfHasComments && !dataItem.has_commentaries ? null : (
            <CommentLink
              href={`/infralist-comments/${infralistId}/directions/${directionId}/section/${dataItem.id}/general`}
              notRead={dataItem.has_commentaries}
            />
          )
        }
        activeSortField={sorting?.field}
        activeSortOrder={sorting?.direction}
        onChangeSort={setSort}
        readonly={isVersionMode}
        minWidth={850}
      />
    </>
  );
}
