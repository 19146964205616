import React, { useMemo } from 'react';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';

import { ListItemLink } from './ListItemLink';

import s from './Sidebar.module.scss';

const getInitialState = (label, location) => {
  if (label === 'Формы сбора') {
    return (
      location.pathname.startsWith('/rpImplementationForecast') ||
      location.pathname.startsWith('/indicatorForecasts') ||
      location.pathname.startsWith('/parentalAssistanceGuidelines')
    );
  }
  return false;
};

export const SidebarSubItemList = ({
  label,
  isShow,
  subItems,
  location,
  user,
  ...rest
}) => {
  const initialState = useMemo(() => {
    let result = false;
    for (let item of subItems) {
      let link = item.to;
      if (location.pathname.startsWith(link)) result = true;
    }
    return result;
  }, [location.pathname, subItems]);

  const [openCollectionForms, setOpenCollectionForms] = React.useState(
    initialState
  );

  if (!isShow(user)) return null;

  return (
    <>
      <ListItemLink
        primary={label}
        className={{ [s.active]: openCollectionForms }}
        onClick={() => {
          setOpenCollectionForms(!openCollectionForms);
        }}
        {...rest}
      />
      <Collapse in={openCollectionForms} timeout="auto" unmountOnExit>
        <List component="div">
          {subItems.map((subItem, iSubItems) => {
            return (
              subItem.isShow(user) && (
                <ListItemLink
                  key={iSubItems}
                  primary={subItem.label}
                  className={s.subItem}
                  {...subItem}
                />
              )
            );
          })}
        </List>
      </Collapse>
    </>
  );
};
