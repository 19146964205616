import gql from 'graphql-tag';

export const MUTATION_CREATE_EQUIPMENT = gql`
  mutation createRvpoEquipment($input: InfralistRvpoEquipmentInputType!) {
    createRvpoEquipment(input: $input) {
      id
    }
  }
`;

export const MUTATION_UPDATE_EQUIPMENT = gql`
  mutation updateRvpoEquipment($input: InfralistRvpoEquipmentInputType!) {
    updateRvpoEquipment(input: $input) {
      id
    }
  }
`;

export const MUTATION_DELETE_EQUIPMENT = gql`
  mutation deleteRvpoEquipment($id: ID!) {
    deleteRvpoEquipment(id: $id)
  }
`;

export const MUTATION_SIGN_EQUIPMENT = gql`
  mutation signingRvpoEquipment(
    $infralist_id: ID!
    $status_id: ID!
    $rvpo_equipment_id: ID!
  ) {
    signingRvpoEquipment(
      infralist_id: $infralist_id
      status_id: $status_id
      rvpo_equipment_id: $rvpo_equipment_id
    ) {
      id
      equipment_status {
        id
        name
      }
    }
  }
`;

export const MUTATION_CHECK_EQUIPMENT = gql`
  mutation checkRvpoEquipmentById($status_id: ID!, $rvpo_equipment_id: ID!) {
    checkRvpoEquipmentById(
      check_status_id: $status_id
      rvpo_equipment_id: $rvpo_equipment_id
    ) {
      id
      check_status {
        id
        name
      }
    }
  }
`;

export const MUTATION_SIGN_SECTION_EQUIPMENTS = gql`
  mutation singingAllEquipments($infralist_id: ID!, $section_id: ID!) {
    singingAllEquipments(infralist_id: $infralist_id, section_id: $section_id)
  }
`;

export const MUTATION_SIGN_DIRECTION_EQUIPMENTS = gql`
  mutation signingAllDirectionEquipments(
    $infralist_id: ID!
    $direction_id: ID!
  ) {
    signingAllDirectionEquipments(
      infralist_id: $infralist_id
      direction_id: $direction_id
    )
  }
`;

export const MUTATION_COMPLETE_SIGNING_DIRECTION = gql`
  mutation completeDirectionSigning($infralist_id: ID!, $direction_id: ID!) {
    completeDirectionSigning(
      infralist_id: $infralist_id
      direction_id: $direction_id
    ) {
      id
    }
  }
`;

export const MUTATION_COMPLETE_CHECKING = gql`
  mutation updateCheckStatus($infralist_id: ID!, $section_id: ID!) {
    updateCheckStatus(infralist_id: $infralist_id, section_id: $section_id) {
      id
    }
  }
`;

export const MUTATION_COMPLETE_SIGNING = gql`
  mutation completeSigning(
    $infralist_id: ID!
    $section_id: ID!
    $is_empty_signing: Boolean
  ) {
    completeSigning(
      infralist_id: $infralist_id
      section_id: $section_id
      is_empty_signing: $is_empty_signing
    ) {
      id
    }
  }
`;

export const MUTATION_CHECK_EQUIPMENT_SECTION = gql`
  mutation checkAllEquipments($infralist_id: ID!, $section_id: ID!) {
    checkAllEquipments(infralist_id: $infralist_id, section_id: $section_id)
  }
`;

export const MUTATION_APPROVE_ALL_EQUIPMENTS = gql`
  mutation approveAllEquipments($infralist_id: ID!, $section_id: ID!) {
    approveAllEquipments(infralist_id: $infralist_id, section_id: $section_id)
  }
`;

export const MUTATION_APPROVE_EQUIPMENT = gql`
  mutation setRvpoEquipmentApproveStatus(
    $approve_status_id: ID!
    $rvpo_equipment_id: ID!
  ) {
    setRvpoEquipmentApproveStatus(
      approve_status_id: $approve_status_id
      rvpo_equipment_id: $rvpo_equipment_id
    ) {
      id
      equipment_approvement_status {
        id
        name
      }
    }
  }
`;

export const MUTATION_COMPLETE_APPROVEMENT = gql`
  mutation completeApprovment(
    $infralist_id: ID!
    $section_id: ID!
    $is_approve: Boolean
  ) {
    completeApprovment(
      infralist_id: $infralist_id
      section_id: $section_id
      is_approve: $is_approve
    ) {
      id
    }
  }
`;

export const MUTATION_RECOMPLETE_APPROVEMENT = gql`
  mutation reCompleteApprove($infralist_id: ID!) {
    reCompleteApprove(infralist_id: $infralist_id) {
      id
    }
  }
`;

export const MUTATION_SET_EQUIPMENT_LOCK = gql`
  mutation setEditLock($input: EditLockInputType!) {
    setEditLock(input: $input) {
      id
    }
  }
`;

export const MUTATION_DELETE_EQUIPMENT_LOCK = gql`
  mutation freeEditLock($input: EditLockInputType!) {
    freeEditLock(input: $input) {
      id
    }
  }
`;

export const MUTATION_DELETE_MASS_EQUIPMENT_LOCK = gql`
  mutation freeEditLocks($input: [EditLockInputType!]!) {
    freeEditLocks(input: $input) {
      id
    }
  }
`;
