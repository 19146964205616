import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';
import PageTitle from '../../common/PageTitle/PageTitle';
import { Form as FormStore } from '../../store/form/Form';
import { FormSelectInput } from '../fillForms/form/FormSelectInput';
import { queryLoader } from '../../common/QueryLoader';
import { useParentalAssistanceGuidelinesOrganizational } from '../../store/parentalAssistanceGuideline/useParentalAssistanceGuidelinesOrganizational';
import { NewOrganizationForm } from './common/NewOrganizationForm';
import { InformationOrganizationFiled } from './common/InformationFields';
import s from './ParentalAssistanceGuidelines.module.scss';

const title = 'Перечень вопросов по апробации методических рекомендаций';

export default function OrganizationalParentalAssistanceGuidelinesPage() {
  const [
    showNewOrganizationCreateForm,
    setShowNewOrganizationCreateForm
  ] = useState(false);
  const [organizationId, setOrganizationId] = useState(undefined);

  const filter = organizationId
    ? {
        non_commercial_organization_ids: organizationId
      }
    : null;

  const parentalAssistanceGuidelinesOrganizationalQuery = useParentalAssistanceGuidelinesOrganizational(
    filter
  );
  const parentalAssistanceGuidelinesOrganizational =
    parentalAssistanceGuidelinesOrganizationalQuery.state.data;

  const guidelineId = parentalAssistanceGuidelinesOrganizational?.[0]?.id;
  if (
    !parentalAssistanceGuidelinesOrganizationalQuery.check.spinner &&
    parentalAssistanceGuidelinesOrganizational?.length === 0
  ) {
    return (
      <Redirect to={`/nkoGuidelines/create?organizationId=${organizationId}`} />
    );
  } else if (
    !parentalAssistanceGuidelinesOrganizationalQuery.check.spinner &&
    guidelineId
  ) {
    return <Redirect to={`/nkoGuidelines/${guidelineId}`} />;
  }

  return (
    <div className={s.wrapper}>
      {queryLoader(parentalAssistanceGuidelinesOrganizationalQuery) || (
        <>
          <HeadTitle title={title} />
          <PageTitle title={title} />
          {showNewOrganizationCreateForm ? (
            <NewOrganizationForm
              setShowNewOrganizationCreateForm={
                setShowNewOrganizationCreateForm
              }
              setOrganizationId={setOrganizationId}
            />
          ) : (
            <>
              <InformationOrganizationFiled />
              <FormSelectInput
                label="Выберите наименование организации"
                entity={FormStore.EntityClass.NonCommercialOrganizations}
                onChange={(value) => {
                  setOrganizationId(value);
                }}
                fullWidth
                isField
                isClearable={false}
                hasCopyBtn={false}
              />
              <button
                className={s.buttonAsLink}
                onClick={() => {
                  setShowNewOrganizationCreateForm(true);
                }}
              >
                Я не нашел свою организацию
              </button>
              <div className={s.annotation}>
                <p>
                  <b>Услуга/Услуги</b> – услуги психолого-педагогической,
                  методической и консультативной помощи, оказываемые родителям
                  (законным представителям) детей, а также гражданам, желающим
                  принять на воспитание в свои семьи детей, оставшихся без
                  попечения родителей (далее – родители) по вопросам обучения и
                  воспитания ребенка;
                </p>
                <p>
                  <b>Организации/ Службы</b> - организации, осуществляющие
                  оказание услуг психолого-педагогической, методической и
                  консультативной помощи родителям (законным представителям)
                  детей, а также гражданам, желающим принять на воспитание в
                  свои семьи детей, оставшихся без попечения родителей, –
                  победители конкурсного отбора на предоставление грантов в
                  форме субсидий из федерального бюджета юридическим лицам в
                  рамках реализации мероприятия «Государственная поддержка
                  некоммерческих организаций в целях оказания
                  психолого-педагогической, методической и консультативной
                  помощи гражданам, имеющих детей» федерального проекта
                  «Поддержка семей, имеющих детей» национального проекта
                  «Образование»;
                </p>
                <p>
                  <b>Получатели услуг</b> – родители (законные представители)
                  детей, а также граждане, желающие принять на воспитание в свои
                  семьи детей, оставшихся без попечения родителей, в том числе:
                  Иные термины используются в значениях, указанных в
                  Методических рекомендациях.
                  <p>
                    - родители детей дошкольного возраста, не посещающие
                    дошкольные образовательные организации;
                  </p>
                  <p>
                    - граждане, желающие принять на воспитание в свои семьи
                    детей, оставшихся без попечения родителей;
                  </p>
                  <p>
                    - родители, чьи дети находятся на обучении в форме семейного
                    образования;
                  </p>
                  <p>
                    - родители детей с ограниченными возможностями здоровья и
                    инвалидностью, в первую очередь раннего возраста;
                  </p>
                  <p>
                    - родители, нуждающиеся в помощи при воспитании детей,
                    имеющих различные проблемы в поведении, развитии,
                    социализации.
                  </p>
                </p>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
