import React, { useMemo } from 'react';
import BreadCrumbs from '../../../../../common/BreadCrumbs/BreadCrumbs';
import { infralistRoutes } from '../../../../../store/infralist/routes/infralistRoutes';
import { useInfralistVersion } from '../../../../../store/infralist/versions/useInfralistVersion';
import { getInfralistDirectionBreadCrumbs } from '../../InfralistDirectionBreadCrumbs';

export function getInfralistSectionBreadCrumbs({
  roadmapName,
  infralistId,
  directionId,
  directionName,
  generatePathBasedOnVersion
}) {
  return [
    ...getInfralistDirectionBreadCrumbs({
      roadmapName,
      infralistId,
      generatePathBasedOnVersion
    }),
    {
      label: directionName,
      to: generatePathBasedOnVersion(infralistRoutes.DIRECTION, {
        infralistId,
        directionId
      })
    }
  ];
}

export const InfralistSectionBreadCrumbs = ({
  roadmapName,
  infralistId,
  directionId,
  directionName
}) => {
  const { generatePathBasedOnVersion } = useInfralistVersion();
  const breadCrumbsLinks = useMemo(() => {
    return getInfralistSectionBreadCrumbs({
      roadmapName,
      infralistId,
      directionId,
      directionName,
      generatePathBasedOnVersion
    });
  }, [
    roadmapName,
    infralistId,
    directionId,
    directionName,
    generatePathBasedOnVersion
  ]);

  return <BreadCrumbs links={breadCrumbsLinks} />;
};
