import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FastField } from 'formik';
import classNames from 'classnames';
import SubTable from '../../../../common/SubTable/SubTable';
import { TextInput } from '../../../../common/Inputs/TextInput';
import { validateNumeric } from '../../../../utils/number';
import { useStaffScheduleGuidelines } from '../../../../store/roadmap/useStaffScheduleGuidelines';
import { queryLoader } from '../../../../common/QueryLoader';
import { Signal } from '../../../../common/Inputs/Signals';
import s from './StaffingTableField.module.scss';

const guidelineMap = (subsidiaryEntityId) => (staffScheduleGuideline) => ({
  occupation: '',
  amount_of_staff: '',
  staff_schedule_guideline_id: staffScheduleGuideline.id,
  subsidiary_entity_id: subsidiaryEntityId,
  stuff_schedule_guideline: staffScheduleGuideline
});

export const StaffingTableField = ({
  staffSchedules,
  name,
  subsidiaryEntityId,
  entityTypeId,
  roadmapId,
  errors,
  setValue
}) => {
  const filter = useMemo(
    () => ({
      roadmap_ids: roadmapId,
      se_type_ids: entityTypeId
    }),
    [roadmapId, entityTypeId]
  );

  const staffScheduleGuidelinesQuery = useStaffScheduleGuidelines(filter);
  const staffScheduleGuidelines = staffScheduleGuidelinesQuery.state.data;

  const latestRef = useRef(staffSchedules);
  latestRef.current = staffSchedules;

  const setStaffSchedulesRef = useRef(setValue);
  setStaffSchedulesRef.current = setValue;

  useEffect(() => {
    const newStaffSchedules = latestRef.current;
    if (staffScheduleGuidelines?.length > 0) {
      if (!newStaffSchedules[subsidiaryEntityId]) {
        newStaffSchedules[subsidiaryEntityId] = staffScheduleGuidelines.map(
          guidelineMap(subsidiaryEntityId)
        );
      }
      const newGuidelines = staffScheduleGuidelines.filter(
        (gl) =>
          !newStaffSchedules[subsidiaryEntityId].some(
            (staffSchedule) =>
              staffSchedule.staff_schedule_guideline_id === gl.id
          )
      );
      if (newGuidelines.length > 0) {
        newStaffSchedules[subsidiaryEntityId].concat(
          newGuidelines.map(guidelineMap(subsidiaryEntityId))
        );
      }
    }

    setStaffSchedulesRef.current(newStaffSchedules);
  }, [staffScheduleGuidelines, subsidiaryEntityId]);

  return (
    <>
      {queryLoader(staffScheduleGuidelinesQuery) ||
        (!(staffScheduleGuidelines?.length > 0) && (
          <div>Нет методических рекомендаций</div>
        )) || (
          <SubTable cols={6} editVariant>
            <TableRow>
              <TableCell
                className={classNames(s.call, s.callCaption)}
                colSpan={4}
              >
                Методические рекомендации
              </TableCell>
              <TableCell
                className={classNames(s.call, s.callCaption)}
                colSpan={2}
              >
                Факт
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classNames(s.call, s.callCaption)}>
                Категория персонала
              </TableCell>
              <TableCell className={classNames(s.call, s.callCaption)}>
                Должность
              </TableCell>
              <TableCell className={classNames(s.call, s.callCaption)}>
                Количество штатных единиц
              </TableCell>
              <TableCell className={classNames(s.call, s.callCaption)}>
                Комментарий
              </TableCell>
              <TableCell className={classNames(s.call, s.callCaption)}>
                Должность из штатного расписания
              </TableCell>
              <TableCell className={classNames(s.call, s.callCaption)}>
                Количество штатных единиц
              </TableCell>
            </TableRow>
            {staffSchedules[subsidiaryEntityId]?.map((data, iData) => {
              return (
                <Fragment key={iData}>
                  <TableRow>
                    <TableCell className={s.call} rowSpan={2}>
                      {data.stuff_schedule_guideline.staff_category}
                    </TableCell>
                    <TableCell className={s.call} rowSpan={2}>
                      {data.stuff_schedule_guideline.occupation}
                    </TableCell>
                    <TableCell className={s.call} rowSpan={2}>
                      {data.stuff_schedule_guideline.amount_of_staff_string ||
                        data.stuff_schedule_guideline.amount_of_staff}
                    </TableCell>
                    <TableCell className={s.call} rowSpan={2}>
                      {data.stuff_schedule_guideline.comment}
                    </TableCell>
                    <TableCell className={classNames(s.call, s.dataCell)}>
                      <FastField
                        name={`${name}[${subsidiaryEntityId}][${iData}].occupation`}
                        multiline
                        label="Должность"
                        component={TextInput}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell className={classNames(s.call, s.dataCell)}>
                      <FastField
                        name={`${name}[${subsidiaryEntityId}][${iData}].amount_of_staff`}
                        label="Штат"
                        validate={validateNumeric}
                        component={TextInput}
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} className={s.errorCell}>
                      {errors[iData] && (
                        <Signal>Имеется отклонение от рекомендации</Signal>
                      )}
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })}
          </SubTable>
        )}
    </>
  );
};
