import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormHelperText } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextBaseInput from './TextBaseInput';
import s from './Inputs.module.scss';

export const TableSelectInput = (props) => {
  const { value, onChange, options, error, style = {} } = props;

  const selectedValue = useMemo(
    () => options?.find((option) => option.value === value),
    [options, value]
  );

  return (
    <FormControl error={Boolean(error)} className={s.TableSelectInput}>
      <Select
        value={value || ''}
        style={{ width: '100%', ...style }}
        onChange={(e) => onChange(e.target.value)}
        title={selectedValue?.label}
      >
        {options?.map((type) => (
          <MenuItem key={type.value} value={type.value} title={type.label}>
            {type.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

const SelectInput = ({ options, ...rest }) => {
  return (
    <TextBaseInput
      {...rest}
      select
      SelectProps={{
        inputProps: {
          classes: {
            root: s.TextInput
          }
        },
        IconComponent: KeyboardArrowDownIcon,
        classes: {
          icon: s.SelectIcon
        }
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextBaseInput>
  );
};

SelectInput.propTypes = {
  field: PropTypes.shape(),
  form: PropTypes.shape(),
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};

export default SelectInput;
