import React from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { removeLastPathItem } from '../../../../utils/url';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import { STORE_MENTORING_COVERAGE } from '../../../../store/names';
import { handleFormSubmission } from '../../../../utils/data/form';
import { MentoringCoverageSchema } from '../../../../utils/data/validation';
import { MentoringCoverage } from '../../../../store/mentoringCoverage/MentoringCoverage';
import RegionMentoringCoverageEditForm from '../edit/RegionMentoringCoverageEditForm';
import { useRegions } from '../../../../store/subsidiaryEntity/useSubsidiaryEntity';
import { useUrlQuery } from '../../../../common/useUrlQuery';
import { useCurrentUser } from '../../../../store/useCurrentUser';

const title = 'Охват наставничеством';

export default function RegionMentoringCoverageCreate({ history, match }) {
  const user = useCurrentUser();
  const regionId = user?.region?.id;

  const mentoringCoverageStore = useContextStore(STORE_MENTORING_COVERAGE);
  const backLink = removeLastPathItem(match.url);

  const regionQuery = useRegions([regionId]);
  const region = regionQuery.state.data;

  const initialValue = {
    region_id: region?.[0]?.id,
    percentage: '',
    comment: ''
  };

  return (
    <>
      <HeadTitle type={title} pageType={HeadTitle.PageType.edit} />
      <h2>
        Данные субъекта о доле обучающихся, вовлечённых в различные формы
        наставничества
      </h2>
      {queryLoaderWithExistenceCheck(regionQuery) || (
        <Formik
          validationSchema={MentoringCoverageSchema}
          initialValues={initialValue}
          onSubmit={(values, actions) => {
            const form = MentoringCoverage.fromMentoringCoverageForm(values);
            handleFormSubmission(
              mentoringCoverageStore.createMentoringCoverage(form),
              actions,
              (result) =>
                history.goBack(
                  MentoringCoverage.getMentoringCoverageUrl(result)
                )
            );
          }}
          render={(formikProps) => (
            <RegionMentoringCoverageEditForm
              {...formikProps}
              onBackClick={() => history.goBack(backLink)}
              region={region[0]}
            />
          )}
        />
      )}
    </>
  );
}
