import React, { useMemo } from 'react';
import { eval as expEval, parse as expParse } from 'expression-eval';
import isNaN from 'lodash-es/isNaN';
import isFinite from 'lodash-es/isFinite';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Field } from 'formik';
import { EMDASH } from '../../../../utils/constants';
import { IndicatorFactValueField } from '../IndicatorFactValueField';
import TableInput from '../../../../common/Table/TableInput';
import SubTable from '../../../../common/SubTable/SubTable';
import { isNumberLike, tryNumberLike } from '../../../../utils/helpers';
import { toFixed, validateNumeric } from '../../../../utils/number';
import { TableTextInput } from '../../../../common/Inputs/TextInput';
import { ContestDocsForSSMonitoringEntity } from '../../../../store/contestDocsForSSMonitoring/ContestDocsForSSMonitoringEntity';

const formulaCleanupRegexp = new RegExp(/[[\]]/, 'g');

export const ContestDocsIndicatorEditable = ({
  index,
  indicator,
  values,
  touched,
  errors
}) => {
  const valuesIndicator = useMemo(() => {
    return values.indicators?.find(
      (v) => v.id === indicator?.roadmap_indicator?.id
    );
  }, [indicator, values]);

  const indicatorUnit = indicator?.roadmap_indicator?.unit?.name_local;
  const value = valuesIndicator?.value;
  const previous_value = indicator?.previous_value;
  const precision = indicator?.roadmap_indicator?.precision;

  const fillType = indicator?.roadmap_indicator?.fill_type;
  const isCalcIndicator =
    fillType === ContestDocsForSSMonitoringEntity.IndicatorFillType.CALCULATED;

  const formula = indicator?.roadmap_indicator?.formula;

  const components = valuesIndicator?.components;
  const hasComponents = !!components && components.length > 0;

  const valueByComponents = useMemo(() => {
    if (!isCalcIndicator || !formula || !components || !components.length)
      return null;

    const compValues = {};

    components.forEach((comp) => {
      compValues[comp.code] = tryNumberLike(comp.value, null, true);
    });

    if (Object.values(compValues).some((v) => v === null)) return null;

    const cleanFormula = formula.replace(formulaCleanupRegexp, '');
    const parsedFormula = expParse(cleanFormula);
    const value = expEval(parsedFormula, compValues);
    if (isNaN(value) || !isFinite(value)) return toFixed(0, precision);
    return tryNumberLike(toFixed(value, precision), null);
  }, [isCalcIndicator, formula, precision, components]);

  const currValue = isCalcIndicator ? valueByComponents : value;

  const indicatorValuesDiff = useMemo(() => {
    if (!isNumberLike(currValue)) return null;
    return toFixed(currValue - (previous_value || 0), precision);
  }, [currValue, previous_value, precision]);

  const indicatorTouched = touched.indicators?.[index];
  const indicatorErrors = errors.indicators?.[index];
  const componentsError =
    indicatorTouched?.components?.length > 0 &&
    indicatorErrors?.components?.length > 0;

  const showPreviousValue = !!values.showPreviousValue;
  const showValueDiff = !!values.showValueDiff;

  const filteredComponentsColumns = useMemo(() => {
    if (!hasComponents) return [];

    const componentNameColWidth = 30;
    const filtersArray = [showPreviousValue, showValueDiff];
    const filtersDisabledAmount = filtersArray.filter((f) => !f).length;

    const cols = [
      {
        field: 'name_full',
        width: componentNameColWidth + '%',
        title: 'Компонент',
        editComponent: ({ value }) => value || EMDASH
      },
      {
        field: 'unit',
        title: 'Единица измерения',
        editComponent: ({ value }) => value || EMDASH
      },
      {
        field: 'previous_value',
        title:
          'Фактическое значение предыдущего периода компонента минимального показателя',
        render: ({ previous_value }) => {
          return tryNumberLike(previous_value, EMDASH);
        },
        editComponent: ({ rowData }) => {
          return tryNumberLike(rowData.previous_value, EMDASH);
        }
      },
      {
        field: 'value',
        title: 'Фактическое значение компонента минимального показателя',
        editComponent: TableTextInput,
        render: ({ value }) => {
          return tryNumberLike(value, EMDASH);
        },
        validate: ({ value, precision }) => {
          let error = validateNumeric(value);

          if (!error && isNumberLike(precision)) {
            const digits = value.toString().split('.')[1];
            if ((digits?.length || 0) !== precision) {
              error = `Количество знаков после запятой должно быть равным ${precision}`;
            }
          }

          return (value && error) || true;
        }
      },
      {
        field: 'values_diff',
        title:
          'Отклонение фактического значения от значения предыдущего периода',
        render: ({ value, previous_value, precision }) => {
          if (!isNumberLike(value)) return EMDASH;
          return toFixed(value - (previous_value || 0), precision);
        },
        editComponent: ({ rowData }) => {
          const { value, previous_value, precision } = rowData;

          return isNumberLike(value)
            ? toFixed(value - (previous_value || 0), precision)
            : EMDASH;
        }
      }
    ].filter((col) => {
      if (col.field === 'previous_value' && !showPreviousValue) {
        return false;
      }
      if (col.field === 'values_diff' && !showValueDiff) {
        return false;
      }
      return true;
    });

    const componentNameCol = cols.find((col) => col.field === 'name_full');

    componentNameCol.width =
      Math.max(
        componentNameColWidth + 10 * filtersDisabledAmount,
        componentNameColWidth
      ) + '%';

    return cols;
  }, [hasComponents, showPreviousValue, showValueDiff]);

  const componentsCols = useMemo(() => {
    return filteredComponentsColumns.map((col) => {
      if (col.field === 'value') {
        col.required = componentsError;
      }
      return col;
    });
  }, [filteredComponentsColumns, componentsError]);

  return (
    <SubTable
      supTitle={'Минимальный показатель:'}
      title={indicator.roadmap_indicator.name_full}
    >
      <TableRow>
        <TableCell>Единица измерения</TableCell>
        <TableCell>{indicatorUnit || EMDASH}</TableCell>
      </TableRow>

      {showPreviousValue && (
        <TableRow>
          <TableCell>Фактическое значение предыдущего периода</TableCell>
          <TableCell>{previous_value ?? EMDASH}</TableCell>
        </TableRow>
      )}

      <TableRow>
        <TableCell>Фактическое значение</TableCell>
        <TableCell>
          <IndicatorFactValueField
            name={`indicators[${index}].value`}
            value={valueByComponents}
            calculated={isCalcIndicator}
            fullWidth
          />
        </TableCell>
      </TableRow>

      {showValueDiff && (
        <TableRow>
          <TableCell>Отклонение фактического значения</TableCell>
          <TableCell>{indicatorValuesDiff ?? EMDASH}</TableCell>
        </TableRow>
      )}

      {hasComponents && (
        <TableRow>
          <TableCell colSpan={2}>
            <Field
              name={`indicators[${index}].components`}
              tableName="Компоненты расчета минимального показателя"
              columns={componentsCols}
              component={TableInput}
              canAdd={false}
              // canUpdate={false}
              canDelete={false}
              errorMessage={
                componentsError && 'Компоненты заполнены некорректно'
              }
            />
          </TableCell>
        </TableRow>
      )}

      <TableRow>
        <TableCell>Примечание</TableCell>
        <TableCell>{indicator?.roadmap_indicator?.note || EMDASH}</TableCell>
      </TableRow>
    </SubTable>
  );
};
