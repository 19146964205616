import { skipIfNull } from '@proscom/prostore';
import React, { useMemo } from 'react';
import { useRetriableGraphql } from '../../../common/useRetriableGraphqlVars';
import { QUERY_INFRALIST_VERSION_LIST } from '../../../graphql/queries/infralist';
import { reformatDatetime } from '../../../utils/date';
import { infralistStatusService } from '../infralistStatusService';

const infralistVersionListQueryOptions = {
  query: QUERY_INFRALIST_VERSION_LIST,
  mapData: (result) =>
    result.infralistVersions?.map((versionItem) => ({
      ...versionItem,
      infralist_status: {
        name:
          versionItem.infralist_status?.name ??
          infralistStatusService.defaultStatus
      }
    })),
  skipQuery: skipIfNull(null)
};

export const useInfralistVersionList = ({ infralistId }) => {
  const versionsQuery = useRetriableGraphql({
    queryOptions: infralistVersionListQueryOptions,
    variables: {
      filter: {
        infralist_id: infralistId
      }
    }
  });

  const versionListOptions = useMemo(() => {
    return (
      versionsQuery.state.data?.map((versionItem) => ({
        label: (
          <>
            {reformatDatetime(versionItem.created_at)}&nbsp;
            {infralistStatusService.getColoredName(
              versionItem.infralist_status.name
            )}
          </>
        ),
        value: versionItem.id
      })) ?? []
    );
  }, [versionsQuery.state.data]);

  return {
    versionListOptions
  };
};
