import gql from 'graphql-tag';
import { FRAGMENT_REGIONAL_ROADMAP_EVENT } from '../queries/roadmapEvents';

export const MUTATION_UPDATE_OR_CREATE_REGIONAL_ROADMAP_EVENT = gql`
  mutation updateOrCreateRegionalRoadmapEvent(
    $input: RegionalRoadmapEventInput!
  ) {
    result: updateOrCreateRegionalRoadmapEvent(eventInput: $input) {
      ...RegionalRoadmapEvent
    }
  }

  ${FRAGMENT_REGIONAL_ROADMAP_EVENT}
`;
