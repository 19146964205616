import React from 'react';
import classNames from 'classnames';
import { ErrorBoundary } from '../common/ErrorBoundary/ErrorBoundary';
import ErrorPage from '../common/ErrorBoundary/ErrorPage';
import { InfralistHeader } from '../common/infralist/InfralistHeader/InfralistHeader';
import Drawer from '../common/Sidebar/Sidebar';
import Header from '../common/Header/Header';
import { ReactComponent as DrawerLogo } from '../assets/icons/logo-color.svg';
import s from './DefaultLayout.module.scss';

export const DefaultLayout = (Comp) => (props) => {
  return (
    <div id="wrapper-offset">
      <ErrorBoundary component={ErrorPage}>
        <div className={s.DefaultLayout}>
          <Drawer location={props.location} />
          <Header />
          <main className={s.Main}>
            <ErrorBoundary component={ErrorPage}>
              <Comp {...props} />
            </ErrorBoundary>
          </main>
        </div>
      </ErrorBoundary>
    </div>
  );
};

export const LoginLayout = (Comp) => (props) => {
  return (
    <div id="wrapper-offset">
      <ErrorBoundary component={ErrorPage}>
        <div className={s.LoginLayout}>
          <div className={s.Logo}>
            <DrawerLogo className={s.Icon} />
            <p className={s.Text}>Подсистема сбора и консолидации данных</p>
          </div>
          <Comp {...props} />
        </div>
      </ErrorBoundary>
    </div>
  );
};

export const InfralistLayout = ({
  versionsPanelIsVisible,
  setVersionsPanelVisible,
  children
}) => {
  return (
    <div id="wrapper-offset">
      <ErrorBoundary component={ErrorPage}>
        <div className={s.DefaultLayout}>
          <InfralistHeader
            versionsPanelIsVisible={versionsPanelIsVisible}
            setVersionsPanelVisible={setVersionsPanelVisible}
          />
          <main className={classNames(s.Main, s.InfralistMain)}>
            <ErrorBoundary component={ErrorPage}>{children}</ErrorBoundary>
          </main>
        </div>
      </ErrorBoundary>
    </div>
  );
};
