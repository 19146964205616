import React, { useCallback, useEffect } from 'react';
import { useStore } from '@proscom/prostore-react';
import { STORE_AUTH } from '../../store/names';
import { User } from '../../store/role/User';

export const useHasPermission = () => {
  const [auth] = useStore(STORE_AUTH);
  const user = User.getUser(auth);

  const checkPermissions = useCallback(
    (permissions) => {
      return User.hasPermission(user, ...permissions);
    },
    [user]
  );

  const checkObjectPermissions = useCallback(
    (permission, object_id) => {
      return User.hasObjectPermission(user, permission, object_id);
    },
    [user]
  );

  return {
    checkPermissions,
    checkObjectPermissions
  };
};
