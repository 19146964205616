import React from 'react';
import classNames from 'classnames';
import { getName } from '../../../../utils/data/label';
import { SubsidiaryEntity } from '../../../../store/subsidiaryEntity/SubsidiaryEntity';
import s from './EventPhotos.module.scss';

const undeterminedTypeInfo =
  'Тип субсидиарной сущности не определён. Перед загрузкой' +
  ' фотографий необходимо задать тип субсидиарной сущности в' +
  ' реестре субсидиарных сущностей';

export function SubsidiaryEntityTitle({ entity, entityType }) {
  if (!entity) {
    return <div>Фотографии, не привязанные к субсидиарным сущностям</div>;
  }

  const entityName = getName(entity);
  const organizationName = SubsidiaryEntity.getBaseOrganizationName(
    entity,
    true
  );
  const typeName = entityType ? `(${entityType.name})` : undeterminedTypeInfo;

  return (
    <div>
      <div className={s.Entity__title}>{entityName}</div>
      <div className={s.Entity__subtitle}>{organizationName}</div>
      <div className={classNames(s.Entity__subtitle, s.Entity__type)}>
        {typeName}
      </div>
    </div>
  );
}
