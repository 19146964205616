import React from 'react';
import { useRegionalProject } from '../../../../store/regionalProject/useRegionalProject';
import { useRoadmapEvent } from '../../../../store/roadmap/useRoadmapEvent';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import RegionalEventEditPage from './RegionalEventEditPage';

export default function ProjectToRegionalEventEdit({ match, history }) {
  const { projectId, roadmapId, eventId } = match.params;

  const projectQuery = useRegionalProject(projectId);
  const project = projectQuery.state.data;

  const regionId = project?.region_id;

  const roadmapEventQuery = useRoadmapEvent(eventId, regionId, true);
  const event = roadmapEventQuery.state.data || {};
  const regionalEvent = event?.regional_roadmap_events?.[0];

  return (
    queryLoaderWithExistenceCheck(roadmapEventQuery) || (
      <RegionalEventEditPage
        event={event}
        regionalEvent={regionalEvent}
        currentUrl={match.url}
        history={history}
        projectId={projectId}
        eventId={eventId}
        roadmapId={roadmapId}
      />
    )
  );
}
