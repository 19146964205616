import { INFRALIST_STATUSES } from './infralistStatusService';
import { useInfralistUserAccesses } from './useInfralistUserAccesses';

export const useVisibilityCommentLink = (infralistStatus) => {
  const { isRVPO1, isRVPO2, isFPO1, isFPO2 } = useInfralistUserAccesses();

  const commentsAlwaysVisible =
    (isRVPO2 && infralistStatus === INFRALIST_STATUSES.ON_SIGNING) ||
    (isFPO1 &&
      [INFRALIST_STATUSES.ON_CHECKING, INFRALIST_STATUSES.ON_APPROVAL].includes(
        infralistStatus
      )) ||
    isFPO2;

  return {
    commentsAlwaysVisible,
    canSeeCommentsIfHasComments: isRVPO1
  };
};
