import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { FastField, Field, Form } from 'formik';

import TableRow from '@material-ui/core/TableRow';

import TableCell from '@material-ui/core/TableCell';

import { FormHeader } from '../../../../common/Form/Form';
import InputGroup from '../../../../common/Inputs/InputGroup';
import { TextInput } from '../../../../common/Inputs/TextInput';
import SubTable from '../../../../common/SubTable/SubTable';

import { useRoles } from '../../../../store/role/useRoles';
import { useCurrentUser } from '../../../../store/useCurrentUser';
import { User } from '../../../../store/role/User';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { NewAutocompleteInput } from '../../../../common/InputsNew/NewAutocompleteInput';
import { OrganizationInput } from '../../../subsidiaryEntities/subsidiaryEntity/edit/OrganizationInput';
import { useRegions } from '../../../../store/subsidiaryEntity/useSubsidiaryEntity';
import { useMemoSuggestions } from '../../../../utils/suggestions';
import { PermissionsEditField } from '../../../../common/Form/Permissions/PermissionsEditField';

export const UserEditForm = ({
  onBackClick,
  status,
  isSubmitting,
  editor,
  touched,
  errors,
  values,
  setFieldValue
}) => {
  const user = useCurrentUser();
  const filter = {
    creator_user_id: !User.isAdmin(user) ? user?.id : undefined
  };
  const rolesQuery = useRoles(filter);
  const roles = rolesQuery.state.data?.list;

  const roleOptions = roles?.map((role) => ({
    value: role.id,
    label: role.name
  }));

  const noRole = !roles?.length;

  const isAdmin = User.isAdmin(user);

  const canEditBaseRole = isAdmin;

  const regionsQuery = useRegions();
  const regions = regionsQuery.state.data;
  const regionsSuggestions = useMemoSuggestions(regions);

  useEffect(() => {
    if (values.region) {
      const currentRegion = regions?.find(
        (region) => region.id === values.region?.id
      );
      setFieldValue('region_code_nalog', currentRegion?.code_nalog);
    }
  }, [setFieldValue, values.region, regions]);

  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <Form noValidate>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting}
          editor={editor}
          isSubmitting={isSubmitting}
        />
        <InputGroup title="Общие сведения">
          <FastField
            name="name"
            label="Имя пользователя"
            component={TextInput}
            errorMessage={touched.name && errors.name}
            fullWidth
          />
          <FastField
            name="email"
            label="Email"
            component={TextInput}
            errorMessage={touched.email && errors.email}
            fullWidth
          />
          <FastField
            name="login"
            label="Логин"
            component={TextInput}
            errorMessage={touched.login && errors.login}
            fullWidth
          />
        </InputGroup>
        <InputGroup>
          {canEditBaseRole && (
            <NewAutocompleteInput
              name="role"
              label="Базовая роль"
              suggestions={User.role.getOptions()}
              fullWidth
              isClearable={false}
              hasCopyBtn={false}
            />
          )}
          <NewAutocompleteInput
            name="roles[0].id"
            label="Роль"
            errorMessage={noRole && 'Необходимо задать роль'}
            suggestions={roleOptions}
            fullWidth
            isClearable={false}
            hasCopyBtn={false}
            isDisabled={noRole}
          />
        </InputGroup>
        {isAdmin && (
          <InputGroup>
            <Field
              label="Организация"
              name="organization_id"
              component={OrganizationInput}
              errorMessage={touched.organization_id && errors.organization_id}
              regionId={values.region_id}
              defaultValue={values.organization}
              required
            />
            <NewAutocompleteInput
              label="Регион"
              name="region.id"
              hasCopyBtn={false}
              suggestions={regionsSuggestions}
              suggestionsLoading={regionsQuery.check.spinner}
              fullWidth
            />
          </InputGroup>
        )}
        <PermissionsEditField
          name="permissions"
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          roles={roles}
        />
        <SubTable title="Пароль" cols={3} editVariant>
          <TableRow>
            <TableCell>
              <FastField
                name="new_password"
                label="Новый пароль"
                component={TextInput}
                errorMessage={touched.new_password && errors.new_password}
                type="password"
                autocomplete="new-password"
                fullWidth
              />
            </TableCell>
            <TableCell>
              <FastField
                name="repeat_password"
                label="Повторите пароль"
                component={TextInput}
                errorMessage={touched.repeat_password && errors.repeat_password}
                type="password"
                autocomplete="new-password"
                fullWidth
              />
            </TableCell>
          </TableRow>
        </SubTable>
      </Form>
    </>
  );
};
