import React from 'react';
import PropTypes from 'prop-types';
import s from './PageActionsBar.module.scss';

export const PageActionsBar = ({ editButton, deleteButton, actions }) => {
  return (
    <div className={s.PageActionsBar}>
      {actions}
      {editButton}
      {deleteButton}
    </div>
  );
};

PageActionsBar.propTypes = {
  editButton: PropTypes.node,
  deleteButton: PropTypes.node,
  actions: PropTypes.node
};
