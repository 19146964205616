import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useState } from 'react';
import { MUTATION_COMPLETE_CHECKING } from '../../../graphql/mutations/equipment';
import { INFRALIST_QUERIES } from '../infralistQueries';

export const useCompleteChecking = () => {
  const client = useContextApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  const completeChecking = ({ infralistId, sectionId }) => {
    setIsLoading(true);
    return client
      .mutate({
        mutation: MUTATION_COMPLETE_CHECKING,
        variables: {
          infralist_id: infralistId,
          section_id: sectionId
        },
        update: (store) => {
          store.reset();
        },
        refetchQueries: [INFRALIST_QUERIES.SECTION]
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoadingCompleteChecking: isLoading,
    completeChecking
  };
};
