import React, { useEffect, useMemo, useRef } from 'react';
import { Prompt } from 'react-router-dom';
import { useStoreState } from '@proscom/prostore-react';
import { FastField, Field, Form } from 'formik';
import { addWeeks, format } from 'date-fns';
import { get, sumBy } from 'lodash-es';
import { TableCell, TableRow } from '@material-ui/core';
import SubTable from '../../../../common/SubTable/SubTable';
import { TableSelectWrapper } from '../../../../common/Inputs/TableSelectWrapper';
import { EMDASH, unitRuble } from '../../../../utils/constants';
import useScrollToError from '../../../../common/Form/useScrollToError';
import { mergeSignals, parseSignals } from '../../../../utils/data/signals';
import { getAuthRegionId } from '../../../../store/AuthStore';
import {
  addWeekToDateString,
  getCurrentYear,
  parseApiDate,
  removeTimezoneFromDate,
  yearsSuggestions
} from '../../../../utils/data/date';
import TableInput from '../../../../common/Table/TableInput';
import { Purchase } from '../../../../store/purchase/Purchase';
import CurrencyDisplayField from '../../../../common/Inputs/CurrencyDisplayField';
import {
  useMemoSuggestions,
  useMemoSuggestionsFull
} from '../../../../utils/suggestions';
import {
  CurrencyInput,
  TableCurrencyInput,
  TableTextInput,
  TextInput
} from '../../../../common/Inputs/TextInput';
import {
  FormDateInput,
  TableDateInput,
  TableMonthInput
} from '../../../../common/Inputs/DateInput';
import InputGroup from '../../../../common/Inputs/InputGroup';
import { FormHeader } from '../../../../common/Form/Form';
import { useRetriableGrbses } from '../../../../store/purchase/useGrbses';
import { useRetriableExpensesDirections } from '../../../../store/expensesDirection/useExpensesDirections';
import { useRetriableRegionalProjects } from '../../../../store/regionalProject/useRegionalProjects';
import { STORE_AUTH } from '../../../../store/names';
import { formatValueUnit } from '../../../../utils/formatNumber';
import {
  DATE_FORMAT_MONTH,
  DATE_FORMAT_RUSSIAN,
  lnFormat
} from '../../../../utils/date';
import {
  formatCurrency,
  renderPlanFactDiff,
  validateNumeric
} from '../../../../utils/number';
import {
  FORM_STATUS_SUBMITTED,
  formUnloadHandler
} from '../../../../utils/data/form';
import { NewAutocompleteInput } from '../../../../common/InputsNew/NewAutocompleteInput';
import { CustomFastField } from '../../../../common/Inputs/CustomFastField';
import { SupplierDeterminationInput } from './SupplierDeterminationInput';
import { CustomerIdInput } from './CustomerIdInput';
import { PurchaseComponentsField } from './PurchaseComponentsField';
import { useServerSignal } from './useServerSignal';
import { PurchaseInfralistEditField } from './PurchaseInfralistEditField';
import tableStyle from '../../../../common/Table/Table.module.scss';
import s from './PurchaseEditForm.module.scss';

const TableDateCell = (field) => (rowData) => {
  const d = removeTimezoneFromDate(parseApiDate(rowData[field]));
  return d && format(d, DATE_FORMAT_RUSSIAN);
};

const TableMonthCell = (field) => (rowData) => {
  const d = removeTimezoneFromDate(parseApiDate(rowData[field]));
  return d && lnFormat(d, DATE_FORMAT_MONTH);
};

const purchaseChangesColumns = [
  {
    editComponent: TableDateInput,
    field: 'date_start',
    headerStyle: {
      width: '30%'
    },
    render: TableDateCell('date_start'),
    required: true,
    title: 'Дата начала перенесенного срока',
    type: 'date'
  },
  {
    editComponent: ({ rowData, value, onChange }) => {
      const { date_start } = rowData;
      const newDate = addWeekToDateString(date_start);
      const newDateWithoutTimezone = removeTimezoneFromDate(newDate);
      let text = EMDASH;
      if (newDate) {
        const dateStr = format(newDateWithoutTimezone, 'dd.MM.yyyy');
        if (value !== dateStr) onChange(dateStr);
        text = dateStr;
      }
      return (
        <p
          style={{
            fontSize: '1rem',
            lineHeight: '1.185rem',
            padding: '6px 0'
          }}
        >
          {text}
        </p>
      );
    },
    field: 'date_end',
    headerStyle: {
      width: '25%'
    },
    render: ({ date_start: row }) => {
      const d = removeTimezoneFromDate(parseApiDate(row));
      return d && format(addWeeks(d, 1), 'dd.MM.yyyy');
    },
    required: true,
    title: 'Дата окончания перенесенного срока',
    type: 'date'
  },
  {
    editComponent: TableTextInput,
    field: 'reason',
    required: true,
    title: 'Причина (обоснование)'
  }
];

const purchaseChangesColumnsContract = [
  {
    editComponent: TableDateInput,
    field: 'date_start',
    headerStyle: {
      width: '30%'
    },
    render: TableDateCell('date_start'),
    required: true,
    title: 'Дата перенесенного срока',
    type: 'date'
  },
  {
    editComponent: TableTextInput,
    field: 'reason',
    required: true,
    title: 'Причина (обоснование)'
  }
];

export const typeOptions = [
  {
    value: 'ADVANCE',
    label: 'Аванс'
  },
  {
    value: 'PAYMENT',
    label: 'Платеж'
  },
  {
    value: 'FINAL_PAYMENT',
    label: 'Итоговый платеж'
  }
];

const cancelStatusOptions = Purchase.cancelStatus.getOptions();

const paymentScheduleColumns = [
  {
    editComponent: TableSelectWrapper(typeOptions),
    field: 'type_payment',
    title: 'Тип платежа',
    render: ({ type_payment }) => {
      const type = typeOptions.filter((item) => item.value === type_payment);
      return type[0] ? type[0].label : '—';
    },
    required: true
  },
  {
    editComponent: TableMonthInput,
    field: 'date_execution_payment',
    title: 'Срок исполнения платежа',
    headerStyle: {
      minWidth: '140px'
    },
    render: TableMonthCell('date_execution_payment'),
    type: 'date',
    required: true
  },
  {
    editComponent: TableCurrencyInput,
    field: 'plan',
    title: 'Плановая сумма, руб.',
    headerStyle: {
      minWidth: '140px'
    },
    render: (row) => formatCurrency(row.plan),
    required: true
  },
  {
    editComponent: TableCurrencyInput,
    field: 'fact',
    title: 'Фактическая сумма, руб.',
    headerStyle: {
      minWidth: '140px'
    },
    render: (row) => formatCurrency(row.fact)
  },
  {
    editComponent: ({ rowData }) => <>{renderPlanFactDiff(rowData)}</>,
    field: 'diff_fact_plan',
    title: 'Отклонение фактической от плановой суммы, руб.',
    headerStyle: {
      minWidth: '140px'
    },
    render: renderPlanFactDiff
  },
  {
    editComponent: TableTextInput,
    field: 'reason',
    title: 'Причина (обоснование)',
    required: (row) => {
      if (row?.plan !== row?.fact) {
        return !row?.reason;
      }
    }
  }
];

const renderScheduleTotalRow = (value) => {
  const totalFact = sumBy(value, (row) => row.fact);
  const totalPlan = sumBy(value, (row) => row.plan);
  const totalDiffPlanFact = totalPlan - totalFact;

  return (
    <div className={tableStyle.TotalTable}>
      <SubTable
        title="Итог"
        cols={3}
        head={
          <TableRow>
            <TableCell>Плановая сумма</TableCell>
            <TableCell>Фактическая сумма</TableCell>
            <TableCell>Отклонение фактической суммы от плановой</TableCell>
          </TableRow>
        }
      >
        <TableRow>
          <TableCell>{formatValueUnit(totalPlan, unitRuble)}</TableCell>
          <TableCell>{formatValueUnit(totalFact, unitRuble)}</TableCell>
          <TableCell>{formatValueUnit(totalDiffPlanFact, unitRuble)}</TableCell>
        </TableRow>
      </SubTable>
    </div>
  );
};

export const PurchaseEditForm = ({
  onBackClick,
  editor,
  errors,
  isSubmitting,
  isValid,
  status,
  touched,
  values,
  setFieldValue
}) => {
  useScrollToError({ errors, isSubmitting, isValid });
  const formValues = Purchase.fromForm(values);
  // todo выглядит некрасиво, подумать, как можно сделать почище
  const clientSignals = useMemo(() => Purchase.checkSignals(values), [values]);
  const serverSignalsRef = useRef({});

  // todo прикрутить дебаунс (через rxjs?)
  const skipQuery =
    !values.regional_project_id || !values.expenses_direction_id;
  const serverSignalsQuery = useServerSignal(
    Purchase.filterUpdateFields(formValues),
    skipQuery
  );

  if (serverSignalsQuery.state.data) {
    serverSignalsRef.current = parseSignals(serverSignalsQuery.state.data);
  } else if (serverSignalsQuery.check.error) {
    serverSignalsRef.current = {};
  }
  const { fields: signals } = mergeSignals(
    serverSignalsRef.current,
    clientSignals
  );
  // end

  useEffect(() => {
    window.addEventListener('beforeunload', formUnloadHandler);
    return () => window.removeEventListener('beforeunload', formUnloadHandler);
  }, []);

  const year = values.year || getCurrentYear().toString();

  const auth = useStoreState(STORE_AUTH);
  const regionId = getAuthRegionId(auth);

  const regionalProjectsFilter = useMemo(
    () => ({
      region_ids: regionId,
      has_pof: true,
      implementation_years: year ? [+year] : undefined
    }),
    [regionId, year]
  );
  const regionalProjectsQuery = useRetriableRegionalProjects(
    regionalProjectsFilter
  );
  const regionalProjects = useMemoSuggestions(
    get(regionalProjectsQuery.state.data, 'data')
  );
  const regionalProject = useMemo(() => {
    if (!regionalProjectsQuery.state.data) return null;
    return regionalProjectsQuery.state.data.data.find(
      (project) => project.id === values.regional_project_id
    );
  }, [values.regional_project_id, regionalProjectsQuery.state.data]);

  const expensesDirectionsFilter = useMemo(() => {
    return {
      regional_project_ids: values.regional_project_id,
      implementation_years: year ? [+year] : undefined
    };
  }, [values.regional_project_id, year]);
  const expensesDirectionsQuery = useRetriableExpensesDirections(
    expensesDirectionsFilter
  );
  const expensesDirections = useMemoSuggestionsFull(
    expensesDirectionsQuery.state.data
  );
  const grbsesQuery = useRetriableGrbses({
    region_ids: regionalProject?.region_id
  });
  const grbses = useMemoSuggestionsFull(grbsesQuery.state.data);

  const {
    financing_total,
    contract_summ_total,
    financing_deviation_sfb,
    financing_deviation_skbsrf,
    financing_deviation_mb,
    financing_deviation_vb,
    financing_deviation_total,
    cancel_status,
    fulfilled_obligations
  } = formValues;

  const statusPartiallyCanceled =
    cancel_status === Purchase.CancelStatus.PARTIALLY_CANCELED;

  const fulFilledOneTouched =
    touched.fulfilled_obligations ||
    touched.fulfilled_obligations_fb ||
    touched.fulfilled_obligations_rb ||
    touched.fulfilled_obligations_mb ||
    touched.fulfilled_obligations_vb;

  useEffect(() => {
    setFieldValue('fulfilled_obligations', fulfilled_obligations || 0);
  }, [setFieldValue, fulfilled_obligations]);

  return (
    <>
      <Prompt
        when={status !== FORM_STATUS_SUBMITTED}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны"
      />
      <Form noValidate>
        <FormHeader
          onBackClick={onBackClick}
          disabled={isSubmitting}
          editor={editor}
          isSubmitting={isSubmitting}
          entityName={`Закупка`}
        />
        <SubTable editVariant>
          <TableRow>
            <TableCell>Статус</TableCell>
            <TableCell>{Purchase.status.getName(values.status)}</TableCell>
          </TableRow>
        </SubTable>
        <InputGroup>
          <NewAutocompleteInput
            name="cancel_status"
            label="Статус расторжения"
            suggestions={cancelStatusOptions}
            fullWidth
          />
        </InputGroup>

        {statusPartiallyCanceled && (
          <InputGroup title={'Сумма исполненных обязательств по контракту'}>
            <FastField
              name="fulfilled_obligations_fb"
              label="Федеральный бюджет"
              component={CurrencyInput}
              errorMessage={
                fulFilledOneTouched && errors.fulfilled_obligations_fb
              }
            />
            <FastField
              name="fulfilled_obligations_rb"
              label="Региональный бюджет"
              component={CurrencyInput}
              errorMessage={
                fulFilledOneTouched && errors.fulfilled_obligations_rb
              }
            />
            <FastField
              name="fulfilled_obligations_mb"
              label="Муниципальный бюджет"
              component={CurrencyInput}
              errorMessage={
                fulFilledOneTouched && errors.fulfilled_obligations_mb
              }
            />
            <FastField
              name="fulfilled_obligations_vb"
              label="Внебюджетные средства"
              component={CurrencyInput}
              errorMessage={
                fulFilledOneTouched && errors.fulfilled_obligations_vb
              }
            />
            <CurrencyDisplayField
              label="Всего"
              unit="руб."
              value={fulfilled_obligations}
            />
          </InputGroup>
        )}

        <InputGroup>
          <NewAutocompleteInput
            name="year"
            label="Год"
            suggestions={yearsSuggestions}
            fullWidth
            isClearable={false}
          />
          <NewAutocompleteInput
            name="regional_project_id"
            label="Региональный проект"
            suggestionsLoading={regionalProjectsQuery.check.spinner}
            suggestions={regionalProjects}
            fullWidth
            required
            disabled={!year}
            placeholder={!year && 'Выберите год'}
          />
          <NewAutocompleteInput
            name="expenses_direction_id"
            label="Направление расходов"
            suggestionsLoading={
              regionalProjectsQuery.check.spinner ||
              expensesDirectionsQuery.check.spinner
            }
            suggestions={expensesDirections}
            fullWidth
            required
            disabled={!year}
            placeholder={!year && 'Выберите год'}
          />
        </InputGroup>
        <InputGroup>
          <NewAutocompleteInput
            name="grbs_id"
            label="Наименование главного распорядителя бюджетных средств"
            suggestionsLoading={grbsesQuery.check.spinner}
            suggestions={grbses}
            fullWidth
            required
          />
          <CustomFastField
            name="grbs_string"
            label="Ввод ГРБС вручную"
            component={TextInput}
            fullWidth
            errorMessage={touched.grbs_string && errors.grbs_string}
            disabled={!!values.grbs_id}
            multiline
            dependentFields={['grbs_id']}
          />
        </InputGroup>
        <InputGroup title="Заказчик">
          <Field
            name="customer_id"
            component={CustomerIdInput}
            errorMessage={touched.customer_id && errors.customer_id}
            regionId={regionalProject && regionalProject.region_id}
            grbses={grbses}
            grbsesLoading={grbsesQuery.check.spinner}
            customer={values.customer}
            customer_grbs={values.customer_grbs}
            customer_id_type={values.customer_id_type}
          />
          <CustomFastField
            name="customer_string"
            label="Ввод наименования заказчика вручную"
            component={TextInput}
            fullWidth
            errorMessage={touched.customer_string && errors.customer_string}
            disabled={!!values.customer_id}
            multiline
            dependentFields={['customer_id']}
          />
        </InputGroup>
        <InputGroup>
          <FastField
            name="item"
            label="Объект закупки"
            component={TextInput}
            fullWidth
            errorMessage={touched.item && errors.item}
            required
            multiline
          />
        </InputGroup>
        {false && (
          /* Скрыто по требованию заказчика */ <InputGroup>
            <NewAutocompleteInput
              label="Оборудование и ПО"
              name="equipment_and_software"
              value={values.equipment_and_software}
              suggestions={Purchase.equipmentAndSoftwareOptions}
              hasCopyBtn={false}
              fullWidth
              required
            />
          </InputGroup>
        )}
        <InputGroup title="Способ определения поставщика">
          <FastField
            name="supplier_determination_id"
            label="Способ"
            component={SupplierDeterminationInput}
            errorMessage={
              touched.supplier_determination_id &&
              errors.supplier_determination_id
            }
            required
          />
        </InputGroup>
        <InputGroup title="Начальная (максимальная) цена контракта">
          <CustomFastField
            component={CurrencyInput}
            errorMessage={touched.financing_sfb && errors.financing_sfb}
            label="Федеральный бюджет"
            name="financing_sfb"
            signals={signals}
          />
          <FastField
            name="financing_skbsrf"
            label="Региональный бюджет *"
            component={CurrencyInput}
            errorMessage={touched.financing_skbsrf && errors.financing_skbsrf}
            className={s.inputStyle}
          />
          <span className={s.info}>
            За исключением межбюджетных трансфертов из федерального бюджета
          </span>
          <FastField
            name="financing_mb"
            label="Муниципальный бюджет"
            component={CurrencyInput}
            errorMessage={touched.financing_mb && errors.financing_mb}
          />
          <FastField
            name="financing_vb"
            label="Внебюджетные средства"
            component={CurrencyInput}
            errorMessage={touched.financing_vb && errors.financing_vb}
          />
          <CurrencyDisplayField
            label="Всего"
            unit="руб."
            value={financing_total}
          />
        </InputGroup>
        <InputGroup title="Срок размещения закупки в ЕИС">
          <FastField
            name="eis_publication_planned_start"
            endFieldName="eis_publication_planned_end"
            label="Плановый. Начало"
            component={FormDateInput}
          />
          <FastField
            name="eis_publication_planned_end"
            startFieldName="eis_publication_planned_start"
            label="Плановый. Конец"
            component={FormDateInput}
          />
          <CustomFastField
            name="eis_publication_fact"
            label="Фактический"
            component={FormDateInput}
            dependentFields={[
              'eis_publication_planned_start',
              'eis_publication_planned_end'
            ]}
            signals={signals}
          />
          <FastField
            name="eis_publication_changes"
            columns={purchaseChangesColumns}
            component={TableInput}
            tableName="Переносы"
          />
        </InputGroup>
        <InputGroup>
          <FastField
            name="purchase_link"
            label="Ссылка на закупку на портале zakupki.gov.ru, прочие площадки"
            component={TextInput}
            fullWidth
            errorMessage={touched.purchase_link && errors.purchase_link}
          />
          <FastField
            name="eis_number"
            label="Номер закупки"
            component={TextInput}
            fullWidth
            inputProps={{ maxLength: 36 }}
            errorMessage={touched.eis_number && errors.eis_number}
          />
        </InputGroup>
        <InputGroup title="Срок заключения контракта/договора">
          <FastField
            name="contract_sign_planned_end"
            startFieldName="contract_sign_planned_start"
            label="Плановый"
            component={FormDateInput}
          />
          <CustomFastField
            name="contract_sign_fact"
            label="Фактический"
            component={FormDateInput}
            dependentFields={['contract_sign_planned_end']}
            signals={signals}
          />
          <FastField
            name="contract_sign_changes"
            columns={purchaseChangesColumnsContract}
            component={TableInput}
            tableName="Переносы"
          />
        </InputGroup>
        <InputGroup title="Цена контракта/договора">
          <CustomFastField
            component={CurrencyInput}
            label="Федеральный бюджет"
            name="contract_summ_sfb"
            signals={signals}
            errorMessage={touched.contract_summ_sfb && errors.contract_summ_sfb}
          />
          <FastField
            name="contract_summ_skbsrf"
            label="Региональный бюджет"
            component={CurrencyInput}
            errorMessage={
              touched.contract_summ_skbsrf && errors.contract_summ_skbsrf
            }
            className={s.inputStyle}
          />
          <span className={s.info}>
            За исключением межбюджетных трансфертов из федерального бюджета
          </span>
          <FastField
            name="contract_summ_mb"
            label="Муниципальный бюджет"
            component={CurrencyInput}
            errorMessage={touched.contract_summ_mb && errors.contract_summ_mb}
          />
          <FastField
            name="contract_summ_vb"
            label="Внебюджетные средства"
            component={CurrencyInput}
            errorMessage={touched.contract_summ_vb && errors.contract_summ_vb}
          />
          <CurrencyDisplayField
            label="Всего"
            unit="руб."
            value={contract_summ_total}
          />
          <CustomFastField
            component={CurrencyInput}
            errorMessage={
              touched.contract_summ_domestic && errors.contract_summ_domestic
            }
            label="в т.ч. отечественного производства"
            name="contract_summ_domestic"
            signals={signals}
          />
        </InputGroup>
        <InputGroup title="Отклонение цены контракта/договора от начальной (максимальной) цены контракта/договора">
          <CurrencyDisplayField
            label="Федеральный бюджет"
            unit="руб."
            value={financing_deviation_sfb}
            signals={signals.financing_deviation_sfb}
          />
          <CurrencyDisplayField
            label="Региональный бюджет"
            unit="руб."
            value={financing_deviation_skbsrf}
            signals={signals.financing_deviation_skbsrf}
            className={s.inputStyle}
          />
          <span className={s.info}>
            За исключением межбюджетных трансфертов из федерального бюджета
          </span>
          <CurrencyDisplayField
            label="Муниципальный бюджет"
            unit="руб."
            value={financing_deviation_mb}
            signals={signals.financing_deviation_mb}
          />
          <CurrencyDisplayField
            label="Внебюджетные средства"
            unit="руб."
            value={financing_deviation_vb}
            signals={signals.financing_deviation_vb}
          />
          <CurrencyDisplayField
            label="Всего"
            unit="руб."
            value={financing_deviation_total}
            signals={signals.financing_deviation_total}
          />
          <FastField
            name="deviation_reason"
            label="Текст обоснования"
            component={TextInput}
            fullWidth
            multiline
          />
        </InputGroup>
        <InputGroup title="Поставщик">
          <FastField
            name="supplier_inn"
            label="ИНН"
            validate={validateNumeric}
            component={TextInput}
            fullWidth
            errorMessage={touched.supplier_inn && errors.supplier_inn}
            inputProps={{ maxLength: 12 }}
          />
          <FastField
            name="supplier_kpp"
            label="КПП"
            validate={validateNumeric}
            component={TextInput}
            fullWidth
            errorMessage={touched.supplier_kpp && errors.supplier_kpp}
            inputProps={{ maxLength: 9 }}
          />
          <FastField
            name="supplier_name"
            label="Наименование"
            component={TextInput}
            fullWidth
            errorMessage={touched.supplier_name && errors.supplier_name}
          />
        </InputGroup>
        <InputGroup>
          <FastField
            name="delivery_planned"
            label="Планируемый срок поставки (подписание акта), дата"
            component={FormDateInput}
            required
          />
        </InputGroup>
        <InputGroup>
          <CustomFastField
            name="payment_schedules"
            columns={paymentScheduleColumns}
            component={TableInput}
            tableName="График платежей по федеральному бюджету"
            signals={signals}
            renderTotalRow={renderScheduleTotalRow}
            dependentFields={['contract_summ_sfb']}
          />
        </InputGroup>
        <PurchaseComponentsField title="Состав закупки" />
        <InputGroup>
          <FastField
            name="description"
            multiline
            label="Примечание (причины, обоснование)"
            component={TextInput}
          />
        </InputGroup>
        <PurchaseInfralistEditField
          name="rvpo_equipments"
          title={'Позиции оборудования инфраструктурного листа'}
          year={values.delivery_planned}
        />
      </Form>
    </>
  );
};
