import React, { useCallback, useEffect } from 'react';
import AutocompleteBaseInput from '../../common/Inputs/Selects/AutocompleteBaseInput';
import { getCurrentYear, yearsSuggestions } from '../../utils/data/date';

export const useRoadmapsFilter = (query, changeQuery) => {
  const year = query.year || getCurrentYear().toString();

  const filter = {
    implementation_years: [+year]
  };

  const setFilterValue = useCallback(
    (value, name) =>
      changeQuery({
        [name]: value,
        page: 0
      }),
    [changeQuery]
  );

  useEffect(() => {
    setFilterValue(year, 'year');
  }, [year, setFilterValue]);

  const filterComponent = (
    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end' }}>
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Год"
        name="year"
        suggestions={yearsSuggestions}
        value={year}
        variant="standard"
        isClearable={false}
      />
    </div>
  );
  return [filterComponent, filter];
};
