import React, { useCallback, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useContextStore } from '@proscom/prostore-react';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { PageActionsBar } from '../../../common/PageActionsBar/PageActionsBar';
import { EditButton } from '../../../common/Form/EditButton/EditButton';
import SubTable from '../../../common/SubTable/SubTable';
import { usePermissions } from '../../../store/role/usePermissions';
import { useRole } from '../../../store/role/useRole';
import { queryLoaderWithExistenceCheck } from '../../../common/QueryLoader';
import { ROLE_STORE } from '../../../store/names';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../graphql/graphqlErrors';
import { DeleteButtonWithConfirmation } from '../../../common/Form/DeleteButtonWithConfirmation';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import { getName } from '../../../utils/data/label';
import { RolePermissions } from './RolePermissions';
import { RoleBreadCrumbs } from './RoleBreadCrumbs';

export default function RolePage({ match, history }) {
  const roleStore = useContextStore(ROLE_STORE);
  const { roleId } = match.params;

  const roleQuery = useRole(roleId, true);
  const role = roleQuery.state.data;

  const permissionsQuery = usePermissions();
  const permissions = permissionsQuery.state.data;

  const [isDeleting, setDeleting] = useState(false);

  const deleteRole = useCallback(() => {
    setDeleting(true);
    roleStore
      .deleteRole(roleId)
      .then((result) => {
        setTimeout(() => {
          history.push('/roles');
        }, 0);
      })
      .catch((error) => {
        showGraphqlErrorToast(error, mutationErrors.deleteObject);
      })
      .finally(() => setDeleting(false));
  }, [roleId, history, roleStore]);

  return (
    <>
      <HeadTitle type="Роль" entity={getName(role)} />
      <RoleBreadCrumbs />
      {queryLoaderWithExistenceCheck(roleQuery) || (
        <>
          <PageTitle title={getName(role)} withBackBtn />
          <PageActionsBar
            editButton={<EditButton editLink={`${match.url}/edit`} />}
            deleteButton={
              <DeleteButtonWithConfirmation
                onDelete={deleteRole}
                isDeleting={isDeleting}
                entityName={'роль'}
              />
            }
          />
          <SubTable title="Название роли" cols={1}>
            <TableRow>
              <TableCell>{role.name}</TableCell>
            </TableRow>
          </SubTable>
          <SubTable title="Права" cols={2}>
            <RolePermissions
              permissions={role?.permissions}
              allPermissions={permissions}
            />
          </SubTable>
        </>
      )}
    </>
  );
}
