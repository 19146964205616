import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { FixedContainer } from '../FixedContainer/FixedContainer';
import { User } from '../../store/role/User';
import s from './Form.module.scss';

export const FormHeader = React.memo(function FormHeader({
  onBackClick,
  showBackButton = true,
  backButtonText,
  disabled,
  editor,
  editable = true,
  actions,
  isSubmitting,
  entityName = 'Форма',
  saveButtonName,
  classWrapper,
  onSubmitClick
}) {
  const editorName = User.getUserName(editor);
  const editingBy =
    editorName &&
    `${entityName} сейчас редактируется пользователем ${editorName}`;
  return (
    <FixedContainer classWrapper={classWrapper}>
      <div className={s.FormHeaderContainer}>
        {showBackButton && (
          <Button
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
            classes={{ root: s.Link }}
            onClick={onBackClick}
          >
            <ArrowBackIcon /> {backButtonText || 'В режим просмотра'}
          </Button>
        )}
        {!!editingBy && (
          <p
            className={s.FormHeaderEditing}
            title={
              'Чтобы избежать конфликтов при редактировании, подождите, пока другой пользователь завершит свою работу'
            }
          >
            {editingBy}
          </p>
        )}
        <div className={s.FormHeaderActions}>
          {actions}
          {editable && (
            <Button
              disabled={disabled}
              color="primary"
              variant="contained"
              type="submit"
              onClick={onSubmitClick}
            >
              {isSubmitting ? (
                <CircularProgress size={24} color="secondary" />
              ) : (
                saveButtonName || 'Сохранить'
              )}
            </Button>
          )}
        </div>
      </div>
    </FixedContainer>
  );
});
