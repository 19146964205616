import React, { useState } from 'react';
import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { showGraphqlErrorToast } from '../../../graphql/graphqlErrors';
import { MUTATION_RECOMPLETE_APPROVEMENT } from '../../../graphql/mutations/equipment';
import { QUERY_CHECK_APPROVMENT } from '../../../graphql/queries/infralist';
import { INFRALIST_STATUSES } from '../infralistStatusService';
import { useInfralistUserAccesses } from '../useInfralistUserAccesses';

export const useRecompleteApprovement = (infralist) => {
  const client = useContextApolloClient();
  const { isFPO2 } = useInfralistUserAccesses();
  const [isAllEquipmentAgreed, setIsAllEquipmentAgreed] = useState(false);
  const [loading, setLoading] = useState(false);

  const reCompleteInfralistApprovement = () => {
    setLoading(true);
    return client
      .mutate({
        mutation: MUTATION_RECOMPLETE_APPROVEMENT,
        variables: {
          infralist_id: infralist.state?.data?.id
        },
        refetchQueries: ['infralist']
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(showGraphqlErrorToast);
  };

  const checkApprovement = () => {
    return client
      .query({
        query: QUERY_CHECK_APPROVMENT,
        variables: {
          infralist_id: infralist.state?.data?.id
        }
      })
      .then((response) =>
        setIsAllEquipmentAgreed(response.data?.checkNotApproveSection)
      );
  };

  const isRecompleteButtonVisible =
    isFPO2 &&
    [
      INFRALIST_STATUSES.APPROVED,
      INFRALIST_STATUSES.APPROVED_WITH_COMMENTS
    ].includes(infralist.state.data?.infralist_status?.name);

  return {
    isRecompleteButtonVisible,
    loading,
    reCompleteInfralistApprovement,
    checkApprovement,
    isAllEquipmentAgreed
  };
};
