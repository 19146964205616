import React from 'react';
import BreadCrumbs from '../../../common/BreadCrumbs/BreadCrumbs';

export function getRoleBreadCrumbsf() {
  return [
    {
      label: 'Роли',
      to: '/roles'
    }
  ];
}

export const RoleBreadCrumbs = () => {
  const breadCrumbsLinks = getRoleBreadCrumbsf();

  return <BreadCrumbs links={breadCrumbsLinks} />;
};
