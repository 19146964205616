import React, { useMemo } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useRegionalProject } from '../../../store/regionalProject/useRegionalProject';
import PageTitle from '../../../common/PageTitle/PageTitle';
import NotFoundPage from '../../notFound/NotFoundPage';
import { useUrlQuery } from '../../../common/useUrlQuery';
import { queryLoader } from '../../../common/QueryLoader';
import { hideRoadmaps } from '../../../config';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { User } from '../../../store/role/User';
import { useUrlTabs } from '../../../common/useUrlTabs';
import { getName } from '../../../utils/data/label';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import { ProjectBreadCrumbs } from './ProjectBreadCrumbs';
import { ProjectExpensesDirections } from './expenseGroups/ProjectExpensesDirections';
import { ProjectRoadmaps } from './projectRoadmaps/ProjectRoadmaps';
import { PofsList } from './pofs/PofsList';
import s from './ProjectPage.module.scss';

const PageTab = {
  PURCHASES: 'purchases',
  ROADMAPS: 'roadmaps',
  POFS: 'pofs'
};

const getTabName = (tab) => {
  return tab === 'purchases'
    ? 'Закупки'
    : tab === 'roadmaps'
    ? 'Дорожные карты'
    : tab === 'pofs'
    ? 'ПОФ'
    : '';
};

const ProjectPage = ({ match, location, history }) => {
  const { projectId } = match.params;
  const [query, changeQuery] = useUrlQuery(location, history);

  const projectQuery = useRegionalProject(projectId);
  const project = projectQuery.state.data;
  const projectYear = +query.year || project?.implementation_years[0];

  const user = useCurrentUser();
  const availableTabs = useMemo(() => {
    const result = [];

    if (User.hasPermission(user, 'purchase.access_page')) {
      result.push({ label: 'Закупки', value: PageTab.PURCHASES });
    }

    if (User.hasPermission(user, 'pof.access_page')) {
      result.push({ label: 'ПОФ', value: PageTab.POFS });
    }

    if (User.hasPermission(user, 'roadmap.access_page') && !hideRoadmaps) {
      result.push({
        label: 'Дорожные карты',
        value: PageTab.ROADMAPS
      });
    }

    return result;
  }, [user]);

  const [openedTab, setTabsValue] = useUrlTabs(
    availableTabs,
    query,
    changeQuery
  );

  if (projectQuery.check.component && !project) {
    return <NotFoundPage />;
  }

  return (
    <>
      <HeadTitle
        type="Региональный проект"
        entity={getName(project)}
        tab={getTabName(openedTab)}
      />
      <ProjectBreadCrumbs />
      <PageTitle title={getName(project)} withBackBtn />
      <Tabs
        value={openedTab}
        indicatorColor="primary"
        textColor="inherit"
        centered
        className={s.Tabs}
        onChange={(e, value) => setTabsValue(value)}
      >
        {availableTabs.map((tab, iTab) => (
          <Tab key={iTab} {...tab} />
        ))}
      </Tabs>
      {queryLoader(projectQuery) ||
        (project && (
          <>
            {openedTab === PageTab.POFS ? (
              <PofsList
                projectId={projectId}
                location={location}
                history={history}
                match={match}
              />
            ) : openedTab === PageTab.PURCHASES ? (
              <ProjectExpensesDirections
                projectId={projectId}
                location={location}
                history={history}
                project={project}
                projectYear={projectYear}
              />
            ) : openedTab === PageTab.ROADMAPS ? (
              <ProjectRoadmaps
                location={location}
                history={history}
                project={project}
                projectYear={projectYear}
              />
            ) : (
              <NotFoundPage />
            )}
          </>
        ))}
    </>
  );
};

ProjectPage.propTypes = {};

export default ProjectPage;
