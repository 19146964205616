import React, { useMemo } from 'react';
import BreadCrumbs, {
  loadingBreadcrumb
} from '../../../../../common/BreadCrumbs/BreadCrumbs';
import { getProjectBreadCrumbs } from '../../ProjectBreadCrumbs';

export function getExpenseGroupBreadCrumbs({ projectId, projectName }) {
  return [
    ...getProjectBreadCrumbs(),
    {
      label: loadingBreadcrumb(projectName),
      to: `/projects/${projectId}`
    },
    {
      label: 'Закупки',
      to: `/projects/${projectId}/?tab=purchases`
    }
  ];
}

export const ExpenseGroupBreadCrumbs = ({ project, projectId }) => {
  const breadCrumbsLinks = useMemo(() => {
    return getExpenseGroupBreadCrumbs({
      projectId,
      projectName: project?.name
    });
  }, [projectId, project]);

  return <BreadCrumbs links={breadCrumbsLinks} />;
};
