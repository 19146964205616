import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../../common/useRetriableGraphqlVars';
import { QUERY_INFRALIST_VERSION_DIRECTIONS } from '../../../graphql/queries/infralist';

const infralistDirectionsQueryOptions = {
  query: QUERY_INFRALIST_VERSION_DIRECTIONS,
  mapData: (result) =>
    result.infralistVersionDirections?.map((direction) => ({
      ...direction,
      status: direction.status_by_version,
      checkStatus: direction.check_status_by_version,
      approvement_status: direction.approvement_status_by_version,
      directionSum: direction.direction_sum_by_version,
      checked_users: direction.checked_users_by_version,
      statusChangeTime: direction.status_change_time_by_version
    })),
  skipQuery: skipIfNull(null)
};

export function useInfralistVersionDirections({ infralistId, versionId }) {
  const response = useRetriableGraphql({
    queryOptions: infralistDirectionsQueryOptions,
    variables: {
      infralist_id: infralistId,
      version_id: versionId
    }
  });

  return {
    data: response.state.data,
    isLoading: response.check.spinner
  };
}
