import React, { useCallback } from 'react';
import shallowEqual from 'shallowequal';
import { DocumentUploadFileStatus } from '../DocumentUploadFileStatus';
import { Photo } from './Photo';
import s from './EventPhotos.module.scss';

export const PhotoEdit = React.memo(
  function PhotoEdit({ document, editProps, disabled }) {
    const documentId = document.id;
    const deleteDocument = editProps.handleDocumentDelete;
    const cancelUpload = editProps.handleCancelUpload;
    const handleCancelUpload = useCallback(() => {
      cancelUpload(documentId);
    }, [documentId, cancelUpload]);
    const handleDelete = useCallback(() => {
      deleteDocument(documentId);
    }, [deleteDocument, documentId]);
    return (
      <div className={s.PhotoEdit}>
        <div className={s.PhotoEdit__photo}>
          <Photo document={document} />
        </div>
        <div className={s.PhotoEdit__status}>
          <DocumentUploadFileStatus
            documentId={documentId}
            document={document}
            disabled={disabled}
            onDelete={handleDelete}
            onDocumentChange={editProps.handleDocumentChange}
            onCancelUpload={handleCancelUpload}
            canUploadDocuments={editProps.canUploadDocuments}
            canEditStatus={editProps.canEditStatus}
          />
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return shallowEqual(prevProps, nextProps, (prev, next, key) => {
      if (key === 'editProps') {
        return shallowEqual(prev, next);
      }
      return undefined;
    });
  }
);
