import gql from 'graphql-tag';
import { FRAGMENT_REGIONAL_PROJECT } from './regionalProjects';

export const FRAGMENT_CONTEST_DOCS_MONITORING_ENTITY_BASE = gql`
  fragment contestDocsMonitoringEntityBase on TenderDocumentationType {
    id
    region {
      id
      name
    }
    regional_project {
      id
      federal_project {
        id
        name
      }
    }
    status
    year
    roadmaps {
      roadmap_indicators {
        value
        components {
          value
        }
      }
    }
    analytical_information
  }
`;

export const FRAGMENT_CONTEST_DOCS_MONITORING_ENTITY_FULL = gql`
  fragment contestDocsMonitoringEntityFull on TenderDocumentationType {
    analytical_information
    id
    region {
      id
      name
    }
    regional_project {
      id
      name
      name_full
      federal_project {
        id
        name
      }
    }
    roadmaps {
      roadmap {
        id
        name
      }
      roadmap_indicators {
        components {
          component {
            id
            name
            name_full
            code
            precision
            unit {
              name_local
            }
          }
          previous_value
          value
        }
        previous_value
        roadmap_indicator {
          id
          name
          name_full
          note
          formula
          precision
          unit {
            name_local
          }
          fill_type
        }
        value
      }
    }
    status
    year
  }
`;

export const FRAGMENT_CONTEST_DOCS_FOR_SS_MONITORING_ENTITY_BASE = gql`
  fragment contestDocsForSSMonitoringEntityBase on TenderDocumentationForSSType {
    analytical_information
    id
    region {
      id
      name
    }
    federal_project {
      id
      name
    }
    subsidiary_entity {
      id
      name
      name_full
      inn
      kpp
      base_organization_name
      base_organization {
        id
        inn
        kpp
        name_short
        name_full
      }
    }
    roadmap_indicators {
      components {
        component {
          id
          name
          name_full
          code
          precision
          unit {
            name_local
          }
        }
        previous_value
        value
      }
      previous_value
      roadmap_indicator {
        id
        name
        name_full
        note
        formula
        precision
        unit {
          name_local
        }
        fill_type
      }
      roadmap_indicator_id
      value
    }
    status
    year
  }
`;

export const FRAGMENT_CONTEST_DOCS_FOR_SS_MONITORING_ENTITY_FULL = gql`
  fragment contestDocsForSSMonitoringEntityFull on TenderDocumentationForSSType {
    analytical_information
    id
    region {
      id
      name
    }
    federal_project {
      id
      name
    }
    subsidiary_entity {
      id
      name
      name_full
      inn
      kpp
      base_organization_name
      base_organization {
        id
        inn
        kpp
        name_short
        name_full
      }
    }
    roadmap_indicators {
      components {
        component {
          id
          name
          name_full
          code
          precision
          unit {
            name_local
          }
        }
        previous_value
        value
      }
      previous_value
      roadmap_indicator {
        id
        name
        name_full
        note
        formula
        precision
        unit {
          name_local
        }
        fill_type
      }
      roadmap_indicator_id
      value
    }
    status
    year
  }
`;

export const QUERY_CONTEST_DOCS_MONITORING_PAGE = gql`
  query monthlyMonitoringTenderDocumentationsPage(
    $input: TenderDocumentationsPageInput
  ) {
    monthlyMonitoringTenderDocumentationsPage(input: $input) {
      header {
        hasNext
        onePage
        page
        totalCount
      }
      list {
        ...contestDocsMonitoringEntityFull
      }
    }
  }

  ${FRAGMENT_CONTEST_DOCS_MONITORING_ENTITY_FULL}
`;

export const QUERY_CONTEST_DOCS_FOR_SS_MONITORING_PAGE = gql`
  query tenderDocumentationForSSPage(
    $input: TenderDocumentationForSSPageInput
  ) {
    tenderDocumentationForSSPage(input: $input) {
      header {
        hasNext
        onePage
        page
        totalCount
      }
      list {
        ...contestDocsForSSMonitoringEntityBase
      }
    }
  }

  ${FRAGMENT_CONTEST_DOCS_FOR_SS_MONITORING_ENTITY_BASE}
`;

export const QUERY_CONTEST_DOCS_MONITORING_ENTITY = gql`
  query monthlyMonitoringTenderDocumentation(
    $input: TenderDocumentationUniqueDocumentSearch!
  ) {
    monthlyMonitoringTenderDocumentation(args: $input) {
      ...contestDocsMonitoringEntityFull
    }
  }

  ${FRAGMENT_CONTEST_DOCS_MONITORING_ENTITY_FULL}
`;

export const QUERY_CONTEST_DOCS_FOR_SS_MONITORING_ENTITY = gql`
  query tenderDocumentationForSS(
    $input: TenderDocumentationForSSUniqueDocumentSearch!
  ) {
    tenderDocumentationForSS(args: $input) {
      ...contestDocsForSSMonitoringEntityFull
    }
  }

  ${FRAGMENT_CONTEST_DOCS_FOR_SS_MONITORING_ENTITY_FULL}
`;

export const QUERY_REGIONAL_PROJECTS_AVAILABLE_FOR_MONTHLY_MONITORING_TENDER_DOCUMENTATION = gql`
  query availableRegionalProjects(
    $input: TenderDocumentationRegionalProjectsSearch!
  ) {
    regionalProjectsAvailableForMonthlyMonitoringTenderDocumentation(
      args: $input
    ) {
      ...RegionalProject
      federal_project {
        id
        name_full
      }
    }
  }

  ${FRAGMENT_REGIONAL_PROJECT}
`;

export const QUERY_CONTEST_DOCS_MONITORING_EXPORT_HISTORY = gql`
  query monthlyMonitoringTenderDocumentationExportHistory(
    $filter: ExportHistoryFilter!
  ) {
    list: monthlyMonitoringTenderDocumentationExportHistory(filter: $filter) {
      export_date
      hash_string
      c_user {
        user {
          id
          name
          login
        }
      }
    }
  }
`;

export const QUERY_CONTEST_DOCS_FOR_SS_MONITORING_EXPORT_HISTORY = gql`
  query tenderDocumentatioForSSExportHistory($filter: ExportHistoryFilter!) {
    list: tenderDocumentatioForSSExportHistory(filter: $filter) {
      export_date
      hash_string
      c_user {
        user {
          id
          name
          login
        }
      }
    }
  }
`;
