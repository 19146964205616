import React from 'react';
import { noop } from 'lodash-es';
import { isTableFieldRequired } from '../Table/TableInput';
import AutocompleteAsyncInput from './Selects/AutocompleteAsyncInput';
import s from './Inputs.module.scss';

export const TableAutocompleteWrapper = ({
  value,
  onChange,
  rowData,
  columnDef,
  getItem = noop,
  convertQuerySuggestion = noop
}) => {
  const error =
    isTableFieldRequired(rowData, columnDef) && !value
      ? 'Должно быть заполнено'
      : undefined;

  return (
    <AutocompleteAsyncInput
      className={s.TableAutocompleteWrapper}
      value={value}
      onChange={onChange}
      hasCopyBtn={false}
      getItems={getItem}
      useObject
      mapData={convertQuerySuggestion}
      error={error}
    />
  );
};
