import React, { useEffect, useMemo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useField } from 'formik';
import Button from '@material-ui/core/Button';
import { ActionButton } from '../ActionButton';
import SubTable from '../../SubTable/SubTable';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { usePermissions } from '../../../store/role/usePermissions';
import { queryLoader } from '../../QueryLoader';
import { User } from '../../../store/role/User';
import { wrapTableCell } from '../../Table/utils';
import { PermissionInputs } from './PermissionInputs';
import s from './Permissions.module.scss';

function getRolePermission(roles, selectedRoles) {
  if (!roles || !selectedRoles?.[0].id) {
    return null;
  }
  const roleId = selectedRoles[0].id;
  return roles?.find((role) => role.id === roleId);
}

export function filterPermissionByRole(
  permission,
  role,
  getPermBaseId = (perm) => perm.base_permission_id
) {
  if (!role) {
    return permission;
  }
  return permission.filter((perm) => {
    // Если Админ, то убираем все права, ибо все уже доступно.
    if (role.code === User.Role.ADMIN) {
      return false;
    }
    if (role.permissions?.length > 0) {
      // Проверяем, находим идентичные права
      const rolePerm = role.permissions.find(
        (rolePerm) => rolePerm.base_permission_id === getPermBaseId(perm)
      );

      // Если предоставлено таргетное право, то оставляем
      if (rolePerm && rolePerm.object_id) {
        return true;
      }
      // Если фильтруем массив прав, оставляем права с типом объект(таргетные права)
      if (rolePerm && rolePerm.type === 'OBJECT') {
        return true;
      }
      // Если право у роли есть, то исключаем из собственного списка.
      // noinspection RedundantIfStatementJS Для читаемости кода
      if (rolePerm) {
        return false;
      }
      return true;
    }
    return true;
  });
}

export const PermissionsEditField = ({ name, values, roles, ...props }) => {
  const [f, meta, { setValue }] = useField(name);
  const permissions = meta.value || [];

  const handlePermissionAdd = () => {
    const newPermission = {
      base_permission_id: '',
      object_id: null,
      object_type: null
    };
    setValue([...permissions, newPermission]);
  };

  const handlePermissionDelete = (iPermission) => {
    setValue(permissions.filter((permission, index) => index !== iPermission));
  };

  const user = useCurrentUser();
  const permissionsQuery = usePermissions();
  const permissionsData = permissionsQuery.state.data;
  const role = getRolePermission(roles, values?.roles);

  const userPermissionsMap = useMemo(() => {
    if (User.isAdmin(user)) {
      const adminPermissions = User.getAdminPermission(permissionsData);
      return User.getAllPermissionsMap(user, adminPermissions);
    } else {
      return User.getAllPermissionsMap(user);
    }
  }, [permissionsData, user]);

  const userPermissions = useMemo(() => {
    return User.getPermissionsFromMap(userPermissionsMap);
  }, [userPermissionsMap]);

  const allUserPermission = useMemo(() => {
    return filterPermissionByRole(userPermissions, role);
  }, [role, userPermissions]);

  const setCurrentPermissions = () => {
    setValue(allUserPermission);
  };

  const filteredPermission = useMemo(() => {
    return permissionsData?.filter((permission) =>
      allUserPermission.some(
        (filteredPerm) => permission.id === filteredPerm.base_permission_id
      )
    );
  }, [permissionsData, allUserPermission]);

  useEffect(() => {
    if (!allUserPermission || allUserPermission?.length === 0) {
      return;
    }
    const filteredValues = values?.permissions?.filter((valuePerm) => {
      return allUserPermission.some(
        (perm) => perm.base_permission_id === valuePerm.base_permission_id
      );
    });
    setValue(filteredValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, allUserPermission]);

  return (
    <SubTable cols={3} title="Права" editVariant>
      {userPermissions && (
        <TableRow>
          <TableCell>
            <Button onClick={setCurrentPermissions}>
              Задать собственные права
            </Button>
          </TableCell>
        </TableRow>
      )}
      {wrapTableCell(queryLoader(permissionsQuery)) ||
        (values.permissions && (
          <PermissionInputs
            onDelete={handlePermissionDelete}
            permissions={filteredPermission}
            userPermissionsMap={userPermissionsMap}
            values={values}
            {...props}
          />
        ))}
      <TableRow>
        <TableCell className={s.buttonCell} colSpan={2}>
          <ActionButton onClick={handlePermissionAdd} title="Добавить право" />
        </TableCell>
      </TableRow>
    </SubTable>
  );
};
