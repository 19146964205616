import gql from 'graphql-tag';

export const FRAGMENT_INDICATOR_FORECAST = gql`
  fragment indicatorForecast on IndicatorForecastType {
    id
    comment
    orgs_demo
    orgs_total
    people_demo
    people_total
    indicator_plan_value
    indicator_forecast_value

    federal_project_id
    indicator_id
    region_id

    form_variant
    status

    federal_project {
      id
      name_full
    }
    indicator {
      id
      name_full
    }
    region {
      id
      name
    }
  }
`;

export const QUERY_GET_INDICATOR_FORECASTS = gql`
  query indicatorForecasts($filter: IndicatorForecastFilter) {
    indicatorForecasts(filter: $filter) {
      id
      status
      federal_project {
        id
        name_full
      }
      indicator {
        id
        name_full
      }
      region {
        id
        name
      }
      status
    }
  }
`;

export const QUERY_GET_INDICATOR_FORECAST = gql`
  query indicatorForecast($id: ID!) {
    indicatorForecast(id: $id) {
      ...indicatorForecast
    }
  }

  ${FRAGMENT_INDICATOR_FORECAST}
`;
