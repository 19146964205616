import { MUTATION_SET_EDITING_ITEM } from '../graphql/mutations/_common';

export class EditingStore {
  constructor({ client }) {
    this.client = client;
  }

  setEditing = async (type, id) => {
    if (!id) return null;

    const result = await this.client.mutate({
      mutation: MUTATION_SET_EDITING_ITEM,
      variables: {
        entity: `${type}-${id}`
      }
    });
    return result.data.setEditingItem;
  };
}
