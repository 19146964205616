import gql from 'graphql-tag';

export const QUERY_GET_NON_COMMERCIAL_ORGANIZATIONS = gql`
  query getNonCommercialOrganizations(
    $input: NonCommercialOrganizationsPageInput
  ) {
    nonCommercialOrganizations(input: $input) {
      list {
        id
        name_short
      }
    }
  }
`;

export const QUERY_GET_NON_COMMERCIAL_ORGANIZATION = gql`
  query getNonCommercialOrganization($id: ID!) {
    nonCommercialOrganization(id: $id) {
      id
      name_short
    }
  }
`;
