import { CREATE_MESSAGE } from '../../graphql/mutations/notifications';
import { Notifications } from './Notification';

export class NotificationStore {
  constructor({ client }) {
    this.client = client;
  }

  createMessage(data) {
    return this.client.mutate({
      mutation: CREATE_MESSAGE,
      variables: {
        input: Notifications.filterCreateFields(data)
      }
    });
  }
}
