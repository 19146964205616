import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import debounce from 'debounce-promise';
import Async from 'react-select/async';
import { showGraphqlErrorToast } from '../../../graphql/graphqlErrors';
import {
  commonSelectComponents,
  loadingMessage,
  noOptionsMessage
} from './common';
import s from '../Inputs.module.scss';

export function NewAutocompleteAsyncField({
  errorMessage = null,
  filterParams,
  loadOptions: loadOptionsProp,
  loadOption,
  fullWidth,
  hasCopyBtn = true,
  label,
  onChange,
  required = false,
  variant = 'outlined',
  value: inputValue,
  TextFieldProps,
  ...rest
}) {
  const loadOptions = useMemo(() => debounce(loadOptionsProp, 500), [
    loadOptionsProp
  ]);

  const [value, setValue] = useState(null);
  useEffect(() => {
    if (typeof inputValue === 'object' || !inputValue) {
      setValue(inputValue);
    } else {
      let active = true;
      loadOption(inputValue)
        .then((result) => {
          if (!active) return;
          setValue(result);
        })
        .catch((e) => showGraphqlErrorToast(e));

      return () => {
        active = false;
      };
    }
  }, [inputValue, loadOption]);

  const loadOptionsFn = useCallback(
    (inputValue) =>
      loadOptions(inputValue).catch((e) => {
        showGraphqlErrorToast(e);
      }),
    [loadOptions]
  );

  const keyRef = useRef({ key: 1, value: loadOptionsFn });
  if (keyRef.current.value !== loadOptionsFn) {
    keyRef.current.key++;
    keyRef.current.value = loadOptionsFn;
  }

  return (
    <Async
      key={keyRef.current.key}
      defaultOptions={true}
      loadOptions={loadOptionsFn}
      className={s.Field}
      components={commonSelectComponents}
      hasCopyBtn={hasCopyBtn}
      isClearable={true}
      loadingMessage={loadingMessage}
      noOptionsMessage={noOptionsMessage}
      openOnFocus={true}
      onChange={(item) => {
        if (Array.isArray(item)) {
          onChange(item);
        } else {
          onChange(!item ? item : item.value);
        }
      }}
      placeholder={false}
      TextFieldProps={{
        label,
        error: !!errorMessage,
        helperText: errorMessage,
        InputLabelProps: {
          shrink: true,
          required
        },
        fullWidth,
        classes: {
          root: s.InputString
        },
        variant,
        ...TextFieldProps
      }}
      value={value}
      menuPortalTarget={document.body}
      {...rest}
    />
  );
}
