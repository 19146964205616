import React from 'react';
import { YearSelect } from '../../../../common/YearSelect';
import { DatePickerRanger } from '../../../../common/DatePickerRanger/DatePickerRanger';
import {
  dateToBackendString,
  getCurrentYear
} from '../../../../utils/data/date';

export const useRegionalEventFilter = (
  query,
  changeQuery,
  roadmap,
  roadmapYear,
  regionId
) => {
  const filter = {
    roadmap_ids: roadmap?.id,
    region_ids: regionId,
    year: roadmapYear,
    plan_term:
      query.planTermStartDate || query.planTermEndDate
        ? {
            start: query.planTermStartDate || undefined,
            end: query.planTermEndDate || undefined
          }
        : undefined,
    status_changed_at:
      query.statusChangedAtStartDate || query.statusChangedAtEndDate
        ? {
            start: query.statusChangedAtStartDate || undefined,
            end: query.statusChangedAtEndDate || undefined
          }
        : undefined
  };

  const handleChange = (field) => (value) => {
    changeQuery({ [field]: value ? dateToBackendString(value) : '' });
  };

  const filterComponent = (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <YearSelect
        label="Год мероприятий"
        year={filter.year}
        years={roadmap?.roadmap_years.map((x) => x.year)}
        onChange={changeQuery}
      />
      <DatePickerRanger
        sinceTitle="От"
        tillTitle="До"
        label="Плановый срок"
        variant="outlined"
        startDate={query.planTermStartDate}
        endDate={query.planTermEndDate}
        onStartChange={handleChange('planTermStartDate')}
        onEndChange={handleChange('planTermEndDate')}
      />
      <DatePickerRanger
        sinceTitle="От"
        tillTitle="До"
        label="Дата изменения статуса"
        variant="outlined"
        startDate={query.statusChangedAtStartDate}
        endDate={query.statusChangedAtEndDate}
        onStartChange={handleChange('statusChangedAtStartDate')}
        onEndChange={handleChange('statusChangedAtEndDate')}
      />
    </div>
  );

  return [filterComponent, filter];
};
