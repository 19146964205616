/**
 * Удаляет из кеша Apollo нужные квери
 *
 * @param store - кеш Apollo
 * @param queries - массив из строк, функций и регекспов, определяющий
 *  какие квери нужно удалить.
 *  Строки метчатся по префиксу, функции должны вернуть true для удаления
 */
export function clearApolloQueryCache(store, queries) {
  const checkQuery = (queryKey) => (query) =>
    query instanceof RegExp
      ? queryKey.match(query)
      : typeof query === 'string'
      ? queryKey.indexOf(query) === 0
      : typeof query === 'function'
      ? query(queryKey)
      : false;

  Object.keys(store.data.data).forEach((key) => {
    const matches = key.match(/^\$ROOT_QUERY\.(.+)$/);
    if (!matches) {
      return;
    }

    const queryKey = matches[1];
    if (queries.some(checkQuery(queryKey))) {
      store.data.delete(key);
    }
  });

  if (store.data.data.ROOT_QUERY) {
    const ROOT_QUERY = store.data.data.ROOT_QUERY;
    Object.keys(ROOT_QUERY).forEach((key) => {
      if (queries.some(checkQuery(key))) {
        delete ROOT_QUERY[key];
      }
    });
  }

  return store;
}
