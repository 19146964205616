import React from 'react';
import s from './Description.module.scss';

export const Description = ({ descriptionTitle, descriptionText }) => {
  return (
    <span className={s.Description}>
      <span className={s.Description__Title}>{descriptionTitle}: </span>
      {descriptionText}
    </span>
  );
};
