const INFRALIST_NOTIFICATIONS_TYPES = {
  DIRECTION: 'notifications.infralist.direction.comment',
  SECTION: 'notifications.infralist.section.comment',
  EQUIPMENT: 'notifications.infralist.rvpo_equipment.comment'
};

export const useNotificationLink = (data, infralistId) => {
  let link = `/infralist-comments/${infralistId}`;
  const fields = data?.template_fields;
  switch (fields?.notification_type) {
    case INFRALIST_NOTIFICATIONS_TYPES.DIRECTION:
      link = `${link}/direction/${fields?.direction_id}`;
      break;
    case INFRALIST_NOTIFICATIONS_TYPES.SECTION:
      link = `${link}/directions/${fields?.direction_id}/section/${fields?.section_id}`;
      break;
    case INFRALIST_NOTIFICATIONS_TYPES.EQUIPMENT:
      link = `${link}/directions/${fields?.direction_id}/sections/${fields?.section_id}/equipment/${fields?.rvpo_equipment_id}`;
      break;
    default:
      link = `${link}/direction/${fields?.direction_id}`;
  }

  return link;
};
