import React from 'react';
import isNumber from 'lodash-es/isNumber';
import { formatNumber } from '../../utils/formatNumber';
import { Signals } from './Signals';
import s from './Inputs.module.scss';

const CurrencyDisplayField = ({ label, unit, value, signals }) => {
  return (
    <div className={s.CurrencyDisplayContainer}>
      <p className={s.CurrencyDisplayPlaceholder}>{label}</p>
      <p className={s.CurrencyDisplayValue}>
        {isNumber(value) ? formatNumber(value) : '—'} {unit}
      </p>
      <Signals signals={signals} />
    </div>
  );
};

export default React.memo(CurrencyDisplayField);
