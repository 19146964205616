import { clearApolloQueryCache } from '../../utils/apollo';
import {
  MUTATION_APPROVE_SUBSIDIARY_ENTITY,
  MUTATION_CREATE_SUBSIDIARY_ENTITY,
  MUTATION_DELETE_SUBSIDIARY_ENTITY,
  MUTATION_REVOKE_SUBSIDIARY_ENTITY_APPROVAL,
  MUTATION_UPDATE_SUBSIDIARY_ENTITY
} from '../../graphql/mutations/subsidiaryEntity';
import { SubsidiaryEntity } from './SubsidiaryEntity';

export class SubsidiaryEntityStore {
  constructor({ client }) {
    this.client = client;
  }

  queriesToClear = ['subsidiaryEntities', 'regions', 'federalProjectRoadmaps'];

  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  updateSubsidiaryEntity(data) {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_SUBSIDIARY_ENTITY,
      variables: {
        input: SubsidiaryEntity.filterUpdateFields(data)
      },
      refetchQueries: ['subsidiaryEntities'],
      update: this.clearCache
    });
  }

  createSubsidiaryEntity(data) {
    return this.client.mutate({
      mutation: MUTATION_CREATE_SUBSIDIARY_ENTITY,
      variables: {
        input: SubsidiaryEntity.filterUpdateFields(data)
      },
      refetchQueries: ['subsidiaryEntities'],
      update: this.clearCache
    });
  }

  deleteSubsidiaryEntity = (data) => {
    return this.client.mutate({
      mutation: MUTATION_DELETE_SUBSIDIARY_ENTITY,
      variables: {
        id: data
      },
      refetchQueries: ['subsidiaryEntities'],
      update: this.clearCache
    });
  };

  approveSubsidiaryEntity = (data) => {
    return this.client.mutate({
      mutation: MUTATION_APPROVE_SUBSIDIARY_ENTITY,
      variables: {
        id: data
      },
      refetchQueries: ['subsidiaryEntities'],
      update: this.clearCache
    });
  };

  revokeSubsidiaryEntityApprove = (data) => {
    return this.client.mutate({
      mutation: MUTATION_REVOKE_SUBSIDIARY_ENTITY_APPROVAL,
      variables: {
        id: data
      },
      refetchQueries: ['subsidiaryEntities'],
      update: this.clearCache
    });
  };
}
