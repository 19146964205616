import React, { useEffect } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import {
  commonSelectComponents,
  loadingMessage,
  noOptionsMessage
} from './common';
import s from '../Inputs.module.scss';

export default React.memo(
  ({
    name,
    value: inputValue,
    onChange,
    onBlur,
    fullWidth,
    suggestions,
    suggestionsLoading,
    useObject = false,
    required = false,
    isClearable = true,
    hasCopyBtn = true,
    label,
    hintMessage = null,
    errorMessage = null,
    variant = 'outlined',
    resetIfNotExists = false,
    disabled,
    ...rest
  }) => {
    let value = null;

    if (!useObject) {
      value = suggestions
        ? suggestions.find((item) => item.value === inputValue) || null
        : null;
    } else {
      value = inputValue;
    }

    useEffect(() => {
      if (resetIfNotExists) {
        if (inputValue && !value) {
          onChange(null, name);
        }
      }
    }, [resetIfNotExists, onChange, inputValue, value, name]);

    return (
      <Select
        className={s.Field}
        components={commonSelectComponents}
        hasCopyBtn={hasCopyBtn}
        inputId={name}
        isClearable={isClearable}
        isInitialLoading={!suggestions && suggestionsLoading}
        isSuggestionLoading={suggestionsLoading}
        loadingMessage={loadingMessage}
        noOptionsMessage={noOptionsMessage}
        openOnFocus={true}
        onBlur={onBlur}
        isDisabled={disabled}
        onChange={(item) => {
          onChange(!item || useObject ? item : item.value, name);
        }}
        styles={{
          menuPortal: (provided, state) => ({
            ...provided,
            zIndex: 9000
          })
        }}
        options={suggestions || []}
        placeholder={false}
        value={value}
        name={name}
        TextFieldProps={{
          label,
          error: !!errorMessage,
          helperText: errorMessage || hintMessage,
          InputLabelProps: {
            htmlFor: name,
            shrink: true,
            required
          },
          fullWidth,
          classes: {
            root: classNames(s.InputString, 'autocompleteInput')
          },
          variant
        }}
        menuPortalTarget={document.body}
        {...rest}
      />
    );
  }
);
