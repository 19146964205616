import { useMemo } from 'react';
import * as Yup from 'yup';
import { useValidationMultipleLinks } from '../../../../createEquipment/useValidationMultipleLinks';

export const useInfralistSectionValidationSchema = () => {
  const urlValidation = useValidationMultipleLinks();

  return useMemo(
    () =>
      Yup.object().shape({
        name_rvpo: Yup.string().required('Обязательное поле'),
        description_rvpo: Yup.string().required('Обязательное поле'),
        fpo_equipment_id: Yup.string().required('Обязательное поле'),
        model_rvpo: Yup.string().required('Обязательное поле'),
        unit: Yup.string().required('Обязательное поле'),
        equipment_external_url: urlValidation,
        quantity: Yup.number().required('Обязательное поле'),
        price: Yup.number().required('Обязательное поле')
      }),
    [urlValidation]
  );
};
