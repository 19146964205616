import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useContextStore } from '@proscom/prostore-react';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { getName } from '../../../utils/data/label';
import { queryLoaderWithExistenceCheck } from '../../../common/QueryLoader';
import { PageActionsBar } from '../../../common/PageActionsBar/PageActionsBar';
import { EditButton } from '../../../common/Form/EditButton/EditButton';
import SubTable from '../../../common/SubTable/SubTable';
import { useIndicatorForecast } from '../../../store/indicatorForecasts/useIndicatorForecast';
import { IndicatorForecast } from '../../../store/indicatorForecasts/IndicatorForecast';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { ActionButton } from '../../../common/Form/ActionButton';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../graphql/graphqlErrors';
import { INDICATOR_FORECAST_STORE } from '../../../store/names';
import { IndicatorForecastBreadCrumbs } from './IndicatorForecastBreadCrumbs';

const Parts = IndicatorForecast.parts;

export default function IndicatorForecastPage({ match }) {
  const indicatorForecastStore = useContextStore(INDICATOR_FORECAST_STORE);
  const user = useCurrentUser();

  const { indicatorForecastId } = match.params;

  const indicatorForecastQuery = useIndicatorForecast(
    indicatorForecastId,
    true
  );
  const indicatorForecast = indicatorForecastQuery.state.data;

  const canEdit = IndicatorForecast.canEdit(user, indicatorForecast);
  const canApprove = IndicatorForecast.canApprove(user, indicatorForecast);

  return (
    <>
      <IndicatorForecastBreadCrumbs />
      <PageTitle title={getName(indicatorForecast?.indicator)} withBackBtn />
      {queryLoaderWithExistenceCheck(indicatorForecastQuery) || (
        <>
          <PageActionsBar
            editButton={
              canEdit && <EditButton editLink={`${match.url}/edit`} />
            }
            actions={
              canApprove && (
                <ActionButton
                  onClick={() =>
                    indicatorForecastStore
                      .approve(indicatorForecast.id)
                      .catch((error) => {
                        showGraphqlErrorToast(
                          error,
                          mutationErrors.updateObject
                        );
                      })
                  }
                  title="Утвердить"
                />
              )
            }
          />
          <SubTable title="Общие сведения">
            <TableRow>
              <TableCell>{Parts.region.name}</TableCell>
              <TableCell>{getName(indicatorForecast.region)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{Parts.federal_project.name}</TableCell>
              <TableCell>
                {getName(indicatorForecast.federal_project)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{Parts.status.name}</TableCell>
              <TableCell>
                {IndicatorForecast.status.getName(indicatorForecast.status)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{Parts.indicator.name}</TableCell>
              <TableCell>{getName(indicatorForecast.indicator)}</TableCell>
            </TableRow>
          </SubTable>
          <SubTable>
            <TableRow>
              <TableCell>{Parts.indicator_plan_value.name}</TableCell>
              <TableCell>{indicatorForecast.indicator_plan_value}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{Parts.indicator_forecast_value.name}</TableCell>
              <TableCell>
                {indicatorForecast.indicator_forecast_value}
              </TableCell>
            </TableRow>
            {indicatorForecast.form_variant ===
              IndicatorForecast.FormVariant.PEOPLE && (
              <>
                <TableRow>
                  <TableCell>{Parts.people_demo.name}</TableCell>
                  <TableCell>{indicatorForecast.people_demo}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{Parts.people_total.name}</TableCell>
                  <TableCell>{indicatorForecast.people_total}</TableCell>
                </TableRow>
              </>
            )}
            {indicatorForecast.form_variant ===
              IndicatorForecast.FormVariant.ORGS && (
              <>
                <TableRow>
                  <TableCell>{Parts.orgs_demo.name}</TableCell>
                  <TableCell>{indicatorForecast.orgs_demo}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{Parts.orgs_total.name}</TableCell>
                  <TableCell>{indicatorForecast.orgs_total}</TableCell>
                </TableRow>
              </>
            )}
          </SubTable>
          <SubTable>
            <TableRow>
              <TableCell>Примечание</TableCell>
              <TableCell>{indicatorForecast.comment}</TableCell>
            </TableRow>
          </SubTable>
        </>
      )}
    </>
  );
}
