import React, { useCallback } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Form } from '../../../../store/form/Form';
import { NewTextInput } from '../../../../common/InputsNew/NewTextInput';
import { FormArrayInput } from '../../../../common/Form/FormArrayInput';
import { NewAutocompleteInput } from '../../../../common/InputsNew/NewAutocompleteInput';
import { DeleteButton } from '../../../../common/DeleteButton';

function getNewField(items) {
  return {
    order: items.length,
    name: '',
    input_type: Form.InputType.TEXT,
    entity_class: ''
  };
}

function deleteValue(items, index) {
  return items
    .filter((v, iV) => iV !== index)
    .map((v, iV) => ({ ...v, order: iV }));
}

export function FormFieldsInput(props) {
  const itemComponent = useCallback(({ item, index, onDelete, name }) => {
    return (
      <TableRow>
        <TableCell>{+item.order + 1}</TableCell>
        <TableCell>
          <NewTextInput name={`${name}.name`} />
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name={`${name}.input_type`}
            suggestions={Form.inputType.getOptions()}
            isClearable={false}
            hasCopyBtn={false}
          />
        </TableCell>
        <TableCell>
          {item.input_type === Form.InputType.SELECT && (
            <NewAutocompleteInput
              name={`${name}.entity_class`}
              suggestions={Form.entityClass.getOptions()}
              isClearable={false}
              hasCopyBtn={false}
            />
          )}
        </TableCell>
        <TableCell>
          <DeleteButton onClick={() => onDelete(index)} />
        </TableCell>
      </TableRow>
    );
  }, []);

  return (
    <FormArrayInput
      title="Поля"
      titleAdd="Добавить поле"
      cols={5}
      itemComponent={itemComponent}
      getNewValue={getNewField}
      deleteValue={deleteValue}
      {...props}
      head={
        <TableRow>
          <TableCell>№</TableCell>
          <TableCell>Название</TableCell>
          <TableCell>Тип</TableCell>
          <TableCell>Сущность</TableCell>
          <TableCell />
        </TableRow>
      }
    />
  );
}
