import React, { useCallback, useEffect, useState } from 'react';
import {
  addWeeks,
  isValid,
  parseISO,
  startOfDay,
  startOfMonth,
  subWeeks
} from 'date-fns';
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  dateToBackendString,
  removeTimezoneFromDate
} from '../../utils/data/date';
import nomRuLocale from '../../utils/fnsNomRuLocale';
import { isTableFieldRequired } from '../Table/TableInput';
import { Signals } from './Signals';
import s from './Inputs.module.scss';

const checkDate = (date) =>
  !date || (typeof date === 'string' && date.startsWith('000'))
    ? null
    : removeTimezoneFromDate(date);

const commonProps = {
  cancelLabel: 'Отменить',
  clearLabel: 'Очистить',
  okLabel: 'Применить',
  invalidDateMessage: 'Некорректная дата',
  minDateMessage: 'Дата меньше минимально возможной',
  maxDateMessage: 'Дата больше максимально возможной',
  InputProps: {
    classes: {
      root: s.TextInput
    },
    autoComplete: 'off'
  },
  classes: {
    root: s.Field
  }
};

const commonDateProps = {
  ...commonProps,
  format: 'dd.MM.yyyy'
};

const minimalDate = new Date('2000-01-01');

const KeyboardDatePickerMemo = React.memo(KeyboardDatePicker);

export const TableMonthInput = ({
  rowData,
  columnDef,
  onChange: handleChange,
  value: defaultValue
}) => {
  const value = checkDate(defaultValue);
  const requiredError =
    isTableFieldRequired(rowData, columnDef) && !value
      ? 'Должно быть заполнено'
      : undefined;
  const [error, setError] = useState(requiredError);

  useEffect(() => {
    setError(requiredError);
  }, [requiredError]);
  const onChange = useCallback(
    (date) => {
      if (isValid(date)) {
        handleChange(dateToBackendString(startOfMonth(date)));
        setError('');
      } else {
        handleChange(date);
      }
    },
    [handleChange]
  );
  const onError = useCallback(
    (err, val) => {
      setError(err || requiredError);
    },
    [requiredError, setError]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nomRuLocale}>
      <DatePicker
        clearable
        error={!!error}
        helperText={error}
        inputVariant="standard"
        onChange={onChange}
        onError={onError}
        {...commonProps}
        value={value}
        KeyboardButtonProps={{
          classes: {
            root: s.KeyboardBtn
          }
        }}
        views={['year', 'month']}
      />
    </MuiPickersUtilsProvider>
  );
};

export const TableDateInput = ({
  rowData,
  columnDef,
  onChange: handleChange,
  value: defaultValue
}) => {
  const value = checkDate(defaultValue);
  const requiredError =
    isTableFieldRequired(rowData, columnDef) && !value
      ? 'Должно быть заполнено'
      : undefined;
  const [error, setError] = useState(requiredError);

  useEffect(() => {
    setError(requiredError);
  }, [requiredError]);

  const onChange = useCallback(
    (date) => {
      if (isValid(date)) {
        handleChange(dateToBackendString(startOfDay(date)));
        setError('');
      } else {
        handleChange(date);
      }
    },
    [handleChange]
  );
  const onError = useCallback(
    (err, val) => {
      setError(err || requiredError);
    },
    [requiredError, setError]
  );

  return (
    <KeyboardDatePickerMemo
      clearable
      error={!!error}
      helperText={error}
      inputVariant="standard"
      onChange={onChange}
      onError={onError}
      {...commonDateProps}
      value={value}
      KeyboardButtonProps={{
        classes: {
          root: s.KeyboardBtn
        }
      }}
    />
  );
};

export const FormDateInput = ({
  field: { name, value: fieldValue },
  form,
  dependentFields,
  signals,
  startFieldName = null,
  endFieldName = null,
  ...other
}) => {
  const currentError = form.errors[name];
  const value = checkDate(fieldValue);

  const setDependentDate = useCallback(
    (startDateStr, fieldName) => {
      const startOfDay = parseISO(startDateStr);
      const f = endFieldName ? addWeeks : subWeeks;
      const dependentDate =
        fieldName === 'contract_sign_planned_start'
          ? startOfDay
          : f(startOfDay, 1);
      const formattedDependentDate = dateToBackendString(dependentDate);
      form.setFieldValue(fieldName, formattedDependentDate);
    },
    [endFieldName, form]
  );

  const onChange = useCallback(
    (date) => {
      if (!date) {
        form.setFieldValue(name, null);
      } else if (isValid(date)) {
        const formattedStartOfDay = dateToBackendString(startOfDay(date));
        const dependentFieldName = startFieldName
          ? startFieldName
          : endFieldName;
        form.setFieldValue(name, formattedStartOfDay);
        if (dependentFieldName) {
          setDependentDate(formattedStartOfDay, dependentFieldName);
        }
      } else {
        form.setFieldValue(name, date, false);
      }
    },
    [name, form, setDependentDate, endFieldName, startFieldName]
  );

  const onError = useCallback(
    (error) => {
      if (error && error !== currentError) {
        form.setFieldError(name, error);
      }
    },
    [currentError, name, form]
  );

  return (
    <>
      <KeyboardDatePickerMemo
        clearable
        inputVariant="outlined"
        name={name}
        value={value}
        error={Boolean(currentError)}
        helperText={currentError}
        onError={onError}
        onChange={onChange}
        minDate={minimalDate}
        format="dd.MM.yyyy"
        {...other}
        {...commonProps}
      />
      <Signals signals={signals && signals[name]} />
    </>
  );
};
