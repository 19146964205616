import gql from 'graphql-tag';
import { FRAGMENT_CLASSES_SCHEDULE } from '../queries/classesSchedules';

export const MUTATION_CREATE_OR_UPDATE_CLASSES_SCHEDULES = gql`
  mutation createOrUpdateClassesSchedules(
    $schedules: [ClassesScheduleInput!]!
  ) {
    result: createOrUpdateClassesSchedules(schedules: $schedules) {
      ...classesSchedule
    }
  }
  ${FRAGMENT_CLASSES_SCHEDULE}
`;

export const MUTATION_DELETE_CLASSES_SCHEDULES = gql`
  mutation deleteClassesSchedules($ids: [ID!]!) {
    result: deleteClassesSchedules(ids: $ids)
  }
`;
