import { useEffect } from 'react';

/**
 * За сколько пикселей от конца скролла начинать загрузку.
 * Необходимо так как высота экрана и scrollHeight могут
 * несовпадать на отмасштабированных страницах.
 */
const scrollTrigger = 10;

export const usePageScrollEndWatcher = (ref, callback) => {
  useEffect(() => {
    if (ref && ref.current) {
      const containerInformation = ref.current?.getBoundingClientRect();
      const isLoadMore = window.innerHeight > containerInformation.height;
      if (isLoadMore && callback) {
        callback();
      }
    }
  }, [callback, ref]);

  useEffect(() => {
    const watcher = () => {
      const scrollNearEnd =
        Math.abs(
          window.innerHeight + window.scrollY - document.body.scrollHeight
        ) <= scrollTrigger;
      if (scrollNearEnd && callback) {
        callback();
      }
    };

    if (callback) {
      window.addEventListener('scroll', watcher);
    }

    return () => window.removeEventListener('scroll', watcher);
  }, [callback]);
};
