import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useState } from 'react';
import { MUTATION_INFRALIST_FORMATION_COMPLETE } from '../../../graphql/mutations/infralist';
import { INFRALIST_QUERIES } from '../infralistQueries';

export const useFormInfralistMutation = () => {
  const client = useContextApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  const formInfralist = (infralistId, sectionId) => {
    setIsLoading(true);
    return client
      .mutate({
        mutation: MUTATION_INFRALIST_FORMATION_COMPLETE,
        variables: {
          infralist_id: infralistId,
          section_id: sectionId
        },
        update: (store) => {
          store.reset();
        },
        refetchQueries: [INFRALIST_QUERIES.SECTION]
      })
      .finally(() => setIsLoading(false));
  };

  return {
    isLoadingFormInfralist: isLoading,
    formInfralist
  };
};
