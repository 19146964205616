import { useEffect } from 'react';
import {
  useGraphqlWatchQuery,
  useGraphqlWatchQueryVars
} from '@proscom/prostore-apollo-react';
import { useIntervalRef, useTimeoutRef } from './useTimeoutRef';

const RETRY_DELAY = 5000;
const INTERVAL_DELAY = 60000;

// todo надо подумать, как это соотносится с retryOnNetworkError

export const useRetriableGraphqlVars = (options) => {
  const timeout = useTimeoutRef();
  const query = useGraphqlWatchQueryVars(options);
  const { error } = query.check;
  const { load } = query;
  const { loading } = query.state;

  useEffect(() => {
    if (error && !timeout.active() && !loading) {
      timeout.set(() => load(), RETRY_DELAY);
    }
  }, [error, timeout, load, loading]);

  return query;
};

export function useRetriableGraphql(options) {
  const timeout = useTimeoutRef();
  const query = useGraphqlWatchQuery(options);
  const { error } = query.check;
  const { load } = query;
  const { loading } = query.state;

  useEffect(() => {
    if (error && !timeout.active() && !loading) {
      timeout.set(() => load(), RETRY_DELAY);
    }
  }, [error, timeout, load, loading]);

  return query;
}

export function useIntervalGraphql(options, delay = INTERVAL_DELAY) {
  const interval = useIntervalRef();
  interval.setDelay(delay);

  const query = useGraphqlWatchQueryVars(options);
  const { error } = query.check;
  const { load } = query;
  const { loading } = query.state;

  useEffect(() => {
    if (!interval.active() && !loading) {
      interval.set(() => load());
    }
  }, [error, interval, load, loading]);

  return query;
}
