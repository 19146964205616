export const STORE_AUTH = 'STORE_AUTH';
export const STORE_EDITING = 'STORE_EDITING';
export const STORE_PURCHASE = 'STORE_PURCHASE';
export const STORE_ROADMAP_EVENT = 'STORE_ROADMAP_EVENT';
export const STORE_POF = 'STORE_POF';
export const STORE_ORGANIZATIONS = 'STORE_ORGANIZATIONS';
export const STORE_FORM = 'STORE_FORM';
export const SUBSIDIARY_ENTITY_STORE = 'SUBSIDIARY_ENTITY_STORE';
export const INDICATOR_STORE = 'INDICATOR_STORE';
export const RP_IMPLEMENTATION_FORECAST_STORE =
  'RP_IMPLEMENTATION_FORECAST_STORE';
export const INDICATOR_FORECAST_STORE = 'INDICATOR_FORECAST_STORE';
export const USER_STORE = 'USER_STORE';
export const ROLE_STORE = 'ROLE_STORE';
export const STORE_LOGIN = 'STORE_LOGIN';
export const STORE_FILE_TOKEN = 'STORE_FILE_TOKEN';
export const ADDITIONAL_DATA_STORE = 'ADDITIONAL_DATA_STORE';
export const STORE_NOTIFICATION_STORE = 'STORE_NOTIFICATION_STORE';
export const STORE_NOTIFICATION_COUNT_STORE = 'STORE_NOTIFICATION_COUNT_STORE';
export const STORE_PARENTAL_ASSISTANCE_GUIDELINE =
  'STORE_PARENTAL_ASSISTANCE_GUIDELINE';
export const STORE_INFRALIST = 'STORE_INFRALIST';
export const STORE_MENTORING_COVERAGE = 'STORE_MENTORING_COVERAGE';
export const CONTEST_DOCS_MONITORING_STORE = 'CONTEST_DOCS_MONITORING_STORE';
export const CONTEST_DOCS_FOR_SS_MONITORING_STORE =
  'CONTEST_DOCS_FOR_SS_MONITORING_STORE';
export const SUBSIDIARY_MONITORING_STORE = 'SUBSIDIARY_MONITORING_STORE';
