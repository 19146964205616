import React from 'react';
import { MultiSelectInline } from '../../../../../common/MultiSelectInline/MultiSelectInline';
import { EQUIPMENT_STATUSES } from '../../../../../store/infralist/equipmentStatusService';
import {
  INFRALIST_STATUSES,
  infralistStatusService
} from '../../../../../store/infralist/infralistStatusService';
import {
  INFRALIST_CHECK_STATUSES,
  infralistCheckStatusService
} from '../../../../../store/infralist/statuses/infralistCheckStatusService';
import { useInfralistUserAccesses } from '../../../../../store/infralist/useInfralistUserAccesses';
import { formatNumber } from '../../../../../utils/formatNumber';
import s from './FiltersEquipment.module.scss';

const optionsStatusColumn = [
  {
    label: INFRALIST_STATUSES.NOT_FORMED,
    value: 'NOT_FORMED',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.NOT_FORMED)
  },
  {
    label: INFRALIST_STATUSES.FORMED,
    value: 'FORMED',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.FORMED)
  },
  {
    label: INFRALIST_STATUSES.SIGNED,
    value: 'SIGNED',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.SIGNED)
  },
  {
    label: INFRALIST_STATUSES.NOT_SIGNED,
    value: 'NOT_SIGNED',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.NOT_SIGNED)
  }
];

const optionsStatusSignColumn = [
  {
    label: EQUIPMENT_STATUSES.SIGNED,
    value: 'SIGNED',
    color: infralistStatusService.getColor(EQUIPMENT_STATUSES.SIGNED)
  },
  {
    label: EQUIPMENT_STATUSES.NOT_SIGNED,
    value: 'NOT_SIGNED',
    color: infralistStatusService.getColor(EQUIPMENT_STATUSES.NOT_SIGNED)
  }
];

const optionsCheckColumn = [
  {
    label: INFRALIST_CHECK_STATUSES.NOT_CHECKED,
    value: 'NOT_CHECKED',
    color: infralistCheckStatusService.getColor(
      INFRALIST_CHECK_STATUSES.NOT_CHECKED
    )
  },
  {
    label: INFRALIST_CHECK_STATUSES.CHECKED_WITH_COMMENTS,
    value: 'THERE_ARE_COMMENTS',
    color: infralistCheckStatusService.getColor(
      INFRALIST_CHECK_STATUSES.CHECKED_WITH_COMMENTS
    )
  },
  {
    label: INFRALIST_CHECK_STATUSES.CHECKED_SUCCESS,
    value: 'NO_COMMENTS',
    color: infralistCheckStatusService.getColor(
      INFRALIST_CHECK_STATUSES.CHECKED_SUCCESS
    )
  }
];

const optionsApproveColumn = [
  {
    label: INFRALIST_STATUSES.ON_APPROVAL,
    value: 'ON_APPROVAL',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.ON_APPROVAL)
  },
  {
    label: INFRALIST_STATUSES.APPROVED,
    value: 'AGREED',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.APPROVED)
  },
  {
    label: INFRALIST_STATUSES.NOT_APPROVED,
    value: 'NOT_AGREED',
    color: infralistStatusService.getColor(INFRALIST_STATUSES.NOT_APPROVED)
  },
  {
    label: INFRALIST_STATUSES.APPROVED_WITH_COMMENTS,
    value: 'AGREED_WITH_REMARKS',
    color: infralistStatusService.getColor(
      INFRALIST_STATUSES.APPROVED_WITH_COMMENTS
    )
  }
];

export const FiltersEquipment = ({
  sum,
  statuses,
  setStatuses,
  checkStatuses,
  setCheckStatuses,
  approvementStatuses,
  setApprovementStatuses,
  infralistSectionApproveStatus
}) => {
  const { isFPO1, isFPO2, isRVPO1, isRVPO2 } = useInfralistUserAccesses();

  return (
    <div className={s.root}>
      <p className={s.sum}>Итого: {formatNumber(sum)} руб.</p>

      <div className={s.filters}>
        {isRVPO1 && (
          <MultiSelectInline
            placeholder="Статус"
            value={statuses}
            onChange={setStatuses}
            options={optionsStatusColumn}
            className={s.select}
          />
        )}

        {isRVPO2 && (
          <MultiSelectInline
            placeholder="Статус"
            value={statuses}
            onChange={setStatuses}
            options={optionsStatusSignColumn}
            className={s.select}
          />
        )}

        {(isFPO1 || isFPO2) && (
          <MultiSelectInline
            placeholder="Результат проверки"
            value={checkStatuses}
            onChange={setCheckStatuses}
            options={optionsCheckColumn}
            className={s.select}
          />
        )}

        {(isFPO1 || isFPO2 || isRVPO2 || !!infralistSectionApproveStatus) && (
          <MultiSelectInline
            placeholder="Статус согласования"
            value={approvementStatuses}
            onChange={setApprovementStatuses}
            options={optionsApproveColumn}
            className={s.select}
          />
        )}
      </div>
    </div>
  );
};
