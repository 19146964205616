import gql from 'graphql-tag';
import {
  FRAGMENT_SUBSIDIARY_ENTITY_ROADMAP_INDICATOR,
  FRAGMENT_SUBSIDIARY_ENTITY_ROADMAP_INDICATORS
} from '../queries/indicators';

export const MUTATION_UPDATE_SE_ROADMAP_INDICATOR = gql`
  mutation updateSeRoadmapIndicator($input: SeRoadmapIndicatorUpdateInput!) {
    result: updateSeRoadmapIndicator(input: $input) {
      ...subsidiaryEntityRoadmapIndicator
    }
  }
  ${FRAGMENT_SUBSIDIARY_ENTITY_ROADMAP_INDICATOR}
`;

export const MUTATION_CREATE_SE_ROADMAP_INDICATOR = gql`
  mutation createSeRoadmapIndicator($input: SeRoadmapIndicatorCreateInput!) {
    result: createSeRoadmapIndicator(input: $input) {
      ...subsidiaryEntityRoadmapIndicator
    }
  }
  ${FRAGMENT_SUBSIDIARY_ENTITY_ROADMAP_INDICATOR}
`;

export const MUTATION_DUPLICATE_SE_ROADMAP_INDICATORS = gql`
  mutation duplicateSeRoadmapIndicators(
    $input: DuplicateSeRoadmapIndicatorsInput!
  ) {
    result: duplicateSeRoadmapIndicators(input: $input) {
      ...subsidiaryEntityRoadmapIndicators
    }
  }
  ${FRAGMENT_SUBSIDIARY_ENTITY_ROADMAP_INDICATORS}
`;

export const MUTATION_DELETE_SE_ROADMAP_INDICATOR = gql`
  mutation deleteSeRoadmapIndicator($id: ID!) {
    result: deleteSeRoadmapIndicator(id: $id)
  }
`;

export const MUTATION_APPROVE_SE_ROADMAP_INDICATORS = gql`
  mutation approveSeRoadmapIndicators($ids: [ID!]!) {
    result: approveSeRoadmapIndicators(ids: $ids)
  }
`;
