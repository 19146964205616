import { clearApolloQueryCache } from '../../utils/apollo';
import {
  MUTATION_APPROVE_SE_ROADMAP_INDICATORS,
  MUTATION_CREATE_SE_ROADMAP_INDICATOR,
  MUTATION_DELETE_SE_ROADMAP_INDICATOR,
  MUTATION_DUPLICATE_SE_ROADMAP_INDICATORS,
  MUTATION_UPDATE_SE_ROADMAP_INDICATOR
} from '../../graphql/mutations/indicators';
import { Indicator } from './Indicator';

export class IndicatorStore {
  constructor({ client }) {
    this.client = client;
  }

  queriesToClear = [
    'subsidiaryEntityRoadmapIndicators',
    'subsidiaryEntityRoadmapIndicator',
    'roadmapIndicators',
    'regionalProject',
    'subsidiaryEntities',
    'roadmap'
  ];

  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  updateIndicator(data) {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_SE_ROADMAP_INDICATOR,
      variables: {
        input: Indicator.filterUpdateFields(data)
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  }

  createIndicator(data) {
    return this.client.mutate({
      mutation: MUTATION_CREATE_SE_ROADMAP_INDICATOR,
      variables: {
        input: Indicator.filterCreateFields(data)
      },
      refetchQueries: ['subsidiaryEntityRoadmapIndicators'],
      update: this.clearCache
    });
  }

  deleteIndicator = (data) => {
    return this.client.mutate({
      mutation: MUTATION_DELETE_SE_ROADMAP_INDICATOR,
      variables: {
        id: data
      },
      refetchQueries: ['subsidiaryEntityRoadmapIndicators'],
      update: this.clearCache
    });
  };

  sendForApproval(id) {
    return this.updateIndicator({
      id,
      status: Indicator.Status.ON_CONFIRMATION
    });
  }

  approveMultipleIndicators(ids) {
    return this.client.mutate({
      mutation: MUTATION_APPROVE_SE_ROADMAP_INDICATORS,
      variables: { ids },
      refetchQueries: ['subsidiaryEntityRoadmapIndicators'],
      update: this.clearCache
    });
  }

  copyIndicators = (data) => {
    return this.client.mutate({
      mutation: MUTATION_DUPLICATE_SE_ROADMAP_INDICATORS,
      variables: {
        input: data
      },
      refetchQueries: ['subsidiaryEntityRoadmapIndicators'],
      update: this.clearCache
    });
  };
}
