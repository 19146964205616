import gql from 'graphql-tag';

export const RP_IMPLEMENTATION_FORECAST = gql`
  fragment RpImplementationForecast on RpImplementationForecastType {
    id
    version
    number
    date
    region_id
    status
    editor_id
    updated_at
    approved_at
    region {
      id
      name
    }
    agreement_forecasts {
      federal_project_id
      agreement_number
      suggestions
      federal_project {
        name_full
        id
      }
    }
    indicator_forecasts {
      federal_project_id
      indicator_id
      forecast_value
      forecast_value_entered
      changes_justification
      annotation
      federal_project {
        id
        name_full
        project_code
      }
      indicator {
        name_full
        id
        unit {
          id
          name
        }
      }
    }
    passport_forecasts {
      federal_project_id
      suggestions
      federal_project {
        id
        name_full
      }
    }
    roadmap_forecasts {
      roadmap_id
      suggestions
      roadmap {
        id
        name_full
      }
    }
    subsidy_forecasts {
      federal_project_id
      expenses_direction_id
      forecast_value
      forecast_value_entered
      annotation
      federal_project {
        id
        name_full
        project_code
      }
      expenses_direction {
        id
        code
        name_full
      }
    }
    result_forecasts {
      changes_justification
      federal_project_id
      federal_project {
        id
        name_full
        project_code
      }
      result_federal_id
      result_federal {
        id
        name_full
      }
      forecast_value
      plan_value
    }
  }
`;

export const GET_QUERY_RP_IMPLEMENTATION_FORECAST = gql`
  query rpImplementationForecast($id: ID, $region_id: ID) {
    rpImplementationForecast(id: $id, region_id: $region_id) {
      ...RpImplementationForecast
    }
  }

  ${RP_IMPLEMENTATION_FORECAST}
`;
