import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { monthNames } from '../utils/data/date';

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 160,
    marginRight: 20
  }
}));

export const MonthSelect = React.memo(function MonthSelect({
  label,
  month,
  maxMonth,
  onChange,
  variant = 'outlined',
  className,
  ...props
}) {
  const classes = useStyles();
  const handleChange = (event) => {
    onChange({ month: event.target.value }, true);
  };

  return (
    <TextField
      select
      value={month}
      onChange={handleChange}
      className={classNames(classes.input, className)}
      variant={variant}
      label={label}
      {...props}
    >
      {monthNames
        .filter((month, iMonth) => !maxMonth || iMonth + 1 <= maxMonth)
        .map((month, iMonth) => {
          return (
            <MenuItem key={iMonth} value={iMonth + 1}>
              {month}
            </MenuItem>
          );
        })}
    </TextField>
  );
});
