import React from 'react';
import { FastField } from 'formik';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SubTable from '../../../common/SubTable/SubTable';
import { ParentalAssistanceGuideline } from '../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import { NewAutocompleteInput } from '../../../common/InputsNew/NewAutocompleteInput';
import { TextInput, TextInputNumeric } from '../../../common/Inputs/TextInput';
import { SubTableHeading } from '../../../common/SubTable/SubTableHeading';
import { UniversalDocumentSimple } from '../common/FileUpload/DownloadFileInput';
import { PossibleChoiceFields } from './PossibleChoiceFields';
import { ServiceDeliveryFormatsFields } from './ServiceDeliveryFormatsFields';
import s from '../ParentalAssistanceGuidelinesRegional/ParentalAssistanceGuidelinesRegionalPage.module.scss';

export const ProcedureOrganizationalPart = ({
  values,
  setValue,
  touched,
  errors
}) => {
  return (
    <SubTable title="II. Порядок оказания услуг" editVariant>
      <TableRow>
        <TableCell className={s.question}>
          1. Перечислите категории получателей услуг, имеющих право на получение
          услуги без взимания платы
        </TableCell>
        <TableCell>
          <FastField
            name="free_service_recipient_groups"
            component={TextInput}
            label="Перечислите категории"
            multiline
            fullWidth
          />
        </TableCell>
      </TableRow>
      <ServiceDeliveryFormatsFields
        setValue={setValue}
        title="2. Перечислите возможные форматы предоставления услуги (очные консультации, дистанционные консультации, выездные консультации и др.)"
        serviceDeliveryFormatsTitle={'Выберите из списка (возможно несколько)'}
        otherServiceDeliveryFormatsTitle={'Другие форматы (если нет в списке)'}
      />
      <TableRow>
        <TableCell className={s.question}>
          3. Перечислите категории получателей услуг, имеющих право на получение
          услуги в особом формате (например, в формате выездных консультаций).
          Опишите условия и порядок предоставления услуги
        </TableCell>
        <TableCell>
          <FastField
            name="special_categories_of_service_recievers"
            component={TextInput}
            label="Перечислите категории получателей услуг"
            multiline
            fullWidth
          />
          <FastField
            name="special_categories_of_service_recievers_description"
            component={TextInput}
            label="Опишите условия и порядок предоставления услуг"
            multiline
            fullWidth
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          4. Предусмотрена ли возможность присмотра за ребенком на время
          оказания услуги?
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="babysitting_in_service_time"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            label={'Выбор из списка'}
            hasCopyBtn={false}
            fullWidth
          />
          <FastField
            name="babysitting_in_service_time_description"
            component={TextInput}
            label="Комментарий (при наличии)"
            multiline
            fullWidth
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          5. Предусмотрена ли зона ожидания в Организации/Службе для родителей с
          детьми?
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="waiting_zone_exists"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            label="Выбор из списка"
            hasCopyBtn={false}
            fullWidth
          />
          <FastField
            name="waiting_zone_description"
            component={TextInput}
            label="Комментарий (при наличии)"
            multiline
            fullWidth
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          6. Предусмотрена ли зона комфортного пребывания в Организации/Службе
          для детей с ограниченными возможностями здоровья?
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="comfortable_zone_for_disabled_children"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            label="Выбор из списка"
            hasCopyBtn={false}
            fullWidth
          />
          <FastField
            name="comfortable_zone_for_disabled_children_description"
            component={TextInput}
            label="Комментарий (при наличии)"
            multiline
            fullWidth
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          7. Оказывает ли Организация/Служба услуги психолого-педагогической,
          методической и консультативной помощи родителям (законным
          представителям) на возмездной (платной основе)? Опишите условия и
          порядок их оказания.
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="paid_consultations_for_parents"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            label="Выбор из списка"
            hasCopyBtn={false}
            fullWidth
          />
          {values.paid_consultations_for_parents && (
            <FastField
              name="paid_consultations_for_parents_description"
              component={TextInput}
              label="Опишите условия оказания услуги"
              multiline
              fullWidth
              errorMessage={
                touched.paid_consultations_for_parents_description &&
                errors.paid_consultations_for_parents_description
              }
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          8. Перечислите предусмотренные способы записи для получения услуги
          (например, электронная запись, по телефону и пр.)
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            label="Выбор из списка (возможно несколько)"
            name="registration_methods"
            suggestions={ParentalAssistanceGuideline.registrationMethods.getOptions()}
            handleChange={setValue}
            hasCopyBtn={false}
            fullWidth
            isMulti
            useObject
          />
          <FastField
            name="other_registration_methods"
            component={TextInput}
            label="Другие способы (если нет в списке)"
            multiline
            fullWidth
          />
        </TableCell>
      </TableRow>
      <PossibleChoiceFields startOfNumerate={9} />
      <TableRow>
        <TableCell className={s.question}>
          11. Установлена ли максимальная продолжительность ожидания получения
          услуги с момента записи? Сколько дней?
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="maximum_waiting_time_exists"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            label="Выбор из списка"
            hasCopyBtn={false}
            fullWidth
          />
          {values.maximum_waiting_time_exists && (
            <FastField
              name="maximum_waiting_time"
              component={TextInputNumeric}
              label="Продолжительность ожидания (дней)"
              errorMessage={
                touched.maximum_waiting_time && errors.maximum_waiting_time
              }
              fullWidth
              unit=""
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          12. Фактическая средняя продолжительность ожидания получения услуги с
          момента записи (дней)?
        </TableCell>
        <TableCell>
          <FastField
            name="average_duration_of_services_receivement"
            component={TextInputNumeric}
            label="Продолжительность ожидания (дней)"
            fullWidth
            unit=""
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          13. Фактическая средняя продолжительность оказания услуги (минут)?
        </TableCell>
        <TableCell>
          <FastField
            name="average_duration_of_services_provisioning"
            component={TextInputNumeric}
            label="Продолжительность услуги (минут)"
            fullWidth
            unit=""
          />
        </TableCell>
      </TableRow>
      <TableRow className={s.headerCell}>
        <TableCell colSpan={2}>
          14. Проводится ли Организацией/Службой информационно-разъяснительная
          деятельность, направленная на доведение до родителей (законных
          представителей) детей информации о возможности обращаться за
          получением услуги, в том числе:
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          - размещена ли информация о деятельности Организации/Службы на
          официальном сайте Организации/Службы в сети Интернет и иных местах, в
          том числе в помещении Организации/Службы
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="official_site_has_info"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            label="Выбор из списка"
            hasCopyBtn={false}
            fullWidth
          />
          <FastField
            name="official_site_has_info_description"
            label="Укажите ссылку в сети Интернет"
            component={TextInput}
            multiline
            fullWidth
            errorMessage={
              touched.official_site_has_info_description &&
              errors.official_site_has_info_description
            }
          />
          <UniversalDocumentSimple
            name={'official_site_info_files'}
            buttonTitle={'Прикрепите файл(-ы)'}
            canEditDescription={false}
            hasTitle={false}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          - размещена ли информация о специалистах, осуществляющих оказание
          услуг, в том числе их образовании, квалификации и опыте работы, на
          официальном сайте Организации/Службы в сети Интернет
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="official_site_has_specialists_info"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            label="Выбор из списка"
            hasCopyBtn={false}
            fullWidth
          />
          <FastField
            name="official_site_has_specialists_info_description"
            component={TextInput}
            label="Укажите ссылку в сети Интернет, где размещена данная информация"
            multiline
            fullWidth
            errorMessage={
              touched.official_site_has_specialists_info_description &&
              errors.official_site_has_specialists_info_description
            }
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          - есть ли утвержденный медиаплан по информационному освещению
          деятельности Организации/Службы, направленного на привлечение внимания
          населения к возможности получить услугу. Приложите файл.
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="has_approved_mediaplan"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            label="Выбор из списка"
            hasCopyBtn={false}
            fullWidth
          />
          {values.has_approved_mediaplan && (
            <UniversalDocumentSimple
              name={'approved_mediaplan'}
              canEditDescription={false}
              buttonTitle={'Прикрепите файл'}
              errorMessage={
                touched.approved_mediaplan && errors.approved_mediaplan
              }
              hasTitle={false}
              isMulti={false}
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          - опишите реализуемые меры, перечислите информационные каналы и
          перечень вопросов, по которым получатели услуг получают информацию
        </TableCell>
        <TableCell>
          <FastField
            name="information_distribution_description"
            label="Опишите реализуемые меры и перечислите вопросы, по которым получатели услуг получают информацию"
            component={TextInput}
            multiline
            fullWidth
          />
          <FastField
            name="information_distribution_links"
            label="Укажите ссылку в сети Интернет"
            component={TextInput}
            errorMessage={
              touched.information_distribution_links &&
              errors.information_distribution_links
            }
            multiline
            fullWidth
          />
          <UniversalDocumentSimple
            name={'information_distribution_protocols'}
            buttonTitle={'Прикрепите файл(-ы)'}
            canEditDescription={false}
            hasTitle={false}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          15. Опишите основные типовые ситуации, типовые консультации (в том
          числе основные вопросы, с которыми обращаются родители (законные
          представители) детей)
        </TableCell>
        <TableCell>
          <FastField
            name="typical_consultations_description"
            component={TextInput}
            label={'Опишите типовые ситуации, основные вопросы'}
            multiline
            fullWidth
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          16. Проводится ли оценка качества оказываемых услуг? Опишите методику
          и порядок оценки качества услуг
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="services_quality_is_being_controlled"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            label={'Проводится ли оценка качества услуг?'}
            hasCopyBtn={false}
            fullWidth
          />
          {!(
            values.services_quality_is_being_controlled ===
            ParentalAssistanceGuideline.yesNoSuggestions[1].value
          ) && (
            <NewAutocompleteInput
              name="services_quality_is_being_controlled_on_portal"
              suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
              label='Оценка качества услуг осуществляется на Портале "Растим детей"?'
              hasCopyBtn={false}
              fullWidth
            />
          )}
          {values.services_quality_is_being_controlled_on_portal ===
            ParentalAssistanceGuideline.yesNoSuggestions[1].value && (
            <FastField
              name="services_quality_control_methodology"
              label="Опишите методику оценки (коротко)"
              component={TextInput}
              multiline
              fullWidth
              errorMessage={
                touched.services_quality_control_methodology &&
                errors.services_quality_control_methodology
              }
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={s.question}>
          17. Опишите проблемы, которые возникали и/или возникают при
          организации процесса оказания услуги (при наличии)
        </TableCell>
        <TableCell>
          <NewAutocompleteInput
            name="problems_in_new_service_introduction_exist"
            suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
            label="Возникали ли проблемы?"
            hasCopyBtn={false}
            fullWidth
          />
          {values.problems_in_new_service_introduction_exist && (
            <FastField
              label="Описание проблем"
              name="problems_in_new_service_introduction_description"
              component={TextInput}
              errorMessage={
                touched.problems_in_new_service_introduction_description &&
                errors.problems_in_new_service_introduction_description
              }
              multiline
              fullWidth
            />
          )}
        </TableCell>
      </TableRow>
    </SubTable>
  );
};
