import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useDeleteConfirmation } from './useDeleteConfirmation';
import s from '../../routes/purchases/purchase/PurchasePage.module.scss';

export const DeleteButtonWithConfirmation = ({
  onDelete,
  isDeleting,
  entityName
}) => {
  const [dialog, handleClickOpen] = useDeleteConfirmation(onDelete, {
    entityName
  });

  return (
    <>
      <Tooltip title="Удалить">
        <IconButton
          color="secondary"
          onClick={handleClickOpen}
          classes={{ root: s.IconButton }}
        >
          {isDeleting ? (
            <CircularProgress size={18} color="secondary" />
          ) : (
            <DeleteIcon />
          )}
        </IconButton>
      </Tooltip>
      {dialog}
    </>
  );
};
