import flatten from 'lodash-es/flatten';
import isNil from 'lodash-es/isNil';
import { User } from '../role/User';
import { tryNumberLike } from '../../utils/helpers';
import { toFixed } from '../../utils/number';

export class ContestDocsMonitoringEntity {
  static Status = {
    DRAFT: 'DRAFT',
    RETURNED: 'RETURNED',
    APPROVED: 'APPROVED',
    EDIT_REQUESTED: 'EDIT_REQUESTED'
  };

  static statusLabel = {
    [ContestDocsMonitoringEntity.Status.DRAFT]: 'Черновик',
    [ContestDocsMonitoringEntity.Status.RETURNED]: 'На доработке',
    [ContestDocsMonitoringEntity.Status.APPROVED]: 'Утвержден',
    [ContestDocsMonitoringEntity.Status.EDIT_REQUESTED]:
      'Запрошено редактирование'
  };

  static statusSuggestions = [
    { value: ContestDocsMonitoringEntity.Status.DRAFT, label: 'Черновик' },
    {
      value: ContestDocsMonitoringEntity.Status.RETURNED,
      label: 'На доработке'
    },
    { value: ContestDocsMonitoringEntity.Status.APPROVED, label: 'Утвержден' },
    {
      value: ContestDocsMonitoringEntity.Status.EDIT_REQUESTED,
      label: 'Запрошено редактирование'
    }
  ];

  static IndicatorFillType = {
    CALCULATED: 'CALCULATED',
    MANUAL: 'MANUAL'
  };

  static formSubmitStatus = {
    Submit: 'Submit',
    Approve: 'Approve'
  };

  static fromForm(data) {
    const indicators = flatten(data.roadmaps?.map((rm) => rm.indicators) || []);

    return {
      id: data.id,
      analytical_information: data.analytical_information || '',
      indicators: indicators.map((ind) => ({
        roadmap_indicator_id: ind.id,
        value: tryNumberLike(ind.value, null, true),
        components:
          ind.components && ind.components.length > 0
            ? ind.components.map((comp) => ({
                component_id: comp.id,
                value: tryNumberLike(comp.value, null, true)
              }))
            : null
      }))
    };
  }

  static toForm(data) {
    const roadmaps = data?.roadmaps?.map((rm) => ({
      id: rm.roadmap?.id || '',
      name: rm.roadmap?.name || '',
      indicators:
        rm.roadmap_indicators?.map((ind) => {
          const indValue = !isNil(ind.value) ? ind.value.toString() : '';
          const indPrecision = ind.roadmap_indicator?.precision ?? undefined;

          return {
            id: ind.roadmap_indicator?.id || '',
            value: toFixed(indValue, indPrecision) ?? '',
            precision: indPrecision,
            fill_type: ind.roadmap_indicator?.fill_type ?? undefined,
            components: ind.components?.map((comp) => {
              const compValue = !isNil(comp.value) ? comp.value.toString() : '';
              const compPrecision = comp.component?.precision ?? undefined;

              return {
                id: comp.component?.id || '',
                name: comp.component?.name || '',
                name_full: comp.component?.name_full || '',
                code: comp.component?.code || '',
                unit: comp.component?.unit?.name_local || '',
                precision: compPrecision,
                fill_type: comp.component?.fill_type ?? undefined,
                previous_value: !isNil(comp.previous_value)
                  ? comp.previous_value.toString()
                  : '',
                value: toFixed(compValue, compPrecision) ?? ''
              };
            })
          };
        }) || []
    }));

    return {
      id: data?.id,
      analytical_information: data?.analytical_information || '',
      status: data?.status || ContestDocsMonitoringEntity.Status.DRAFT,
      roadmaps: roadmaps || []
    };
  }

  static getUrl(entity) {
    return `/contestDocsMonitoring/edit/${entity.id}`;
  }

  static canView(user) {
    return User.hasPermission(
      user,
      'monthly_monitoring.tender_documentation.view'
    );
  }

  static canEdit(user, item) {
    const { isRegionAdmin } = User.getRole(user);
    const hasPermission = User.hasPermission(
      user,
      'monthly_monitoring.tender_documentation.crud'
    );

    const userRegionId = user?.region?.id;
    const itemRegionId = item?.region?.id;

    const allowedByRole =
      item && isRegionAdmin ? userRegionId === itemRegionId : true;

    return hasPermission && allowedByRole;
  }

  static canRevoke(user) {
    return User.hasPermission(
      user,
      'monthly_monitoring.tender_documentation.revoke_approval'
    );
  }
}
