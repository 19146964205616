import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_PERMISSIONS } from '../../graphql/queries/permissions';

const permissionsQueryOptions = {
  query: QUERY_GET_PERMISSIONS,
  mapData: (result) => result.permissions,
  skipQuery: skipIfNull(null)
};

export function usePermissions() {
  return useRetriableGraphql({
    queryOptions: permissionsQueryOptions,
    variables: {}
  });
}
