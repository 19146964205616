import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useState } from 'react';
import { MUTATION_INFRALIST_RESET } from '../../../graphql/mutations/infralist';
import { clearApolloQueryCache } from '../../../utils/apollo';
import { INFRALIST_QUERIES } from '../infralistQueries';

export const useResetInfralistMutation = () => {
  const client = useContextApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  const resetInfralist = (infralistId) => {
    setIsLoading(true);
    return client.mutate({
      mutation: MUTATION_INFRALIST_RESET,
      variables: {
        infralist_id: infralistId
      },
      update: (store) => {
        clearApolloQueryCache(store, [
          INFRALIST_QUERIES.SECTION,
          INFRALIST_QUERIES.DIRECTION,
          INFRALIST_QUERIES.DIRECTIONS_LIST,
          INFRALIST_QUERIES.DIRECTION_SECTIONS,
          INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT
        ]);
        setIsLoading(false);
      },
      refetchQueries: [
        INFRALIST_QUERIES.DIRECTIONS_LIST,
        INFRALIST_QUERIES.INFRALIST
      ]
    });
  };

  return {
    isLoadingResetInfralist: isLoading,
    resetInfralist
  };
};
