import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useCallback } from 'react';
import {
  MUTATION_DELETE_EQUIPMENT_LOCK,
  MUTATION_DELETE_MASS_EQUIPMENT_LOCK,
  MUTATION_SET_EQUIPMENT_LOCK
} from '../../../graphql/mutations/equipment';

export const useLockActions = () => {
  const client = useContextApolloClient();

  const lockEditing = useCallback(
    ({ objectId, objectType }) => {
      return client.mutate({
        mutation: MUTATION_SET_EQUIPMENT_LOCK,
        variables: {
          input: {
            object_id: objectId,
            object_type: objectType
          }
        }
      });
    },
    [client]
  );

  const unlockEditing = useCallback(
    ({ objectId, objectType }) => {
      return client.mutate({
        mutation: MUTATION_DELETE_EQUIPMENT_LOCK,
        variables: {
          input: {
            object_id: objectId,
            object_type: objectType
          }
        }
      });
    },
    [client]
  );

  const unlockMassEditing = useCallback(
    (objects) => {
      return client.mutate({
        mutation: MUTATION_DELETE_MASS_EQUIPMENT_LOCK,
        variables: {
          input: objects
        }
      });
    },
    [client]
  );

  return { lockEditing, unlockEditing, unlockMassEditing };
};
