import { clearApolloQueryCache } from '../../utils/apollo';
import { IndicatorForecast } from '../indicatorForecasts/IndicatorForecast';
import { MUTATION_UPDATE_INDICATOR_FORECAST } from '../../graphql/mutations/indicatorForecast';

export class IndicatorForecastStore {
  constructor({ client }) {
    this.client = client;
  }

  queriesToClear = ['indicatorForecasts', 'indicatorForecast'];

  clearCache = (store) => {
    clearApolloQueryCache(store, this.queriesToClear);
  };

  updateIndicatorForecast(data) {
    return this.client.mutate({
      mutation: MUTATION_UPDATE_INDICATOR_FORECAST,
      variables: {
        input: IndicatorForecast.filterUpdateFields(data)
      },
      refetchQueries: this.queriesToClear,
      update: this.clearCache
    });
  }

  approve(id) {
    return this.updateIndicatorForecast({
      id,
      status: IndicatorForecast.Status.APPROVED_BY_REGION
    });
  }
}
