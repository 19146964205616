import React, { useCallback, useEffect, useMemo } from 'react';

import { useRoadmapFederalEventsForFilter } from '../../store/roadmap/useRoadmapFederalEvents';
import { useMemoSuggestions } from '../../utils/suggestions';
import { useRoadmaps } from '../../store/roadmap/useRoadmaps';
import { RegionalEvent } from '../../store/roadmap/RegionalEvent';
import { makeOptionator } from '../../utils/data/label';
import AutocompleteBaseInput from '../../common/Inputs/Selects/AutocompleteBaseInput';
import { DatePickerRanger } from '../../common/DatePickerRanger/DatePickerRanger';
import {
  dateToBackendString,
  getCurrentYear,
  yearsSuggestions
} from '../../utils/data/date';

const statusOptions = [
  RegionalEvent.Status.APPROVAL,
  RegionalEvent.Status.COMPLETED,
  RegionalEvent.Status.NOT_COMPLETED,
  RegionalEvent.Status.RETURNED
].map(makeOptionator(RegionalEvent.status.getName));

export function useRegionalRoadmapEventsFilter(query, changeQuery) {
  const year = query.year || getCurrentYear().toString();

  const filter = {
    year: +year,
    roadmap_ids: query.roadmap_ids || undefined,
    status: query.status || undefined,
    roadmap_event_ids: query.roadmap_event_ids || undefined,
    plan_term:
      query.planTermStartDate || query.planTermEndDate
        ? {
            start: query.planTermStartDate || undefined,
            end: query.planTermEndDate || undefined
          }
        : undefined,
    status_changed_at:
      query.statusChangedAtStartDate || query.statusChangedAtEndDate
        ? {
            start: query.statusChangedAtStartDate || undefined,
            end: query.statusChangedAtEndDate || undefined
          }
        : undefined
  };

  const setFilterValue = useCallback(
    (value, name) => {
      const queryObj = {
        [name]: value ?? undefined,
        page: 0
      };
      return changeQuery(queryObj);
    },
    [changeQuery]
  );

  useEffect(() => {
    setFilterValue(year, 'year');
  }, [year, setFilterValue]);

  const roadmapFederalEventsQuery = useRoadmapFederalEventsForFilter(
    filter.roadmap_ids,
    filter.year
  );
  const roadmapFederalEvents = roadmapFederalEventsQuery.state.data;
  const roadmapFederalEventsSuggestions = useMemoSuggestions(
    roadmapFederalEvents
  );

  const roadmapsFilter = useMemo(() => {
    return {
      implementation_years: [+year]
    };
  }, [year]);
  const roadmapsQuery = useRoadmaps(roadmapsFilter);
  const roadmaps = roadmapsQuery.state.data?.data;
  const roadmapsSuggestions = useMemoSuggestions(roadmaps);

  const handleChange = (field) => (value) => {
    changeQuery({ [field]: value ? dateToBackendString(value) : '' });
  };

  const filterComponent = (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Год"
        name="year"
        suggestions={yearsSuggestions}
        value={year}
        variant="standard"
        isClearable={false}
      />
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Дорожная карта"
        name="roadmap_ids"
        suggestions={roadmapsSuggestions}
        suggestionsLoading={roadmapsQuery.check.spinner}
        value={filter.roadmap_ids}
        resetIfNotExists={true}
        variant="standard"
      />
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Федеральное мероприятие"
        name="roadmap_event_ids"
        suggestions={roadmapFederalEventsSuggestions}
        suggestionsLoading={roadmapFederalEventsQuery.check.spinner}
        value={filter.roadmap_event_ids}
        resetIfNotExists={true}
        variant="standard"
      />
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Статус"
        name="status"
        suggestions={statusOptions}
        value={filter.status}
        variant="standard"
      />
      <DatePickerRanger
        sinceTitle="От"
        tillTitle="До"
        label="Плановый срок"
        startDate={query.planTermStartDate}
        endDate={query.planTermEndDate}
        onStartChange={handleChange('planTermStartDate')}
        onEndChange={handleChange('planTermEndDate')}
      />
      <DatePickerRanger
        sinceTitle="От"
        tillTitle="До"
        label="Дата изменения статуса"
        startDate={query.statusChangedAtStartDate}
        endDate={query.statusChangedAtEndDate}
        onStartChange={handleChange('statusChangedAtStartDate')}
        onEndChange={handleChange('statusChangedAtEndDate')}
      />
    </div>
  );
  return [filter, filterComponent];
}
