import { pick } from 'lodash-es';
import { EnumHelpers } from '../../utils/EnumHelpers';

const formFields = ['name', 'fields'];

const formFieldFields = [
  'id',
  'key',
  'name',
  'input_type',
  'entity_class',
  'order'
];

export class Form {
  static EntityClass = {
    FederalProject: 'FederalProject',
    RegionalProject: 'RegionalProject',
    TaskFp: 'TaskFp',
    ResultFp: 'ResultFp',
    Roadmap: 'Roadmap',
    RoadmapEvent: 'RoadmapEvent',
    RoadmapIndicator: 'RoadmapIndicator',
    SubsidiaryEntity: 'SubsidiaryEntity',
    ExpensesDirection: 'ExpensesDirection',
    SupplierDetermination: 'SupplierDetermination',
    Organization: 'Organization',
    Grbs: 'Grbs',
    FederalDistrict: 'FederalDistrict',
    Region: 'Region',
    Users: 'Users',
    NonCommercialOrganizations: 'NonCommercialOrganizations'
    // Form: 'Form'
  };

  static entityClass = new EnumHelpers(this.EntityClass, {
    FederalProject: 'Федеральный проект',
    RegionalProject: 'Региональный проект',
    TaskFp: 'Задача ФП',
    ResultFp: 'Результат ФП',
    Roadmap: 'Дорожная карта',
    RoadmapEvent: 'Мероприятие ДК',
    RegionalRoadmapEvent: 'Региональное мероприятие ДК',
    RoadmapIndicator: 'Индикатор ДК',
    SubsidiaryEntity: 'Субсидиарная сущность',
    ExpensesDirection: 'Направление расхода',
    SupplierDetermination: 'Способ определения поставщика',
    Organization: 'Организация',
    Grbs: 'ГРБС',
    FederalDistrict: 'Федеральный округ',
    Region: 'Субъект РФ',
    Users: 'Пользователь',
    NonCommercialOrganizations: 'Некоммерческие организации'
    // Form: 'Форма'
  });

  static InputType = {
    TEXT: 'TEXT',
    INTEGER: 'INTEGER',
    FLOAT: 'FLOAT',
    YEAR: 'YEAR',
    MONTH: 'MONTH',
    CURRENCY: 'CURRENCY',
    SELECT: 'SELECT'
  };

  static inputType = new EnumHelpers(this.InputType, {
    TEXT: 'Текст',
    INTEGER: 'Целое число',
    FLOAT: 'Дробное число',
    YEAR: 'Год',
    MONTH: 'Месяц',
    CURRENCY: 'Денежная сумма',
    SELECT: 'Сущность'
  });

  static filterUpdateFields(data) {
    return {
      ...pick(data, ['id', ...formFields]),
      fields: data.fields?.map((field) => pick(field, formFieldFields))
    };
  }

  static filterCreateFields(data) {
    return {
      ...pick(data, formFields),
      fields: data.fields?.map((field) => pick(field, formFieldFields))
    };
  }

  static toForm(form) {
    return form;
  }

  static fromForm(form) {
    return form;
  }

  static getUrl(form) {
    return '/forms/' + form.id;
  }
}
