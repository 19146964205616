import gql from 'graphql-tag';

export const MUTATION_UPDATE_CONTEST_DOCS_ENTITY = gql`
  mutation updateMonthlyMonitoringTenderDocumentation(
    $input: TenderDocumentationUpdateInput!
  ) {
    updateMonthlyMonitoringTenderDocumentation(input: $input) {
      id
      status
    }
  }
`;

export const MUTATION_DELETE_CONTEST_DOCS_ENTITY = gql`
  mutation deleteMonthlyMonitoringTenderDocumentation($id: ID!) {
    deleteMonthlyMonitoringTenderDocumentation(id: $id)
  }
`;

export const MUTATION_APPROVE_CONTEST_DOCS_ENTITY = gql`
  mutation approveMonthlyMonitoringTenderDocumentation($id: ID!) {
    approveMonthlyMonitoringTenderDocumentation(id: $id) {
      id
      status
    }
  }
`;

export const MUTATION_REQUEST_EDIT_CONTEST_DOCS_ENTITY = gql`
  mutation requestEditForMonthlyMonitoringTenderDocumentation($id: ID!) {
    requestEditForMonthlyMonitoringTenderDocumentation(id: $id) {
      id
      status
    }
  }
`;

export const MUTATION_ALLOW_EDIT_CONTEST_DOCS_ENTITY = gql`
  mutation allowEditForMonthlyMonitoringTenderDocumentation($id: ID!) {
    allowEditForMonthlyMonitoringTenderDocumentation(id: $id) {
      id
      status
    }
  }
`;

export const MUTATION_REJECT_EDIT_CONTEST_DOCS_ENTITY = gql`
  mutation rejectEditForMonthlyMonitoringTenderDocumentation($id: ID!) {
    rejectEditForMonthlyMonitoringTenderDocumentation(id: $id) {
      id
      status
    }
  }
`;

export const MUTATION_CREATE_CONTEST_DOCS_REPORT_FILE_TOKEN = gql`
  mutation createTenderDocumentationMonthlyMonitoringReportOneTimeToken {
    createTenderDocumentationMonthlyMonitoringReportOneTimeToken
  }
`;
