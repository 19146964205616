import React, { useMemo, Suspense } from 'react';
import { useHistory, useParams, generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ComponentWithTooltip } from '../../common/ComponentWithTooltip/ComponentWithTooltip';
import Dialog from '../../common/Dialog';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';
import { Popup } from '../../common/Popup/Popup';
import { ButtonLine } from '../../common/ButtonLine/ButtonLine';
import { ProgressButton } from '../../common/ProgressButton';
import { InfralistTable } from '../../common/infralist/InfralistTable/InfralistTable';
import { isInfralistTestModeEnable } from '../../config';
import {
  getGraphQLErrorInfo,
  showGraphqlErrorToast
} from '../../graphql/graphqlErrors';
import { useRecompleteApprovement } from '../../store/infralist/hooks/useRecompleteApprovement';
import { useResetInfralistMutation } from '../../store/infralist/hooks/useResetInfralistMutation';
import { infralistStatusDescriptions } from '../../store/infralist/infralistStatusService';
import { infralistRoutes } from '../../store/infralist/routes/infralistRoutes';
import { useInfralist } from '../../store/infralist/useInfralist';
import { useInfralistDirections } from '../../store/infralist/useInfralistDirections';
import { useVisibilityCommentLink } from '../../store/infralist/useVisibilityCommentLink';
import { useInfralistUserAccesses } from '../../store/infralist/useInfralistUserAccesses';
import { useNotApproveAll } from '../../store/infralist/hooks/useNotApproveAll';
import { useCompleteNotApprove } from '../../store/infralist/hooks/useCompleteNotApprove';
import { useDialog } from '../../utils/hooks/useDialog';
import { useSortPager } from '../../utils/hooks/useSortPager';
import { useSignInfralist } from '../../store/infralist/hooks/useSigningInfralist';
import { useCheckAll } from '../../store/infralist/hooks/useCheckAll';
import { useNotSignAll } from '../../store/infralist/hooks/useNotSignAll';
import { useCompleteCheckInfralist } from '../../store/infralist/hooks/useCompleteCheckInfralist';
import { CommentLink } from './CommentLink';
import { FiltersDirectionsSections } from './FiltersDirectionsSections';
import { useInfralistPageColumns } from './hooks/useInfralistPageColumns';
import { InfralistPageBreadCrumbs } from './InfralistPageBreadCrumbs';
import { InfralistStatus } from './InfralistStatus';
import { PageTitlePanel } from './PageTitlePanel';
import s from './InfralistPage.module.scss';

const InfralistDemo = React.lazy(() => import('./InfralistDemo'));

const title = 'Формирование и согласование перечня оборудования';

export default function InfralistPage() {
  const { infralistId } = useParams();
  const history = useHistory();
  const infralistQuery = useInfralist(infralistId);
  const infralistStatus = infralistQuery.state.data?.infralist_status?.name;
  const {
    showNotApproveAllActions,
    isFPO2,
    showSigningActions,
    showCheckActions
  } = useInfralistUserAccesses();
  const { isLoadingNotApproveAll, notApproveAll } = useNotApproveAll();
  const {
    isLoadingCompleteNotApprove,
    completeNotApprove,
    isLoadingCompleteNotApproveWithoutEquipment
  } = useCompleteNotApprove();

  const catchCompleteNotApproveErrors = (graphqlError) => {
    const errorInfo = getGraphQLErrorInfo(graphqlError);
    const completeError =
      'Для завершения согласования необходимо проставить статусы всем позициям оборудования';
    // Привязываемся к тексту ошибки
    const catchErrors = [
      'Not all infralists items are approve or disapprove',
      'Not all equipments approved'
    ];
    if (catchErrors.includes(errorInfo?.message)) {
      toast.error(completeError);
    } else {
      showGraphqlErrorToast(graphqlError);
    }
  };

  const {
    isOpen: isRecompletePopupOpen,
    open: openRecompletePopup,
    close: closeRecompletePopup
  } = useDialog();
  const {
    isOpen: isNotApproveAllSuccessPopupOpen,
    open: openNotApproveAllSuccessPopup,
    close: closeNotApproveAllSuccessPopup
  } = useDialog();
  const {
    isOpen: isCompleteNotApprovePopupOpen,
    open: openCompleteNotApprovePopup,
    close: closeCompleteNotApprovePopup
  } = useDialog();

  const { params, setSort, sorting, setFilter } = useSortPager({
    initialParams: {
      pagination: {
        page: 0,
        // выводим все сразу, постраничка не должна работать
        onePage: 100
      },
      filter: {
        infralist_id: infralistId
      }
    }
  });
  const {
    data: directionsData,
    isLoading: isLoadingDirections
  } = useInfralistDirections(params);

  const { canSeeCommentsIfHasComments } = useVisibilityCommentLink(
    infralistStatus
  );

  const columns = useInfralistPageColumns({ infralistStatus });
  const {
    isRecompleteButtonVisible,
    loading,
    reCompleteInfralistApprovement,
    checkApprovement,
    isAllEquipmentAgreed
  } = useRecompleteApprovement(infralistQuery);

  const reComplete = () => {
    reCompleteInfralistApprovement().finally(closeRecompletePopup);
  };

  const onOpenRecompletePopup = () => {
    checkApprovement().then(openRecompletePopup).catch(showGraphqlErrorToast);
  };

  const {
    isOpen: isCompleteApprovmentWithoutEquipmentPopup,
    open: openCompleteApprovmentWithoutEquipmentPopup,
    close: closeCompleteApprovmentWithoutEquipmentPopup
  } = useDialog();

  const sum = useMemo(
    () =>
      directionsData?.list.reduce(
        (total, current) => total + +current.direction_sum,
        0
      ),
    [directionsData]
  );
  const {
    isLoadingResetInfralist,
    resetInfralist
  } = useResetInfralistMutation();

  const {
    completeInfralistSigning,
    completeSigningLoading,
    completeSigningLoadingWithoutEquipment,
    closeSigningInfralistPopup,
    closeSigningInfralistPopupWithoutEquipment,
    isSigningInfralistPopup,
    isSigningInfralistPopupWithoutEquipment,
    openSigningInfralistPopup,
    openSigningInfralistPopupWithoutEquipment
  } = useSignInfralist(infralistId);

  const {
    checkAll,
    checkAllLoading,
    closeCheckAllPopup,
    isCheckAllPopupVisible
  } = useCheckAll(infralistId);
  const {
    notSignAll,
    notSignAllLoading,
    closeNotSignAllPopup,
    isNotSignAllPopupVisible
  } = useNotSignAll(infralistId);

  const {
    completeCheckInfralist,
    completeCheckLoading,
    completeCheckLoadingWithoutEquipment,
    isCompleteCheckPopupVisible,
    isCompleteCheckPopupVisibleWithoutEquipment,
    openCompleteCheckPopup,
    openCompleteCheckPopupWithoutEquipment,
    closeCompleteCheckPopupWithoutEquipment,
    closeCompleteCheckPopup
  } = useCompleteCheckInfralist(infralistId);

  return (
    <>
      <HeadTitle title={title} />
      <InfralistPageBreadCrumbs
        roadmapName={infralistQuery.state.data?.roadmap?.name}
      />
      <PageTitlePanel
        title={title}
        description={
          <ComponentWithTooltip
            isTooltipExists
            placement={'right'}
            title={
              <span className={s.statusDescription}>
                {infralistStatusDescriptions[infralistStatus]}
              </span>
            }
          >
            <InfralistStatus
              isLoading={infralistQuery.check.spinner}
              status={infralistStatus}
            />
          </ComponentWithTooltip>
        }
        withBackBtn={false}
        actions={
          <ButtonLine>
            {isInfralistTestModeEnable && (
              <ProgressButton
                variant="contained"
                color={'primary'}
                onClick={() => resetInfralist(infralistId)}
                isLoading={isLoadingResetInfralist}
              >
                Сбросить данные инфралиста
              </ProgressButton>
            )}
            {showNotApproveAllActions && (
              <>
                <ComponentWithTooltip
                  placement="bottom"
                  isTooltipExists={true}
                  width={300}
                  title={
                    'После нажатия на кнопку все позиции приобретут статус "Не согласовано"'
                  }
                >
                  <ProgressButton
                    color="secondary"
                    variant="outlined"
                    onClick={() =>
                      notApproveAll({ infralistId })
                        .then(openNotApproveAllSuccessPopup)
                        .catch(showGraphqlErrorToast)
                    }
                    isLoading={isLoadingNotApproveAll}
                    disabled={!infralistQuery.state.data?.editable}
                  >
                    Не согласовать все
                  </ProgressButton>
                </ComponentWithTooltip>
                <ProgressButton
                  color="secondary"
                  variant="contained"
                  disabled={!infralistQuery.state.data?.editable}
                  onClick={
                    isFPO2 &&
                    infralistQuery.state.data
                      ?.has_section_without_rvpo_equipments
                      ? openCompleteApprovmentWithoutEquipmentPopup
                      : openCompleteNotApprovePopup
                  }
                >
                  Завершить согласование
                </ProgressButton>
              </>
            )}
            {isRecompleteButtonVisible && (
              <ProgressButton
                variant="contained"
                color={'secondary'}
                onClick={onOpenRecompletePopup}
              >
                Осуществить пересогласование
              </ProgressButton>
            )}
            {showSigningActions && (
              <>
                <ProgressButton
                  variant="outlined"
                  color={'secondary'}
                  isLoading={notSignAllLoading}
                  onClick={notSignAll}
                  disabled={!infralistQuery.state.data?.editable}
                >
                  Не подписать все
                </ProgressButton>
                <ProgressButton
                  variant="contained"
                  color={'secondary'}
                  disabled={!infralistQuery.state.data?.editable}
                  onClick={
                    infralistQuery.state.data
                      ?.has_section_without_rvpo_equipments
                      ? openSigningInfralistPopupWithoutEquipment
                      : openSigningInfralistPopup
                  }
                >
                  Завершить подписание
                </ProgressButton>
                <Dialog
                  title={'Вы действительно хотите завершить подписание?'}
                  open={isSigningInfralistPopup}
                  handleClose={closeSigningInfralistPopup}
                  isLoading={completeSigningLoading}
                  handleAccept={() => completeInfralistSigning()}
                >
                  После подтверждения редактирование будет невозможно.
                </Dialog>
                <Dialog
                  title={'Вы действительно хотите завершить подписание?'}
                  open={isSigningInfralistPopupWithoutEquipment}
                  handleAccept={() => completeInfralistSigning(true)}
                  handleClose={closeSigningInfralistPopupWithoutEquipment}
                  isLoading={completeSigningLoading}
                  titleRefuse={'Отмена'}
                  titleAccept={'Подписать раздел'}
                  additionalButtons={
                    <ProgressButton
                      color="primary"
                      isLoading={completeSigningLoadingWithoutEquipment}
                      onClick={() => completeInfralistSigning(false)}
                    >
                      Не подписать раздел
                    </ProgressButton>
                  }
                >
                  После подтверждения редактирование будет невозможно.
                </Dialog>
                <Popup
                  open={isNotSignAllPopupVisible}
                  onClose={closeNotSignAllPopup}
                  title={'Все оборудования не подписаны успешно'}
                >
                  Для возврата инфралиста нажмите на кнопку "Завершить
                  подписание"
                </Popup>
              </>
            )}
            {showCheckActions && (
              <>
                <ProgressButton
                  variant="outlined"
                  color={'secondary'}
                  isLoading={checkAllLoading}
                  onClick={checkAll}
                  disabled={!infralistQuery.state.data?.editable}
                >
                  Замечаний нет
                </ProgressButton>
                <ProgressButton
                  variant="contained"
                  color={'secondary'}
                  disabled={!infralistQuery.state.data?.editable}
                  onClick={
                    infralistQuery.state.data
                      ?.has_section_without_rvpo_equipments
                      ? openCompleteCheckPopupWithoutEquipment
                      : openCompleteCheckPopup
                  }
                >
                  Завершить проверку
                </ProgressButton>
                <Dialog
                  title={'Вы действительно хотите завершить проверку?'}
                  open={isCompleteCheckPopupVisible}
                  handleClose={closeCompleteCheckPopup}
                  isLoading={completeCheckLoading}
                  handleAccept={() => completeCheckInfralist()}
                >
                  После подтверждения редактирование будет невозможно.
                </Dialog>
                <Dialog
                  title={'Вы действительно хотите завершить проверку?'}
                  open={isCompleteCheckPopupVisibleWithoutEquipment}
                  handleAccept={() => completeCheckInfralist(true)}
                  handleClose={closeCompleteCheckPopupWithoutEquipment}
                  isLoading={completeCheckLoading}
                  titleRefuse={'Отмена'}
                  titleAccept={'Замечаний нет'}
                  additionalButtons={
                    <ProgressButton
                      color="primary"
                      isLoading={completeCheckLoadingWithoutEquipment}
                      onClick={() => completeCheckInfralist(false)}
                    >
                      Есть замечания
                    </ProgressButton>
                  }
                >
                  После подтверждения редактирование будет невозможно.
                </Dialog>
                <Popup
                  open={isCheckAllPopupVisible}
                  onClose={closeCheckAllPopup}
                  title={'Все оборудования проверены успешно'}
                >
                  Для продолжения нажмите на кнопку "Завершить проверку"
                </Popup>
              </>
            )}
          </ButtonLine>
        }
      />

      {/* Кнопки для заполнения инфралиста */}
      <Suspense fallback={<div>Загрузка</div>}>
        {isInfralistTestModeEnable && <InfralistDemo />}
      </Suspense>

      <Popup
        open={isNotApproveAllSuccessPopupOpen}
        onClose={closeNotApproveAllSuccessPopup}
        title="Все позиции не согласованы успешно"
      >
        Для возврата инфралиста на доработу нажмите на кнопку "Завершить
        согласование"
      </Popup>

      <Dialog
        title="Вы действительно хотите завершить согласование?"
        open={isCompleteNotApprovePopupOpen}
        handleAccept={() =>
          completeNotApprove({ infralistId })
            .then(() => {
              closeCompleteNotApprovePopup();
              closeCompleteApprovmentWithoutEquipmentPopup();
            })
            .catch(catchCompleteNotApproveErrors)
        }
        handleClose={closeCompleteNotApprovePopup}
        isLoading={isLoadingCompleteNotApprove}
      >
        После подтверждения редактирование будет невозможно
      </Dialog>

      {infralistQuery.state?.data?.check_re_complete_available &&
      isAllEquipmentAgreed ? (
        <Dialog
          title={
            'Вы действительно хотите осуществить пересогласование перечня оборудования?'
          }
          open={isRecompletePopupOpen}
          handleClose={closeRecompletePopup}
          isLoading={loading}
          handleAccept={reComplete}
        >
          После подтверждения редактирование будет невозможно.
        </Dialog>
      ) : (
        <Popup open={isRecompletePopupOpen} onClose={closeRecompletePopup}>
          Для осуществления пересогласования необходимо проставить статус «Не
          согласовано» хотя бы одной позиции оборудования и завершить
          согласование в соответствующем разделе
        </Popup>
      )}
      <FiltersDirectionsSections
        sum={sum}
        statuses={params?.filter?.statuses}
        checkStatuses={params?.filter?.check_statuses}
        approvementStatuses={params?.filter?.approvement_statuses}
        setFilter={setFilter}
        infralistStatus={infralistStatus}
      />

      <InfralistTable
        onLineClick={(dataItem) => {
          history.push(
            generatePath(infralistRoutes.DIRECTION, {
              infralistId,
              directionId: dataItem.id
            })
          );
        }}
        columns={columns}
        data={directionsData?.list}
        isLoading={isLoadingDirections}
        getActions={(dataItem) =>
          canSeeCommentsIfHasComments && !dataItem.has_commentaries ? null : (
            <CommentLink
              href={`/infralist-comments/${infralistId}/direction/${dataItem.id}/general`}
              notRead={dataItem.has_commentaries}
            />
          )
        }
        activeSortField={sorting?.field}
        activeSortOrder={sorting?.direction}
        onChangeSort={setSort}
        minWidth={850}
      />
      <Dialog
        title={'Вы действительно хотите завершить согласование?'}
        open={isCompleteApprovmentWithoutEquipmentPopup}
        handleAccept={() => {
          completeNotApprove({
            infralistId,
            isEmptyAgreed: true
          })
            .then(() => {
              closeCompleteNotApprovePopup();
              closeCompleteApprovmentWithoutEquipmentPopup();
            })
            .catch(catchCompleteNotApproveErrors);
        }}
        handleClose={closeCompleteApprovmentWithoutEquipmentPopup}
        isLoading={isLoadingCompleteNotApprove}
        titleRefuse={'Отмена'}
        titleAccept={'Согласовать раздел'}
        additionalButtons={
          <ProgressButton
            color="primary"
            isLoading={isLoadingCompleteNotApproveWithoutEquipment}
            onClick={() => {
              completeNotApprove({
                infralistId,
                isEmptyAgreed: false
              })
                .then(() => {
                  closeCompleteNotApprovePopup();
                  closeCompleteApprovmentWithoutEquipmentPopup();
                })
                .catch(catchCompleteNotApproveErrors);
            }}
          >
            Не согласовать раздел
          </ProgressButton>
        }
      >
        После подтверждения редактирование будет невозможно.
      </Dialog>
    </>
  );
}
