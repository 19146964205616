import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_PARENTAL_ASSISTANCE_GUIDELINE_ORGANIZATIONAL } from '../../graphql/queries/parentalAssistanceGuidelines/parentalAssistanceGuidelineOrganizational';

const nonParentalAssistanceGuidelineOrganizationalQueryOptions = {
  query: QUERY_GET_PARENTAL_ASSISTANCE_GUIDELINE_ORGANIZATIONAL,
  mapData: (result) => result.parentalAssistanceGuidelineOrganizational,
  skipQuery: skipIfNull(null)
};

export function useParentalAssistanceGuidelineOrganizational(id) {
  return useRetriableGraphqlVars({
    queryOptions: nonParentalAssistanceGuidelineOrganizationalQueryOptions,
    // TODO refetchQuery не срабатывает, пока сделаем без кеша. Нужно разобраться
    options: {
      apolloOptions: {
        fetchPolicy: 'network-only'
      }
    },
    variableCreator: [
      () =>
        id
          ? {
              id
            }
          : null,
      [id]
    ]
  });
}
