import React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import BreadCrumbs from '../../../../common/BreadCrumbs/BreadCrumbs';
import PageTitle from '../../../../common/PageTitle/PageTitle';
import { useRoadmapEventWithoutRegion } from '../../../../store/roadmap/useRoadmapEvent';
import { useUrlQuery } from '../../../../common/useUrlQuery';
import { useUrlValue } from '../../../../common/useUrlValue';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { getName } from '../../../../utils/data/label';

import { Event } from './Event';
import { Regions } from './Regions';
import { getFederalEventBreadCrumbs } from './getFederalEventBreadCrumbs';

import s from '../../../projects/project/ProjectPage.module.scss';

const PageTab = {
  EVENT: 'event',
  REGIONS: 'regions'
};

const getTabName = (tab) => {
  return tab === 'event' ? 'Мероприятия' : tab === 'regions' ? 'Регионы' : '';
};

export default function FederalEventPage({ match, location, history }) {
  const { eventId, roadmapId } = match.params;

  const roadmapEventQuery = useRoadmapEventWithoutRegion(eventId, true);
  const event = roadmapEventQuery.state.data;

  console.log({ event });

  const regionalRoadmapEvents = event?.regional_roadmap_events;

  const [query, changeQuery] = useUrlQuery(location, history);
  const [tabsValue, setTabsValue] = useUrlValue(
    query,
    changeQuery,
    'tab',
    PageTab.EVENT,
    true
  );

  const breadCrumbsLinks = getFederalEventBreadCrumbs({
    roadmapId,
    roadmapName: event?.roadmap?.name_full
  });

  return (
    <>
      <HeadTitle
        type="Федеральное мероприятие"
        entity={getName(event)}
        tab={getTabName(tabsValue)}
      />
      <BreadCrumbs links={breadCrumbsLinks} />
      <PageTitle title={getName(event)} withBackBtn />
      <Tabs
        value={tabsValue}
        indicatorColor="primary"
        textColor="inherit"
        centered
        className={s.Tabs}
        onChange={(e, value) => setTabsValue(value)}
      >
        <Tab label="Мероприятие" value={PageTab.EVENT} />
        <Tab label="Регионы" value={PageTab.REGIONS} />
      </Tabs>
      {queryLoaderWithExistenceCheck(roadmapEventQuery) ||
        (tabsValue === PageTab.EVENT ? (
          <Event match={match} event={event} />
        ) : (
          <Regions
            match={match}
            regionalRoadmapEvents={regionalRoadmapEvents}
            location={location}
            history={history}
          />
        ))}
    </>
  );
}
