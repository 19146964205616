import { useState } from 'react';
import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { MUTATION_INFRALIST_CHECK_ALL } from '../../../graphql/mutations/infralist';
import { INFRALIST_QUERIES } from '../infralistQueries';
import { useDialog } from '../../../utils/hooks/useDialog';
import { clearApolloQueryCache } from '../../../utils/apollo';

export const useCheckAll = (infralistId) => {
  const [loading, setLoading] = useState(false);
  const client = useContextApolloClient();
  const { open, isOpen, close } = useDialog();

  const checkAll = () => {
    setLoading(true);
    clearApolloQueryCache(client.store.getCache(), [
      INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT
    ]);
    return client
      .mutate({
        mutation: MUTATION_INFRALIST_CHECK_ALL,
        variables: {
          infralist_id: infralistId
        },
        refetchQueries: [INFRALIST_QUERIES.INFRALIST_SECTION_RVPO_EQUIPMENT]
      })
      .finally(() => {
        setLoading(false);
        open();
      });
  };

  return {
    checkAll,
    checkAllLoading: loading,
    isCheckAllPopupVisible: isOpen,
    closeCheckAllPopup: close
  };
};
