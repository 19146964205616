import React, { useMemo } from 'react';
import { useUrlQuery } from '../../../../common/useUrlQuery';
import { compareSimple, makeComparator } from '../../../../utils/sorting';
import { reformatDate } from '../../../../utils/date';
import { YearSelect } from '../../../../common/YearSelect';
import { MonthSelect } from '../../../../common/MonthSelect';
import { PofStatusSelect } from '../../../../common/PofStatusSelect';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import { PofsListTable } from '../../../pofs/PofsListTable';
import { usePofRegionalDocuments } from '../../../../store/pof/pofQueries';
import { PofStatus } from '../../../pofs/pof/PofStatus';
import { useSetMonth } from '../../../pofs/useSetMonth';
import { Signal } from '../../../../common/Inputs/Signals';
import { useSorting } from '../../../../common/Table/Sorting/useSorting';
import { Sorting } from '../../../../common/Table/Sorting/Sorting';

const getPOFsColumnsColumn = (sortableColumns) => [
  { label: '№', key: 'order', isThin: true },
  { label: 'Код НР', key: 'expenses_direction.code', isThin: true },
  {
    label: 'Наименование НР',
    key: 'expenses_direction.name_full',
    isBold: true,
    isWide: true
  },
  {
    label: 'Результат регионального проекта',
    key: 'expenses_direction.result.name_full',
    isWide: true
  },
  { label: 'Дата изменения', key: 'updated_at_formatted', isThin: true },
  { label: 'Автор изменений', key: 'author.name', isThin: true },
  {
    label: 'Статус',
    key: 'status',
    isThin: true,
    renderCell: (value) => <PofStatus status={value} />
  },
  ...sortableColumns
];

export const PofsList = ({ projectId, location, history, match }) => {
  const [query, changeQuery] = useUrlQuery(location, history);

  const status = query.status || 'all';

  const [year, month, currentYear, maxMonth] = useSetMonth(query, changeQuery);

  const [sortableColumns, sorting] = useSorting([
    {
      field: Sorting.SortFields.status_changed_at,
      key: (row) => reformatDate(row.status_changed_at),
      isThin: true
    }
  ]);

  const pofRegionalDocumentsQuery = usePofRegionalDocuments(
    {
      regional_project_ids: projectId,
      year: year,
      month: month,
      status: status !== 'all' ? status : undefined
    },
    sorting
  );

  const pofRegionalDocuments = useMemo(() => {
    if (!pofRegionalDocumentsQuery.state.data) return [];
    return pofRegionalDocumentsQuery.state.data
      .slice()
      .sort(makeComparator(compareSimple, (r) => r.expenses_direction.code))
      .map(({ updated_at, ...rest }, index) => ({
        ...rest,
        order: index + 1,
        updated_at_formatted: reformatDate(updated_at)
      }));
  }, [pofRegionalDocumentsQuery.state.data]);

  const columns = useMemo(() => getPOFsColumnsColumn(sortableColumns), [
    sortableColumns
  ]);

  return (
    <>
      <Signal warning>
        Документ ПОФ субъекту следует предоставлять до 25 числа каждого месяца
      </Signal>
      <YearSelect
        label="Год"
        year={year}
        onChange={changeQuery}
        maxYear={currentYear}
      />
      <MonthSelect
        label="Месяц"
        month={month}
        onChange={changeQuery}
        maxMonth={maxMonth}
      />
      <PofStatusSelect label="Статус" status={status} onChange={changeQuery} />

      {queryLoaderWithExistenceCheck(pofRegionalDocumentsQuery) || (
        <PofsListTable
          match={match}
          history={history}
          data={pofRegionalDocuments}
          canCreate={month <= maxMonth}
          POFsColumns={columns}
          addFieldRoute={`/projects/${projectId}/pofs/create?year=${year}&month=${month}`}
          rowRedirectRoute={`/projects/${projectId}/pofs/`}
        />
      )}
    </>
  );
};
