import gql from 'graphql-tag';

export const QUERY_GET_FEDERAL_DISTRICTS = gql`
  query getFederalDistricts {
    federalDistricts {
      id
      name
      code
    }
  }
`;
