import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useState, useCallback, useEffect } from 'react';
import {
  QUERY_INFRALIST_CHANGE_LOG_DIRECTIONS,
  QUERY_INFRALIST_CHANGE_LOG_SECTION,
  QUERY_INFRALIST_CHANGE_LOG_FPO_EQUIPMENTS
} from '../../../../graphql/queries/infralist';
import { getEndOfDayString, getStartOfDayString } from '../../../../utils/date';

const dataToOption = (data) => ({ label: data.name, value: data.id });

export const useSearchOptions = (infralistId, params) => {
  const client = useContextApolloClient();

  const [directionsOptions, setDirectionsOptions] = useState([]);
  const [isLoadingDirections, setIsLoadingDirections] = useState(false);

  const [sectionsOptions, setSectionsOptions] = useState([]);
  const [isLoadingSections, setIsLoadingSections] = useState(false);

  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [isLoadingEquipment, setIsLoadingEquipment] = useState(false);

  useEffect(() => {
    // направления запрашиваем только после выбора даты
    if (!params.filter.date) {
      return;
    }
    setIsLoadingDirections(true);
    client
      .query({
        query: QUERY_INFRALIST_CHANGE_LOG_DIRECTIONS,
        variables: {
          input: {
            filter: {
              infralist_id: infralistId,
              date: {
                start: getStartOfDayString(params.filter.date),
                end: getEndOfDayString(params.filter.date)
              }
            },
            pagination: {
              page: 0,
              onePage: 1000
            }
          }
        }
      })
      .then((response) => {
        setDirectionsOptions(
          response?.data?.infralistChangeLogDirections?.list?.map(
            dataToOption
          ) ?? []
        );
      })
      .finally(() => {
        setIsLoadingDirections(false);
      });
  }, [client, infralistId, params.filter.date]);

  const fetchSections = useCallback(
    (directionIds) => {
      setIsLoadingSections(true);
      setSectionsOptions([]);
      setEquipmentOptions([]);
      client
        .query({
          query: QUERY_INFRALIST_CHANGE_LOG_SECTION,
          variables: {
            input: {
              filter: {
                infralist_id: infralistId,
                direction_ids: directionIds,
                date: {
                  start: getStartOfDayString(params.filter.date),
                  end: getEndOfDayString(params.filter.date)
                }
              }
            },
            pagination: {
              page: 0,
              onePage: 1000
            }
          }
        })
        .then((response) => {
          setSectionsOptions(
            response?.data?.infralistChangeLogSections?.list?.map(
              dataToOption
            ) ?? []
          );
        })
        .finally(() => {
          setIsLoadingSections(false);
        });
    },
    [client, infralistId, params.filter.date]
  );

  const fetchEquipment = useCallback(
    (sectionIds) => {
      setIsLoadingEquipment(true);
      setEquipmentOptions([]);
      client
        .query({
          query: QUERY_INFRALIST_CHANGE_LOG_FPO_EQUIPMENTS,
          variables: {
            input: {
              filter: {
                infralist_id: infralistId,
                section_ids: sectionIds,
                date: {
                  start: getStartOfDayString(params.filter.date),
                  end: getEndOfDayString(params.filter.date)
                }
              }
            },
            pagination: {
              page: 0,
              onePage: 1000
            }
          }
        })
        .then((response) => {
          setEquipmentOptions(
            response?.data?.infralistChangeLogFpoEquipments?.list?.map(
              dataToOption
            ) ?? []
          );
        })
        .finally(() => setIsLoadingEquipment(false));
    },
    [client, infralistId, params.filter.date]
  );

  useEffect(() => {
    const directionIds = params.filter.direction_ids;
    directionIds && directionIds.length > 0 && fetchSections(directionIds);
  }, [params.filter.direction_ids, fetchSections]);

  useEffect(() => {
    const sectionIds = params.filter.section_ids;
    sectionIds && sectionIds.length > 0 && fetchEquipment(sectionIds);
  }, [params.filter.section_ids, fetchEquipment]);

  return {
    directionsOptions,
    isLoadingDirections,
    sectionsOptions,
    isLoadingSections,
    equipmentOptions,
    isLoadingEquipment
  };
};
