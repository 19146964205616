import React from 'react';
import Dialog from '../../../common/Dialog';

export function ContestDocsEntityValidationDialog({
  isOpen,
  onCancel,
  children
}) {
  return (
    <Dialog
      open={isOpen}
      showAcceptButton={false}
      titleRefuse="OK"
      handleClose={onCancel}
    >
      {children}
    </Dialog>
  );
}
