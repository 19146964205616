import React, { useMemo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { DeleteButton } from '../../DeleteButton';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { User } from '../../../store/role/User';
import { NewAutocompleteInput } from '../../InputsNew/NewAutocompleteInput';
import {
  FormSelector,
  RegionalProjectsSelector,
  RegionSelector,
  RoadmapSelector
} from './PermissionObjectSelecters';
import { NotificationSelector } from './NotificationSelector';
import s from './Permissions.module.scss';

const makePermissionOption = (permission) => ({
  value: permission.id,
  label: permission.name
});

export const PermissionInputs = ({
  values,
  setFieldValue,
  handleBlur,
  onDelete,
  permissions,
  userPermissionsMap
}) => {
  const user = useCurrentUser();

  const permissionsOptions = useMemo(
    () =>
      permissions
        ?.filter(
          (permission) =>
            User.isAdmin(user) || userPermissionsMap[permission.id]
        )
        .filter((permission) => {
          return !values.permissions?.some((valPerm) => {
            if (valPerm.object_id) {
              return false;
            }
            return permission.id === valPerm.base_permission_id;
          });
        })
        .map(makePermissionOption)
        .filter((permission) => permission),
    [permissions, user, userPermissionsMap, values.permissions]
  );

  return values.permissions.map((permission, iPermission) => {
    const basePermission = permissions.find(
      (p) => p.id === permission.base_permission_id
    );
    const showObjectField = basePermission?.type === 'OBJECT';
    const showNotificationField = basePermission?.type === 'NOTIFICATION';

    let permOptions = permissionsOptions;

    const currentPerm = permissions.find(
      (perm) => perm.id === permission.base_permission_id
    );
    if (currentPerm) {
      permOptions = [makePermissionOption(currentPerm), ...permissionsOptions];
    }

    const objectIdProps = {
      // костыль, чтобы выбирать "Все" объекты
      value: permission.object_type || permission.object_id,
      onChange: (value) => {
        if (value === 'all') {
          setFieldValue(`permissions[${iPermission}].object_type`, 'all');
          setFieldValue(`permissions[${iPermission}].object_id`, null);
        } else {
          setFieldValue(`permissions[${iPermission}].object_type`, null);
          setFieldValue(`permissions[${iPermission}].object_id`, value);
        }
      },
      onBlur: handleBlur,
      name: `permissions[${iPermission}].object_id`
    };

    return (
      <TableRow key={iPermission}>
        <TableCell
          colSpan={!(showObjectField || showNotificationField) ? 2 : 1}
          className={s.cellStile}
        >
          <NewAutocompleteInput
            name={`permissions[${iPermission}].base_permission_id`}
            label={`Право ${iPermission + 1}`}
            suggestions={permOptions}
            isClearable={false}
            hasCopyBtn={false}
            fullWidth
          />
        </TableCell>
        {showObjectField && (
          <TableCell className={s.cellStile}>
            {permission.base_permission_id === 'roadmap.access' ? (
              <RoadmapSelector {...objectIdProps} />
            ) : permission.base_permission_id === 'region.access' ? (
              <RegionSelector {...objectIdProps} />
            ) : permission.base_permission_id === 'regional_project.access' ? (
              <RegionalProjectsSelector {...objectIdProps} />
            ) : permission.base_permission_id === 'form.fill' ? (
              <FormSelector {...objectIdProps} />
            ) : permission.base_permission_id === 'form.fill_all' ? (
              <FormSelector {...objectIdProps} permission={'form.fill_all'} />
            ) : permission.base_permission_id === 'roadmap.edit' ? (
              <RoadmapSelector {...objectIdProps} permission={'roadmap.edit'} />
            ) : permission.base_permission_id === 'notifications.roadmaps' ? (
              <RoadmapSelector
                {...objectIdProps}
                permission={'notifications.roadmaps'}
              />
            ) : (
              false
            )}
          </TableCell>
        )}
        {showNotificationField && (
          <TableCell className={s.cellStile}>
            <NotificationSelector {...objectIdProps} />
          </TableCell>
        )}
        <TableCell
          className={s.cellStile}
          style={{
            width: '84px',
            textAlign: 'left',
            verticalAlign: 'middle'
          }}
        >
          <DeleteButton
            onClick={() => {
              onDelete(iPermission);
            }}
          />
        </TableCell>
      </TableRow>
    );
  });
};
