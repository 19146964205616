import React from 'react';
import { TextField as MaterialTextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import s from './TextField.module.scss';

export const TextField = ({
  value,
  multiline,
  onChange,
  placeholder,
  name,
  disabled,
  error,
  maxLength,
  className,
  ...rest
}) => {
  return (
    <MaterialTextField
      value={value}
      onChange={onChange}
      multiline={multiline}
      placeholder={placeholder}
      fullWidth
      disabled={disabled}
      name={name}
      variant={'outlined'}
      helperText={error}
      InputProps={{
        notched: false,
        classes: {
          root: s.InputWrapper,
          input: classNames(s.Input, className),
          inputMultiline: s.InputMultiline,
          multiline: s.InputMultilineWrapper,
          disabled: s.Disabled,
          error: s.ErrorField,
          notchedOutline: s.Notched,
          focused: s.Focus
        }
      }}
      inputProps={{ maxLength: maxLength }}
      FormHelperTextProps={{
        classes: {
          root: s.Error
        }
      }}
      InputLabelProps={{
        shrink: false,
        disableAnimation: true
      }}
      error={!!error}
      {...rest}
    />
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string
};
