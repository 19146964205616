import { useGraphqlQueryVars } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import {
  QUERY_GET_REGIONAL_PROJECTS,
  QUERY_GET_REGIONAL_PROJECTS_LIST
} from '../../graphql/queries/regionalProjects';

const projectsQueryOptions = {
  query: QUERY_GET_REGIONAL_PROJECTS,
  mapData: (result) => ({
    data: result.regionalProjects.list,
    header: result.regionalProjects.header
  }),
  skipQuery: skipIfNull(null)
};

const projectsListQueryOptions = {
  query: QUERY_GET_REGIONAL_PROJECTS_LIST,
  mapData: (result) => ({
    data: result.regionalProjects.list?.filter((p) => p.federal_project),
    header: result.regionalProjects.header
  }),
  skipQuery: skipIfNull(null)
};

export function useRegionalProjects(filter, pagination = null) {
  return useGraphqlQueryVars({
    queryOptions: projectsQueryOptions,
    variableCreator: [
      () => ({
        input: {
          filter: {
            region_ids: filter.regionId,
            has_pof: 'hasPof' in filter ? filter.hasPof : null,
            search: filter.search,
            implementation_years: filter.implementation_years
          },
          pagination
        },
        delivery_years: filter.delivery_years
      }),
      [filter, pagination]
    ]
  });
}
export function useRetriableRegionalProjects(filter, pagination = null) {
  return useRetriableGraphql({
    queryOptions: projectsListQueryOptions,
    variables: filter
      ? {
          input: {
            filter,
            pagination
          }
        }
      : null
  });
}
