import React, { useMemo } from 'react';
import BreadCrumbs, {
  loadingBreadcrumb
} from '../../../common/BreadCrumbs/BreadCrumbs';
import { getExpenseGroupBreadCrumbs } from '../../projects/project/expenseGroups/expenseGroup/ExpenseGroupBreadCrumbs';

export function getPurchaseBreadCrumbs({
  projectId,
  projectName,
  expenseGroupId,
  expensesDirectionName
}) {
  if (expenseGroupId) {
    return [
      ...getExpenseGroupBreadCrumbs({ projectId, projectName }),
      {
        label: loadingBreadcrumb(expensesDirectionName),
        to: `/projects/${projectId}/expenseGroups/${expenseGroupId}`
      }
    ];
  }

  if (projectId) {
    return [...getExpenseGroupBreadCrumbs({ projectId, projectName })];
  }

  return [
    {
      label: 'Все закупки',
      to: `/purchases`
    }
  ];
}

export const PurchaseBreadCrumbs = ({
  project,
  projectId,
  expensesDirection,
  expenseGroupId
}) => {
  const breadCrumbsLinks = useMemo(() => {
    return getPurchaseBreadCrumbs({
      projectId,
      expenseGroupId,
      projectName: project?.name,
      expensesDirectionName: expensesDirection?.name_full
    });
  }, [projectId, project, expenseGroupId, expensesDirection]);

  return <BreadCrumbs links={breadCrumbsLinks} />;
};
