import Button from '@material-ui/core/Button';
import React, { useCallback, useState } from 'react';
import { CircularProgress } from '@material-ui/core';

export function ActionButton({ title, onClick, disabled, ...props }) {
  const [isLoading, setLoading] = useState(false);

  const handleClick = useCallback(() => {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => onClick())
      .catch(() => {})
      .then(() => setLoading(false));
  }, [onClick]);

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      disabled={isLoading || disabled}
      {...props}
    >
      {isLoading ? <CircularProgress size={24} color="secondary" /> : title}
    </Button>
  );
}
