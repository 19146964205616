import { skipIfNull } from '@proscom/prostore';
import { set } from 'lodash-es';
import { useMemo } from 'react';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_EQUIPMENT_NAME_LIST } from '../../graphql/queries/infralistEquipment';

const queryOptions = {
  query: QUERY_GET_EQUIPMENT_NAME_LIST,
  mapData: (result) =>
    result.fpoEquipmentList?.map((item) => ({
      value: item.id,
      label: item.name,
      description: item.fpo_description
    })),
  skipQuery: skipIfNull(null)
};

export function useEquipmentNameList(sectionId) {
  const response = useRetriableGraphql({
    queryOptions,
    variables: {
      section_id: sectionId
    }
  });
  return response.state.data ?? [];
}

export const transformEquipmentNameListToMap = (list = []) => {
  const equipmentNameMap = {};
  list.forEach((item) => {
    set(equipmentNameMap, item.value, {
      label: item.label,
      description: item.description
    });
  });
  const get = (key) => {
    return (
      equipmentNameMap[key] || {
        label: '',
        description: ''
      }
    );
  };

  return { get };
};
