import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SubTable from '../../../common/SubTable/SubTable';
import { ParentalAssistanceGuideline } from '../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import { ListWithDescription } from '../common/ListWithDescription';
import { Description } from '../common/Description';
import { SimpleFileView } from '../common/FileUpload/FileEditableComponent';
import { ServiceDeliveryFormats } from './ServiceDeliveryFormats';
import { PossibleChoice } from './PossibleChoice';
import s from '../ParentalAssistanceGuidelines.module.scss';

export const ProcedureOrganizationalSection = ({ data }) => {
  return (
    <SubTable title="II. Порядок оказания услуг">
      <TableRow>
        <TableCell>
          1. Перечислите категории получателей услуг, имеющих право на получение
          услуги без взимания платы
        </TableCell>
        <TableCell className={s.paragraphBlock}>
          {data.free_service_recipient_groups}
        </TableCell>
      </TableRow>
      <ServiceDeliveryFormats
        data={data}
        title="2. Перечислите возможные форматы предоставления услуги (очные консультации, дистанционные консультации, выездные консультации и др.)"
      />
      <TableRow>
        <TableCell>
          3. Перечислите категории получателей услуг, имеющих право на получение
          услуги в особом формате (например, в формате выездных консультаций).
          Опишите условия и порядок предоставления услуги
        </TableCell>
        <TableCell className={s.paragraphBlock}>
          {data.special_categories_of_service_recievers}
          <Description
            descriptionTitle="Условия и порядок предоставления услуг"
            descriptionText={
              data.special_categories_of_service_recievers_description
            }
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          4. Предусмотрена ли возможность присмотра за ребенком на время
          оказания услуги?
        </TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(
            data.babysitting_in_service_time
          )}
          <Description
            descriptionTitle="Комментарий"
            descriptionText={data.babysitting_in_service_time_description}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          5. Предусмотрена ли зона ожидания в Организации/Службе для родителей с
          детьми?
        </TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(data.waiting_zone_exists)}
          <Description
            descriptionTitle="Комментарий"
            descriptionText={data.waiting_zone_description}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          6. Предусмотрена ли зона комфортного пребывания в Организации/Службе
          для детей с ограниченными возможностями здоровья?
        </TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(
            data.comfortable_zone_for_disabled_children
          )}
          <Description
            descriptionTitle="Комментарий"
            descriptionText={
              data.comfortable_zone_for_disabled_children_description
            }
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          7. Оказывает ли Организация/Служба услуги психолого-педагогической,
          методической и консультативной помощи родителям (законным
          представителям) на возмездной (платной основе)? Опишите условия и
          порядок их оказания.
        </TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(
            data.paid_consultations_for_parents
          )}
          <Description
            descriptionTitle="Комментарий"
            descriptionText={data.paid_consultations_for_parents_description}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          8. Перечислите предусмотренные способы записи для получения услуги
          (например, электронная запись, по телефону и пр.)
        </TableCell>
        <TableCell>
          <ListWithDescription
            list={data.registration_methods}
            mapData={(item) =>
              ParentalAssistanceGuideline.registrationMethods.getName(
                item.toUpperCase()
              )
            }
            descriptionTitle="Другие способы"
            descriptionText={data.other_registration_methods}
          />
        </TableCell>
      </TableRow>
      <PossibleChoice data={data} startOfList={9} />
      <TableRow>
        <TableCell>
          11. Установлена ли максимальная продолжительность ожидания получения
          услуги с момента записи?
        </TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(
            data.maximum_waiting_time_exists
          )}
          <Description
            descriptionTitle="Продолжительность ожидания (дней)"
            descriptionText={data.maximum_waiting_time}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          12. Фактическая средняя продолжительность ожидания получения услуги с
          момента записи (дней)?
        </TableCell>
        <TableCell>{data.average_duration_of_services_receivement}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          13. Фактическая средняя продолжительность оказания услуги (минут)?
        </TableCell>
        <TableCell>{data.average_duration_of_services_provisioning}</TableCell>
      </TableRow>
      <TableRow className={s.headerCell}>
        <TableCell colSpan={2}>
          14. Проводится ли Организацией/Службой информационно-разъяснительная
          деятельность, направленная на доведение до родителей (законных
          представителей) детей информации о возможности обращаться за
          получением услуги, в том числе:
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          - размещена ли информация о деятельности Организации/Службы на
          официальном сайте Организации/Службы в сети Интернет и иных местах, в
          том числе в помещении Организации/Службы
        </TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(data.official_site_has_info)}
          <Description
            descriptionTitle="Ссылка в сети Интернет"
            descriptionText={data.official_site_has_info_description}
          />
          {data.official_site_info_files?.map((document, key) => {
            return <SimpleFileView key={key} document={document} />;
          })}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          - размещена ли информация о специалистах, осуществляющих оказание
          услуг, в том числе их образовании, квалификации и опыте работы, на
          официальном сайте Организации/Службы в сети Интернет
        </TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(
            data.official_site_has_specialists_info
          )}
          <Description
            descriptionTitle="Ссылка в сети Интернет, где размещена данная информация"
            descriptionText={
              data.official_site_has_specialists_info_description
            }
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          - есть ли утвержденный медиаплан по информационному освещению
          деятельности Организации/Службы, направленного на привлечение внимания
          населения к возможности получить услугу. Приложите файл.
        </TableCell>
        <TableCell>
          {ParentalAssistanceGuideline.getYesOrNo(data.has_approved_mediaplan)}
          {data.approved_mediaplan && (
            <SimpleFileView document={data.approved_mediaplan} />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          - опишите реализуемые меры, перечислите информационные каналы и
          перечень вопросов, по которым получатели услуг получают информацию
        </TableCell>
        <TableCell>
          <div className={s.paragraphBlock}>
            {data.information_distribution_description}
          </div>
          <div className={s.paragraphBlock}>
            {data.information_distribution_links}
          </div>
          {data.information_distribution_protocols?.map((document, key) => {
            return <SimpleFileView key={key} document={document} />;
          })}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          15. Опишите основные типовые ситуации, типовые консультации (в том
          числе основные вопросы, с которыми обращаются родители (законные
          представители) детей)
        </TableCell>
        <TableCell className={s.paragraphBlock}>
          {data.typical_consultations_description}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          16. Проводится ли оценка качества оказываемых услуг? Опишите методику
          и порядок оценки качества услуг
        </TableCell>
        <TableCell>
          <div>
            {ParentalAssistanceGuideline.getYesOrNo(
              data.services_quality_is_being_controlled
            )}
          </div>
          <Description
            descriptionTitle="Оценка качества услуг осуществляется на Портале «Растим детей»?"
            descriptionText={ParentalAssistanceGuideline.getYesOrNo(
              data.services_quality_is_being_controlled_on_portal
            )}
          />
          <Description
            descriptionTitle="Методика оценки (коротко)"
            descriptionText={data.services_quality_control_methodology}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          17. Опишите проблемы, которые возникали и/или возникают при
          организации процесса оказания услуги (при наличии)
        </TableCell>
        <TableCell>
          <div>
            {ParentalAssistanceGuideline.getYesOrNo(
              data.problems_in_new_service_introduction_exist
            )}
          </div>
          <Description
            descriptionTitle="Описание проблем"
            descriptionText={
              data.problems_in_new_service_introduction_description
            }
          />
        </TableCell>
      </TableRow>
    </SubTable>
  );
};
