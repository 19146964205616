export class SingleIntervalManager {
  interval = null;
  timeout = 1000;

  set(cb) {
    if (this.interval) clearTimeout(this.interval);
    this.interval = setTimeout(() => {
      this.interval = null;
      cb && cb();
      this.set(cb, this.timeout);
    }, this.timeout);
  }

  setDelay(time) {
    this.timeout = time;
  }

  clear() {
    if (this.interval) {
      clearTimeout(this.interval);
      this.interval = null;
    }
  }

  getDelay() {
    return this.timeout;
  }

  active() {
    return !!this.interval;
  }
}
