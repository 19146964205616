import React, { useCallback, useEffect } from 'react';
import AutocompleteBaseInput from '../../common/Inputs/Selects/AutocompleteBaseInput';
import { SearchForm } from '../../common/SearchForm/SearchForm';
import { getCurrentYear, yearsSuggestions } from '../../utils/data/date';
import s from './ProjectsPage.module.scss';

export const useProjectsPageFilter = (query, changeQuery, regionId) => {
  const year = query.year || getCurrentYear().toString();

  const filter = {
    regionId,
    hasPof: true,
    search: query.search,
    delivery_years: [+year],
    implementation_years: [+year]
  };

  const setFilterValue = useCallback(
    (value, name) =>
      changeQuery({
        [name]: value,
        page: 0
      }),
    [changeQuery]
  );

  useEffect(() => {
    setFilterValue(year, 'year');
  }, [year, setFilterValue]);

  const filterComponent = (
    <div className={s.filters}>
      <AutocompleteBaseInput
        onChange={setFilterValue}
        hasCopyBtn={false}
        label="Год"
        name="year"
        suggestions={yearsSuggestions}
        value={year}
        variant="standard"
        className={s.year}
        isClearable={false}
      />
      <SearchForm changeQuery={changeQuery} initialInput={filter.search} />
    </div>
  );
  return [filterComponent, filter];
};
