import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ListWithDescription } from '../common/ListWithDescription';
import { ParentalAssistanceGuideline } from '../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';

export const ServiceDeliveryFormats = ({ data, title }) => {
  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell>
        <ListWithDescription
          list={data.service_delivery_formats}
          mapData={(item) =>
            ParentalAssistanceGuideline.serviceDeliveryFormats.getName(
              item.toUpperCase()
            )
          }
          descriptionTitle="Другие форматы"
          descriptionText={data.other_service_delivery_formats}
        />
      </TableCell>
    </TableRow>
  );
};
