import React from 'react';
import { EMDASH } from './constants';

export class StatusService {
  defaultStatus = '';
  colors = {};
  emptyStatus = '';

  constructor({ colors, defaultStatus = '', emptyStatus }) {
    this.defaultStatus = defaultStatus;
    this.colors = colors;
    this.emptyStatus = emptyStatus;
  }

  getColor(statusName) {
    if (statusName === this.emptyStatus) {
      statusName = '';
    }
    return this.colors[statusName] ?? this.colors[this.defaultStatus];
  }

  getColoredName(statusName = this.defaultStatus, possibleStatuses) {
    if (
      (possibleStatuses && !possibleStatuses.includes(statusName)) ||
      statusName === this.emptyStatus
    ) {
      statusName = EMDASH;
    }
    return (
      <span style={{ color: this.getColor(statusName) }}>{statusName}</span>
    );
  }

  checkIsEmpty(statusName) {
    return !statusName || this.emptyStatus === statusName;
  }
}
