import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { isStaffSchedules } from '../../../../../store/roadmap/roadmapEvents';
import { useSubsidiaryEntitiesForFilter } from '../../../../../store/subsidiaryEntity/useSubsidiaryEntities';
import { useMemoSuggestions } from '../../../../../utils/suggestions';
import InputGroup from '../../../../../common/Inputs/InputGroup';
import { StaffingTableField } from '../../staffingTable/StaffingTableField';
import { SubsidiaryEntity } from '../../../../../store/subsidiaryEntity/SubsidiaryEntity';
import AutocompleteBaseInput from '../../../../../common/Inputs/Selects/AutocompleteBaseInput';
import s from '../EventEditPage.module.scss';

export const StaffScheduleInfo = React.memo(function StaffScheduleInfo({
  name,
  eventType,
  eventYear,
  region,
  setStaffError,
  roadmap,
  values
}) {
  const isStaff = isStaffSchedules(eventType);
  const [, meta, { setValue }] = useField(name);

  const entitiesQuery = useSubsidiaryEntitiesForFilter({
    region_ids: region.id,
    owner_roadmap_ids: roadmap.id,
    year: eventYear + ''
  });
  const entities = entitiesQuery.state.data?.list;
  const entitiesSuggestions = useMemoSuggestions(entities, (entity) =>
    SubsidiaryEntity.getCompleteName(entity)
  );

  const [subsidiaryEntityId, setSubsidiaryEntityId] = useState('');
  const entity = entities?.find((entity) => entity.id === subsidiaryEntityId);
  const entityTypeName = entity?.se_type?.name_full;
  const entityTypeId = entity?.se_type?.id;

  const errors = values[name]?.[subsidiaryEntityId]?.map((item) => {
    if (item.stuff_schedule_guideline.amount_of_staff_string) return false;
    return (
      +item.amount_of_staff !== item.stuff_schedule_guideline.amount_of_staff
    );
  });

  if (errors?.filter((error) => error).length > 0) {
    setStaffError(true);
  } else {
    setStaffError(false);
  }

  return (
    <>
      {isStaff && (
        <InputGroup title="Штатное расписание">
          <AutocompleteBaseInput
            label="Субсидиарная сущность"
            onChange={(e) => {
              setSubsidiaryEntityId(e);
            }}
            value={subsidiaryEntityId}
            suggestions={entitiesSuggestions}
            suggestionsLoading={entitiesQuery.check.spinner}
            hasCopyBtn={false}
            fullWidth
          />
          {entityTypeName && (
            <div className={s.ssType}>
              <span className={s.ssType__caption}>
                Тип субсидиарной сущности:
              </span>{' '}
              {entityTypeName}
            </div>
          )}
          {entityTypeId ? (
            <StaffingTableField
              key={entityTypeId}
              values={values}
              staffSchedules={meta.value}
              name={name}
              subsidiaryEntityId={subsidiaryEntityId}
              entityTypeId={entityTypeId}
              roadmapId={roadmap.id}
              setValue={setValue}
              errors={errors}
            />
          ) : (
            <div className={s.ssType}>
              Чтобы заполнить укажите субсидиарную сущность
            </div>
          )}
        </InputGroup>
      )}
    </>
  );
});
