import { compareAsc } from 'date-fns';
import { tryParseIso } from './date';

export const compareSimple = (a, b) => (a < b ? -1 : a > b ? 1 : 0);

export function compareNumericStrings(a, b, dir = 1) {
  const aNumber = +a;
  const bNumber = +b;
  const aNaN = isNaN(aNumber);
  const bNaN = isNaN(bNumber);
  if (aNaN && bNaN) {
    return a < b ? -dir : a > b ? dir : 0;
  }
  if (aNaN) return 1;
  if (bNaN) return -1;
  return aNumber < bNumber ? -dir : aNumber > bNumber ? dir : 0;
}

export function compareDateStrings(a, b) {
  const aDate = a && tryParseIso(a);
  const bDate = b && tryParseIso(b);
  if (aDate && bDate) {
    return compareAsc(aDate, bDate);
  }

  if (!aDate) return 1;
  if (!bDate) return -1;
  return 0;
}

export function revertCompareDateStrings(a, b) {
  return compareDateStrings(b, a);
}

export function makeComparator(compFn, ...fieldFns) {
  return (a, b) => {
    for (const fieldFn of fieldFns) {
      const compRes = compFn(fieldFn(a), fieldFn(b));
      if (compRes !== 0) return compRes;
    }
    return 0;
  };
}
