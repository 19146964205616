import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { FileInputButton } from '../../../common/Form/FileInputButton';
import { RegionalEvent } from '../../../store/roadmap/RegionalEvent';
import { useArchiveDownloadingFile } from '../downloadArchiveFile';
import { Signal } from '../../../common/Inputs/Signals';
import { enableArchiveDownload } from '../../../config';
import {
  DocumentLink,
  FileWithLicense,
  FileWithNumberAndApprovalDate,
  SimpleFile
} from './TypeComponents';
import {
  DocumentLinkEditable,
  FileWithLicenseEditable,
  FileWithNumberAndApprovalDateEditable,
  SimpleFileStatusEditable
} from './TypeEditableComponents';
import s from './EventPage.module.scss';

export const EventSlot = ({
  slot,
  uploadMultiple,
  documents,
  editable,
  onSelectFile,
  onLinkAdd,
  regionalEventId,
  eventName,
  isArchiveDownload,
  haveReason,
  ...props
}) => {
  const { canUploadDocuments } = props;

  const showUploadButton =
    editable && (uploadMultiple || (documents && documents.length === 0));
  const showNoDocsMessage = !editable && (!documents || documents.length === 0);
  const slotType = RegionalEvent.normalizeDocType(slot.type);

  const { handleArchiveDownload, isDownloading } = useArchiveDownloadingFile(
    regionalEventId,
    slot.name,
    {
      slotIds: [slot.id]
    }
  );

  const isShowArchiveButton =
    enableArchiveDownload &&
    isArchiveDownload &&
    !showUploadButton &&
    slotType !== RegionalEvent.DocType.LINK &&
    documents?.length > 0;

  return (
    <>
      <TableRow>
        <TableCell className={s.documentNameCell}>{slot.name}</TableCell>
        <TableCell>
          <>
            {editable &&
              documents.map((document) => {
                const documentId = document.id;
                return (
                  <div key={documentId}>
                    {slotType ===
                    RegionalEvent.DocType.WITH_NUMBER_AND_APPROVAL_DATE ? (
                      <FileWithNumberAndApprovalDateEditable
                        documentId={documentId}
                        document={document}
                        {...props}
                      />
                    ) : slotType === RegionalEvent.DocType.LICENSE ? (
                      <FileWithLicenseEditable
                        documentId={documentId}
                        document={document}
                        {...props}
                      />
                    ) : slotType === RegionalEvent.DocType.LINK ? (
                      <DocumentLinkEditable
                        documentId={documentId}
                        document={document}
                        {...props}
                      />
                    ) : (
                      <SimpleFileStatusEditable
                        documentId={documentId}
                        document={document}
                        {...props}
                      />
                    )}
                  </div>
                );
              })}
            {!editable &&
              documents.map((document) => (
                <div key={document.id} className={s.documentBoxStyle}>
                  {document.type ===
                  RegionalEvent.DocType.WITH_NUMBER_AND_APPROVAL_DATE ? (
                    <FileWithNumberAndApprovalDate
                      document={document}
                      {...props}
                    />
                  ) : document.type === RegionalEvent.DocType.LICENSE ? (
                    <FileWithLicense document={document} {...props} />
                  ) : document.type === RegionalEvent.DocType.LINK ? (
                    <DocumentLink document={document} {...props} />
                  ) : (
                    <SimpleFile document={document} {...props} />
                  )}
                </div>
              ))}
            {showUploadButton && canUploadDocuments && (
              <div className={s.documentBoxStyle}>
                {slotType === 'LINK' ? (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={s.addLinkButton}
                    onClick={() => onLinkAdd(slotType, slot)}
                  >
                    Добавить ссылку
                  </Button>
                ) : (
                  <FileInputButton
                    onSelectFile={(e) => onSelectFile(e, slotType, slot)}
                  />
                )}
              </div>
            )}
            {showNoDocsMessage && 'Не загружено'}
          </>
          {!haveReason &&
            documents.length === 0 &&
            slot.code_name === 'staff_schedule_guideline_deviation' && (
              <Signal>Просьба прикрепить официальное письмо</Signal>
            )}
          {isShowArchiveButton && (
            <div>
              <Button
                variant="outlined"
                component="span"
                className={s.addLinkButton}
                onClick={handleArchiveDownload}
                disabled={isDownloading}
              >
                {isDownloading ? 'Скачивание' : 'Скачать архивом'}
              </Button>
            </div>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
