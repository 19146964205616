import React from 'react';
import classnames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import s from './Loader.module.scss';

const Loader = ({ className, centered = false, size = 40, style }) => {
  return (
    <CircularProgress
      className={classnames(s.Loader, className, {
        [s.LoaderCentered]: centered
      })}
      size={size}
      style={style}
    />
  );
};

export default Loader;
