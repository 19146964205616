import React, { useMemo } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { stringify } from 'query-string';
import { useContextStore } from '@proscom/prostore-react';
import { CircularProgress } from '@material-ui/core';
import { reformatDate } from '../../../../utils/date';
import { useClassesSchedulesPage } from '../../../../store/additionalData/useClassesSchedulesPage';
import PageTitle from '../../../../common/PageTitle/PageTitle';
import Table from '../../../../common/Table/Table';
import Menu from '../../../../common/Menu/Menu';
import { useDeleteRow } from '../../../../common/Table/useDeleteRow';
import { ADDITIONAL_DATA_STORE } from '../../../../store/names';
import { getCurrentYear } from '../../../../utils/data/date';
import { useUrlQuery } from '../../../../common/useUrlQuery';
import { useCurrentUser } from '../../../../store/useCurrentUser';
import { AdditionalData } from '../../../../store/additionalData/AdditionalData';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { SubsidiaryEntityNameCell } from '../../../subsidiaryEntities/SubsidiaryEntityNameCell';
import { useAdditionalDataListFilter } from './useAdditionalDataListFilter';

const classesSchedulesColumns = [
  { label: '№', key: 'order', isThin: true },
  {
    label: 'Субсидиарная сущность',
    key: (row) => <SubsidiaryEntityNameCell entity={row.subsidiary_entity} />,
    isBold: true,
    isWide: true
  },
  {
    label: 'Регион',
    key: 'subsidiary_entity.region.name',
    isBold: true,
    isWide: true
  },
  {
    label: 'Предварительная дата',
    key: (row) => `01.09.${row.year}`,
    isWide: true
  },
  {
    label: 'Фактическая дата',
    key: (row) => reformatDate(row.fact_date),
    isWide: true
  }
];

export default function AdditionalDataListPage({ match, location, history }) {
  const additionalDataStore = useContextStore(ADDITIONAL_DATA_STORE);
  const { projectId, roadmapId } = match.params;
  const [query, changeQuery] = useUrlQuery(location, history);
  const defaultYear = getCurrentYear();

  const [filter, filterComponent] = useAdditionalDataListFilter(
    query,
    changeQuery,
    roadmapId,
    defaultYear,
    projectId
  );

  const classesSchedulesQuery = useClassesSchedulesPage(filter);
  const classesSchedules = classesSchedulesQuery.state.data?.list.map(
    (classesSchedule, iClassesSchedule) => ({
      ...classesSchedule,
      order: iClassesSchedule + 1
    })
  );

  const user = useCurrentUser();
  const canCreateClassesSchedule = AdditionalData.canCreateClassesSchedule(
    user
  );

  const menuComponent = useMemo(
    () =>
      function MenuComponent({ row }) {
        const [isDeleting, dialog, openDialog] = useDeleteRow(
          additionalDataStore.deleteClassesSchedule,
          'график занятий'
        );

        const canEditClassesSchedule = AdditionalData.canEditClassesSchedule(
          user,
          row
        );
        const canDeleteClassesSchedule = AdditionalData.canDeleteClassesSchedule(
          user,
          row
        );

        const actions = [];

        if (canEditClassesSchedule) {
          actions.push({
            title: 'Редактировать',
            onClick: () =>
              history.push(`${match.url}/additionalData/${row.id}/edit`)
          });
        }

        if (canDeleteClassesSchedule) {
          actions.push({
            title: 'Удалить',
            onClick: () => openDialog(row.id)
          });
        }

        if (actions.length === 0) return false;

        return (
          <>
            {isDeleting ? (
              <CircularProgress size={18} />
            ) : (
              <Menu
                iconColor="default"
                icon={MoreHorizIcon}
                options={actions}
              />
            )}
            {dialog}
          </>
        );
      },
    [history, match, additionalDataStore, user]
  );

  return (
    <div>
      <HeadTitle title="График начала занятий" />
      <PageTitle title="График начала занятий" />
      <Table
        columns={classesSchedulesColumns}
        data={classesSchedules}
        rowRedirectRoute={`${match.url}/additionalData/`}
        addButtonText="Добавить субсидиарную сущность"
        addFieldRoute={
          `${match.url}/additionalData/create` +
          '?' +
          stringify({
            year: filter.years,
            subsidiaryEntityId: filter.subsidiary_entity_ids
          })
        }
        hasAddField={canCreateClassesSchedule}
        filter={filterComponent}
        menuComponent={menuComponent}
        query={classesSchedulesQuery}
      />
    </div>
  );
}
