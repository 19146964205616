import React, { useCallback, useContext, useEffect, useState } from 'react';
import { __RouterContext } from 'react-router';
import TablePagination from '@material-ui/core/TablePagination';
import { tryNumber } from '../utils/helpers';
import { useUrlQuery } from './useUrlQuery';

export const defaultPaginationParams = {
  page: 0,
  onePage: 20
};

const defaultPerPageOptions = [10, 20, 25, 50, 100];

const mergePagination = (...params) => {
  const result = {};
  // eslint-disable-next-line no-unused-vars
  for (const { page, onePage } of params) {
    if (!isNaN(+page)) {
      result.page = +page;
    }
    if (!isNaN(+onePage)) {
      result.onePage = +onePage;
    }
  }
  return result;
};

/**
 * @deprecated
 * Вместо этого надо использовать useQueryPagination и <QueryPagination/>
 */
export function usePagination(
  defaultPagination = defaultPaginationParams,
  perPageOptions = defaultPerPageOptions
) {
  const { history, location } = useContext(__RouterContext);
  const [query, changeQuery] = useUrlQuery(location, history);
  const pagination = mergePagination(defaultPagination, query);
  const [count, setCount] = useState(null);
  const setTotalCount = useCallback(
    (totalCount) => {
      if (totalCount !== count) setCount(totalCount);
    },
    [count]
  );
  const handleChangePage = useCallback(
    (e, newPage) => {
      changeQuery({ page: newPage });
    },
    [changeQuery]
  );
  const handleChangeRowsPerPage = useCallback(
    (e) => {
      const onePage = +e.target.value;
      changeQuery({ onePage });
    },
    [changeQuery]
  );

  const paginationElement = (
    <TablePagination
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
      rowsPerPageOptions={perPageOptions}
      component="div"
      labelRowsPerPage="Элементов на странице:"
      count={count}
      rowsPerPage={pagination.onePage}
      page={pagination.page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );

  return [paginationElement, pagination, setTotalCount];
}

export function useQueryPagination(query, changeQuery, onePage) {
  const params = {
    page: tryNumber(query.page, 0),
    onePage: tryNumber(query.onePage, onePage)
  };
  const pagination = {
    params
  };
  const [count, setCount] = useState(null);
  pagination.count = count;

  const handleChangePage = useCallback(
    (e, newPage) => {
      changeQuery({ page: newPage });
    },
    [changeQuery]
  );

  const handleChangeRowsPerPage = useCallback(
    (e) => {
      const onePage = +e.target.value;
      changeQuery({ onePage });
    },
    [changeQuery]
  );

  pagination.onChangePage = handleChangePage;
  pagination.onChangeRowsPerPage = handleChangeRowsPerPage;
  pagination.setCount = useCallback((count) => {
    setCount(count);
  }, []);

  return pagination;
}

export function useQueryPaginationCount(pagination, apiQuery) {
  const pageHeader = apiQuery.state.data?.header;
  const { setCount } = pagination;
  useEffect(() => {
    if (pageHeader) {
      setCount(pageHeader.totalCount);
    }
  }, [pageHeader, setCount]);
}

export function QueryPagination({
  pagination,
  pageSizes = defaultPerPageOptions
}) {
  const { params } = pagination;
  return (
    <TablePagination
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to} из ${count || '...'}`
      }
      rowsPerPageOptions={pageSizes}
      component="div"
      labelRowsPerPage="Элементов на странице:"
      count={pagination.count || 0}
      rowsPerPage={params.onePage}
      page={params.page || 0}
      onChangePage={pagination.onChangePage}
      onChangeRowsPerPage={pagination.onChangeRowsPerPage}
    />
  );
}
