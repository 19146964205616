import React from 'react';
import { TableCell } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import SubTable from '../../../../../common/SubTable/SubTable';
import { NewTextInput } from '../../../../../common/InputsNew/NewTextInput';
import { Form } from '../../../../../store/form/Form';
import { NewCurrencyInput } from '../../../../../common/InputsNew/NewCurrencyInput';
import { NewIntegerInput } from '../../../../../common/InputsNew/NewIntegerInput';
import { NewFloatInput } from '../../../../../common/InputsNew/NewFloatInput';
import { NewYearInput } from '../../../../../common/InputsNew/NewYearInput';
import { NewMonthInput } from '../../../../../common/InputsNew/NewMonthInput';
import { FormSelectInput } from '../../FormSelectInput';
import { ErrorBoundary } from '../../../../../common/ErrorBoundary/ErrorBoundary';

export function FormDocumentFields({ values, form }) {
  return (
    <SubTable editVariant>
      {form.fields.map((field, iField) => {
        const inputProps = {
          name: `data.${field.key}`,
          label: field.name,
          variant: 'outlined',
          fullWidth: true
        };

        return (
          <TableRow key={iField}>
            <TableCell colSpan={2}>
              <ErrorBoundary>
                {field.input_type === Form.InputType.TEXT ? (
                  <NewTextInput {...inputProps} />
                ) : field.input_type === Form.InputType.CURRENCY ? (
                  <NewCurrencyInput {...inputProps} />
                ) : field.input_type === Form.InputType.INTEGER ? (
                  <NewIntegerInput {...inputProps} />
                ) : field.input_type === Form.InputType.FLOAT ? (
                  <NewFloatInput {...inputProps} />
                ) : field.input_type === Form.InputType.YEAR ? (
                  <NewYearInput {...inputProps} />
                ) : field.input_type === Form.InputType.MONTH ? (
                  <NewMonthInput {...inputProps} />
                ) : field.input_type === Form.InputType.SELECT ? (
                  <FormSelectInput
                    entity={field.entity_class}
                    {...inputProps}
                  />
                ) : (
                  false
                )}
              </ErrorBoundary>
            </TableCell>
          </TableRow>
        );
      })}
    </SubTable>
  );
}
