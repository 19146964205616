import React from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { infralistRoutes } from '../../../../store/infralist/routes/infralistRoutes';
import { useInfralist } from '../../../../store/infralist/useInfralist';
import { useInfralistDirectionAccesses } from '../../../../store/infralist/useInfralistUserAccesses';
import { useInfralistVersion } from '../../../../store/infralist/versions/useInfralistVersion';
import { useInfralistVersionData } from '../../../../store/infralist/versions/useInfralistVersionData';
import { useVersionDirection } from '../../../../store/infralist/versions/useVersionDirection';
import { useVersionDirectionSections } from '../../../../store/infralist/versions/useVersionDirectionSections';
import { InfralistDirectionBreadCrumbs } from '../../direction/InfralistDirectionBreadCrumbs';
import { useInfralistDirectionColumns } from '../../hooks/useInfralistDirectionColumns';
import { InfralistCheckStatus } from '../../InfralistCheckStatus';
import { InfralistSumFilter } from '../../InfralistSumFilter';
import { PageTitlePanel } from '../../PageTitlePanel';
import { InfralistStatus } from '../../InfralistStatus';
import { InfralistTable } from '../../../../common/infralist/InfralistTable/InfralistTable';

export default function VersionDirection() {
  const { infralistId, directionId } = useParams();
  const { generatePathBasedOnVersion, versionId } = useInfralistVersion();
  const history = useHistory();

  const directionQuery = useVersionDirection({ directionId, versionId });
  const directionStatus = directionQuery.state.data?.status_by_version?.name;
  const directionCheckStatus =
    directionQuery.state.data?.check_status_by_version?.name;

  const infralistQuery = useInfralist(infralistId);
  const infralistVersionQuery = useInfralistVersionData(versionId);
  const infralistStatus =
    infralistVersionQuery.state.data?.infralist_status?.name;

  const { showCheckStatus } = useInfralistDirectionAccesses({
    directionStatus,
    directionCheckStatus
  });

  const { sections, spinner: isSectionsLoading } = useVersionDirectionSections({
    versionId,
    directionId
  });

  const columns = useInfralistDirectionColumns({
    infralistStatus,
    versionPage: true
  });

  return (
    <>
      <HeadTitle title={directionQuery.state.data?.name} />
      <InfralistDirectionBreadCrumbs
        roadmapName={infralistQuery.state.data?.roadmap?.name}
        infralistId={infralistId}
      />
      <PageTitlePanel
        title={directionQuery.state.data?.name}
        backUrl={generatePath(infralistRoutes.INFRALIST_VERSION, {
          infralistId,
          versionId
        })}
        description={
          <>
            {showCheckStatus ? (
              <InfralistCheckStatus
                isLoading={directionQuery.check.spinner}
                status={directionCheckStatus}
              />
            ) : (
              <InfralistStatus
                isLoading={directionQuery.check.spinner}
                status={directionStatus}
              />
            )}
          </>
        }
      />

      <InfralistSumFilter
        sum={directionQuery.state.data?.direction_sum_by_version}
      />

      <InfralistTable
        onLineClick={(dataItem) => {
          history.push(
            generatePathBasedOnVersion(infralistRoutes.SECTION_VERSION, {
              infralistId,
              directionId,
              sectionId: dataItem.id,
              versionId
            })
          );
        }}
        columns={columns}
        data={sections}
        isLoading={isSectionsLoading}
        readonly={true}
        minWidth={850}
      />
    </>
  );
}
