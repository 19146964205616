import React from 'react';
import PageTitle from '../../common/PageTitle/PageTitle';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';
import { useUrlQuery } from '../../common/useUrlQuery';
import { RoadmapsTable } from './RoadmapsTable';
import { useRoadmapsFilter } from './useRoadmapsFilter';

const title = 'Реестр дорожных карт';

export default function RoadmapsPage({ location, history }) {
  const [query, changeQuery] = useUrlQuery(location, history);

  const [filterComponent, filter] = useRoadmapsFilter(query, changeQuery);

  const year = filter.implementation_years?.[0];

  return (
    <>
      <HeadTitle title={title} />
      <PageTitle title={title} />
      <RoadmapsTable
        filter={filter}
        filterComponent={filterComponent}
        year={year}
      />
    </>
  );
}
