import React, { useMemo } from 'react';
import { CircularProgress } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useContextStore } from '@proscom/prostore-react';
import { useForm } from '../../../store/form/formQueries';
import BreadCrumbs from '../../../common/BreadCrumbs/BreadCrumbs';
import { queryLoaderWithExistenceCheck } from '../../../common/QueryLoader';
import PageTitle from '../../../common/PageTitle/PageTitle';
import Table from '../../../common/Table/Table';
import {
  QueryPagination,
  useQueryPagination,
  useQueryPaginationCount
} from '../../../common/usePagination';
import { useUrlQuery } from '../../../common/useUrlQuery';
import { useFormDocuments } from '../../../store/form/formDocumentQueries';
import Menu from '../../../common/Menu/Menu';
import { STORE_FORM } from '../../../store/names';
import { tryParseJson } from '../../../utils/helpers';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { User } from '../../../store/role/User';
import { useDeleteRow } from '../../../common/Table/useDeleteRow';
import { getName } from '../../../utils/data/label';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import { FormDocumentDisplayField } from './document/FormDocumentDisplayField';
import { getFillFormBreadCrumbs } from './getFillFormBreadCrumbs';

function FormDocuments({ form, location, history, match }) {
  const formStore = useContextStore(STORE_FORM);

  const columns = useMemo(() => {
    return [
      {
        label: '№',
        key: 'order',
        isWide: false
      },
      ...form.fields.slice(0, 5).map((field) => {
        return {
          label: field.name,
          key: `data.${field.key}`,
          renderCell: (value) => (
            <FormDocumentDisplayField value={value} field={field} />
          ),
          isWide: true
        };
      })
    ];
  }, [form]);

  const [query, changeQuery] = useUrlQuery(location, history);
  const pagination = useQueryPagination(query, changeQuery, 50);

  const user = useCurrentUser();
  const documentsQuery = useFormDocuments(
    {
      form_id: form.id,
      author_id: User.hasObjectPermission(user, 'form.fill_all', form.id)
        ? undefined
        : user.id
    },
    pagination.params
  );
  useQueryPaginationCount(pagination, documentsQuery);
  const documentsData = documentsQuery.state.data;
  const documents = useMemo(() => {
    if (!documentsData) {
      return null;
    }
    const { list, header } = documentsData;
    return list.map((entity, iEntity) => ({
      ...entity,
      order: 1 + iEntity + header.page * header.onePage,
      data: tryParseJson(entity.data)
    }));
  }, [documentsData]);

  const menuComponent = useMemo(
    () =>
      function MenuComponent({ row }) {
        const [isDeleting, dialog, openDialog] = useDeleteRow(
          formStore.deleteFormDocument,
          'документ'
        );

        const actions = [];

        actions.push({
          title: 'Редактировать',
          onClick: () => history.push(`${match.url}/documents/${row.id}/edit`)
        });

        actions.push({
          title: 'Удалить',
          onClick: () => openDialog(row.id)
        });

        if (actions.length === 0) {
          return false;
        }

        return (
          <>
            {isDeleting ? (
              <CircularProgress size={18} />
            ) : (
              <Menu
                iconColor="default"
                icon={MoreHorizIcon}
                options={actions}
              />
            )}
            {dialog}
          </>
        );
      },
    [history, match, formStore]
  );

  return (
    queryLoaderWithExistenceCheck(documentsQuery) || (
      <>
        <PageTitle withBackBtn={false} title={form.name} />
        <Table
          addButtonText="Создать"
          addFieldRoute={`${match.url}/documents/create`}
          hasAddField={true}
          columns={columns}
          data={documents}
          isLoading={documentsQuery.check.spinner}
          renderNoData={() => <h3>Нет доступных форм</h3>}
          rowRedirectRoute={`${match.url}/documents/`}
          pagination={<QueryPagination pagination={pagination} />}
          menuComponent={menuComponent}
        />
      </>
    )
  );
}

export default function FillFormPage({ match, history, location }) {
  const { formId } = match.params;

  const formQuery = useForm(formId);
  const form = formQuery.state.data;

  const breadCrumbs = useMemo(() => getFillFormBreadCrumbs(), []);

  return (
    <>
      <HeadTitle type={getName(form)} entity="Список документов" />
      <BreadCrumbs links={breadCrumbs} />
      {queryLoaderWithExistenceCheck(formQuery) || (
        <FormDocuments
          form={form}
          location={location}
          history={history}
          match={match}
        />
      )}
    </>
  );
}
