import React, { useCallback } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { TextField } from '@material-ui/core';
import { formatFileSize } from '../../../utils/formatNumber';
import { number } from '../../../utils/number';
import { DocumentStatus } from '../../../store/roadmap/documentStatus';
import { Signal } from '../../../common/Inputs/Signals';
import { apiUrl } from '../../../config';
import { makeOptionator } from '../../../utils/data/label';
import SubTable from '../../../common/SubTable/SubTable';
import { getFullFileName } from '../../../utils/data/file';
import AutocompleteBaseInput from '../../../common/Inputs/Selects/AutocompleteBaseInput';
import s from './EventPage.module.scss';

function FileName({ document, fileName, file }) {
  return (
    <TableRow>
      <TableCell style={{ padding: '5px', border: 'none' }}>
        {document.isNew ? (
          <span>{fileName}</span>
        ) : (
          <a
            className="external"
            href={`${apiUrl}/files/roadmapDocument/${document.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {fileName}
          </a>
        )}
        <div>
          <span className={s.filePropCaptionStyle}>Размер: </span>
          <span>{formatFileSize(number(file.file_size))}</span>
        </div>
      </TableCell>
    </TableRow>
  );
}

export function FileEntity({
  entitiesSuggestions,
  entitiesStatus,
  disabled,
  onChange,
  document
}) {
  return (
    <TableRow>
      <TableCell style={{ padding: '5px', border: 'none' }}>
        <AutocompleteBaseInput
          label="Субсидиарная сущность"
          variant="standard"
          value={document.subsidiary_entity_id}
          suggestions={entitiesSuggestions}
          disabled={disabled}
          onChange={onChange}
          suggestionsLoading={entitiesStatus}
          hasCopyBtn={false}
          isClearable={false}
        />
      </TableCell>
    </TableRow>
  );
}

const statusOptions = [
  DocumentStatus.status.NOT_APPROVED,
  DocumentStatus.status.APPROVED
].map(makeOptionator(DocumentStatus.getStatusName));

function FileStatus({ canEditStatus, disabled, onChange, status, isNew }) {
  return (
    <TableRow>
      <TableCell style={{ padding: '5px', border: 'none' }}>
        {canEditStatus ? (
          <AutocompleteBaseInput
            value={status}
            label="Статус"
            suggestions={statusOptions}
            variant="standard"
            disabled={disabled}
            onChange={onChange}
            hasCopyBtn={false}
            isClearable={false}
          />
        ) : (
          <div>
            <span>
              {isNew ? 'не сохранено' : DocumentStatus.getStatusName(status)}
            </span>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
}

function FileComment({ disabled, onChange, value }) {
  return (
    <TableRow>
      <TableCell style={{ padding: '5px', border: 'none' }}>
        <TextField
          label="Комментарий ФПО"
          className={s.textInputStyle}
          fullWidth
          value={value || ''}
          disabled={disabled}
          onChange={onChange}
        />
      </TableCell>
    </TableRow>
  );
}

export const DocumentFile = ({
  document,
  canEditStatus,
  onDocumentChange,
  documentId,
  showDocumentStatus = true,
  disabled,
  entities
}) => {
  const file = document.connected_file;

  const handleSubsidiaryEntityChange = useCallback(
    (value) => onDocumentChange(documentId, { subsidiary_entity_id: value }),
    [documentId, onDocumentChange]
  );

  const handleStatusChange = useCallback(
    (value) => onDocumentChange(documentId, { status: value }),
    [documentId, onDocumentChange]
  );

  const handleCommentChange = useCallback(
    (e) => onDocumentChange(documentId, { comment_fpo: e.target.value }),
    [documentId, onDocumentChange]
  );

  if (!file) {
    return (
      <div>
        <Signal>Ошибка. Файл недоступен</Signal>
      </div>
    );
  }

  const fileName = getFullFileName(file);

  return (
    <div>
      <SubTable cols={1}>
        <FileName document={document} file={file} fileName={fileName} />
        {entities?.canEditEntities && (
          <FileEntity
            entitiesStatus={entities?.entitiesStatus}
            entitiesSuggestions={entities?.entitiesSuggestions}
            disabled={disabled}
            onChange={handleSubsidiaryEntityChange}
            document={document}
          />
        )}
        {showDocumentStatus && (
          <FileStatus
            canEditStatus={canEditStatus}
            disabled={disabled}
            onChange={handleStatusChange}
            status={document.status}
            isNew={document.isNew}
          />
        )}
        {showDocumentStatus && canEditStatus && (
          <FileComment
            disabled={disabled}
            onChange={handleCommentChange}
            value={document.comment_fpo}
          />
        )}
      </SubTable>
    </div>
  );
};
