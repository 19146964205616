import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import MaterialLink from '@material-ui/core/Link';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useContextStore } from '@proscom/prostore-react';
import { ActionButton } from '../../../common/Form/ActionButton';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../graphql/graphqlErrors';
import { PageActionsBar } from '../../../common/PageActionsBar/PageActionsBar';
import { EditButton } from '../../../common/Form/EditButton/EditButton';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { RegionalEvent } from '../../../store/roadmap/RegionalEvent';
import { DateCell } from '../../../common/TableCells';
import SubTable from '../../../common/SubTable/SubTable';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { STORE_ROADMAP_EVENT } from '../../../store/names';
import {
  EVENT_TYPE_PHOTOS,
  EVENT_TYPE_PURCHASES,
  EVENT_TYPE_SUBSIDIARY_ENTITIES,
  isInfralist,
  isStaffSchedules
} from '../../../store/roadmap/roadmapEvents';
import { createPreservedUrl } from '../../../utils/links';
import { Individual } from '../../../store/Individual';
import ErrorMessage from '../../../common/ErrorMessage/ErrorMessage';

import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import { getName } from '../../../utils/data/label';
import { User } from '../../../store/role/User';

import { enableArchiveDownload } from '../../../config';
import { RegionalEventBreadCrumbs } from './RegionalEventBreadCrumbs';

import { EventDocuments } from './EventDocuments';

import { StaffingTable } from './staffingTable/StaffingTable';
import { EventPhotos } from './photos/EventPhotos';
import { InfralistsInfo } from './edit/parts/InfralistsInfo';

const RegionalEventPage = ({
  event,
  regionalEvent,
  currentUrl,
  projectId,
  roadmapId,
  eventId
}) => {
  const roadmapEventStore = useContextStore(STORE_ROADMAP_EVENT);
  const user = useCurrentUser();
  const canSeeFederalEvent = RegionalEvent.canSeeFederalEvent(user);
  const canSeeSSLink = event.event_type === EVENT_TYPE_SUBSIDIARY_ENTITIES;
  const canSeePurchaseLink = event.event_type === EVENT_TYPE_PURCHASES;
  const isPhotos = event.event_type === EVENT_TYPE_PHOTOS;
  const isStaff = isStaffSchedules(event.event_type);
  const isInfralistEvent = isInfralist(event.event_type);

  const regionalResponsiblePerson =
    event.responsibility_level_rf_subject && regionalEvent?.responsible_person;

  const regionalIndividualData = regionalResponsiblePerson?.individual_data;

  const federalResponsiblePerson =
    event.responsibility_level_vo && event.responsible_person;

  const project = regionalEvent?.regional_project;
  const roadmap = event.roadmap;
  const region = regionalEvent?.region;

  const { showSlots, federalSlots, regionalSlots } = useMemo(() => {
    return RegionalEvent.getSlots(event);
  }, [event]);

  const federalDocuments = event.roadmap_event_documents;
  const regionalDocuments = regionalEvent?.roadmap_event_documents;

  const canEdit =
    regionalEvent?.permissions?.edit || regionalEvent?.permissions?.approve;

  return (
    <>
      <HeadTitle
        type="Региональное мероприятие"
        entity={`${getName(event)} (${getName(region)})`}
      />
      <RegionalEventBreadCrumbs
        project={project}
        event={event}
        projectId={projectId}
        federalEventId={eventId}
        roadmapId={roadmapId}
      />
      <PageTitle title={`${getName(event)} (${getName(region)})`} withBackBtn />
      {regionalEvent ? (
        <>
          <PageActionsBar
            editButton={
              canEdit && <EditButton editLink={`${currentUrl}/edit`} />
            }
            actions={
              regionalEvent.permissions?.sendForApproval && (
                <ActionButton
                  onClick={() => {
                    return roadmapEventStore
                      .sendForApproval(event.id, region.id)
                      .catch((error) => {
                        showGraphqlErrorToast(
                          error,
                          mutationErrors.updateObject
                        );
                      });
                  }}
                  title="Отправить на утверждение"
                />
              )
            }
          />
          <SubTable title="Общие сведения">
            <TableRow>
              <TableCell>Регион</TableCell>
              <TableCell>{region.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Региональный проект</TableCell>
              <TableCell>
                <MaterialLink
                  component={Link}
                  to={`/projects/${project.id}`}
                  color="textPrimary"
                >
                  {project.name}
                </MaterialLink>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Результат</TableCell>
              <TableCell>{roadmap.result.name_full}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Год</TableCell>
              <TableCell>{event.year}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Утвердил</TableCell>
              <TableCell>{User.getUserName(regionalEvent.approver)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Дата утверждения</TableCell>
              <DateCell value={regionalEvent.approved_at} />
            </TableRow>
            {canSeeSSLink && (
              <TableRow>
                <TableCell colSpan={2}>
                  <MaterialLink
                    className="external"
                    component={Link}
                    to={createPreservedUrl(`/subsidiaryEntities`, {
                      regional_project_id: project.id,
                      owner_roadmap_id: roadmap.id,
                      year: event.year
                    })}
                  >
                    Перейти к реестру субсидиарных сущностей
                  </MaterialLink>
                </TableCell>
              </TableRow>
            )}
            {canSeeFederalEvent && (
              <TableRow>
                <TableCell colSpan={2}>
                  <MaterialLink
                    className="external"
                    component={Link}
                    to={`/roadmaps/${roadmap.id}/events/${event.id}?tab=event`}
                  >
                    Перейти к федеральному мероприятию
                  </MaterialLink>
                </TableCell>
              </TableRow>
            )}
            {canSeePurchaseLink && (
              <TableRow>
                <TableCell colSpan={2}>
                  <MaterialLink
                    className="external"
                    component={Link}
                    to={createPreservedUrl(`/purchases`, {
                      regional_project_id: project.id,
                      expenses_direction_id:
                        roadmap.result.expenses_direction.id,
                      year: event.year
                    })}
                  >
                    Перейти к закупкам
                  </MaterialLink>
                </TableCell>
              </TableRow>
            )}
          </SubTable>
          {event.responsibility_level_vo && (
            <SubTable
              title={
                'Ответственный за реализацию. Ведомственный проектный офис нацпроекта "Образование"'
              }
            >
              <TableRow>
                <TableCell>ФИО</TableCell>
                <TableCell>
                  {Individual.combineName(federalResponsiblePerson)}
                </TableCell>
              </TableRow>
            </SubTable>
          )}
          {event.responsibility_level_rf_subject && (
            <SubTable title="Ответственный за реализацию. Субъект Российской Федерации">
              <TableRow>
                <TableCell>ФИО</TableCell>
                <TableCell>
                  {Individual.combineName(regionalResponsiblePerson)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>
                  {regionalResponsiblePerson?.emails && (
                    <a
                      className="external"
                      href={'mailto:' + regionalResponsiblePerson.emails[0]}
                    >
                      {regionalResponsiblePerson.emails[0]}
                    </a>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Номер мобильного телефона</TableCell>
                <TableCell>{regionalResponsiblePerson?.phones?.[0]}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Должность</TableCell>
                <TableCell>{regionalIndividualData?.occupation}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Отдел/Департамент</TableCell>
                <TableCell>{regionalIndividualData?.department}</TableCell>
              </TableRow>
            </SubTable>
          )}
          <SubTable title="Результат мероприятия">
            <TableRow>
              <TableCell>Наименование результата мероприятия</TableCell>
              <TableCell>{event?.event_result || ''}</TableCell>
            </TableRow>
          </SubTable>
          <EventDocuments
            slots={federalSlots}
            documents={federalDocuments}
            showSlots={showSlots}
            showDocumentStatus={false}
            title="Документы ведомственного проектного офиса"
          />
          {isPhotos ? (
            <EventPhotos event={event} regionalEvent={regionalEvent} />
          ) : (
            <EventDocuments
              slots={regionalSlots}
              documents={regionalDocuments}
              showSlots={showSlots}
              regionalEventId={regionalEvent.id}
              eventName={getName(event)}
              event={event}
              isArchiveDownload={enableArchiveDownload}
              title="Документы"
            />
          )}
          {isStaff && (
            <StaffingTable
              staffSchedules={regionalEvent.staff_schedules}
              roadmapId={roadmap.id}
              year={event.year}
              regionId={region.id}
            />
          )}
          {isInfralistEvent && (
            <InfralistsInfo
              event={regionalEvent}
              eventType={event.event_type}
            />
          )}
          <SubTable title="Сроки выполнения">
            <TableRow>
              <TableCell>Плановый срок</TableCell>
              {event.plan_term ? (
                <DateCell value={event.plan_term} />
              ) : (
                <TableCell>{event.plan_roadmap?.periodicity_string}</TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell>Фактический срок</TableCell>
              <DateCell value={regionalEvent?.fact_term} />
            </TableRow>
          </SubTable>
          <SubTable title="Статус выполнения мероприятия">
            <TableRow>
              <TableCell>Статус</TableCell>
              <TableCell>
                {RegionalEvent.status.getName(regionalEvent?.status)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Комментарий ФПО</TableCell>
              <TableCell>{regionalEvent?.comment_fpo}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Примечание</TableCell>
              <TableCell>{regionalEvent?.annotation}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Дата заполнения</TableCell>
              <DateCell value={regionalEvent?.created_at} />
            </TableRow>
            <TableRow>
              <TableCell>Дата обновления</TableCell>
              <DateCell value={regionalEvent?.updated_at} />
            </TableRow>
            <TableRow>
              <TableCell>Версия</TableCell>
              <TableCell>{regionalEvent?.version}</TableCell>
            </TableRow>
          </SubTable>
        </>
      ) : (
        <ErrorMessage error="Региональное мероприятие не найдено. Пожалуйста, обратитесь к администратору" />
      )}
    </>
  );
};

export default RegionalEventPage;
