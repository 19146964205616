import { toast } from 'react-toastify';
import { compareSimple, makeComparator } from '../sorting';
import { isValidNumber } from '../helpers';

export async function getWorkbookFromFile(file) {
  if (!file) return null;
  const XLSX = await import('xlsx');
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      resolve(workbook);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export function findClosestSuggestion(label, options) {
  const results = options
    .filter((o) => o.label.indexOf(label) >= 0)
    .sort(
      makeComparator(compareSimple, (o) =>
        Math.abs(o.label.length - label.length)
      )
    );
  return results[0];
}

export function parseEnum(enumHelpers, status) {
  const result = enumHelpers.getFromName(status);
  if (result === null) {
    throw new Error(`Неизвестное поле "${status}"`);
  }
  return result;
}

export async function createFieldParser(sheet) {
  const XLSX = await import('xlsx');

  const get = (c, r) => {
    const cell = XLSX.utils.encode_cell({ c, r });
    return sheet[cell]?.v;
  };

  const getNumber = (c, r) => {
    const cell = XLSX.utils.encode_cell({ c, r });
    const result = +sheet[cell]?.v;
    if (!isValidNumber(result)) {
      throw new Error(
        `Некорректное значение в ячейке '${cell}'. Ожидается число`
      );
    }
    return result;
  };

  return {
    get,
    getNumber
  };
}

export async function parseXlsxFile(
  files,
  { parseWorkbook, getUpdates, applyUpdates }
) {
  try {
    const wb = await getWorkbookFromFile(files?.[0]);
    if (!wb) {
      throw new Error('Не удалось прочитать файл');
    }

    const data = await parseWorkbook(wb);
    const updates = await getUpdates(data);

    if (Object.keys(updates).length > 0) {
      applyUpdates(updates);
      toast.success('Данные успешно загружены');
    } else {
      toast.info('Нет подходящих данных для загрузки');
    }
  } catch (e) {
    console.error(e);
    toast.error('Не удалось прочитать файл. ' + e.toString());
  }
}
