import React from 'react';
import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';
import { useParams } from 'react-router';
import BreadCrumbs from '../../common/BreadCrumbs/BreadCrumbs';
import { ButtonLine } from '../../common/ButtonLine/ButtonLine';
import { LabelField } from '../../common/infralist/FormFields/LabelField/LabelField';
import { TextField } from '../../common/infralist/FormFields/TextField/TextField';
import { FormikAutocomplete } from '../../common/infralist/FormikFormFields/FormikAutocomplete/FormikAutocomplete';
import { FormikCurrency } from '../../common/infralist/FormikFormFields/FormikCurrency';
import { FormikNumber } from '../../common/infralist/FormikFormFields/FormikNumber';
import { FormikTextField } from '../../common/infralist/FormikFormFields/FormikTextField/FormikTextField';
import { ProgressButton } from '../../common/ProgressButton';
import { PreventChangePage } from '../../PreventChangePage';
import { formatNumber } from '../../utils/formatNumber';
import { PageTitlePanel } from '../infralist/PageTitlePanel';
import { createEquipmentInitialValues } from './initialValues';
import { useCreateEquipmentPage } from './useCreateEquipentPage';
import { useCreateEquipmentPageValidationSchema } from './useCreateEquipmentPageValidationSchema';
import s from './CreateEquipmentPage.module.scss';

export default function CreateEquipmentPage() {
  const { sectionId, infralistId, directionId } = useParams();
  const {
    backUrl,
    breadcrumbs,
    equipmentMap,
    equipmentList,
    isFormationComplete,
    submitCreateEquipment,
    back
  } = useCreateEquipmentPage(infralistId, directionId, sectionId);

  const validationSchema = useCreateEquipmentPageValidationSchema();

  return (
    <>
      <BreadCrumbs links={breadcrumbs} />
      <PageTitlePanel
        title={'Добавление оборудования'}
        backUrl={backUrl}
        withBackBtn={true}
      />
      <Formik
        onSubmit={submitCreateEquipment}
        initialValues={createEquipmentInitialValues}
        validationSchema={validationSchema}
      >
        {({ values, dirty, isSubmitting }) => (
          <Form className={s.form}>
            <PreventChangePage when={dirty} />
            <LabelField label={'Наименование оборудования (ФПО)'}>
              <FormikAutocomplete
                options={equipmentList}
                name={'fpo_equipment_id'}
                placeholder={'Выберите оборудование'}
                hasCopyBtn={true}
              />
            </LabelField>
            <LabelField
              label={'Краткие примерные технические характеристики (ФПО)'}
            >
              <TextField
                placeholder={'Автозаполняемое поле'}
                value={equipmentMap.get(values.fpo_equipment_id).description}
                multiline
                disabled
              />
            </LabelField>
            <LabelField label={'Наименование оборудования (РВПО)'}>
              <FormikTextField
                name={'name_rvpo'}
                placeholder={'Например, смартфон для тестирования'}
              />
            </LabelField>
            <LabelField
              label={'Краткие примерные технические характеристики (РВПО)'}
            >
              <FormikTextField
                name={'description_rvpo'}
                placeholder={
                  'Например, Наличие контроллера, сенсорная панель, совместимость с мобильным устройством через USB Type-C, угол обзора 101 гр, 2 сменных USB-держателя (USB C-Type и microUSB), акселерометр, гироскоп'
                }
                multiline
              />
            </LabelField>
            <LabelField label={'Примерная модель (РВПО)'}>
              <FormikTextField
                name={'model_rvpo'}
                placeholder={'Например, смартфон Honor 10 128 ГБ черный'}
                multiline
              />
            </LabelField>
            <div className={s.twoCol}>
              <LabelField label={'Единица измерения'}>
                <FormikTextField name={'unit'} placeholder={'Например, шт'} />
              </LabelField>
              <LabelField label={'Количество'}>
                <FormikNumber name={'quantity'} placeholder={'Например, 100'} />
              </LabelField>
              <LabelField label={'Цена, руб.'}>
                <FormikCurrency name={'price'} placeholder={'Например, 500'} />
              </LabelField>
              <LabelField label={'Стоимость, руб.'}>
                <TextField
                  value={formatNumber(
                    Number(values.price) * Number(values.quantity)
                  )}
                  disabled
                />
              </LabelField>
            </div>
            <LabelField label={'Ссылка на оборудование'}>
              <FormikTextField
                name={'equipment_external_url'}
                multiline
                className={s.links}
                isValueTrimmed
                placeholder={
                  'Например, https://www.citilink.ru/catalog/computers_and_notebooks/hdd/ssd_in/1014931/'
                }
              />
            </LabelField>
            <ButtonLine>
              <ProgressButton
                type={'submit'}
                variant="contained"
                color="secondary"
                disabled={isFormationComplete}
                isLoading={isSubmitting}
              >
                Сохранить
              </ProgressButton>
              <Button color="default" variant={'outlined'} onClick={back}>
                Отменить
              </Button>
            </ButtonLine>
          </Form>
        )}
      </Formik>
    </>
  );
}
