import React from 'react';
import { withRouter } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import s from './PageTitle.module.scss';

const PageTitle = ({ history, title, withBackBtn }) => (
  <div className={s.PageTitle}>
    {withBackBtn && (
      <IconButton className={s.BackButton} onClick={() => history.goBack()}>
        <ArrowBackIcon />
      </IconButton>
    )}
    <h2>{title || '...'}</h2>
  </div>
);

PageTitle.defaultProps = {
  title: '...',
  widthBackBtn: true
};

export default withRouter(PageTitle);
