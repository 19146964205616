import { Check, Close } from '@material-ui/icons';
import React from 'react';
import { COMMENT_STATUSES } from '../../../../../store/infralist/commentEntities';
import { StatusService } from '../../../../../utils/StatusService';
import colors from '../../../../../colors.scss';
import s from './Comment.module.scss';

const commentStatusTranslation = {
  [COMMENT_STATUSES.ACCEPTED_BY_RVPO]: 'Принято РВПО',
  [COMMENT_STATUSES.ACCEPTED_BY_FPO]: 'Принято ФПО',
  [COMMENT_STATUSES.NOT_ACCEPTED_BY_FPO]: 'Отклонено ФПО',
  [COMMENT_STATUSES.NOT_ACCEPTED_BY_RVPO]: 'Отклонено РВПО'
};

const commentStatusService = new StatusService({
  colors: {
    [COMMENT_STATUSES.ACCEPTED_BY_RVPO]: colors.blue,
    [COMMENT_STATUSES.ACCEPTED_BY_FPO]: colors.blue,
    [COMMENT_STATUSES.NOT_ACCEPTED_BY_RVPO]: colors.red,
    [COMMENT_STATUSES.NOT_ACCEPTED_BY_FPO]: colors.red
  },
  defaultStatus: COMMENT_STATUSES.EMPTY
});

export const useCommentStatus = (status) => {
  const accepted = [
    COMMENT_STATUSES.ACCEPTED_BY_FPO,
    COMMENT_STATUSES.ACCEPTED_BY_RVPO
  ].includes(status);

  return (
    <span
      className={s.Status}
      style={{ color: commentStatusService.getColor(status) }}
    >
      {accepted ? (
        <Check
          fontSize={'small'}
          style={{ color: commentStatusService.getColor(status) }}
          className={s.Icon}
        />
      ) : (
        <Close
          fontSize={'small'}
          style={{ color: commentStatusService.getColor(status) }}
          className={s.Icon}
        />
      )}
      {commentStatusTranslation[status]}
    </span>
  );
};
