import gql from 'graphql-tag';
import { FRAGMENT_ROADMAP_EVENT } from '../queries/roadmapEvents';

export const MUTATION_UPDATE_ROADMAP_EVENT = gql`
  mutation updateRoadmapEvent($input: RoadmapEventInput!) {
    result: updateRoadmapEvent(input: $input) {
      ...roadmapEvent
      version
      year
    }
  }

  ${FRAGMENT_ROADMAP_EVENT}
`;

export const MUTATION_ROADMAP_FILE_TOKEN = gql`
  mutation createRoadmapDocumentFileToken($id: String!) {
    createRoadmapDocumentFileOneTimeToken(id: $id)
  }
`;

export const MUTATION_ROADMAP_DOCUMENT_TOKEN = gql`
  mutation createRoadmapDocumentToken($id: String!) {
    createRoadmapDocumentOneTimeToken(id: $id)
  }
`;

export const MUTATION_UPLOAD_DOCUMENT_TOKEN = gql`
  mutation createUploadOneTimeToken {
    createUploadOneTimeToken
  }
`;

export const MUTATION_UPLOAD_UNIVERSAL_DOCUMENT_TOKEN = gql`
  mutation createUploadUniversalDocumentFileOneTimeToken {
    createUploadUniversalDocumentFileOneTimeToken
  }
`;

export const MUTATION_DOCUMENT_ARCHIVE_TOKEN = gql`
  mutation createRoadmapDocumentsArchiveOneTimeToken($id: String!) {
    createRoadmapDocumentsArchiveOneTimeToken(id: $id)
  }
`;
