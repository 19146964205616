import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import React, { useEffect, useMemo, useState } from 'react';
import { QUERY_INFRALIST_RVPO_EQUIPMENT_STATUSES } from '../../graphql/queries/infralistStatuses';
import { equipmentStatusService } from './equipmentStatusService';

const options = {
  query: QUERY_INFRALIST_RVPO_EQUIPMENT_STATUSES
};

export const useEquipmentStatuses = (hasAccess) => {
  const [statusList, setStatusList] = useState([]);
  const client = useContextApolloClient();

  useEffect(() => {
    if (hasAccess) {
      client
        .query({
          ...options,
          variables: {}
        })
        .then((response) => {
          setStatusList(response.data.infralistRvpoEquipmentStatuses);
        });
    }
  }, [client, hasAccess]);

  const statusListOptions = useMemo(() => {
    return statusList.map((item) => ({
      value: item.id,
      label: equipmentStatusService.getColoredName(item.name)
    }));
  }, [statusList]);

  return {
    statusList,
    statusListOptions
  };
};
