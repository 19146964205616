import { useCallback, useEffect, useState } from 'react';
import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { QUERY_INFRALIST_CHANGE_LOGS } from '../../../../graphql/queries/infralist';
import { getEndOfDayString, getStartOfDayString } from '../../../../utils/date';
import { useSortPager } from '../../../../utils/hooks/useSortPager';

/**
 * Заменяет пустые массивы фильтра на null, чтобы избежать ошибок сервера
 * @param arr {[]}
 */
const setArrayToNullIfEmpty = (arr) => (arr.length > 0 ? arr : null);

export const useChangeLogs = (infralistId) => {
  const [queryResult, setQueryResult] = useState();
  const [changeLogs, setChangeLogs] = useState([]);
  const [isLoadingChangeLogs, setIsLoadingChangeLogs] = useState(false);
  const client = useContextApolloClient();

  const { loadMore, params, setFilter } = useSortPager({
    itemsPerPage: 20,
    initialParams: {
      filter: {
        infralist_id: infralistId,
        date: null,
        direction_ids: [],
        section_ids: [],
        fpo_equipment_ids: []
      }
    }
  });

  const fetchChangeLogs = useCallback(() => {
    // список журнала запрашиваем только после выбора даты
    if (!params.filter.date) {
      return;
    }
    setIsLoadingChangeLogs(true);
    client
      .query({
        query: QUERY_INFRALIST_CHANGE_LOGS,
        variables: {
          input: {
            ...params,
            filter: {
              ...params.filter,
              date: {
                start: getStartOfDayString(params.filter.date),
                end: getEndOfDayString(params.filter.date)
              },
              direction_ids: setArrayToNullIfEmpty(params.filter.direction_ids),
              section_ids: setArrayToNullIfEmpty(params.filter.section_ids),
              fpo_equipment_ids: setArrayToNullIfEmpty(
                params.filter.fpo_equipment_ids
              )
            }
          }
        }
      })
      .then((response) => {
        const changeLogData = response?.data?.infralistChangeLogs;
        setQueryResult(changeLogData);
        if (changeLogData.header.page === 0) {
          setChangeLogs(changeLogData.list);
        } else {
          setChangeLogs((prev) => [...prev, ...changeLogData.list]);
        }
      })
      .finally(() => setIsLoadingChangeLogs(false));
  }, [client, params, setChangeLogs, setIsLoadingChangeLogs]);

  useEffect(fetchChangeLogs, [fetchChangeLogs]);

  const loadMoreChangeLogs = useCallback(() => {
    if (queryResult?.header?.hasNext && !isLoadingChangeLogs) {
      loadMore();
    }
  }, [queryResult, isLoadingChangeLogs, loadMore]);

  return {
    changeLogs,
    isLoadingChangeLogs,
    params,
    loadMoreChangeLogs,
    setFilter
  };
};
