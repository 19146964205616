import { toast } from 'react-toastify';
import { joinNonEmpty } from '../utils/data/label';
import { shortenText } from '../utils/string';

export const STATUS_CODE_GRAPHQL = 400;
export const STATUS_CODE_AUTH = 401;
export const STATUS_CODE_ACCESS = 403;
export const STATUS_CODE_NOT_FOUND = 404;
export const STATUS_CODE_CONFLICT = 409;
export const STATUS_CODE_NETWORK = 1000;
export const STATUS_CODE_UNKNOWN = 1001;

export const mutationErrors = {
  login: { statusCode: STATUS_CODE_AUTH, message: 'Неверный логин или пароль' },
  logOut: {
    statusCode: STATUS_CODE_GRAPHQL,
    message:
      'Возникла ошибка при выходе из системы. Попробуйте перезагрузить страницу'
  },
  createObject: {
    statusCode: STATUS_CODE_GRAPHQL,
    message: 'Возникла ошибка при создании объекта. Попробуйте еще раз'
  },
  deleteObject: {
    statusCode: STATUS_CODE_GRAPHQL,
    message: 'Возникла ошибка при удалении объекта. Попробуйте еще раз'
  },
  updateObject: {
    statusCode: STATUS_CODE_GRAPHQL,
    message: 'Возникла ошибка при сохранении объекта. Попробуйте еще раз'
  },
  confirmObject: {
    statusCode: STATUS_CODE_GRAPHQL,
    message: 'Возникла ошибка при утверждении объекта. Попробуйте еще раз'
  },
  revokeConfirmationObject: {
    statusCode: STATUS_CODE_GRAPHQL,
    message:
      'Возникла ошибка при отмене утверждения объекта. Попробуйте еще раз'
  },
  requestApprovalRevokeObject: {
    statusCode: STATUS_CODE_GRAPHQL,
    message:
      'Возникла ошибка при запросе редактирования объекта. Попробуйте еще раз'
  },
  approveObject: {
    statusCode: STATUS_CODE_GRAPHQL,
    message: 'Возникла ошибка при утверждении формы. Попробуйте еще раз'
  },
  sendForApprovalObject: {
    statusCode: STATUS_CODE_GRAPHQL,
    message:
      'Возникла ошибка при отправке формы на утверждение. Попробуйте еще раз'
  }
};

/**
 * @typedef ParsedError
 * @property error {string}
 * @property statusCode {number}
 * @property message {string}
 */

/**
 * Парсит ошибку GraphQL, пытаясь достать ошибку, возвращенную бекендом
 * Бекенд возвращает ошибку в виде json-строки, содержащей statusCode и message
 * Если ошибка не с бекенда, то возвращается объект у которого есть хотя бы message
 * и statusCode в худшем случае STATUS_CODE_UNKNOWN
 * Если какие-то проблемы с сетью, то возвращает STATUS_CODE_NETWORK
 *
 * Если переданная ошибка не определяется, как ошибка graphql, то просто возвращает ее
 *
 * @return {ParsedError|any}
 */
export function getGraphQLErrorInfo(error) {
  if (typeof error !== 'object') return error;

  let graphQLErrors;
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    graphQLErrors = error.graphQLErrors;
  } else if (
    error.networkError &&
    error.networkError.result &&
    error.networkError.result.errors
  ) {
    graphQLErrors = error.networkError.result.errors;
  }

  if (graphQLErrors && graphQLErrors.length > 0) {
    try {
      return {
        ...error.networkError,
        ...JSON.parse(graphQLErrors[0].message)
      };
    } catch (e) {
      return {
        statusCode: STATUS_CODE_UNKNOWN,
        ...error.networkError,
        ...graphQLErrors[0]
      };
    }
  }

  if (error.networkError) {
    if (error.networkError.statusCode) {
      return {
        ...error.networkError,
        message:
          error.networkError.bodyText || error.networkError.result.message
      };
    }

    return {
      error: error.networkError,
      statusCode: STATUS_CODE_NETWORK
    };
  }

  return error;
}

/**
 * Возвращает текст ошибки для отображения пользователю
 * @param parsedError {ParsedError|any}
 * @param codeOverride
 */
export function getParsedErrorMessage(parsedError, codeOverride = null) {
  if (!parsedError) return null;
  if (typeof parsedError !== 'object') return parsedError + '';
  const errorId = parsedError.errorId ? '#' + parsedError.errorId : null;
  const codeMessage =
    codeOverride || getErrorCodeMessage(parsedError.statusCode);
  let errorMessage = parsedError.message;
  if (errorMessage) {
    if (typeof errorMessage === 'object') {
      errorMessage = `Ошибка: ${JSON.stringify(errorMessage)}`;
    }
  } else {
    errorMessage = `Ошибка: ${JSON.stringify(parsedError.error)}`;
  }

  errorMessage = shortenText(errorMessage, 400);

  return joinNonEmpty([codeMessage, errorId, errorMessage], '. ');
}

/**
 * Возвращает общеупотребимые сообщения ошибок
 * @param statusCode {number}
 * @returns {string|null}
 */
export function getErrorCodeMessage(statusCode) {
  switch (statusCode) {
    case STATUS_CODE_AUTH:
      return 'Требуется авторизация';
    case STATUS_CODE_ACCESS:
      return 'У вас недостаточно прав для выполнения данного действия';
    case STATUS_CODE_NOT_FOUND:
      return 'Не найдено';
    case STATUS_CODE_NETWORK:
      return 'Ошибка сети. Проверьте свой доступ к интернету';
    case STATUS_CODE_CONFLICT:
      return 'Конфликт. Такой документ уже существует';
    default:
      return null;
  }
}

/**
 * Позволяет выдать кастомное сообщение об ошибке по совпадению statusCode
 * @param parsedError {ParsedError} - ошибка
 * @param overrides {object} - объект кастомных сообщений вида {[code]: message}
 */
export function overrideErrorMessage(parsedError, overrides = null) {
  return getParsedErrorMessage(
    parsedError,
    overrides?.[parsedError.statusCode]
  );
}

/**
 * Позволяет показать ошибку мутации пользователю
 * @param error - ошибка
 * @param messageOverride? {{statusCode:number, message:string}} - кастомное сообщение об ошибке
 */
export function showGraphqlErrorToast(error, ...messageOverride) {
  const errorInfo = getGraphQLErrorInfo(error);
  const overrideData = {};
  for (let i = 0; i < messageOverride.length; i++) {
    const message = messageOverride[i];
    if (message) {
      overrideData[message.statusCode] = message.message;
    }
  }
  const errorMessage = overrideErrorMessage(errorInfo, overrideData);
  toast.error(errorMessage);
}

/**
 * Получает тестовое презентабельное пользователю описание ошибки axios
 * @param error - ошибка axios
 * @returns {string}
 */
export function getAxiosErrorMessage(error) {
  if (error?.response?.status) {
    return getErrorCodeMessage(error.response.status) || 'Неизвестная ошибка';
  }

  const message = error.message;
  if (message.includes('413')) {
    return 'Размер файла слишком большой';
  }

  return message || 'Неизвестная ошибка';
}
