import React, { useMemo } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { queryLoader } from '../../../../common/QueryLoader';
import Table from '../../../../common/Table/Table';
import Menu from '../../../../common/Menu/Menu';
import { useUrlQuery } from '../../../../common/useUrlQuery';
import { useRoadmapFederalEvents } from '../../../../store/roadmap/useRoadmapFederalEvents';
import { useSorting } from '../../../../common/Table/Sorting/useSorting';
import { reformatDateWithoutTimezone } from '../../../../utils/date';
import { Sorting } from '../../../../common/Table/Sorting/Sorting';
import { useEventFilter } from './useEventFilter';

const getRoadmapEventsColumn = (sortableColumns) => [
  { label: '№', key: 'order', isThin: true },
  { label: 'Код', key: 'code', isThin: true },
  {
    label: 'Название мероприятия',
    key: 'name',
    isBold: true,
    isWide: true
  },
  ...sortableColumns
];

export const Events = ({ match, history, location, roadmap, roadmapYear }) => {
  const { roadmapId } = match.params;
  const [query, changeQuery] = useUrlQuery(location, history);

  const [sortableColumns, sorting] = useSorting([
    {
      field: Sorting.SortFields.plan_term,
      key: (row) => reformatDateWithoutTimezone(row.plan_term),
      isThin: true
    }
  ]);

  const [filterComponent, filter] = useEventFilter(
    query,
    changeQuery,
    roadmap,
    roadmapYear
  );

  const roadmapFederalEventsQuery = useRoadmapFederalEvents(filter, sorting);

  const events = useMemo(() => {
    if (!roadmapFederalEventsQuery.state.data) return [];
    return roadmapFederalEventsQuery.state.data
      .slice()
      .map(({ name, name_full, ...rest }, index) => ({
        ...rest,
        order: index + 1,
        name: name_full || name
      }));
  }, [roadmapFederalEventsQuery.state.data]);

  const columns = useMemo(() => getRoadmapEventsColumn(sortableColumns), [
    sortableColumns
  ]);

  return (
    <>
      {queryLoader(roadmapFederalEventsQuery) || (
        <Table
          columns={columns}
          data={events}
          rowRedirectRoute={`/roadmaps/${roadmapId}/events/`}
          renderMenu={(row) => {
            const canEdit = row.permissions?.edit;
            if (!canEdit) return null;
            return (
              <Menu
                iconColor="default"
                icon={MoreHorizIcon}
                options={[
                  {
                    title: 'Редактировать',
                    onClick: () =>
                      history.push(`${match.url}/events/${row.id}/edit`)
                  }
                ]}
              />
            );
          }}
          filter={filterComponent}
        />
      )}
    </>
  );
};
