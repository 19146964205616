import React from 'react';
import { NewNumberField } from './NewNumberField';
import { useFieldFast } from './useFieldFast';

export const NewCurrencyInput = function NewCurrencyInput(props) {
  const [field, meta, helpers] = useFieldFast(props);
  return (
    <NewNumberField
      onChange={(value) => {
        helpers.setValue(value || 0);
      }}
      value={meta.value}
      unit={' руб.'}
      {...props}
    />
  );
};
