import React from 'react';
import { formatNumber } from '../../utils/formatNumber';
import s from './InfralistSumFilter.module.scss';

export const InfralistSumFilter = ({ sum, children }) => {
  return (
    <div className={s.root}>
      <p className={s.sum}>Итого: {formatNumber(sum)} руб.</p>
      {children}
    </div>
  );
};
