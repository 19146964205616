import { useStore } from '@proscom/prostore-react';
import { COMMENT_TYPES } from '../../../../store/infralist/commentEntities';
import { useModifySectionComments } from '../../../../store/infralist/useModifySectionComments';
import { STORE_AUTH } from '../../../../store/names';
import { useUserRoleByPermissions } from '../../../../utils/hooks/useUserRoleByPermissions';

export const useSectionComments = ({ infralistId, sectionId, type }) => {
  const [auth] = useStore(STORE_AUTH);
  const user = auth.authData.user;
  const { getRole } = useUserRoleByPermissions();
  const role = getRole(user);
  const commentType =
    type && [COMMENT_TYPES.GENERAL, role?.type].includes(type)
      ? type
      : role?.type;
  const {
    createCommentary,
    updateCommentary,
    deleteCommentary
  } = useModifySectionComments(commentType);

  const onCreate = (data) => {
    return createCommentary({
      content: data.content,
      infralist_id: infralistId,
      section_id: sectionId,
      type: commentType,
      user_id: user?.id
    });
  };

  const onUpdate = (data) => {
    return updateCommentary({
      content: data.content,
      infralist_id: infralistId,
      section_id: sectionId,
      type: commentType,
      id: data.id
    });
  };

  const onDelete = (id) => {
    return deleteCommentary(id);
  };

  return {
    deleteCommentary: onDelete,
    createCommentary: onCreate,
    updateCommentary: onUpdate,
    commentType
  };
};
