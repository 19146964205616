import { isFinite } from 'lodash-es';
import { EMDASH } from './constants';

export const localeNumberFormat = ({ number, minLength, maxLength = 20 }) => {
  // Проверка на число, исключает Infinity и NaN
  if (!isFinite(number)) {
    return EMDASH;
  }

  const formatter = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: minLength,
    maximumFractionDigits: maxLength
  });
  return formatter.format(number);
};
