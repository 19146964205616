import React from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import { IndicatorForecastBreadCrumbs } from '../IndicatorForecastBreadCrumbs';
import { useIndicatorForecast } from '../../../../store/indicatorForecasts/useIndicatorForecast';
import { IndicatorForecast } from '../../../../store/indicatorForecasts/IndicatorForecast';
import { useOtherEditor } from '../../../../common/Form/useOtherEditor';
import { removeLastPathItem } from '../../../../utils/url';
import { INDICATOR_FORECAST_STORE } from '../../../../store/names';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../graphql/graphqlErrors';
import { IndicatorForecastEditForm } from './IndicatorForecastEditForm';

export default function IndicatorForecastEditPage({ match, history }) {
  const indicatorForecastStore = useContextStore(INDICATOR_FORECAST_STORE);
  const backLink = removeLastPathItem(match.url);

  const { indicatorForecastId } = match.params;
  const editor = useOtherEditor('indicatorForecast', indicatorForecastId);

  const indicatorForecastQuery = useIndicatorForecast(
    indicatorForecastId,
    true
  );
  const indicatorForecast = indicatorForecastQuery.state.data;

  return (
    <>
      <IndicatorForecastBreadCrumbs />
      <h2>{IndicatorForecast.title.edit}</h2>
      {queryLoaderWithExistenceCheck(indicatorForecastQuery) || (
        <Formik
          initialValues={IndicatorForecast.toForm(indicatorForecast)}
          onSubmit={(values, actions) => {
            const indicatorForecast = IndicatorForecast.fromForm(values);
            indicatorForecastStore
              .updateIndicatorForecast(indicatorForecast)
              .then((result) => {
                actions.setStatus(FORM_STATUS_SUBMITTED);
                setTimeout(() => {
                  history.goBack(IndicatorForecast.getUrl(result.data.result));
                }, 0);
              })
              .catch((error) => {
                showGraphqlErrorToast(error, mutationErrors.updateObject);
              })
              .then(() => {
                actions.setSubmitting(false);
              });
          }}
          render={(formikProps) => (
            <IndicatorForecastEditForm
              {...formikProps}
              onBackClick={() => history.goBack(backLink)}
              editor={editor}
            />
          )}
        />
      )}
    </>
  );
}
