import { useGraphqlWatchQueryVars } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { QUERY_GET_PURCHASES } from '../../graphql/queries/purchases';
import { Purchase } from './Purchase';

const purchasesMapper = ({ page, onePage }) => (purchase, index) => ({
  ...purchase,
  order: page * onePage + index + 1,
  grbs_name: Purchase.getGrbsName(purchase),
  customer_name_display: Purchase.getCustomerNameDisplay(purchase)
});

const purchasesQueryOptions = {
  query: QUERY_GET_PURCHASES,
  mapData: (result) => ({
    data: result.purchases.list.map(purchasesMapper(result.purchases.header)),
    header: result.purchases.header
  }),
  skipQuery: skipIfNull(null)
};

export function usePurchases(filter, pagination = {}, sorting) {
  return useGraphqlWatchQueryVars({
    queryOptions: purchasesQueryOptions,
    variableCreator: [
      () => ({
        input: {
          filter,
          pagination,
          sorting
        }
      }),
      [filter, pagination, sorting]
    ]
  });
}
