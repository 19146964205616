import { useGraphqlQueryVars } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import {
  QUERY_GET_EXPENSES_DIRECTIONS,
  QUERY_GET_EXPENSES_DIRECTIONS_FOR_PROJECT,
  QUERY_GET_EXPENSES_DIRECTIONS_RICH_LIST,
  QUERY_GET_RP_INDICATORS
} from '../../graphql/queries/expensesDirections';
import {
  useRetriableGraphql,
  useRetriableGraphqlVars
} from '../../common/useRetriableGraphqlVars';
import { compareSimple, makeComparator } from '../../utils/sorting';

const expensesDirectionsOptions = {
  query: QUERY_GET_EXPENSES_DIRECTIONS,
  mapData: (result) => result.expensesDirections.list,
  skipQuery: skipIfNull(null)
};

const expensesDirectionsRichList = {
  query: QUERY_GET_EXPENSES_DIRECTIONS_RICH_LIST,
  mapData: (result) => {
    const eds = result.expensesDirections.list;
    return eds.slice().sort(
      makeComparator(
        compareSimple,
        (ed) => +ed.federal_project.project_code,
        (ed) => ed.code
      )
    );
  },
  skipQuery: skipIfNull(null)
};

const expensesDirectionsForProjectOptions = {
  query: QUERY_GET_EXPENSES_DIRECTIONS_FOR_PROJECT,
  mapData: (result) => result.expensesDirections.list,
  skipQuery: skipIfNull(null)
};

const RPIndicatorGoalsOptions = {
  query: QUERY_GET_RP_INDICATORS,
  mapData: (result) => result.indicators,
  skipQuery: skipIfNull(null)
};

export function useRetriableExpensesDirections(filter) {
  return useRetriableGraphqlVars({
    queryOptions: expensesDirectionsOptions,
    variableCreator: [
      () => {
        if (!filter)
          return {
            input: {
              filter: {
                exists_in_mbt_by_subjects: true,
                exists_in_pof_links: true
              }
            }
          };
        return {
          input: {
            filter
          }
        };
      },
      [filter]
    ]
  });
}

export function useRetriableExpensesDirectionsFilter(filter) {
  return useRetriableGraphqlVars({
    queryOptions: expensesDirectionsOptions,
    variableCreator: [
      () => {
        if (!filter) return null;
        return {
          input: {
            filter
          }
        };
      },
      [filter]
    ]
  });
}

export function useRetriableExpensesDirectionsRich(filter) {
  return useRetriableGraphql({
    queryOptions: expensesDirectionsRichList,
    variables: filter
      ? {
          input: {
            filter
          }
        }
      : null
  });
}

export function useExpensesDirectionsForProject(
  regionalProjectId,
  implementation_years,
  delivery_years
) {
  return useGraphqlQueryVars({
    queryOptions: expensesDirectionsForProjectOptions,
    variableCreator: [
      () =>
        regionalProjectId
          ? {
              regionalProjectId,
              implementation_years: implementation_years
                ? [+implementation_years]
                : undefined,
              delivery_years: delivery_years ? [+delivery_years] : undefined
            }
          : null,
      [regionalProjectId, implementation_years, delivery_years]
    ]
  });
}

export function useRPIndicatorGoals(regionId, year) {
  return useGraphqlQueryVars({
    queryOptions: RPIndicatorGoalsOptions,
    variableCreator: [
      () =>
        regionId && year
          ? {
              regionId,
              year
            }
          : null,
      [regionId, year]
    ]
  });
}
