import React from 'react';
import format from 'date-fns/format';
import { ru } from 'date-fns/locale';
import { isNil } from 'lodash-es';
import TableCell from '@material-ui/core/TableCell';
import { parseApiDate, removeTimezoneFromDate } from '../utils/data/date';
import { formatValueUnit } from '../utils/formatNumber';
import { unitRuble } from '../utils/constants';
import { DATE_FORMAT_RUSSIAN } from '../utils/date';
import s from './Table/Table.module.scss';

export const CellSignals = ({ signals }) => {
  if (!signals) return false;
  return (
    <>
      {signals.map((signal, iSignal) => (
        <p key={iSignal} className={s.SignalText}>
          {signal.text}
        </p>
      ))}
    </>
  );
};

export const CellWithSignals = ({
  showSignals = true,
  signals,
  children,
  ...props
}) => (
  <TableCell {...props}>
    {children}
    {showSignals && <CellSignals signals={signals} />}
  </TableCell>
);

export const PriceCell = ({ value, unit = unitRuble, ...props }) => {
  const showSignals = !isNil(value);
  return (
    <CellWithSignals showSignals={showSignals} {...props}>
      <p>{formatValueUnit(value, unit)}</p>
    </CellWithSignals>
  );
};

export const DateCell = ({
  value,
  format: formatText = DATE_FORMAT_RUSSIAN,
  locale = ru,
  removeTimezone = true,
  ...props
}) => {
  let d = parseApiDate(value);
  if (removeTimezone) {
    d = removeTimezoneFromDate(d);
  }
  return (
    <CellWithSignals {...props}>
      <p>{d && format(d, formatText, { locale })}</p>
    </CellWithSignals>
  );
};

export const DateTimeCell = ({ value, ...props }) => {
  const d = parseApiDate(value);
  return (
    <CellWithSignals {...props}>
      {d && format(d, 'dd.MM.yyyy в HH:mm:ss', { locale: ru })}
    </CellWithSignals>
  );
};

export const LinkCell = ({ value, ...props }) => {
  return (
    <CellWithSignals {...props}>
      <a href={value}>{value}</a>
    </CellWithSignals>
  );
};
