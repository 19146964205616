import gql from 'graphql-tag';

export const FRAGMENT_EVENT_INFRALISTS = gql`
  fragment eventInfralist on InfralistType {
    id
    code
    created_at
    description
    subsidiary_entity {
      id
      name
      name_full
      base_organization {
        id
        name_full
        inn
        kpp
      }
      base_organization_name
    }
    infralist_status_id
    main_data_area
    predefined_id
    subsidiary_entity_id
    type
    updated_at
    is_deletable
  }
`;

export const QUERY_GET_EVENT_INFRALISTS = gql`
  query eventInfralists($filter: InfralistFilterInput!) {
    infralists(filter: $filter) {
      ...eventInfralist
    }
  }
  ${FRAGMENT_EVENT_INFRALISTS}
`;

export const QUERY_GET_EVENT_INFRALISTS_STATUS = gql`
  query eventInfralists($filter: InfralistFilterInput!) {
    infralists(filter: $filter) {
      id
      infralist_status_id
    }
  }
`;
