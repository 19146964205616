import { Button } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import Loader from './Loader/Loader';

export const ProgressButton = ({
  isLoading,
  children,
  disabled,
  ...restProps
}) => {
  return (
    <Button {...restProps} disabled={isLoading || disabled}>
      {children}
      {isLoading && (
        <Loader
          style={{
            color: 'inherit',
            marginLeft: 10
          }}
          size={14}
          centered={true}
        />
      )}
    </Button>
  );
};

ProgressButton.propTypes = {
  isLoading: PropTypes.bool
};
