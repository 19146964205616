import React from 'react';
import { useDocumentsField } from '../DocumentsEditField';
import { EventPhotos } from './EventPhotos';

export function EventPhotosField({
  name,
  canEditStatus,
  canUploadDocuments,
  eventStatus,
  // entities,
  event,
  regionalEvent
}) {
  const { documents, ...editProps } = useDocumentsField({ name });
  return (
    <EventPhotos
      event={event}
      regionalEvent={regionalEvent}
      documents={documents}
      editProps={{
        ...editProps,
        canEditStatus,
        canUploadDocuments,
        eventStatus
      }}
    />
  );
}
