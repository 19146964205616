import React from 'react';
import {
  INFRALIST_STATUSES,
  infralistStatusService
} from '../../../../store/infralist/infralistStatusService';
import { infralistCheckStatusService } from '../../../../store/infralist/statuses/infralistCheckStatusService';
import { useInfralistStatusUserAccesses } from '../../../../store/infralist/useInfralistUserAccesses';
import { EMDASH } from '../../../../utils/constants';
import { reformatDatetime } from '../../../../utils/date';
import { formatNumber } from '../../../../utils/formatNumber';
import { CommentInfoLine } from '../components/CommentInfoLine/CommentInfoLine';

export const DirectionInfo = ({ data, infralistStatus }) => {
  const { showRVPOColumns, showFPOColumns } = useInfralistStatusUserAccesses(
    infralistStatus
  );
  return (
    <>
      <CommentInfoLine label={'Наименование направления'} title={data?.name} />

      <CommentInfoLine
        label={'Стоимость, руб.'}
        title={formatNumber(data?.direction_sum)}
      />

      {/* Формирование и подписание */}
      {showRVPOColumns && (
        <CommentInfoLine
          label={'Статус'}
          title={infralistStatusService.getColoredName(data?.status?.name)}
        />
      )}
      {/* Проверка, согласование */}
      {showFPOColumns && (
        <CommentInfoLine
          label={'Результат проверки'}
          title={
            <>
              {/* По умолчанию показываем На проверке, если инфралист на проверке */}
              {infralistStatus === INFRALIST_STATUSES.ON_CHECKING &&
              !data?.check_status?.name
                ? infralistStatusService.getColoredName(
                    INFRALIST_STATUSES.ON_CHECKING
                  )
                : infralistCheckStatusService.getColoredName(
                    data?.check_status?.name ?? EMDASH
                  )}
            </>
          }
        />
      )}
      {showFPOColumns && (
        <CommentInfoLine
          label={'ФИО проверяющего'}
          title={
            data?.checked_users?.map((user) => user.name).join(', ') ?? EMDASH
          }
        />
      )}
      {showFPOColumns && (
        <CommentInfoLine
          label={'Статус согласования'}
          title={infralistStatusService.getColoredName(
            data?.approvement_status?.name,
            [
              INFRALIST_STATUSES.ON_APPROVAL,
              INFRALIST_STATUSES.APPROVED,
              INFRALIST_STATUSES.APPROVED_WITH_COMMENTS,
              INFRALIST_STATUSES.NOT_APPROVED
            ]
          )}
        />
      )}

      <CommentInfoLine
        label={'Дата изменения статуса'}
        title={reformatDatetime(data?.status_change_time)}
      />
    </>
  );
};
