import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useEffect, useRef, useState } from 'react';
import { useStore } from '@proscom/prostore-react';
import { SUBSCRIPTION_LOCK_SECTION_OR_EQUIPMENT } from '../../../graphql/subscriptions/equipment';
import { useDialog } from '../../../utils/hooks/useDialog';
import { useEffectWithDeepCompare } from '../../../utils/hooks/useEffectWithDeepCompare';
import { STORE_AUTH } from '../../names';
import { useLockActions } from './useLockActions';

export const useSubscribeEquipmentList = (allEquipments) => {
  const [blockList, setBlockList] = useState([]);
  const blockedListRef = useRef([]);
  const client = useContextApolloClient();
  const [auth] = useStore(STORE_AUTH);
  const userId = auth?.authData?.user?.id;
  const { lockEditing, unlockEditing, unlockMassEditing } = useLockActions();
  const {
    isOpen: isRefreshPagePopupVisible,
    open: openRefreshPagePopup,
    close: closeRefreshPagePopup
  } = useDialog();

  const addNew = (value) => {
    setBlockList((prev) => {
      const index = prev.findIndex(
        (item) => item.object_id === value.object_id
      );
      if (index < 0) {
        blockedListRef.current = prev.concat([value]);
        return prev.concat([value]);
      } else {
        const copy = [...prev];
        copy[index].user_id = value.user_id;
        blockedListRef.current = copy;
        return copy;
      }
    });
  };

  const checkIsBlocked = (objectId) => {
    const equipment = blockList?.find((item) => item.object_id === objectId);
    if (!equipment) {
      return false;
    } else {
      return equipment?.user_id !== '' && equipment?.user_id !== userId;
    }
  };

  const isEquipmentEditing = () => {
    if (!blockList || blockList.length === 0) {
      return false;
    }
    return blockList.some((item) => item.user_id !== '');
  };

  // форматируем и сортируем список оборудования для предотвращения лишних срабатываний useEffect
  const equipmentIds =
    allEquipments?.map((equipment) => equipment.id).sort() ?? [];
  const listenUpdateEquipment = (subscriptionValue) => {
    if (subscriptionValue?.user_id === '' && blockList.length > 0) {
      const prevEquipment = blockList.find(
        (item) => item.object_id === subscriptionValue?.object_id
      );
      if (
        prevEquipment &&
        prevEquipment.user_id !== '' &&
        prevEquipment.user_id !== userId
      ) {
        openRefreshPagePopup();
      }
    }
  };

  useEffectWithDeepCompare(() => {
    let subscription;
    if (equipmentIds && equipmentIds.length > 0) {
      subscription = client
        .subscribe({
          query: SUBSCRIPTION_LOCK_SECTION_OR_EQUIPMENT,
          variables: {
            input: equipmentIds.map((item) => ({
              object_id: item,
              object_type: 'Equipment'
            }))
          }
        })
        .subscribe((value) => {
          listenUpdateEquipment(value?.data?.onEditLock);
          addNew(value?.data?.onEditLock);
        });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [client, equipmentIds, blockList]);

  // сброс блокировок на unmount компонента
  useEffect(() => {
    return () => {
      if (blockedListRef.current && blockedListRef.current.length > 0) {
        unlockMassEditing(
          blockedListRef.current.map((item) => ({
            object_id: item.object_id,
            object_type: item.object_type
          }))
        );
      }
    };
  }, [unlockMassEditing]);

  return {
    checkIsBlocked,
    lockEditing,
    unlockEditing,
    isEquipmentEditing: isEquipmentEditing(),
    isRefreshPagePopupVisible,
    closeRefreshPagePopup
  };
};
