import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import classNames from 'classnames';
import SubTable from '../../../../common/SubTable/SubTable';
import { getName } from '../../../../utils/data/label';
import { useRPIndicatorGoals } from '../../../../store/expensesDirection/useExpensesDirections';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import { formatNumber } from '../../../../utils/formatNumber';
import s from '../RpImplementationForecastPage.module.scss';

export const IndicatorForecasts = ({ indicatorForecasts, regionId }) => {
  const indicatorsQuery = useRPIndicatorGoals(regionId, 2020);
  const indicators = indicatorsQuery.state.data;

  return (
    <>
      {queryLoaderWithExistenceCheck(indicatorsQuery) || (
        <SubTable title="Информация по показателям" cols={7}>
          <TableRow>
            <TableCell className={classNames(s.tableCell, s.tableCellMedium)}>
              Региональный проект
            </TableCell>
            <TableCell className={classNames(s.tableCell, s.tableCellMedium)}>
              Показатели
            </TableCell>
            <TableCell className={classNames(s.tableCell, s.tableCellSmall)}>
              Ед.изм.
            </TableCell>
            <TableCell className={classNames(s.tableCell, s.tableCellSmall)}>
              Плановое значение показателя на 2020 год
            </TableCell>
            <TableCell className={classNames(s.tableCell, s.tableCellSmall)}>
              Прогнозное значение показателя на 2020 год
            </TableCell>
            <TableCell className={classNames(s.tableCell, s.tableCellLarge)}>
              Обоснование изменений
            </TableCell>
            <TableCell className={classNames(s.tableCell, s.tableCellLarge)}>
              Примечание
            </TableCell>
          </TableRow>
          {indicatorForecasts?.map((indicatorForecast, iIndicatorForecast) => {
            const indicator = indicators.find((item) => {
              const value = item.values?.[0];
              const federalProject =
                value?.plan_data?.regional_project?.federal_project;

              return (
                indicatorForecast.federal_project_id === federalProject?.id &&
                indicatorForecast.indicator_id === value?.indicator_id
              );
            });

            return (
              <TableRow key={iIndicatorForecast}>
                <TableCell
                  className={classNames(s.tableCell, s.tableCellMedium)}
                >
                  {getName(indicatorForecast.federal_project)}
                </TableCell>
                <TableCell
                  className={classNames(s.tableCell, s.tableCellMedium)}
                >
                  {getName(indicator)}
                </TableCell>
                <TableCell
                  className={classNames(s.tableCell, s.tableCellSmall)}
                >
                  {indicator?.unit?.name}
                </TableCell>
                <TableCell
                  className={classNames(s.tableCell, s.tableCellSmall)}
                >
                  {formatNumber(indicator?.values?.[0]?.value_number, 10, true)}
                </TableCell>
                <TableCell
                  className={classNames(s.tableCell, s.tableCellSmall)}
                >
                  {indicatorForecast.forecast_value_entered &&
                    indicatorForecast.forecast_value}
                </TableCell>
                <TableCell
                  className={classNames(s.tableCell, s.tableCellLarge)}
                >
                  {indicatorForecast.changes_justification}
                </TableCell>
                <TableCell
                  className={classNames(s.tableCell, s.tableCellLarge)}
                >
                  {indicatorForecast.annotation}
                </TableCell>
              </TableRow>
            );
          })}
        </SubTable>
      )}
    </>
  );
};
