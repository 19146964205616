import gql from 'graphql-tag';

export const QUERY_GET_PARENTAL_ASSISTANCE_GUIDELINES_REGIONAL = gql`
  query getParentalAssistanceGuidelinesRegional(
    $filter: ParentalAssistanceGuidelinesRegionalFilter
  ) {
    parentalAssistanceGuidelinesRegional(filter: $filter) {
      id
    }
  }
`;
