import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useContextStore } from '@proscom/prostore-react';
import Button from '@material-ui/core/Button';
import { stringify } from 'query-string';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { useRegionalProject } from '../../../store/regionalProject/useRegionalProject';
import SubTable from '../../../common/SubTable/SubTable';
import { getMonth } from '../../../utils/data/date';
import { DateCell } from '../../../common/TableCells';
import {
  queryLoaderInline,
  queryLoaderWithExistenceCheck
} from '../../../common/QueryLoader';
import {
  compareFinancial,
  formatCurrency,
  sumNumbersNullish
} from '../../../utils/number';
import { STORE_POF } from '../../../store/names';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../graphql/graphqlErrors';
import { useCurrentUser } from '../../../store/useCurrentUser';
import { getName } from '../../../utils/data/label';
import { useGroupedPurchaseScheduleSum } from '../../../store/purchase/useGroupedPurchaseScheduleSum';
import { PageActionsBar } from '../../../common/PageActionsBar/PageActionsBar';
import { Signal } from '../../../common/Inputs/Signals';
import { ActionButton } from '../../../common/Form/ActionButton';
import { DeleteButtonWithConfirmation } from '../../../common/Form/DeleteButtonWithConfirmation';
import { EditButton } from '../../../common/Form/EditButton/EditButton';
import { apiUrl } from '../../../config';
import { createPreservedUrl } from '../../../utils/links';
import { PofAuth } from '../../../store/pof/PofAuth';
import {
  usePofFinances,
  usePofFinancesTotal,
  usePofRegionalDocument
} from '../../../store/pof/pofQueries';
import { useDeleting } from '../../../utils/data/form';
import { Pof } from '../../../store/pof/Pof';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import { PofBreadCrumbs } from './PofBreadCrumbs';
import { PofStatus } from './PofStatus';
import { PofQuarters } from './PofQuarters';

function ExportButtons({ url }) {
  const pdf = createPreservedUrl(url, { type: 'pdf' });
  const docx = createPreservedUrl(url, { type: 'docx' });
  const xlsx = createPreservedUrl(url, { type: 'xlsx' });
  return (
    <>
      <Button href={pdf}>PDF</Button>
      <Button href={docx}>DOCX</Button>
      <Button href={xlsx}>XLSX</Button>
    </>
  );
}

const PofPage = ({ match, history }) => {
  const { projectId, pofId } = match.params;
  const pofStore = useContextStore(STORE_POF);

  const projectQuery = useRegionalProject(projectId);
  const project = projectQuery.state.data;

  const pofRegionalDocumentQuery = usePofRegionalDocument(pofId);
  const document = pofRegionalDocumentQuery.state.data;

  const financesQuery = usePofFinances(document);
  const finances = financesQuery.state.data;

  const contractSumSfbQuery = financesQuery;
  const contractSumSfb = finances?.purchases;

  const groupedPurchaseScheduleSumQuery = useGroupedPurchaseScheduleSum(
    document,
    document && document.region_id,
    'month'
  );
  const groupedPurchaseScheduleSum = groupedPurchaseScheduleSumQuery.state.data;

  const user = useCurrentUser();
  const canSeeTotalFinances = PofAuth.canSeeTotalFinances(document, user);
  const financesTotalQuery = usePofFinancesTotal(
    canSeeTotalFinances && document
  );
  const financesTotal = financesTotalQuery.state.data;

  const total =
    document?.values &&
    sumNumbersNullish(...document.values.map((v) => v.value));

  const [redirectAfterDeletionUrl] = match.url.split('/pofs');
  const goBackLink = redirectAfterDeletionUrl
    ? redirectAfterDeletionUrl +
      '?' +
      stringify({
        tab: 'pofs',
        month: document?.month,
        year: document?.year
      })
    : '/pofs';

  const [isDeleting, deletePof] = useDeleting(
    () => pofStore.deletePof(pofId),
    () => history.push(goBackLink)
  );

  const canEditForm = PofAuth.canEdit(document, user);
  const canDeleteFrom = PofAuth.canDelete(document, user);
  const canSendForApproval = PofAuth.canSendForApproval(document, user);
  const canApprove =
    PofAuth.canApprove(document, user) &&
    document.status === Pof.Status.APPROVAL;

  const isDifferenceMbtTotal =
    compareFinancial(finances?.mbt?.plan, total) !== 0;

  return (
    <>
      <HeadTitle type="ПОФ" entity={getName(document?.expenses_direction)} />
      <PofBreadCrumbs projectId={projectId} project={project} />
      <PageTitle title={getName(document?.expenses_direction)} withBackBtn />
      {queryLoaderWithExistenceCheck(pofRegionalDocumentQuery) || (
        <>
          <PageActionsBar
            editButton={
              canEditForm && <EditButton editLink={`${match.url}/edit`} />
            }
            deleteButton={
              canDeleteFrom && (
                <DeleteButtonWithConfirmation
                  onDelete={deletePof}
                  isDeleting={isDeleting}
                  entityName={'документ ПОФ'}
                />
              )
            }
            actions={
              <>
                <ExportButtons
                  url={apiUrl + '/form/pofRegionalDocument/export?id=' + pofId}
                />
                {canSendForApproval && (
                  <ActionButton
                    onClick={() =>
                      pofStore.sendForApproval(document.id).catch((error) => {
                        showGraphqlErrorToast(
                          error,
                          mutationErrors.updateObject
                        );
                      })
                    }
                    title="Отправить на утверждение"
                  />
                )}
                {canApprove && (
                  <ActionButton
                    onClick={() =>
                      pofStore.approve(document.id).catch((error) => {
                        showGraphqlErrorToast(
                          error,
                          mutationErrors.updateObject
                        );
                      })
                    }
                    title="Утвердить"
                  />
                )}
              </>
            }
          />
          <div>
            <SubTable title="Общие сведения">
              <TableRow>
                <TableCell>Субъект Российской Федерации</TableCell>
                <TableCell>{getName(document.region)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Наименование НР</TableCell>
                <TableCell>{getName(document.expenses_direction)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Год заполнения</TableCell>
                <TableCell>{document.year}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Месяц заполнения</TableCell>
                <TableCell>{getMonth(document.month - 1)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Региональный проект</TableCell>
                <TableCell>{getName(document.federal_project)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Результат регионального проекта</TableCell>
                <TableCell>
                  {getName(document.expenses_direction?.result)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Статус</TableCell>
                <TableCell>
                  <PofStatus status={document.status} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Комментарий ФПО</TableCell>
                <TableCell>{document.comment_fpo}</TableCell>
              </TableRow>
            </SubTable>
            <SubTable>
              <TableRow>
                <TableCell>
                  Общий объем средств МБТ из федерального бюджета
                </TableCell>
                <TableCell>
                  {queryLoaderInline(financesQuery) ||
                    formatCurrency(finances?.mbt?.plan)}
                </TableCell>
              </TableRow>
            </SubTable>
            <SubTable>
              <TableRow>
                <TableCell>Сумма за все кварталы</TableCell>
                <TableCell>
                  {formatCurrency(total)}
                  {isDifferenceMbtTotal && (
                    <Signal warning>
                      Имеется отклонение общего объема средств МБТ из
                      федерального бюджета от суммы за все кварталы
                    </Signal>
                  )}
                </TableCell>
              </TableRow>
            </SubTable>
            <PofQuarters
              values={document.values}
              groupedPurchaseScheduleSum={groupedPurchaseScheduleSum}
            />
            <SubTable>
              <TableRow>
                <TableCell>
                  Израсходовано средств бюджета субъекта РФ (кассовый расход)
                  (федеральный бюджет)
                </TableCell>
                <TableCell>
                  {queryLoaderInline(financesQuery) ||
                    formatCurrency(finances?.mbt?.otfg_kr_total)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Сумма заключенных государственных контрактов, договоров, в том
                  числе муниципальных договоров и контрактов (федеральный
                  бюджет)
                </TableCell>
                <TableCell>
                  {queryLoaderInline(contractSumSfbQuery) ||
                    formatCurrency(contractSumSfb?.contract_summ_sfb)}
                </TableCell>
              </TableRow>
              {canSeeTotalFinances && (
                <>
                  <TableRow>
                    <TableCell>
                      Израсходовано средств бюджета субъекта РФ (кассовый
                      расход) (федеральный бюджет + бюджет субъекта РФ)
                    </TableCell>
                    <TableCell>
                      {queryLoaderInline(financesTotalQuery) ||
                        formatCurrency(financesTotal?.total?.cash_expenses)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Сумма заключенных государственных контрактов, договоров, в
                      том числе муниципальных договоров и контрактов
                      (федеральный бюджет + бюджет субъекта РФ)
                    </TableCell>
                    <TableCell>
                      {queryLoaderInline(contractSumSfbQuery) ||
                        formatCurrency(
                          contractSumSfb?.contract_summ_sfb +
                            contractSumSfb?.contract_summ_skbsrf
                        )}
                    </TableCell>
                  </TableRow>
                </>
              )}
            </SubTable>
            <SubTable>
              <TableRow>
                <TableCell>Автор</TableCell>
                <TableCell>{document.author?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Дата создания</TableCell>
                <DateCell value={document.created_at} />
              </TableRow>
              <TableRow>
                <TableCell>Дата редактирования</TableCell>
                <DateCell value={document.updated_at} />
              </TableRow>
              <TableRow>
                <TableCell>Версия</TableCell>
                <TableCell>{document.version}</TableCell>
              </TableRow>
            </SubTable>
          </div>
        </>
      )}
    </>
  );
};

export default PofPage;
