import 'symbol-observable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import './fonts.scss';
import './index.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  ApolloClientsContext,
  ApolloClientsManager
} from '@proscom/prostore-apollo-react';
import { ProstoreContext } from '@proscom/prostore-react';
import {
  ADDITIONAL_DATA_STORE,
  CONTEST_DOCS_FOR_SS_MONITORING_STORE,
  CONTEST_DOCS_MONITORING_STORE,
  INDICATOR_FORECAST_STORE,
  INDICATOR_STORE,
  ROLE_STORE,
  RP_IMPLEMENTATION_FORECAST_STORE,
  STORE_AUTH,
  STORE_EDITING,
  STORE_FILE_TOKEN,
  STORE_FORM,
  STORE_INFRALIST,
  STORE_LOGIN,
  STORE_MENTORING_COVERAGE,
  STORE_NOTIFICATION_COUNT_STORE,
  STORE_NOTIFICATION_STORE,
  STORE_ORGANIZATIONS,
  STORE_PARENTAL_ASSISTANCE_GUIDELINE,
  STORE_POF,
  STORE_PURCHASE,
  STORE_ROADMAP_EVENT,
  SUBSIDIARY_ENTITY_STORE,
  SUBSIDIARY_MONITORING_STORE,
  USER_STORE
} from './store/names';
import App from './App';
import { LocalStorageStore } from './store/LocalStorageStore';
import { AuthStore } from './store/AuthStore';
import { LoginStore } from './store/LoginStore';
import { apiGraphqlUrl, wsGraphqlUrl } from './config';
import {
  createBrowserClientWithAuth,
  createSimpleBrowserClient
} from './graphql/client';
import { PurchaseStore } from './store/purchase/PurchaseStore';
import { OrganizationsStore } from './store/organization/OrganizationsStore';
import { createAppHistory } from './appHistory';
import { RoadmapEventStore } from './store/roadmap/RoadmapEventStore';
import { PofStore } from './store/pof/PofStore';
import { SubsidiaryEntityStore } from './store/subsidiaryEntity/SubsidiaryEntityStore';
import { IndicatorStore } from './store/indicator/IndicatorStore';
import { UserStore } from './store/role/UserStore';
import { RoleStore } from './store/role/RoleStore';
import { EditingStore } from './store/EditingStore';
import { FormStore } from './store/form/FormStore';
import { AdditionalDataStore } from './store/additionalData/AdditionalDataStore';
import { RpImplementationForecastStore } from './store/rpImplementationForecast/RpImplementationForecastStore';
import { IndicatorForecastStore } from './store/indicatorForecasts/IndicatorForecastStore';
import { FileTokenStore } from './store/FileTokenStore';
import { NotificationStore } from './store/notification/NotificationStore';
import { NotificationCountStore } from './store/notification/NotificationCountStore';
import { ParentalAssistanceGuidelineStore } from './store/parentalAssistanceGuideline/ParentalAssistanceGuidelineStore';
import { InfralistStore } from './store/infralists/InfralistStore';
import { MentoringCoverageStore } from './store/mentoringCoverage/MentoringCoverageStore';
import { YandexMetrika } from './YandexMetrika';
import { ContestDocsMonitoringStore } from './store/contestDocsMonitoring/ContestDocsMonitoringStore';
import { SubsidiaryMonitoringStore } from './store/subsidiaryMonitoring/SubsidiaryMonitoringStore';
import { ContestDocsForSSMonitoringStore } from './store/contestDocsForSSMonitoring/ContestDocsForSSMonitoringStore';

const localStorageStore = new LocalStorageStore(localStorage);
localStorageStore.registerListener();

const apolloClient = createSimpleBrowserClient({ uri: apiGraphqlUrl });

const authStore = new AuthStore({
  localStorageStore,
  client: apolloClient
});
authStore.subscribe();

const loginStore = new LoginStore();

const history = createAppHistory();

const clientWithAuth = createBrowserClientWithAuth({
  uri: apiGraphqlUrl,
  authStore,
  wsUri: wsGraphqlUrl
});

const editingStore = new EditingStore({ client: clientWithAuth });
const purchaseStore = new PurchaseStore({ client: clientWithAuth });
const roadmapEventStore = new RoadmapEventStore({ client: clientWithAuth });
const pofStore = new PofStore({ client: clientWithAuth });
const organizationsStore = new OrganizationsStore({ client: clientWithAuth });
const subsidiaryEntityStore = new SubsidiaryEntityStore({
  client: clientWithAuth
});
const indicatorStore = new IndicatorStore({ client: clientWithAuth });
const rpImplementationForecastStore = new RpImplementationForecastStore({
  client: clientWithAuth
});
const indicatorForecastStore = new IndicatorForecastStore({
  client: clientWithAuth
});
const userStore = new UserStore({ client: clientWithAuth });
const roleStore = new RoleStore({ client: clientWithAuth });
const formStore = new FormStore({ client: clientWithAuth });
const parentalAssistanceGuidelineStore = new ParentalAssistanceGuidelineStore({
  client: clientWithAuth
});
const notificationStore = new NotificationStore({ client: clientWithAuth });
const notificationCountStore = new NotificationCountStore({
  client: clientWithAuth,
  authStore
});
const infralistStore = new InfralistStore({ client: clientWithAuth });
const mentoringCoverageStore = new MentoringCoverageStore({
  client: clientWithAuth
});
notificationCountStore.subscribe();

const contestDocsMonitoringStore = new ContestDocsMonitoringStore({
  client: clientWithAuth
});
const contestDocsForSSMonitoringStore = new ContestDocsForSSMonitoringStore({
  client: clientWithAuth
});

const subsidiaryMonitoringStore = new SubsidiaryMonitoringStore({
  client: clientWithAuth
});

const additionalDataStore = new AdditionalDataStore({ client: clientWithAuth });
const fileTokenStore = new FileTokenStore({ client: clientWithAuth });

const stores = {
  [STORE_AUTH]: authStore,
  [STORE_EDITING]: editingStore,
  [STORE_PURCHASE]: purchaseStore,
  [STORE_ROADMAP_EVENT]: roadmapEventStore,
  [STORE_POF]: pofStore,
  [STORE_ORGANIZATIONS]: organizationsStore,
  [STORE_FORM]: formStore,
  [SUBSIDIARY_ENTITY_STORE]: subsidiaryEntityStore,
  [INDICATOR_STORE]: indicatorStore,
  [ADDITIONAL_DATA_STORE]: additionalDataStore,
  [RP_IMPLEMENTATION_FORECAST_STORE]: rpImplementationForecastStore,
  [INDICATOR_FORECAST_STORE]: indicatorForecastStore,
  [USER_STORE]: userStore,
  [ROLE_STORE]: roleStore,
  [STORE_LOGIN]: loginStore,
  [STORE_FILE_TOKEN]: fileTokenStore,
  [STORE_NOTIFICATION_STORE]: notificationStore,
  [STORE_NOTIFICATION_COUNT_STORE]: notificationCountStore,
  [STORE_PARENTAL_ASSISTANCE_GUIDELINE]: parentalAssistanceGuidelineStore,
  [STORE_INFRALIST]: infralistStore,
  [STORE_MENTORING_COVERAGE]: mentoringCoverageStore,
  [CONTEST_DOCS_MONITORING_STORE]: contestDocsMonitoringStore,
  [CONTEST_DOCS_FOR_SS_MONITORING_STORE]: contestDocsForSSMonitoringStore,
  [SUBSIDIARY_MONITORING_STORE]: subsidiaryMonitoringStore
};

const apolloContext = new ApolloClientsManager({
  default: clientWithAuth,
  simple: apolloClient
});

ReactDOM.render(
  <Router history={history}>
    <ProstoreContext.Provider value={stores}>
      <ApolloClientsContext.Provider value={apolloContext}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <YandexMetrika />
          <CssBaseline />
          <App />
        </MuiPickersUtilsProvider>
      </ApolloClientsContext.Provider>
    </ProstoreContext.Provider>
  </Router>,
  document.getElementById('root')
);
