import React, { useMemo } from 'react';
import { useContextStore } from '@proscom/prostore-react';
import AutocompleteAsyncInput from '../../../../common/Inputs/Selects/AutocompleteAsyncInput';
import { STORE_ORGANIZATIONS } from '../../../../store/names';

export const OrganizationInput = ({
  field: { value: inputValue, name, onBlur },
  form: { setFieldValue: handleChange },
  errorMessage,
  regionId,
  defaultValue,
  label = 'Выбор из списка'
}) => {
  const organizationsStore = useContextStore(STORE_ORGANIZATIONS);

  const organizationFilters = useMemo(
    () =>
      regionId
        ? {
            region_ids: [regionId]
          }
        : null,
    [regionId]
  );

  return (
    <AutocompleteAsyncInput
      name="base_organization_id"
      defaultValue={defaultValue}
      value={inputValue}
      errorMessage={errorMessage}
      label={label}
      onBlur={onBlur}
      onChange={(item) => {
        handleChange(name, item);
      }}
      getItems={organizationsStore.searchOrganizations}
      mapData={organizationsStore.getSuggestions}
      filterParams={organizationFilters}
      isLoading={!organizationFilters}
      fullWidth
      isAsync={true}
    />
  );
};
