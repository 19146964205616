import React from 'react';
import { NewAutocompleteInput } from '../../../common/InputsNew/NewAutocompleteInput';
import InputGroup from '../../../common/Inputs/InputGroup';
import { ParentalAssistanceGuideline } from '../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import { UniversalDocumentTable } from '../common/FileUpload/DownloadFileInput';

export const FilesRegionalPart = ({ values, touched, errors }) => {
  return (
    <InputGroup title="I. Нормативно - правовое регулирование порядка оказания услуг">
      <UniversalDocumentTable
        name={'list_of_service_regulations'}
        title={
          '1. Перечислите нормативные акты, регламентирующие порядок оказания услуги на территории субъекта Российской Федерации, в том числе акты, устанавливающие размер нормативных затрат (в случае оказания услуги в рамках государственного задания). Представьте копии указанных нормативных актов.'
        }
      />
      <NewAutocompleteInput
        name="new_service_implementation_roadmap_exist"
        label="2. Утверждена ли дорожная карта по внедрению на территории субъекта Российской Федерации новой услуги. Представьте (при наличии) копию утвержденной дорожной карты"
        suggestions={ParentalAssistanceGuideline.yesNoSuggestions}
        hasCopyBtn={false}
        fullWidth
      />
      {values.new_service_implementation_roadmap_exist && (
        <UniversalDocumentTable
          name={'approved_roadmap'}
          title={'Представьте (при наличии) копию утвержденной дорожной карты'}
          errorMessage={touched.approved_roadmap && errors.approved_roadmap}
          canEditDescription={false}
          isMulti={false}
        />
      )}
    </InputGroup>
  );
};
