import React from 'react';
import ErrorPage from '../../common/ErrorBoundary/ErrorPage';
import { EMDASH } from '../../utils/constants';
import { HeadTitle } from '../../common/HeadTitle/HeadTitle';

const title = `Ошибка 404 ${EMDASH} Страница не найдена`;

export default function NotFoundPage() {
  return (
    <>
      <HeadTitle title={title} />
      <ErrorPage
        text="Ошибка 404"
        description={
          'Страница, которую вы ищете, могла быть ' +
          'перемещена из-за изменения ее названия или ' +
          'временно недоступна.'
        }
      />
    </>
  );
}
