import React from 'react';
import { withRouter } from 'react-router';
import Avatar from '@material-ui/core/Avatar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useStore } from '@proscom/prostore-react';
import { padStart } from 'lodash-es';
import { Link } from 'react-router-dom';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Menu from '../Menu/Menu';
import russiaSrc from '../../assets/avatar-template.jpg';
import { STORE_AUTH, STORE_NOTIFICATION_COUNT_STORE } from '../../store/names';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../graphql/graphqlErrors';
import { User } from '../../store/role/User';
import { enableNotificationsIcon } from '../../config';
import s from './Header.module.scss';

const Header = ({ history }) => {
  const [auth, authStore] = useStore(STORE_AUTH);
  const [notifyCount, notifyCountStore] = useStore(
    STORE_NOTIFICATION_COUNT_STORE
  );
  if (!authStore.isLoggedIn()) return false;
  const user = auth.authData.user;

  const regionCodeRaw = user.region && user.region.code_nalog;
  const regionCode = regionCodeRaw && padStart(regionCodeRaw, 2, '0');
  const regionSrc = regionCode ? `/img/regions/${regionCode}.png` : russiaSrc;
  const notificationCount = notifyCount.count;

  return (
    <header className={s.Header}>
      <div className={s.Header__element}>
        <Avatar alt="Герб" src={regionSrc} className={s.Avatar} />
        <div className={s.CabCaption}>
          <p className={s.CabOffice}>
            {user ? User.getRoleAdminPanelName(user) : 'Личный кабинет'}
          </p>
          <p className={s.CabWorker}>{user.name}</p>
        </div>
        <Menu
          iconColor="inherit"
          icon={KeyboardArrowDownIcon}
          options={[
            {
              title: 'Выход',
              onClick: () =>
                authStore
                  .logOut()
                  .catch((e) => showGraphqlErrorToast(e, mutationErrors.logOut))
            }
          ]}
        />
        {/*<SearchField classes={s.SearchField} />*/}
      </div>

      {enableNotificationsIcon && (
        <Link to={'/notifications'} className={s.Header__mail}>
          <NotificationsIcon className={s.mail__logo} />
          <div className={s.mail__count}>{notificationCount}</div>
        </Link>
      )}
    </header>
  );
};

export default withRouter(Header);
