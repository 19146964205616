import React, { useMemo } from 'react';
import { YearSelect } from '../../../../common/YearSelect';
import { User } from '../../../../store/role/User';
import { DatePickerRanger } from '../../../../common/DatePickerRanger/DatePickerRanger';
import {
  dateToBackendString,
  getCurrentYear
} from '../../../../utils/data/date';

export const useEventFilter = (query, changeQuery, roadmap, roadmapYear) => {
  const filter = useMemo(
    () => ({
      plan_term:
        query.planTermStartDate || query.planTermEndDate
          ? {
              start: query.planTermStartDate || undefined,
              end: query.planTermEndDate || undefined
            }
          : undefined,
      roadmap_id: roadmap?.id,
      year: roadmapYear
    }),
    [query.planTermStartDate, query.planTermEndDate, roadmap, roadmapYear]
  );

  const handleChange = (field) => (value) => {
    changeQuery({ [field]: value ? dateToBackendString(value) : '' });
  };

  const filterComponent = (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <YearSelect
        label="Год мероприятий"
        year={filter.year}
        years={roadmap?.roadmap_years.map((x) => x.year)}
        onChange={changeQuery}
      />
      <DatePickerRanger
        sinceTitle="От"
        tillTitle="До"
        label="Плановый срок"
        variant="outlined"
        startDate={query.planTermStartDate}
        endDate={query.planTermEndDate}
        onStartChange={handleChange('planTermStartDate')}
        onEndChange={handleChange('planTermEndDate')}
      />
    </div>
  );

  return [filterComponent, filter];
};
