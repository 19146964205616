import React from 'react';
import { Description } from './Description';
import { List } from './List';

export const ListWithDescription = ({
  list,
  mapData = (item) => item,
  descriptionTitle,
  descriptionText
}) => {
  return (
    <>
      <List list={list} mapData={mapData} />
      <Description
        descriptionTitle={descriptionTitle}
        descriptionText={descriptionText}
      />
    </>
  );
};
