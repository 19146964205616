import Link from '@material-ui/core/Link';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { ComponentWithTooltip } from '../../common/ComponentWithTooltip/ComponentWithTooltip';
import s from './CommentLink.module.scss';

export const CommentLink = ({ href, notRead }) => {
  return (
    <ComponentWithTooltip
      title={'Оставить комментарий'}
      placement={'left'}
      isTooltipExists
    >
      <Link
        component={RouterLink}
        to={href}
        className={s.link}
        onClick={(e) => e.stopPropagation()}
      >
        {notRead && <span className={s.notReadLabel} />}
        <ChatBubbleOutlineIcon className={s.icon} />
      </Link>
    </ComponentWithTooltip>
  );
};

CommentLink.propTypes = {
  href: PropTypes.string
};
