import React, { Fragment, useCallback, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import MaterialLink from '@material-ui/core/Link';
import { useContextStore } from '@proscom/prostore-react';
import SubTable from '../../../common/SubTable/SubTable';
import { useUser } from '../../../store/role/useUsers';
import { queryLoaderWithExistenceCheck } from '../../../common/QueryLoader';
import { usePermissions } from '../../../store/role/usePermissions';
import { DeleteButtonWithConfirmation } from '../../../common/Form/DeleteButtonWithConfirmation';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../graphql/graphqlErrors';
import { PageActionsBar } from '../../../common/PageActionsBar/PageActionsBar';
import { EditButton } from '../../../common/Form/EditButton/EditButton';
import { getName } from '../../../utils/data/label';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { USER_STORE } from '../../../store/names';
import { RolePermissions } from '../../roles/role/RolePermissions';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import { User } from '../../../store/role/User';
import { SubTableHeading } from '../../../common/SubTable/SubTableHeading';
import { UserBreadCrumbs } from './UserBreadCrumbs';

export default function UserPage({ match, history }) {
  const userStore = useContextStore(USER_STORE);
  const { userId } = match.params;

  const userQuery = useUser(userId, true);
  const user = userQuery.state.data;

  const permissionsQuery = usePermissions();
  const permissions = permissionsQuery.state.data;

  const [isDeleting, setDeleting] = useState(false);

  const deleteUser = useCallback(() => {
    setDeleting(true);
    return userStore
      .deleteUser(userId)
      .then((result) => {
        setTimeout(() => {
          history.push('/users');
        }, 0);
      })
      .catch((error) => {
        showGraphqlErrorToast(error, mutationErrors.deleteObject);
      })
      .finally(() => setDeleting(false));
  }, [userId, history, userStore]);

  const userPermissions = User.getUserPermissions(user);

  return (
    <>
      <HeadTitle type="Пользователь" entity={getName(user)} />
      <UserBreadCrumbs />
      {queryLoaderWithExistenceCheck(userQuery) || (
        <>
          <PageTitle title={getName(user)} withBackBtn />
          <PageActionsBar
            editButton={<EditButton editLink={`${match.url}/edit`} />}
            deleteButton={
              <DeleteButtonWithConfirmation
                onDelete={deleteUser}
                isDeleting={isDeleting}
                entityName={'пользователя'}
              />
            }
          />
          <SubTable title="Общие сведения">
            <TableRow>
              <TableCell>Имя пользователя</TableCell>
              <TableCell>{user.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Почта</TableCell>
              <TableCell>{user.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Логин</TableCell>
              <TableCell>{user.login}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Роль</TableCell>
              <TableCell>
                <MaterialLink
                  component={Link}
                  to={`/roles/${user.roles?.[0]?.id}`}
                  color="textPrimary"
                >
                  {user.roles?.[0]?.name}
                </MaterialLink>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Регион</TableCell>
              <TableCell>{user.region?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Организация</TableCell>
              <TableCell>{user.organization?.name_full}</TableCell>
            </TableRow>
          </SubTable>
          <SubTable title="Права пользователя" cols={1}>
            {Object.keys(userPermissions).map((roleName, key) => {
              return (
                <Fragment key={key}>
                  <SubTableHeading cols={2}>
                    {roleName === 'userPermissions'
                      ? 'Собственные права'
                      : roleName}
                  </SubTableHeading>
                  <RolePermissions
                    permissions={userPermissions[roleName]}
                    allPermissions={permissions}
                  />
                </Fragment>
              );
            })}
          </SubTable>
        </>
      )}
    </>
  );
}
