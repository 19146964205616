import React, { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useContextStore } from '@proscom/prostore-react';
import { HeadTitle } from '../../../common/HeadTitle/HeadTitle';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { getName } from '../../../utils/data/label';
import { ParentalAssistanceGuideline } from '../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import SubTable from '../../../common/SubTable/SubTable';
import { EditButton } from '../../../common/Form/EditButton/EditButton';
import { PageActionsBar } from '../../../common/PageActionsBar/PageActionsBar';
import { useParentalAssistanceGuidelineRegional } from '../../../store/parentalAssistanceGuideline/useParentalAssistanceGuidelineRegional';
import { queryLoaderWithExistenceCheck } from '../../../common/QueryLoader';
import { FilesRegionalSection } from '../sections/FilesRegionalSection';
import { ProcedureRegionalSection } from '../sections/ProcedureRegionalSection';
import { ResultSection } from '../sections/ResultSection';
import { OrganizationsData } from '../sections/OrganizationsData';
import { InformationRegionField } from '../common/InformationFields';
import { downloadRestFile } from '../../../utils/AxiosHelpers';
import { DownloadButton } from '../../../common/Form/DownloadButton/DownloadButton';
import { STORE_FILE_TOKEN } from '../../../store/names';

const title = 'Перечень вопросов по апробации методических рекомендаций';

export default function ParentalAssistanceGuidelinesRegionalPage({ match }) {
  const [isDownload, setIsDownload] = useState(false);
  const tokenStore = useContextStore(STORE_FILE_TOKEN);
  const { guidelineId } = match.params;
  const parentalAssistanceGuidelineRegionalQuery = useParentalAssistanceGuidelineRegional(
    guidelineId
  );

  const parentalAssistanceGuidelineRegional =
    parentalAssistanceGuidelineRegionalQuery.state.data;

  const handleDownload = async ({ type }) => {
    setIsDownload(true);
    const accessToken = await tokenStore.createParentalAssistanceGuidelinesRegionalReportOneTimeToken();
    await downloadRestFile({
      filters: {
        region_code_nalog:
          parentalAssistanceGuidelineRegional?.region?.code_nalog,
        extension: type
      },
      accessToken,
      responseUrl: '/reference/parental_assistance_guideline_regional',
      method: 'post'
    });
    setIsDownload(false);
  };

  const canEdit =
    parentalAssistanceGuidelineRegional?.status ===
    ParentalAssistanceGuideline.Status.DRAFT;

  return (
    <>
      <HeadTitle title={title} />
      <PageTitle title={title} withBackBtn />
      <PageActionsBar
        actions={
          parentalAssistanceGuidelineRegional?.region?.code_nalog && (
            <DownloadButton
              files={[
                {
                  name: 'Формат xls',
                  type: 'xls'
                },
                {
                  name: 'Формат pdf',
                  type: 'pdf'
                }
              ]}
              title={'Загрузить'}
              loader={isDownload}
              onClick={handleDownload}
            >
              Загрузить
            </DownloadButton>
          )
        }
        editButton={canEdit && <EditButton editLink={`${match.url}/edit`} />}
      />
      {queryLoaderWithExistenceCheck(
        parentalAssistanceGuidelineRegionalQuery
      ) || (
        <>
          <InformationRegionField />
          <SubTable title="Общие сведения">
            <TableRow>
              <TableCell>Регион</TableCell>
              <TableCell>
                {getName(parentalAssistanceGuidelineRegional.region)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Статус</TableCell>
              <TableCell>
                {ParentalAssistanceGuideline.status.getName(
                  parentalAssistanceGuidelineRegional.status
                )}
              </TableCell>
            </TableRow>
          </SubTable>
          <FilesRegionalSection data={parentalAssistanceGuidelineRegional} />
          <ProcedureRegionalSection
            data={parentalAssistanceGuidelineRegional}
          />
          <ResultSection data={parentalAssistanceGuidelineRegional} />
          <SubTable
            cols={3}
            title="Приложение 1. Заполните сведения об  организациях, оказывающих на территории субъекта Российской
          Федерации услуги (структурных подразделениях организаций, осуществляющих
          образовательную деятельность, либо отдельно созданных консультационных
          центрах)"
          >
            <TableRow>
              <TableCell>
                Наименование организации, в которой создан консультационный
                центр
              </TableCell>
              <TableCell>
                Оказание услуг осуществляется без взимания платы (да/нет)
              </TableCell>
              <TableCell>
                Предоставляются ли услуги в том числе на платной основе?
                (да/нет)
              </TableCell>
            </TableRow>
            <OrganizationsData
              organizations={
                parentalAssistanceGuidelineRegional.non_commercial_organizations_info
              }
            />
          </SubTable>
        </>
      )}
    </>
  );
}
