import gql from 'graphql-tag';

export const FRAGMENT_POF_REGIONAL_DOCUMENT = gql`
  fragment pofRegionalDocument on PofRegionalDocumentType {
    actual
    author {
      id
      name
    }
    comment_fpo
    created_at
    date
    editor_id
    expenses_direction {
      code
      name
      name_full
      result_id
      id
      result {
        id
        name_full
      }
    }
    expenses_direction_id
    federal_project {
      id
      code
      name
      name_full
    }
    id
    month
    number
    region {
      id
      name
    }
    region_id
    status
    updated_at
    values {
      keyfield
      line_number
      month
      value
      year
      comment
    }
    year
    version
  }
`;

export const QUERY_POF_REGIONAL_DOCUMENTS = gql`
  query pofRegionalDocuments(
    $filter: PofRegionalDocumentFilter
    $sorting: [PofRegionalDocumentSorting!]
  ) {
    pofRegionalDocuments(filter: $filter, sorting: $sorting) {
      id
      actual
      author {
        id
        name
      }
      status
      status_changed_at
      updated_at
      expenses_direction {
        id
        code
        name
        name_full
        result {
          id
          name_full
        }
      }
      year
      month
      regional_project {
        id
        name
        name_full
      }
    }
  }
`;

export const QUERY_POF_REGIONAL_DOCUMENT = gql`
  query pofRegionalDocument($id: ID!) {
    pofRegionalDocument(id: $id) {
      ...pofRegionalDocument
    }
  }
  ${FRAGMENT_POF_REGIONAL_DOCUMENT}
`;

export const QUERY_POF_FINANCES = gql`
  query pofFinances(
    $years: [Int!]
    $yearMonth: String
    $expensesDirectionIds: [ID!]
    $regionIds: [ID!]
  ) {
    mbt: mbtFinances(
      filter: {
        month: $yearMonth
        expenses_direction_ids: $expensesDirectionIds
        region_ids: $regionIds
      }
    ) {
      otfg_received
      otfg_kr_total
      plan
    }
    purchases: purchaseFinances(
      filter: {
        delivery_years: $years
        expenses_direction_ids: $expensesDirectionIds
        region_ids: $regionIds
      }
    ) {
      contract_summ_sfb
      contract_summ_skbsrf
    }
  }
`;

export const QUERY_POF_FINANCES_TOTAL = gql`
  query pofFinancesTotal(
    $years: [Int!]
    $yearMonth: String
    $expensesDirectionIds: [ID!]
    $regionIds: [ID!]
  ) {
    total: finances(
      filter: {
        month: $yearMonth
        region_ids: $regionIds
        expenses_direction_ids: $expensesDirectionIds
        budget_levels: [subject]
        intersect_mbt: intersect
      }
    ) {
      total_budget
      obligations
      cash_expenses
      accept: obligations_percent
      cash_completion: cash_expenses_percent
    }
    purchases: purchaseFinances(
      filter: {
        delivery_years: $years
        expenses_direction_ids: $expensesDirectionIds
        region_ids: $regionIds
      }
    ) {
      contract_summ_sfb
      contract_summ_skbsrf
    }
  }
`;

export const QUERY_PURCHASE_FINANCES = gql`
  query purchaseFinances($filter: PurchasePageFilter!) {
    sum: purchaseFinances(filter: $filter) {
      contract_summ_sfb
      contract_summ_skbsrf
    }
  }
`;

export const QUERY_GROUPED_PURCHASE_SCHEDULE_SUM = gql`
  query groupedPurchaseScheduleSum(
    $filter: PurchaseScheduleFilterInput!
    $group_by: [PurchaseScheduleGroupField!]!
  ) {
    groupedPurchaseScheduleSum(filter: $filter, group_by: $group_by) {
      month
      sum
    }
  }
`;

export const QUERY_GET_ACTUAL_POF = gql`
  query pofRegionalDocuments($filter: PofRegionalDocumentFilter) {
    pofRegionalDocuments(filter: $filter) {
      ...pofRegionalDocument
    }
  }
  ${FRAGMENT_POF_REGIONAL_DOCUMENT}
`;
