import React from 'react';
import { Formik } from 'formik';
import { useContextStore } from '@proscom/prostore-react';
import { useUser } from '../../../../store/role/useUsers';
import { queryLoaderWithExistenceCheck } from '../../../../common/QueryLoader';
import { removeLastPathItem } from '../../../../utils/url';
import { UserBreadCrumbs } from '../UserBreadCrumbs';
import { userValidationSchema } from '../../../../utils/data/validation';
import { USER_STORE } from '../../../../store/names';
import { User } from '../../../../store/role/User';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../../graphql/graphqlErrors';
import { FORM_STATUS_SUBMITTED } from '../../../../utils/data/form';
import { useOtherEditor } from '../../../../common/Form/useOtherEditor';
import { useCurrentUser } from '../../../../store/useCurrentUser';
import { HeadTitle } from '../../../../common/HeadTitle/HeadTitle';
import { getName } from '../../../../utils/data/label';
import { parseUserDuplicateError } from '../create/UserCreatePage';
import { UserEditForm } from './UserEditForm';

export default function UserEditPage({ match, history }) {
  const backLink = removeLastPathItem(match.url);
  const { userId } = match.params;

  const editor = useOtherEditor('user', userId);

  const userStore = useContextStore(USER_STORE);

  const userQuery = useUser(userId, true);
  const user = userQuery.state.data;

  const currentUser = useCurrentUser();

  return (
    <>
      <HeadTitle
        type="Пользователь"
        entity={getName(user)}
        pageType={HeadTitle.PageType.edit}
      />
      <UserBreadCrumbs />
      <h2>Редактирование пользователя</h2>
      {queryLoaderWithExistenceCheck(userQuery) || (
        <Formik
          validationSchema={userValidationSchema}
          initialValues={User.toForm(user)}
          onSubmit={(values, actions) => {
            const user = User.fromForm(values, currentUser);
            userStore
              .updateUser(user)
              .then((result) => {
                actions.setStatus(FORM_STATUS_SUBMITTED);
                setTimeout(() => {
                  history.goBack(User.getUrl(result.data.result));
                }, 0);
              })
              .catch((error) => {
                const duplicateError = parseUserDuplicateError(error);
                if (duplicateError) {
                  actions.setFieldError(
                    duplicateError.field,
                    duplicateError.message
                  );
                }
                showGraphqlErrorToast(
                  error,
                  mutationErrors.updateObject,
                  duplicateError
                );
              })
              .then(() => {
                actions.setSubmitting(false);
              });
          }}
          render={(formikProps) => (
            <UserEditForm
              {...formikProps}
              onBackClick={() => history.goBack(backLink)}
              editor={editor}
            />
          )}
        />
      )}
    </>
  );
}
