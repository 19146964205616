import React from 'react';
import { useFieldFast } from './useFieldFast';
import { NewNumberField } from './NewNumberField';

export function NewIntegerInput(props) {
  const [field, meta, helpers] = useFieldFast(props);

  return (
    <NewNumberField
      onChange={helpers.setValue}
      value={meta.value}
      unit={''}
      decimalScale={0}
      fixedDecimalScale={0}
      {...props}
    />
  );
}
