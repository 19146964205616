import React, { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import { CustomFastField } from '../../../common/Inputs/CustomFastField';
import TableInput from '../../../common/Table/TableInput';
import { TableTextInput } from '../../../common/Inputs/TextInput';
import { NoWrap } from '../../../common/NoWrap';
import { ParentalAssistanceGuideline } from '../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import { TableSelectWrapper } from '../../../common/Inputs/TableSelectWrapper';
import SubTable from '../../../common/SubTable/SubTable';
import s from '../ParentalAssistanceGuidelines.module.scss';

const title =
  'Приложение 1. Заполните сведения об организациях, оказывающих на территории ' +
  'субъекта Российской Федерации услуги (структурных подразделениях ' +
  'организаций, осуществляющих образовательную деятельность, либо ' +
  'отдельно созданных консультационных центрах)';

const options = ParentalAssistanceGuideline.yesNoSuggestions.map((item) => ({
  ...item,
  value: String(item.value)
}));

const columns = [
  {
    editComponent: TableTextInput,
    field: 'organization_name',
    title: 'Наименование организации, в которой создан консультационный центр',
    required: true,
    render: (row) => <NoWrap>{row.organization_name}</NoWrap>
  },
  {
    editComponent: TableSelectWrapper(options),
    field: 'free_services',
    title: 'Оказание услуг осуществляется без взимания платы',
    required: true,
    render: ({ free_services }) => {
      const item = options.find((item) => item.value === String(free_services));
      return item?.label || '—';
    }
  },
  {
    editComponent: TableSelectWrapper(options),
    field: 'paid_services',
    title: 'Предоставляются ли услуги в том числе на платной основе?',
    required: true,
    render: ({ paid_services }) => {
      const item = options.find((item) => item.value === String(paid_services));
      return item?.label || '—';
    }
  }
];

export const OrganizationsDataFields = () => {
  const customAddButton = {
    Add: forwardRef((props, ref) => (
      <div className={s.addButton}>
        <div className={s.addButton__title}>Добавить организацию</div>
        <AddBox {...props} ref={ref} />
      </div>
    ))
  };

  return (
    <SubTable title={title}>
      <div className={s.OrganizationsDataFields__table}>
        <CustomFastField
          name="preschool_non_commercial_organizations_info"
          columns={columns}
          component={TableInput}
          tableName="Дошкольная образовательная организация"
          customIcons={customAddButton}
        />
      </div>
      <div className={s.OrganizationsDataFields__table}>
        <CustomFastField
          name="general_non_commercial_organizations_info"
          columns={columns}
          component={TableInput}
          tableName="Общеобразовательная организация"
          customIcons={customAddButton}
        />
      </div>
      <div className={s.OrganizationsDataFields__table}>
        <CustomFastField
          name="other_non_commercial_organizations_info"
          columns={columns}
          component={TableInput}
          tableName="Иная организация"
          customIcons={customAddButton}
        />
      </div>
    </SubTable>
  );
};
