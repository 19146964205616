import { useMemo } from 'react';
import * as Yup from 'yup';
import { validationMessages } from '../../utils/data/validation';
import { useValidationMultipleLinks } from './useValidationMultipleLinks';

export const useCreateEquipmentPageValidationSchema = () => {
  const urlValidation = useValidationMultipleLinks();
  return useMemo(
    () =>
      Yup.object().shape({
        fpo_equipment_id: Yup.string().required(
          validationMessages.requiredMessage
        ),
        name_rvpo: Yup.string().required(validationMessages.requiredMessage),
        description_rvpo: Yup.string().required(
          validationMessages.requiredMessage
        ),
        model_rvpo: Yup.string().required(validationMessages.requiredMessage),
        unit: Yup.string().required(validationMessages.requiredMessage),
        quantity: Yup.number().required(validationMessages.requiredMessage),
        price: Yup.number().required(validationMessages.requiredMessage),
        equipment_external_url: urlValidation
      }),
    [urlValidation]
  );
};
