import { EnumHelpers } from '../../../utils/EnumHelpers';

export class Sorting {
  static SortDirection = {
    ASC: 'ASC',
    DESC: 'DESC'
  };

  static SortFields = {
    code: 'code',
    plan_term: 'plan_term',
    status_changed_at: 'status_changed_at',
    version: 'version'
  };

  static sortFields = new EnumHelpers(this.SortFields, {
    code: 'Код',
    plan_term: 'Плановый срок',
    status_changed_at: 'Дата изменения статуса',
    version: 'Версия'
  });
}
