import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import { isNumeric } from 'rxjs/internal-compatibility';
import { isTableFieldRequired } from '../Table/TableInput';
import { requiredMessage } from '../../utils/data/validation';
import TextBaseInput from './TextBaseInput';
import { Signals } from './Signals';
import s from './Inputs.module.scss';

export const TableTextInput = ({
  rowData,
  columnDef,
  onChange,
  value = '',
  error: propError,
  helperText,
  ...props
}) => {
  const isRequired = isTableFieldRequired(rowData, columnDef);
  const error = isRequired && !value?.toString() ? requiredMessage : undefined;

  return (
    <TextField
      {...props}
      error={error || propError}
      helperText={error || helperText}
      onChange={(e) => onChange(e.target.value)}
      required
      style={{ width: '100%' }}
      value={value ?? ''}
    />
  );
};

export const TextInput = ({ dependentFields, autocomplete, ...props }) => {
  return (
    <TextBaseInput
      // InputProps={{
      //   ...props?.InputProps,
      //   classes: {
      //     ...props?.InputProps?.classes,
      //     root: classnames(s.TextInput, props?.InputProps?.classes?.root),
      //   }
      // }}
      {...props}
      autoComplete={autocomplete ? autocomplete : 'off'}
    />
  );
};

export const TextInputNumeric = ({
  dependentFields,
  autocomplete,
  label,
  ...props
}) => {
  const { value = '' } = props.field;

  return (
    <TextBaseInput
      {...props}
      // InputProps={{
      //   ...props?.InputProps,
      //   classes: {
      //     ...props?.InputProps?.classes,
      //     root: classnames(s.TextInput, props?.InputProps?.classes?.root),
      //   }
      // }}
      type={'number'}
      label={label || ''}
      value={value ?? ''}
      autoComplete={autocomplete ? autocomplete : 'off'}
    />
  );
};

TextInput.propTypes = {
  field: PropTypes.shape(),
  form: PropTypes.shape(),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  variant: PropTypes.string
};

export const TableCurrencyInput = ({
  columnDef: { required },
  onChange,
  value = '',
  rowData,
  errorMessage
}) => {
  const enhancedInputComponent = React.useCallback(
    (props) => <NumberFormatCustom {...props} />,
    []
  );

  const getRequired = useCallback(
    (value) => {
      if (typeof required === 'function') {
        return required(rowData);
      }
      return required && !isNumeric(value);
    },
    [required, rowData]
  );

  return (
    <TextField
      error={getRequired(value)}
      helperText={
        getRequired(value) && (errorMessage || 'Должно быть заполнено')
      }
      onChange={(e) => onChange(e.target.value)}
      required={getRequired(value)}
      style={{ width: '100%' }}
      value={value}
      InputProps={{
        inputComponent: enhancedInputComponent,
        autoComplete: 'off'
      }}
    />
  );
};

export const CurrencyInput = ({
  unit,
  signals,
  decimalScale,
  fixedDecimalScale,
  allowString,
  allowNegative,
  ...props
}) => {
  const enhancedInputComponent = React.useCallback(
    (props) => (
      <NumberFormatCustom
        {...props}
        unit={unit}
        decimalScale={decimalScale}
        fixedDecimalScale={fixedDecimalScale}
        allowString={allowString}
        allowNegative={allowNegative}
      />
    ),
    [unit, decimalScale, fixedDecimalScale, allowString, allowNegative]
  );
  return (
    <>
      <TextBaseInput
        {...props}
        // InputProps={{
        //   ...props?.InputProps,
        //   classes: {
        //     ...props?.InputProps?.classes,
        //     root: classnames(s.TextInput, props?.InputProps?.classes?.root),
        //   },
        //   inputComponent: enhancedInputComponent,
        //   autoComplete: 'off'
        // }}
        InputProps={{
          classes: {
            root: s.TextInput
          },
          inputComponent: enhancedInputComponent,
          autoComplete: 'off'
        }}
      />
      <Signals signals={signals && signals[props.field.name]} />
    </>
  );
};

CurrencyInput.defaultProps = {
  unit: ' руб.'
};

CurrencyInput.propTypes = {
  field: PropTypes.shape(),
  form: PropTypes.shape(),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  variant: PropTypes.string,
  unit: PropTypes.string
};

function NumberFormatCustom({
  inputRef,
  onChange,
  id,
  unit,
  allowString,
  ...other
}) {
  return (
    <NumberFormat
      {...other}
      id={`${id}-format`}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            id,
            value: allowString ? values.value : values.floatValue
          }
        });
      }}
      thousandSeparator=" "
      isNumericString={allowString}
      decimalSeparator=","
      suffix={unit}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};
