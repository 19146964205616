import React from 'react';
import { FastField, Form, Formik } from 'formik';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { useContextStore } from '@proscom/prostore-react';
import InputGroup from '../../../common/Inputs/InputGroup';
import { TextInput } from '../../../common/Inputs/TextInput';
import { NewAutocompleteInput } from '../../../common/InputsNew/NewAutocompleteInput';
import { useMemoSuggestions } from '../../../utils/suggestions';
import { useSimpleRegions } from '../../../store/parentalAssistanceGuideline/useSimpleRegions';
import { STORE_PARENTAL_ASSISTANCE_GUIDELINE } from '../../../store/names';
import { FORM_STATUS_SUBMITTED } from '../../../utils/data/form';
import { parseUserDuplicateError } from '../../users/user/create/UserCreatePage';
import { NewOrganizationParentalValidationSchema } from '../../../utils/data/validation';
import {
  mutationErrors,
  showGraphqlErrorToast
} from '../../../graphql/graphqlErrors';
import { ParentalAssistanceGuideline } from '../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import { compareSimple, makeComparator } from '../../../utils/sorting';
import s from '../ParentalAssistanceGuidelinesOrganizational/ParentalAssistanceGuidelinesOrganizationalPage.module.scss';

export const NewOrganizationForm = ({
  setShowNewOrganizationCreateForm,
  setOrganizationId
}) => {
  const regionsQuery = useSimpleRegions();
  const allRegions = regionsQuery.state.data;
  const regionsSuggestions = useMemoSuggestions(allRegions);
  const regionsSuggestionsSorted = regionsSuggestions?.sort(
    makeComparator(compareSimple, (r) => r.label)
  );
  const parentalAssistanceGuideline = useContextStore(
    STORE_PARENTAL_ASSISTANCE_GUIDELINE
  );

  return (
    <div>
      <Formik
        initialValues={{
          name_short: '',
          address: '',
          region_id: ''
        }}
        validationSchema={NewOrganizationParentalValidationSchema}
        onSubmit={(values, actions) => {
          const organization = ParentalAssistanceGuideline.fromNonCommercialOrganizationForm(
            values
          );
          parentalAssistanceGuideline
            .createNonCommercialOrganization(organization)
            .then((result) => {
              actions.setStatus(FORM_STATUS_SUBMITTED);
              setOrganizationId(result.data.result.id);
              setShowNewOrganizationCreateForm(false);
            })
            .catch((error) => {
              const duplicateError = parseUserDuplicateError(error);
              if (duplicateError) {
                actions.setFieldError(
                  duplicateError.field,
                  duplicateError.message
                );
              }
              showGraphqlErrorToast(
                error,
                mutationErrors.updateObject,
                duplicateError
              );
            })
            .then(() => {
              actions.setSubmitting(false);
            });
        }}
        render={({ isSubmitting, touched, errors }) => {
          return (
            <Form noValidate>
              <InputGroup>
                <FastField
                  name="name_short"
                  label="Сокращенное наименование"
                  component={TextInput}
                  multiline
                  fullWidth
                />
                <FastField
                  name="address"
                  label="Адрес"
                  component={TextInput}
                  multiline
                  fullWidth
                />
                <NewAutocompleteInput
                  name="region_id"
                  label="Субъект Российской Федерации"
                  suggestions={regionsSuggestionsSorted}
                  fullWidth
                  errorMessage={touched.region_id && errors.region_id}
                />
              </InputGroup>
              <div className={s.buttons}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  style={{ marginRight: '20px' }}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} color="secondary" />
                  ) : (
                    'Сохранить'
                  )}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setShowNewOrganizationCreateForm(false);
                  }}
                >
                  Отменить
                </Button>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
};
