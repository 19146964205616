import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { QUERY_INFRALIST_DIRECTION } from '../../graphql/queries/infralist';

const infralistDirectionsQueryOptions = {
  query: QUERY_INFRALIST_DIRECTION,
  mapData: (result) => result.infralistDirection,
  skipQuery: skipIfNull(null)
};

export function useDirection(directionId, infralistId) {
  return useRetriableGraphql({
    queryOptions: infralistDirectionsQueryOptions,
    variables: {
      direction_id: directionId,
      infralist_id: infralistId
    }
  });
}
