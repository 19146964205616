import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PreventChangePage } from '../../../PreventChangePage';
import { useEscapeClick } from '../../../utils/hooks/useEscapeClick';
import { usePageScrollEndWatcher } from '../../../utils/hooks/usePageScrollEndWatcher';
import { ComponentWithTooltip } from '../../ComponentWithTooltip/ComponentWithTooltip';
import Dialog from '../../Dialog';
import Loader from '../../Loader/Loader';
import { TableRow } from './TableRow/TableRow';
import { SORT_ORDER_MAP } from './useTableManager';
import s from './InfralistTable.module.scss';

export const InfralistTable = ({
  data,
  columns,
  onLineClick,
  emptyMessage,
  activeSortField,
  activeSortOrder,
  onChangeSort,
  getActions,
  editRowId,
  onUpdateRow,
  onChangeEditRowId,
  isLoading,
  validationSchema,
  onLoadMore,
  checkItemEditAccess,
  minWidth,
  readonly
}) => {
  const activeColumns = useMemo(() => {
    return columns.filter((columnItem) => columnItem.isActive !== false);
  }, [columns]);
  const wrapperRef = useRef();

  // создает grid шаблон строки таблицы. Например: `10px 1fr 3fr 1fr`
  const gridTemplateColumns = useMemo(() => {
    let template = activeColumns.reduce((sum, currentColumn) => {
      const currentWidth =
        typeof currentColumn.width === 'number'
          ? `${currentColumn.width}px`
          : currentColumn.width;
      return `${sum} ${currentWidth ?? '1fr'}`;
    }, '');
    if (getActions) {
      template += ' auto';
    }
    return template;
  }, [activeColumns, getActions]);
  const [isDirty, setIsDirty] = useState(false);
  const [isCancelPopupVisible, setCancelPopupVisible] = useState(false);
  const [currentEditRowId, setCurrentEditRowId] = useState();

  const cancelEditRow = useCallback(() => {
    onChangeEditRowId && onChangeEditRowId(undefined);
  }, [onChangeEditRowId]);

  // откладываем смену редактируемой строки чтобы отследить несохраненные данные
  useLayoutEffect(() => {
    if (currentEditRowId !== editRowId) {
      if (currentEditRowId && isDirty) {
        setCancelPopupVisible(true);
      } else {
        setIsDirty(false);
        setCurrentEditRowId(editRowId);
      }
    }
  }, [currentEditRowId, editRowId, isDirty]);

  useEscapeClick(() => {
    if (currentEditRowId) {
      onChangeEditRowId(undefined);
    }
  });

  usePageScrollEndWatcher(data && wrapperRef, onLoadMore);

  return (
    <div className={s.tableWrapper} ref={wrapperRef}>
      <div
        className={s.table}
        style={{
          minWidth
        }}
      >
        <PreventChangePage when={!!currentEditRowId && !!isDirty} />
        <div
          className={s.tableRow}
          style={{
            gridTemplateColumns: gridTemplateColumns
          }}
        >
          {activeColumns.map((columnItem, columnItemKey) => {
            const isSortActive = activeSortField === columnItem.sort;
            const sortDescription =
              activeSortOrder === SORT_ORDER_MAP.ASC
                ? 'Применена сортировка по возрастанию'
                : activeSortOrder === SORT_ORDER_MAP.DESC
                ? 'Применена сортировка по убыванию'
                : '';
            return (
              <ComponentWithTooltip
                open={isSortActive}
                isTooltipExists={isSortActive}
                placement={'top'}
                title={sortDescription}
                key={columnItemKey}
              >
                <div
                  className={classNames(s.tableCell, '_header', {
                    [s.isClickable]: !!columnItem.sort && !readonly
                  })}
                  onClick={() =>
                    columnItem.sort &&
                    !readonly &&
                    onChangeSort(columnItem.sort)
                  }
                >
                  {columnItem.label}
                  {columnItem.sort && !readonly && (
                    <span
                      className={classNames(s.sortIcon, {
                        [s.sortIconActiveAsc]:
                          isSortActive &&
                          activeSortOrder === SORT_ORDER_MAP.ASC,
                        [s.sortIconActiveDesc]:
                          isSortActive &&
                          activeSortOrder === SORT_ORDER_MAP.DESC
                      })}
                    />
                  )}
                </div>
              </ComponentWithTooltip>
            );
          })}
          {getActions && <div className={s.tableActionCell} />}
        </div>
        <div className={s.tableBody}>
          {data &&
            data.map((dataItem, dataItemKey) => {
              const isEditMode = dataItem.id === currentEditRowId;
              return (
                <TableRow
                  onLineClick={onLineClick}
                  key={dataItem.id}
                  onSubmit={onUpdateRow}
                  dataItem={dataItem}
                  getActions={getActions}
                  isEditMode={isEditMode}
                  onCancelEditRow={cancelEditRow}
                  gridTemplateColumns={gridTemplateColumns}
                  onDoubleClick={onChangeEditRowId}
                  onChangeDirty={setIsDirty}
                  validationSchema={validationSchema}
                  columns={activeColumns}
                  dataItemKey={dataItemKey}
                  checkItemEditAccess={checkItemEditAccess}
                  readonly={readonly}
                />
              );
            })}
          {!data?.length && !isLoading && (
            <p className={s.emptyMessage}>{emptyMessage}</p>
          )}
          {isLoading && (
            <div className={s.loadingBlock}>
              <Loader
                centered={true}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  margin: 'auto'
                }}
              />
            </div>
          )}
        </div>

        <Dialog
          title={
            'Вы уверены, что хотите закрыть форму редактирования? Имеются несохраненные данные.'
          }
          open={isCancelPopupVisible}
          handleAccept={() => {
            setIsDirty(false);
            setCancelPopupVisible(false);
            setCurrentEditRowId(editRowId);
          }}
          handleClose={() => {
            onChangeEditRowId(currentEditRowId);
            setCancelPopupVisible(false);
          }}
        />
      </div>
    </div>
  );
};

InfralistTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      renderCell: PropTypes.func,
      renderEditCell: PropTypes.func,
      // ширина колонки записывается в `grid-template-columns`
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      isActive: PropTypes.bool
    })
  ).isRequired,
  activeSortField: PropTypes.string,
  activeSortOrder: PropTypes.string,
  onChangeSort: PropTypes.func,
  getContextMenu: PropTypes.func,
  onLineClick: PropTypes.func,
  emptyMessage: PropTypes.element,
  getActions: PropTypes.func,
  editRowId: PropTypes.string,
  onUpdateRow: PropTypes.func,
  isLoading: PropTypes.bool,
  onLoadMore: PropTypes.func,
  checkItemEditAccess: PropTypes.func,
  minWidth: PropTypes.number,
  readonly: PropTypes.bool
};
