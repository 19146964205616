import React, { useCallback } from 'react';
import { Formik, Form, FastField } from 'formik';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import MaterialDialog from '@material-ui/core/Dialog';
import InputGroup from '../../../common/Inputs/InputGroup';
import { TextInput } from '../../../common/Inputs/TextInput';
import {
  ContestDocsForSSMonitoringExportValidationSchema,
  ContestDocsMonitoringExportValidationSchema
} from '../../../utils/data/validation';

const initialValues = {
  FIO: '',
  position: ''
};

export const ContestDocsExportDialog = ({
  open,
  title,
  onConfirm,
  onClose
}) => {
  const handleSubmit = useCallback(
    (data) => {
      onClose();
      onConfirm(data);
    },
    [onClose, onConfirm]
  );

  return (
    <MaterialDialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <Formik
        validationSchema={ContestDocsForSSMonitoringExportValidationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ touched, errors }) => (
          <Form noValidate>
            <DialogContent>
              <InputGroup>
                <FastField
                  name="FIO"
                  label="ФИО"
                  component={TextInput}
                  fullWidth
                  errorMessage={touched.FIO && errors.FIO}
                />
                <FastField
                  name="position"
                  label="Должность"
                  component={TextInput}
                  fullWidth
                  errorMessage={touched.position && errors.position}
                />
              </InputGroup>
            </DialogContent>
            <DialogActions>
              <Button color="primary" type="submit">
                Экспорт
              </Button>
              <Button color="primary" autoFocus onClick={onClose}>
                Отмена
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </MaterialDialog>
  );
};
