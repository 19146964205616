import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MaterialLink from '@material-ui/core/Link';
import MaterialBreadcrumbs from '@material-ui/core/Breadcrumbs';
import classNames from 'classnames';
import s from './BreadCrumbs.module.scss';

const BreadCrumbs = ({ links, className }) => {
  return (
    <MaterialBreadcrumbs
      classes={{
        root: classNames(s.BreadCrumbs, className),
        separator: s.BreadCrumbFont,
        li: s.BreadCrumbFont
      }}
      aria-label="breadcrumb"
    >
      {links.map(({ to, label }, index) => (
        <MaterialLink
          key={index}
          component={to ? Link : 'span'}
          color={links.length - 1 === index ? 'textPrimary' : 'inherit'}
          to={to}
          className={classNames({
            [s.linkAsSpan]: !to
          })}
        >
          {label || '...'}
        </MaterialLink>
      ))}
    </MaterialBreadcrumbs>
  );
};

BreadCrumbs.propTypes = {
  links: PropTypes.array.isRequired
};

export default BreadCrumbs;

export function loadingBreadcrumb(value) {
  return value || '...';
}
