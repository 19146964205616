import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_PARENTAL_ASSISTANCE_GUIDELINES_REGIONAL } from '../../graphql/queries/parentalAssistanceGuidelines/parentalAssistanceGuidelinesRegional';

const nonParentalAssistanceGuidelinesRegionalQueryOptions = {
  query: QUERY_GET_PARENTAL_ASSISTANCE_GUIDELINES_REGIONAL,
  mapData: (result) => result.parentalAssistanceGuidelinesRegional,
  skipQuery: skipIfNull(null)
};

export function useParentalAssistanceGuidelinesRegional(filter) {
  return useRetriableGraphqlVars({
    queryOptions: nonParentalAssistanceGuidelinesRegionalQueryOptions,
    options: {
      apolloOptions: {
        fetchPolicy: 'network-only'
      }
    },
    variableCreator: [
      () =>
        filter
          ? {
              filter
            }
          : null,
      [filter]
    ]
  });
}
