import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import classNames from 'classnames';
import { SubTableHeading } from './SubTableHeading';
import s from './SubTable.module.scss';

const SubTable = ({
  title,
  supTitle,
  cols = 2,
  fixedLayout = true,
  head,
  className,
  children,
  editVariant
}) => {
  return (
    <Table
      classes={{
        root: classNames(
          s.SubTable,
          s['_cols' + cols],
          {
            [s.SubTable_edit]: editVariant,
            [s.SubTable_fixed]: fixedLayout
          },
          className
        )
      }}
    >
      {(!!title || !!supTitle || head) && (
        <TableHead>
          {!!title && (
            <SubTableHeading cols={cols}>
              {supTitle && (
                <div
                  className={classNames(s.SubTable__supHeading, {
                    [s.SubTable__supHeading_withTitle]: !!title
                  })}
                >
                  {supTitle}
                </div>
              )}
              {title}
            </SubTableHeading>
          )}
          {head}
        </TableHead>
      )}
      <TableBody>{children}</TableBody>
    </Table>
  );
};

SubTable.defaultProps = {
  title: ''
};

SubTable.propTypes = {
  title: PropTypes.string
};

export default SubTable;
