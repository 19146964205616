import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { QUERY_INFRALIST_DIRECTION_SECTIONS } from '../../graphql/queries/infralist';

const infralistDirectionsQueryOptions = {
  query: QUERY_INFRALIST_DIRECTION_SECTIONS,
  mapData: (result) => result.infralistDirectionSections,
  skipQuery: skipIfNull(null)
};

export function useDirectionSections(input, infralistId) {
  const response = useRetriableGraphql({
    queryOptions: infralistDirectionsQueryOptions,
    variables: {
      input,
      infralist_id: infralistId
    }
  });

  const data = response.state.data;

  return {
    sections: data,
    spinner: response.check.spinner
  };
}
