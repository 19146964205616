import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphqlVars } from '../../common/useRetriableGraphqlVars';
import { QUERY_GET_ROLES } from '../../graphql/queries/roles';

const rolesQueryOptions = {
  query: QUERY_GET_ROLES,
  mapData: (result) => result.roles,
  skipQuery: skipIfNull(null)
};

export function useRoles(filter, paginationParams) {
  return useRetriableGraphqlVars({
    queryOptions: rolesQueryOptions,
    variableCreator: [
      () =>
        filter
          ? {
              input: {
                filter: filter,
                pagination: paginationParams
              }
            }
          : null,
      [filter, paginationParams]
    ]
  });
}
