import { skipIfNull } from '@proscom/prostore';
import { useRetriableGraphql } from '../../common/useRetriableGraphqlVars';
import { GET_QUERY_MBT_FINANCES_GROUPED } from '../../graphql/queries/mbtFinancesGrouped';

const mbtFinancesGroupedQueryOptions = {
  query: GET_QUERY_MBT_FINANCES_GROUPED,
  mapData: (result) => result.mbtFinancesGrouped,
  skipQuery: skipIfNull(null)
};

export function useMbtFinancesGrouped(filter, groupBy) {
  return useRetriableGraphql({
    queryOptions: mbtFinancesGroupedQueryOptions,
    variables: filter
      ? {
          filter,
          groupBy
        }
      : null
  });
}
