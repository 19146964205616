import React from 'react';
import { FastField } from 'formik';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { NewAutocompleteInput } from '../../../common/InputsNew/NewAutocompleteInput';
import { ParentalAssistanceGuideline } from '../../../store/parentalAssistanceGuideline/ParentalAssistanceGuideline';
import { TextInput } from '../../../common/Inputs/TextInput';
import s from '../ParentalAssistanceGuidelinesRegional/ParentalAssistanceGuidelinesRegionalPage.module.scss';

export const ServiceDeliveryFormatsFields = ({
  title,
  setValue,
  serviceDeliveryFormatsTitle,
  otherServiceDeliveryFormatsTitle
}) => {
  return (
    <TableRow>
      <TableCell className={s.question}>{title}</TableCell>
      <TableCell>
        <NewAutocompleteInput
          label={
            serviceDeliveryFormatsTitle || 'Форматы (можно выбрать несколько)'
          }
          name="service_delivery_formats"
          suggestions={ParentalAssistanceGuideline.serviceDeliveryFormats.getOptions()}
          handleChange={setValue}
          hasCopyBtn={false}
          fullWidth
          isMulti
          useObject
        />
        <FastField
          name="other_service_delivery_formats"
          component={TextInput}
          label={
            otherServiceDeliveryFormatsTitle ||
            'Другие форматы (опишите при наличии)'
          }
          multiline
          fullWidth
        />
      </TableCell>
    </TableRow>
  );
};
