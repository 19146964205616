import { pick } from 'lodash-es';
import { User } from '../role/User';
import { getName, joinNonEmpty } from '../../utils/data/label';
import { Organization } from '../organization/Organization';
import { EMDASH } from '../../utils/constants';

const supervisorFields = [
  'surname',
  'first_name',
  'patronymic',
  'phones',
  'emails'
];

const subsidiaryEntityFields = [
  'address',
  'name_full',
  'index',
  'region_string',
  'locality',
  'street',
  'house',
  'building',
  'inn',
  'kpp',
  'year_of_creation',
  'region_id',
  'owner_roadmap_id',
  'base_organization_id',
  'se_type_id',
  'base_organization_name',
  'latitude',
  'longitude',
  'is_small_school'
];

export class SubsidiaryEntity {
  static combineAddress(entity) {
    if (!entity) return null;

    const parts = [
      entity.index,
      entity.region_string,
      entity.locality,
      entity.street,
      entity.house && `д. ${entity.house}`,
      entity.building && `корп/стр. ${entity.building}`,
      entity.latitude,
      entity.longitude
    ];

    const nonEmpty = parts.filter((p) => p);
    if (nonEmpty.length > (entity.region_string ? 1 : 0)) {
      return nonEmpty.join(', ');
    }
    if (entity.address) {
      return entity.address;
    }
    return entity.region_string || null;
  }

  static filterUpdateFields(data) {
    return {
      ...pick(data, ['id', ...subsidiaryEntityFields]),
      supervisor: pick(data.supervisor, supervisorFields)
    };
  }

  static fromForm({
    showAddressFields,
    index,
    locality,
    street,
    house,
    building,
    latitude,
    longitude,
    base_organization_id,
    base_organization_name,
    year_of_creation,
    is_small_school_field_required,
    is_small_school,
    ...data
  }) {
    const defaultFields = {
      supervisor: {
        surname: '',
        first_name: '',
        patronymic: ''
      },
      index: '',
      locality: '',
      street: '',
      house: '',
      building: ''
    };
    const addressFields = showAddressFields && {
      address: '',
      index,
      locality,
      street,
      house,
      building
    };

    return {
      ...defaultFields,
      year_of_creation: +year_of_creation,
      latitude: +latitude || 0,
      longitude: +longitude || 0,
      base_organization_id: base_organization_name
        ? null
        : base_organization_id || undefined,
      base_organization_name,
      ...data,
      ...addressFields,
      is_small_school: !!is_small_school
    };
  }

  static toForm({
    index,
    region_string,
    locality,
    street,
    house,
    building,
    address,
    year_of_creation,
    ...subsidiaryEntity
  }) {
    const latitude = subsidiaryEntity.latitude;
    const longitude = subsidiaryEntity.longitude;

    return {
      ...subsidiaryEntity,
      index,
      region_string,
      locality,
      street,
      house,
      building,
      latitude: latitude ? latitude.toString() : '',
      longitude: longitude ? longitude.toString() : '',
      address: !!(locality || street || house)
        ? SubsidiaryEntity.combineAddress({
            index,
            region_string,
            locality,
            street,
            house,
            building
          })
        : address,
      showAddressFields: !!(locality || street || house),
      se_type_id: subsidiaryEntity.se_type?.id,
      year_of_creation: String(year_of_creation)
    };
  }

  static getUrl(subsidiaryEntity) {
    return `/subsidiaryEntities/${subsidiaryEntity.id}`;
  }

  static canCreate(user) {
    return User.hasPermission(user, 'subsidiary_entity.crud');
  }

  static canEdit(user, item) {
    return User.hasPermission(user, 'subsidiary_entity.crud');
  }

  static canDelete(user, item) {
    return User.hasPermission(user, 'subsidiary_entity.crud');
  }

  static canConfirm(user, item) {
    return User.hasPermission(user, 'subsidiary_entity.approve');
  }

  static canRevokeConfirmation(user, item) {
    return User.hasPermission(user, 'subsidiary_entity.revoke_approval');
  }

  static getCompleteName(entity) {
    if (!entity) {
      return null;
    }

    return joinNonEmpty(
      [
        getName(entity),
        entity.base_organization
          ? Organization.getCompleteName(entity.base_organization)
          : Organization.getCompleteName({
              name_short: entity.base_organization_name,
              inn: entity.inn,
              kpp: entity.kpp
            })
      ],
      ` ${EMDASH} `
    );
  }

  static getBaseOrganizationName(entity, complete = false) {
    if (!entity) return null;
    return (
      (complete
        ? Organization.getCompleteName(entity.base_organization)
        : Organization.getName(entity.base_organization)) ||
      entity.base_organization_name
    );
  }
}
