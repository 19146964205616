import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { useEffect, useState } from 'react';
import { QUERY_VERSION_RVPO_EQUIPMENTS_LIST } from '../../../graphql/queries/infralistEquipment';
import { useSortPager } from '../../../utils/hooks/useSortPager';
import { clearApolloQueryCache } from '../../../utils/apollo';

const EQUIPMENT_PER_PAGE = 10;

export function useVersionEquipmentList({ sectionId, versionId }) {
  const client = useContextApolloClient();

  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(false);
  const [equipmentQueryResult, setEquipmentQueryResult] = useState();
  const { params, loadMore, setSort, reset, setFilter } = useSortPager({
    itemsPerPage: EQUIPMENT_PER_PAGE
  });

  // обновление фильтров через setFilter, для сброса постранички
  useEffect(() => {
    setFilter({
      infralist_version_id: versionId,
      section_id: sectionId
    });
  }, [sectionId, setFilter, versionId]);

  useEffect(() => {
    setIsLoading(true);
    if (!params.filter?.infralist_version_id || !params.filter?.section_id) {
      return;
    }
    client
      .query({
        query: QUERY_VERSION_RVPO_EQUIPMENTS_LIST,
        variables: {
          input: params
        }
      })
      .then((response) => {
        /**
         * @type {{header: {hasNext: boolean, page: number, totalCount: number}, list: []}}
         */
        const responseData = response.data.infralistVersionRvpoEquipments;

        setEquipmentQueryResult((prev) => ({
          header: responseData.header,
          list:
            responseData.header.page === 0
              ? responseData.list
              : [...(prev.list ?? []), ...responseData.list]
        }));
      })
      .finally(() => {
        setLoadMoreEnabled(true);
        setIsLoading(false);
        clearApolloQueryCache(client.cache, ['infralistVersionRvpoEquipments']);
      });
  }, [client, params]);

  const updateItem = (nextItem) => {
    setEquipmentQueryResult({
      ...equipmentQueryResult,
      list: equipmentQueryResult.list.map((currentItem) =>
        currentItem.id === nextItem.id
          ? { ...currentItem, ...nextItem }
          : currentItem
      )
    });
  };

  const deleteItem = (itemId) => {
    setEquipmentQueryResult({
      ...equipmentQueryResult,
      list: equipmentQueryResult.list.filter(
        (currentItem) => currentItem.id !== itemId
      )
    });
  };

  const updateAll = (updatedProps) => {
    setEquipmentQueryResult({
      ...equipmentQueryResult,
      list: equipmentQueryResult.list.map((currentItem) => ({
        ...currentItem,
        ...updatedProps
      }))
    });
  };

  const loadMoreWatchQuery = () => {
    if (equipmentQueryResult?.header?.hasNext && !isLoading) {
      if (loadMoreEnabled) {
        loadMore();
      }
      setLoadMoreEnabled(false);
    }
  };

  return {
    data: equipmentQueryResult?.list,
    spinner: isLoading,
    loadMore: loadMoreWatchQuery,
    setSort,
    updateItem,
    updateAll,
    resetEquipmentList: reset,
    deleteEquipment: deleteItem,
    setEquipmentData: setEquipmentQueryResult
  };
}
