import { useState } from 'react';
import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import { MUTATION_INFRALIST_NOT_APPROVE_ALL } from '../../../graphql/mutations/infralist';
import { INFRALIST_QUERIES } from '../infralistQueries';

export const useNotApproveAll = () => {
  const client = useContextApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  const notApproveAll = ({ infralistId }) => {
    setIsLoading(true);
    return client
      .mutate({
        mutation: MUTATION_INFRALIST_NOT_APPROVE_ALL,
        variables: {
          infralist_id: infralistId
        },
        refetchQueries: [
          INFRALIST_QUERIES.INFRALIST,
          INFRALIST_QUERIES.DIRECTIONS_LIST,
          INFRALIST_QUERIES.DIRECTION,
          INFRALIST_QUERIES.DIRECTION_SECTIONS,
          INFRALIST_QUERIES.SECTION,
          INFRALIST_QUERIES.EQUIPMENT_LIST
        ],
        update: (store) => {
          store.reset();
        }
      })
      .finally(() => setIsLoading(false));
  };

  return {
    isLoadingNotApproveAll: isLoading,
    notApproveAll
  };
};
